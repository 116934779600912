.Table__itemCount {
  /* margin-top: 10px; */
  font-size: 14px;
}
.Table__pagination {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.Table__pageButton {
  padding: 5px 8px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #c2d4e0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin: 0 6px;
}

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: #c2d4e0;
}
.Table__pageButton:focus {
  outline: none;
}

.Table__pageButton--active {
  background: #795afa;
  border-radius: 3px;
  color: #fff;
}
.Table__visiblePagesWrapper {
  padding: 2px 0;
}
.icon {
  color: #6a49f9;
}
.Table__pageButton:disabled .icon {
  color: #c2d4e0;
}
.Dropdown_Pagination {
  outline: none;
  padding: 0px 5px 0px 5px;
  background-color: transparent;
  border: none;
  color: #6a49f9;
}
.Table__rppContainer {
  min-width: 100px;
}
.highlight {
  background: red;
  margin-top: 20px;
}
