@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,400;0,700;0,800;1,200&family=Lora:ital,wght@0,200;0,400;0,700;0,800;1,200&display=swap);
.Table__itemCount {
  /* margin-top: 10px; */
  font-size: 14px;
}
.Table__pagination {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.Table__pageButton {
  padding: 5px 8px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #c2d4e0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin: 0 6px;
}

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: #c2d4e0;
}
.Table__pageButton:focus {
  outline: none;
}

.Table__pageButton--active {
  background: #795afa;
  border-radius: 3px;
  color: #fff;
}
.Table__visiblePagesWrapper {
  padding: 2px 0;
}
.icon {
  color: #6a49f9;
}
.Table__pageButton:disabled .icon {
  color: #c2d4e0;
}
.Dropdown_Pagination {
  outline: none;
  padding: 0px 5px 0px 5px;
  background-color: transparent;
  border: none;
  color: #6a49f9;
}
.Table__rppContainer {
  min-width: 100px;
}
.highlight {
  background: red;
  margin-top: 20px;
}

@charset "UTF-8";
/*!
 * Bootstrap v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #795afa;
  --secondary: #fc5c7d;
  --success: #45b649;
  --info: #00c9ff;
  --warning: #ffd700;
  --danger: #e40061;
  --light: #f8f9fa;
  --dark: #343a40;
  --coffee: #3d3d3d;
  --blue: #795afa;
  --dark-blue: #224b8b;
  --roll-over: #4324c7;
  --gray: #c2d4e0;
  --focus-box-shadow: rgba(121, 90, 250, 0.25);
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #795afa;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #3d10f8;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014   \A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d9d1fe; }

.table-hover .table-primary:hover {
  background-color: #c4b8fd; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #c4b8fd; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fed1db; }

.table-hover .table-secondary:hover {
  background-color: #fdb8c7; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #fdb8c7; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cbebcc; }

.table-hover .table-success:hover {
  background-color: #b9e4ba; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b9e4ba; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8f0ff; }

.table-hover .table-info:hover {
  background-color: #9febff; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #9febff; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff4b8; }

.table-hover .table-warning:hover {
  background-color: #fff09f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fff09f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f7b8d3; }

.table-hover .table-danger:hover {
  background-color: #f4a1c5; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f4a1c5; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-coffee,
.table-coffee > th,
.table-coffee > td {
  background-color: #c9c9c9; }

.table-hover .table-coffee:hover {
  background-color: #bcbcbc; }
  .table-hover .table-coffee:hover > td,
  .table-hover .table-coffee:hover > th {
    background-color: #bcbcbc; }

.table-blue,
.table-blue > th,
.table-blue > td {
  background-color: #d9d1fe; }

.table-hover .table-blue:hover {
  background-color: #c4b8fd; }
  .table-hover .table-blue:hover > td,
  .table-hover .table-blue:hover > th {
    background-color: #c4b8fd; }

.table-dark-blue,
.table-dark-blue > th,
.table-dark-blue > td {
  background-color: #c1cddf; }

.table-hover .table-dark-blue:hover {
  background-color: #b0bfd6; }
  .table-hover .table-dark-blue:hover > td,
  .table-hover .table-dark-blue:hover > th {
    background-color: #b0bfd6; }

.table-roll-over,
.table-roll-over > th,
.table-roll-over > td {
  background-color: #cac2ef; }

.table-hover .table-roll-over:hover {
  background-color: #b8aeea; }
  .table-hover .table-roll-over:hover > td,
  .table-hover .table-roll-over:hover > th {
    background-color: #b8aeea; }

.table-gray,
.table-gray > th,
.table-gray > td {
  background-color: #eef3f6; }

.table-hover .table-gray:hover {
  background-color: #dde7ed; }
  .table-hover .table-gray:hover > td,
  .table-hover .table-gray:hover > th {
    background-color: #dde7ed; }

.table-focus-box-shadow,
.table-focus-box-shadow > th,
.table-focus-box-shadow > td {
  background-color: rgba(248, 246, 255, 0.79); }

.table-hover .table-focus-box-shadow:hover {
  background-color: rgba(228, 221, 255, 0.79); }
  .table-hover .table-focus-box-shadow:hover > td,
  .table-hover .table-focus-box-shadow:hover > th {
    background-color: rgba(228, 221, 255, 0.79); }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ded6fe;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #45b649; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(69, 182, 73, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #45b649; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #45b649;
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #45b649; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #45b649; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #a0dba2; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background: #68c66b linear-gradient(180deg, #7fce82, #68c66b) repeat-x; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(69, 182, 73, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #45b649; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e40061; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(228, 0, 97, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #e40061; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #e40061;
    box-shadow: 0 0 0 0.2rem rgba(228, 0, 97, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e40061; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #e40061; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #ff65a6; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background: #ff187a linear-gradient(180deg, #ff3b8e, #ff187a) repeat-x; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(228, 0, 97, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e40061; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(228, 0, 97, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background: #795afa linear-gradient(180deg, #8d73fb, #795afa) repeat-x;
  border-color: #795afa; }
  .btn-primary:hover {
    color: #fff;
    background: #5b35f9 linear-gradient(180deg, #7353fa, #5b35f9) repeat-x;
    border-color: #5129f9; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #795afa;
    border-color: #795afa; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #5129f9;
    background-image: none;
    border-color: #471cf8; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.5); }

.btn-secondary {
  color: #fff;
  background: #fc5c7d linear-gradient(180deg, #fc7491, #fc5c7d) repeat-x;
  border-color: #fc5c7d; }
  .btn-secondary:hover {
    color: #fff;
    background: #fb365f linear-gradient(180deg, #fc5577, #fb365f) repeat-x;
    border-color: #fb2a55; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 92, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #fc5c7d;
    border-color: #fc5c7d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #fb2a55;
    background-image: none;
    border-color: #fb1d4b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(252, 92, 125, 0.5); }

.btn-success {
  color: #fff;
  background: #45b649 linear-gradient(180deg, #61c164, #45b649) repeat-x;
  border-color: #45b649; }
  .btn-success:hover {
    color: #fff;
    background: #3a9a3e linear-gradient(180deg, #58a95b, #3a9a3e) repeat-x;
    border-color: #37913a; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #45b649;
    border-color: #45b649; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #37913a;
    background-image: none;
    border-color: #338836; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.5); }

.btn-info {
  color: #fff;
  background: #00c9ff linear-gradient(180deg, #26d1ff, #00c9ff) repeat-x;
  border-color: #00c9ff; }
  .btn-info:hover {
    color: #fff;
    background: #00abd9 linear-gradient(180deg, #26b7de, #00abd9) repeat-x;
    border-color: #00a1cc; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 201, 255, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #00c9ff;
    border-color: #00c9ff; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #00a1cc;
    background-image: none;
    border-color: #0097bf; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 201, 255, 0.5); }

.btn-warning {
  color: #212529;
  background: #ffd700 linear-gradient(180deg, #ffdd26, #ffd700) repeat-x;
  border-color: #ffd700; }
  .btn-warning:hover {
    color: #212529;
    background: #d9b700 linear-gradient(180deg, #dec226, #d9b700) repeat-x;
    border-color: #ccac00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 215, 0, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffd700;
    border-color: #ffd700; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #ccac00;
    background-image: none;
    border-color: #bfa100; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 215, 0, 0.5); }

.btn-danger {
  color: #fff;
  background: #e40061 linear-gradient(180deg, #e82679, #e40061) repeat-x;
  border-color: #e40061; }
  .btn-danger:hover {
    color: #fff;
    background: #be0051 linear-gradient(180deg, #c8266b, #be0051) repeat-x;
    border-color: #b1004b; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(228, 0, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #e40061;
    border-color: #e40061; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b1004b;
    background-image: none;
    border-color: #a40046; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(228, 0, 97, 0.5); }

.btn-light {
  color: #212529;
  background: #f8f9fa linear-gradient(180deg, #f9fafb, #f8f9fa) repeat-x;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background: #e2e6ea linear-gradient(180deg, #e6eaed, #e2e6ea) repeat-x;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    background-image: none;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background: #343a40 linear-gradient(180deg, #52585d, #343a40) repeat-x;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background: #23272b linear-gradient(180deg, #44474b, #23272b) repeat-x;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    background-image: none;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-coffee {
  color: #fff;
  background: #3d3d3d linear-gradient(180deg, #5a5a5a, #3d3d3d) repeat-x;
  border-color: #3d3d3d; }
  .btn-coffee:hover {
    color: #fff;
    background: #2a2a2a linear-gradient(180deg, #4a4a4a, #2a2a2a) repeat-x;
    border-color: #242424; }
  .btn-coffee:focus, .btn-coffee.focus {
    box-shadow: 0 0 0 0.2rem rgba(61, 61, 61, 0.5); }
  .btn-coffee.disabled, .btn-coffee:disabled {
    color: #fff;
    background-color: #3d3d3d;
    border-color: #3d3d3d; }
  .btn-coffee:not(:disabled):not(.disabled):active, .btn-coffee:not(:disabled):not(.disabled).active,
  .show > .btn-coffee.dropdown-toggle {
    color: #fff;
    background-color: #242424;
    background-image: none;
    border-color: #1d1d1d; }
    .btn-coffee:not(:disabled):not(.disabled):active:focus, .btn-coffee:not(:disabled):not(.disabled).active:focus,
    .show > .btn-coffee.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(61, 61, 61, 0.5); }

.btn-blue {
  color: #fff;
  background: #795afa linear-gradient(180deg, #8d73fb, #795afa) repeat-x;
  border-color: #795afa; }
  .btn-blue:hover {
    color: #fff;
    background: #5b35f9 linear-gradient(180deg, #7353fa, #5b35f9) repeat-x;
    border-color: #5129f9; }
  .btn-blue:focus, .btn-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.5); }
  .btn-blue.disabled, .btn-blue:disabled {
    color: #fff;
    background-color: #795afa;
    border-color: #795afa; }
  .btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active,
  .show > .btn-blue.dropdown-toggle {
    color: #fff;
    background-color: #5129f9;
    background-image: none;
    border-color: #471cf8; }
    .btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.5); }

.btn-dark-blue {
  color: #fff;
  background: #224b8b linear-gradient(180deg, #43669c, #224b8b) repeat-x;
  border-color: #224b8b; }
  .btn-dark-blue:hover {
    color: #fff;
    background: #1a3a6c linear-gradient(180deg, #3d5882, #1a3a6c) repeat-x;
    border-color: #183562; }
  .btn-dark-blue:focus, .btn-dark-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(34, 75, 139, 0.5); }
  .btn-dark-blue.disabled, .btn-dark-blue:disabled {
    color: #fff;
    background-color: #224b8b;
    border-color: #224b8b; }
  .btn-dark-blue:not(:disabled):not(.disabled):active, .btn-dark-blue:not(:disabled):not(.disabled).active,
  .show > .btn-dark-blue.dropdown-toggle {
    color: #fff;
    background-color: #183562;
    background-image: none;
    border-color: #152f58; }
    .btn-dark-blue:not(:disabled):not(.disabled):active:focus, .btn-dark-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(34, 75, 139, 0.5); }

.btn-roll-over {
  color: #fff;
  background: #4324c7 linear-gradient(180deg, #5f45cf, #4324c7) repeat-x;
  border-color: #4324c7; }
  .btn-roll-over:hover {
    color: #fff;
    background: #381ea7 linear-gradient(180deg, #5640b4, #381ea7) repeat-x;
    border-color: #341c9c; }
  .btn-roll-over:focus, .btn-roll-over.focus {
    box-shadow: 0 0 0 0.2rem rgba(67, 36, 199, 0.5); }
  .btn-roll-over.disabled, .btn-roll-over:disabled {
    color: #fff;
    background-color: #4324c7;
    border-color: #4324c7; }
  .btn-roll-over:not(:disabled):not(.disabled):active, .btn-roll-over:not(:disabled):not(.disabled).active,
  .show > .btn-roll-over.dropdown-toggle {
    color: #fff;
    background-color: #341c9c;
    background-image: none;
    border-color: #311a91; }
    .btn-roll-over:not(:disabled):not(.disabled):active:focus, .btn-roll-over:not(:disabled):not(.disabled).active:focus,
    .show > .btn-roll-over.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(67, 36, 199, 0.5); }

.btn-gray {
  color: #212529;
  background: #c2d4e0 linear-gradient(180deg, #cbdae5, #c2d4e0) repeat-x;
  border-color: #c2d4e0; }
  .btn-gray:hover {
    color: #212529;
    background: #a9c2d3 linear-gradient(180deg, #b6cbda, #a9c2d3) repeat-x;
    border-color: #a0bccf; }
  .btn-gray:focus, .btn-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(194, 212, 224, 0.5); }
  .btn-gray.disabled, .btn-gray:disabled {
    color: #212529;
    background-color: #c2d4e0;
    border-color: #c2d4e0; }
  .btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active,
  .show > .btn-gray.dropdown-toggle {
    color: #212529;
    background-color: #a0bccf;
    background-image: none;
    border-color: #98b6cb; }
    .btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(194, 212, 224, 0.5); }

.btn-focus-box-shadow {
  color: #fff;
  background: rgba(121, 90, 250, 0.25) linear-gradient(180deg, rgba(195, 181, 253, 0.3625), rgba(121, 90, 250, 0.25)) repeat-x;
  border-color: rgba(121, 90, 250, 0.25); }
  .btn-focus-box-shadow:hover {
    color: #fff;
    background: rgba(91, 53, 249, 0.25) linear-gradient(180deg, rgba(182, 165, 252, 0.3625), rgba(91, 53, 249, 0.25)) repeat-x;
    border-color: rgba(81, 41, 249, 0.25); }
  .btn-focus-box-shadow:focus, .btn-focus-box-shadow.focus {
    box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.5); }
  .btn-focus-box-shadow.disabled, .btn-focus-box-shadow:disabled {
    color: #fff;
    background-color: rgba(121, 90, 250, 0.25);
    border-color: rgba(121, 90, 250, 0.25); }
  .btn-focus-box-shadow:not(:disabled):not(.disabled):active, .btn-focus-box-shadow:not(:disabled):not(.disabled).active,
  .show > .btn-focus-box-shadow.dropdown-toggle {
    color: #fff;
    background-color: rgba(81, 41, 249, 0.25);
    background-image: none;
    border-color: rgba(71, 28, 248, 0.25); }
    .btn-focus-box-shadow:not(:disabled):not(.disabled):active:focus, .btn-focus-box-shadow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-focus-box-shadow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.5); }

.btn-outline-primary {
  color: #795afa;
  background-color: transparent;
  background-image: none;
  border-color: #795afa; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #795afa;
    border-color: #795afa; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #795afa;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #795afa;
    border-color: #795afa; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.5); }

.btn-outline-secondary {
  color: #fc5c7d;
  background-color: transparent;
  background-image: none;
  border-color: #fc5c7d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #fc5c7d;
    border-color: #fc5c7d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 92, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #fc5c7d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #fc5c7d;
    border-color: #fc5c7d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(252, 92, 125, 0.5); }

.btn-outline-success {
  color: #45b649;
  background-color: transparent;
  background-image: none;
  border-color: #45b649; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #45b649;
    border-color: #45b649; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #45b649;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #45b649;
    border-color: #45b649; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.5); }

.btn-outline-info {
  color: #00c9ff;
  background-color: transparent;
  background-image: none;
  border-color: #00c9ff; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #00c9ff;
    border-color: #00c9ff; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 201, 255, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #00c9ff;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00c9ff;
    border-color: #00c9ff; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 201, 255, 0.5); }

.btn-outline-warning {
  color: #ffd700;
  background-color: transparent;
  background-image: none;
  border-color: #ffd700; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffd700;
    border-color: #ffd700; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 215, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffd700;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffd700;
    border-color: #ffd700; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 215, 0, 0.5); }

.btn-outline-danger {
  color: #e40061;
  background-color: transparent;
  background-image: none;
  border-color: #e40061; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #e40061;
    border-color: #e40061; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(228, 0, 97, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #e40061;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #e40061;
    border-color: #e40061; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(228, 0, 97, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-coffee {
  color: #3d3d3d;
  background-color: transparent;
  background-image: none;
  border-color: #3d3d3d; }
  .btn-outline-coffee:hover {
    color: #fff;
    background-color: #3d3d3d;
    border-color: #3d3d3d; }
  .btn-outline-coffee:focus, .btn-outline-coffee.focus {
    box-shadow: 0 0 0 0.2rem rgba(61, 61, 61, 0.5); }
  .btn-outline-coffee.disabled, .btn-outline-coffee:disabled {
    color: #3d3d3d;
    background-color: transparent; }
  .btn-outline-coffee:not(:disabled):not(.disabled):active, .btn-outline-coffee:not(:disabled):not(.disabled).active,
  .show > .btn-outline-coffee.dropdown-toggle {
    color: #fff;
    background-color: #3d3d3d;
    border-color: #3d3d3d; }
    .btn-outline-coffee:not(:disabled):not(.disabled):active:focus, .btn-outline-coffee:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-coffee.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(61, 61, 61, 0.5); }

.btn-outline-blue {
  color: #795afa;
  background-color: transparent;
  background-image: none;
  border-color: #795afa; }
  .btn-outline-blue:hover {
    color: #fff;
    background-color: #795afa;
    border-color: #795afa; }
  .btn-outline-blue:focus, .btn-outline-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.5); }
  .btn-outline-blue.disabled, .btn-outline-blue:disabled {
    color: #795afa;
    background-color: transparent; }
  .btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-blue.dropdown-toggle {
    color: #fff;
    background-color: #795afa;
    border-color: #795afa; }
    .btn-outline-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.5); }

.btn-outline-dark-blue {
  color: #224b8b;
  background-color: transparent;
  background-image: none;
  border-color: #224b8b; }
  .btn-outline-dark-blue:hover {
    color: #fff;
    background-color: #224b8b;
    border-color: #224b8b; }
  .btn-outline-dark-blue:focus, .btn-outline-dark-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(34, 75, 139, 0.5); }
  .btn-outline-dark-blue.disabled, .btn-outline-dark-blue:disabled {
    color: #224b8b;
    background-color: transparent; }
  .btn-outline-dark-blue:not(:disabled):not(.disabled):active, .btn-outline-dark-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark-blue.dropdown-toggle {
    color: #fff;
    background-color: #224b8b;
    border-color: #224b8b; }
    .btn-outline-dark-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(34, 75, 139, 0.5); }

.btn-outline-roll-over {
  color: #4324c7;
  background-color: transparent;
  background-image: none;
  border-color: #4324c7; }
  .btn-outline-roll-over:hover {
    color: #fff;
    background-color: #4324c7;
    border-color: #4324c7; }
  .btn-outline-roll-over:focus, .btn-outline-roll-over.focus {
    box-shadow: 0 0 0 0.2rem rgba(67, 36, 199, 0.5); }
  .btn-outline-roll-over.disabled, .btn-outline-roll-over:disabled {
    color: #4324c7;
    background-color: transparent; }
  .btn-outline-roll-over:not(:disabled):not(.disabled):active, .btn-outline-roll-over:not(:disabled):not(.disabled).active,
  .show > .btn-outline-roll-over.dropdown-toggle {
    color: #fff;
    background-color: #4324c7;
    border-color: #4324c7; }
    .btn-outline-roll-over:not(:disabled):not(.disabled):active:focus, .btn-outline-roll-over:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-roll-over.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(67, 36, 199, 0.5); }

.btn-outline-gray {
  color: #c2d4e0;
  background-color: transparent;
  background-image: none;
  border-color: #c2d4e0; }
  .btn-outline-gray:hover {
    color: #212529;
    background-color: #c2d4e0;
    border-color: #c2d4e0; }
  .btn-outline-gray:focus, .btn-outline-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(194, 212, 224, 0.5); }
  .btn-outline-gray.disabled, .btn-outline-gray:disabled {
    color: #c2d4e0;
    background-color: transparent; }
  .btn-outline-gray:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray.dropdown-toggle {
    color: #212529;
    background-color: #c2d4e0;
    border-color: #c2d4e0; }
    .btn-outline-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(194, 212, 224, 0.5); }

.btn-outline-focus-box-shadow {
  color: rgba(121, 90, 250, 0.25);
  background-color: transparent;
  background-image: none;
  border-color: rgba(121, 90, 250, 0.25); }
  .btn-outline-focus-box-shadow:hover {
    color: #fff;
    background-color: rgba(121, 90, 250, 0.25);
    border-color: rgba(121, 90, 250, 0.25); }
  .btn-outline-focus-box-shadow:focus, .btn-outline-focus-box-shadow.focus {
    box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.5); }
  .btn-outline-focus-box-shadow.disabled, .btn-outline-focus-box-shadow:disabled {
    color: rgba(121, 90, 250, 0.25);
    background-color: transparent; }
  .btn-outline-focus-box-shadow:not(:disabled):not(.disabled):active, .btn-outline-focus-box-shadow:not(:disabled):not(.disabled).active,
  .show > .btn-outline-focus-box-shadow.dropdown-toggle {
    color: #fff;
    background-color: rgba(121, 90, 250, 0.25);
    border-color: rgba(121, 90, 250, 0.25); }
    .btn-outline-focus-box-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-focus-box-shadow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-focus-box-shadow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.5); }

.btn-link {
  font-weight: 400;
  color: #795afa;
  background-color: transparent; }
  .btn-link:hover {
    color: #3d10f8;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background: #f8f9fa linear-gradient(180deg, #f9fafb, #f8f9fa) repeat-x; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background: #795afa linear-gradient(180deg, #8d73fb, #795afa) repeat-x; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent;
    background-image: none; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background: #795afa linear-gradient(180deg, #8d73fb, #795afa) repeat-x; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(121, 90, 250, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: white; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
            user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: #795afa linear-gradient(180deg, #8d73fb, #795afa) repeat-x; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background: #795afa linear-gradient(180deg, #8d73fb, #795afa) repeat-x; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(121, 90, 250, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(121, 90, 250, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: #795afa linear-gradient(180deg, #8d73fb, #795afa) repeat-x; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(121, 90, 250, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #ded6fe;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(222, 214, 254, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #ded6fe;
    box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.25); }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #ded6fe; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background: #e9ecef linear-gradient(180deg, #eceff1, #e9ecef) repeat-x;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background: #795afa linear-gradient(180deg, #8d73fb, #795afa) repeat-x;
    border: 0;
    border-radius: 1rem;
    -webkit-appearance: none;
            appearance: none; }
    .custom-range::-webkit-slider-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(121, 90, 250, 0.25); }
    .custom-range::-webkit-slider-thumb:active {
      background: white linear-gradient(180deg, white, white) repeat-x; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background: #795afa linear-gradient(180deg, #8d73fb, #795afa) repeat-x;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-moz-range-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(121, 90, 250, 0.25); }
    .custom-range::-moz-range-thumb:active {
      background: white linear-gradient(180deg, white, white) repeat-x; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    background: #795afa linear-gradient(180deg, #8d73fb, #795afa) repeat-x;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-ms-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(121, 90, 250, 0.25); }
    .custom-range::-ms-thumb:active {
      background: white linear-gradient(180deg, white, white) repeat-x; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #795afa; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #795afa;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #3d10f8;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #795afa;
  border-color: #795afa; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #795afa; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #5129f9; }

.badge-secondary {
  color: #fff;
  background-color: #fc5c7d; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #fb2a55; }

.badge-success {
  color: #fff;
  background-color: #45b649; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #37913a; }

.badge-info {
  color: #fff;
  background-color: #00c9ff; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #00a1cc; }

.badge-warning {
  color: #212529;
  background-color: #ffd700; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #ccac00; }

.badge-danger {
  color: #fff;
  background-color: #e40061; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #b1004b; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.badge-coffee {
  color: #fff;
  background-color: #3d3d3d; }
  .badge-coffee[href]:hover, .badge-coffee[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #242424; }

.badge-blue {
  color: #fff;
  background-color: #795afa; }
  .badge-blue[href]:hover, .badge-blue[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #5129f9; }

.badge-dark-blue {
  color: #fff;
  background-color: #224b8b; }
  .badge-dark-blue[href]:hover, .badge-dark-blue[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #183562; }

.badge-roll-over {
  color: #fff;
  background-color: #4324c7; }
  .badge-roll-over[href]:hover, .badge-roll-over[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #341c9c; }

.badge-gray {
  color: #212529;
  background-color: #c2d4e0; }
  .badge-gray[href]:hover, .badge-gray[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #a0bccf; }

.badge-focus-box-shadow {
  color: #fff;
  background-color: rgba(121, 90, 250, 0.25); }
  .badge-focus-box-shadow[href]:hover, .badge-focus-box-shadow[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: rgba(81, 41, 249, 0.25); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #3f2f82;
  background: #e4defe linear-gradient(180deg, #e8e3fe, #e4defe) repeat-x;
  border-color: #d9d1fe; }
  .alert-primary hr {
    border-top-color: #c4b8fd; }
  .alert-primary .alert-link {
    color: #2d215d; }

.alert-secondary {
  color: #833041;
  background: #fedee5 linear-gradient(180deg, #fee3e9, #fedee5) repeat-x;
  border-color: #fed1db; }
  .alert-secondary hr {
    border-top-color: #fdb8c7; }
  .alert-secondary .alert-link {
    color: #5e222e; }

.alert-success {
  color: #245f26;
  background: #daf0db linear-gradient(180deg, #e0f2e0, #daf0db) repeat-x;
  border-color: #cbebcc; }
  .alert-success hr {
    border-top-color: #b9e4ba; }
  .alert-success .alert-link {
    color: #163a17; }

.alert-info {
  color: #006985;
  background: #ccf4ff linear-gradient(180deg, #d4f6ff, #ccf4ff) repeat-x;
  border-color: #b8f0ff; }
  .alert-info hr {
    border-top-color: #9febff; }
  .alert-info .alert-link {
    color: #004152; }

.alert-warning {
  color: #857000;
  background: #fff7cc linear-gradient(180deg, #fff8d4, #fff7cc) repeat-x;
  border-color: #fff4b8; }
  .alert-warning hr {
    border-top-color: #fff09f; }
  .alert-warning .alert-link {
    color: #524500; }

.alert-danger {
  color: #770032;
  background: #faccdf linear-gradient(180deg, #fbd4e4, #faccdf) repeat-x;
  border-color: #f7b8d3; }
  .alert-danger hr {
    border-top-color: #f4a1c5; }
  .alert-danger .alert-link {
    color: #44001d; }

.alert-light {
  color: #818182;
  background: #fefefe linear-gradient(180deg, #fefefe, #fefefe) repeat-x;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background: #d6d8d9 linear-gradient(180deg, #dcdedf, #d6d8d9) repeat-x;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.alert-coffee {
  color: #202020;
  background: #d8d8d8 linear-gradient(180deg, #dedede, #d8d8d8) repeat-x;
  border-color: #c9c9c9; }
  .alert-coffee hr {
    border-top-color: #bcbcbc; }
  .alert-coffee .alert-link {
    color: #070707; }

.alert-blue {
  color: #3f2f82;
  background: #e4defe linear-gradient(180deg, #e8e3fe, #e4defe) repeat-x;
  border-color: #d9d1fe; }
  .alert-blue hr {
    border-top-color: #c4b8fd; }
  .alert-blue .alert-link {
    color: #2d215d; }

.alert-dark-blue {
  color: #122748;
  background: #d3dbe8 linear-gradient(180deg, #dae0eb, #d3dbe8) repeat-x;
  border-color: #c1cddf; }
  .alert-dark-blue hr {
    border-top-color: #b0bfd6; }
  .alert-dark-blue .alert-link {
    color: #08111f; }

.alert-roll-over {
  color: #231367;
  background: #d9d3f4 linear-gradient(180deg, #dfdaf6, #d9d3f4) repeat-x;
  border-color: #cac2ef; }
  .alert-roll-over hr {
    border-top-color: #b8aeea; }
  .alert-roll-over .alert-link {
    color: #140b3c; }

.alert-gray {
  color: #656e74;
  background: #f3f6f9 linear-gradient(180deg, #f5f7fa, #f3f6f9) repeat-x;
  border-color: #eef3f6; }
  .alert-gray hr {
    border-top-color: #dde7ed; }
  .alert-gray .alert-link {
    color: #4d5459; }

.alert-focus-box-shadow {
  color: rgba(16, 12, 34, 0.61);
  background: rgba(250, 249, 255, 0.85) linear-gradient(180deg, rgba(251, 250, 255, 0.8725), rgba(250, 249, 255, 0.85)) repeat-x;
  border-color: rgba(248, 246, 255, 0.79); }
  .alert-focus-box-shadow hr {
    border-top-color: rgba(228, 221, 255, 0.79); }
  .alert-focus-box-shadow .alert-link {
    color: rgba(0, 0, 0, 0.61); }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #795afa;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #795afa;
    border-color: #795afa; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #3f2f82;
  background-color: #d9d1fe; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #3f2f82;
    background-color: #c4b8fd; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #3f2f82;
    border-color: #3f2f82; }

.list-group-item-secondary {
  color: #833041;
  background-color: #fed1db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #833041;
    background-color: #fdb8c7; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #833041;
    border-color: #833041; }

.list-group-item-success {
  color: #245f26;
  background-color: #cbebcc; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #245f26;
    background-color: #b9e4ba; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #245f26;
    border-color: #245f26; }

.list-group-item-info {
  color: #006985;
  background-color: #b8f0ff; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #006985;
    background-color: #9febff; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #006985;
    border-color: #006985; }

.list-group-item-warning {
  color: #857000;
  background-color: #fff4b8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #857000;
    background-color: #fff09f; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #857000;
    border-color: #857000; }

.list-group-item-danger {
  color: #770032;
  background-color: #f7b8d3; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #770032;
    background-color: #f4a1c5; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #770032;
    border-color: #770032; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.list-group-item-coffee {
  color: #202020;
  background-color: #c9c9c9; }
  .list-group-item-coffee.list-group-item-action:hover, .list-group-item-coffee.list-group-item-action:focus {
    color: #202020;
    background-color: #bcbcbc; }
  .list-group-item-coffee.list-group-item-action.active {
    color: #fff;
    background-color: #202020;
    border-color: #202020; }

.list-group-item-blue {
  color: #3f2f82;
  background-color: #d9d1fe; }
  .list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
    color: #3f2f82;
    background-color: #c4b8fd; }
  .list-group-item-blue.list-group-item-action.active {
    color: #fff;
    background-color: #3f2f82;
    border-color: #3f2f82; }

.list-group-item-dark-blue {
  color: #122748;
  background-color: #c1cddf; }
  .list-group-item-dark-blue.list-group-item-action:hover, .list-group-item-dark-blue.list-group-item-action:focus {
    color: #122748;
    background-color: #b0bfd6; }
  .list-group-item-dark-blue.list-group-item-action.active {
    color: #fff;
    background-color: #122748;
    border-color: #122748; }

.list-group-item-roll-over {
  color: #231367;
  background-color: #cac2ef; }
  .list-group-item-roll-over.list-group-item-action:hover, .list-group-item-roll-over.list-group-item-action:focus {
    color: #231367;
    background-color: #b8aeea; }
  .list-group-item-roll-over.list-group-item-action.active {
    color: #fff;
    background-color: #231367;
    border-color: #231367; }

.list-group-item-gray {
  color: #656e74;
  background-color: #eef3f6; }
  .list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
    color: #656e74;
    background-color: #dde7ed; }
  .list-group-item-gray.list-group-item-action.active {
    color: #fff;
    background-color: #656e74;
    border-color: #656e74; }

.list-group-item-focus-box-shadow {
  color: rgba(16, 12, 34, 0.61);
  background-color: rgba(248, 246, 255, 0.79); }
  .list-group-item-focus-box-shadow.list-group-item-action:hover, .list-group-item-focus-box-shadow.list-group-item-action:focus {
    color: rgba(16, 12, 34, 0.61);
    background-color: rgba(228, 221, 255, 0.79); }
  .list-group-item-focus-box-shadow.list-group-item-action.active {
    color: #fff;
    background-color: rgba(16, 12, 34, 0.61);
    border-color: rgba(16, 12, 34, 0.61); }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
            transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next,
    .active.carousel-item-right {
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-prev,
    .active.carousel-item-left {
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001)); }

.carousel-control-next {
  right: 0;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001)); }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #795afa !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #5129f9 !important; }

.bg-secondary {
  background-color: #fc5c7d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #fb2a55 !important; }

.bg-success {
  background-color: #45b649 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #37913a !important; }

.bg-info {
  background-color: #00c9ff !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #00a1cc !important; }

.bg-warning {
  background-color: #ffd700 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ccac00 !important; }

.bg-danger {
  background-color: #e40061 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #b1004b !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-coffee {
  background-color: #3d3d3d !important; }

a.bg-coffee:hover, a.bg-coffee:focus,
button.bg-coffee:hover,
button.bg-coffee:focus {
  background-color: #242424 !important; }

.bg-blue {
  background-color: #795afa !important; }

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #5129f9 !important; }

.bg-dark-blue {
  background-color: #224b8b !important; }

a.bg-dark-blue:hover, a.bg-dark-blue:focus,
button.bg-dark-blue:hover,
button.bg-dark-blue:focus {
  background-color: #183562 !important; }

.bg-roll-over {
  background-color: #4324c7 !important; }

a.bg-roll-over:hover, a.bg-roll-over:focus,
button.bg-roll-over:hover,
button.bg-roll-over:focus {
  background-color: #341c9c !important; }

.bg-gray {
  background-color: #c2d4e0 !important; }

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #a0bccf !important; }

.bg-focus-box-shadow {
  background-color: rgba(121, 90, 250, 0.25) !important; }

a.bg-focus-box-shadow:hover, a.bg-focus-box-shadow:focus,
button.bg-focus-box-shadow:hover,
button.bg-focus-box-shadow:focus {
  background-color: rgba(81, 41, 249, 0.25) !important; }

.bg-gradient-primary {
  background: #795afa linear-gradient(180deg, #8d73fb, #795afa) repeat-x !important; }

.bg-gradient-secondary {
  background: #fc5c7d linear-gradient(180deg, #fc7491, #fc5c7d) repeat-x !important; }

.bg-gradient-success {
  background: #45b649 linear-gradient(180deg, #61c164, #45b649) repeat-x !important; }

.bg-gradient-info {
  background: #00c9ff linear-gradient(180deg, #26d1ff, #00c9ff) repeat-x !important; }

.bg-gradient-warning {
  background: #ffd700 linear-gradient(180deg, #ffdd26, #ffd700) repeat-x !important; }

.bg-gradient-danger {
  background: #e40061 linear-gradient(180deg, #e82679, #e40061) repeat-x !important; }

.bg-gradient-light {
  background: #f8f9fa linear-gradient(180deg, #f9fafb, #f8f9fa) repeat-x !important; }

.bg-gradient-dark {
  background: #343a40 linear-gradient(180deg, #52585d, #343a40) repeat-x !important; }

.bg-gradient-coffee {
  background: #3d3d3d linear-gradient(180deg, #5a5a5a, #3d3d3d) repeat-x !important; }

.bg-gradient-blue {
  background: #795afa linear-gradient(180deg, #8d73fb, #795afa) repeat-x !important; }

.bg-gradient-dark-blue {
  background: #224b8b linear-gradient(180deg, #43669c, #224b8b) repeat-x !important; }

.bg-gradient-roll-over {
  background: #4324c7 linear-gradient(180deg, #5f45cf, #4324c7) repeat-x !important; }

.bg-gradient-gray {
  background: #c2d4e0 linear-gradient(180deg, #cbdae5, #c2d4e0) repeat-x !important; }

.bg-gradient-focus-box-shadow {
  background: rgba(121, 90, 250, 0.25) linear-gradient(180deg, rgba(195, 181, 253, 0.3625), rgba(121, 90, 250, 0.25)) repeat-x !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #795afa !important; }

.border-secondary {
  border-color: #fc5c7d !important; }

.border-success {
  border-color: #45b649 !important; }

.border-info {
  border-color: #00c9ff !important; }

.border-warning {
  border-color: #ffd700 !important; }

.border-danger {
  border-color: #e40061 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-coffee {
  border-color: #3d3d3d !important; }

.border-blue {
  border-color: #795afa !important; }

.border-dark-blue {
  border-color: #224b8b !important; }

.border-roll-over {
  border-color: #4324c7 !important; }

.border-gray {
  border-color: #c2d4e0 !important; }

.border-focus-box-shadow {
  border-color: rgba(121, 90, 250, 0.25) !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #795afa !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #5129f9 !important; }

.text-secondary {
  color: #fc5c7d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #fb2a55 !important; }

.text-success {
  color: #45b649 !important; }

a.text-success:hover, a.text-success:focus {
  color: #37913a !important; }

.text-info {
  color: #00c9ff !important; }

a.text-info:hover, a.text-info:focus {
  color: #00a1cc !important; }

.text-warning {
  color: #ffd700 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ccac00 !important; }

.text-danger {
  color: #e40061 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #b1004b !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-coffee {
  color: #3d3d3d !important; }

a.text-coffee:hover, a.text-coffee:focus {
  color: #242424 !important; }

.text-blue {
  color: #795afa !important; }

a.text-blue:hover, a.text-blue:focus {
  color: #5129f9 !important; }

.text-dark-blue {
  color: #224b8b !important; }

a.text-dark-blue:hover, a.text-dark-blue:focus {
  color: #183562 !important; }

.text-roll-over {
  color: #4324c7 !important; }

a.text-roll-over:hover, a.text-roll-over:focus {
  color: #341c9c !important; }

.text-gray {
  color: #c2d4e0 !important; }

a.text-gray:hover, a.text-gray:focus {
  color: #a0bccf !important; }

.text-focus-box-shadow {
  color: rgba(121, 90, 250, 0.25) !important; }

a.text-focus-box-shadow:hover, a.text-focus-box-shadow:focus {
  color: rgba(81, 41, 249, 0.25) !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.Cal__Day__root {
  display: inline-block;
  box-sizing: border-box;
  width: 14.28571%;
  list-style: none;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none; }

.Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted, .Cal__Day__root.Cal__Day__enabled:active, .Cal__Day__root.Cal__Day__enabled:hover {
  position: relative;
  z-index: 1; }

.Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before, .Cal__Day__root.Cal__Day__enabled:active:before, .Cal__Day__root.Cal__Day__enabled:hover:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 52px;
  height: 52px;
  margin-top: -26px;
  margin-left: -26px;
  border-radius: 50%;
  background-color: #EFEFEF;
  z-index: -1; }

.Cal__Day__root.Cal__Day__enabled:hover:before {
  opacity: 0.5; }

.Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before, .Cal__Day__root.Cal__Day__enabled:active:before {
  opacity: 1; }

.Cal__Day__root:first-child {
  position: relative; }

.Cal__Day__root.Cal__Day__today {
  position: relative;
  z-index: 2; }

.Cal__Day__root.Cal__Day__today > span {
  color: #3d3d3d; }

.Cal__Day__root.Cal__Day__today.Cal__Day__disabled > span {
  color: #AAA; }

.Cal__Day__root.Cal__Day__today:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 52px;
  height: 52px;
  margin-top: -26px;
  margin-left: -26px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px;
  z-index: -1; }

.Cal__Day__root.Cal__Day__today.Cal__Day__disabled:before {
  box-shadow: inset 0 0 0 1px #BBB; }

.Cal__Day__root.Cal__Day__selected {
  position: relative; }

.Cal__Day__root.Cal__Day__selected > .Cal__Day__month, .Cal__Day__root.Cal__Day__selected > .Cal__Day__year {
  display: none; }

.Cal__Day__root.Cal__Day__selected:before {
  display: none; }

.Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 52px;
  height: 52px;
  margin-top: -26px;
  margin-left: -26px;
  border-radius: 50%;
  line-height: 56px;
  z-index: 2; }

.Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__month {
  top: 9px; }

.Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__day {
  position: relative;
  top: 5px;
  font-weight: bold;
  font-size: 18px; }

.Cal__Day__root.Cal__Day__disabled {
  color: #AAA;
  cursor: not-allowed; }

.Cal__Day__month, .Cal__Day__year {
  position: absolute;
  left: 0;
  right: 0;
  font-size: 12px;
  line-height: 12px;
  text-transform: capitalize; }

.Cal__Day__month {
  top: 5px; }

.Cal__Day__year {
  bottom: 5px; }

/*
 * Range selection styles
 */
.Cal__Day__range.Cal__Day__selected.Cal__Day__start:after, .Cal__Day__range.Cal__Day__selected.Cal__Day__end:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 50%;
  height: 52px;
  margin-top: -26px;
  box-shadow: inset 56px 56px; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__disabled .Cal__Day__selection.Cal__Day__selection {
  background-color: #EEE !important; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__disabled .Cal__Day__selection.Cal__Day__selection .Cal__Day__day, .Cal__Day__range.Cal__Day__selected.Cal__Day__disabled .Cal__Day__selection.Cal__Day__selection .Cal__Day__month {
  color: #AAA;
  font-weight: 300; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__start .Cal__Day__selection {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__start:after {
  right: 0; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__start.Cal__Day__end:after {
  display: none; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange .Cal__Day__selection {
  left: 0;
  right: 0;
  width: 100%;
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange .Cal__Day__day {
  top: 0;
  font-size: 16px; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange .Cal__Day__month {
  display: none; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__end:after {
  left: 0; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  color: inherit !important;
  background-color: #FFF !important;
  border: 2px solid;
  box-sizing: border-box; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection .Cal__Day__day {
  top: 4px; }

.Cal__Container__root {
  position: relative;
  display: table;
  z-index: 1;
  font: 14px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.4em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  outline: none;
  text-align: left; }

.Cal__Container__root.Cal__Container__landscape {
  display: flex;
  flex-direction: row; }

.Cal__Container__root.Cal__Container__landscape .Cal__Container__wrapper {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  z-index: 1;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.Cal__Container__listWrapper {
  position: relative;
  overflow: hidden;
  background-color: #FFF; }

.Cal__Header__root {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  min-height: 98px;
  padding: 20px;
  line-height: 1.3;
  font-weight: 400;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.Cal__Header__root.Cal__Header__landscape {
  align-items: flex-start;
  min-width: 200px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 3px; }

.Cal__Header__root.Cal__Header__landscape .Cal__Header__dateWrapper.Cal__Header__day {
  flex-grow: 1;
  height: 76px; }

.Cal__Header__wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  cursor: pointer; }

.Cal__Header__wrapper.Cal__Header__blank {
  height: 58px;
  line-height: 58px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  cursor: default; }

.Cal__Header__dateWrapper {
  position: relative;
  display: block;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.3s ease; }

.Cal__Header__dateWrapper.Cal__Header__active {
  color: white; }

.Cal__Header__dateWrapper.Cal__Header__day {
  height: 38px;
  font-size: 36px;
  line-height: 36px;
  text-transform: capitalize; }

.Cal__Header__dateWrapper.Cal__Header__year {
  height: 20px;
  font-size: 18px;
  line-height: 18px; }

.Cal__Header__date {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.Cal__Header__range {
  display: flex;
  flex-grow: 1; }

.Cal__Header__range .Cal__Header__dateWrapper {
  overflow: visible; }

.Cal__Header__range .Cal__Header__wrapper:first-child:before, .Cal__Header__range .Cal__Header__wrapper:first-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  margin-top: -50px;
  margin-left: -50px;
  border-top: 100px solid transparent;
  border-bottom: 100px solid transparent;
  border-left: 60px solid; }

.Cal__Header__range .Cal__Header__wrapper:first-child:before {
  color: rgba(255, 255, 255, 0.15);
  -webkit-transform: translateX(1px);
          transform: translateX(1px); }

.Cal__Header__range .Cal__Header__wrapper:last-child {
  margin-left: 60px; }

.Cal__Header__range .Cal__Header__wrapper .Cal__Header__date {
  white-space: nowrap;
  z-index: 1; }

.Cal__Today__root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 6px;
  box-sizing: border-box;
  -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
  font-weight: 500;
  line-height: 0;
  z-index: 10;
  cursor: pointer;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  transition-delay: 0.3s; }

.Cal__Today__root.Cal__Today__show {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition-delay: 0s; }

.Cal__Today__root.Cal__Today__show .Cal__Today__chevron {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }

.Cal__Today__root .Cal__Today__chevron {
  position: absolute;
  top: 50%;
  margin-top: -6px;
  margin-left: 5px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }

.Cal__Today__root.Cal__Today__chevronUp .Cal__Today__chevron {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.Cal__Today__root.Cal__Today__chevronDown .Cal__Today__chevron {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg); }

.Cal__MonthList__root {
  width: 100% !important;
  background-color: #FFF;
  -webkit-overflow-scrolling: touch; }

.Cal__MonthList__root.Cal__MonthList__scrolling > div {
  pointer-events: none; }

.Cal__MonthList__root.Cal__MonthList__scrolling label {
  opacity: 1; }

.Cal__Weekdays__root {
  position: relative;
  z-index: 5;
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.04); }

.Cal__Weekdays__day {
  padding: 15px 0;
  flex-basis: 14.28571%;
  flex-grow: 1;
  font-weight: 500;
  text-align: center; }

.Cal__Years__root {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F9F9F9; }

.Cal__Years__root:before, .Cal__Years__root:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 50px;
  pointer-events: none;
  z-index: 1; }

.Cal__Years__root:before {
  top: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%); }

.Cal__Years__root:after {
  bottom: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%); }

.Cal__Years__list {
  box-sizing: border-box; }

.Cal__Years__list.Cal__Years__center {
  display: flex;
  align-items: center;
  justify-content: center; }

.Cal__Years__year {
  display: flex;
  padding: 0 20px;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  box-sizing: border-box; }

.Cal__Years__year.Cal__Years__withMonths {
  border-bottom: 1px solid #E9E9E9; }

.Cal__Years__year.Cal__Years__withMonths label {
  height: 88px;
  padding-top: 12px;
  box-sizing: border-box; }

.Cal__Years__year label {
  flex-basis: 42%; }

.Cal__Years__year label span {
  flex-shrink: 0;
  color: #333; }

.Cal__Years__year ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  font-size: 14px; }

.Cal__Years__year ol li {
  display: flex;
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  list-style: none;
  border-radius: 50%;
  box-sizing: border-box;
  color: #444;
  font-weight: 400;
  text-transform: capitalize; }

.Cal__Years__year ol li.Cal__Years__currentMonth {
  border: 1px solid; }

.Cal__Years__year ol li.Cal__Years__selected {
  position: relative;
  z-index: 1;
  background-color: blue;
  color: #FFF !important;
  border: 0; }

.Cal__Years__year ol li.Cal__Years__disabled {
  cursor: not-allowed;
  color: #AAA; }

.Cal__Years__year ol li.Cal__Years__disabled:hover {
  background-color: inherit; }

.Cal__Years__year ol li:hover {
  background-color: #EFEFEF; }

.Cal__Years__year:hover label > span, .Cal__Years__year.Cal__Years__active label > span {
  color: inherit; }

.Cal__Years__year:hover, .Cal__Years__year.Cal__Years__active {
  position: relative;
  z-index: 2; }

.Cal__Years__year.Cal__Years__active {
  font-size: 32px; }

.Cal__Years__year.Cal__Years__currentYear {
  position: relative; }

.Cal__Years__year.Cal__Years__currentYear label > span {
  min-width: 50px;
  padding-bottom: 5px;
  border-bottom: 3px solid; }

.Cal__Years__year.Cal__Years__currentYear.Cal__Years__active label > span {
  min-width: 85px; }

.Cal__Years__year.Cal__Years__first {
  padding-top: 40px; }

.Cal__Years__year.Cal__Years__last {
  padding-bottom: 40px; }

.Cal__Animation__enter {
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
  transition: 0.25s ease; }

.Cal__Animation__enter.Cal__Animation__enterActive {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

.Cal__Animation__leave {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition: 0.25s ease; }

.Cal__Animation__leave.Cal__Animation__leaveActive {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
          transform: translate3d(0, 100%, 0); }

.Cal__Slider__root, .Cal__Slider__slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.Cal__Slider__root {
  overflow: hidden; }

.Cal__Slider__slide {
  padding: 20px 65px; }

.Cal__Slider__slide:first-child {
  padding-left: 20px; }

.Cal__Slider__wrapper {
  height: 100%;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }

.Cal__Slider__arrow {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  opacity: 0.7;
  cursor: pointer;
  border-left: 1px solid rgba(255, 255, 255, 0.1); }

.Cal__Slider__arrow svg {
  width: 15px; }

.Cal__Slider__arrow:hover {
  opacity: 1; }

.Cal__Slider__arrowRight {
  right: 0; }

.Cal__Slider__arrowLeft {
  left: 0;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1); }

.Cal__transition__enter {
  opacity: 0; }

.Cal__transition__enterActive {
  opacity: 1;
  transition: opacity 0.3s ease; }

.Cal__transition__leave {
  opacity: 1; }

.Cal__transition__leaveActive {
  opacity: 0;
  transition: opacity 0.3s ease; }

.Cal__Month__rows {
  position: relative;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.05) 100%); }

.Cal__Month__row {
  padding: 0;
  margin: 0; }

.Cal__Month__row:first-child {
  text-align: right; }

.Cal__Month__row:first-child li {
  background-color: #FFF;
  box-shadow: 0 -1px 0 #E9E9E9; }

.Cal__Month__row:nth-child(2) {
  box-shadow: 0 -1px 0 #E9E9E9; }

.Cal__Month__row.Cal__Month__partial:first-child li:first-child {
  box-shadow: 0px -1px 0 #E9E9E9, inset 1px 0 0 #E9E9E9; }

.Cal__Month__row.Cal__Month__partial:last-of-type li {
  position: relative;
  z-index: 1; }

.Cal__Month__label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  font-size: 30px;
  font-weight: 500;
  z-index: 3;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease; }

.Cal__Month__label > span {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize; }

.Cal__Month__label.Cal__Month__partialFirstRow {
  top: 56px; }

/* Font Smoothing */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Mulish", sans-serif;
  font-weight: 400; }

.cr-app {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0; }

.cr-icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear; }

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

body {
  background-color: #f7fbfd;
  position: relative; }

.bg-app {
  background-color: #f7fbfd; }

.nav-item .nav-link {
  display: flex;
  align-items: center; }
  .nav-item .nav-link.active {
    color: #fff;
    background: rgba(255, 255, 255, 0.23);
    opacity: 1; }

.nav-tabs {
  border-bottom: 0px solid #dee2e6; }

.nav-tabs .nav-item {
  z-index: 9; }

.nav-tabs .nav-item .active.nav-link {
  color: black;
  font-weight: bold;
  background-color: #fff; }

.nav-tabs .nav-item .nav-link {
  cursor: pointer;
  padding: 0.2rem 0.5rem;
  font-size: 14px; }

.nav-tabs .nav-item .nav-link[disabled] {
  cursor: default; }

.sidebar .nav a {
  transition: all 150ms ease-in; }

.card-header {
  text-transform: uppercase; }

.badge:empty {
  display: inline-block; }

label {
  font-size: 12px; }

.react-select-custom-option > div {
  width: auto !important; }

input[role="combobox"] {
  padding: 12px !important; }

.autosuggest-container > div {
  display: initial !important; }

.btn {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  height: 44px; }
  @media (max-width: 768px) {
    .btn {
      font-size: 0.7rem;
      font-size: 2vw; } }

.btn.disabled {
  cursor: not-allowed; }

.tabs-icon {
  margin-right: 5px; }

.tabs-icon.star-icon {
  margin-top: -5px; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.btn-pull-left {
  border-radius: 30px;
  float: left;
  margin-bottom: 0px;
  margin-right: 10px;
  padding-right: 20px;
  padding-left: 20px; }

.form-div {
  text-align: left;
  border: 0px solid #ccc !important;
  border-width: 0px;
  padding: 0px;
  border-radius: 5px; }

.form-segment-label {
  text-align: left;
  width: 100%;
  padding-bottom: 0px;
  padding-top: 20px;
  font-size: 23px;
  font-weight: 600;
  color: #334856; }

.table-hidden-checkbox .rc-checkbox {
  opacity: 0;
  transition: opacity 0.5s ease-out; }

.table-hidden-checkbox .rc-checkbox-checked {
  opacity: 1; }

.table-hidden-checkbox .rc-checkbox:hover {
  cursor: pointer; }

.table-hidden-checkbox .rt-tr-group {
  cursor: pointer; }

.table-hidden-checkbox .rt-tr-group:hover .rc-checkbox {
  opacity: 1; }

.navbar-light .navbar-nav .nav-link {
  color: #c2d4e0; }

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #c2d4e0; }

.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none !important; }

.buttons-order-details {
  display: flex; }
  .buttons-order-details .btn {
    padding-right: 20px;
    padding-left: 20px;
    margin-right: 10px; }

.orderForm {
  display: block; }
  .orderForm .btn-group .btn {
    margin-top: 0; }
  .orderForm .btn-outline-grey.disabled {
    background: transparent; }

.order-spots-container {
  display: flex;
  align-items: center;
  justify-content: center; }
  .order-spots-container .button-add-spot {
    border-radius: 5px !important;
    padding: 10px;
    background: #795afa linear-gradient(180deg, #8d73fb, #795afa) repeat-x;
    border-color: #795afa;
    border-color: transparent; }
    .order-spots-container .button-add-spot:hover {
      background: #5b35f9 linear-gradient(180deg, #7353fa, #5b35f9) repeat-x;
      border-color: #5129f9; }
    .order-spots-container .button-add-spot span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #ffffff; }

.order-spots-buttons {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-bottom: 1px solid #224b8b;
  margin-right: 7px; }
  .order-spots-buttons .order-spot-button {
    flex: 1 1;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 5px 5px 0px 0px !important;
    background-color: #fff;
    border: 1px solid #224b8b;
    border-bottom: none;
    box-sizing: border-box;
    text-align: left;
    margin-left: 8px !important; }
    .order-spots-buttons .order-spot-button span {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #224b8b; }
  .order-spots-buttons .order-spot-button-active {
    background-color: #224b8b;
    border: 1px solid #224b8b;
    box-sizing: border-box;
    border-bottom: none; }
    .order-spots-buttons .order-spot-button-active span {
      color: #ffffff; }
  .order-spots-buttons .btn:focus {
    box-shadow: none; }
  .order-spots-buttons .btn:first-child {
    margin-left: 0 !important; }

.form-add-update-user .user-checkbox {
  display: flex;
  justify-content: flex-end;
  align-items: center; }
  .form-add-update-user .user-checkbox .cr-checkbox {
    margin-right: 5px; }

.cr-page {
  background: #f7fbfd; }

.cr-page.cr-server {
  background-color: #fff; }

.popover {
  font-family: "Mulish", sans-serif; }

.popover-body {
  padding: 0 !important; }

.cr-page.p-5 {
  padding: 40px !important; }
  @media (max-width: 768px) {
    .cr-page.p-5 {
      padding: 1rem !important; } }

.cr-page-min-500 {
  min-width: 500px;
  background: #fff; }

.col-min-500 {
  min-width: 500px;
  margin: 0 auto; }

.cr-app {
  min-width: 500px; }

.user-tag {
  text-decoration: underline; }

.custom-button-groups {
  padding: 0 30px; }

@media (min-width: 1400px) {
  .container {
    max-width: 1400px; } }

@media (min-width: 1600px) {
  .container {
    max-width: 1500px; } }

@media (min-width: 2000px) {
  .container {
    max-width: 1900px; } }

.cr-admins__tab-detail .react-select__menu {
  width: auto; }

.cr-admins__tab-detail .react-select__menu div {
  white-space: nowrap;
  display: block;
  border: 1px solid #f7f7f7;
  max-width: 400px; }

.table-no-data {
  display: block;
  /* position: absolute; */
  /* left: 50%; */
  /* top: 50%; */
  /* -webkit-transform: translate(-50%, -50%); */
  /* transform: translate(-50%, -50%); */
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  /* margin-top: 40px; */
  text-align: center; }
  .table-no-data span {
    color: #334856 !important; }

.link-subdomain {
  display: flex;
  flex: 1 1; }

.centered-bg-image {
  background-position: center center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important; }

.react-select__placeholder {
  color: #829fb1 !important; }

.auto-complete {
  display: block; }

.ReactTable .rt-thead .rt-resizable-header-content {
  text-align: left; }

.ReactTable .rt-tr-group {
  border-top: 1px solid #ecf1f7;
  border-bottom: none !important; }
  .ReactTable .rt-tr-group:hover {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.16);
    border-top-color: transparent; }

.footer-powered-by {
  padding: 0 50px;
  font-size: 16px;
  color: #829fb1; }
  .footer-powered-by a {
    font-weight: bold;
    color: #829fb1; }

.cr-loading {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999; }

.order-view-value {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%; }

.no-display {
  width: 0;
  height: 0;
  opacity: 0;
  border: 0;
  padding: 0; }

.order-view-list-item {
  display: inline-block;
  padding-right: 20px; }

.ps-view-value {
  font-size: 18px;
  margin-bottom: 0.5rem;
  display: inline-block; }

.ps-label {
  text-transform: uppercase !important;
  display: block;
  color: #537a91; }

.ps-view-list-item {
  display: inline-block;
  padding-right: 20px; }

.button-search-close {
  cursor: pointer;
  border: 1px solid;
  border-radius: 50px;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: center; }

.search-buttons {
  display: flex;
  align-items: center; }

.row-user-name {
  display: flex;
  align-items: center; }
  .row-user-name .cr-avatar {
    margin-right: 5px; }

.process-title-creative-details {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 29px;
  color: #334856;
  margin: 23px 0; }

.order-view-files span {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #334856;
  display: block;
  margin: 5px 0; }

.admin-login-loading {
  background-color: #fff; }

.process-history-item {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 12px; }

.process-history-row {
  margin-bottom: 15px; }

.col-table-10 {
  width: 10%; }

.col-table-10-i {
  width: 10% !important; }

.col-table-15 {
  width: 15%; }

.col-table-15-i {
  width: 15% !important; }

.col-table-20 {
  width: 20%; }

.col-table-20-i {
  width: 20% !important; }

.col-table-25 {
  width: 25%; }

.col-table-25-i {
  width: 25% !important; }

.col-table-30 {
  width: 30%; }

.col-table-30-i {
  width: 30% !important; }

.col-table-40 {
  width: 40%; }

.col-table-40-i {
  width: 40% !important; }

.rotation-station {
  white-space: normal;
  padding-left: 25px;
  padding-right: 10px;
  font-weight: bold;
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  border-right: 1px solid #c2d4e0;
  color: #334856;
  font-size: 16px; }

.rotation-trash {
  padding-right: 10px;
  cursor: pointer; }

.rotation-table tr {
  height: 50px; }

.rotation-table {
  display: block;
  border: 1px solid #c2d4e0;
  border-radius: 0.25rem; }

.copy-points-creative {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #111b2b; }

.user-name-column {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .user-name-column .cr-avatar {
    margin-right: 10px; }

.table-station-name {
  white-space: nowrap;
  padding-right: 10px;
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #111b2b; }

.cr-process-list__table th:first-child:not(.text-center),
.cr-process-list__table td:first-child:not(.text-center) {
  text-align: left !important; }

.cr-process-list__table th:first-child .cr-process-list__item-sortable {
  display: inline-flex;
  white-space: nowrap; }

.cr-process-list__table.produced_digitals th.can-click.client_name {
  text-align: left !important;
  padding-left: 20px; }

.cr-process-list__table.produced_digitals th.can-click.align-middle {
  text-align: center !important; }

.cr-process-list__table.produced_digitals th.can-click.align-middle .cr-process-list__item-sortable {
  justify-content: center; }

.cr-process-list__table.produced_digitals td.cr-process-list__client-name {
  text-align: left !important;
  padding-left: 14px; }

.cr-process-list__table.produced_digitals td .online {
  color: #1c6fec; }

.btn-outline-blue,
.btn-outline-primary {
  border: 1px solid #acc4d4;
  background: #fff !important; }
  .btn-outline-blue:hover,
  .btn-outline-primary:hover {
    color: #4324c7;
    border: 1px solid #4324c7;
    background: #fff !important; }

.btn-outline-none {
  border-width: 0 !important; }

.tab-assign-team > div {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0; }

.assigned-great-modal .modal-header {
  margin-bottom: 0 !important; }

.assigned-great-modal .modal-body {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  margin-top: 10px;
  color: #000000;
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 15%; }

.fullDate {
  position: relative; }

.datePickerIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer; }

.pointer {
  cursor: pointer; }

.head-admin-list-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px; }
  .head-admin-list-wrapper .create-station {
    margin-bottom: 0 !important; }

.select-stations-hide-value .react-select__multi-value {
  display: none; }

.select-stations-hide-value .react-select__clear-indicator {
  display: none; }

.button-edit-prize {
  padding-left: 49px; }

.order-stations-view {
  display: flex;
  flex-wrap: wrap; }
  .order-stations-view span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #657894;
    text-transform: uppercase; }
  .order-stations-view .order-station-item {
    background: #f7fbfd;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 13.36px;
    margin-bottom: 15px;
    height: 42px;
    padding: 0 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center; }

.cr-admins__tab-detail .btn {
  min-width: auto !important; }

.cr-page-admins-roles {
  background-color: #f7fbfd !important; }

.header-display-settings {
  display: none; }

.cr-page-jock-console {
  overflow-y: hidden !important; }
  .cr-page-jock-console .header-display-settings {
    display: inline-flex; }

.file-value {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #657894;
  margin-bottom: 5px;
  word-break: break-all; }

.cr-icon-spin-container {
  display: flex;
  align-items: center;
  justify-content: center; }

.react-datepicker-popper {
  z-index: 100; }

.modal-open.date-picker-open .react-datepicker-popper {
  z-index: 1051; }

.alert-danger,
.text-danger {
  background: rgba(246, 44, 0, 0.14);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #e40061;
  margin-top: 5px; }

.text-danger {
  display: inline-block; }

.message-no-upload {
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  top: -5px !important; }
  .bs-tooltip-top .tooltip-inner, .bs-tooltip-auto[x-placement^="top"] .tooltip-inner {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
            transform: matrix(-1, 0, 0, 1, 0, 0);
    padding: 8px 15px;
    width: 216px;
    max-width: auto; }
    .bs-tooltip-top .tooltip-inner span, .bs-tooltip-auto[x-placement^="top"] .tooltip-inner span {
      -webkit-transform: inherit;
              transform: inherit;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      display: block; }
  .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before,
  .bs-tooltip-top .bs-tooltip-auto[x-placement^="top"] .arrow::before,
  .bs-tooltip-auto[x-placement^="top"] .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff; }

.react-select__indicator-separator {
  margin: 0 !important; }

.btn-blue {
  background: #795afa; }
  .btn-blue:hover {
    background: #4324c7; }

.btn-disabled {
  opacity: 0.6;
  cursor: not-allowed !important; }

.btn-green {
  background: #73a703;
  color: #ffffff; }
  .btn-green:hover {
    background: #86bf0b;
    color: #ffffff; }
  .btn-green:focus {
    box-shadow: 0 0 0.2rem rgba(115, 167, 3, 0.25); }

.btn-primary {
  background: #795afa; }
  .btn-primary:hover {
    background: #4324c7; }

.text-danger-line {
  display: block; }

.scroll-bar-style::-webkit-scrollbar {
  width: 8px;
  height: 8px; }

.scroll-bar-style::-webkit-scrollbar-thumb {
  background: #c2d4e0;
  border-radius: 6px; }

.scroll-bar-style::-webkit-scrollbar-thumb:hover {
  background: #c2d4e0; }

.scroll-bar-style::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 6px; }

.cr-app {
  position: fixed;
  overflow-y: scroll; }

.RichEditor-root .RichEditor-tags-label {
  text-transform: uppercase;
  font-size: 12px;
  color: #657894; }

.RichEditor-root .RichEditor-tags-text {
  padding: 4px 5px;
  margin: 0 5px;
  background: #829fb1;
  border: 1px solid #657894;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer; }

.RichEditor-root .RichEditor-tags-modal {
  background: #657894;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 50px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  padding: 5px 16px;
  cursor: pointer;
  text-transform: capitalize;
  margin: 0 15px; }

.RichEditor-root .RichEditor-set-as-default {
  font-size: 14px;
  line-height: 18px;
  color: #795afa;
  position: absolute;
  right: 24px;
  cursor: pointer; }

.dynamic-text.fs-16 {
  font-size: 16px; }

.dynamic-text.fs-17 {
  font-size: 17px; }

.dynamic-text.fs-18 {
  font-size: 18px; }

.dynamic-text.fs-19 {
  font-size: 19px; }

.dynamic-text.fs-20 {
  font-size: 20px; }

.dynamic-text.fs-21 {
  font-size: 21px; }

.dynamic-text.fs-22 {
  font-size: 22px; }

.dynamic-text.fs-23 {
  font-size: 23px; }

.dynamic-text.fs-24 {
  font-size: 24px; }

.dynamic-text.fs-25 {
  font-size: 25px; }

.dynamic-text.fs-26 {
  font-size: 26px; }

.dynamic-text.fs-27 {
  font-size: 27px; }

.dynamic-text.fs-28 {
  font-size: 28px; }

.dynamic-text.fs-29 {
  font-size: 29px; }

.dynamic-text.fs-30 {
  font-size: 30px; }

.dynamic-text.fs-31 {
  font-size: 31px; }

.dynamic-text.fs-32 {
  font-size: 32px; }

.dynamic-text.fs-33 {
  font-size: 33px; }

.dynamic-text.fs-34 {
  font-size: 34px; }

.dynamic-text.fs-35 {
  font-size: 35px; }

.dynamic-text.fs-36 {
  font-size: 36px; }

.dynamic-text.fs-37 {
  font-size: 37px; }

.dynamic-text.fs-38 {
  font-size: 38px; }

.dynamic-text.fs-39 {
  font-size: 39px; }

.dynamic-text.fs-40 {
  font-size: 40px; }

.dynamic-text.fs-41 {
  font-size: 41px; }

.dynamic-text.fs-42 {
  font-size: 42px; }

.dynamic-text.case-capitalize {
  text-transform: capitalize; }

.dynamic-text.case-uppercase {
  text-transform: uppercase; }

.dynamic-text.case-lowercase {
  text-transform: lowercase; }

.dynamic-text {
  color: #000000;
  font-family: "Mulish";
  font-size: 20px; }
  .dynamic-text.font-sans-serif {
    font-family: sans-serif; }
  .dynamic-text.font-mulish {
    font-family: "Mulish", sans-serif; }
  .dynamic-text.font-lora {
    font-family: "Lora", serif; }

.cr-spinner-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  display: flex;
  cursor: default; }

.btn-impersonal {
  height: auto;
  padding: 3px 0px;
  float: right;
  margin-left: 20px; }

.avatar-impersonal {
  margin-right: 10px !important; }

.upload-file-buttons {
  display: flex;
  margin-top: 4px;
  grid-gap: 10px;
  gap: 10px; }
  .upload-file-buttons .btn-link {
    min-width: initial !important; }
    .upload-file-buttons .btn-link:hover {
      text-decoration: none; }

.btn-link:disabled, .btn-link.disabled {
  color: #bccedb;
  opacity: 1; }

.form-control:disabled {
  color: #829fb1 !important; }

@media (min-width: 1599px) {
  .footer-powered-by {
    padding: 0 70px; } }

.cr-admins__tab-detail .cr-email-configurations__save-button.btn {
  min-width: 150px !important;
  margin-top: 20px; }

.servers-actions {
  width: 154px; }

.text-area-view {
  border: none;
  background-color: transparent;
  resize: none;
  padding: 0;
  font-size: 16px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; }

#cr-app-main.right-sidebar-open {
  overflow-y: hidden; }

.body-right-sidebar-open .cr-add-update-process__tab-content-container {
  width: calc(100% + 422px); }

.body-right-sidebar-open .cr-process-list__process-list-container {
  width: calc( 100% + 594px); }
  .body-right-sidebar-open .cr-process-list__process-list-container .cr-production-manager-queue__table {
    max-width: 1400px; }

.body-right-sidebar-open .cr-app {
  overflow-x: scroll; }

.body-right-sidebar-open .bottom-actions-sticky,
.body-right-sidebar-open .process-bottom-buttons {
  bottom: 8px; }

.body-right-sidebar-open .cr-comments-panel__wrapper {
  height: calc(100% - 73px); }

@-moz-document url-prefix() {
  .body-right-sidebar-open .cr-comments-panel__wrapper {
    height: calc(100% - 63px); }
  .body-right-sidebar-open .bottom-actions-sticky,
  .body-right-sidebar-open .process-bottom-buttons {
    bottom: 0px; } }

.logout-loading {
  background-color: #fff; }

.selected-station-item {
  display: inline-block; }

.card-img-left {
  border-radius: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-img-right {
  border-radius: 0;
  border-top-right-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px); }

input[role="combobox"] {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.menu {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #cccccc;
  background-color: #fff;
  z-index: 99; }

.item {
  padding: 2px 6px;
  cursor: default; }

.item-highlighted {
  color: white;
  background-color: #4095bf; }

.item-header {
  background-color: #eeeeee;
  color: #454545;
  font-weight: bold; }

.expand-icon_nobg {
  margin-left: 2px;
  z-index: 3;
  color: #fff;
  margin-bottom: 2px; }

.expand-icon {
  margin-left: 5px;
  z-index: 3; }

.rc-slider-mark {
  top: -40px !important; }

.rc-slider-dot {
  display: none; }

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
  visibility: hidden; }

.player-container-ui .rc-slider-tooltip-inner {
  width: 50px;
  height: 50px;
  background-color: transparent;
  display: inline-block;
  cursor: pointer;
  visibility: visible;
  box-shadow: none; }

.tooltip-user {
  width: 50px;
  height: 77px;
  position: absolute;
  background-position: center;
  background-size: contain;
  top: -23px;
  background-repeat: no-repeat; }

.rc-slider-tooltip-user-avatar {
  width: 30px;
  cursor: pointer;
  height: 30px;
  border-radius: 100px;
  overflow: hidden;
  margin-top: 14px;
  margin-right: 2px; }

.rc-slider-tooltip-message {
  font-size: 14px;
  line-height: 1;
  color: #fff;
  transition: opacity 0.2 ease-out;
  display: inline-block; }

.rc-slider-tooltip-user-name {
  font-size: 15px;
  font-weight: 600;
  color: #ffffff;
  background: #0f55b9;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  margin-top: 14px;
  margin-right: 2px;
  overflow: hidden; }

.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
  max-width: 300px; }

.btn-search {
  background: transparent; }

.btn-radius {
  border-radius: 24px;
  min-width: 150px;
  padding-right: 20px;
  padding-left: 20px;
  text-transform: uppercase;
  width: auto; }

.btn:not(.btn-) {
  min-width: 150px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  width: auto; }

.rt-td .btn-group button.btn {
  min-width: auto !important;
  padding-left: 12px !important;
  padding-right: 12px !important; }

.hidden {
  display: none !important; }

.message-avatar-text-noti {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border: 1px solid #ccc;
  color: #8165f3;
  display: inline-flex; }

.promotion-section-title {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize; }

.promotion-shadow-box {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-right: 10px; }

.promotion-shadow-box:hover {
  box-shadow: rgba(46, 85, 143, 0.5) 0px 0px 13px; }

.promotion-shadow-box.promotion-section-disabled {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); }

.promotion-shadow-box.promotion-section-disabled .promotion-section-title {
  cursor: default; }

.logo img {
  max-width: 80%; }

.page-title {
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 38px;
  color: #334856; }

.react-select-container .react-select__option--is-selected {
  background-color: transparent !important;
  color: inherit !important; }

.left-0 {
  left: 0; }

.top-0 {
  top: 0; }

.h-100 {
  height: 100% !important; }

.w-100 {
  width: 100% !important; }

.position-fixed {
  position: fixed !important;
  z-index: 99; }

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
            transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
            transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
            transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
            transform: rotate3d(0, 0, 1, -5deg); }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
            transform: rotate3d(0, 0, 1, 0deg); } }

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
            transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
            transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
            transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
            transform: rotate3d(0, 0, 1, -5deg); }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
            transform: rotate3d(0, 0, 1, 0deg); } }

.swing {
  -webkit-transform-origin: top center;
          transform-origin: top center;
  -webkit-animation-name: swing;
          animation-name: swing; }

.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.can-click {
  cursor: pointer; }

.overflow-hidden {
  overflow: hidden; }

@media (min-width: 1200px) {
  .hidden-xl {
    display: none; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .hidden-lg {
    display: none; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .hidden-md {
    display: none; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .hidden-sm {
    display: none; } }

@media (max-width: 575.98px) {
  .hidden-xs {
    display: none; } }

.modal-backdrop-light {
  background: #f8f9fa;
  opacity: 1; }
  .modal-backdrop-light.modal-backdrop.show {
    opacity: 1; }

@media (max-width: 480px) {
  .modal-dialog {
    width: calc(100% - 20px) !important;
    margin: 0 10px;
    min-width: auto !important;
    max-width: 100% !important; }
    .modal-dialog .btn {
      font-size: 14px;
      min-width: 130px; }
  .production-prompt-details-modal .modal-content {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 0 !important; }
  .production-prompt-details-modal .modal-title {
    font-size: 35px !important;
    line-height: 44px !important; }
  .production-prompt-details-modal .cr-confirmation-modal__description-bottom {
    display: none; }
  .production-prompt-details-modal .modal-footer {
    flex-direction: column; }
    .production-prompt-details-modal .modal-footer .cr-confirmation-modal__confirm-button {
      margin-bottom: 24px;
      margin-right: 0 !important; }
    .production-prompt-details-modal .modal-footer .btn {
      width: 100%;
      background-color: #795afa !important;
      border-color: #795afa !important; }
      .production-prompt-details-modal .modal-footer .btn:hover {
        border-color: #795afa; }
    .production-prompt-details-modal .modal-footer .btn-content {
      display: flex;
      align-items: center; }
      .production-prompt-details-modal .modal-footer .btn-content span {
        color: #fff !important;
        margin-left: 10px;
        font-weight: 700;
        text-transform: capitalize; }
      .production-prompt-details-modal .modal-footer .btn-content svg {
        display: block !important; }
  .cr-confirmation-modal__success .modal-content {
    padding: 25px; }
  .cr-copy-order-modal .cr-copy-order-modal__title {
    padding: 0 20px;
    font-size: 35px;
    line-height: 44px; }
  .cr-copy-order-modal .cr-copy-order-modal__content {
    min-height: auto; }
  .cr-copy-order-modal .cr-copy-order-modal__checkbox-spot {
    max-width: 100%;
    padding: 0 20px;
    box-sizing: border-box; }
  .cr-copy-order-modal .cr-copy-order-modal__buttons {
    padding-top: 20px; }
  .cr-copy-order-modal .cr-copy-order-modal__select-spot-header,
  .cr-copy-order-modal .cr-copy-order-modal__spot-switch-template {
    padding-left: 20px;
    padding-right: 20px; }
  .modal-delete .modal-content {
    padding: 20px !important; }
  .modal-delete .modal-title {
    font-size: 35px;
    line-height: 44px; }
  .react-select__menu .react-select__menu-list {
    max-height: 250px !important; } }

.cr-dashboard {
  background-color: #f7fbfd; }
  .cr-dashboard__header {
    background: #336ABB;
    color: white;
    padding: 10px 0px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1); }
  .cr-dashboard__title {
    text-transform: capitalize; }
  .cr-dashboard__header-actions {
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: space-between;
    margin: 38px auto;
    margin-bottom: 60px; }
  .cr-dashboard__nav-tabs {
    display: flex;
    flex: 1 1;
    justify-content: space-between; }
    .cr-dashboard__nav-tabs .cr-button-add {
      width: 100%; }
  .cr-dashboard__tab-content {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .cr-dashboard__tab-content .cr-add-update-process__header {
      display: none; }
  .cr-dashboard__production-prompt-modal {
    width: 573px;
    max-width: inherit; }
    .cr-dashboard__production-prompt-modal .cr-confirmation-modal__header {
      margin-top: 63px; }
    .cr-dashboard__production-prompt-modal .modal-content {
      height: 430px; }
    .cr-dashboard__production-prompt-modal .modal-title {
      font-style: normal;
      font-weight: 800 !important;
      font-size: 40px !important;
      line-height: 50px;
      color: #000000 !important;
      max-width: 400px; }
    .cr-dashboard__production-prompt-modal .btn {
      width: 205px;
      max-width: initial; }
      .cr-dashboard__production-prompt-modal .btn span {
        color: #795afa; }
    .cr-dashboard__production-prompt-modal .cr-confirmation-modal__confirm-button {
      margin-right: 22.5px;
      background: #fff;
      border-color: #c2d4e0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .cr-dashboard__production-prompt-modal .cr-confirmation-modal__confirm-button:hover {
        border-color: #4324c7;
        background: #fff; }
      .cr-dashboard__production-prompt-modal .cr-confirmation-modal__confirm-button:active {
        background: #fff !important; }
    .cr-dashboard__production-prompt-modal .cr-confirmation-modal__cancel-button {
      margin-left: 22.5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .cr-dashboard__production-prompt-modal .modal-footer {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 16px; }

.autosuggest-container * {
  outline: none !important; }

.autosuggest-container input:focus {
  background-color: #fff;
  border-color: #795afa;
  box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.25);
  outline: none !important; }

.autosuggest-container input:hover {
  border-color: #4324c7;
  box-shadow: 0 0 0 1px #4324c7; }

.cr-confirmation-modal {
  z-index: 99; }
  .cr-confirmation-modal .modal-content {
    border: none;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-confirmation-modal__header {
    margin-top: 30px;
    padding-top: 1rem;
    border-bottom: 0;
    align-items: center;
    justify-content: center;
    padding: 0; }
    .cr-confirmation-modal__header button {
      display: none; }
    .cr-confirmation-modal__header .modal-title {
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 50px;
      text-align: center;
      color: #000000; }
  .cr-confirmation-modal__body {
    border-bottom: 0;
    font-size: 20px;
    font-weight: 400;
    color: #324856;
    text-align: center;
    padding: 0; }
    .cr-confirmation-modal__body.mtop {
      margin-top: 25px; }
  .cr-confirmation-modal__footer {
    border-top: 0;
    justify-content: center;
    margin: 30px 0 0 0; }
  .cr-confirmation-modal__cancel-button {
    margin-top: 0 !important;
    background-color: transparent;
    text-transform: uppercase;
    margin-left: 7px !important; }
  .cr-confirmation-modal__confirm-button {
    text-transform: uppercase;
    margin-top: 0 !important;
    margin-right: 7px !important; }
  .cr-confirmation-modal__checked-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 7px; }
  .cr-confirmation-modal__success {
    max-width: 526px; }
    .cr-confirmation-modal__success .modal-footer {
      display: none; }
    .cr-confirmation-modal__success .modal-header {
      margin: 83px 0; }
  .cr-confirmation-modal__description-bottom {
    padding: 0;
    width: 100%; }

.cr-confirmation-modal.modal-delete-confirm {
  width: 743px;
  height: 486px; }
  .cr-confirmation-modal.modal-delete-confirm .modal-header .modal-title {
    width: 359px;
    height: 138px;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #000000; }

.cr-confirmation-modal.modal-with-no-title .modal-content {
  padding: 40px 60px; }
  .cr-confirmation-modal.modal-with-no-title .modal-content .modal-body {
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #000000; }
  .cr-confirmation-modal.modal-with-no-title .modal-content .modal-footer {
    margin: 0; }
    .cr-confirmation-modal.modal-with-no-title .modal-content .modal-footer > .btn:not(:last-child) {
      margin-right: 24px; }

.cr-confirmation-modal.production-prompt-modal {
  width: 573px;
  max-width: inherit; }
  .cr-confirmation-modal.production-prompt-modal .modal-content {
    height: 430px; }
  .cr-confirmation-modal.production-prompt-modal .modal-title {
    font-style: normal;
    font-weight: 800 !important;
    font-size: 40px !important;
    line-height: 50px;
    color: #000000 !important;
    max-width: 400px; }
  .cr-confirmation-modal.production-prompt-modal .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: initial; }
  .cr-confirmation-modal.production-prompt-modal .cr-confirmation-modal__cancel-button {
    margin-left: 22.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px; }
  .cr-confirmation-modal.production-prompt-modal .modal-footer {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 16px; }
  .cr-confirmation-modal.production-prompt-modal .description-left {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    display: block;
    flex: 1 1;
    margin-left: 6px;
    display: flex;
    justify-content: center; }
    .cr-confirmation-modal.production-prompt-modal .description-left span {
      width: 170px;
      display: inline-block; }
  .cr-confirmation-modal.production-prompt-modal .description-right {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    display: block;
    flex: 1 1;
    margin-left: -53px;
    display: flex;
    justify-content: center; }
    .cr-confirmation-modal.production-prompt-modal .description-right span {
      width: 160px;
      display: inline-block; }
  .cr-confirmation-modal.production-prompt-modal .description-spots-button {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-confirmation-modal.production-prompt-modal .btn-content svg {
    display: none; }

.cr-confirmation-modal.modal-request-changes {
  max-width: 573px; }
  .cr-confirmation-modal.modal-request-changes .cr-confirmation-modal__header {
    margin-top: 40px; }
  .cr-confirmation-modal.modal-request-changes .cr-confirmation-modal__footer {
    margin: 30px 0 24px 0; }

.cr-confirmation-modal.confirmation-switch-channels-modal {
  max-width: 700px; }
  .cr-confirmation-modal.confirmation-switch-channels-modal .modal-title {
    font-size: 25px;
    line-height: 35px;
    margin-top: 0; }
  .cr-confirmation-modal.confirmation-switch-channels-modal .cr-confirmation-modal__header {
    margin-top: 10px; }
  .cr-confirmation-modal.confirmation-switch-channels-modal .modal-footer {
    padding-bottom: 0; }

@media (max-width: 480px) {
  .cr-confirmation-modal.client-review-confirmation {
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
    display: flex; }
    .cr-confirmation-modal.client-review-confirmation .modal-content {
      padding: 40px; }
    .cr-confirmation-modal.client-review-confirmation .modal-header {
      margin-top: 0; }
    .cr-confirmation-modal.client-review-confirmation .modal-title {
      font-size: 32px;
      line-height: 38px; }
    .cr-confirmation-modal.client-review-confirmation .modal-footer {
      margin-bottom: 0;
      padding: 0; }
      .cr-confirmation-modal.client-review-confirmation .modal-footer .btn {
        font-size: 14px; } }

.cr-confirmation-modal.warning-delete-hub-modal .modal-header {
  max-width: 320px; }

.cr-confirmation-modal.delete-hub-modal .modal-body {
  max-width: 360px; }

@media (min-width: 576px) {
  .cr-confirmation-modal {
    max-width: 562px; } }

.client-review-confirmation-feedback .cr-checkbox {
  display: flex;
  align-items: center;
  justify-content: center; }
  .client-review-confirmation-feedback .cr-checkbox .cr-checkbox__text {
    color: #657894; }

.client-review-confirmation-feedback .cr-confirmation-modal__description-bottom {
  margin-top: 20px; }

@media (max-width: 480px) {
  .client-review-confirmation-feedback {
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
    display: flex; }
    .client-review-confirmation-feedback .modal-content {
      padding: 30px; }
    .client-review-confirmation-feedback .modal-header {
      margin-top: 0; }
    .client-review-confirmation-feedback .modal-title {
      font-size: 32px;
      line-height: 38px; }
    .client-review-confirmation-feedback .modal-footer {
      margin-bottom: 0;
      padding: 0; }
      .client-review-confirmation-feedback .modal-footer .btn {
        font-size: 14px; } }

.cr-header {
  background-color: #121d2f;
  z-index: 100;
  position: fixed;
  height: 65px;
  width: 100%;
  right: 0;
  top: 0;
  padding-left: 0;
  padding-right: 50px; }
  .cr-header__logo {
    height: 65px;
    width: 265px;
    display: flex;
    align-items: center;
    font-size: 25px;
    color: #c2d4e0;
    font-weight: bold;
    justify-content: center;
    margin-right: 16px; }
  .cr-header__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto; }
  .cr-header__nav-toggle {
    background: transparent;
    border: none;
    padding: 0;
    align-items: center;
    display: flex;
    cursor: pointer;
    padding-left: 30px; }
    .cr-header__nav-toggle:focus {
      outline: none; }
  .cr-header__nav-toggle-open svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .cr-header__nav-toggle-line {
    color: #fff;
    height: 2px;
    display: block;
    width: 100%;
    background: #fff;
    margin-bottom: 5px;
    overflow: hidden; }
    .cr-header__nav-toggle-line:last-child {
      margin-bottom: 0; }
  .cr-header__site-logo {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-header__site-logo-none-menu {
    margin-left: 70px; }
  .cr-header__link-title {
    font-size: 14px;
    line-height: 17px;
    color: #b7c0cd;
    text-transform: capitalize;
    margin-left: 10px; }
  .cr-header .nav-link {
    padding-right: 20px !important;
    padding-left: 20px !important; }
    .cr-header .nav-link .d-flex {
      display: flex;
      align-items: center;
      justify-content: center; }
  .cr-header__nav-link-user-avatar.nav-link {
    padding-right: 0 !important; }
  .cr-header__btn-impersonating {
    background-color: #795afa;
    border-radius: 50px;
    cursor: pointer; }
    .cr-header__btn-impersonating .cr-header__link-title {
      text-transform: uppercase;
      margin-left: 0;
      color: #fff; }
  .cr-header__notification-active-icon {
    position: relative; }
  .cr-header__notification-total {
    width: 17px;
    height: 17px;
    display: flex;
    font-size: 10px;
    font-weight: bold;
    background-color: #ff006c;
    border: 1px solid #121d2f;
    border-radius: 100%;
    position: absolute;
    z-index: 9;
    right: -10.7px;
    top: -5px;
    align-items: center;
    justify-content: center; }
  .cr-header__notification-total-small {
    font-size: 8px; }
  .cr-header__notification-popover {
    padding-right: 30px !important; }
  @media (min-width: 1599px) {
    .cr-header {
      padding-right: 70px; } }

.popover {
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  cursor: pointer;
  text-align: left;
  width: 500px;
  background: white;
  z-index: 999;
  top: 5px !important; }

.cr-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 100;
  background-position: center center;
  background-size: cover;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .cr-sidebar + .cr-content {
    margin: 0 0 0 78px;
    width: calc(100% - 78px); }
  .cr-sidebar__sidebar-content {
    width: inherit; }
    .cr-sidebar__sidebar-content .cr-sidebar__user-card {
      padding-bottom: 24px; }
    .cr-sidebar__sidebar-content .cr-sidebar-new {
      padding-bottom: 24px; }
  .cr-sidebar__header-fake {
    height: 65px; }
  .cr-sidebar__separator {
    height: 1px;
    width: 100%; }
  .cr-sidebar .nav-link {
    display: flex;
    align-items: center;
    color: #657894;
    position: relative; }
    .cr-sidebar .nav-link.active {
      color: #000; }
  .cr-sidebar__sections-scroll {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .cr-sidebar__sidebar-section {
    display: flex;
    flex-direction: column; }
    .cr-sidebar__sidebar-section:last-child .cr-sidebar__separator {
      display: none; }
  .cr-sidebar__link-title {
    font-weight: bold;
    font-size: 14px;
    margin-left: 16px;
    text-transform: capitalize; }
  .cr-sidebar__nav-tooltip {
    opacity: 1;
    padding: 0; }
    .cr-sidebar__nav-tooltip .rc-tooltip-arrow {
      display: none; }
    .cr-sidebar__nav-tooltip .rc-tooltip-inner {
      background: #edf4f8;
      border-radius: 0px 30px 30px 0px;
      padding: 0;
      box-shadow: none;
      margin-left: -4px;
      padding-left: 4px; }
  .cr-sidebar__stripe-link-title span {
    color: #334856;
    font-weight: bold;
    font-size: 14px;
    margin-left: 16px;
    text-transform: capitalize;
    line-height: 38px;
    padding-right: 20px;
    white-space: nowrap;
    margin: 0; }
  .cr-sidebar__sidebar-title {
    font-weight: 800;
    font-size: 17px;
    color: #334856;
    margin-top: 8px;
    margin-bottom: 12px; }
  .cr-sidebar__sidebar-sort-title {
    color: #6ea7da;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
    text-align: center; }
  .cr-sidebar__nav-top {
    background-position: center -36px;
    background-repeat: no-repeat;
    background-size: cover; }
  .cr-sidebar__top-separator {
    margin: 14px 30px !important;
    width: calc(100% - 60px);
    height: 1px;
    background: #c4c4c4; }
  .cr-sidebar__nav-icon {
    position: relative; }
  .cr-sidebar__counter {
    min-width: 17px;
    height: 17px;
    padding: 1px;
    background: #ff006c;
    border-radius: 100px;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    color: #ffffff; }
  .cr-sidebar__footer {
    margin-bottom: 30px;
    text-align: center;
    margin-top: 50px; }
    .cr-sidebar__footer strong {
      color: #657894; }
    .cr-sidebar__footer span {
      color: #829fb1; }
  .cr-sidebar__collapsed {
    width: 78px;
    transition: width 0.1s ease-out; }
    .cr-sidebar__collapsed .scroll-bar-style::-webkit-scrollbar {
      width: 5px; }
    .cr-sidebar__collapsed .cr-sidebar-new--closed {
      border-bottom: 1px solid #edf1f6;
      margin-bottom: 25px; }
    .cr-sidebar__collapsed .cr-sidebar__nav-top {
      background-position: 0 -35px; }
    .cr-sidebar__collapsed .cr-admin-card__background-swirl {
      height: 90px; }
    .cr-sidebar__collapsed .nav-link {
      justify-content: center;
      padding: 0;
      width: 100%; }
      .cr-sidebar__collapsed .nav-link:hover::after {
        content: "";
        background: #edf4f8;
        display: block;
        height: 38px;
        position: absolute;
        width: 100%; }
    .cr-sidebar__collapsed .cr-sidebar__nav-link-active::after {
      content: "";
      background: #edf4f8;
      display: block;
      height: 38px;
      position: absolute;
      width: 100%; }
    .cr-sidebar__collapsed .cr-sidebar__sidebar-sort-title {
      display: block; }
    .cr-sidebar__collapsed .cr-sidebar__separator {
      background: #edf1f6;
      margin: 16px 0; }
    .cr-sidebar__collapsed .cr-sidebar__sidebar-section {
      align-items: center; }
    .cr-sidebar__collapsed .cr-admin-card__server-text,
    .cr-sidebar__collapsed .cr-sidebar__top-separator,
    .cr-sidebar__collapsed .cr-sidebar__sidebar-title,
    .cr-sidebar__collapsed .cr-user-card__title {
      display: none; }
    .cr-sidebar__collapsed .cr-admin-card {
      margin-bottom: 0;
      position: relative;
      height: 90px; }
    .cr-sidebar__collapsed .cr-admin-card__server-image {
      position: absolute;
      top: 20px; }
      .cr-sidebar__collapsed .cr-admin-card__server-image img {
        padding: 10px; }
    .cr-sidebar__collapsed .cr-user-card__user-avatar {
      height: 36px;
      width: 36px;
      margin: 20px 0 0 0; }
    .cr-sidebar__collapsed .avatar-sort-name-text {
      font-size: 11px; }
    .cr-sidebar__collapsed .cr-sidebar__user-card {
      height: 90px;
      padding: 0;
      justify-content: flex-start; }
    .cr-sidebar__collapsed .cr-sidebar__nav-icon {
      position: relative;
      z-index: 2;
      display: block;
      width: 70px;
      text-align: center;
      padding: 12.5px 0; }
    .cr-sidebar__collapsed .cr-sidebar__footer {
      min-height: 104px; }
    .cr-sidebar__collapsed .cr-sidebar__counter {
      position: absolute;
      left: 35px;
      top: 5px; }
  .cr-sidebar__sidebar-open-style .cr-sidebar__sidebar-title {
    padding: 0 30px; }
  .cr-sidebar__sidebar-open-style .nav-link {
    padding: 10px 30px; }
    .cr-sidebar__sidebar-open-style .nav-link:hover {
      background: #edf4f8; }
      .cr-sidebar__sidebar-open-style .nav-link:hover .cr-sidebar__link-title {
        color: #000; }
  .cr-sidebar__sidebar-open-style .cr-sidebar__counter {
    position: absolute;
    left: 6px;
    top: -8px; }
  .cr-sidebar__sidebar-open-style .cr-sidebar__separator {
    background: #c4c4c4;
    margin: 14px auto;
    padding: 0 30px;
    width: calc(100% - 60px); }
  .cr-sidebar__sidebar-open-style .cr-sidebar__sidebar-title {
    display: block; }
  .cr-sidebar__rollover-open {
    width: 265px; }
    .cr-sidebar__rollover-open + .cr-content {
      margin: 0 0 0 78px; }
  .cr-sidebar__open {
    width: 265px; }
    .cr-sidebar__open + .cr-content {
      margin: 0 0 0 265px;
      transition: margin 0.2s ease-in;
      width: calc(100% - 265px); }
    .cr-sidebar__open .avatar-sort-name-text {
      font-size: 20px;
      line-height: initial; }
    .cr-sidebar__open .cr-sidebar__footer {
      min-height: 104px; }
    .cr-sidebar__open .cr-sidebar__sections-wrapper {
      padding-right: 5px; }
  @media screen and (min-width: 1200px) and (max-width: 1436px) {
    .cr-sidebar__rollover-open + .cr-content {
      margin: 0 0 0 78px;
      width: calc(100% - 78px); }
    .cr-sidebar__open {
      width: 244px; }
      .cr-sidebar__open + .cr-content {
        margin: 0 0 0 244px;
        width: calc(100% - 244px); }
    .cr-sidebar__sidebar-open-style .nav-link {
      padding: 10px 20px; }
    .cr-sidebar__sidebar-open-style .cr-sidebar__separator {
      padding: 0 20px;
      width: calc(100% - 40px); } }

.cr-content {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0; }

.cr-page__header {
  padding-top: 65px;
  display: flex;
  align-items: flex-end;
  align-self: center; }
  @media (max-width: 767.98px) {
    .cr-page__header {
      flex-direction: column;
      align-items: flex-start; } }

.cr-page__title {
  padding-right: 0.5rem;
  margin-bottom: 1rem;
  text-transform: capitalize; }
  @media (max-width: 767.98px) {
    .cr-page__title {
      margin-bottom: 0; } }

.cr-page__breadcrumb {
  align-self: flex-end;
  padding: 0;
  text-transform: capitalize; }
  @media (max-width: 767.98px) {
    .cr-page__breadcrumb {
      align-self: flex-start; } }

.cr-page .row .col,
.cr-page .row > [class^="col-"] {
  margin-bottom: 1rem; }

.cr-page__breadcrumb .breadcrumb {
  background-color: transparent;
  padding-left: 0; }
  .cr-page__breadcrumb .breadcrumb .breadcrumb-item {
    cursor: pointer;
    font-size: 14px; }
    .cr-page__breadcrumb .breadcrumb .breadcrumb-item a {
      color: #657894; }
    .cr-page__breadcrumb .breadcrumb .breadcrumb-item.active {
      cursor: inherit; }
    .cr-page__breadcrumb .breadcrumb .breadcrumb-item strong {
      font-weight: 600; }

.cr-widget {
  display: flex;
  flex-direction: row; }
  .cr-widget__icon {
    flex: 0 0 auto;
    align-items: center;
    justify-content: center; }

.cr-search-form {
  position: relative;
  width: 30%;
  min-width: 300px;
  box-shadow: 2px 2px 10px rgba(80, 92, 98, 0.25);
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden; }
  .cr-search-form__icon-search {
    position: absolute;
    right: 10px;
    color: #121d2f;
    font-size: 30px;
    top: calc(50% - 25px);
    background: none;
    border: none;
    padding: 0; }
    .cr-search-form__icon-search:focus {
      outline: none; }
  .cr-search-form__icon-close {
    position: absolute;
    right: 45px;
    color: #121d2f;
    top: calc(50% - 12px);
    font-size: 22px;
    border-radius: 100px;
    border: 1px solid #aaa;
    cursor: pointer; }
  .cr-search-form__input {
    /* stylelint-disable-next-line */
    padding-right: 60px !important;
    font-weight: 400;
    font-size: 14px;
    color: #6e8ca0;
    width: 100% !important;
    border: none;
    padding: 15px; }
    .cr-search-form__input::-webkit-input-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: #6e8ca0; }
    .cr-search-form__input:-moz-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: #6e8ca0; }
    .cr-search-form__input::-moz-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: #6e8ca0; }
    .cr-search-form__input:-ms-input-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: #6e8ca0; }

.tooltip-emoji-picker.show {
  opacity: 1; }

.tooltip-inner-emoji-picker {
  background: transparent; }

.tooltip-arrow-emoji-picker::before {
  border: 0px;
  border-top-color: transparent !important; }

.cr-chatlist {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 350px; }
  .cr-chatlist .rce-container-clist {
    height: 300px;
    overflow-y: auto;
    transition: all 0.2s ease-in; }
    .cr-chatlist .rce-container-clist.hide {
      height: 0; }
  .cr-chatlist .rce-navbar-item__right {
    cursor: pointer; }

.cr-chatbox {
  max-width: 350px;
  z-index: 10; }
  .cr-chatbox__comment-button {
    border-bottom: 1px solid transparent;
    display: flex;
    align-items: center;
    height: 79px;
    min-width: initial; }
  .cr-chatbox__tagged-users {
    display: flex;
    align-items: center;
    flex-direction: column; }
    .cr-chatbox__tagged-users .avatar-sort-name-text {
      font-size: 15px;
      line-height: initial; }
    .cr-chatbox__tagged-users .cr-avatar {
      margin: 4px 0;
      cursor: pointer; }
  .cr-chatbox__button-circle {
    border-radius: 50px;
    width: 50px;
    height: 50px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.102);
    border: none;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #795afa;
    cursor: pointer; }
    .cr-chatbox__button-circle:focus, .cr-chatbox__button-circle:active {
      outline: none; }
  .cr-chatbox__title {
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(194, 212, 224, 0.67);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 79px; }
    .cr-chatbox__title span {
      font-style: normal;
      font-weight: 800;
      font-size: 22px;
      color: #334856; }
  .cr-chatbox .react-hint {
    position: absolute;
    z-index: 1000; }
  .cr-chatbox .rce-container-mlist {
    height: 300px;
    overflow-y: auto;
    transition: all 0.2s ease-in;
    background: #f8f9fa; }
    .cr-chatbox .rce-container-mlist.hide {
      height: 0; }
  .cr-chatbox .cr-chatbox__mention__list {
    display: flex;
    align-items: center; }
  .cr-chatbox .cr-chatbox__mention__avatar {
    margin-right: 5px;
    border-radius: 50%;
    width: 40px;
    height: 40px; }
  .cr-chatbox .cr-chatbox__mention__name {
    margin: 5px; }
  .cr-chatbox .rce-container-input {
    border-top: 2px solid rgba(0, 0, 0, 0.125); }
  .cr-chatbox .cr-chatbox__mentionInput__suggestions {
    left: 0px !important; }
  .cr-chatbox .cr-chatbox__mentionInput__control {
    overflow: hidden;
    border-radius: 30px; }
  .cr-chatbox .cr-chatbox__input {
    display: flex;
    margin-top: 5px;
    border: 1px solid #c2d4e0;
    border-radius: 30px;
    padding: 5px;
    background: white;
    align-items: center; }
    .cr-chatbox .cr-chatbox__input .cr-chatbox__input__button {
      background: #795afa;
      display: flex;
      color: #fff;
      cursor: pointer;
      border-radius: 100px;
      justify-content: center;
      align-items: center;
      width: 34px;
      height: 34px; }
    .cr-chatbox .cr-chatbox__input .cr-chatbox__input__field {
      flex: 1 1; }
  .cr-chatbox__mentionInput__input {
    border: none !important;
    font-weight: 400;
    font-size: 14px;
    color: #000; }
    .cr-chatbox__mentionInput__input::-webkit-input-placeholder {
      font-size: 14px;
      line-height: 18px;
      color: #829fb1;
      padding-left: 10px;
      opacity: 1;
      outline: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .cr-chatbox__mentionInput__input:-moz-placeholder {
      font-size: 14px;
      line-height: 18px;
      color: #829fb1;
      padding-left: 10px;
      opacity: 1;
      outline: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .cr-chatbox__mentionInput__input::-moz-placeholder {
      font-size: 14px;
      line-height: 18px;
      color: #829fb1;
      padding-left: 10px;
      opacity: 1;
      outline: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .cr-chatbox__mentionInput__input:-ms-input-placeholder {
      font-size: 14px;
      line-height: 18px;
      color: #829fb1;
      padding-left: 10px;
      opacity: 1;
      outline: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .cr-chatbox__input__button-mention {
    width: 40px;
    height: 40px;
    background: rgba(246, 247, 249, 0.5);
    border: 1px solid rgba(194, 212, 224, 0.5);
    border-radius: 100px;
    color: #c2d4e0;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-chatbox__mentionInput__highlighter {
    border: none !important; }
  .cr-chatbox .messages-wrapper {
    padding: 10px 0; }
  .cr-chatbox .message-content {
    flex: 1 1; }
  .cr-chatbox .message-active .text {
    background-color: #6f96d1 !important;
    color: #000 !important; }
  .cr-chatbox .message {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; }
    .cr-chatbox .message .text {
      border-radius: 15px;
      margin-top: 0px;
      margin-bottom: 0px;
      display: block;
      font-size: 14px;
      line-height: 21px;
      color: #374c59;
      background-color: #ffffff;
      overflow: hidden;
      padding: 15px;
      border: 1px solid #c2d4e0; }
    .cr-chatbox .message .time {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 14px;
      color: #829fb1;
      margin: 5px 0 10px 0; }
    .cr-chatbox .message .from {
      font-size: 11px; }
    .cr-chatbox .message.yours {
      margin-right: 20%; }
      .cr-chatbox .message.yours .message-content {
        margin-left: 10px; }
      .cr-chatbox .message.yours .text {
        align-items: flex-start;
        position: relative;
        border-top-left-radius: 0px; }
      .cr-chatbox .message.yours .time {
        text-align: right; }
    .cr-chatbox .message.mine {
      margin-left: 20%; }
      .cr-chatbox .message.mine .message-content {
        margin-right: 10px; }
      .cr-chatbox .message.mine .text {
        align-items: flex-end;
        background-attachment: fixed;
        position: relative;
        border-top-right-radius: 0px; }
      .cr-chatbox .message.mine .message-highlight {
        margin-left: 4px; }
      .cr-chatbox .message.mine .time {
        text-align: right; }
  .cr-chatbox .message-played {
    margin-right: 5px;
    font-weight: 500;
    color: #8165f3;
    cursor: pointer; }
    .cr-chatbox .message-played:hover {
      text-decoration: underline; }
  .cr-chatbox__message {
    margin-bottom: 10px; }
  .cr-chatbox__message-avatar-text {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #ffffff;
    background: #0f55b9; }
  .cr-chatbox__tooltip-comment-actions {
    opacity: 1;
    background-color: transparent; }
    .cr-chatbox__tooltip-comment-actions .rc-tooltip-inner {
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      padding: 14.5px 0 12px 0;
      width: 161px; }
    .cr-chatbox__tooltip-comment-actions .rc-tooltip-arrow {
      border-color: transparent;
      top: -1px; }
      .cr-chatbox__tooltip-comment-actions .rc-tooltip-arrow:after {
        content: "";
        position: absolute;
        width: 19px;
        height: 19px;
        background: #fff;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 1px;
        left: -10px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); }
      .cr-chatbox__tooltip-comment-actions .rc-tooltip-arrow:before {
        content: "";
        display: block;
        width: 30px;
        height: 14px;
        background: #fff;
        position: absolute;
        top: 11px;
        z-index: 9;
        left: -15px; }
  .cr-chatbox__comment-action-button {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: #334856;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    white-space: nowrap; }
    .cr-chatbox__comment-action-button:hover {
      background: #c2d4e0; }
  .cr-chatbox__comment-more-icon {
    position: absolute;
    right: 13px;
    bottom: 10.5px;
    cursor: pointer;
    font-size: 0; }
  .cr-chatbox__view-comment-close {
    position: absolute;
    right: 9px;
    top: 3px;
    cursor: pointer; }

.cr-chatbox-input .react-hint {
  position: absolute;
  z-index: 1000; }

.cr-chatbox-input .rce-container-mlist {
  height: 300px;
  overflow-y: auto;
  transition: all 0.2s ease-in;
  background: #f8f9fa; }
  .cr-chatbox-input .rce-container-mlist.hide {
    height: 0; }

.cr-chatbox-input__mention__list {
  display: flex;
  align-items: center; }

.cr-chatbox-input__mention__avatar {
  margin-right: 5px;
  border-radius: 50%;
  width: 40px;
  height: 40px; }

.cr-chatbox-input__mention__name {
  margin: 5px; }

.cr-chatbox-input .rce-container-input {
  border-top: 2px solid rgba(0, 0, 0, 0.125); }

.cr-chatbox-input__mentionInput__suggestions {
  left: 0px !important; }

.cr-chatbox-input__mentionInput__control {
  overflow: hidden;
  border-radius: 30px; }

.cr-chatbox-input__input {
  display: flex;
  margin-top: 5px;
  border: 1px solid #c2d4e0;
  border-radius: 30px;
  padding: 5px;
  background: white;
  align-items: flex-start; }
  .cr-chatbox-input__input .cr-chatbox-input__input__button {
    background: #795afa;
    display: flex;
    color: #fff;
    cursor: pointer;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    margin-top: 5px;
    margin-right: 5px; }
  .cr-chatbox-input__input .cr-chatbox-input__input__field {
    flex: 1 1; }

.cr-chatbox-input__mentionInput__input {
  border: none !important;
  font-weight: 400;
  font-size: 14px;
  color: #000; }
  .cr-chatbox-input__mentionInput__input::-webkit-input-placeholder {
    font-size: 14px;
    line-height: 18px;
    color: #829fb1;
    padding-left: 10px;
    opacity: 1;
    outline: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .cr-chatbox-input__mentionInput__input:-moz-placeholder {
    font-size: 14px;
    line-height: 18px;
    color: #829fb1;
    padding-left: 10px;
    opacity: 1;
    outline: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .cr-chatbox-input__mentionInput__input::-moz-placeholder {
    font-size: 14px;
    line-height: 18px;
    color: #829fb1;
    padding-left: 10px;
    opacity: 1;
    outline: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .cr-chatbox-input__mentionInput__input:-ms-input-placeholder {
    font-size: 14px;
    line-height: 18px;
    color: #829fb1;
    padding-left: 10px;
    opacity: 1;
    outline: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.cr-chatbox-input__input__button-mention {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  color: #c2d4e0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.cr-chatbox-input__button-action {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #edf1f64d;
  border: 1px solid #c2d4e0;
  border-radius: 30px;
  height: 40px;
  padding: 0 12px; }

.cr-chatbox-input__attach-icon {
  margin-bottom: 0;
  margin-left: 12px;
  cursor: pointer;
  position: relative;
  width: 24px;
  display: inline-flex; }
  .cr-chatbox-input__attach-icon input {
    opacity: 0;
    position: absolute;
    width: 0; }
  .cr-chatbox-input__attach-icon .cr-spinner-loading {
    position: absolute; }

.cr-chatbox-input__mentionInput__highlighter {
  border: none !important; }

.cr-chatbox-input .cr-chat-attachment {
  margin-left: 12px;
  margin-right: 30px;
  width: calc(100% - 33px); }

.cr-chat-attachment {
  width: 100%;
  position: relative; }
  .cr-chat-attachment__image {
    max-width: 100%; }
    .cr-chat-attachment__image img {
      max-width: 100%; }
  .cr-chat-attachment__file-name {
    color: #334856;
    font-size: 12px;
    line-height: 18px;
    max-width: calc(100% - 20px); }
  .cr-chat-attachment__delete-button {
    position: absolute;
    right: 0px;
    bottom: -4px;
    cursor: pointer; }
  .cr-chat-attachment__file-buttons {
    justify-content: flex-end;
    display: flex; }
  .cr-chat-attachment__file-view, .cr-chat-attachment__file-download, .cr-chat-attachment__file-trash {
    margin: 0 5px;
    cursor: pointer; }
  .cr-chat-attachment__file-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1;
    margin-top: 10px; }
  .cr-chat-attachment__pointer {
    cursor: pointer;
    display: inline-block; }

.cr-textinput__input-wrapper {
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: #c2d4e0;
  border-radius: 6px;
  height: 50px; }
  .cr-textinput__input-wrapper.invalid {
    border-color: #e40061;
    color: #e40061;
    box-shadow: 0 0 0 1px #e40061; }
    .cr-textinput__input-wrapper.invalid ::-webkit-input-placeholder {
      color: #e40061; }
    .cr-textinput__input-wrapper.invalid ::placeholder {
      color: #e40061; }
  .cr-textinput__input-wrapper.textarea {
    height: auto; }
  .cr-textinput__input-wrapper ::-webkit-input-placeholder {
    color: #829fb1; }
  .cr-textinput__input-wrapper ::placeholder {
    color: #829fb1; }

.cr-textinput .fullDate .react-datepicker__header {
  background-color: #fff;
  border: none; }

.cr-textinput .fullDate .react-datepicker__month-container {
  padding: 0px 15px; }

.cr-textinput .fullDate .react-datepicker__day-names {
  background-color: #eff3f6;
  border: 1px solid #e8e9ec; }

.cr-textinput .fullDate .react-datepicker__day-name {
  color: #a3a6b4;
  font-size: 13px;
  margin: 0px;
  padding: 7px 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase; }

.cr-textinput .fullDate .react-datepicker__current-month {
  margin-bottom: 10px;
  color: #334856;
  font-size: 20px; }

.cr-textinput .fullDate .react-datepicker__month {
  margin: 0px; }

.cr-textinput .fullDate .react-datepicker__day {
  color: #334856;
  font-size: 16px;
  margin: 0px;
  padding: 7px 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #c2d4e0;
  border-left: 1px solid #c2d4e0;
  border-radius: 0px; }

.cr-textinput .fullDate .react-datepicker__month {
  border-right: 1px solid #c2d4e0;
  margin-bottom: 14px; }

.cr-textinput .fullDate .react-datepicker__day--selected,
.cr-textinput .fullDate .react-datepicker__day--keyboard-selected {
  background-color: #8165f3;
  border-color: #8165f3 !important;
  color: #fff; }

.cr-textinput .fullDate .react-datepicker__triangle {
  display: none; }

.cr-textinput .fullDate .react-datepicker__navigation--previous,
.cr-textinput .fullDate .react-datepicker__navigation--next {
  margin-top: 7px; }

.cr-textinput .fullDate input {
  padding: 0px 10px; }

.cr-textinput .fullDate .react-datepicker {
  border: none;
  margin-top: 3px;
  box-shadow: 0px 0px 7px -3px #000000; }

.cr-textinput .fullDate .datePickerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; }

.cr-textinput .fullDate .datePickerButtons {
  background-color: transparent;
  color: #a4afb7;
  border: 1px solid #e8e9ec;
  font-size: 25px;
  padding: 5px 6px;
  border-radius: 4px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  line-height: 0px; }
  .cr-textinput .fullDate .datePickerButtons:focus {
    outline: none; }

.cr-textinput .fullDate .datePickerTxt {
  color: #334856;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px; }

.cr-textinput .fullDate .react-datepicker-wrapper,
.cr-textinput .fullDate .react-datepicker__input-container {
  width: 100%;
  height: 100%; }

.cr-textinput .fullDate input:disabled {
  background-color: #e9ecef; }

.cr-textinput__left-component {
  height: 50px;
  padding: 0px 5px 0px 10px;
  display: flex;
  text-transform: capitalize;
  align-items: center;
  font-size: 20px;
  color: #829fb1; }
  .cr-textinput__left-component * {
    outline: 0 !important; }

.cr-textinput__right-component {
  height: 50px;
  padding: 0px 10px 0px 5px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #829fb1; }
  .cr-textinput__right-component * {
    outline: 0 !important; }
  .cr-textinput__right-component.delete {
    color: #e40061;
    cursor: pointer; }

.cr-textinput__right-component {
  height: 50px;
  padding: 0px 10px 0px 5px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #829fb1; }
  .cr-textinput__right-component * {
    outline: 0 !important; }

.cr-textinput__add-new-link {
  margin-top: 10px;
  color: #003baf;
  cursor: pointer; }

.cr-textinput__input-field {
  flex: 1 1;
  height: 100%;
  max-width: 100%; }
  .cr-textinput__input-field .fullDate {
    height: 100%; }
  .cr-textinput__input-field.textarea {
    height: auto; }
  .cr-textinput__input-field input {
    outline: none;
    height: 100%;
    width: 100%;
    background-color: transparent;
    border-width: 0px; }
    .cr-textinput__input-field input:focus {
      background-color: #fff;
      border-color: #795afa;
      box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.25); }
    .cr-textinput__input-field input:hover {
      border-color: #4324c7;
      box-shadow: 0 0 0 2px #4324c7;
      border-radius: 6px; }
    .cr-textinput__input-field input:disabled {
      background-color: #e9ecef !important; }
  .cr-textinput__input-field textarea {
    border: 0px;
    outline: none;
    width: 100%;
    background-color: transparent;
    min-height: 100px; }
    .cr-textinput__input-field textarea:focus {
      background-color: #fff;
      border-color: #795afa;
      box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.25); }
    .cr-textinput__input-field textarea:hover {
      border-color: #4324c7;
      box-shadow: 0 0 0 2px #4324c7; }
    .cr-textinput__input-field textarea:disabled {
      background-color: #e9ecef !important; }
  .cr-textinput__input-field .color-indicator {
    height: 48px;
    position: absolute;
    width: 17px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }

.cr-textinput__input-label {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.cr-textinput__character-count {
  font-size: 14px;
  color: #657894;
  margin-bottom: 0.5rem; }
  .cr-textinput__character-count strong {
    color: #000; }

.cr-textinput__character-max strong {
  color: #e40061; }

.date-picker-open #cr-app-main {
  overflow-y: hidden; }

.react-datepicker-popper::after {
  content: "";
  background-color: red;
  width: 100%;
  height: 100%; }

.react-datepicker-popper .react-datepicker__header {
  background-color: #fff;
  border: none; }

.react-datepicker-popper .react-datepicker__month-container {
  padding: 0px 15px; }

.react-datepicker-popper .react-datepicker__day-names {
  background-color: #eff3f6;
  border: 1px solid #e8e9ec; }

.react-datepicker-popper .react-datepicker__day-name {
  color: #a3a6b4;
  font-size: 13px;
  margin: 0px;
  padding: 7px 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase; }

.react-datepicker-popper .react-datepicker__current-month {
  margin-bottom: 10px;
  color: #334856;
  font-size: 20px; }

.react-datepicker-popper .react-datepicker__month {
  margin: 0px; }

.react-datepicker-popper .react-datepicker__day {
  color: #334856;
  font-size: 16px;
  margin: 0px;
  padding: 7px 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #c2d4e0;
  border-left: 1px solid #c2d4e0;
  border-radius: 0px; }

.react-datepicker-popper .react-datepicker__month {
  border-right: 1px solid #c2d4e0;
  margin-bottom: 14px; }

.react-datepicker-popper .react-datepicker__day--selected,
.react-datepicker-popper .react-datepicker__day--keyboard-selected {
  background-color: #8165f3;
  border-color: #8165f3 !important;
  color: #fff; }

.react-datepicker-popper .react-datepicker__triangle {
  display: none; }

.react-datepicker-popper .react-datepicker__navigation--previous,
.react-datepicker-popper .react-datepicker__navigation--next {
  margin-top: 7px; }

.react-datepicker-popper input {
  padding: 0px 10px; }

.react-datepicker-popper .react-datepicker {
  border: none;
  margin-top: 3px;
  box-shadow: 0px 0px 7px -3px #000000; }

.react-datepicker-popper .datePickerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; }

.react-datepicker-popper .datePickerButtons {
  background-color: transparent;
  color: #a4afb7;
  border: 1px solid #e8e9ec;
  font-size: 25px;
  padding: 5px 6px;
  border-radius: 4px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  line-height: 0px; }
  .react-datepicker-popper .datePickerButtons:focus {
    outline: none; }

.react-datepicker-popper .datePickerTxt {
  color: #334856;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px; }

.react-datepicker-popper .react-datepicker-wrapper,
.react-datepicker-popper .react-datepicker__input-container {
  width: 100%;
  height: 100%; }

.react-datepicker-popper input:disabled {
  background-color: #e9ecef; }

.cr-auto-complete-input .auto-complete-error {
  margin-bottom: 5px; }

.cr-auto-complete-input .auto-complete {
  position: relative; }
  .cr-auto-complete-input .auto-complete input {
    border-width: 0;
    border-radius: 6px;
    height: 48px; }
    .cr-auto-complete-input .auto-complete input:focus {
      background-color: #fff;
      border-color: #795afa;
      box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.25);
      outline: 0; }
    .cr-auto-complete-input .auto-complete input:focus-visible {
      border-color: #795afa;
      outline: 0; }
    .cr-auto-complete-input .auto-complete input:hover {
      border-color: #4324c7;
      box-shadow: 0 0 0 2px #4324c7; }
    .cr-auto-complete-input .auto-complete input::-webkit-input-placeholder {
      /* Edge */
      color: #829fb1; }
    .cr-auto-complete-input .auto-complete input::placeholder {
      color: #829fb1; }
    .cr-auto-complete-input .auto-complete input:disabled {
      background-color: #e9ecef;
      color: #829fb1; }
  .cr-auto-complete-input .auto-complete .menu {
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 6px;
    overflow: hidden;
    left: 0;
    padding: 5px 0; }
    .cr-auto-complete-input .auto-complete .menu .item {
      font-style: normal;
      font-weight: normal;
      font-size: 16px !important;
      color: #000000 !important;
      line-height: 40px;
      cursor: pointer;
      margin: 5px 0; }
    .cr-auto-complete-input .auto-complete .menu .item-highlighted {
      background-color: rgba(188, 213, 229, 0.3) !important; }

.cr-auto-complete-input .auto-complete-open .auto-complete-wrapper > div:first-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.cr-auto-complete-input .auto-complete-open input:focus {
  border-color: transparent;
  box-shadow: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.cr-auto-complete-input .auto-complete-open input:focus-visible {
  border-color: transparent;
  outline: 0; }

.cr-auto-complete-input .auto-complete-open input:hover {
  box-shadow: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.cr-auto-complete-input .auto-complete-wrapper {
  z-index: 2; }
  .cr-auto-complete-input .auto-complete-wrapper > div:first-child {
    height: 50px;
    border: 1px solid #c2d4e0;
    border-radius: 6px;
    display: block !important; }
  .cr-auto-complete-input .auto-complete-wrapper .dropdown-icon {
    position: absolute;
    width: 47px;
    height: 100%;
    right: 1px;
    border-left: 1px solid #c2d4e0;
    top: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 48px; }

.react-select__control {
  min-height: 50px !important;
  border-color: #c2d4e0 !important; }
  .react-select__control:focus {
    border-color: #795afa !important;
    box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.25) !important; }
  .react-select__control:hover {
    border-color: #4324c7 !important;
    box-shadow: 0 0 0 1px #4324c7 !important; }
  .react-select__control--is-focused {
    border-color: #795afa !important;
    box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.25) !important; }
  .react-select__control--is-disabled {
    background-color: #e9ecef !important; }

.react-select__indicator-separator {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  background-color: #c2d4e0 !important; }

.react-select__dropdown-indicator {
  padding: 19px !important; }

.react-select__control {
  border-radius: 6px !important; }

.react-select-container:focus {
  outline: none; }

body > [class^="css-"] .react-select__menu {
  border-color: #795afa !important;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(121, 90, 250, 0.15); }

.react-select-menu-open .react-select__control {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-color: #795afa !important;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(121, 90, 250, 0.15) !important;
  border-bottom: none; }

.react-select-menu-open .react-select__menu {
  border-color: #795afa !important;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(121, 90, 250, 0.15); }

.react-select-menu-open .react-select__indicator-separator {
  background-color: transparent !important; }

/* .react-select__menu-list{
  padding-top: 25px !important;
  padding-bottom: 23px !important;
} */
.react-select__option {
  padding: 8px 18px;
  color: #334856 !important; }

.react-select__single-value .value-container,
.react-select__option .value-container {
  display: flex; }
  .react-select__single-value .value-container .label,
  .react-select__option .value-container .label {
    min-width: 132px;
    margin-right: 16px; }
  .react-select__single-value .value-container .type,
  .react-select__option .value-container .type {
    width: calc(100% - 148px); }

.react-select__option,
.react-select__menu-notice--no-options {
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important; }

.react-select__menu-notice--no-options {
  color: #000 !important; }

.react-select__single-value {
  color: #334856 !important;
  width: calc(100% - 16px); }

.react-select__control--is-focused,
.react-select__control:focus,
.react-select__control:hover {
  border-color: #795afa !important; }

.react-select__menu {
  margin: 0 !important;
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-top: 0 !important;
  box-sizing: border-box !important;
  box-shadow: none !important;
  border: 1px solid #c2d4e0 !important;
  border-top: 0 !important;
  border-bottom-color: rgba(194, 212, 224, 0.5);
  z-index: 101 !important; }

.react-select__multi-value {
  background-color: #f7fbfd !important;
  border: 1px solid #c2d4e0;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  display: flex;
  min-height: 28px;
  flex-direction: row-reverse; }

.react-select__multi-value__remove {
  padding-left: 6px !important; }

.react-select__multi-value__label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px !important;
  color: #657894 !important;
  padding-right: 10px !important; }

.react-select__multi-value__remove {
  padding-left: 10px !important; }
  .react-select__multi-value__remove:hover {
    color: transparent !important;
    background-color: transparent !important; }

.react-select__option--is-focused {
  background-color: rgba(188, 213, 229, 0.3) !important; }

.react-select__group-heading .select-group-option-label {
  font-weight: bold;
  min-height: 41px;
  color: #334856;
  font-size: 16px; }

.react-select__clear-indicator {
  cursor: pointer; }

.react-select__dropdown-indicator {
  pointer-events: none; }

.player-container-ui {
  /* Safari */
  transition: width 2s;
  height: 50px;
  background-color: #374C59;
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
  align-items: center; }
  .player-container-ui .button-play {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
  .player-container-ui .player-volume {
    display: flex;
    align-items: center;
    cursor: pointer; }
  .player-container-ui .rc-slider .rc-slider-step {
    background: dimgrey; }
  .player-container-ui .rc-slider .rc-slider-track {
    background-color: #fff;
    z-index: 2;
    transition: width 0.01s ease-out; }
  .player-container-ui .rc-slider .rc-slider-handle {
    background-color: #fff;
    border-color: #fff;
    width: 12px;
    height: 4px;
    margin-top: 0;
    opacity: 0;
    border-radius: 0; }
  .player-container-ui .rc-slider-timer {
    margin: 0 10px;
    cursor: pointer; }
  .player-container-ui .rc-slider-volume {
    max-width: 80px;
    margin: 0 -5px; }
  .player-container-ui .slider-volume {
    display: none;
    width: 80px; }
  .player-container-ui .slider-volume-active {
    display: block;
    margin-left: 10px; }
  .player-container-ui .player-timer {
    color: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
    min-width: 85px;
    overflow: hidden; }
  .player-container-ui .divide {
    font-size: 10px;
    margin: 0 2px; }

.player-hidden {
  display: none; }

.RichEditor-root {
  background: #fff;
  border: 1px solid #d7e3ea;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  border-top: 0px;
  border-radius: 5px; }

.RichEditor-editor {
  cursor: text;
  font-size: 16px;
  padding: 0 15px 15px; }

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px; }

.public-DraftEditorPlaceholder-root {
  z-index: 0; }

.RichEditor-emptyText .public-DraftStyleDefault-block {
  height: 1px; }

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px; }

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none; }

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px; }

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 16px;
  padding: 20px; }

.RichEditor-controls {
  font-size: 14px;
  margin: 5px;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center; }

.RichEditor-divider {
  margin: 0px 10px;
  width: 1px;
  background-color: #d8e4eb; }

.RichEditor-styleButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 15px; }

.RichEditor-activeButton {
  color: #5890ff; }
  .RichEditor-activeButton svg path {
    fill: red; }

.RichEditor-toolbar {
  text-align: left;
  background: #f7fbfd;
  border-bottom: 1px solid #d7e3ea;
  border-top: 1px solid #d7e3ea;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  display: flex;
  height: 49px;
  position: relative; }

.RichEditor-icon-text {
  padding: 0; }

.RichEditor-increment-icon {
  margin-right: 5px; }

.RichEditor-decrement-icon {
  margin-left: 0;
  margin-top: 6px; }

.RichEditor-styleDropdownFonts {
  margin-left: 26px;
  width: 85% !important; }
  .RichEditor-styleDropdownFonts .react-select__menu {
    background-color: #f7fbfd;
    border-color: #d7e3ea !important; }
  .RichEditor-styleDropdownFonts .react-select__clear-indicator {
    padding: 1px; }
    .RichEditor-styleDropdownFonts .react-select__clear-indicator svg {
      width: 10px;
      height: 10px; }
  .RichEditor-styleDropdownFonts .react-select__control--is-focused {
    box-shadow: none !important; }
  .RichEditor-styleDropdownFonts .react-select__control {
    border: none;
    border-radius: 0 !important;
    background: transparent;
    min-width: 95px;
    min-height: 47px !important; }
    .RichEditor-styleDropdownFonts .react-select__control:hover, .RichEditor-styleDropdownFonts .react-select__control:focus {
      border-color: transparent !important;
      box-shadow: none !important; }
  .RichEditor-styleDropdownFonts .react-select__value-container {
    padding-left: 0;
    padding-right: 0; }
  .RichEditor-styleDropdownFonts .react-select__indicator-separator {
    display: none; }
  .RichEditor-styleDropdownFonts .react-select__placeholder {
    color: #657894 !important;
    font-size: 14px;
    margin: 0;
    text-transform: uppercase; }
  .RichEditor-styleDropdownFonts .react-select__dropdown-indicator {
    padding: 5px !important; }

.RichEditor-none::after {
  content: "";
  clear: both;
  display: block; }

.RichEditor-none::before {
  content: "";
  clear: both;
  display: block; }

.RichEditor-upload-icon {
  display: flex;
  align-items: center;
  margin: 0 15px;
  cursor: pointer; }

.RichEditor-tooltip {
  opacity: 1; }
  .RichEditor-tooltip .rc-tooltip-inner {
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding-bottom: 15px; }
    .RichEditor-tooltip .rc-tooltip-inner span {
      color: #334856;
      font-size: 14px;
      text-transform: initial; }
  .RichEditor-tooltip .rc-tooltip-arrow {
    border-color: transparent; }
    .RichEditor-tooltip .rc-tooltip-arrow:after {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background: #fff;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      top: -13px;
      left: -5px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); }
    .RichEditor-tooltip .rc-tooltip-arrow:before {
      content: "";
      display: block;
      width: 30px;
      height: 14px;
      background: #fff;
      position: absolute;
      top: -19px;
      z-index: 9;
      left: -15px; }

.editor-upload {
  position: relative; }

.editor-wrapper {
  z-index: 99;
  padding: 17px 18px 10px 16px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 456px;
  position: absolute;
  top: -115px;
  left: calc(50% - 220px); }
  .editor-wrapper.hyperlink-wrapper {
    top: -100px; }
  .editor-wrapper .component-form {
    display: flex;
    align-items: center;
    position: relative; }
    .editor-wrapper .component-form:after {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background: #fff;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      top: 76px;
      left: 197px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); }
    .editor-wrapper .component-form:before {
      content: "";
      display: block;
      width: 30px;
      height: 14px;
      background: #fff;
      position: absolute;
      top: 70px;
      z-index: 9;
      left: 190px; }
  .editor-wrapper .upload-files {
    height: 43px;
    padding: 0;
    flex: 1 1;
    flex-direction: row;
    align-items: center; }
    .editor-wrapper .upload-files .cr-icon-spin-container {
      margin-right: 20px; }
      .editor-wrapper .upload-files .cr-icon-spin-container svg {
        width: 20px;
        height: 20px; }
  .editor-wrapper .upload-save {
    margin-left: 15px;
    cursor: pointer; }
  .editor-wrapper .upload-description {
    font-size: 14px;
    color: #829fb1;
    margin-top: 10px; }
  .editor-wrapper .upload-form-has-files .cr-upload-files__drop-text-wrapper {
    opacity: 0; }
  .editor-wrapper .hyperlink-form {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .editor-wrapper .hyperlink-form::before {
      top: 52px;
      height: 12px; }
    .editor-wrapper .hyperlink-form::after {
      top: 57px; }
    .editor-wrapper .hyperlink-form .cr-textinput {
      flex: 1 1; }
    .editor-wrapper .hyperlink-form .input-container {
      margin-bottom: 0; }

.component-form .content-editable {
  width: 100%;
  height: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #c2d4e0;
  border-radius: 6px;
  min-height: 48px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
  max-width: 387px; }
  .component-form .content-editable:before {
    content: attr(placeholder);
    color: #829fb1; }
  .component-form .content-editable:hover, .component-form .content-editable:focus, .component-form .content-editable:active, .component-form .content-editable:focus-visible {
    border-color: #4324c7 !important;
    box-shadow: 0 0 0 1px #4324c7 !important; }

.RichEditor-edit-hyperlink {
  opacity: 1;
  z-index: 1049;
  max-width: initial; }
  .RichEditor-edit-hyperlink::before {
    content: "";
    background-color: transparent;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1; }
  .RichEditor-edit-hyperlink .rc-tooltip-inner {
    padding: 25px 18px 15px 16px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 456px; }
  .RichEditor-edit-hyperlink .rc-tooltip-content {
    position: relative;
    z-index: 2; }
    .RichEditor-edit-hyperlink .rc-tooltip-content .component-form {
      display: flex;
      align-items: center; }
    .RichEditor-edit-hyperlink .rc-tooltip-content .upload-save {
      margin-left: 15px;
      cursor: pointer; }
  .RichEditor-edit-hyperlink .rc-tooltip-arrow {
    border-color: transparent; }
    .RichEditor-edit-hyperlink .rc-tooltip-arrow:after {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background: #fff;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      top: -4px;
      left: -5px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); }
    .RichEditor-edit-hyperlink .rc-tooltip-arrow:before {
      content: "";
      display: block;
      width: 30px;
      height: 14px;
      background: #fff;
      position: absolute;
      top: -10px;
      z-index: 9;
      left: -15px; }
  .RichEditor-edit-hyperlink .edit-hyperlink-close {
    position: absolute;
    top: 7px;
    right: 20px;
    cursor: pointer; }

.DraftEditor-editorContainer {
  z-index: inherit; }

.cr-toggle-switch {
  display: flex;
  align-items: center; }
  .cr-toggle-switch__left-component {
    font-size: 15px;
    font-weight: 600;
    margin-right: 10px; }
  .cr-toggle-switch__right-component {
    font-size: 15px;
    font-weight: 600;
    margin-left: 10px; }
  .cr-toggle-switch .react-switch-handle {
    pointer-events: none; }

.cr-checkbox__checkbox {
  display: flex;
  align-items: center; }

.cr-checkbox__text {
  margin-left: 8px;
  cursor: pointer; }

.cr-checkbox__base-checkbox {
  position: relative; }

.cr-checkbox__checked-icon {
  position: absolute;
  left: 5px;
  top: 2px; }

.cr-checkbox__checkbox-green.rc-checkbox-checked .rc-checkbox-inner {
  border-color: #73a703 !important;
  background-color: #73a703 !important; }

.cr-checkbox .rc-checkbox-inner {
  width: 25px;
  height: 25px;
  border-color: #c2d4e0; }

.cr-checkbox .rc-checkbox-input {
  width: 100%;
  height: 100%;
  z-index: 99 !important; }

.cr-checkbox .rc-checkbox:hover .rc-checkbox-inner,
.cr-checkbox .rc-checkbox-input:focus + .rc-checkbox-inner {
  border-color: #224b8b; }

.cr-checkbox .rc-checkbox-checked .rc-checkbox-inner {
  border-color: #224b8b;
  background-color: #224b8b; }
  .cr-checkbox .rc-checkbox-checked .rc-checkbox-inner:after {
    display: none; }

.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner {
  background-color: #c2d4e0 !important;
  border-color: #c2d4e0 !important; }

.cr-radio__radio {
  display: flex;
  align-items: center; }

.cr-radio__text {
  margin-left: 5px;
  cursor: pointer; }

.cr-radio .rc-checkbox {
  width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 1px solid #c2d4e0; }

.cr-radio .rc-checkbox-inner {
  margin-left: 2px;
  margin-top: 2px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-color: transparent;
  border-radius: 50px; }
  .cr-radio .rc-checkbox-inner:after {
    display: none !important; }

.cr-radio .rc-checkbox-input {
  width: 100%;
  height: 100%;
  z-index: 99 !important; }

.cr-radio .rc-checkbox:hover .rc-checkbox-inner,
.cr-radio .rc-checkbox-input:focus + .rc-checkbox-inner {
  border-color: #224b8b;
  background-color: #224b8b; }

.cr-radio .rc-checkbox-checked .rc-checkbox-inner {
  border-color: #224b8b;
  background-color: #224b8b; }

.cr-radio .rc-checkbox-disabled .rc-checkbox-inner {
  border-color: #e9ecef !important;
  background-color: #e9ecef !important; }

.cr-button-add {
  background: #ffffff !important;
  border: none;
  border-radius: 30px;
  min-width: 256px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  height: 49px; }
  .cr-button-add span {
    color: #2d4364;
    font-weight: 800;
    font-size: 20px;
    max-width: calc(100% - 25px);
    margin-left: 17px;
    text-transform: capitalize; }
  .cr-button-add__icon-add {
    color: #8165f3;
    font-size: 25px; }

.cr-count-down__has-days {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.cr-count-down__col-days {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #829FB1;
  text-transform: uppercase; }
  .cr-count-down__col-days strong {
    margin-right: 5px; }

.cr-count-down__col-timer {
  color: #C12674;
  font-size: 20px;
  font-weight: 700; }

.cr-count-down__child-timer {
  color: #829FB1;
  font-size: 12px; }

.cr-count-down__negative {
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }
  .cr-count-down__negative .cr-count-down__col-days {
    color: #C12674; }
  .cr-count-down__negative .cr-count-down__child-timer {
    color: #C12674; }
  .cr-count-down__negative .cr-count-down__col-timer {
    color: #C12674; }
  .cr-count-down__negative .cr-count-down__has-days {
    display: none; }

.cr-count-down__negative-icon {
  font-size: 25px;
  font-weight: 800;
  color: #C12674;
  margin-right: 5px; }

.cr-count-down__negative-overdue-text {
  text-transform: uppercase;
  color: #C12674;
  font-size: 16px;
  font-weight: 800; }

.cr-alert-modal__body {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4rem 0; }

.cr-alert-modal__title {
  font-size: 50px;
  font-weight: 600;
  color: #324856;
  margin-top: 10px;
  max-width: 80%;
  margin: 0 auto; }

.cr-alert-modal__icon-check {
  color: #795afa; }

.tab-assign-team {
  background-color: initial !important;
  box-shadow: none !important; }

.cr-select-team__members {
  margin-bottom: 0; }

.cr-select-team .cr-textarea-evaluator__wrapper-top-length {
  display: none; }

.cr-select-team__container-users {
  width: 100%; }

.cr-select-team__col-user {
  position: relative; }

.cr-select-team__user-row {
  background-color: #ffffff;
  border-bottom: 1px solid #edf1f6;
  padding: 20px 21px 20px 25px; }
  .cr-select-team__user-row [class^="col-"] {
    margin-bottom: 0 !important; }
  .cr-select-team__user-row:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102); }

.cr-select-team__user-ooo .cr-select-team__user-name {
  color: #829fb1;
  display: flex;
  width: calc(100% - 30px);
  justify-content: space-between; }

.cr-select-team__user-ooo .cr-select-team__user-task-label,
.cr-select-team__user-ooo .cr-select-team__user-task-value {
  color: #829fb1; }

.cr-select-team__user-ooo .cr-select-team__user-avatar {
  opacity: 0.6; }

.cr-select-team__user-ooo .cr-select-team__user-role-button {
  cursor: not-allowed; }

.cr-select-team__out-of-office {
  color: #e40061;
  font-size: 11px;
  margin-top: 7px; }

.cr-select-team__user-info {
  display: flex;
  align-items: center; }
  .cr-select-team__user-info:after {
    content: "";
    width: 1px;
    height: 100%;
    display: block;
    position: absolute;
    background: #c2d4e0;
    right: 0;
    top: 0;
    bottom: 0; }

.cr-select-team__user-avatar {
  width: 46px !important;
  height: 46px !important;
  max-width: 100%;
  overflow: hidden;
  margin-right: 10px !important;
  float: none !important; }
  .cr-select-team__user-avatar .avatar-sort-name-text {
    font-size: 14px; }

.cr-select-team__vacation-icon {
  margin-right: 10px; }

.cr-select-team__user-name {
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  line-height: 26px;
  color: #111b2b; }

.cr-select-team__user-roles {
  flex-direction: row;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%; }

.cr-select-team__user-role-button {
  background: #f7fbfd;
  border: 1px solid #829fb1;
  box-sizing: border-box;
  border-radius: 50px;
  width: 117px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 5px 0;
  margin-right: 5px;
  color: #4324c7;
  padding: 0 15px; }

.cr-select-team__user-role-assigned {
  background: #305a99;
  color: #fff; }

.cr-select-team__user-role-disabled {
  color: #c2d4e0;
  border-color: #c2d4e0; }

.cr-select-team__user-tasks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  overflow: hidden; }

.cr-select-team__user-task-label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #829fb1; }
  @media (max-width: 768px) {
    .cr-select-team__user-task-label {
      font-size: 2vw; } }

.cr-select-team__user-info-right {
  width: calc(100% - 60px);
  position: relative; }

.cr-select-team__user-task {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  white-space: nowrap; }

.cr-select-team__user-task-current {
  margin-right: 5px; }

.cr-select-team__user-task-value {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #4628bf;
  margin-left: 5px; }

.cr-select-team__user-action {
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: 0px; }

.cr-select-team__user-role-title {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.cr-select-team__roles {
  align-items: center;
  margin-bottom: 45.5px; }
  @media (max-width: 768px) {
    .cr-select-team__roles {
      display: block; } }

.cr-select-team__role {
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 15px;
  display: inline-block;
  max-width: 190px;
  overflow: hidden; }
  @media (max-width: 768px) {
    .cr-select-team__role {
      margin-bottom: 20px; } }

.cr-select-team__role-info {
  flex-direction: row;
  display: flex;
  align-items: center; }

.cr-select-team__role-left {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column; }

.cr-select-team__assigned-user-avatar {
  width: 55px !important;
  height: 55px !important;
  margin-right: 12px; }

.cr-select-team__assigned-user-name {
  color: #224b8b;
  font-size: 16px;
  font-weight: 800;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  top: 21px;
  left: 10px;
  display: block; }

.cr-select-team__role-title {
  color: #b6cedd;
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase; }

.cr-select-team__assigned {
  color: #224b8b; }

.cr-select-team__role-image {
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  background-color: #d8e4eb;
  margin-right: 12px; }

.cr-select-team__assigned-user-info {
  position: relative; }

.cr-select-team__checked {
  position: absolute;
  bottom: 0;
  z-index: 2;
  background: #fff;
  border-radius: 100px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center; }

.cr-select-team__filter-row {
  justify-content: space-between;
  display: flex;
  border-bottom: 1px solid #c2d4e0;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 47.76px;
  margin-bottom: 22px; }
  .cr-select-team__filter-row .react-select-container {
    min-width: 180px; }

.cr-select-team__team-member-dropdown {
  display: flex;
  align-items: center; }

.cr-select-team__filter-dropdown-label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-right: 10px;
  color: #657894; }

.cr-select-team__filters {
  display: flex;
  margin-bottom: 27px; }

.cr-select-team__filter-role {
  color: #8165f3;
  font-size: 16px;
  font-weight: 700;
  margin-right: 20px;
  text-transform: uppercase;
  cursor: pointer; }

.cr-select-team__filter-selected {
  color: #224b8b; }

.cr-select-team__team-members {
  margin-bottom: 0 !important;
  overflow: hidden; }

.cr-select-team__settings {
  color: #9abcd1;
  cursor: pointer; }
  .cr-select-team__settings > span {
    font-size: 15px;
    font-weight: 400;
    margin-right: 15px; }

.cr-select-team__team-member-title {
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 29px;
  color: #334856;
  margin-left: 30px;
  display: flex;
  align-items: center; }

.cr-select-team__select-teams-member {
  margin-bottom: 0; }

.cr-select-team__team-members-title {
  padding-right: 25px !important; }

.cr-select-team__user-roles-reel {
  display: flex;
  align-items: center; }
  .cr-select-team__user-roles-reel .player-container {
    margin-right: 10px;
    flex: 1 1; }
  .cr-select-team__user-roles-reel .player-container,
  .cr-select-team__user-roles-reel .player-wrapper {
    margin-bottom: 0; }

.cr-select-team__team-members-title {
  text-transform: capitalize; }

.cr-select-team__members-items {
  display: flex;
  flex-direction: row;
  margin: 0 -12px;
  flex-wrap: wrap;
  align-items: flex-end; }

.cr-select-team__member-box {
  min-height: 42px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px; }

.cr-select-team__member-item {
  margin: 0 12px;
  margin-bottom: 12px; }

.cr-select-team__member-inactive .cr-select-team__member-box {
  background: #c2d4e0;
  border: 1px solid #c2d4e0;
  width: 195px;
  cursor: pointer; }

.cr-select-team__member-selected .cr-select-team__member-box {
  width: 195px;
  background: #795afa;
  border: 1px solid #795afa; }

.cr-select-team__member-assigned .cr-select-team__member-box {
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  padding: 5px 30px;
  position: relative;
  cursor: pointer; }
  .cr-select-team__member-assigned .cr-select-team__member-box:hover {
    border-color: #795afa; }
    .cr-select-team__member-assigned .cr-select-team__member-box:hover .cr-select-team__delete-icon {
      opacity: 1;
      visibility: visible; }

.cr-select-team__delete-icon {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: calc(50% - 10px);
  opacity: 0;
  visibility: hidden; }

.cr-select-team__vo-text {
  color: #795afa;
  margin-bottom: 8px;
  display: block;
  margin: 0 auto;
  text-align: center;
  border: none;
  background-color: transparent;
  cursor: pointer; }
  .cr-select-team__vo-text:focus {
    outline: none; }
  .cr-select-team__vo-text::-webkit-input-placeholder {
    color: #795afa; }
  .cr-select-team__vo-text::placeholder {
    color: #795afa; }

.cr-select-team__member-name {
  color: #334856;
  text-transform: capitalize;
  text-align: center; }

.cr-select-team__assign-vo-members {
  border-bottom: 1px solid #c2d4e0;
  padding-bottom: 31px !important; }
  .cr-select-team__assign-vo-members .cr-search-form {
    flex: 1 1; }
  .cr-select-team__assign-vo-members .cr-select-team__member-item {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(25% - 24px); }
  .cr-select-team__assign-vo-members .cr-select-team__member-box {
    width: 100%; }

.cr-select-team__vo-members-items-container {
  display: flex;
  align-items: flex-end; }
  .cr-select-team__vo-members-items-container .cr-select-team__members-items {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(100% - 53px); }

.cr-select-team__view-assign-vo-members {
  border-bottom: none; }
  .cr-select-team__view-assign-vo-members .cr-select-team__member-box {
    cursor: inherit; }

.cr-select-team__members-search {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px; }
  .cr-select-team__members-search .cr-search-form__input {
    padding: 14px; }

.cr-select-team__btn-assign-team {
  width: 204px !important; }

.cr-select-team__btn-assign-team {
  height: 49px; }
  .cr-select-team__btn-assign-team:disabled {
    background: #bccedb;
    border-color: #bccedb; }

.cr-select-team__assign-vo .cr-select-team__user-roles-reel {
  justify-content: flex-end; }

.cr-select-team__add-member-btn {
  width: 41px;
  height: 41px;
  border-radius: 6px;
  background-color: #c2d4e0;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 12px; }

.cr-select-team__minus-icon {
  background-color: #fff;
  width: 11px;
  height: 3px; }

.cr-select-team__assign-vo-sidebar-members {
  padding-left: 0;
  padding-right: 0; }
  .cr-select-team__assign-vo-sidebar-members .cr-select-team__vo-members-items-container {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 16px; }
  .cr-select-team__assign-vo-sidebar-members .cr-select-team__member-box {
    width: 191px; }
  .cr-select-team__assign-vo-sidebar-members .cr-search-form {
    width: 40%; }
  .cr-select-team__assign-vo-sidebar-members .cr-select-team__members-search {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: #f7fbfd;
    border-top: 1px solid #c2d4e0; }
    .cr-select-team__assign-vo-sidebar-members .cr-select-team__members-search .cr-search-form__input {
      padding-top: 10px;
      padding-bottom: 10px; }

.cr-select-team__tooltip-vo-label-text {
  color: #334856;
  font-size: 15px;
  line-height: 21px; }

@media screen and (min-width: 1200px) and (max-width: 1436px) {
  .cr-select-team__user-role-button {
    width: 100px;
    height: 35px;
    padding: 0 10px; }
  .cr-select-team__user-roles-reel-column {
    flex-direction: column;
    align-items: flex-start; }
    .cr-select-team__user-roles-reel-column .player-container {
      width: 100%;
      margin-right: 0; } }

.tooltip-vo-label {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  opacity: 1;
  padding: 0; }
  .tooltip-vo-label .rc-tooltip-content {
    background: #ffffff;
    padding: 10px;
    border-radius: 4px; }
  .tooltip-vo-label .rc-tooltip-arrow {
    border-top-color: #ffffff;
    border-width: 10px 10px 0;
    bottom: -8px; }
  .tooltip-vo-label .rc-tooltip-inner {
    padding: 0;
    background-color: #ffffff;
    box-shadow: none;
    min-height: auto; }

.cr-activity-log .tab-content {
  width: 100% !important;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px; }

.cr-activity-log__tab-title {
  font-weight: bold;
  font-size: 23px;
  color: #334856;
  text-transform: capitalize; }

.cr-activity-log .nav-link {
  height: 50px;
  width: 150px;
  justify-content: center; }
  .cr-activity-log .nav-link span {
    font-size: 16px;
    color: #657894;
    text-transform: capitalize; }
  .cr-activity-log .nav-link.nav-active {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px 6px 0 0;
    background-color: #fff;
    position: relative; }
    .cr-activity-log .nav-link.nav-active span {
      font-weight: bold; }
    .cr-activity-log .nav-link.nav-active::after {
      content: "";
      display: block;
      width: 150px;
      background-color: #fff;
      position: absolute;
      height: 20px;
      bottom: -10px; }

.cr-activity-log__list {
  padding: 0;
  list-style: none; }

.cr-activity-log__log-section {
  margin-bottom: 20px; }
  .cr-activity-log__log-section:nth-last-child {
    margin-bottom: 0; }

.cr-activity-log__activity-log {
  padding: 48px 47px; }

.cr-activity-log__log-date {
  color: #829fb1;
  text-transform: uppercase;
  margin-bottom: 16px;
  font-size: 14px; }

.cr-activity-log__log-item {
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row; }

.cr-activity-log__log-text {
  font-size: 16px;
  color: #000000;
  margin-left: 20px;
  max-width: 100%; }

.cr-activity-log__log-time {
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  width: 75px;
  min-width: 75px; }

.cr-activity-log .step-name {
  text-transform: capitalize;
  font-weight: bold;
  color: #795afa; }

.cr-activity-log .user-tag {
  color: #795afa; }

.cr-activity-log__scheduling-log {
  padding-left: 47px;
  padding-top: 48px;
  padding-bottom: 48px;
  padding-right: 76px; }

.cr-activity-log__scheduling-items-run-log {
  padding-top: 33px; }
  .cr-activity-log__scheduling-items-run-log .cr-activity-log__log-date {
    margin-bottom: 12px; }
  .cr-activity-log__scheduling-items-run-log .cr-activity-log__log-item {
    margin-bottom: 0; }
  .cr-activity-log__scheduling-items-run-log .cr-activity-log__schedule-log-date {
    margin-bottom: 27px; }
    .cr-activity-log__scheduling-items-run-log .cr-activity-log__schedule-log-date:last-child {
      margin-bottom: 0; }
  .cr-activity-log__scheduling-items-run-log .cr-activity-log__log-text {
    margin-left: 5px; }

.cr-activity-log__allocated {
  width: 608px;
  border-collapse: collapse;
  background: #f7fbfd;
  box-sizing: border-box;
  border-radius: 6px;
  border-style: hidden;
  box-shadow: 0 0 0 1px #c2d4e0;
  overflow: hidden; }
  .cr-activity-log__allocated thead th {
    border-bottom: 1px solid #c2d4e0;
    height: 38px; }
    .cr-activity-log__allocated thead th span {
      font-weight: 600;
      font-size: 12px;
      color: #829fb1;
      text-transform: uppercase; }
  .cr-activity-log__allocated tbody {
    background-color: #fff;
    height: 52px; }

.cr-activity-log__allocated-text {
  padding-left: 28px; }

.cr-activity-log__total {
  padding-left: 28px;
  font-weight: bold;
  font-size: 16px;
  color: #000000; }

.cr-activity-log__total-highlight {
  font-weight: bold;
  font-size: 16px;
  color: #e40061; }

.cr-activity-log__scheduling-top {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.cr-activity-log .btn-save-pdf {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px; }
  .cr-activity-log .btn-save-pdf span {
    font-size: 14px;
    margin-left: 12px;
    color: #795afa; }

.cr-activity-log__items-run-log-content {
  margin-top: 50px; }

.cr-activity-log__scheduling-log-content .cr-activity-log__schedule-log-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px; }

.cr-activity-log__scheduling-log-content .cr-activity-log__log-text {
  max-width: 100%;
  white-space: normal; }

.cr-page-processes-log .cr-comments-sidebar {
  margin-top: 50px; }

.cr-process-dialog {
  width: 743px;
  max-width: 743px; }
  .cr-process-dialog__header {
    border: none;
    padding-bottom: 0; }
  .cr-process-dialog__body {
    padding-top: 23px;
    padding-left: 48px;
    padding-right: 59px; }
  .cr-process-dialog__reschedule-button {
    margin-right: 7.5px !important; }
  .cr-process-dialog__edit-button {
    margin-top: 0 !important;
    margin-left: 7.5px !important; }
  .cr-process-dialog__client-name {
    font-size: 18px;
    line-height: 23px;
    color: #9cb0bd; }
  .cr-process-dialog__title {
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    color: #111b2b;
    margin-bottom: 53px; }
  .cr-process-dialog__player {
    margin-top: 20px; }
  .cr-process-dialog .evaluator-order-length * {
    color: #657894;
    font-weight: 400; }
  .cr-process-dialog .player-container-ui .player-timer {
    width: 130px; }
  .cr-process-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-top: none;
    padding-bottom: 41px;
    padding-left: 49px;
    padding-right: 50px; }
  .cr-process-dialog .modal-title {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left: 30px;
    position: relative; }
  .cr-process-dialog .modal-header {
    border-bottom-color: #edf1f6; }
    .cr-process-dialog .modal-header .close {
      display: none; }
  .cr-process-dialog__close {
    background: transparent;
    border: none;
    position: absolute;
    right: 30px;
    cursor: pointer; }
    .cr-process-dialog__close:active, .cr-process-dialog__close:focus {
      outline: none; }
  .cr-process-dialog .cr-checkbox__text {
    text-transform: capitalize; }
  .cr-process-dialog__line {
    height: 1px;
    width: 100%;
    background-color: #c4c4c4;
    margin: 10px 0; }
  .cr-process-dialog__order-title {
    font-size: 20px;
    font-weight: 600;
    color: #324856;
    margin-bottom: 10px; }
  .cr-process-dialog__spot-wrapper {
    margin-bottom: 10px; }
  .cr-process-dialog__spot-title {
    font-size: 16px;
    color: #334856; }
  .cr-process-dialog .cr-textarea-evaluator__main-title-wrapper {
    display: none; }
  .cr-process-dialog--schedule-again .cr-process-dialog__title {
    margin-bottom: 0; }
  .cr-process-dialog--schedule-again .modal-header {
    border-bottom: none;
    padding-top: 44px; }
  .cr-process-dialog--schedule-again .cr-process-dialog__close {
    display: none; }

.u_body {
  background: transparent !important; }

.cr-send-mail-dialog__modal-mail .modal-content {
  background-color: #f7fbfd; }

.cr-send-mail-dialog__modal-mail .cr-send-mail-dialog__buttons {
  padding-top: 29px;
  padding-bottom: 29px; }

.cr-send-mail-dialog__modal-mail .cr-send-mail-dialog__user-info {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #111b2b; }

.cr-send-mail-dialog__modal-mail .cr-send-mail-dialog__thank-you {
  display: none; }

.cr-send-mail-dialog__modal-mail .cr-send-mail-dialog__email-editor {
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 53px;
  padding-bottom: 0; }

.cr-send-mail-dialog__modal-mail .cr-send-mail-dialog__box-action {
  margin-bottom: 25px;
  padding-top: 40px;
  padding-bottom: 37px;
  margin-left: auto;
  max-width: inherit;
  margin-right: auto;
  margin-top: 0; }

.cr-send-mail-dialog__modal-mail .cr-send-mail-dialog__section-user {
  padding-top: 0;
  padding-left: 53px;
  padding-right: 41px;
  padding-bottom: 0px; }
  .cr-send-mail-dialog__modal-mail .cr-send-mail-dialog__section-user .avatar-sort-name-text {
    font-size: 20px; }

.cr-send-mail-dialog__modal-mail .cr-send-mail-dialog__box-action {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102); }

.cr-send-mail-dialog__modal-mail .cr-send-mail-dialog__message,
.cr-send-mail-dialog__modal-mail .cr-send-mail-dialog__welcome {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #111b2b;
  background: none; }

.cr-send-mail-dialog__modal-mail .cr-send-mail-dialog__box-action-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #829fb1; }

.cr-send-mail-dialog__modal-mail .cr-send-mail-dialog__input {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #334856; }

.cr-send-mail-dialog__modal-mail .cr-send-mail-dialog__label {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #334856; }

.cr-send-mail-dialog__modal-mail .cr-send-mail-dialog__box-sub-title {
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  color: #334856; }

.cr-send-mail-dialog__modal-mail .cr-send-mail-dialog__box-action-sub-title {
  color: #334856;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 26px;
  display: block; }

.cr-send-mail-dialog__modal-mail .cr-send-mail-dialog__box-action-sub-title strong {
  display: block; }

.cr-send-mail-dialog__modal-mail .cr-send-mail-dialog__box-action-sub-title > span {
  font-weight: normal;
  color: #334856; }

.cr-send-mail-dialog__modal-mail .cr-send-mail-dialog__deadline {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #334856;
  margin-bottom: 25px; }

.cr-send-mail-dialog__modal-mail .cr-send-mail-dialog__email-button {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  width: 200px;
  height: 44px; }

.cr-send-mail-dialog__modal-digital {
  width: 646px;
  max-width: 646px; }

.cr-send-mail-dialog__modal-production {
  max-width: 646px; }

.cr-send-mail-dialog__close {
  position: absolute;
  right: 21px;
  cursor: pointer; }

.cr-send-mail-dialog__header {
  padding-bottom: 0;
  border: none;
  position: relative;
  height: 43px;
  background: #fff; }
  .cr-send-mail-dialog__header .close {
    display: none !important; }

.cr-send-mail-dialog__body {
  padding: 0; }

.cr-send-mail-dialog__label {
  font-weight: 700;
  margin: 0;
  font-size: 16px !important;
  margin-right: 5px; }

.cr-send-mail-dialog__form-control {
  display: flex; }

.cr-send-mail-dialog__input {
  border: none;
  color: #657894;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  flex: 1 1;
  resize: none; }
  .cr-send-mail-dialog__input:focus {
    outline: none; }

.cr-send-mail-dialog__message {
  color: #657894;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  border: none;
  resize: none;
  text-align: left;
  text-align: left;
  min-height: 75px; }
  .cr-send-mail-dialog__message:focus {
    outline: none; }
  .cr-send-mail-dialog__message::-webkit-input-placeholder {
    color: #657894; }
  .cr-send-mail-dialog__message:-moz-placeholder {
    color: #657894; }
  .cr-send-mail-dialog__message::-moz-placeholder {
    color: #657894; }
  .cr-send-mail-dialog__message:-ms-input-placeholder {
    color: #657894; }

.cr-send-mail-dialog__header-information {
  padding: 0 2rem 2rem 2rem;
  border-bottom: 1px solid #ced4da;
  background: #fff; }

.cr-send-mail-dialog__email-editor {
  padding: 1rem 2rem 0 2rem;
  background: #f7fbfd;
  color: #657894;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.cr-send-mail-dialog__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem; }

.cr-send-mail-dialog__button-send {
  text-transform: uppercase;
  font-weight: 500;
  border-color: #ced4da;
  border-radius: 30px;
  min-width: 100px; }

.cr-send-mail-dialog__section-user {
  padding: 1rem 2rem 1.5rem 2rem;
  background: #f7fbfd; }

.cr-send-mail-dialog__user {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center; }

.cr-send-mail-dialog__user-info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  font-size: 13px;
  width: calc(100% - 75px); }
  .cr-send-mail-dialog__user-info span {
    text-transform: capitalize; }

.cr-send-mail-dialog__user-avatar {
  background-color: white;
  border-radius: 100px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 10px; }

.cr-send-mail-dialog__error {
  border-bottom: 1px solid #f85032; }

.cr-send-mail-dialog__box-action {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 30px 10px;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 20px auto;
  background-color: #fff;
  max-width: 500px; }

.cr-send-mail-dialog__box-action-title {
  text-transform: uppercase;
  color: #657894;
  font-size: 16px;
  margin-bottom: 0; }

.cr-send-mail-dialog__box-sub-title {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #212529;
  margin-top: 10px; }

.cr-send-mail-dialog__box-action-sub-title {
  text-transform: uppercase; }
  .cr-send-mail-dialog__box-action-sub-title > span {
    margin-left: 5px; }
  .cr-send-mail-dialog__box-action-sub-title strong {
    color: #212529; }

.cr-send-mail-dialog__flight-dates {
  display: flex;
  margin-bottom: 30px; }

.cr-send-mail-dialog__flight-dates-list {
  margin-left: 5px; }

.cr-send-mail-dialog__deadline {
  font-weight: 600;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #212529; }

.cr-send-mail-dialog__email-button {
  border-radius: 30px;
  min-width: 145px;
  text-transform: uppercase;
  background: #324856;
  border-color: #324856;
  display: flex;
  align-items: center;
  justify-content: center; }
  .cr-send-mail-dialog__email-button:hover {
    background: #324856;
    border-color: #324856; }

.cr-send-mail-dialog__btn-send-mail:focus {
  box-shadow: none; }

.cr-send-mail-dialog__button-send-digital {
  background: #ffffff;
  border: 1px solid #829fb1;
  box-sizing: border-box;
  border-radius: 24px;
  width: 137px;
  height: 44px; }
  .cr-send-mail-dialog__button-send-digital span {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #111b2b;
    text-transform: uppercase; }

.cr-profile {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 39px; }
  .cr-profile__title {
    color: #657894;
    margin: 0;
    font-size: 25px;
    margin-bottom: 20px; }
  .cr-profile__info-profile {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 480px) {
      .cr-profile__info-profile {
        flex-direction: column; } }
  .cr-profile__user-avatar {
    display: flex;
    justify-content: flex-start;
    align-items: center; }
  .cr-profile__avatar-wrapper {
    display: flex;
    position: relative;
    cursor: pointer; }
  .cr-profile__user-edit-icon {
    margin-left: -22px;
    box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 10px;
    height: 26px;
    width: 26px;
    text-align: center;
    border-radius: 50%;
    z-index: 10;
    background: white; }
    .cr-profile__user-edit-icon:hover {
      cursor: pointer; }
  .cr-profile__round-avatar {
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    padding: 10px; }
  .cr-profile__user-name {
    font-weight: 800;
    font-size: 23px;
    line-height: 29px;
    color: #111b2b;
    margin-left: 34px; }
  .cr-profile__user-position {
    font-weight: 800;
    font-size: 23px;
    line-height: 29px;
    text-align: right;
    color: #0f5589;
    margin-right: 20px; }
  .cr-profile__tab-container {
    margin-top: 37px; }
  .cr-profile__text {
    color: #657894;
    font-size: 16px;
    text-transform: capitalize; }
  .cr-profile__tab-link {
    font-weight: bold;
    font-size: 16px !important;
    line-height: 20px;
    color: #657894 !important;
    padding: 12px 40px !important; }
  .cr-profile__active {
    color: #495057 !important;
    background-color: #fff !important;
    box-shadow: -2px -3px 6px -2px rgba(0, 0, 0, 0.102);
    border: none !important; }
  .cr-profile__nav {
    padding: 0; }
  .cr-profile__form-content {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px;
    border-top-left-radius: 0; }
  .cr-profile__user-form-content .cr-profile__form-buttons {
    margin-top: 30px;
    padding-left: 42px; }
  .cr-profile__box-form-fields {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px; }
  .cr-profile__section-title {
    font-weight: 800;
    font-size: 23px;
    line-height: 29px;
    padding: 35px 40px 15px 40px;
    color: #111b2b;
    border-bottom: 1px solid #edf1f6;
    margin-bottom: 0; }
  .cr-profile__reset-col {
    margin-bottom: 0 !important; }
  .cr-profile__form-group-edit {
    margin-bottom: 33px; }
  .cr-profile__form-fields {
    padding: 44px 40px; }
  .cr-profile__view-label {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #829fb1;
    margin-bottom: 10px;
    text-transform: uppercase;
    display: block; }
  .cr-profile__view-value {
    font-size: 16px;
    line-height: 20px;
    color: #111b2b;
    display: block; }
  .cr-profile__view-row {
    margin-bottom: 43px; }
  .cr-profile__section-sub-title {
    font-weight: 800;
    font-size: 23px;
    line-height: 29px;
    color: #111b2b; }
  .cr-profile__section-title-wrapper {
    border-bottom: 1px solid #edf1f6;
    padding-left: 40px;
    padding-right: 40px; }
  .cr-profile__view-stations {
    padding-left: 40px; }
  .cr-profile__list-inline-item {
    background: #f7fbfd;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 13px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 20px;
    color: #657894;
    padding: 10px 25px;
    display: inline-block; }
  .cr-profile__list-inline {
    list-style: none;
    padding: 0;
    margin-top: 39px; }
  .cr-profile__list-inline-item-station {
    text-transform: uppercase; }
  .cr-profile__view-roles, .cr-profile__view-stations {
    margin-bottom: 45px; }
  .cr-profile__security-section {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px;
    margin-top: 20px; }
  .cr-profile__description-password-changed {
    padding: 48px 42px 56px 42px;
    font-size: 16px;
    line-height: 20px;
    color: #111b2b; }
  .cr-profile__edit-icon {
    position: absolute;
    bottom: 0;
    right: 0; }
  .cr-profile__buttons {
    padding-top: 30px;
    padding-left: 44px; }
  .cr-profile__creative-details-view {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px;
    margin-top: 21px; }
  .cr-profile__button-up-icon, .cr-profile__button-down-icon {
    cursor: pointer; }
  .cr-profile__creative-title {
    font-weight: 800;
    font-size: 23px;
    line-height: 29px;
    color: #111b2b;
    padding: 42px 42px 39px 42px;
    border-bottom: 1px solid #edf1f6;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .cr-profile__creative-view-content {
    padding: 41px 42px 42px 49px; }
  .cr-profile__creative-view-row-reel {
    margin-bottom: 0 !important; }
  .cr-profile__creative-view-row {
    margin-bottom: 25px; }
  .cr-profile__container-change-buttons {
    display: flex;
    justify-items: flex-start;
    align-items: center;
    flex: 1 1;
    height: 100%; }
  .cr-profile__button-submit {
    margin-right: 7px; }
  .cr-profile__button-edit {
    margin-right: 7px; }
  .cr-profile__button-cancel {
    margin-left: 7px; }
  .cr-profile .form-group label {
    text-transform: uppercase !important;
    display: block;
    color: #537a91; }
  .cr-profile__view-row-label {
    text-transform: uppercase !important;
    display: block;
    color: #537a91; }
  .cr-profile__placeholder-avatar {
    width: 100px;
    height: 100px;
    background: #aaa; }
  .cr-profile__password-buttons {
    padding-top: 33px;
    padding-left: 40px;
    padding-bottom: 43px; }
  .cr-profile__form-group-creative-edit {
    margin-bottom: 29px; }
    .cr-profile__form-group-creative-edit .delete-icon {
      top: calc(50% - 3px); }
  .cr-profile__creative-edit-reel .drop-area {
    height: 108px; }
  .cr-profile .cr-radio-buttons__radios {
    margin-top: 22px; }
    .cr-profile .cr-radio-buttons__radios .form-check-label {
      display: flex;
      align-items: center; }
  .cr-profile__field-view-value .delete-icon {
    top: calc(50% - 4px); }
  @media (min-width: 1599px) {
    .cr-profile {
      padding-left: 70px;
      padding-right: 70px; } }
  .cr-profile__button-ok {
    max-width: 150px;
    margin-top: 33px; }
  .cr-profile__limit-access-message {
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #000000; }
  .cr-profile__limit-access-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 50px; }
  .cr-profile__limit-access-modal {
    height: 100%; }
    .cr-profile__limit-access-modal .modal-content {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
  .cr-profile__box-section {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px;
    margin-top: 20px; }
  .cr-profile__list-dates {
    padding: 40px; }
    .cr-profile__list-dates .cr-profile__view-value {
      margin-bottom: 10px; }
  .cr-profile__add-button .cr-profile__add-icon {
    margin-right: 10px; }
  .cr-profile__add-button span {
    text-transform: capitalize; }
  .cr-profile__trash-date {
    margin-top: 41px;
    cursor: pointer; }
  .cr-profile__share-access-content {
    padding: 30px 40px; }
    .cr-profile__share-access-content .cr-profile__view-value {
      text-transform: capitalize; }
  .cr-profile__selected-users {
    padding-top: 28px; }
  .cr-profile__selected-user {
    background: #f7fbfd;
    border: 1px solid #c2d4e0;
    border-radius: 4px;
    height: 46px;
    padding: 0 10px;
    display: inline-flex;
    align-items: center;
    margin-right: 10px; }
    .cr-profile__selected-user span {
      color: #657894;
      font-size: 16px; }
  .cr-profile__delete-user {
    margin-right: 10px;
    cursor: pointer; }
  .cr-profile__view-share-access-section .cr-profile__selected-users {
    padding-top: 10px; }
  .cr-profile__workflow-content {
    padding: 40px 40px 24px 40px; }
    .cr-profile__workflow-content .cr-toggle-switch__left-component,
    .cr-profile__workflow-content .cr-toggle-switch__right-component {
      text-transform: uppercase;
      font-weight: 400;
      color: #829fb1;
      font-size: 17px; }
      .cr-profile__workflow-content .cr-toggle-switch__left-component.active,
      .cr-profile__workflow-content .cr-toggle-switch__right-component.active {
        color: #374c59;
        font-weight: 700; }
  .cr-profile__workflow-help-text {
    color: #111b2b;
    margin-bottom: 20px; }

.cr-color-picker__picker {
  display: block; }

.cr-color-picker__picker-trigger {
  width: 50px;
  height: 20px;
  display: block;
  cursor: pointer;
  box-shadow: 0 0 0 2px #fff inset;
  border: 1px solid #999;
  display: inline-block;
  padding: 2px;
  border-radius: 2px;
  -webkit-user-select: none;
  user-select: none; }

.cr-days-of-week {
  display: flex;
  margin: 5px 0;
  align-items: center; }
  .cr-days-of-week__label-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #43535c;
    text-transform: uppercase; }
  .cr-days-of-week__days {
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-flex; }
  .cr-days-of-week__day {
    margin-right: 10px; }
  .cr-days-of-week__day-text {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #334856;
    margin-bottom: 10px;
    display: block;
    text-align: center; }
  .cr-days-of-week__day-check {
    color: #2e558f;
    font-size: 20px;
    font-weight: 800;
    position: absolute;
    bottom: 0;
    left: calc(50% - 10px); }
  .cr-days-of-week__extra-checkbox-container {
    display: flex;
    align-items: center;
    margin-top: 33px;
    margin-left: 45px; }
    .cr-days-of-week__extra-checkbox-container .cr-checkbox__text {
      margin-left: 12px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #334856; }

.cr-incomplete-contract-modal {
  padding: 20px; }
  .cr-incomplete-contract-modal__title {
    font-weight: 600;
    color: #000;
    font-size: 25px;
    text-align: center; }
  .cr-incomplete-contract-modal__items {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center; }
  .cr-incomplete-contract-modal__item {
    color: #000;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    text-transform: capitalize; }
    .cr-incomplete-contract-modal__item b {
      margin: 5px 0; }
  .cr-incomplete-contract-modal__buttons {
    margin-top: 30px; }
  .cr-incomplete-contract-modal__button-dismiss {
    border-radius: 30px;
    max-width: 150px;
    margin: 0 auto;
    text-transform: uppercase; }

.cr-cities-list__title {
  text-transform: capitalize; }

.cr-cities-list__tree {
  min-height: 500px; }

.cr-cities-list__row-item-container {
  display: flex;
  align-items: center; }

.cr-cities-list__row-item-checkbox {
  margin-top: 2px; }

.cr-cities-list__row-item-title {
  margin: 0;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #324856; }

.cr-cities-list__row-item-icon {
  max-height: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-left: 10px;
  border: 0.5px solid #aaa;
  padding: 1px;
  justify-content: center;
  max-width: 30px; }
  .cr-cities-list__row-item-icon img {
    max-width: 100%; }

.cr-cities-list__row-item-check {
  margin-left: 10px; }

.cr-cities-list__tree-header {
  display: flex;
  flex-direction: "row"; }

.cr-cities-list__button-group {
  align-items: center;
  justify-content: flex-start;
  display: flex; }
  .cr-cities-list__button-group .btn {
    min-width: 40px !important; }

.cr-cities-list__buttons {
  padding-top: 20px; }

.cr-cities-list__tree-wrapper {
  height: 80vh; }

.cr-cities-list__search-count {
  margin: 0;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #324856; }

.cr-cities-list .ReactVirtualized__Grid {
  border: 1px solid #aaa; }

.cr-cities-list .ReactVirtualized__Grid__innerScrollContainer > .rst__node {
  border: 1px solid #aaa;
  border-bottom: none;
  background: #eee; }

.cr-cities-list .rst__lineChildren::after,
.cr-cities-list .rst__lineHalfVerticalTop::before,
.cr-cities-list .rst__lineHalfVerticalTop::after,
.cr-cities-list .rst__lineFullVertical::after,
.cr-cities-list .rst__lineHalfVerticalBottom::after,
.cr-cities-list .rst__lineFullVertical::before,
.cr-cities-list .rst__lineHalfVerticalBottom::before {
  background: transparent; }

.cr-cities-list .rst__expandButton {
  background-size: contain;
  opacity: .5;
  background-color: transparent;
  border-radius: 0%;
  box-shadow: 0 0 0 0px #000; }

.cr-cities-list button.rst__collapseButton {
  background-size: contain;
  opacity: .5;
  background-color: transparent;
  border-radius: 0%;
  box-shadow: 0 0 0 0px #000; }

.rst__rowContents {
  padding-right: 2px;
  position: relative;
  height: 100%;
  border: none;
  border-left: none !important;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  min-width: auto;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent; }

.cr-notifications {
  position: absolute;
  top: 65px;
  right: calc(50% - 250px);
  width: 500px;
  background: #fff;
  z-index: 999;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px; }
  .cr-notifications__notification-item {
    padding-top: 15px;
    padding-left: 28px;
    padding-bottom: 13px;
    padding-right: 13px;
    position: relative;
    border-bottom: 1px solid #ecf1f7;
    min-width: 150px; }
  .cr-notifications__btn-dismiss {
    background: transparent;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9aa4ce;
    cursor: pointer; }
    .cr-notifications__btn-dismiss:focus {
      outline: none; }
  .cr-notifications__subtitle {
    font-size: 14px;
    color: #9aa4ce;
    margin-bottom: 3px;
    text-transform: uppercase; }
  .cr-notifications__title {
    font-size: 18px;
    font-weight: bold;
    color: #202124;
    margin-bottom: 8px; }
  .cr-notifications__content {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .cr-notifications__content {
    font-size: 13px;
    color: #666666; }
  .cr-notifications__user-avatar {
    margin-right: 10px; }
    .cr-notifications__user-avatar.default {
      display: flex;
      align-items: center;
      justify-content: center; }
  .cr-notifications__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden; }
    .cr-notifications__avatar.default {
      background: #0f55b9;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center; }
  .cr-notifications__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px; }
  .cr-notifications__time {
    font-size: 10px;
    color: #9aa4ce;
    margin: 0;
    text-transform: uppercase; }
  .cr-notifications__btn-reply {
    border: none;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8165f3;
    cursor: pointer;
    -webkit-transform: rotate(0) scaleX(-1);
            transform: rotate(0) scaleX(-1);
    background: transparent; }
  .cr-notifications__form-reply {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 5px; }
  .cr-notifications__input-message {
    border: 1px solid #dddddd;
    border-radius: 20px;
    flex: 1 1;
    resize: none;
    padding: 5px;
    font-size: 13px;
    color: #666666; }
    .cr-notifications__input-message:focus {
      outline: none;
      border-color: #795afa; }
    .cr-notifications__input-message:disabled {
      background-color: transparent; }
  .cr-notifications__input-message-error {
    border-color: red; }
  .cr-notifications__btn-send {
    background: #795afa;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #fff;
    margin-left: 5px;
    border: none;
    cursor: pointer;
    align-items: center;
    display: flex; }
    .cr-notifications__btn-send:focus {
      outline: none; }
  .cr-notifications__no-notification {
    text-align: center;
    padding: 10px; }
  .cr-notifications #notifications-scroll {
    max-height: 447px;
    overflow-y: scroll; }

.nav-notifications {
  position: relative; }

.cr-prizes-details__total-wrapper {
  color: #707070;
  font-weight: 400;
  font-size: 18px;
  display: flex;
  justify-content: space-between; }

.cr-prizes-details__total-value {
  min-width: 70px;
  text-align: right; }

.cr-prizes-details__empty-container {
  margin-top: 35px;
  margin-bottom: 44px;
  background-color: #eff3f6;
  border-radius: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 30px 0;
  align-items: center;
  flex-direction: column; }

.cr-prizes-details__empty-text {
  color: #6f96d1;
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px; }

.cr-prizes-details__box-icon {
  font-size: 47px; }

.cr-prizes-details__prizes-list {
  margin-bottom: 50px; }

.cr-prizes-details__button {
  border-radius: 30px !important;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: 10px !important;
  border-color: #9abcd1;
  text-transform: uppercase;
  color: #795afa;
  margin-top: 0.5rem;
  background: #fff; }

.cr-prizes-details__button-gray {
  background: #c2d4e0 !important;
  color: #fff !important;
  border-color: #c2d4e0 !important; }
  .cr-prizes-details__button-gray:hover {
    background: #c2d4e0 !important;
    border-color: #c2d4e0 !important; }

.cr-prizes-closet__button-icon {
  margin-right: 10px; }

.cr-prizes-closet__button-add {
  border-color: #9abcd1;
  height: 43px; }

.cr-prizes-closet__top-title {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f0f3f4;
  padding-bottom: 13px; }

.cr-prize-item {
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
  background: #fff;
  width: 49%;
  display: inline-block;
  margin-bottom: 2%; }
  .cr-prize-item:nth-child(2n + 1) {
    margin-right: 2%; }
  .cr-prize-item__body {
    padding: 20px 10px 10px 10px; }
  .cr-prize-item__top-title {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .cr-prize-item__title {
    color: #334856;
    font-size: 18px;
    font-weight: 800;
    margin: 0; }
  .cr-prize-item__time {
    color: #9abcd1;
    font-size: 14;
    font-weight: 400;
    margin-bottom: 10px; }
  .cr-prize-item__value {
    color: #334856;
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase; }
    .cr-prize-item__value span {
      margin-left: 5px; }
  .cr-prize-item__description {
    color: #334856;
    font-size: 16px;
    margin-bottom: 15px;
    cursor: pointer;
    width: 100%;
    border: none;
    height: 50px;
    resize: none; }
  .cr-prize-item__label {
    color: #43535c;
    font-size: 12px;
    text-transform: uppercase;
    white-space: nowrap; }
  .cr-prize-item__trash-icon {
    cursor: pointer; }
  .cr-prize-item__form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
  .cr-prize-item__total-row {
    min-height: 50px;
    text-align: center;
    color: #000000;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-prize-item__group-col {
    flex: 1 1; }
  .cr-prize-item__group {
    flex: 2 1;
    padding: 0 10px; }
  .cr-prize-item__footer {
    border-top: 1px solid #f0f3f4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around; }
  .cr-prize-item__footer-left {
    flex: 1 1;
    border-right: 1px solid #f0f3f4;
    padding: 10px 0;
    text-align: center; }
  .cr-prize-item__footer-right {
    flex: 2 1;
    padding: 10px 0;
    text-align: center; }
  .cr-prize-item__footer-label {
    color: #374c59;
    font-size: 16px; }
  .cr-prize-item__footer-value {
    color: #795afa;
    font-size: 20px;
    font-weight: bold;
    margin-left: 5px; }
  .cr-prize-item__on-hold {
    background: #224b8b;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    text-align: center;
    display: inline-block;
    text-transform: capitalize;
    margin-left: 10px; }
  .cr-prize-item__value-section {
    display: flex;
    align-items: center;
    margin-bottom: 15px; }

.cr-add-prize-modal {
  max-width: 80%; }
  .cr-add-prize-modal.is-split-form .cr-add-prize-modal__header {
    border-bottom: none; }
  .cr-add-prize-modal.is-split-form .cr-prize-form {
    padding-bottom: 0 !important; }
    .cr-add-prize-modal.is-split-form .cr-prize-form .cr-prize-form__section-itemize-price {
      border-top: none;
      padding: 0; }
      .cr-add-prize-modal.is-split-form .cr-prize-form .cr-prize-form__section-itemize-price.cr-prize-form__section-title {
        justify-content: flex-end;
        height: -webkit-max-content;
        height: max-content;
        margin-bottom: 16px; }
        .cr-add-prize-modal.is-split-form .cr-prize-form .cr-prize-form__section-itemize-price.cr-prize-form__section-title > span {
          display: none; }
        .cr-add-prize-modal.is-split-form .cr-prize-form .cr-prize-form__section-itemize-price.cr-prize-form__section-title.smaller {
          justify-content: space-between; }
    .cr-add-prize-modal.is-split-form .cr-prize-form .cr-prize-form__section-itemize-price + .cr-prize-form__fields {
      padding: 0; }
    .cr-add-prize-modal.is-split-form .cr-prize-form__section-form .cr-prize-form__fields {
      padding: 0; }
    .cr-add-prize-modal.is-split-form .cr-prize-form__allocation-fields {
      padding: 0; }
  .cr-add-prize-modal__header {
    text-align: center;
    display: flex;
    align-items: center;
    padding-left: 49px;
    padding-right: 49px;
    height: 74px;
    border-bottom: 1px solid #edf1f6; }
    .cr-add-prize-modal__header .modal-title {
      font-weight: bold;
      font-size: 23px;
      line-height: 29px;
      color: #334856; }
    .cr-add-prize-modal__header .close {
      opacity: 1;
      font-size: 28px;
      margin-right: -40px; }
      .cr-add-prize-modal__header .close span {
        color: #657894; }
  .cr-add-prize-modal .cr-prize-form__form {
    margin-top: 0; }
  .cr-add-prize-modal .modal-body {
    padding: 0; }
  .cr-add-prize-modal .cr-prize-form__section-form {
    box-shadow: none; }
  .cr-add-prize-modal .cr-prize-form__section-title {
    padding-left: 49px;
    padding-right: 49px;
    border-bottom: 0; }
  .cr-add-prize-modal .cr-prize-form__fields {
    padding-left: 49px;
    padding-right: 49px;
    padding-bottom: 0;
    padding-top: 27px; }
  .cr-add-prize-modal .cr-prize-form__buttons {
    padding-left: 49px;
    padding-right: 49px;
    padding-bottom: 30px; }

.cr-city-modal .modal-title {
  font-size: 20px;
  color: #657894;
  text-transform: capitalize;
  font-weight: 600;
  color: #324856; }

.cr-city-modal__label-checkbox {
  display: flex;
  align-items: center; }
  .cr-city-modal__label-checkbox > span {
    margin-left: 10px; }

.cr-city-modal__change-icon {
  color: #795afa;
  text-transform: uppercase;
  margin-left: 20px;
  text-decoration: underline;
  cursor: pointer; }

.cr-city-modal__icon {
  background-color: white;
  border-radius: 100px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 10px; }

.cr-city-modal .form-group label {
  text-transform: uppercase !important;
  color: #537a91; }

.cr-city-modal .form-group .form-check-label {
  color: #212529; }

.cr-admin-rights__label-checkbox {
  display: flex;
  align-items: center; }
  .cr-admin-rights__label-checkbox .cr-checkbox {
    margin-right: 10px; }

.cr-admin-rights__content-form {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  padding: 40px;
  padding-bottom: 0; }

.cr-admin-rights__form-buttons {
  margin-top: 20px;
  margin-left: 40px; }
  .cr-admin-rights__form-buttons .btn-blue {
    margin-right: 14px; }

.cr-admin-rights .form-group label {
  text-transform: uppercase !important;
  color: #537a91; }

.cr-admin-rights .form-group .form-check-label {
  color: #212529; }

.cr-admin-rights__field-view-value {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #111b2b;
  text-transform: capitalize;
  display: block; }

.cr-admin-rights__form-group-view {
  margin-bottom: 49px; }

.cr-admin-rights__col-reset {
  margin-bottom: 0; }

.cr-select-effective {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .cr-select-effective__title {
    font-size: 16px !important; }
  .cr-select-effective__item {
    align-items: center;
    text-transform: capitalize;
    margin-right: 10px;
    padding: 5px 10px;
    cursor: pointer; }
  .cr-select-effective__remove-icon {
    color: #dc3545;
    margin-left: 10px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 8px; }
  .cr-select-effective__list-items {
    background-color: #fff;
    padding: 10px;
    display: flex;
    border-radius: 5px;
    box-shadow: 2px 4px 10px #aaa;
    height: 200px;
    overflow: scroll; }
    .cr-select-effective__list-items ul {
      list-style: none;
      padding: 0; }
  .cr-select-effective__list-selected-items {
    background-color: #fff;
    padding: 10px;
    display: flex;
    border-radius: 5px;
    box-shadow: 2px 4px 10px #aaa;
    height: 200px;
    overflow: scroll; }
    .cr-select-effective__list-selected-items ul {
      list-style: none;
      padding: 0; }
  .cr-select-effective__selected-item {
    align-items: center;
    text-transform: capitalize;
    margin-right: 10px;
    padding: 5px 10px;
    padding-right: 20px;
    position: relative;
    cursor: pointer; }
  .cr-select-effective__list-items-disabled {
    background-color: #e2e2e2; }

.cr-security-policy__title {
  font-weight: 800;
  font-size: 23px;
  line-height: 29px;
  color: #111b2b;
  margin-bottom: 0;
  padding-top: 59px;
  padding-bottom: 32px;
  border-radius: 0 !important;
  border-bottom: 0; }

.cr-security-policy__content-form {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px; }

.cr-security-policy__form-buttons {
  margin-top: 30px;
  padding-left: 40px; }
  .cr-security-policy__form-buttons .btn {
    margin-right: 14px; }

.cr-security-policy .form-group label {
  text-transform: uppercase !important;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #43535c; }

.cr-security-policy__view-wrapper label {
  text-transform: uppercase !important;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #829fb1; }

.cr-security-policy__field-view-value {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #111b2b;
  text-transform: capitalize;
  display: block; }

.cr-security-policy__form-group-view {
  margin-bottom: 49px; }

.cr-station-form__station-wrapper {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  background-color: #fff;
  border-radius: 6px; }

.cr-station-form__products-wrapper {
  box-shadow: none !important; }
  .cr-station-form__products-wrapper .cr-station-form__title {
    height: unset;
    padding-bottom: 25px; }

.cr-station-form__section-mm-checkbox {
  text-transform: uppercase;
  font-size: 16px;
  margin-left: 30px; }

.cr-station-form__section-toggle-switch {
  margin-right: 100px; }
  .cr-station-form__section-toggle-switch .cr-toggle-switch__left-component, .cr-station-form__section-toggle-switch .cr-toggle-switch__right-component {
    font-size: 16px !important; }
    .cr-station-form__section-toggle-switch .cr-toggle-switch__left-component:not(.active), .cr-station-form__section-toggle-switch .cr-toggle-switch__right-component:not(.active) {
      color: #829fb1; }

.cr-station-form__title {
  border-bottom: 1px solid #edf1f6;
  height: 66px;
  display: flex;
  padding-left: 37px;
  align-items: center;
  margin-bottom: 0; }
  .cr-station-form__title span {
    color: #334856;
    font-size: 23px;
    margin-bottom: 0;
    font-weight: bold;
    text-transform: capitalize; }

.cr-station-form__content-form, .cr-station-form__content-view {
  padding: 33px 0; }
  .cr-station-form__content-form .form-group, .cr-station-form__content-view .form-group {
    margin-bottom: 27px; }

.cr-station-form .react-select__placeholder {
  color: #829fb1 !important; }

.cr-station-form__col-reset {
  margin-bottom: 0; }

.cr-station-form .form-group label {
  text-transform: uppercase !important;
  display: block;
  color: #43535c; }

.cr-station-form .form-group .cr-days-of-week__label-text {
  display: none; }

.cr-station-form__view-wrapper label {
  text-transform: uppercase !important;
  display: block;
  color: #537a91; }

.cr-station-form__to-title {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0; }

.cr-station-form__view-value {
  color: #212529;
  font-size: 16px;
  font-weight: normal; }

.cr-station-form__form-buttons {
  margin-top: 20px;
  padding-left: 37px; }
  .cr-station-form__form-buttons .btn {
    text-transform: uppercase;
    margin-right: 14px; }

.cr-station-form__divider {
  height: 1px;
  width: 100%;
  background-color: #edf1f6;
  margin-bottom: 36px;
  margin-top: 12px; }

.cr-station-form__form-padding {
  padding-left: 37px;
  padding-right: 35px; }

.cr-station-form .cr-days-of-week__days .cr-checkbox .rc-checkbox-inner {
  width: 22px;
  height: 22px; }

.cr-station-form .cr-days-of-week__days .cr-checkbox .cr-checkbox__checked-icon svg {
  width: 14px; }

.cr-station-form .cr-days-of-week__days .cr-days-of-week__day-text {
  margin-bottom: 0;
  color: #000; }

.cr-station-form__station-privileges {
  font-size: 16px;
  line-height: 20px;
  color: #000000; }

.cr-station-form__product-int-list {
  margin-top: 25px; }

.cr-station-form__assigned-product {
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  margin-right: 15px; }

.cr-station-form__upload-description {
  font-size: 14px;
  color: #657894;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column; }

.cr-station-form__help-text {
  font-size: 14px;
  line-height: 18px;
  color: #657894;
  max-width: 257px;
  display: block;
  margin-top: 22px; }

.cr-station-form__selected-single-eligible {
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: #c2d4e0;
  border-radius: 6px;
  height: 50px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #829fb1;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.cr-station-form__section-enterprise {
  margin-left: 47.76px; }

.cr-select-multiple-effective__left-vertical {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin-right: 40px; }

.cr-select-multiple-effective__right-box {
  margin: 10px 0; }

.cr-select-multiple-effective__title {
  font-size: 16px !important; }

.cr-select-multiple-effective__item {
  align-items: center;
  text-transform: lowercase;
  margin: 2px 0;
  margin-right: 10px;
  padding: 5px 10px;
  cursor: pointer;
  position: relative; }

.cr-select-multiple-effective__item-text-capitalize {
  text-transform: capitalize; }

.cr-select-multiple-effective__item-selected {
  background: #2e558f;
  color: #fff;
  padding-right: 20px; }

.cr-select-multiple-effective__remove-icon {
  color: #dc3545;
  margin-left: 10px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 8px; }

.cr-select-multiple-effective__list-items {
  background-color: #fff;
  padding: 10px;
  display: flex;
  border-radius: 5px;
  box-shadow: 2px 4px 10px #aaa;
  height: 200px;
  overflow: scroll; }
  .cr-select-multiple-effective__list-items ul {
    list-style: none;
    padding: 0; }

.cr-select-multiple-effective__list-selected-items {
  background-color: #fff;
  padding: 10px;
  display: flex;
  border-radius: 5px;
  box-shadow: 2px 4px 10px #aaa;
  height: 200px;
  overflow: scroll; }
  .cr-select-multiple-effective__list-selected-items ul {
    list-style: none;
    padding: 0; }

.cr-select-multiple-effective__selected-item {
  align-items: center;
  text-transform: lowercase;
  margin-right: 10px;
  padding: 5px 10px;
  padding-right: 20px;
  position: relative;
  cursor: pointer; }

.cr-select-multiple-effective__buttons {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: -60px;
  justify-content: space-between;
  height: 200px;
  bottom: 0; }

.cr-select-multiple-effective__left-box {
  position: relative; }

.cr-select-multiple-effective__button {
  font-size: 30px !important; }

.cr-select-multiple-effective__content {
  background: #fff;
  margin-bottom: 30px;
  margin-left: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-right: 0; }

.cr-server-form__box-section {
  margin-top: 20px; }

.cr-server-form__section-title {
  color: #000000;
  cursor: pointer; }

.cr-server-form__section-toggle-switch {
  margin-right: 50px; }
  .cr-server-form__section-toggle-switch .cr-toggle-switch {
    color: #829fb1; }
    .cr-server-form__section-toggle-switch .cr-toggle-switch .active {
      color: #334856; }

.cr-server-form__section-fields {
  margin-top: 0;
  padding: 44px; }

.cr-server-form__env-field-wrapper {
  border: 1px solid #c2d4e0;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; }

.cr-server-form__file-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0; }
  .cr-server-form__file-actions div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-left: 20px; }
    .cr-server-form__file-actions div svg {
      height: 24px;
      width: 24px; }

.cr-server-form__title-section {
  font-weight: 800;
  font-size: 23px;
  line-height: 29px;
  color: #111b2b;
  padding: 30.67px 44px 20px 44px;
  border-bottom: 1px solid #edf1f6; }

.cr-server-form__logo {
  background-color: white;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 10px;
  border-radius: initial !important;
  max-height: 100%;
  width: auto !important;
  margin-bottom: 10px; }

.cr-server-form__view-logo {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  padding: 22px; }
  .cr-server-form__view-logo .cr-server-form__logo-img {
    max-height: 50px; }
  .cr-server-form__view-logo .cr-server-form__logo {
    width: 100% !important;
    display: inline-block;
    margin-left: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: none;
    background-origin: content-box;
    padding: 22px; }

.cr-server-form__change-logo {
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px; }

.cr-server-form__view-logo {
  margin-bottom: 20px; }

.cr-server-form__picker-trigger {
  width: 50px;
  height: 20px;
  display: block;
  box-shadow: 0 0 0 2px #fff inset;
  border: 1px solid #999;
  display: inline-block;
  padding: 2px;
  border-radius: 2px;
  -webkit-user-select: none;
  user-select: none;
  margin-left: 5px; }

.cr-server-form .list-group-item-flex {
  display: flex;
  align-items: center; }

.cr-server-form__button-cancel {
  margin-right: 0 !important; }

.cr-server-form .list-group-item span {
  text-transform: uppercase !important;
  color: #537a91; }

.cr-server-form__content-form {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-top-left-radius: 0; }

.cr-server-form__form-buttons {
  margin-top: 32px;
  padding: 0 44px !important; }
  .cr-server-form__form-buttons .btn {
    text-transform: uppercase;
    margin-right: 14px; }

.cr-server-form__view-data {
  display: inline-block;
  color: #212529; }
  .cr-server-form__view-data span {
    color: #212529 !important; }

.cr-server-form__admins-list {
  margin-bottom: 60px; }
  .cr-server-form__admins-list table {
    background: #ffffff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 6px;
    border-collapse: inherit;
    border-spacing: 0; }
  .cr-server-form__admins-list tr th {
    text-transform: uppercase !important;
    color: #537a91;
    font-weight: normal;
    border: none; }
  .cr-server-form__admins-list td {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #334856; }

.cr-server-form__admin-edit {
  background: transparent;
  color: #795afa;
  border: none; }
  .cr-server-form__admin-edit:hover {
    background: transparent !important;
    color: #795afa !important;
    border: none !important;
    box-shadow: none !important; }
  .cr-server-form__admin-edit:active {
    background: transparent !important;
    color: #795afa !important;
    border: none !important;
    box-shadow: none !important; }
  .cr-server-form__admin-edit:focus {
    background: transparent !important;
    color: #795afa !important;
    border: none !important;
    box-shadow: none !important; }

.cr-server-form__admin-trash {
  background: transparent;
  color: #795afa;
  border: none; }
  .cr-server-form__admin-trash:hover {
    background: transparent !important;
    color: #795afa !important;
    border: none !important;
    box-shadow: none !important; }
  .cr-server-form__admin-trash:active {
    background: transparent !important;
    color: #795afa !important;
    border: none !important;
    box-shadow: none !important; }
  .cr-server-form__admin-trash:focus {
    background: transparent !important;
    color: #795afa !important;
    border: none !important;
    box-shadow: none !important; }

.cr-server-form__button-add-new-admin {
  padding: 11px 20px;
  background: #ffffff;
  border: 1px solid #795afa;
  box-sizing: border-box;
  border-radius: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #795afa; }
  .cr-server-form__button-add-new-admin:hover {
    background: #ffffff;
    color: #795afa;
    border: 1px solid #795afa;
    box-shadow: none !important; }
  .cr-server-form__button-add-new-admin:active {
    background: #ffffff;
    color: #795afa;
    border: 1px solid #795afa;
    box-shadow: none !important; }
  .cr-server-form__button-add-new-admin:focus {
    background: #ffffff;
    color: #795afa;
    border: 1px solid #795afa;
    box-shadow: none !important; }

.cr-server-form__icon-plus {
  margin-right: 10px; }

.cr-server-form__domain-text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #334856;
  margin-bottom: 36px;
  margin-left: 13px; }

.cr-server-form__subdomain {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.cr-server-form .cr-handle-assets__file-buttons .btn {
  padding: 10px 5px; }

.cr-server-form__security-policy label {
  text-transform: uppercase !important;
  display: block;
  color: #537a91; }

.cr-server-form__security-link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  color: #795afa; }

.cr-server-form__server-view-value img {
  max-width: 100%; }

.cr-server-form__server-view-value {
  font-size: 16px;
  line-height: 20px;
  color: #111b2b; }

.cr-server-form__row-content {
  padding: 44px;
  margin: 0; }

.cr-server-form__row-logo-content {
  align-items: flex-start; }
  .cr-server-form__row-logo-content .form-group {
    margin-bottom: 0 !important; }
  .cr-server-form__row-logo-content .input-container {
    margin-bottom: 30px !important; }

.cr-server-form__assigned-wrapper {
  display: flex; }
  .cr-server-form__assigned-wrapper > div {
    flex: 0 0 66%; }

.cr-server-form__assigned-entities {
  display: flex;
  flex-wrap: wrap; }

.cr-server-form__assigned-entry-column {
  flex: 0 0 50%; }

.cr-server-form__assigned-entry {
  display: flex;
  align-items: center;
  width: -webkit-max-content;
  width: max-content; }

.cr-server-form__assigned-entry-market {
  color: #334856;
  font-size: 20px;
  font-weight: 600; }

.cr-server-form__assigned-entry-stations {
  color: #537a91;
  font-size: 16px;
  font-weight: 400;
  margin-left: 8px; }

.cr-server-form__dial-info-popover {
  opacity: 1; }
  .cr-server-form__dial-info-popover .rc-tooltip-content {
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); }
  .cr-server-form__dial-info-popover .rc-tooltip-arrow {
    border-top-color: #ffffff; }
  .cr-server-form__dial-info-popover .rc-tooltip-inner {
    background-color: #ffffff;
    color: #334856;
    font-size: 16px;
    padding: 24px; }

.cr-server-form__btn-assign.btn {
  border: 1px solid #795afa;
  color: #334856;
  font-weight: 800;
  margin-right: 0;
  display: flex;
  align-items: center;
  font-size: 19px;
  text-transform: none !important; }
  .cr-server-form__btn-assign.btn svg {
    margin-right: 16px; }
  .cr-server-form__btn-assign.btn.disabled {
    border: 1px solid #bccedb;
    color: #bccedb; }
    .cr-server-form__btn-assign.btn.disabled svg {
      color: #bccedb !important; }

.cr-server-form__empty-assignment {
  font-size: 24px;
  color: #537a91; }

.cr-server-form__server-view-container .form-group, .cr-server-form__box-server-details .form-group, .cr-server-form__body .form-group {
  margin-bottom: 39px; }

.cr-server-form__server-view-container label, .cr-server-form__box-server-details label, .cr-server-form__body label {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #43535c;
  margin-bottom: 12px;
  text-transform: uppercase; }

.cr-server-form__server-view-container .cr-handle-assets__file-name-view, .cr-server-form__box-server-details .cr-handle-assets__file-name-view, .cr-server-form__body .cr-handle-assets__file-name-view {
  color: #795afa; }

.cr-server-form .cr-file-view__file-name {
  color: #795afa;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.cr-server-form__server-edit-container label {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #43535c;
  text-transform: uppercase; }

.cr-server-form__server-edit-container .form-group {
  margin-bottom: 30px; }

.cr-server-form__server-edit-container .cr-file-view__file-buttons {
  min-width: 130px;
  display: flex; }

.cr-server-form__server-edit-container .col,
.cr-server-form__server-edit-container [class^="col-"] {
  margin-bottom: 0 !important; }

.cr-server-form__view-value-bold {
  font-weight: bold;
  color: #374c59;
  font-size: 17px; }

.cr-server-form__module-view-name {
  display: block;
  margin: 8px 0; }

.cr-server-form__no-margin {
  margin-bottom: 0; }

.cr-server-form__last-form-group {
  margin-bottom: 9px !important; }

.cr-server-form .cr-toggle-switch {
  color: #829fb1; }
  .cr-server-form .cr-toggle-switch .active {
    font-weight: bold;
    color: #334856; }

.cr-server-form .cr-checkbox {
  display: inline-block; }

.cr-server-form .mt-30 {
  margin-top: 30px; }

.cr-server-form__upload-description {
  font-size: 14px;
  line-height: 17.57px;
  color: #657894;
  flex: 1 1;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column; }

.cr-server-form__sever-logo-upload, .cr-server-form__background-upload-left {
  width: 63%;
  margin-right: 23px;
  position: relative; }
  .cr-server-form__sever-logo-upload .cr-file-view__file-item, .cr-server-form__background-upload-left .cr-file-view__file-item {
    padding: 0 20px; }
    .cr-server-form__sever-logo-upload .cr-file-view__file-item .cr-file-view__file-buttons, .cr-server-form__background-upload-left .cr-file-view__file-item .cr-file-view__file-buttons {
      align-items: center; }
      .cr-server-form__sever-logo-upload .cr-file-view__file-item .cr-file-view__file-buttons button, .cr-server-form__background-upload-left .cr-file-view__file-item .cr-file-view__file-buttons button {
        display: flex;
        align-items: center; }

.cr-server-form .image {
  width: 100% !important;
  height: 156px !important;
  background-color: transparent;
  background-origin: content-box;
  padding: 22px; }

.cr-server-form__background-upload, .cr-server-form__server-logo-edit {
  display: flex;
  align-items: flex-start;
  justify-content: center; }
  .cr-server-form__background-upload .cr-file-view,
  .cr-server-form__background-upload .cr-file-view__file-item, .cr-server-form__server-logo-edit .cr-file-view,
  .cr-server-form__server-logo-edit .cr-file-view__file-item {
    margin-bottom: 0; }

.cr-server-form__input-col .cr-textinput {
  display: flex;
  flex-direction: column; }

.cr-server-form__section-mm-checkbox {
  margin-left: 40px; }
  .cr-server-form__section-mm-checkbox .rc-checkbox-inner {
    width: 22px;
    height: 22px; }
  .cr-server-form__section-mm-checkbox .cr-checkbox__text {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #334856;
    text-transform: uppercase; }
  .cr-server-form__section-mm-checkbox .cr-checkbox__checked-icon {
    left: 4px;
    top: -1px; }

.cr-server-form__help-text {
  font-size: 14px;
  color: #657894;
  margin-top: 28px;
  display: block; }

.cr-server-form .modal-body .cr-server-form__server-view-value {
  word-break: break-all;
  white-space: break-spaces; }

.cr-server-form__box-multi-market-details {
  margin-left: 47.6px; }

.cr-calendar-events-modal {
  max-width: 95%; }
  .cr-calendar-events-modal .rbc-time-content .rbc-day-slot.ps-rbc-slot-current-scroll .cr-popover-event-detail {
    left: auto !important;
    right: auto;
    top: 0; }
  .cr-calendar-events-modal .rbc-time-content .rbc-day-slot.ps-rbc-slot-current-scroll + .rbc-day-slot .cr-popover-event-detail {
    left: calc(100% + 20px); }
  .cr-calendar-events-modal .ps-calendar-events .rbc-time-header-gutter {
    border-right: 1px solid #ddd; }
  .cr-calendar-events-modal .ps-calendar-events .rbc-event-content {
    position: relative;
    overflow: inherit; }
  .cr-calendar-events-modal .rbc-time-header-content {
    display: flex;
    flex: 1 0;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;
    position: relative;
    border-left: 0; }

.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0; }

button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled].rbc-btn {
  cursor: not-allowed; }

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0; }

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch; }

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit; }

.rbc-abs-full, .rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.rbc-ellipsis, .rbc-event-label, .rbc-row-segment .rbc-event-content, .rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.rbc-rtl {
  direction: rtl; }

.rbc-off-range {
  color: #999999; }

.rbc-off-range-bg {
  background: #e6e6e6; }

.rbc-header {
  overflow: hidden;
  flex: 1 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #ddd; }
  .rbc-header + .rbc-header {
    border-left: 1px solid #ddd; }
  .rbc-rtl .rbc-header + .rbc-header {
    border-left-width: 0;
    border-right: 1px solid #ddd; }
  .rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
    color: inherit;
    text-decoration: none; }

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4; }

.rbc-today {
  background-color: #eaf6ff; }

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px; }
  .rbc-toolbar.ps-rbc-toolbar {
    justify-content: space-between; }
  .rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0 10px;
    text-align: center; }
  .rbc-toolbar .ps-rbc-toolbar-label {
    font-weight: bold;
    font-size: 17px;
    color: #000000;
    padding: 0 30px; }
  .rbc-toolbar button {
    color: #373a3c;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid #ccc;
    padding: 0.375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap; }
    .rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
      background-image: none;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      background-color: #e6e6e6;
      border-color: #adadad; }
      .rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
        color: #373a3c;
        background-color: #d4d4d4;
        border-color: #8c8c8c; }
    .rbc-toolbar button:focus {
      color: #373a3c;
      background-color: #e6e6e6;
      border-color: #adadad; }
    .rbc-toolbar button:hover {
      color: #373a3c;
      background-color: #e6e6e6;
      border-color: #adadad; }

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap; }
  .rbc-btn-group.ps-rbc-btn-group-center {
    text-align: center; }
  .rbc-btn-group > button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .rbc-btn-group > button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .rbc-btn-group > button:not(:first-child):not(:last-child) {
    border-radius: 0; }
  .rbc-btn-group button + button {
    margin-left: -1px; }
  .rbc-rtl .rbc-btn-group button + button {
    margin-left: 0;
    margin-right: -1px; }
  .rbc-btn-group + .rbc-btn-group,
  .rbc-btn-group + button {
    margin-left: 10px; }
  .rbc-btn-group .ps-rbc-toolbar-button {
    border: 1px solid #333333;
    width: 28px;
    height: 28px;
    border-radius: 50% !important;
    padding: 0;
    overflow: hidden;
    text-align: center;
    cursor: pointer; }
    .rbc-btn-group .ps-rbc-toolbar-button:focus {
      background-color: transparent;
      border-color: #333333;
      outline: none; }
    .rbc-btn-group .ps-rbc-toolbar-button:hover {
      background-color: transparent;
      border-color: #333333;
      outline: none; }

.ps-rbc-filter-day {
  text-align: right; }
  .ps-rbc-filter-day .filter-day-item {
    cursor: pointer;
    background: #ffffff;
    border: 0.5px solid #333333;
    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 10px;
    padding: 3px 10px;
    display: inline-flex;
    justify-content: space-between; }
    .ps-rbc-filter-day .filter-day-item .ps-rbc-day {
      margin-right: 5px; }
    .ps-rbc-filter-day .filter-day-item span {
      font-style: normal;
      font-weight: 500;
      font-size: 13px; }
  .ps-rbc-filter-day .filter-day-item-selected {
    color: #8e2fed;
    border-color: #8e2fed; }

.rbc-event {
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left; }
  .rbc-event.ps-rbc-event {
    overflow: inherit !important;
    padding: 10px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25); }
    .rbc-event.ps-rbc-event.ps-rbs-event-time-at-0 {
      top: 0 !important; }
    .rbc-event.ps-rbc-event .rbc-event-label {
      display: none; }
  .rbc-event.ps-rcb-on-hold-event {
    background-color: #828282;
    border-color: #828282; }
  .rbc-event.ps-rcb-internal-event {
    background-color: #2fcbed;
    border-color: #2fcbed; }
  .rbc-event.ps-rcb-client-event {
    background-color: #003baf;
    border-color: #003baf; }
  .rbc-event.ps-rcb-success-event {
    background-color: #2cc805;
    border-color: #2cc805; }
  .rbc-slot-selecting .rbc-event {
    cursor: inherit;
    pointer-events: none; }
  .rbc-event:focus {
    outline: 5px auto #3b99fc; }

.rbc-event-label {
  font-size: 80%; }

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5); }

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.rbc-row {
  display: flex;
  flex-direction: row; }

.rbc-row-segment {
  padding: 0 1px 1px 1px; }

.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1); }

.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal; }

.rbc-month-view {
  position: relative;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%; }

.rbc-month-header {
  display: flex;
  flex-direction: row; }

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%; }
  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid #ddd; }

.rbc-date-cell {
  flex: 1 1;
  min-width: 0;
  padding-right: 5px;
  text-align: right; }
  .rbc-date-cell.rbc-now {
    font-weight: bold; }
  .rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
    color: inherit;
    text-decoration: none; }

.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0;
  overflow: hidden; }

.rbc-day-bg {
  flex: 1 0; }
  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #ddd; }
  .rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid #ddd; }

.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px; }
  .rbc-overlay > * + * {
    margin-top: 1px; }

.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px; }

.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow: auto; }
  .rbc-agenda-view table.rbc-agenda-table {
    width: 100%;
    border: 1px solid #ddd;
    border-spacing: 0;
    border-collapse: collapse; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
      padding: 5px 10px;
      vertical-align: top; }
    .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
      padding-left: 15px;
      padding-right: 15px;
      text-transform: lowercase; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
      border-left: 1px solid #ddd; }
    .rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
      border-left-width: 0;
      border-right: 1px solid #ddd; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
      border-top: 1px solid #ddd; }
    .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
      padding: 3px 5px;
      text-align: left;
      border-bottom: 1px solid #ddd; }
      .rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
        text-align: right; }

.rbc-agenda-time-cell {
  text-transform: lowercase; }
  .rbc-agenda-time-cell .rbc-continues-after:after {
    content: ' \BB'; }
  .rbc-agenda-time-cell .rbc-continues-prior:before {
    content: '\AB   '; }

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap; }

.rbc-agenda-event-cell {
  width: 100%; }

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%; }
  .rbc-time-column .rbc-timeslot-group {
    flex: 1 1; }

.rbc-timeslot-group {
  border-bottom: 1px solid #ddd;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap; }
  .rbc-timeslot-group.ps-rbc-timeslot-group {
    min-width: 75px; }
    .rbc-timeslot-group.ps-rbc-timeslot-group .rbc-label {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      text-align: right;
      color: #828282; }

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none; }

.rbc-label {
  padding: 0 5px; }

.rbc-day-slot {
  position: relative; }
  .rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    margin-right: 10px;
    top: 0; }
    .rbc-day-slot .rbc-events-container.ps-rbc-events-container {
      margin-right: 0; }
    .rbc-day-slot .rbc-events-container.rbc-rtl {
      left: 10px;
      right: 0; }
  .rbc-day-slot .rbc-event {
    display: flex;
    max-height: 100%;
    min-height: 20px;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute; }
  .rbc-day-slot .rbc-event-label {
    flex: none;
    padding-right: 5px;
    width: auto; }
  .rbc-day-slot .rbc-event-content {
    width: 100%;
    flex: 1 1;
    word-wrap: break-word;
    line-height: 1;
    height: 100%;
    min-height: 1em; }
    .rbc-day-slot .rbc-event-content .ps-rbc-event-content-wrapper {
      position: relative;
      height: 100%;
      overflow: hidden; }
  .rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #f7f7f7; }

.ps-rbc-event-content .event-title {
  font-size: 13px;
  font-weight: bold; }

.ps-rbc-event-content .event-subtitle {
  font-weight: 400;
  font-size: 13px; }

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #ddd;
  z-index: 10;
  margin-right: -1px; }

.rbc-time-view-resources .rbc-time-header {
  overflow: hidden; }

.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0;
  flex-basis: 0px; }

.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none; }

.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px; }

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1;
  flex-basis: 0 px; }

.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px; }

.rbc-time-slot {
  flex: 1 0; }
  .rbc-time-slot.rbc-now {
    font-weight: bold; }

.rbc-day-header {
  text-align: center; }

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px; }

.rbc-slot-selecting {
  cursor: move; }

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  border: 1px solid #ddd;
  min-height: 0; }
  .rbc-time-view .rbc-time-gutter {
    white-space: nowrap; }
  .rbc-time-view .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    position: relative; }
    .rbc-time-view .rbc-allday-cell.ps-rbc-allday-cell {
      height: auto;
      display: none; }
  .rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
    border-left: 1px solid #ddd; }
  .rbc-time-view .rbc-allday-events {
    position: relative;
    z-index: 4; }
  .rbc-time-view .rbc-row {
    box-sizing: border-box;
    min-height: 20px; }

.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row; }
  .rbc-time-header.rbc-overflowing {
    border-right: 1px solid #ddd; }
  .rbc-rtl .rbc-time-header.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid #ddd; }
  .rbc-time-header > .rbc-row:first-child {
    border-bottom: 1px solid #ddd; }
  .rbc-time-header > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #ddd; }
  .rbc-time-header .rbc-header.ps-rbc-header {
    border-bottom: 0;
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
    min-width: 75px; }
  .rbc-time-header .rbc-header .ps-rbc-header-date {
    display: block; }
    .rbc-time-header .rbc-header .ps-rbc-header-date .fullName {
      text-align: center;
      color: #000000;
      display: block;
      font-weight: 500;
      font-size: 12px; }
    .rbc-time-header .rbc-header .ps-rbc-header-date .dateNumber {
      font-weight: bold;
      text-align: center;
      color: #000000;
      display: block;
      font-size: 16px; }

.rbc-time-header-cell-single-day {
  display: none; }

.rbc-time-header-content {
  flex: 1 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid #ddd; }
  .rbc-rtl .rbc-time-header-content {
    border-left-width: 0;
    border-right: 1px solid #ddd; }
  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #ddd;
    flex-shrink: 0; }

.rbc-time-content {
  display: flex;
  flex: 1 0;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #ddd;
  overflow-y: auto;
  position: relative; }
  .rbc-time-content > .rbc-time-gutter {
    flex: none; }
  .rbc-time-content > * + * > * {
    border-left: 1px solid #ddd; }
  .rbc-rtl .rbc-time-content > * + * > * {
    border-left-width: 0;
    border-right: 1px solid #ddd; }
  .rbc-time-content > .rbc-day-slot {
    width: 100%;
    user-select: none;
    -webkit-user-select: none; }

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none; }

.ps-rbc-time-content-wrapper {
  display: flex;
  flex: 1 0;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #ddd; }
  .ps-rbc-time-content-wrapper .rbc-time-content {
    border-top: 0; }
  .ps-rbc-time-content-wrapper .rbc-time-gutter {
    border-right: 1px solid #ddd; }

.cr-calendar-time {
  position: relative;
  padding: 8.5px 0; }
  .cr-calendar-time--first {
    margin-top: 0; }
  .cr-calendar-time__hour {
    width: 77px;
    margin-right: 18px;
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    padding-top: 10px; }
    .cr-calendar-time__hour span {
      font-weight: bold;
      font-size: 16px;
      color: #537a91; }
  .cr-calendar-time__open-icon {
    position: absolute;
    right: -16px;
    top: 6px;
    cursor: pointer; }
  .cr-calendar-time__row-time {
    display: flex; }
  .cr-calendar-time__slots {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center; }
    .cr-calendar-time__slots .cr-schedule-calendar-item {
      width: 100%; }
  .cr-calendar-time__time-slots-collapsed .cr-schedule-calendar-item {
    margin: 8.5px 0; }
  .cr-calendar-time__time-slots-collapsed .cr-calendar-time__hour-range-tooltip-first-inside-hours-range.cr-calendar-time__hour-range-tooltip-first {
    top: 9px; }
  .cr-calendar-time__time-slots-collapsed .cr-calendar-time__hour-range-tooltip-first-inside-hours-range.cr-calendar-time__hour-range-tooltip-first-left-hours-range {
    top: 0 !important; }
  .cr-calendar-time__time-slots-collapsed .cr-calendar-time__hour-range-tooltip-first-inside-hours-range.cr-calendar-time__hour-range-tooltip-boundary {
    top: 9px; }
  .cr-calendar-time__time-slots-collapsed .cr-calendar-time__hour-range-tooltip-last-inside-hours-rang.cr-calendar-time__hour-range-tooltip-last {
    height: calc(100% - 3.5px); }
  .cr-calendar-time__time-slots-collapsed .cr-calendar-time__hour-range-tooltip-last-inside-hours-rang.cr-calendar-time__hour-range-tooltip-boundary {
    height: 100%;
    top: 0; }
  .cr-calendar-time__time-slots-opened .cr-schedule-calendar-item {
    margin: 3.5px 0; }
  .cr-calendar-time__item-slots-single {
    height: 100%; }
    .cr-calendar-time__item-slots-single .cr-schedule-calendar-item {
      height: calc(100% - 7px); }
  .cr-calendar-time__time-slots {
    flex: 1 1;
    width: 0;
    padding: 0 5px;
    height: 100%; }
  .cr-calendar-time__time-slots-side {
    position: relative;
    padding-right: 15.5px; }
    .cr-calendar-time__time-slots-side + .cr-calendar-time__time-slots {
      padding-left: 15.5px; }
  .cr-calendar-time__hour-range-tooltip {
    width: 11px;
    height: 100%;
    display: block;
    position: absolute;
    right: -5.5px;
    top: 0;
    z-index: 99; }
  .cr-calendar-time__hour-range-tooltip-middle {
    height: calc(100% + 3.5px); }
  .cr-calendar-time__hour-range-tooltip-boundary {
    height: calc( 100% + 17px - 3.5px);
    top: 3.5px; }
    .cr-calendar-time__hour-range-tooltip-boundary.cr-calendar-time__hour-range-tooltip-last {
      height: calc(100% + 3.5px); }
  .cr-calendar-time__hour-range-tooltip-first {
    border-radius: 4px 4px 0 0; }
  .cr-calendar-time__hour-range-tooltip-last {
    border-radius: 0 0 4px 4px;
    top: -3.5px; }
    .cr-calendar-time__hour-range-tooltip-last.cr-calendar-time__hour-range-tooltip-first-left-hours-range {
      top: -3.5px; }
  .cr-calendar-time__hour-range-tooltip-first-inside-hours-range {
    top: 3.5px; }
  .cr-calendar-time__hour-color {
    width: 77px;
    border-radius: 4px;
    height: calc(100% - 17px);
    position: absolute; }
  .cr-calendar-time__hour-color-tooltip {
    background-color: transparent;
    z-index: 999; }
  .cr-calendar-time__row-time-first .cr-calendar-time__time-slots-collapsed .cr-schedule-calendar-item {
    margin-top: 0; }
  .cr-calendar-time__row-time-first .cr-calendar-time__time-slots-side::after {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .cr-calendar-time__row-time-first .cr-calendar-time__time-slots-opened.cr-calendar-time__time-slots-side::after {
    top: 3.5px; }
  .cr-calendar-time__row-time-last .cr-calendar-time__time-slots .cr-schedule-calendar-item:last-child {
    margin-bottom: 0; }
  .cr-calendar-time__row-time-last .cr-calendar-time__time-slots-side::after {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
  .cr-calendar-time__hour-range-content {
    width: 196px;
    padding: 10px 20px 15px 10px; }
  .cr-calendar-time__hour-tooltip-time {
    font-size: 16px;
    color: #657894;
    display: block;
    text-transform: lowercase; }
  .cr-calendar-time__hour-tooltip-text {
    font-weight: 800;
    font-size: 16px;
    color: #334856;
    display: block;
    word-break: break-word; }

.hour-range-tooltip.rc-tooltip-placement-bottom .rc-tooltip-arrow:before {
  height: 15px !important;
  top: 6px !important; }

.hour-range-tooltip.rc-tooltip-placement-bottom .rc-tooltip-arrow::after {
  height: 13px !important;
  width: 13px !important;
  top: -1px !important;
  left: -6px !important; }

.hour-range-tooltip.rc-tooltip-placement-top .rc-tooltip-arrow:before {
  height: 15px !important;
  top: -5px !important; }

.hour-range-tooltip.rc-tooltip-placement-top .rc-tooltip-arrow::after {
  height: 13px !important;
  width: 13px !important;
  top: 3px !important;
  left: -6px !important; }

.cr-schedule-calendar .cr-calendar-week-sticky {
  padding-bottom: 26px;
  position: -webkit-sticky;
  position: sticky;
  top: 65px;
  transition: all 0.5ms;
  z-index: 100;
  background: #f7fbfd;
  transition: padding-top 0.05s ease-out;
  padding-top: 100px; }
  .cr-schedule-calendar .cr-calendar-week-sticky .calendar-sticky-content::after {
    background: linear-gradient(180deg, rgba(1, 1, 1, 0.2) 0%, rgba(0, 0, 0, 0) 80.73%);
    content: "";
    width: calc(100% - 5px);
    height: 26px;
    display: none;
    position: absolute;
    bottom: -26px; }

.cr-schedule-calendar__close-all {
  font-size: 10px;
  color: #795afa;
  cursor: pointer;
  position: absolute;
  width: 75px;
  top: -45px;
  left: 0;
  text-align: center;
  text-transform: lowercase;
  z-index: 101; }

.cr-schedule-calendar__calendar-content {
  text-align: center;
  padding-bottom: 120px; }
  .cr-schedule-calendar__calendar-content .cr-schedule-action-statement {
    margin-bottom: 21px; }

.cr-schedule-calendar__calendar {
  position: relative; }

.cr-schedule-calendar .select-stations-wrapper {
  max-width: 350px;
  margin-right: 10px; }
  .cr-schedule-calendar .select-stations-wrapper .react-select__menu {
    z-index: 102 !important; }

.cr-schedule-calendar .select-stations-disabled {
  width: 163px;
  margin-right: 10px;
  align-items: center;
  border: 1px solid #c2d4e0;
  border-radius: 6px;
  height: 50px;
  display: flex;
  padding: 0.375rem 0.75rem;
  background-color: #fff; }
  .cr-schedule-calendar .select-stations-disabled:hover {
    border-color: #4324c7;
    box-shadow: 0 0 0 1px #4324c7;
    border-radius: 6px; }

.cr-schedule-calendar__dropdown-weekdays {
  max-width: 204px;
  margin-right: 10px; }

.cr-schedule-calendar__dropdown-type {
  max-width: 157px;
  margin-right: 10px; }

.cr-schedule-calendar .cr-prog-slots-filter-dropdown {
  max-width: 262px; }
  .cr-schedule-calendar .cr-prog-slots-filter-dropdown .cr-checkbox__text {
    max-width: 120px; }

.cr-schedule-calendar .cr-prog-slots-filter-dropdown__value-container span {
  color: #657894; }

.cr-schedule-calendar .react-select__placeholder {
  color: #657894; }

.cr-schedule-calendar__filter-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between; }

.cr-schedule-calendar .cr-search-autocomplete-input {
  width: 383px; }

.cr-schedule-calendar__filter-dropdown {
  flex: 1 1;
  display: flex;
  margin-bottom: 23px; }
  .cr-schedule-calendar__filter-dropdown > div {
    flex: 1 1; }
  .cr-schedule-calendar__filter-dropdown .cr-textinput {
    max-width: 163px;
    margin-right: 10px; }
    .cr-schedule-calendar__filter-dropdown .cr-textinput input {
      background-color: #fff !important; }
  .cr-schedule-calendar__filter-dropdown .select-stations-wrapper label {
    display: none; }

.cr-schedule-calendar__statement-select-item-text strong {
  color: #334856; }

.cr-schedule-calendar__statement-sticky {
  margin-top: 15px;
  text-align: center; }

.cr-schedule-calendar__setting-icon {
  width: 40px;
  flex: initial !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.cr-schedule-calendar__schedule-buttons {
  margin-left: 10px; }

.cr-schedule-calendar__btn-publish {
  margin-right: 14px; }

.cr-schedule-calendar .bottom-actions-sticky .buttons {
  justify-content: flex-start; }

@media screen and (min-width: 1200px) and (max-width: 1436px) {
  .cr-schedule-calendar .cr-search-autocomplete-input {
    width: auto;
    margin-left: 20px; } }

@media (min-width: 1599px) {
  .cr-schedule-calendar .bottom-actions-sticky .buttons {
    padding-left: 70px;
    padding-right: 70px; } }

.calendar-sticky .calendar-sticky-content::after {
  display: block !important; }

.cr-popover-event-detail {
  display: none;
  padding: 15px;
  background: #f8f8f8;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  position: absolute;
  right: calc(100% + 20px);
  z-index: 999;
  min-width: 200px;
  top: 0;
  color: #333333; }
  .cr-popover-event-detail__active {
    display: block; }
  .cr-popover-event-detail__title {
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 5px; }
  .cr-popover-event-detail__time {
    font-size: 11px;
    display: block;
    margin-bottom: 5px; }
  .cr-popover-event-detail__address {
    font-size: 13px;
    display: block;
    margin-bottom: 10px; }
  .cr-popover-event-detail__role {
    margin: 5px 0; }
  .cr-popover-event-detail__user-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 5px; }
  .cr-popover-event-detail__role-title {
    margin-right: 10px; }

.cr-invite-modal {
  min-width: 60%; }
  .cr-invite-modal__button-cancel {
    border-radius: 24px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: transparent;
    background-image: none;
    text-transform: uppercase; }
  .cr-invite-modal .card-header {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important; }
  .cr-invite-modal__body {
    padding: 0;
    padding-bottom: 20px; }
  .cr-invite-modal .cr-user-form__content-form {
    box-shadow: none; }
  .cr-invite-modal .cr-user-form__line {
    display: none; }
  .cr-invite-modal .cr-user-form__title {
    font-weight: 700;
    font-size: 23px;
    line-height: 29px;
    color: #334856;
    padding: 20px 20px 20px 40px;
    margin-bottom: 0;
    border-bottom: 1px solid #edf1f6; }
  .cr-invite-modal .cr-user-form__form-fields {
    padding-top: 30px; }

.cr-bulk-upload-form__title {
  color: #334856;
  font-size: 23px;
  font-weight: bold; }

.cr-bulk-upload-form.card {
  border: none !important;
  padding: 0 5px; }

.cr-admin-tab-detail__tab-detail {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px; }

.cr-admin-tab-detail__tab-role-detail {
  box-shadow: none;
  border-right: none; }

.cr-admin-tab-detail__title {
  color: #657894;
  margin: 0;
  font-size: 25px;
  margin-bottom: 20px; }

.cr-admin-tab-detail__info-profile {
  background-color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  box-shadow: 2px 4px 10px #aaa; }
  @media (max-width: 480px) {
    .cr-admin-tab-detail__info-profile {
      flex-direction: column; } }

.cr-admin-tab-detail__user-avatar {
  display: flex;
  justify-content: center;
  align-items: center; }

.cr-admin-tab-detail__avatar-warpper {
  display: flex; }

.cr-admin-tab-detail__avatar {
  background-color: white;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 10px; }

.cr-admin-tab-detail__user-edit-icon {
  margin-left: -22px;
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 10px;
  height: 26px;
  width: 26px;
  text-align: center;
  border-radius: 50%;
  z-index: 10;
  background: white; }
  .cr-admin-tab-detail__user-edit-icon:hover {
    cursor: pointer; }

.cr-admin-tab-detail__user-name {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  width: calc(100% - 100px); }

.cr-admin-tab-detail__text-head {
  font-size: 20px;
  color: #657894;
  text-transform: capitalize;
  font-weight: 600;
  color: #324856; }

.cr-admin-tab-detail__user-position {
  flex-direction: column;
  display: flex;
  min-width: 200px; }
  @media (max-width: 768px) {
    .cr-admin-tab-detail__user-position {
      min-width: auto; } }

.cr-admin-tab-detail__text {
  color: #657894;
  font-size: 16px;
  text-transform: capitalize; }

.cr-admin-tab-detail__tab-link {
  font-size: 16px !important;
  min-width: 140px;
  text-align: center;
  display: inline-block !important;
  padding: 10px !important; }

.cr-admin-tab-detail__active {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #c4c4c4 #c4c4c4 #fff !important; }

.cr-admin-tab-detail__nav {
  padding: 10px 30px 0px 30px;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125); }

.cr-admin-tab-detail__form-fields {
  background-color: #fff;
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 2px 4px 10px #aaa; }

.cr-admin-tab-detail__section-title {
  border-bottom: 1px solid #ecf1f7;
  padding-bottom: 10px; }

.cr-admin-tab-detail__button-change-password {
  border-radius: 30px;
  min-height: 48px;
  margin-top: 10px;
  min-width: 40%; }

.cr-admin-tab-detail__container-change-buttons {
  display: flex;
  justify-items: flex-start;
  align-items: center;
  flex: 1 1;
  height: 100%; }

.cr-admin-tab-detail__list-inline {
  list-style: none;
  padding: 0; }

.cr-admin-tab-detail__list-inline-item {
  display: inline-block;
  padding-right: 25px;
  font-size: 18px;
  text-transform: capitalize;
  margin-bottom: 10px; }

.cr-admin-tab-detail__list-inline {
  list-style: none;
  padding: 0;
  display: inline-block;
  margin-top: 10px; }

.cr-admin-tab-detail__list-inline-item-station {
  text-transform: uppercase; }

.cr-admin-tab-detail__button-submit {
  border-radius: 30px;
  min-width: 140px;
  margin-right: 10px;
  padding-right: 20px;
  padding-left: 20px;
  width: auto; }

.cr-admin-tab-detail__button-edit {
  border-radius: 30px;
  min-width: 140px;
  margin-right: 10px;
  padding-right: 20px;
  padding-left: 20px;
  width: auto; }

.cr-admin-tab-detail button {
  margin-right: 14px; }

.cr-admin-tab-detail__change-avatar {
  color: #795afa;
  text-transform: uppercase;
  margin-left: 20px;
  text-decoration: underline;
  cursor: pointer; }

.cr-admin-tab-detail__top-roles span {
  margin-right: 10px; }

.cr-role-form__section-container {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  margin-bottom: 18px;
  max-width: 1000px; }

.cr-role-form__content-view {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  padding-top: 46px;
  padding-left: 42px;
  padding-right: 50px; }

.cr-role-form__form-buttons {
  margin-left: 20px;
  margin-top: 35px;
  padding-left: 1.25rem; }
  .cr-role-form__form-buttons .btn {
    text-transform: uppercase;
    margin-right: 14px; }
  .cr-role-form__form-buttons .disabled {
    background: #c2d4e0;
    border-radius: 24px;
    opacity: 1;
    border-color: #c2d4e0; }

.cr-role-form__title {
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 29px;
  color: #111b2b;
  padding: 23px 20px 20px 42px;
  outline-width: 1px;
  margin-bottom: 0;
  height: 89px;
  outline-style: solid;
  outline-color: #edf1f6;
  position: relative;
  display: flex;
  align-items: center; }

.cr-role-form__hide {
  display: none; }

.cr-role-form__form-title-privileges-0 {
  margin-top: 48px !important; }

.cr-role-form__name-group {
  margin-top: 46px;
  padding-bottom: 24px; }
  .cr-role-form__name-group .col-6,
  .cr-role-form__name-group .form-group {
    margin-bottom: 0 !important; }

.cr-role-form__title-privileges {
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 29px;
  color: #111b2b;
  margin: 51px 0 42px 0;
  padding-left: 42px;
  height: 32px;
  display: flex;
  align-items: center;
  max-width: 871px; }

.cr-role-form__form-title-privileges {
  padding-left: 0; }

.cr-role-form .form-group label {
  text-transform: uppercase !important;
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #43535c; }

.cr-role-form .form-group .cr-textinput__input-field ::-webkit-input-placeholder {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #829fb1 !important; }

.cr-role-form .form-group .cr-textinput__input-field ::placeholder {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #829fb1 !important; }

.cr-role-form .form-group .react-select__placeholder {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #829fb1 !important; }

.cr-role-form .cr-checkbox__text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  color: #537a91;
  text-transform: capitalize;
  margin-left: 20px; }

.cr-role-form__view-wrapper label {
  text-transform: uppercase !important;
  display: block;
  color: #537a91; }

.cr-role-form__sub-group-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #334856;
  margin-bottom: 23px;
  height: 32px;
  display: flex;
  align-items: center; }

.cr-role-form__privilege-name {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  color: #537a91; }

.cr-role-form__group {
  border-bottom: 1px solid #edf1f6;
  padding: 0px 20px 20px 40px; }

.cr-role-form__last-group {
  border-bottom: none; }

.cr-role-form__error-privileges {
  padding-left: 40px; }

.cr-role-form__section-icon {
  right: 50px;
  position: absolute; }

.cr-role-form__total {
  font-style: normal;
  font-weight: normal;
  margin-left: 10px;
  color: #537a91;
  text-transform: capitalize; }

.cr-role-form__role-icon {
  margin-right: 21px; }

.cr-role-form__view-title {
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 33px;
  color: #334856;
  padding: 23px 20px 20px 42px;
  outline-width: 1px;
  margin-bottom: 0;
  height: 130px;
  outline-style: solid;
  outline-color: #edf1f6;
  position: relative;
  display: flex;
  align-items: center; }

.cr-role-form__p-left-0 {
  padding-left: 0; }

.cr-setting-form__content-form {
  padding: 1.25rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.cr-setting-form__form-buttons {
  margin-top: 20px; }

.cr-setting-form .form-group label {
  text-transform: uppercase !important;
  display: block;
  color: #537a91; }

.cr-setting-form__view-wrapper label {
  text-transform: uppercase !important;
  display: block;
  color: #537a91; }

.cr-ps-form__content-form {
  padding: 1.25rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.cr-ps-form__form-buttons {
  padding-left: 1.25rem;
  margin-top: 20px; }
  .cr-ps-form__form-buttons .btn-blue {
    margin-right: 7.5px; }
  .cr-ps-form__form-buttons .btn-outline-blue {
    margin-left: 7.5px; }

.cr-ps-form .form-group label {
  text-transform: uppercase !important;
  display: block;
  color: #537a91; }

.cr-ps-form__view-wrapper label {
  text-transform: uppercase !important;
  display: block;
  color: #537a91; }

.cr-reminder-modal {
  max-width: 562px; }
  .cr-reminder-modal__header {
    margin-top: 40px;
    padding-top: 1rem;
    border-bottom: 0;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
    text-align: center;
    margin-bottom: 40px; }
    .cr-reminder-modal__header .modal-title {
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 50px;
      text-align: center;
      color: #334856; }
  .cr-reminder-modal__subtitle {
    border-bottom: 0;
    font-size: 20px;
    font-weight: 400;
    color: #324856;
    text-align: center;
    padding: 0;
    line-height: 24px;
    margin-top: 20px; }
  .cr-reminder-modal .close {
    display: none; }
  .cr-reminder-modal .modal-footer {
    border-top: none; }
  .cr-reminder-modal .modal-footer {
    justify-content: center;
    margin-bottom: 40px; }
  .cr-reminder-modal__confirm-button {
    margin-right: 7.5px !important; }
  .cr-reminder-modal__cancel-button {
    margin-left: 7.5px !important;
    margin-top: 0 !important; }

.cr-broadcasting-modal {
  background: transparent;
  min-width: 80%; }
  .cr-broadcasting-modal .cr-server-form__row-content {
    margin-left: -15px;
    margin-right: -15px; }
  .cr-broadcasting-modal .cr-server-form__content-form {
    border: none;
    border-radius: 0.3rem;
    outline: none;
    box-shadow: none; }
  .cr-broadcasting-modal .cr-server-form__form-buttons {
    padding: 0 1.25rem;
    margin: 0;
    margin-top: 5px; }
  .cr-broadcasting-modal .cr-server-form__box-section {
    margin-left: 44px;
    margin-right: 44px; }
  .cr-broadcasting-modal .modal-body {
    padding: 0; }
  .cr-broadcasting-modal__button-cancel {
    border-radius: 30px;
    padding-right: 20px;
    padding-left: 20px;
    color: #795afa;
    background-color: #fff;
    background-image: none; }
  .cr-broadcasting-modal__close {
    position: absolute;
    z-index: 9;
    right: 15px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 30px;
    cursor: pointer; }

.cr-empty-announcement {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 350px;
  padding: 125px 0; }
  .cr-empty-announcement__image {
    margin: 0px auto;
    max-width: 200px;
    margin-bottom: 20px; }
    .cr-empty-announcement__image img {
      max-width: 100%; }
  .cr-empty-announcement__title {
    font-size: 30px;
    font-weight: bold;
    color: #6f14ae;
    text-transform: capitalize; }
  .cr-empty-announcement__description {
    color: #212529; }
  .cr-empty-announcement__content {
    text-align: center; }

.cr-user-form .card {
  border: none !important;
  padding: 0;
  background-color: transparent; }

.cr-user-form .card-body {
  padding: 0; }

.cr-user-form__title {
  font-weight: 800;
  font-size: 23px;
  line-height: 29px;
  color: #111b2b;
  margin-bottom: 0;
  padding-top: 59px;
  padding-bottom: 32px;
  border-radius: 0 !important;
  border-bottom: 0;
  padding-left: 40px;
  padding-right: 40px;
  text-transform: capitalize; }

.cr-user-form__content-form {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  border-top-left-radius: 0; }

.cr-user-form__header-modal {
  border-left: none !important;
  border-right: none !important; }

.cr-user-form__fields-view {
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 11px; }

.cr-user-form__label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #829fb1;
  margin-bottom: 12px;
  display: block;
  text-transform: uppercase; }

.cr-user-form__field-view-value {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #111b2b;
  text-transform: capitalize;
  display: block; }

.cr-user-form__form-group-view {
  margin-bottom: 49px; }

.cr-user-form__col-reset {
  margin-bottom: 0; }

.cr-user-form__field-view-value-email {
  text-transform: inherit; }

.cr-user-form__line {
  width: 100%;
  height: 1px;
  background-color: #edf1f6; }

.cr-user-form__creative-details-view {
  padding-top: 42px;
  padding-left: 46px;
  padding-right: 46px;
  padding-bottom: 39px; }

.cr-user-form__creative-title {
  font-weight: 800;
  font-size: 23px;
  height: 32px;
  color: #111b2b;
  margin-bottom: 0;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.cr-user-form__form-group-creative-view {
  margin-bottom: 25px; }

.cr-user-form__tag-view {
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #657894;
  margin-right: 13.32px;
  display: inline-block;
  height: 42px;
  padding: 10px 20px;
  margin-bottom: 15px; }

.cr-user-form__form-group-reset {
  margin-bottom: 0; }

.cr-user-form__form-buttons {
  margin-left: 46px;
  margin-top: 30px; }

.cr-user-form__form-buttons .btn {
  margin-right: 14px; }
  .cr-user-form__form-buttons .btn:last-child {
    margin-right: 0; }

.cr-user-form__form-fields label, .cr-user-form__creative-edit label {
  font-style: normal;
  font-weight: normal;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #43535c;
  margin-bottom: 8px;
  display: block;
  text-transform: uppercase; }

.cr-user-form__form-fields {
  padding-left: 42px;
  padding-right: 42px; }

.cr-user-form__form-group-edit {
  margin-bottom: 33px; }

.cr-user-form .cr-radio-buttons__radios {
  margin-top: 22px; }

.cr-user-form__btn-reset-password {
  width: 217px;
  margin-right: 36px; }

.cr-user-form__form-group-edit-password {
  display: flex;
  align-items: center;
  margin-top: 9px;
  margin-bottom: 56px; }

.cr-user-form__creative-edit {
  padding-top: 42px;
  padding-left: 46px;
  padding-right: 46px;
  padding-bottom: 39px; }
  .cr-user-form__creative-edit .delete-icon {
    top: calc(50% - 5px); }
  .cr-user-form__creative-edit .drop-area {
    height: 108px; }

.cr-user-form__form-group-creative-edit {
  margin-bottom: 32px; }

.cr-user-form__creative-content {
  margin-top: 34px; }

.cr-user-form__button-up-icon, .cr-user-form__button-down-icon {
  cursor: pointer; }

.cr-user-form__content-box {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  margin-top: 20px; }
  .cr-user-form__content-box label {
    color: #43535c;
    text-transform: uppercase;
    line-height: 15px; }

.cr-user-form__enterprise-roles-title {
  font-weight: 800;
  font-size: 23px;
  line-height: 29px;
  color: #111b2b;
  display: flex;
  justify-content: space-between; }

.cr-user-form__content-box-head {
  padding: 31px 42px 15px 42px; }

.cr-user-form__content-box-content {
  border-top: 1px solid #edf1f6;
  padding: 35px 42px; }
  .cr-user-form__content-box-content .react-select__multi-value {
    display: none; }

.cr-user-form__manage-privileges {
  color: #795afa;
  font-size: 16px;
  margin-top: 8px;
  cursor: pointer; }

.cr-user-form__selected-roles {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -8px;
  margin-bottom: 20px; }

.cr-user-form__remove-role {
  cursor: pointer;
  padding-right: 10px;
  display: inline-block; }

.cr-user-form__role-item {
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  border-radius: 4px;
  padding: 8px 15px;
  margin: 0 8px;
  margin-bottom: 8px; }
  .cr-user-form__role-item span {
    color: #657894;
    font-size: 16px; }

.cr-station-modal {
  min-width: 60%; }
  .cr-station-modal__button-cancel {
    border-radius: 30px;
    padding-right: 20px;
    padding-left: 20px;
    color: #795afa;
    background-color: transparent;
    background-image: none;
    border-color: #795afa; }
  .cr-station-modal .modal-body {
    padding: 0;
    padding-bottom: 30px; }
  .cr-station-modal__close {
    cursor: pointer;
    position: absolute;
    z-index: 9;
    right: 35px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 19px; }
  .cr-station-modal .cr-station-form__station-wrapper {
    box-shadow: none; }
  .cr-station-modal .cr-station-form__content-form {
    padding-bottom: 0; }

.cr-no-items-found {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 350px;
  padding: 250px 0; }
  .cr-no-items-found__title {
    font-size: 30px;
    font-weight: bold;
    text-transform: capitalize;
    color: #657894; }

.cr-user-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 13px; }
  .cr-user-card__user-avatar {
    width: 74px;
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: #fff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25); }
  .cr-user-card__title {
    font-weight: 800;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #111b2b;
    text-transform: capitalize; }
  .cr-user-card__subtitle {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #657894;
    text-transform: capitalize; }
  .cr-user-card__children {
    width: 100%; }
    .cr-user-card__children .avatar-sort-name-text {
      font-size: 10px !important; }
  .cr-user-card__user-avatar-background {
    width: 100%;
    min-height: 200px;
    background-position: center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center; }

.cr-header__popover-header-card.cr-user-card {
  padding-top: 0; }
  .cr-header__popover-header-card.cr-user-card .avatar-sort-name-text {
    font-size: 30px;
    line-height: inherit; }
  .cr-header__popover-header-card.cr-user-card .cr-user-card__user-avatar {
    width: 90px;
    height: 90px; }

.cr-station-tab-detail__title {
  color: #657894;
  margin: 0;
  font-size: 25px;
  margin-bottom: 20px; }

.cr-station-tab-detail__info-profile {
  background-color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  box-shadow: 2px 4px 10px #aaa; }
  @media (max-width: 480px) {
    .cr-station-tab-detail__info-profile {
      flex-direction: column; } }

.cr-station-tab-detail__user-avatar {
  display: flex;
  justify-content: center;
  align-items: center; }

.cr-station-tab-detail__avatar-warpper {
  display: flex; }

.cr-station-tab-detail__avatar {
  background-color: white;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 10px; }

.cr-station-tab-detail__user-edit-icon {
  margin-left: -22px;
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 10px;
  height: 26px;
  width: 26px;
  text-align: center;
  border-radius: 50%;
  z-index: 10;
  background: white; }
  .cr-station-tab-detail__user-edit-icon:hover {
    cursor: pointer; }

.cr-station-tab-detail__user-name {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  width: calc(100% - 100px); }

.cr-station-tab-detail__text-head {
  font-size: 20px;
  color: #657894;
  text-transform: capitalize;
  font-weight: 600;
  color: #324856; }

.cr-station-tab-detail__user-position {
  flex-direction: column;
  display: flex;
  min-width: 200px; }
  @media (max-width: 768px) {
    .cr-station-tab-detail__user-position {
      min-width: auto; } }

.cr-station-tab-detail__text {
  color: #657894;
  font-size: 16px;
  text-transform: capitalize; }

.cr-station-tab-detail__tab-link {
  font-size: 16px !important;
  min-width: 140px;
  text-align: center;
  display: inline-block !important;
  padding: 10px !important; }

.cr-station-tab-detail__active {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #c4c4c4 #c4c4c4 #fff !important; }

.cr-station-tab-detail__has-tabs {
  padding: 10px 30px 0px 30px;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125); }

.cr-station-tab-detail__form-fields {
  background-color: #fff;
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 2px 4px 10px #aaa; }

.cr-station-tab-detail__section-title {
  border-bottom: 1px solid #ecf1f7;
  padding-bottom: 10px; }

.cr-station-tab-detail__button-change-password {
  border-radius: 30px;
  min-height: 48px;
  margin-top: 10px;
  min-width: 40%; }

.cr-station-tab-detail__container-change-buttons {
  display: flex;
  justify-items: flex-start;
  align-items: center;
  flex: 1 1;
  height: 100%; }

.cr-station-tab-detail__list-inline {
  list-style: none;
  padding: 0; }

.cr-station-tab-detail__list-inline-item {
  display: inline-block;
  padding-right: 25px;
  font-size: 18px;
  text-transform: capitalize;
  margin-bottom: 10px; }

.cr-station-tab-detail__list-inline {
  list-style: none;
  padding: 0;
  display: inline-block;
  margin-top: 10px; }

.cr-station-tab-detail__list-inline-item-station {
  text-transform: uppercase; }

.cr-station-tab-detail__button-submit {
  border-radius: 30px;
  min-width: 140px;
  width: auto;
  margin-right: 10px;
  padding-right: 20px;
  padding-left: 20px; }

.cr-station-tab-detail__change-avatar {
  color: #795afa;
  text-transform: uppercase;
  margin-left: 20px;
  text-decoration: underline;
  cursor: pointer; }

.cr-station-tab-detail__top-roles span {
  margin-right: 10px; }

.cr-avatar {
  width: 100px;
  height: 100px;
  -webkit-border-radius: 60px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 60px;
  -moz-background-clip: padding;
  border-radius: 60px;
  background-clip: padding-box;
  margin: 0;
  float: left;
  background-size: cover;
  background-position: center center; }

.avatar-sort-name {
  background-color: #0F5589;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center; }

.avatar-sort-name-text {
  font-weight: 600;
  font-size: 50px;
  line-height: 80px;
  text-align: center;
  color: #FFFFFF;
  text-transform: uppercase; }

.avatar-small .avatar-sort-name-text {
  font-size: 15px; }

.cr-handle-assets {
  margin-bottom: 10px; }
  .cr-handle-assets__file-item {
    background: #ffffff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    margin-bottom: 15px;
    height: 50px; }
  .cr-handle-assets__file-name {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #795afa;
    white-space: nowrap;
    max-width: 40%;
    text-overflow: ellipsis;
    overflow: hidden; }
  .cr-handle-assets__file-name-view {
    color: #334856; }
  .cr-handle-assets__file-buttons {
    min-width: 35%;
    display: flex;
    justify-content: flex-end; }
    .cr-handle-assets__file-buttons .btn {
      margin: 0px;
      background: transparent;
      border: transparent !important;
      color: #795afa !important;
      min-width: auto !important;
      padding-left: 10.5px !important;
      padding-right: 10.5px !important; }
      .cr-handle-assets__file-buttons .btn:hover {
        background: transparent !important;
        box-shadow: none !important; }
      .cr-handle-assets__file-buttons .btn:focus {
        outline: none;
        background: transparent !important;
        box-shadow: none !important; }
      .cr-handle-assets__file-buttons .btn:active {
        outline: none;
        background: transparent !important;
        box-shadow: none !important; }
  .cr-handle-assets__asset-audit {
    font-size: 11px;
    line-height: 150%;
    color: #657894;
    margin-bottom: 6px; }

.cr-preview-file-modal {
  max-width: 80%;
  width: 80%; }
  .cr-preview-file-modal--large {
    max-width: 80%; }
  .cr-preview-file-modal__footer {
    align-items: center;
    justify-content: center;
    border: none; }
  .cr-preview-file-modal__body {
    border: none; }
  .cr-preview-file-modal__submit-button {
    text-transform: uppercase;
    margin-right: 7px; }
    .cr-preview-file-modal__submit-button:focus {
      outline: none; }
    .cr-preview-file-modal__submit-button:active {
      outline: none; }
    .cr-preview-file-modal__submit-button.disabled {
      background: #bccedb;
      border-color: #bccedb; }
  .cr-preview-file-modal__cancel-button {
    margin: 0 !important;
    text-transform: uppercase;
    margin-left: 7px; }
    .cr-preview-file-modal__cancel-button:focus, .cr-preview-file-modal__cancel-button:hover, .cr-preview-file-modal__cancel-button:active {
      outline: none; }
  .cr-preview-file-modal__file-name {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #795afa;
    margin-bottom: 1rem; }
  .cr-preview-file-modal__image {
    text-align: center;
    margin-bottom: 10px; }
    .cr-preview-file-modal__image img {
      max-width: 100%; }
  .cr-preview-file-modal__image-background {
    background-color: #c2d4e0;
    padding: 18px;
    border-radius: 6px; }
    .cr-preview-file-modal__image-background img {
      max-height: 50px; }
  .cr-preview-file-modal__default {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px; }
  .cr-preview-file-modal__default-ext {
    position: absolute;
    font-size: 10px; }
  .cr-preview-file-modal__file-name-edit {
    margin: 10px 0; }
  .cr-preview-file-modal__buttons {
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-preview-file-modal__center-buttons {
    display: inline-flex;
    align-self: center; }
  .cr-preview-file-modal__view-buttons {
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-preview-file-modal__preview-pdf-wrapper {
    position: relative; }
  .cr-preview-file-modal__page-btn {
    position: absolute;
    top: calc(50% - 12px);
    cursor: pointer;
    display: block;
    width: 15px;
    height: 24px;
    z-index: 999; }
  .cr-preview-file-modal__btn-prev {
    left: -5px; }
  .cr-preview-file-modal__btn-next {
    right: -5px; }
  .cr-preview-file-modal__pdf-pages {
    text-align: center; }
  .cr-preview-file-modal__pdf-container {
    position: relative;
    text-align: center; }
    .cr-preview-file-modal__pdf-container .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px; }
    .cr-preview-file-modal__pdf-container .page-controls {
      background: white;
      display: inline-block;
      margin: 0 auto;
      margin-top: 15px;
      opacity: 1;
      transition: opacity ease-in-out 0.2s;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px; }
    .cr-preview-file-modal__pdf-container .react-pdf__Page {
      margin: 0 auto; }
    .cr-preview-file-modal__pdf-container .react-pdf__Page__canvas {
      margin: 0 auto; }
    .cr-preview-file-modal__pdf-container button {
      width: 44px;
      height: 44px;
      background: white;
      border: 0;
      font-size: 0.8em;
      border-radius: 4px;
      cursor: pointer; }
      .cr-preview-file-modal__pdf-container button:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .cr-preview-file-modal__pdf-container button:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      .cr-preview-file-modal__pdf-container button:focus, .cr-preview-file-modal__pdf-container button:active {
        outline: none;
        border: none; }
  .cr-preview-file-modal__cannot-preview {
    margin-bottom: 10px;
    color: #000;
    text-align: center;
    font-weight: 600; }

.cr-page-admin.page-padding {
  padding-top: 65px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 62px; }

.cr-page-admin__header {
  display: flex;
  align-items: flex-end;
  align-self: center;
  padding-bottom: 20px; }
  @media (max-width: 767.98px) {
    .cr-page-admin__header {
      flex-direction: column;
      align-items: flex-start; } }

.cr-page-admin__title {
  padding-right: 0.5rem;
  text-transform: capitalize;
  color: #334856;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  margin: 0; }

.cr-page-admin__breadcrumb {
  align-self: flex-end;
  padding: 0;
  text-transform: capitalize; }
  @media (max-width: 767.98px) {
    .cr-page-admin__breadcrumb {
      align-self: flex-start; } }

.cr-page-admin .row .col,
.cr-page-admin .row > [class^="col-"] {
  margin-bottom: 1rem; }

.cr-page-admin__breadcrumb .breadcrumb {
  background-color: transparent;
  padding-left: 0; }
  .cr-page-admin__breadcrumb .breadcrumb .breadcrumb-item {
    cursor: pointer;
    font-weight: normal;
    color: #657894;
    font-size: 14px; }
    .cr-page-admin__breadcrumb .breadcrumb .breadcrumb-item.active {
      cursor: inherit;
      font-weight: 600; }

@media (min-width: 1599px) {
  .cr-page-admin.page-padding {
    padding-left: 70px;
    padding-right: 70px; } }

.cr-dropdown-color__dot-color {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50px;
  margin-right: 15px; }

.cr-dropdown-color__dot-color-border {
  border: 1px solid #c2d4e0; }

.cr-dropdown-color__color-option {
  display: flex;
  align-items: center; }
  .cr-dropdown-color__color-option .react-select__option {
    display: flex;
    align-items: center; }

.cr-dropdown-color__color-name {
  font-size: 16px;
  line-height: 20px;
  color: #111B2B; }

.cr-dropdown-color .react-select__value-container {
  padding-left: 18px; }

.cr-file-view {
  margin-bottom: 10px; }
  .cr-file-view__file-item {
    background: #ffffff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    margin-bottom: 15px; }
  .cr-file-view__file-name {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #334856; }
  .cr-file-view__file-buttons {
    justify-content: flex-end; }
    .cr-file-view__file-buttons .btn {
      margin: 0px;
      background: transparent;
      border: transparent !important;
      color: #795afa !important;
      min-width: auto !important;
      padding-left: 10.5px !important;
      padding-right: 10.5px !important; }
      .cr-file-view__file-buttons .btn:hover {
        background: transparent !important;
        box-shadow: none !important; }
      .cr-file-view__file-buttons .btn:focus {
        outline: none;
        background: transparent !important;
        box-shadow: none !important; }
      .cr-file-view__file-buttons .btn:active {
        outline: none;
        background: transparent !important;
        box-shadow: none !important; }

.cr-live-preview__logo {
  height: 50px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 25px;
  background-position: center center; }

.cr-live-preview__top-bar {
  height: 70px;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-top-left-radius: 25px;
  padding: 10px;
  padding-left: 32px; }

.cr-live-preview__group-name {
  margin-right: 25px;
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 0; }

.cr-live-preview__tag-line {
  margin-bottom: 10px;
  font-size: 14px;
  align-self: flex-end;
  white-space: nowrap; }

.cr-live-preview__content {
  background-color: #fff;
  min-height: 150px;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.cr-live-preview__sample-title {
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 29px;
  color: #111b2b; }

.cr-live-preview__text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334856;
  margin-bottom: 0; }

.cr-live-preview__buttons {
  display: flex;
  flex-direction: column; }
  .cr-live-preview__buttons .btn {
    font-weight: 700; }

.cr-live-preview__button {
  text-transform: uppercase;
  background: transparent;
  border-color: #334856;
  border-radius: 25px;
  color: #334856;
  max-width: 95px;
  margin: 5px 0; }
  .cr-live-preview__button:hover {
    background: transparent;
    border-color: #334856;
    color: #334856; }
  .cr-live-preview__button:active {
    background: transparent;
    border-color: #334856;
    color: #334856; }
  .cr-live-preview__button:focus {
    background: transparent;
    border-color: #334856;
    color: #334856; }

.cr-live-preview__left-content {
  max-width: 70%; }

.cr-live-preview__background-view {
  border: 1px solid #C2D4E0;
  box-sizing: border-box;
  border-radius: 6px;
  padding-top: 30px;
  padding-left: 40px;
  background-color: #F7FBFD;
  overflow: hidden;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1); }

.cr-group-admin-modal .modal-title {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 29px;
  color: #334856; }

.cr-group-admin-modal .modal-header {
  border-bottom: 1px solid #edf1f6;
  padding: 20px 40px; }

.cr-group-admin-modal .close {
  color: #657894;
  opacity: 1;
  position: absolute;
  right: 20px;
  top: 10px; }
  .cr-group-admin-modal .close span {
    font-size: 40px; }

.cr-group-admin-modal__body {
  padding: 40px; }

.cr-group-admin-form__content-form {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word; }

.cr-group-admin-form__form-buttons {
  margin-top: 20px; }

.cr-group-admin-form .form-group {
  margin-bottom: 22px; }
  .cr-group-admin-form .form-group label {
    text-transform: uppercase !important;
    display: block;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #43535c;
    margin-bottom: 8px; }

.cr-group-admin-form__view-wrapper label {
  text-transform: uppercase !important;
  display: block;
  color: #537a91; }

.cr-group-admin-form .btn-blue {
  margin-right: 15px; }

.cr-group-admin-form__form-buttons {
  margin-top: 8px; }

.cr-security-policy-modal .modal-title {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 29px;
  color: #334856; }

.cr-security-policy-modal .modal-header {
  border-bottom: 1px solid #edf1f6;
  padding: 20px 30px; }

.cr-security-policy-modal .close {
  color: #657894;
  opacity: 1;
  position: absolute;
  right: 20px;
  top: 10px; }
  .cr-security-policy-modal .close span {
    font-size: 40px; }

.cr-security-policy-modal__body {
  padding: 30px; }

.cr-security-policy-modal .cr-server-security-policy-form__content-form {
  padding: 0; }

.cr-server-security-policy-form__content-form {
  padding: 1.25rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box; }

.cr-server-security-policy-form__form-buttons {
  margin-top: 20px; }

.cr-server-security-policy-form .form-group label {
  text-transform: uppercase !important;
  display: block;
  color: #537a91; }

.cr-server-security-policy-form__view-wrapper label {
  text-transform: uppercase !important;
  display: block;
  color: #537a91; }

.cr-server-security-policy-form .btn-blue {
  margin-right: 15px; }

.cr-edit-image-modal .modal-title {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 29px;
  color: #334856;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 30px; }

.cr-edit-image-modal .modal-header {
  border-bottom-color: #edf1f6; }
  .cr-edit-image-modal .modal-header .close {
    display: none; }

.cr-edit-image-modal__close {
  background: transparent;
  border: none; }

.cr-edit-image-modal .modal-footer {
  justify-content: center;
  border-top: none;
  padding-bottom: 41px; }

.cr-edit-image-modal__drop-container {
  background: rgba(194, 212, 224, 0.24);
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 6px;
  height: 417px;
  overflow: hidden; }
  .cr-edit-image-modal__drop-container .drop-area {
    width: 100%;
    height: 100%; }

.cr-edit-image-modal__button-resize {
  display: flex;
  margin: 0 10px;
  min-width: 60px;
  justify-content: space-between; }

.cr-edit-image-modal__resize-icon {
  color: #795afa;
  width: 24px;
  height: 24px;
  background: #ffffff;
  border: 1px solid #acc4d4;
  box-sizing: border-box;
  display: block;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
  line-height: 20px; }

.cr-edit-image-modal__resize-action-text {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0.05em;
  color: #43535c;
  text-transform: uppercase; }

.cr-edit-image-modal__resize-action {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0; }

.cr-edit-image-modal__sub-title {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #43535c;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase; }

.cr-edit-image-modal__body {
  padding-top: 43px;
  padding-left: 48px;
  padding-right: 59px; }

.cr-edit-image-modal .cropper-view-box {
  outline: none; }

.cr-edit-image-modal .cropper-line {
  border: 1px dashed #f7fbfd; }

.cr-edit-image-modal .cropper-line.line-e {
  background-color: #795AFA;
  width: 1px;
  opacity: 1;
  outline: none;
  right: 1.5px; }

.cr-edit-image-modal .cropper-point {
  border: 1px solid #795AFA;
  height: 9px;
  width: 9px;
  opacity: 1;
  background-color: #fff; }

.cr-edit-image-modal .cropper-line.line-w {
  background-color: #795AFA;
  width: 1px;
  opacity: 1;
  outline: none;
  left: 1.5px; }

.cr-edit-image-modal .cropper-line.line-s {
  height: 1px;
  background-color: #795AFA;
  opacity: 1;
  outline: none;
  bottom: 0.5px; }

.cr-edit-image-modal .cropper-line.line-n {
  height: 1px;
  background-color: #795AFA;
  opacity: 1;
  outline: none;
  top: 0.5px; }

.cr-edit-image-modal .cropper-modal {
  opacity: 0; }

.cr-edit-image-modal .cropper-face {
  background-color: transparent;
  /* width: 245px; */
  /* height: 245px; */
  border: 10px solid #fff;
  opacity: 1;
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 1000px #00000094, inset 0px 0px 11px -1px #00000033; }

.cr-edit-image-modal .btn-blue {
  margin-right: 7.5px; }

.cr-edit-image-modal .btn-outline-blue {
  margin-left: 7.5px; }

.cr-edit-image-modal__no-image {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #795afa; }
  .cr-edit-image-modal__no-image div.placeholder {
    display: flex;
    justify-content: center;
    align-items: center; }

.cr-change-password-modal {
  max-width: 766px; }
  .cr-change-password-modal .modal-title {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-transform: capitalize; }
  .cr-change-password-modal .modal-header {
    padding: 20px 20px 20px 48px;
    border-bottom: 1px solid #edf1f6; }
    .cr-change-password-modal .modal-header .close {
      display: none; }
  .cr-change-password-modal__close {
    background: transparent;
    border: none; }
  .cr-change-password-modal .modal-footer {
    justify-content: center;
    border-top: none; }
  .cr-change-password-modal__body {
    padding: 55px 48px 63px 48px; }

.cr-channel-manager *::-webkit-scrollbar {
  display: none; }

.cr-channel-manager__channel-row {
  display: flex;
  align-items: flex-start; }

.cr-channel-manager__channel-form {
  margin-right: 97.47px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-left: 10px; }

.cr-channel-manager__channel-form-new {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
  position: relative;
  width: calc(100% - 100px); }
  .cr-channel-manager__channel-form-new .cr-channel-manager-form__channel-checkbox {
    right: -100px; }

.cr-channel-manager__channel-row-hidden {
  text-align: right; }
  .cr-channel-manager__channel-row-hidden span {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #334856; }

.cr-channel-manager__channel {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  display: flex;
  padding-top: 25px;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
  position: relative;
  margin-left: 10px; }

.cr-channel-manager__channel-open-form {
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.102);
  transition: height 0.2s ease;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .cr-channel-manager__channel-open-form .cr-channel-manager__channel-head {
    border-bottom: 1px solid #edf1f6; }

.cr-channel-manager__channel-head {
  padding: 0 59px 24px 20px;
  display: flex; }

.cr-channel-manager__channel-title {
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 29px;
  color: #111b2b;
  margin-left: 26px;
  margin-bottom: 0;
  text-transform: capitalize;
  cursor: pointer; }

.cr-channel-manager__shared-text {
  font-weight: normal !important; }

.cr-channel-manager__channel-title-inactive {
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 29px;
  color: #829fb1; }

.cr-channel-manager__menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c2d4e0;
  font-size: 29px;
  cursor: move; }

.cr-channel-manager__channel-icon {
  width: 30px;
  margin-left: 19px;
  position: relative;
  display: flex; }
  .cr-channel-manager__channel-icon img {
    max-width: 100%; }

.cr-channel-manager__edit-icon {
  position: absolute;
  bottom: -5px;
  right: -7px; }

.cr-channel-manager__section-main-action {
  display: flex;
  align-items: center; }

.cr-channel-manager__button-down {
  border: none;
  outline: none;
  padding: 0;
  margin-left: 42px;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center; }
  .cr-channel-manager__button-down img {
    max-width: 100%; }
  .cr-channel-manager__button-down:focus {
    outline: none; }

.cr-channel-manager__section-main-info {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: flex-start; }

.cr-channel-manager__channel-checkbox {
  display: inline-block;
  margin-left: 29px;
  margin-top: calc((89px - 26.44px) / 2); }

.cr-channel-manager__channel-checkbox-hidden {
  visibility: hidden; }

.cr-channel-manager__button-create {
  background: #ffffff !important;
  border-radius: 24px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  height: 49px;
  border-color: #795afa;
  position: absolute;
  top: -126px;
  right: 100px; }
  .cr-channel-manager__button-create span {
    color: #2d4364;
    font-weight: 800;
    font-size: 20px;
    max-width: calc(100% - 25px);
    margin-left: 17px; }

.cr-channel-manager__icon-add {
  color: #795afa;
  font-size: 25px; }

.cr-channel-manager__button-icon {
  color: #795afa;
  margin-right: 10px; }

.cr-channel-manager__button-down-icon {
  color: #795afa;
  font-size: 29px;
  font-weight: bold; }

.cr-channel-manager__button-up-icon {
  color: #c2d4e0;
  font-size: 29px; }

.cr-channel-manager__confirmation-modal .modal-header {
  padding: 0 45px; }

.cr-channel-manager__confirmation-modal .modal-body {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #657894;
  padding: 0 60px;
  margin-top: 20px; }

.cr-channel-manager__btn-clone {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .cr-channel-manager__btn-clone span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 40px;
    color: #795afa;
    margin-left: 10px; }

.cr-channel-manager__btn-clone-disabled {
  cursor: not-allowed; }
  .cr-channel-manager__btn-clone-disabled span {
    color: #bccedb; }

.cr-channel-manager__btn-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 33px;
  cursor: pointer; }
  .cr-channel-manager__btn-preview span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 40px;
    color: #795afa;
    margin-left: 10px; }

.cr-channel-manager__channel-sort-item {
  position: relative;
  margin: 17px 0; }

.cr-channel-manager__channel-sort-item-0 {
  margin-top: 0; }
  .cr-channel-manager__channel-sort-item-0 .cr-channel-manager__channel {
    border-top-left-radius: 0; }

.cr-channel-manager .cr-form-builder-fields .cr-form-builder-fields__input-label .cr-textinput__input-field input {
  font-size: 12px;
  padding-left: 23px;
  padding-right: 23px; }

.cr-channel-manager__channel-checkbox-new {
  opacity: 0; }

.cr-channel-manager__channels-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  margin-top: 29px;
  margin-left: 10px; }
  .cr-channel-manager__channels-filter .select-stations-wrapper {
    width: 388px; }
    .cr-channel-manager__channels-filter .select-stations-wrapper label {
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      color: #43535c; }

.cr-channel-manager__channels-filter-empty {
  justify-content: flex-end; }

.cr-channel-manager-form__channel-form-add {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
  position: relative; }

.cr-channel-manager-form--new .cr-channel-manager-form__header-section-overlay .cr-toggle-switch {
  display: none; }

.cr-channel-manager-form__channel-form-head {
  flex-direction: row;
  justify-content: space-between;
  flex: 1 1;
  display: flex;
  padding: 0 20px 20px 20px;
  cursor: pointer; }

.cr-channel-manager-form__channel-form-head-open {
  border-bottom: 1px solid #edf1f6; }

.cr-channel-manager-form__section-main-info {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: flex-start; }

.cr-channel-manager-form__channel-title {
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 29px;
  color: #111b2b;
  margin-left: 25px;
  margin-bottom: 0;
  text-transform: capitalize; }

.cr-channel-manager-form__channel-title-inactive {
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 29px;
  color: #829fb1; }

.cr-channel-manager-form__menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c2d4e0;
  font-size: 29px; }

.cr-channel-manager-form__channel-icon {
  width: 30px;
  margin-left: 15px;
  display: flex;
  align-items: center; }
  .cr-channel-manager-form__channel-icon img {
    max-width: 100%; }

.cr-channel-manager-form__fields {
  padding: 45px 45px 0 45px; }

.cr-channel-manager-form__button-create {
  background: #ffffff;
  border: 1px solid #795afa;
  box-sizing: border-box;
  border-radius: 24px;
  width: 170px;
  height: 44px;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 33px; }
  .cr-channel-manager-form__button-create span {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #795afa;
    text-transform: capitalize; }

.cr-channel-manager-form__button-down {
  border: none;
  outline: none;
  padding: 0;
  background: transparent;
  cursor: pointer; }
  .cr-channel-manager-form__button-down img {
    max-width: 100%; }

.cr-channel-manager-form__button-icon {
  color: #795afa;
  margin-right: 10px; }

.cr-channel-manager-form__button-down-icon {
  color: #795afa;
  font-size: 29px; }

.cr-channel-manager-form__button-up-icon {
  color: #c2d4e0;
  font-size: 29px; }

.cr-channel-manager-form .form-group label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #43535c;
  text-transform: uppercase; }

.cr-channel-manager-form__align-right {
  text-align: right; }

.cr-channel-manager-form__channel-checkbox {
  display: inline-block;
  margin-left: 24px;
  margin-top: 20px;
  position: absolute;
  right: 0;
  top: 0; }

.cr-channel-manager-form__channel-checkbox.new {
  z-index: 10;
  top: -80px;
  right: -95px; }

.cr-channel-manager-form__form-field-group {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 60px;
  padding-right: 15px; }

.cr-channel-manager-form__menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c2d4e0;
  font-size: 29px; }

.cr-channel-manager-form__form-field-group-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; }

.cr-channel-manager-form__form-field-group-head-left {
  display: flex;
  flex: 1 1;
  align-items: center; }
  .cr-channel-manager-form__form-field-group-head-left span {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #334856; }

.cr-channel-manager-form__form-field-group-head-right {
  display: flex; }
  .cr-channel-manager-form__form-field-group-head-right .form-group {
    margin-right: 30px;
    margin-bottom: 0; }

.cr-channel-manager-form .cr-toggle-switch__left-component {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  color: #374c59;
  text-transform: uppercase; }

.cr-channel-manager-form .cr-toggle-switch__right-component {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #829fb1;
  text-transform: uppercase; }

.cr-channel-manager-form__section-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #43535c;
  text-transform: uppercase; }

.cr-channel-manager-form__note-text span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #829fb1;
  display: block;
  margin: 10px 0; }

.cr-channel-manager-form .btn-blue {
  margin-right: 14px; }

.cr-channel-manager-form .cr-channel-manager-form__align-right .btn-radius {
  margin-right: 0; }

.cr-channel-manager-form__custom-fields {
  border-top: 1px solid #edf1f6;
  min-height: 100px;
  display: flex;
  padding: 0 40px;
  flex-direction: column; }

.cr-channel-manager-form__buttons {
  border-top: 1px solid #edf1f6;
  padding: 0 40px;
  padding-top: 21px;
  padding-bottom: 33px; }
  .cr-channel-manager-form__buttons button {
    text-transform: uppercase; }

.cr-channel-manager-form__custom-fields-container {
  padding-top: 44px; }

.cr-channel-manager-form__custom-fields-button {
  margin-top: 35px; }

.cr-channel-manager-form__alert-error-custom-fields {
  color: #f85032;
  margin-bottom: 10px; }

.cr-channel-manager-form__header-section-overlay {
  position: absolute;
  top: -81px;
  width: calc(100% - 95px);
  height: 81px;
  left: 95px;
  z-index: 2;
  background-color: transparent; }

.cr-channel-manager-form__overlay-navigation {
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1; }

.cr-channel-manager-form__overlay-actions {
  cursor: pointer;
  width: 315px;
  height: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: flex; }
  .cr-channel-manager-form__overlay-actions .cr-toggle-switch {
    position: absolute;
    top: 23px;
    right: -98px; }

.cr-channel-manager-form__overlay-action-preview {
  width: 110px; }

.cr-channel-manager-form__overlay-action-copy {
  flex: 1 1; }

.cr-channel-manager-form__overlay-action-dropdown {
  flex: 1 1; }

.cr-channel-manager-form__overlay-action-switch {
  flex: 1 1; }

.cr-channel-manager-form__confirmation-modal .btn-blue {
  margin-right: 7.5px; }

.cr-channel-manager-form__confirmation-modal .btn-outline-blue {
  margin-left: 7.5px; }

.cr-channel-manager-form__custom-fields-sort {
  list-style: none;
  padding: 0; }

.cr-channel-manager-form .react-select__placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1 !important; }

.cr-channel-manager-form .cr-textinput__input-field ::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-channel-manager-form .cr-textinput__input-field ::-moz-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-channel-manager-form .cr-textinput__input-field :-ms-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-channel-manager-form .cr-textinput__input-field :-moz-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-channel-manager .cr-channel-manager-form {
  position: relative; }

.cr-channel-manager-dropdown__option-text {
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-left: 11px; }

.cr-channel-manager-dropdown__option-text-primary {
  font-weight: bold;
  margin-left: 5px; }

.cr-channel-manager-dropdown__link-primary {
  color: #795afa;
  margin-left: 5px;
  cursor: pointer; }

.cr-channel-manager-dropdown__option-container {
  width: 100%; }

.cr-channel-manager-dropdown__option {
  display: flex;
  align-items: center; }

.cr-channel-manager-dropdown .react-select__option--is-selected {
  background-color: transparent;
  color: inherit; }
  .cr-channel-manager-dropdown .react-select__option--is-selected:active {
    background-color: transparent; }

.cr-traffic-details {
  margin-bottom: 33px; }
  .cr-traffic-details__main-section {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    margin-bottom: 0px; }
  .cr-traffic-details__main-title {
    border-bottom: 1px solid #edf1f6;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 47.76px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .cr-traffic-details__title {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856;
    margin-bottom: 0; }
  .cr-traffic-details__manage-link {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #795afa; }
    .cr-traffic-details__manage-link span {
      margin-left: 5px; }
  .cr-traffic-details__schedule-view-value {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    color: #334856; }
  .cr-traffic-details__text-value {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856;
    height: 50px;
    display: flex;
    align-items: center; }
  .cr-traffic-details__main-content {
    padding-left: 50px;
    padding-top: 47px;
    padding-right: 50px;
    padding-bottom: 14px; }
  .cr-traffic-details__text-description {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: #334856; }
  .cr-traffic-details__text-description-empty {
    color: #829fb1; }
  .cr-traffic-details__btn-recurrence {
    border: 1px solid #795afa;
    box-sizing: border-box;
    border-radius: 24px;
    height: 44px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .cr-traffic-details__btn-recurrence span {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #795afa;
      text-transform: capitalize; }
  .cr-traffic-details__btn-has-campaign {
    width: 284px !important; }
  .cr-traffic-details__selected-item {
    background: #f7fbfd;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 14px;
    display: inline-flex;
    padding-left: 15px;
    padding-right: 12px;
    margin-bottom: 11px;
    height: 42px;
    align-items: center;
    justify-content: center; }
  .cr-traffic-details__selected-name {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #334856; }
  .cr-traffic-details__trash-icon {
    margin-right: 14px;
    cursor: pointer; }
  .cr-traffic-details__channel-form {
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
    margin-bottom: 20px;
    overflow: hidden; }
    .cr-traffic-details__channel-form .cr-form-builder-fields__form-group {
      margin-bottom: 0; }
  .cr-traffic-details__empty-text {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #829fb1; }
  .cr-traffic-details__traffic-detail-edit label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #43535c;
    margin-bottom: 8px;
    text-transform: uppercase; }
  .cr-traffic-details__text-campaign-description {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #829fb1;
    height: 50px;
    display: flex;
    align-items: center; }
  .cr-traffic-details__recurrence-icon {
    margin-right: 10px; }
  .cr-traffic-details__channels-list {
    margin-top: 20px; }
  .cr-traffic-details__special-schedule {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #ff006c !important;
    display: flex !important;
    align-items: center;
    margin-bottom: 11px;
    text-transform: capitalize !important; }
    .cr-traffic-details__special-schedule span {
      margin-left: 5px; }
  .cr-traffic-details .cr-traffic-details__channel-form:last-child {
    margin-bottom: 0; }
  .cr-traffic-details .cr-traffic-details__channel-form .cr-custom-form__form-header {
    border: none; }
  .cr-traffic-details .cr-form-builder-fields .full_wrap .rowWrap > .textInput {
    width: 40%; }
  .cr-traffic-details .cr-form-builder-fields .full_wrap .cr-textinput__input-field .textInput {
    width: 100%; }
  .cr-traffic-details .cr-form-builder-fields {
    margin-bottom: 8px; }
  .cr-traffic-details .react-select-container .react-select__single-value {
    color: #111b2b !important; }
  .cr-traffic-details .cr-custom-form__right-column textarea {
    background-color: #f7fbfd;
    border-radius: 6px;
    min-height: auto;
    padding: 18px 19px;
    color: #111b2b; }
  .cr-traffic-details .cr-custom-form__right-column .form-group {
    margin-bottom: 24px; }
  .cr-traffic-details .cr-custom-form__right-column .cr-custom-form__assets {
    background-color: #f7fbfd;
    border-color: #829fb1; }
  .cr-traffic-details .cr-custom-form__right-column .cr-handle-assets {
    margin-top: 20px; }
  .cr-traffic-details .cr-handle-assets__file-item {
    margin-bottom: 10px; }
  .cr-traffic-details .cr-form-builder-fields__assets,
  .cr-traffic-details .cr-custom-form__assets {
    padding: 20px 10px 21px 10px; }
  .cr-traffic-details .cr-form-builder-fields .cr-textinput__input-field textarea {
    min-height: 82px;
    padding: 14px 13px;
    border-radius: 6px;
    border-color: #c2d4e0; }
  .cr-traffic-details .cr-custom-form__container-view {
    margin-top: 47px; }
    .cr-traffic-details .cr-custom-form__container-view label {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #829fb1; }
  .cr-traffic-details__recurrence-statement {
    margin-bottom: 25px; }
  .cr-traffic-details__marketing-channels {
    margin-bottom: 34px; }
  .cr-traffic-details .cr-form-builder-fields .form-control {
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    color: #111b2b; }
  .cr-traffic-details .cr-form-builder-fields .cr-textinput__input-field input {
    color: #111b2b !important; }
  .cr-traffic-details .cr-custom-form__form {
    margin-bottom: 34px; }
    .cr-traffic-details .cr-custom-form__form label {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #43535c; }
  .cr-traffic-details .cr-custom-form__form-content {
    background-color: #fff; }
  .cr-traffic-details__schedule-dates-view-value {
    margin-bottom: 46px; }
  .cr-traffic-details__view-field-dates-label, .cr-traffic-details__view-field-label {
    margin-bottom: 18px; }
  .cr-traffic-details__button-campaign {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .cr-traffic-details__placement-confirmation-modal {
    max-width: 600px; }
    .cr-traffic-details__placement-confirmation-modal .cr-reminder-modal__subtitle {
      font-size: 24px;
      color: #000000;
      font-weight: 400;
      text-transform: inherit;
      line-height: inherit;
      margin-top: 10px; }
    .cr-traffic-details__placement-confirmation-modal .cr-reminder-modal__header {
      margin-bottom: 0px; }
    .cr-traffic-details__placement-confirmation-modal .cr-reminder-modal__cancel-button {
      margin-left: 0 !important;
      background-color: #795AFA !important;
      color: #fff; }
      .cr-traffic-details__placement-confirmation-modal .cr-reminder-modal__cancel-button:hover {
        background-color: #4324c7 !important;
        border-color: #5129f9 !important; }

.traffic-container > div {
  padding: 0; }

.traffic-container .cr-traffic-details {
  margin-bottom: 0; }

.traffic-container-bottom-buttons {
  margin-left: 50px; }

.cr-fields-form-builder-modal {
  max-width: 384px; }
  .cr-fields-form-builder-modal__header.modal-header {
    border-bottom: none;
    padding: 0; }
  .cr-fields-form-builder-modal__header .close {
    display: none; }
  .cr-fields-form-builder-modal__close {
    position: absolute;
    cursor: pointer;
    right: 15px;
    top: 16px;
    z-index: 9; }
    .cr-fields-form-builder-modal__close:focus {
      outline: none; }
    .cr-fields-form-builder-modal__close:active {
      outline: none; }
  .cr-fields-form-builder-modal__title {
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    color: #000000;
    padding-left: 22px;
    margin-bottom: 10px; }
  .cr-fields-form-builder-modal__field-name {
    padding: 9px 22px;
    cursor: pointer; }
    .cr-fields-form-builder-modal__field-name span {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #334855;
      text-transform: capitalize;
      line-height: 1; }
    .cr-fields-form-builder-modal__field-name:hover {
      background: #ebf2f7; }
  .cr-fields-form-builder-modal__field-name-disabled {
    cursor: not-allowed; }
  .cr-fields-form-builder-modal .modal-body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 16px; }
  .cr-fields-form-builder-modal .modal-content {
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102); }
  .cr-fields-form-builder-modal__group {
    margin: 32px 0;
    padding: 0; }
  .cr-fields-form-builder-modal__field-section {
    border-top: 1px solid #c2d4e0;
    padding: 20px 0 5px 0;
    margin-top: 10px; }
  .cr-fields-form-builder-modal__group-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 8px 22px; }
    .cr-fields-form-builder-modal__group-title span {
      margin-left: 26px;
      font-weight: 600;
      font-size: 18px;
      color: #334856; }
  .cr-fields-form-builder-modal__group-icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition: -webkit-transform 0.1s ease;
    transition: transform 0.1s ease;
    transition: transform 0.1s ease, -webkit-transform 0.1s ease; }
  .cr-fields-form-builder-modal__group-icon-active {
    -webkit-transform: inherit;
            transform: inherit; }
  .cr-fields-form-builder-modal__group-fields .cr-fields-form-builder-modal__field-name {
    padding-left: 71px;
    padding-right: 10px; }
    .cr-fields-form-builder-modal__group-fields .cr-fields-form-builder-modal__field-name span {
      text-transform: inherit; }
  .cr-fields-form-builder-modal__section-head {
    border-top: 1px solid #edf1f6;
    font-weight: 600;
    font-size: 18px;
    color: #334856;
    padding: 25px 22px 14px 22px;
    margin-top: 10px; }
  .cr-fields-form-builder-modal__empty-fields {
    padding-left: 22px;
    color: #334856; }

.form-builder-field {
  margin-bottom: 25px !important; }

.form-builder-edit-field {
  margin-bottom: 16px; }

.form-builder-edit-field-2 {
  margin-bottom: 32px; }

.cr-form-builder-fields__col-reset {
  margin-bottom: 0; }

.cr-form-builder-fields__align-right {
  text-align: right; }

.cr-form-builder-fields__channel-checkbox {
  width: 25px;
  display: inline-block;
  margin-left: 37px;
  margin-top: 20px;
  position: absolute;
  right: -62px;
  top: 0; }

.cr-form-builder-fields__form-field-group {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  padding-top: 16px;
  padding-bottom: 20px;
  padding-left: 56px;
  padding-right: 40px; }
  .cr-form-builder-fields__form-field-group .cr-toggle-switch {
    justify-content: flex-end; }

.cr-form-builder-fields__form-field-group-section-header {
  padding-right: 45px;
  background: #f7fbfd; }
  .cr-form-builder-fields__form-field-group-section-header .form-control {
    color: #000 !important;
    font-size: 18px !important;
    font-weight: 600 !important; }

.cr-form-builder-fields__menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  left: -38px;
  position: absolute;
  cursor: move;
  top: 14px; }

.cr-form-builder-fields__form-field-group-bottom .col-8,
.cr-form-builder-fields__form-field-group-bottom .col-4,
.cr-form-builder-fields__form-field-group-bottom .form-group {
  margin-bottom: 0 !important; }

.cr-form-builder-fields__form-field-group-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px; }
  .cr-form-builder-fields__form-field-group-head [class^="col-"] {
    margin-bottom: 0 !important; }

.cr-form-builder-fields__form-field-group-head-single-left {
  flex: 1 1;
  align-items: center;
  position: relative; }

.cr-form-builder-fields__form-field-group-head-left {
  display: flex;
  flex: 1 1;
  position: relative;
  align-items: center; }
  .cr-form-builder-fields__form-field-group-head-left span:not(.text-danger) {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #334856; }

.cr-form-builder-fields__form-field-group-head-right {
  display: flex;
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 25px; }
  .cr-form-builder-fields__form-field-group-head-right .form-group {
    margin-right: 30px;
    margin-bottom: 0; }

.cr-form-builder-fields__header-line {
  width: 100%;
  height: 1px;
  background-color: #829fb1;
  margin: 20px 0; }

.cr-form-builder-fields__switch-active {
  font-weight: bold !important;
  color: #374c59; }

.cr-form-builder-fields .cr-toggle-switch__main-component {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.cr-form-builder-fields .cr-toggle-switch__left-component {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: #829fb1;
  text-transform: uppercase;
  transition: all 0.1s ease; }

.cr-form-builder-fields .cr-toggle-switch__right-component {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: #829fb1;
  text-transform: uppercase; }

.cr-form-builder-fields__section-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #43535c;
  text-transform: uppercase; }

.cr-form-builder-fields__note-text span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #829fb1;
  display: block;
  margin: 10px 0; }

.cr-form-builder-fields .cr-textinput__input-field input {
  background: #fff; }

.cr-form-builder-fields .cr-textinput__input-field textarea {
  background: #fff; }

.cr-form-builder-fields .cr-textinput__input-field ::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #829fb1;
  text-transform: capitalize; }

.cr-form-builder-fields .cr-textinput__input-field ::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #829fb1;
  text-transform: capitalize; }

.cr-form-builder-fields__text-placeholder ::-webkit-input-placeholder {
  text-transform: initial !important; }

.cr-form-builder-fields__text-placeholder ::placeholder {
  text-transform: initial !important; }

.cr-form-builder-fields .cr-textinput__input-wrapper .react-datepicker-wrapper {
  border-radius: 6px;
  overflow: hidden; }

.cr-form-builder-fields .cr-textinput__input-field input.form-control {
  border-radius: 6px; }

.cr-form-builder-fields__input-label {
  position: relative;
  min-width: 60%;
  max-width: 600px; }
  .cr-form-builder-fields__input-label:hover .cr-form-builder-fields__pencil-icon {
    opacity: 1; }
  .cr-form-builder-fields__input-label .cr-textinput__input-wrapper {
    height: 39px; }
  .cr-form-builder-fields__input-label .cr-textinput__input-field input {
    text-transform: uppercase; }
  .cr-form-builder-fields__input-label ::-webkit-input-placeholder {
    text-transform: uppercase !important; }
  .cr-form-builder-fields__input-label ::placeholder {
    text-transform: uppercase !important; }

.cr-form-builder-fields__full-input-label {
  width: 100%;
  max-width: 100%; }

.cr-form-builder-fields__master-input-field {
  width: 100%;
  max-width: 100%; }
  .cr-form-builder-fields__master-input-field .cr-textinput__input-field input {
    color: #000 !important;
    text-transform: uppercase !important; }

.cr-form-builder-fields__system-input-field {
  padding-right: 0 !important; }

.cr-form-builder-fields .cr-textinput__input-field input {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #829fb1;
  text-transform: capitalize; }

.cr-form-builder-fields .react-select__placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #495057;
  text-transform: capitalize; }

.cr-form-builder-fields .cr-textinput__input-field textarea {
  min-height: 122px;
  border-radius: 6px; }

.cr-form-builder-fields__pencil-icon {
  position: absolute;
  top: 13px;
  right: 0;
  opacity: 0;
  transition: opacity 0.1s ease; }

.cr-form-builder-fields__form-field-group-saved {
  position: relative; }
  .cr-form-builder-fields__form-field-group-saved .order-view-value {
    white-space: inherit;
    overflow: initial;
    word-break: break-all; }

.cr-form-builder-fields__form-field-group-saved-button-close {
  position: absolute;
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer; }

.cr-form-builder-fields__selection-item-disabled .cr-form-builder-fields__menu-selection-icon {
  cursor: auto; }

.cr-form-builder-fields__menu-selection-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c2d4e0;
  font-size: 29px;
  cursor: move;
  margin-top: 19px; }

.cr-form-builder-fields__selection-item {
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-bottom: 8px; }
  .cr-form-builder-fields__selection-item .cr-textinput {
    flex: 1 1;
    margin-left: 10px; }
    .cr-form-builder-fields__selection-item .cr-textinput:hover ~ .cr-form-builder-fields__selection-remove {
      opacity: 1; }
    .cr-form-builder-fields__selection-item .cr-textinput .cr-textinput__input-wrapper .form-control {
      border-radius: 6px; }
    .cr-form-builder-fields__selection-item .cr-textinput .cr-textinput__input-field input {
      text-transform: inherit; }
  .cr-form-builder-fields__selection-item .cr-radio {
    height: 49px;
    display: flex; }
  .cr-form-builder-fields__selection-item .cr-checkbox {
    height: 49px;
    display: flex; }

.cr-form-builder-fields__selection-item-container {
  margin-bottom: 7px; }
  .cr-form-builder-fields__selection-item-container::after {
    content: "";
    display: block;
    clear: both; }
  .cr-form-builder-fields__selection-item-container::before {
    content: "";
    display: block;
    clear: both; }

.cr-form-builder-fields__selection-item-container-selected .cr-textinput__input-wrapper input {
  background-color: #e1f4fe; }

.cr-form-builder-fields__form-field-group-highlight {
  background-color: #e1f4fe; }

.cr-form-builder-fields__form-field-group-disable {
  opacity: 0.2; }

.cr-form-builder-fields__button-new-selection {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  color: #795afa;
  margin-left: 27px;
  cursor: pointer;
  margin-top: 18px;
  cursor: pointer; }
  .cr-form-builder-fields__button-new-selection span {
    margin-left: 13px; }

.cr-form-builder-fields__selection-condition {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #43535c;
  margin-bottom: 14px;
  cursor: pointer;
  margin-top: 14px; }
  .cr-form-builder-fields__selection-condition span {
    margin-right: 5px; }

.cr-form-builder-fields__selection-remove {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 10px;
  opacity: 0; }
  .cr-form-builder-fields__selection-remove:hover {
    opacity: 1; }

.cr-form-builder-fields__selection-remove-logic {
  right: -30px;
  z-index: 9; }

.cr-form-builder-fields__error-text {
  color: #f85032; }

.cr-form-builder-fields__tooltip {
  opacity: 1; }
  .cr-form-builder-fields__tooltip .rc-tooltip-inner {
    background-color: #fff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 10px 20px;
    max-width: 265px; }

.cr-form-builder-fields__tooltip-title {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  color: #111b2b;
  margin-bottom: 15px; }

.cr-form-builder-fields__tooltip-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334856;
  margin-bottom: 0; }

.cr-form-builder-fields__description-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #334856; }

.cr-form-builder-fields__col-label {
  margin-bottom: 0; }

.cr-form-builder-fields__date-selector {
  position: relative; }

.cr-form-builder-fields__calendar-icon {
  position: absolute;
  top: 10px;
  right: 10px; }

.cr-form-builder-fields__form-field-group-line {
  padding-bottom: 10px; }
  .cr-form-builder-fields__form-field-group-line .row {
    position: unset; }
  .cr-form-builder-fields__form-field-group-line [class^="col-"] {
    position: unset !important;
    margin-bottom: 0 !important; }
  .cr-form-builder-fields__form-field-group-line .col-4 {
    margin-top: 10px; }

.cr-form-builder-fields__form-field-group-delete-icon {
  display: flex;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 25px; }

.cr-form-builder-fields__sub-field-delete-icon {
  position: absolute;
  right: 25px;
  cursor: pointer; }

.cr-form-builder-fields__media-mimes {
  margin-bottom: 7px; }
  .cr-form-builder-fields__media-mimes .form-group {
    display: inline-block;
    width: 50%;
    overflow: hidden;
    margin-bottom: 0; }

.cr-form-builder-fields__media-mime-label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 38px;
  margin-left: 20;
  color: #537a91; }

.cr-form-builder-fields__subtitle {
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;
  color: #43535c;
  margin-bottom: 22px; }

.cr-form-builder-fields .no-files {
  display: none; }

.cr-form-builder-fields__file-mimes {
  margin-bottom: 7px; }
  .cr-form-builder-fields__file-mimes .form-group {
    display: inline-block;
    width: 25%;
    overflow: hidden;
    margin-bottom: 0; }

.cr-form-builder-fields__demographic-label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #657894;
  margin-left: 7px; }

.cr-form-builder-fields__demographics-group {
  margin-bottom: 23px; }
  .cr-form-builder-fields__demographics-group .form-group {
    display: inline-block;
    overflow: hidden;
    margin-bottom: 0;
    margin-right: 10px; }

.cr-form-builder-fields__demographic-row-label {
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #43535c;
  font-size: 13px; }

.cr-form-builder-fields__demographic-row-gender {
  margin-bottom: 1rem; }
  .cr-form-builder-fields__demographic-row-gender .form-group {
    display: inline-block;
    width: 33%; }

.cr-form-builder-fields__demographic-row-common {
  margin-bottom: 1rem; }

.cr-form-builder-fields__demographic-age-range-divider {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  color: #829fb1;
  font-size: 16px;
  line-height: 50px; }

.cr-form-builder-fields__demographic-row-slider {
  margin-bottom: 4rem; }

.cr-form-builder-fields__slider .rc-slider-rail {
  height: 26px;
  border-radius: 25px;
  background-color: #e4ebef; }

.cr-form-builder-fields__slider .rc-slider-track {
  height: 26px;
  border-radius: 25px;
  background-color: #795afa; }

.cr-form-builder-fields__slider .rc-slider-handle {
  background-color: #ffffff;
  width: 26px;
  height: 26px;
  margin-top: 0;
  border-color: #795afa; }

.cr-form-builder-fields__slider .rc-slider-handle-2 {
  margin-left: -26px; }

.cr-form-builder-fields__slide-value {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 21px;
  color: #111b2b; }

.cr-form-builder-fields__slide-tooltip .rc-slider-tooltip-inner {
  background-color: transparent;
  box-shadow: none; }

.cr-form-builder-fields__reset-margin {
  margin-bottom: 0 !important; }

.cr-form-builder-fields__label-hidden {
  display: block;
  width: 1px;
  height: 16px; }

.cr-form-builder-fields__branch-icon {
  width: 34px;
  position: absolute;
  display: flex;
  right: 10px;
  top: 10px;
  cursor: pointer; }
  .cr-form-builder-fields__branch-icon img {
    max-width: 100%; }
  .cr-form-builder-fields__branch-icon .branch-disabled {
    cursor: not-allowed; }

.cr-form-builder-fields__push-rule {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #795afa;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  cursor: pointer; }
  .cr-form-builder-fields__push-rule span {
    margin-left: 10px; }

.cr-form-builder-fields__push-rule-disabled {
  color: #e3ecf1;
  cursor: not-allowed; }

.cr-form-builder-fields__rule-container {
  box-sizing: border-box;
  border-radius: 6px;
  margin-left: 30px;
  padding: 20px 30px;
  position: relative; }

.cr-form-builder-fields__rule-condition-label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #657894; }

.cr-form-builder-fields__rule-condition-label-value {
  color: #334856;
  margin-left: 5px; }

.cr-form-builder-fields__rule-show {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  padding: 5px 10px;
  margin-right: 12px;
  color: #657894; }

.cr-form-builder-fields__rule-option {
  margin-bottom: 10px; }

.cr-form-builder-fields__rule-option-value {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334856; }

.cr-form-builder-fields__list-rule-options {
  margin: 10px 0; }

.cr-form-builder-fields__rule-edit {
  color: #795afa;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center; }
  .cr-form-builder-fields__rule-edit span {
    margin-left: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px; }

.cr-form-builder-fields__push-rule-disabled {
  color: #e3ecf1;
  cursor: not-allowed; }

.cr-form-builder-fields__branch-icon-selected {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: rotate 0.2s ease; }

.cr-form-builder-fields__drop-text-class {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #795afa; }

.cr-form-builder-fields__fields-status-content {
  display: flex;
  align-items: center;
  justify-content: center; }

.cr-form-builder-fields__fields-status-upload {
  width: calc(100% - 36px);
  margin-left: 36px; }

.cr-form-builder-fields__fields-status-item {
  display: flex;
  flex-direction: column; }
  .cr-form-builder-fields__fields-status-item .cr-file-view__file-name {
    color: #334856;
    white-space: nowrap;
    max-width: 50%;
    text-overflow: ellipsis;
    overflow: hidden; }
  .cr-form-builder-fields__fields-status-item .cr-file-view__file-buttons {
    min-width: 35%;
    display: flex; }
    .cr-form-builder-fields__fields-status-item .cr-file-view__file-buttons .btn {
      padding: 0 !important; }
  .cr-form-builder-fields__fields-status-item .cr-file-view__file-item {
    margin-bottom: 0px; }
  .cr-form-builder-fields__fields-status-item .cr-file-view {
    width: calc(100% - 33px); }

.cr-form-builder-fields__status-icon {
  margin-right: 13px; }

.cr-form-builder-fields__max-characters {
  display: flex;
  flex: 1 1;
  height: 100%;
  align-items: flex-end; }

.cr-form-builder-fields__selections {
  margin-bottom: 14px; }

.cr-form-builder-fields__selections-heading {
  color: #43535c;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 14px;
  margin-bottom: 11px; }

.cr-form-builder-fields__field-draggable .cr-form-builder-fields__menu-selection-icon {
  margin-right: 10px; }

.cr-form-builder-fields__radio-selections .cr-form-builder-fields__button-new-selection {
  margin-left: 36px; }

.cr-form-builder-fields__checkbox-selections .cr-form-builder-fields__button-new-selection {
  margin-left: 35px; }

.cr-form-builder-fields__form-field-switch-date-selector {
  margin-top: 9px;
  margin-bottom: 23px; }

.cr-form-builder-fields__media-description-text {
  margin-top: 25px; }

.cr-form-builder-fields__form-field-group-media {
  margin-bottom: 19px; }

.cr-form-builder-fields__include-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #43535c;
  margin-bottom: 16px;
  margin-top: 7px;
  text-transform: uppercase; }

.cr-form-builder-fields .react-switch-handle {
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.282); }

.cr-form-builder-fields__geotargeting-form .dropDown_input {
  margin-bottom: 5px; }

.cr-form-builder-fields__geotargeting-form .plus_button {
  border-radius: 24px;
  width: 45px !important;
  height: 45px;
  min-width: auto !important;
  border: 1px solid #795afa;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  color: #795afa; }

.cr-form-builder-fields__geotargeting-form .cross {
  cursor: pointer; }

.cr-form-builder-fields__group-label {
  font-size: 18px;
  line-height: 23px;
  text-transform: capitalize;
  color: #657894;
  margin-top: 8px; }

.cr-form-builder-fields__sub-field {
  margin-top: 30px;
  margin-bottom: 30px; }
  .cr-form-builder-fields__sub-field .cr-form-builder-fields__master-input-field {
    padding-right: 50px; }

.cr-form-builder-fields__eye-icon {
  position: absolute;
  right: 0;
  top: 8px;
  cursor: pointer;
  z-index: 9; }

.cr-form-builder-fields__separator {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 8px; }
  .cr-form-builder-fields__separator span {
    color: #000;
    text-transform: uppercase;
    margin-right: 10px; }

.cr-form-builder-fields__separator-line {
  width: 100%;
  height: 1px;
  background-color: #657894; }

.cr-form-builder-fields__form-field-group-separator {
  background-color: #f7fbfd; }
  .cr-form-builder-fields__form-field-group-separator .cr-form-builder-fields__form-field-group-head {
    margin-bottom: 0; }

.cr-form-builder-fields__form-field-group-helper-text {
  background: #f7fbfd; }
  .cr-form-builder-fields__form-field-group-helper-text textarea {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000; }
    .cr-form-builder-fields__form-field-group-helper-text textarea::-webkit-input-placeholder {
      text-transform: inherit !important; }
    .cr-form-builder-fields__form-field-group-helper-text textarea::placeholder {
      text-transform: inherit !important; }

.cr-form-builder-fields__form-date-selector .cr-toggle-switch {
  justify-content: flex-start; }

.cr-form-builder-fields__form-name-title {
  line-height: 20px;
  text-transform: capitalize;
  color: #111b2b; }

.cr-form-builder-fields__first-last-name {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  height: 78px;
  padding: 0 56px;
  line-height: 78px; }
  .cr-form-builder-fields__first-last-name span {
    color: #000000;
    text-transform: uppercase; }

.cr-form-builder-fields__required-text {
  font-size: 17px;
  line-height: 21px;
  color: #537a91 !important;
  margin-left: 156px; }

.cr-form-builder-fields__group-primary .form-group {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between; }

.cr-form-builder-fields__group-primary .cr-textinput {
  max-width: calc(100% - 105px);
  flex: 1 1; }

.cr-form-builder-fields__group-primary .cr-checkbox {
  position: absolute;
  right: 0;
  top: 35px; }

.cr-form-builder-fields__group-phone {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  .cr-form-builder-fields__group-phone .cr-textinput {
    max-width: calc(100% - 105px);
    flex: 1 1; }
  .cr-form-builder-fields__group-phone .cr-checkbox {
    margin-top: 33px; }

.cr-form-builder-fields__group-business-phone {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%; }
  .cr-form-builder-fields__group-business-phone .cr-textinput__input-label {
    min-height: 23px; }
  .cr-form-builder-fields__group-business-phone .cr-textinput {
    max-width: calc(100% - 225px);
    flex: 1 1; }
  .cr-form-builder-fields__group-business-phone .input-container {
    margin-bottom: 0 !important; }
  .cr-form-builder-fields__group-business-phone .cr-checkbox {
    margin-bottom: 10px; }

.cr-form-builder-fields .cr-radio-buttons__radios {
  margin-top: 15px; }

.cr-form-builder-fields__social-security-group {
  display: flex;
  align-items: center; }
  .cr-form-builder-fields__social-security-group .cr-textinput {
    width: 92px; }
  .cr-form-builder-fields__social-security-group .divider {
    display: block;
    margin: 0 10px; }

.cr-form-builder-fields__input-ext {
  width: 98px; }

.cr-form-builder-fields__section-header {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.cr-form-builder-fields__form-field-section-header-saved {
  padding-top: 32px;
  padding-bottom: 28px; }
  .cr-form-builder-fields__form-field-section-header-saved::before {
    content: "";
    display: block;
    width: calc(100% + 80px);
    height: 1px;
    background-color: #edf1f6;
    position: absolute;
    top: 0;
    left: -40px;
    right: -40px; }

.cr-form-builder-fields__section-header-title {
  font-weight: 600;
  font-size: 23px;
  line-height: 29px;
  color: #334856; }

.cr-form-builder-fields__form-field-separator-saved {
  height: 50px; }
  .cr-form-builder-fields__form-field-separator-saved::before {
    content: "";
    display: block;
    width: calc(100% + 110px);
    height: 1px;
    background-color: #edf1f6;
    position: absolute;
    top: 24.5px;
    left: -55px;
    right: -55px; }

.cr-form-builder-fields__form-field-helper-text-saved {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #334856;
  text-transform: initial; }

.cr-form-builder-fields__duplicate {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  right: 80px;
  top: 15px; }
  .cr-form-builder-fields__duplicate span {
    font-size: 14px;
    color: #795afa;
    margin-left: 8px; }

.cr-geo-targeting .full_wrap .rowWrap {
  display: flex;
  align-items: center; }
  .cr-geo-targeting .full_wrap .rowWrap .dropDown_input {
    width: 23%;
    margin-right: 10px;
    margin-bottom: 5px; }
  .cr-geo-targeting .full_wrap .rowWrap .textInput {
    margin-right: 10px; }
  .cr-geo-targeting .full_wrap .rowWrap .textInput_last {
    width: 30%;
    margin-right: 10px; }
  .cr-geo-targeting .full_wrap .rowWrap input.textInput_last {
    width: 100%;
    margin-right: 10px; }
  .cr-geo-targeting .full_wrap .rowWrap .plus_button {
    background-color: transparent;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #795afa;
    margin-bottom: 4px;
    cursor: pointer;
    display: inline-flex;
    min-width: auto;
    align-items: center;
    justify-content: center;
    padding: 12px !important; }
    .cr-geo-targeting .full_wrap .rowWrap .plus_button svg {
      color: #795afa; }

.cr-geo-targeting .full_wrap .FlexRow {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px; }

.cr-geo-targeting .full_wrap .notification_text {
  flex: 1 1;
  word-break: break-all;
  padding-right: 10px; }

.cr-geo-targeting .full_wrap .location {
  background-color: #e6ecf1;
  border: 1px solid #ccc;
  padding: 1px 10px;
  color: #3b3e40 !important;
  font-size: 14px;
  border-radius: 2px;
  margin-right: 10px;
  min-width: 80px;
  text-align: center; }

.cr-geo-targeting .full_wrap .cross {
  background-color: white;
  color: #bacedb;
  border: none;
  outline: none; }
  .cr-geo-targeting .full_wrap .cross svg {
    font-size: 22px; }

.cr-geo-targeting .full_wrap .targets {
  color: #aeb5b8;
  margin-bottom: 10px;
  font-size: 14px !important;
  font-weight: 600; }

.cr-channel-form__channel-form {
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1; }

.cr-channel-form__channel-form-head {
  flex-direction: row;
  justify-content: space-between;
  flex: 1 1;
  display: flex;
  padding: 20px;
  cursor: pointer; }

.cr-channel-form__channel-form-head-open {
  border-bottom: 1px solid #edf1f6; }

.cr-channel-form__section-main-info {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: flex-start; }

.cr-channel-form__channel-title {
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 29px;
  color: #111b2b;
  margin-left: 25px;
  margin-bottom: 0;
  text-transform: capitalize; }

.cr-channel-form__menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c2d4e0;
  font-size: 29px; }

.cr-channel-form__channel-icon {
  width: 30px;
  margin-left: 15px;
  display: flex;
  align-items: center; }
  .cr-channel-form__channel-icon img {
    max-width: 100%; }

.cr-channel-form__fields {
  padding: 45px 45px 0 45px; }

.cr-channel-form__button-create {
  background: #ffffff;
  border: 1px solid #795afa;
  box-sizing: border-box;
  border-radius: 24px;
  min-width: 140px;
  width: auto;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 30px; }
  .cr-channel-form__button-create span {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #795afa;
    text-transform: initial !important; }

.cr-channel-form__button-down {
  border: none;
  outline: none;
  width: 19px;
  padding: 0; }
  .cr-channel-form__button-down img {
    max-width: 100%; }

.cr-channel-form__button-icon {
  color: #795afa;
  margin-right: 10px; }

.cr-channel-form__button-down-icon {
  color: #795afa;
  font-size: 29px; }

.cr-channel-form__button-up-icon {
  color: #c2d4e0;
  font-size: 29px; }

.cr-channel-form .form-group label {
  font-style: normal;
  font-weight: normal;
  color: #43535c;
  text-transform: uppercase; }

.cr-channel-form__align-right {
  text-align: right; }

.cr-channel-form__form-field-group {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 60px;
  padding-right: 15px; }

.cr-channel-form__form-field-group-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; }

.cr-channel-form__form-field-group-head-left {
  display: flex;
  flex: 1 1;
  align-items: center; }
  .cr-channel-form__form-field-group-head-left span {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #334856; }

.cr-channel-form__form-field-group-head-right {
  display: flex; }
  .cr-channel-form__form-field-group-head-right .form-group {
    margin-right: 30px;
    margin-bottom: 0; }

.cr-channel-form__section-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #43535c;
  text-transform: uppercase; }

.cr-channel-form__note-text span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #829fb1;
  display: block;
  margin: 10px 0; }

.cr-channel-form .btn-radius {
  margin-right: 10px; }

.cr-channel-form__custom-fields {
  min-height: 100px;
  display: flex;
  padding: 0 40px;
  flex-direction: column; }

.cr-channel-form__buttons {
  padding: 0 40px; }

.cr-channel-form__custom-fields-container {
  padding-top: 44px; }

.cr-channel-form__custom-fields-button {
  margin-top: 35px; }

.cr-channel-form__alert-error-custom-fields {
  color: #f85032;
  margin-bottom: 10px; }

.cr-channel-form__confirmation-modal button {
  max-width: 160px !important;
  text-transform: initial !important; }

.cr-custom-form__form-header {
  border: 1px solid #edf1f6;
  padding: 28px 50px;
  display: flex;
  flex-direction: row;
  position: relative; }

.cr-custom-form__title {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 29px;
  color: #334856;
  margin: 0; }

.cr-custom-form__channel-icon {
  width: 30px;
  display: flex;
  align-items: center;
  margin-right: 24px; }
  .cr-custom-form__channel-icon img {
    max-width: 100%; }

.cr-custom-form__form-content {
  background-color: #f7fbfd; }

.cr-custom-form__form {
  padding-top: 33px;
  margin-bottom: 35px;
  padding-left: 48px;
  padding-right: 33px; }
  .cr-custom-form__form textarea {
    min-height: 174px; }

.cr-custom-form__list-group-item {
  border: 0 !important;
  padding-left: 0;
  padding-right: 0; }

.cr-custom-form__view-label {
  margin-bottom: 18px; }

.cr-custom-form__view-assets-link {
  display: block;
  margin-bottom: 10px; }

.cr-custom-form__fields-view label {
  margin-bottom: 18px; }

.cr-custom-form__creative-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 21px;
  margin-top: 10px; }

.cr-custom-form__list-group-value {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 0.5rem;
  display: inline-block;
  color: #212529; }

.cr-custom-form__container-view {
  margin-top: 35px;
  margin-bottom: 35px;
  padding: 0 50px; }
  .cr-custom-form__container-view .cr-form-builder-fields__demographic-row-label {
    margin-bottom: 8px; }

.cr-custom-form__text-description {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #334856; }

.cr-custom-form__button-collapse-icon {
  margin-top: 4px;
  cursor: pointer;
  display: flex; }

.cr-custom-form__show {
  display: block; }

.cr-custom-form__hide {
  display: none; }

.cr-custom-form__recurrence-container {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.cr-custom-form__btn-recurrence {
  border: 1px solid #795afa;
  box-sizing: border-box;
  border-radius: 24px;
  height: 44px;
  width: 261px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .cr-custom-form__btn-recurrence span {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #795afa;
    text-transform: capitalize; }

.cr-custom-form__head-right {
  display: flex;
  position: absolute;
  right: 20px;
  align-items: center; }

.cr-custom-form__head-status-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #829fb1; }

.cr-custom-form__head-status-value {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #657892;
  margin-left: 5px;
  text-transform: capitalize; }

.cr-custom-form__recurrence-icon {
  margin-right: 10px; }

.cr-custom-form__drop-text-class {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #795afa; }

.cr-custom-form__submit-button-fake {
  position: absolute;
  bottom: 0;
  opacity: 0; }

.cr-custom-form__design-view-fields label {
  color: #829fb1 !important;
  margin-bottom: 15px; }

.cr-custom-form__changes-requested {
  color: #e40061;
  margin-left: 5px; }

.cr-custom-form__head-status {
  margin-right: 39px; }

.cr-custom-form__button-delete-icon {
  margin: 0 30px;
  cursor: pointer;
  display: flex; }

.cr-custom-form__requires-placement {
  margin-bottom: 30px;
  text-transform: capitalize; }

.cr-channel-preview-modal {
  min-width: 1137px; }
  .cr-channel-preview-modal .modal-title {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    text-align: center;
    color: #ffffff;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    width: 100%; }
  .cr-channel-preview-modal .modal-header {
    align-items: center;
    justify-content: center; }
    .cr-channel-preview-modal .modal-header .close {
      display: none; }
  .cr-channel-preview-modal__close {
    position: absolute;
    right: 0;
    cursor: pointer; }
  .cr-channel-preview-modal .cr-custom-form__form {
    margin-bottom: 0; }
    .cr-channel-preview-modal .cr-custom-form__form label {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #43535C;
      text-transform: uppercase; }
  .cr-channel-preview-modal__header {
    background-color: #0f5589;
    border-radius: 0 !important;
    border-top-left-radius: 0px !important; }
  .cr-channel-preview-modal .modal-footer {
    border-top: none;
    background: rgba(194, 212, 224, 0.5);
    border-radius: 6px 6px 0px 0px;
    padding: 27px 0;
    justify-content: flex-start;
    padding-left: 48px;
    border-radius: 0; }
  .cr-channel-preview-modal__close-button {
    background: #795afa;
    border-radius: 24px;
    width: 140px;
    border-color: #795afa; }
    .cr-channel-preview-modal__close-button:hover {
      background-color: #795afa !important;
      border-color: #795afa; }
    .cr-channel-preview-modal__close-button span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #ffffff; }
  .cr-channel-preview-modal .modal-body {
    padding: 0; }
  .cr-channel-preview-modal .cr-textinput__input-wrapper {
    border-radius: 6px; }
    .cr-channel-preview-modal .cr-textinput__input-wrapper .cr-textinput__input-field input {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #111B2B !important;
      border-radius: 6px; }
    .cr-channel-preview-modal .cr-textinput__input-wrapper .cr-textinput__input-field textarea {
      border-radius: 6px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #111B2B; }
    .cr-channel-preview-modal .cr-textinput__input-wrapper .cr-textinput__input-field ::-webkit-input-placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #829FB1 !important;
      text-transform: initial !important; }
    .cr-channel-preview-modal .cr-textinput__input-wrapper .cr-textinput__input-field ::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #829FB1 !important;
      text-transform: initial !important; }
  .cr-channel-preview-modal .react-select__control {
    border-radius: 6px; }
  .cr-channel-preview-modal .cr-form-builder-fields .react-select__placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #829FB1 !important; }
  .cr-channel-preview-modal .react-select__placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #829FB1 !important; }
  .cr-channel-preview-modal .cr-form-builder-fields__assets {
    width: 510px; }
  .cr-channel-preview-modal .cr-custom-form__assets {
    width: 510px; }
  .cr-channel-preview-modal .react-select__single-value {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #111B2B; }
  .cr-channel-preview-modal__right-column .react-select__control {
    width: 510px; }
  .cr-channel-preview-modal .cr-custom-form__right-column .react-select__control {
    width: 510px; }
  .cr-channel-preview-modal .cr-form-builder-fields__dropdown .react-select__control {
    width: 510px; }

.cr-rule-modal__header {
  border-bottom: 1px solid #edf1f6;
  padding-left: 50px; }

.cr-rule-modal .close {
  display: none; }

.cr-rule-modal__title {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 29px;
  color: #334856; }

.cr-rule-modal__body {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px; }

.cr-rule-modal__label-group {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #334856;
  margin-right: 15px; }

.cr-rule-modal__group-input {
  display: flex;
  align-items: center; }
  .cr-rule-modal__group-input .cr-textinput {
    flex: 1 1; }
  .cr-rule-modal__group-input .react-select-container {
    width: 300px; }

.cr-rule-modal__then-show {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #334856;
  margin-left: 15px; }

.cr-rule-modal__subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-rule-modal__list-options {
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 30px; }
  .cr-rule-modal__list-options .cr-checkbox {
    margin-bottom: 15px; }

.cr-rule-modal__rule-condition {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 6px; }

.cr-rule-modal__rule-condition-label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #657894; }

.cr-rule-modal__buttons .btn {
  margin-right: 14px; }
  .cr-rule-modal__buttons .btn:last-child {
    margin-right: 0; }

.cr-flight-schedule-modal .close {
  display: none; }

.cr-flight-schedule-modal__header {
  padding: 18px 1rem;
  padding-left: 48px; }
  .cr-flight-schedule-modal__header .modal-title {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856; }

.cr-flight-schedule-modal__body {
  padding: 37px 48px 51px 48px; }

.cr-flight-schedule-modal__text-description {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: #537a91; }

.cr-flight-schedule-modal__label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #43535c;
  margin-bottom: 10px;
  text-transform: uppercase; }

.cr-flight-schedule-modal__label-fake {
  height: 41px;
  width: 1px; }

.cr-flight-schedule-modal .btn {
  text-transform: uppercase; }

.cr-flight-schedule-modal .text-danger {
  display: block; }

.cr-flight-schedule-modal__form-group {
  margin-bottom: 32px; }

.cr-flight-schedule-modal__buttons .btn {
  height: 44px; }

.cr-flight-schedule-modal__buttons .btn-submit {
  width: 169px;
  margin-right: 12px; }

.cr-flight-schedule-modal__buttons .btn-cancel {
  width: 137px; }

.cr-flight-schedule-modal input,
.cr-flight-schedule-modal .react-select__single-value {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334856; }

.cr-flight-schedule-modal__label-week {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 38px;
  color: #537a91;
  text-transform: lowercase; }

.cr-flight-schedule-modal ::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-flight-schedule-modal ::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-flight-schedule-modal ::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-flight-schedule-modal ::-ms-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-flight-schedule-modal .react-select__placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1 !important; }

.cr-flight-schedule-modal .react-datepicker__day--disabled {
  background-color: #C2D4E080;
  color: #C2D4E0 !important; }

.cr-flight-schedule-modal__tooltip {
  opacity: 1; }
  .cr-flight-schedule-modal__tooltip .rc-tooltip-inner {
    background-color: #fff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 10px 20px;
    max-width: 265px; }
  .cr-flight-schedule-modal__tooltip .rc-tooltip-arrow {
    border-color: transparent; }
    .cr-flight-schedule-modal__tooltip .rc-tooltip-arrow:after {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background: #fff;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      top: -13px;
      left: -5px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); }
    .cr-flight-schedule-modal__tooltip .rc-tooltip-arrow:before {
      content: "";
      display: block;
      width: 30px;
      height: 12px;
      background: #fff;
      position: absolute;
      top: -18px;
      z-index: 9;
      left: -15px; }

.cr-flight-schedule-modal__tooltip-content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #334856; }

.cr-flight-schedule-modal__tooltip-icon {
  cursor: pointer;
  margin-left: 5px; }

.cr-select-team-guide__box-view {
  margin-bottom: 0; }

.cr-select-team-guide__content {
  display: flex; }

.cr-select-team-guide__description {
  display: flex;
  flex-direction: column; }
  .cr-select-team-guide__description span {
    margin-left: 50px;
    font-style: normal;
    font-weight: 800;
    font-size: 23px;
    line-height: 29px;
    color: #0f5589;
    margin-left: 100px;
    max-width: 300px; }

.cr-select-team-guide__arrow-icon {
  width: 154px;
  margin-bottom: -25px; }

.cr-select-team-guide__list {
  list-style: none;
  padding-left: 25px; }
  .cr-select-team-guide__list li {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #334856;
    position: relative;
    padding: 12px 0; }
    .cr-select-team-guide__list li:before {
      content: "";
      border-radius: 50%;
      overflow: hidden;
      display: block;
      width: 23px;
      height: 23px;
      background: #d6e5ee;
      position: absolute;
      left: -35px; }

.cr-select-team-guide__hand {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .cr-select-team-guide__hand img {
    max-width: 100%; }

.cr-select-team-guide__role-item {
  margin: 14px 0; }

.cr-select-team-guide__checked {
  margin-right: 15px; }

.cr-select-team-guide__role-icon-checked {
  width: 23px;
  height: 23px;
  background: #d6e5ee;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px; }

.cr-select-team-guide__role-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334856; }

.cr-select-team-guide__role-info {
  display: flex; }

.cr-select-team-guide__role-left {
  width: 140px;
  display: flex;
  align-items: center;
  flex-direction: row; }

.cr-select-team-guide__role-right {
  width: calc(100% - 140px);
  display: flex;
  flex-wrap: wrap; }

.cr-select-team-guide__assigned-user-name {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334856; }

.cr-select-team-guide__assigned-user {
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  height: 42px;
  cursor: pointer;
  overflow: hidden;
  transition: background 0.1s ease;
  margin: 5px; }
  .cr-select-team-guide__assigned-user:hover {
    background: #fff;
    border-color: #795afa; }
    .cr-select-team-guide__assigned-user:hover .cr-select-team-guide__close-icon {
      display: flex;
      opacity: 1; }

.cr-select-team-guide__close-icon {
  margin-left: 5px;
  opacity: 0;
  display: none;
  transition: all 0.1s ease; }

.cr-select-team-guide__role-info-view .cr-select-team-guide__checked {
  display: none; }

.cr-select-team-guide__role-info-view .cr-select-team-guide__assigned-user:hover {
  border-color: #c2d4e0; }

.cr-select-team-guide__role-info-view .cr-select-team-guide__role-title {
  font-weight: bold; }

.cr-select-team-guide__role-error {
  color: #812a1a !important; }

.cr-digital-order-details__section {
  margin-bottom: 22px !important; }

.cr-digital-order-details__switch-order {
  margin-bottom: 17px; }

.cr-digital-order-details__section-title {
  font-style: normal;
  font-weight: bold !important;
  font-size: 23px !important;
  line-height: 29px;
  color: #334856 !important;
  padding-bottom: 17.45px !important;
  padding-top: 24px !important;
  border-bottom: 1px solid #edf1f6 !important;
  margin-bottom: 0 !important;
  height: 79px; }

.cr-digital-order-details__section-fields {
  padding: 33px 52px 21px 48px !important; }
  .cr-digital-order-details__section-fields.p-top-0 {
    padding-top: 0px !important; }

.cr-digital-order-details .order-view-value {
  margin-bottom: 30px;
  display: inline-block; }

.cr-digital-order-details__col-view-rotation-field label {
  margin-bottom: 18px; }

.cr-digital-order-details__col-field {
  margin-bottom: 22px !important; }
  .cr-digital-order-details__col-field label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #43535c;
    margin-bottom: 8px;
    text-transform: uppercase; }

.cr-digital-order-details__view-field-label {
  color: #829fb1 !important;
  margin-bottom: 18px !important; }

.cr-digital-order-details__buttons {
  justify-content: flex-start !important; }
  .cr-digital-order-details__buttons .btn {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    height: 44px;
    margin-right: 14px !important;
    border-radius: 24px !important; }
  .cr-digital-order-details__buttons .button-submit {
    width: 195px; }
  .cr-digital-order-details__buttons .button-edit {
    width: 195px; }
  .cr-digital-order-details__buttons .button-save-draft {
    width: 189.56px; }
  .cr-digital-order-details__buttons .button-cancel {
    width: 137px; }
  .cr-digital-order-details__buttons .disabled {
    background: #BCCEDB !important;
    color: #fff;
    border-color: #BCCEDB !important;
    opacity: 1; }
  .cr-digital-order-details__buttons span {
    display: block; }

.cr-digital-order-details .cr-handle-assets__file-buttons .btn {
  margin: 0;
  padding: 10.5px !important; }

.cr-digital-order-details__auto-complete {
  margin-bottom: 5px; }
  .cr-digital-order-details__auto-complete input {
    height: 100%; }

.cr-digital-order-details input {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334856; }

.cr-digital-order-details ::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-digital-order-details ::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-digital-order-details ::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-digital-order-details ::-ms-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-digital-order-details .react-select__placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1 !important; }

.cr-digital-order-details .cr-form-builder-fields .cr-textinput__input-field input {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334856;
  text-transform: inherit; }

.cr-digital-order-details .react-select__single-value {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334856; }

.cr-digital-order-details .cr-form-builder-fields .cr-textinput__input-field ::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1;
  text-transform: inherit; }

.cr-digital-order-details .cr-form-builder-fields .cr-textinput__input-field ::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1;
  text-transform: inherit; }

.cr-digital-order-details .cr-form-builder-fields .cr-textinput__input-field ::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1;
  text-transform: inherit; }

.cr-digital-order-details .cr-form-builder-fields .cr-textinput__input-field ::-ms-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1;
  text-transform: inherit; }

.cr-digital-order-details__align-right {
  text-align: right; }

.cr-digital-order-details__view-station td {
  padding: 5px 0; }

.cr-digital-order-details__view-station-name {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #334856;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.cr-digital-order-details__view-station-number {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334856;
  padding-left: 20px !important; }

.cr-digital-order-details__order-view .cr-traffic-details__main-title {
  height: 78px;
  padding-top: 27.91px !important;
  padding-bottom: 18.45px !important; }

.cr-digital-order-details .cr-custom-form__head-right {
  right: 34px; }

.cr-digital-order-details__rotation-table {
  margin-bottom: 14px;
  border: 1px solid #C2D4E0;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  position: relative; }

.cr-digital-order-details__rotation-name {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #334856;
  text-transform: uppercase;
  flex: 1 1;
  padding-left: 18px; }

.cr-digital-order-details__rotation-value {
  flex: 0 0 50%;
  max-width: 50%;
  height: 100%; }
  .cr-digital-order-details__rotation-value input {
    border: none;
    height: 100%;
    width: calc(100% - 55px);
    margin-left: 15px;
    border-left: 1px solid #C2D4E0;
    border-radius: 0; }
    .cr-digital-order-details__rotation-value input:focus, .cr-digital-order-details__rotation-value input:active {
      outline: none;
      box-shadow: none;
      border-color: #C2D4E0; }

.cr-digital-order-details__rotation-number-view {
  display: block;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #334856;
  padding-left: 24px;
  border-left: 1px solid #C2D4E0;
  margin-left: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 48px; }

.cr-digital-order-details__rotations {
  margin-bottom: 16px; }
  .cr-digital-order-details__rotations .cr-digital-order-details__rotation-table:last-child {
    margin-bottom: 0; }

.cr-digital-order-details__delete-order-number {
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 19px; }

.cr-digital-order-details .rotation-value input:focus, .cr-digital-order-details .rotation-value input:active {
  outline: none;
  box-shadow: none; }

.cr-digital-order-details__rotation-container-view {
  border: 1px solid #C2D4E0;
  box-sizing: border-box;
  border-radius: 6px; }
  .cr-digital-order-details__rotation-container-view .cr-digital-order-details__rotation-view-table:last-child {
    border-bottom: none; }

.cr-digital-order-details__rotation-container-view-empty {
  border: none; }

.cr-digital-order-details__rotation-view-table {
  margin-bottom: 0;
  border: none;
  border-bottom: 1px solid #C2D4E0;
  border-radius: 0;
  height: 46px; }

.cr-digital-order-details .cr-select-audience__selected-value {
  color: #000; }

.cr-digital-approval {
  margin-bottom: 20px; }
  .cr-digital-approval__wrapper {
    background: #ffffff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    padding: 44px 66px 13px 66px; }
  .cr-digital-approval__title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #111b2b;
    margin-bottom: 15px; }
  .cr-digital-approval__button-approve {
    margin-right: 10px; }
    .cr-digital-approval__button-approve:hover {
      background-color: #73a703 !important;
      border-color: #73a703 !important; }
      .cr-digital-approval__button-approve:hover span {
        color: #fff !important; }
      .cr-digital-approval__button-approve:hover .cr-digital-approval__button-icon {
        display: block; }
    .cr-digital-approval__button-approve:focus {
      box-shadow: none; }
  .cr-digital-approval__button-approved {
    background-color: #73a703 !important;
    border-color: #73a703 !important; }
    .cr-digital-approval__button-approved span {
      color: #fff !important; }
    .cr-digital-approval__button-approved .cr-digital-approval__button-icon {
      display: block; }
  .cr-digital-approval__button-reject:hover {
    background-color: #e40061 !important;
    border-color: #e40061 !important; }
    .cr-digital-approval__button-reject:hover span {
      color: #fff !important; }
    .cr-digital-approval__button-reject:hover .cr-digital-approval__button-icon {
      display: block; }
  .cr-digital-approval__button-reject:focus {
    box-shadow: none; }
  .cr-digital-approval__button-rejected {
    background-color: #e40061 !important;
    border-color: #e40061 !important; }
    .cr-digital-approval__button-rejected span {
      color: #fff !important; }
    .cr-digital-approval__button-rejected .cr-digital-approval__button-icon {
      display: block; }
  .cr-digital-approval__button-icon {
    display: none;
    margin-right: 10px; }
  .cr-digital-approval__buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center; }
  .cr-digital-approval .btn {
    background: #ffffff;
    border: 1px solid #829fb1;
    border-radius: 25px;
    height: 48px;
    min-width: 130px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    opacity: 1;
    max-width: 230px; }
    .cr-digital-approval .btn span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #334856;
      border-radius: 18px; }
  .cr-digital-approval__completed-title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: #334856;
    margin-bottom: 21px; }
  .cr-digital-approval__list-approvals {
    list-style: none;
    padding: 0;
    margin-bottom: 0; }
    .cr-digital-approval__list-approvals li {
      display: inline-flex;
      align-items: center;
      justify-content: left;
      margin-bottom: 15px; }
    .cr-digital-approval__list-approvals li:nth-child(2n + 1) {
      margin-right: 44px; }
  .cr-digital-approval__checked-icon {
    display: flex;
    align-items: center;
    margin-right: 16px; }
  .cr-digital-approval__uncheck-icon {
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    margin-right: 16px;
    background: #d6e5ef;
    border-radius: 50px; }
  .cr-digital-approval__comment-wrapper .cr-textinput__input-wrapper {
    border-color: #c2d4e0;
    overflow: hidden;
    border-radius: 20px; }
    .cr-digital-approval__comment-wrapper .cr-textinput__input-wrapper textarea {
      resize: none;
      background: #ffffff; }
      .cr-digital-approval__comment-wrapper .cr-textinput__input-wrapper textarea ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #829fb1; }
      .cr-digital-approval__comment-wrapper .cr-textinput__input-wrapper textarea ::-moz-placeholder {
        /* Firefox 19+ */
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #829fb1; }
      .cr-digital-approval__comment-wrapper .cr-textinput__input-wrapper textarea :-ms-input-placeholder {
        /* IE 10+ */
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #829fb1; }
      .cr-digital-approval__comment-wrapper .cr-textinput__input-wrapper textarea :-moz-placeholder {
        /* Firefox 18- */
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #829fb1; }
  .cr-digital-approval__form-comment {
    position: relative; }
  .cr-digital-approval__send-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 53px;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0; }
    .cr-digital-approval__send-button:focus {
      outline: none; }
  .cr-digital-approval__show {
    display: block; }
  .cr-digital-approval__hide {
    display: none; }

.cr-workflow-manager__subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #657894;
  text-transform: uppercase;
  margin-bottom: 0; }

.cr-workflow-manager__button-create {
  margin-top: 36px;
  background: #ffffff !important;
  border-radius: 24px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  border-color: #795afa;
  height: 49px; }
  .cr-workflow-manager__button-create span {
    color: #2d4364;
    font-weight: 800;
    font-size: 20px;
    max-width: calc(100% - 25px);
    margin-left: 17px;
    text-transform: capitalize; }

.cr-workflow-manager__icon-add {
  color: #795afa;
  font-size: 25px; }

.cr-workflow-manager__button-icon {
  color: #795afa;
  margin-right: 10px; }

.cr-workflow-manager__custom-workflows {
  margin-top: 50px; }

.cr-workflow-manager__create-container {
  padding-bottom: 50px; }

.cr-workflow-manager .dropdown-menu {
  background: linear-gradient(0deg, #ffffff, #ffffff), linear-gradient(0deg, #ffffff, #ffffff), #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.28);
  border-radius: 3px;
  padding-bottom: 0;
  padding-top: 0;
  width: 224px; }
  .cr-workflow-manager .dropdown-menu.show {
    top: 6px !important;
    left: 18px !important; }

.cr-workflow-manager__dropdown-text {
  height: 40px;
  display: flex;
  padding-left: 25px;
  cursor: pointer; }
  .cr-workflow-manager__dropdown-text span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 36px;
    color: #334856; }
  .cr-workflow-manager__dropdown-text:hover {
    background: rgba(194, 212, 224, 0.5); }

.cr-workflow-manager__published-title {
  margin-top: 50px; }
  .cr-workflow-manager__published-title span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #334856; }

.cr-workflow-manager__confirmation-modal .modal-title {
  max-width: 270px; }

.cr-workflow-manager-item {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  display: flex;
  flex: 1 1;
  position: relative;
  margin: 20px 0;
  padding: 20px 30px;
  flex-direction: column; }
  .cr-workflow-manager-item__head-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer; }
  .cr-workflow-manager-item__name {
    font-style: normal;
    font-weight: 800;
    font-size: 23px;
    line-height: 29px;
    color: #111b2b;
    margin-bottom: 0;
    text-transform: capitalize; }
  .cr-workflow-manager-item__name-disabled {
    color: #829fb1; }
  .cr-workflow-manager-item__button-down-icon {
    color: #795afa;
    font-size: 29px;
    font-weight: bold;
    cursor: pointer; }
  .cr-workflow-manager-item__button-up-icon {
    color: #795afa;
    font-size: 29px;
    font-weight: bold;
    cursor: pointer; }
  .cr-workflow-manager-item__head-type-text {
    margin-right: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 40px;
    color: #657894; }
  .cr-workflow-manager-item__workflow-form {
    margin-top: 50px; }
    .cr-workflow-manager-item__workflow-form .cr-workflow-manager-item__list {
      margin-top: 50px; }
  .cr-workflow-manager-item__button-wrapper {
    text-align: center; }
  .cr-workflow-manager-item__button-create {
    background: #fff;
    border: 1px solid #795afa;
    box-sizing: border-box;
    border-radius: 24px;
    min-width: 140px;
    width: auto;
    padding-right: 20px;
    padding-left: 20px;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-items: center; }
    .cr-workflow-manager-item__button-create span {
      font-style: normal;
      font-weight: 800;
      font-size: 19px;
      line-height: 24px;
      text-align: center;
      color: #334856;
      text-transform: capitalize; }
  .cr-workflow-manager-item__button-icon {
    color: #795afa;
    margin-right: 10px;
    display: inline-flex; }
  .cr-workflow-manager-item .form-group label {
    text-transform: uppercase !important;
    display: block;
    color: #537a91; }
  .cr-workflow-manager-item__form-buttons {
    margin-top: 50px; }
    .cr-workflow-manager-item__form-buttons .btn-blue {
      margin-right: 7px; }
    .cr-workflow-manager-item__form-buttons .btn-outline-blue {
      margin-left: 7px; }
    .cr-workflow-manager-item__form-buttons .btn {
      text-transform: uppercase;
      margin-right: 14px; }
  .cr-workflow-manager-item__checkbox-wrapper {
    position: absolute;
    right: -105px;
    top: 30px;
    z-index: 99; }
  .cr-workflow-manager-item__approval-list {
    margin-left: 60px; }
  .cr-workflow-manager-item__button-edit-workflow {
    margin-top: 50px; }
    .cr-workflow-manager-item__button-edit-workflow span {
      text-transform: uppercase; }
  .cr-workflow-manager-item__workflow-show {
    display: block; }
  .cr-workflow-manager-item__workflow-hide {
    display: none; }
  .cr-workflow-manager-item__workflow-form-hide {
    margin-top: 0; }
  .cr-workflow-manager-item__btn-right {
    float: right; }

.cr-workflow-role {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  padding: 15px;
  margin: 20px 0;
  display: flex;
  flex-direction: row; }
  .cr-workflow-role__name-section {
    display: flex;
    align-items: center;
    margin-right: 20px;
    min-width: 150px; }
    .cr-workflow-role__name-section img {
      max-width: 100%; }
  .cr-workflow-role__icon {
    width: 40px; }
  .cr-workflow-role__name {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #334856; }
  .cr-workflow-role__start-icon {
    width: 24px; }
  .cr-workflow-role__team-icon {
    width: 29px; }
  .cr-workflow-role__union-icon {
    width: 24px; }
  .cr-workflow-role__continuity-icon {
    width: 24px; }
  .cr-workflow-role__dub-icon {
    width: 26px; }
  .cr-workflow-role__approval-icon {
    width: 24px; }
  .cr-workflow-role__items {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row; }
  .cr-workflow-role__item {
    background: #f7fbfd;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 7px 0;
    margin-right: 12px;
    padding: 10px 20px; }
  .cr-workflow-role__approval-list {
    margin-left: 50px; }

.cr-workflow-manager-approval {
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  padding: 20px 20px 20px 60px;
  position: relative;
  margin-bottom: 20px; }
  .cr-workflow-manager-approval:hover {
    background: #f7fbfd; }
    .cr-workflow-manager-approval:hover .cr-workflow-manager-approval__close-icon {
      opacity: 1; }
  .cr-workflow-manager-approval__approval-icon {
    width: 24px; }
  .cr-workflow-manager-approval__menu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c2d4e0;
    font-size: 29px;
    left: -30px;
    top: -5px;
    position: absolute; }
  .cr-workflow-manager-approval__close-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
    opacity: 0.7; }
  .cr-workflow-manager-approval__head {
    margin-bottom: 20px; }
  .cr-workflow-manager-approval__title {
    display: flex; }
    .cr-workflow-manager-approval__title span {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      margin-left: 17px;
      color: #334856; }
  .cr-workflow-manager-approval__approval-view-col {
    margin-bottom: 0 !important; }
  .cr-workflow-manager-approval__head-view {
    display: flex;
    align-items: center;
    margin-right: 20px;
    min-width: 150px; }
  .cr-workflow-manager-approval__approval-view-container {
    display: flex;
    flex-direction: row; }
  .cr-workflow-manager-approval__approval-view-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row; }
  .cr-workflow-manager-approval__approval-view-item {
    background: #f7fbfd;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 7px 0;
    margin-right: 12px;
    padding: 10px 20px; }
    .cr-workflow-manager-approval__approval-view-item span {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #334856; }

.cr-dropdown__option-container {
  position: relative; }
  .cr-dropdown__option-container .cr-checkbox__text {
    margin-left: 16px; }
  .cr-dropdown__option-container.option-other {
    margin-top: 5px;
    border-top: 1px solid #c2d4e0; }
    .cr-dropdown__option-container.option-other .react-select__option {
      padding-top: 12px;
      padding-bottom: 10; }

.cr-dropdown__remove-option {
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: calc(50% - 11px); }

.time-option-list {
  padding: 0;
  float: left; }
  .time-option-list.All {
    float: none; }

.cr-review-media__buttons {
  margin-top: 30px; }

.cr-review-media .cr-channel-media:last-child {
  margin-bottom: 0; }

.cr-review-media .cr-channel-media__form-header {
  height: 79px;
  padding-top: inherit;
  padding-bottom: inherit;
  display: flex;
  align-items: center; }

.review-media-container > div {
  padding: 0; }

.cr-channel-media {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px; }
  .cr-channel-media__form-header {
    display: flex;
    flex-direction: row;
    position: relative;
    padding-bottom: 28px;
    padding-top: 29px;
    padding-right: 43px;
    padding-left: 31.63px;
    height: 89px; }
  .cr-channel-media__form-content {
    border-top: 1px solid #edf1f6; }
  .cr-channel-media__title {
    font-style: normal;
    font-weight: 800;
    font-size: 23px;
    line-height: 29px;
    margin-bottom: 0;
    color: #111b2b; }
  .cr-channel-media__channel-icon {
    width: 30px;
    display: flex;
    align-items: center;
    margin-right: 14px; }
    .cr-channel-media__channel-icon img {
      max-width: 100%; }
  .cr-channel-media__head-right {
    display: flex;
    position: absolute;
    right: 43px;
    align-items: center; }
  .cr-channel-media__button-down-icon {
    margin-top: 5px; }
  .cr-channel-media__button-up-icon {
    margin-top: 4px; }
  .cr-channel-media__show {
    display: block; }
  .cr-channel-media__hide {
    display: none; }
  .cr-channel-media__assets-approvals {
    margin-right: 31px; }
  .cr-channel-media__assets-approvals-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #829fb1; }
  .cr-channel-media__assets-approvals-value {
    color: #657891;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    margin-left: 10px; }
  .cr-channel-media__content {
    padding-top: 58px;
    padding-right: 45.32px;
    padding-left: 36px;
    padding-bottom: 205.34px; }
  .cr-channel-media__media-name {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #334856;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: initial;
    word-break: break-all; }
  .cr-channel-media__button-approve {
    margin-right: 7px; }
    .cr-channel-media__button-approve:hover {
      background-color: #73a703 !important;
      border-color: #73a703 !important; }
      .cr-channel-media__button-approve:hover span {
        color: #fff !important; }
      .cr-channel-media__button-approve:hover .cr-channel-media__button-icon {
        display: block; }
    .cr-channel-media__button-approve:focus {
      box-shadow: none; }
  .cr-channel-media__button-approved {
    background-color: #73a703 !important;
    border-color: #73a703 !important; }
    .cr-channel-media__button-approved span {
      color: #fff !important; }
    .cr-channel-media__button-approved .cr-channel-media__button-icon {
      display: block; }
  .cr-channel-media__button-reject {
    margin-right: 0 !important;
    margin-left: 7px; }
    .cr-channel-media__button-reject:hover {
      background-color: #e40061 !important;
      border-color: #e40061 !important; }
      .cr-channel-media__button-reject:hover span {
        color: #fff !important; }
      .cr-channel-media__button-reject:hover .cr-channel-media__button-icon {
        display: block; }
    .cr-channel-media__button-reject:focus {
      box-shadow: none; }
  .cr-channel-media__button-rejected {
    background-color: #e40061 !important;
    border-color: #e40061 !important; }
    .cr-channel-media__button-rejected span {
      color: #fff !important; }
    .cr-channel-media__button-rejected .cr-channel-media__button-icon {
      display: block; }
  .cr-channel-media__button-icon {
    display: none;
    margin-right: 10px; }
  .cr-channel-media__buttons {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 60px;
    cursor: pointer;
    position: relative; }
  .cr-channel-media__button-review {
    background: #ffffff;
    border: 1px solid #829fb1;
    border-radius: 25px;
    height: 48px;
    min-width: 130px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    opacity: 1;
    width: 230px !important; }
    .cr-channel-media__button-review span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #334856;
      border-radius: 18px; }
  .cr-channel-media__content .slick-prev,
  .cr-channel-media__content .slick-next {
    top: 10px;
    z-index: 9;
    margin-top: 7px;
    width: 30px;
    height: 33px;
    cursor: pointer; }
    .cr-channel-media__content .slick-prev svg,
    .cr-channel-media__content .slick-next svg {
      cursor: pointer; }
    .cr-channel-media__content .slick-prev::before,
    .cr-channel-media__content .slick-next::before {
      display: none; }
  .cr-channel-media__content .slick-prev {
    left: 0;
    text-align: left; }
  .cr-channel-media__content .slick-next {
    right: 0;
    text-align: right; }
  .cr-channel-media__slide-media-items {
    margin-top: 40px; }
  .cr-channel-media__pdf-container {
    position: relative;
    margin: 0 auto; }
    .cr-channel-media__pdf-container .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px; }
    .cr-channel-media__pdf-container .page-controls {
      background: white;
      display: inline-block;
      margin: 0 auto;
      margin-top: 15px;
      opacity: 1;
      transition: opacity ease-in-out 0.2s;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px; }
    .cr-channel-media__pdf-container .react-pdf__Page {
      max-width: 516px; }
    .cr-channel-media__pdf-container button {
      width: 44px;
      height: 44px;
      background: white;
      border: 0;
      font-size: 0.8em;
      border-radius: 4px;
      cursor: pointer; }
      .cr-channel-media__pdf-container button:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .cr-channel-media__pdf-container button:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      .cr-channel-media__pdf-container button:focus, .cr-channel-media__pdf-container button:active {
        outline: none;
        border: none; }
  .cr-channel-media__slide-media {
    text-align: center; }
  .cr-channel-media .slick-dots {
    bottom: -65px;
    cursor: default; }
    .cr-channel-media .slick-dots li {
      width: 15px;
      height: 15px;
      margin: 0 11px; }
    .cr-channel-media .slick-dots button {
      width: 15px;
      height: 15px;
      background: #c2d4e0;
      border-radius: 50px;
      margin: 0 auto; }
      .cr-channel-media .slick-dots button::before {
        display: none; }
    .cr-channel-media .slick-dots .slick-active button {
      background: #111b2b; }
  .cr-channel-media__media-image {
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 300px;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    margin: 0 auto; }
  .cr-channel-media__pin-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    cursor: pointer; }
  .cr-channel-media__tooltip-confirmation {
    padding: 0;
    opacity: 1 !important;
    z-index: 14 !important; }
    .cr-channel-media__tooltip-confirmation .rc-tooltip-arrow {
      display: none; }
    .cr-channel-media__tooltip-confirmation .rc-tooltip-inner {
      background-color: #fff;
      border: none;
      padding: 0; }
  .cr-channel-media__confirmation-modal .modal-content {
    max-width: 454px;
    max-height: 174px;
    background: #ffffff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    overflow: hidden; }
  .cr-channel-media__confirmation-modal .modal-footer {
    margin: 0;
    padding-bottom: 29px;
    padding-top: 21px; }
  .cr-channel-media__confirmation-modal .cr-confirmation-modal__header {
    margin-top: 33px; }
  .cr-channel-media__confirmation-modal .cr-confirmation-modal__confirm-button {
    width: 114px;
    min-width: auto;
    max-width: auto;
    margin-right: 2px; }
  .cr-channel-media__confirmation-modal .cr-confirmation-modal__cancel-button {
    width: 110px;
    min-width: auto;
    max-width: auto;
    margin-left: 2px; }
  .cr-channel-media__confirmation-content {
    display: flex;
    align-items: center;
    justify-items: center; }
    .cr-channel-media__confirmation-content span {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 23px;
      color: #334856;
      text-align: left; }
  .cr-channel-media__modal-pin-icon {
    margin-right: 17px;
    margin-left: 49px; }
  .cr-channel-media__pin-asset {
    display: inline-block;
    opacity: 0;
    cursor: pointer;
    pointer-events: none;
    position: absolute; }
  .cr-channel-media__pin-show {
    opacity: 1 !important;
    pointer-events: initial !important; }
  .cr-channel-media .asset-number {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px; }
  .cr-channel-media__placed-icon {
    position: absolute; }
    .cr-channel-media__placed-icon img {
      width: 48px;
      max-width: inherit; }
  .cr-channel-media__placed-wrapper {
    position: relative;
    cursor: pointer; }
  .cr-channel-media__placed-number {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    position: absolute;
    top: 4px;
    right: 12px;
    width: 28px;
    height: 28px;
    display: inline-block;
    text-align: center; }
  .cr-channel-media__media-wrapper {
    display: flex !important;
    align-items: center;
    justify-items: center;
    cursor: initial; }
  .cr-channel-media__review-complete {
    text-align: center;
    padding-bottom: 70px; }
  .cr-channel-media__submit-all-feedback {
    width: 257px !important;
    height: 44px; }
    .cr-channel-media__submit-all-feedback:focus {
      box-shadow: none; }
    .cr-channel-media__submit-all-feedback.disabled {
      background: #bccedb !important;
      border-color: #bccedb !important; }
      .cr-channel-media__submit-all-feedback.disabled span {
        font-weight: 800; }
    .cr-channel-media__submit-all-feedback span {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase; }
  .cr-channel-media__review-complete-image {
    width: 207px;
    margin: 0 auto;
    margin-top: 116px;
    margin-bottom: 16px; }
    .cr-channel-media__review-complete-image img {
      max-width: 100%; }
  .cr-channel-media__review-complete-title {
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #000000;
    margin-bottom: 0; }
  .cr-channel-media .cr-channel-media__review-complete-disabled .cr-channel-media__review-complete-image {
    margin-bottom: 24px; }
  .cr-channel-media .cr-channel-media__review-complete-disabled .cr-channel-media__review-complete-image {
    margin-top: 59px; }
  .cr-channel-media__content-review {
    padding-top: 55px;
    padding-bottom: 56px; }
    .cr-channel-media__content-review .slick-dots {
      bottom: -25px !important;
      z-index: 99; }
  .cr-channel-media__tooltip-edit-comment {
    padding: 0;
    opacity: 1; }
    .cr-channel-media__tooltip-edit-comment .rc-tooltip-content {
      position: relative;
      padding: 0;
      background: #ffffff;
      border: 1px solid #c2d4e0;
      box-sizing: border-box;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      margin-top: -10px; }
    .cr-channel-media__tooltip-edit-comment .rc-tooltip-inner {
      background-color: transparent;
      padding: 24px 27px 31px 24px;
      width: 412px; }
    .cr-channel-media__tooltip-edit-comment .rc-tooltip-arrow {
      display: none; }
    .cr-channel-media__tooltip-edit-comment .cr-textinput__input-wrapper {
      background: #ffffff;
      border: 1px solid #c2d4e0;
      box-sizing: border-box;
      border-radius: 30px;
      padding: 20.58px 20px;
      padding-bottom: 40px; }
    .cr-channel-media__tooltip-edit-comment .form-control {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #334856; }
      .cr-channel-media__tooltip-edit-comment .form-control:focus, .cr-channel-media__tooltip-edit-comment .form-control:hover, .cr-channel-media__tooltip-edit-comment .form-control:active {
        border-color: transparent;
        box-shadow: none; }
  .cr-channel-media__view-comment {
    display: flex;
    align-items: center; }
    .cr-channel-media__view-comment .cr-avatar {
      float: none; }
  .cr-channel-media__message-avatar-text {
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #ffffff;
    background: #0f55b9; }
  .cr-channel-media__tooltip-comment {
    padding: 0;
    opacity: 1;
    z-index: 1049; }
    .cr-channel-media__tooltip-comment .rc-tooltip-content {
      position: relative;
      padding: 0;
      background: #ffffff;
      border: 1px solid #c2d4e0;
      box-sizing: border-box;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      margin-top: -10px; }
    .cr-channel-media__tooltip-comment .rc-tooltip-inner {
      background-color: transparent;
      padding: 20px 48px 18px 25px;
      width: 417px;
      cursor: default; }
    .cr-channel-media__tooltip-comment .rc-tooltip-arrow {
      display: none; }
  .cr-channel-media__view-comment-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #334856;
    margin-left: 13px;
    word-break: break-word; }
  .cr-channel-media__view-comment-close {
    position: absolute;
    top: 13px;
    right: 10px;
    cursor: pointer; }
  .cr-channel-media__edit-comment-close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer; }
  .cr-channel-media__tooltip-comment-actions {
    opacity: 1;
    background-color: transparent; }
    .cr-channel-media__tooltip-comment-actions .rc-tooltip-inner {
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      padding: 14.5px 0 12px 0;
      width: 161px; }
    .cr-channel-media__tooltip-comment-actions .rc-tooltip-arrow {
      border-color: transparent;
      top: -1px; }
      .cr-channel-media__tooltip-comment-actions .rc-tooltip-arrow:after {
        content: "";
        position: absolute;
        width: 19px;
        height: 19px;
        background: #fff;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 1px;
        left: -10px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); }
      .cr-channel-media__tooltip-comment-actions .rc-tooltip-arrow:before {
        content: "";
        display: block;
        width: 30px;
        height: 14px;
        background: #fff;
        position: absolute;
        top: 11px;
        z-index: 9;
        left: -15px; }
  .cr-channel-media__comment-button {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: #334856;
    cursor: pointer;
    padding-left: 24px;
    padding-right: 31px; }
    .cr-channel-media__comment-button:hover {
      background: #c2d4e0; }
  .cr-channel-media__comment-more-icon {
    position: absolute;
    right: 13px;
    bottom: 10.5px;
    cursor: pointer;
    font-size: 0; }
  .cr-channel-media__edit-comment-text {
    position: relative; }
  .cr-channel-media__edit-submit {
    position: absolute;
    bottom: 10px;
    right: 12px;
    padding: 0;
    border: none;
    height: 30px;
    width: 30px;
    background-color: #795afa;
    min-width: auto !important;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-channel-media__react-cursor {
    width: 100%;
    height: 100%;
    position: relative; }
  .cr-channel-media .slick-list {
    overflow: inherit;
    z-index: 2; }
  .cr-channel-media__enlarge-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 25px;
    cursor: pointer; }
  .cr-channel-media .slick-slide {
    opacity: 0;
    visibility: hidden; }
  .cr-channel-media__slick-moving {
    overflow: hidden !important; }
  .cr-channel-media .slick-active.slick-current {
    opacity: 1;
    visibility: visible; }
  .cr-channel-media__reject-container {
    position: relative; }
  .cr-channel-media__pin-icon-top {
    position: relative; }
  .cr-channel-media__review-complete-description {
    color: #657894;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 0; }
  .cr-channel-media__button-select {
    min-width: 190px !important;
    margin: 0 10px !important; }
  .cr-channel-media__button-selections h2 {
    color: #111b2b;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px; }
  .cr-channel-media__selections {
    display: flex;
    align-items: center;
    justify-content: center; }
    .cr-channel-media__selections .cr-send-mail-dialog {
      margin: 0 10px; }
    .cr-channel-media__selections .cr-send-mail-dialog__btn-send-mail {
      margin-right: 0 !important;
      min-width: 190px !important;
      text-transform: uppercase; }
  .cr-channel-media__content-who-review {
    padding-top: 26px !important; }
  .cr-channel-media__title-preview {
    font-size: 16px;
    color: #111b2b;
    width: 100%;
    text-align: center; }

.cr-review-asset-modal {
  max-width: 766px; }
  .cr-review-asset-modal__header {
    border: 1px solid #edf1f6;
    height: 66px;
    padding-left: 48px;
    position: relative; }
  .cr-review-asset-modal .modal-title {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856; }
  .cr-review-asset-modal .modal-body {
    padding-top: 37px;
    padding-left: 48px;
    padding-right: 53px;
    padding-bottom: 0; }
  .cr-review-asset-modal .close {
    display: none; }
  .cr-review-asset-modal__close-button {
    position: absolute;
    right: 25px;
    cursor: pointer; }
  .cr-review-asset-modal__footer {
    border-top: 0;
    justify-content: flex-start;
    padding-top: 44px;
    padding-left: 49.41px;
    padding-bottom: 81px; }
  .cr-review-asset-modal__field-title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #657894;
    text-align: center;
    margin-bottom: 17px; }
  .cr-review-asset-modal .btn {
    width: 160px;
    height: 44px; }
    .cr-review-asset-modal .btn span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      text-transform: uppercase; }
  .cr-review-asset-modal__asset-view {
    margin-bottom: 58px;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 300px;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block; }
  .cr-review-asset-modal__pin-asset {
    display: inline-block;
    opacity: 0;
    cursor: move;
    z-index: 999; }
  .cr-review-asset-modal__pin-show {
    opacity: 1 !important; }
  .cr-review-asset-modal .asset-number {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px; }
  .cr-review-asset-modal__placed-icon {
    position: absolute; }
    .cr-review-asset-modal__placed-icon img {
      width: 48px;
      max-width: inherit; }
  .cr-review-asset-modal__placed-wrapper {
    position: relative;
    cursor: pointer; }
  .cr-review-asset-modal__placed-number {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    position: absolute;
    top: 4px;
    right: 12px;
    width: 28px;
    height: 28px;
    display: inline-block;
    text-align: center; }
  .cr-review-asset-modal .cr-textinput__input-wrapper {
    background: #ffffff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 20px; }
    .cr-review-asset-modal .cr-textinput__input-wrapper textarea {
      resize: none;
      height: 134px;
      padding-left: 27px;
      padding-top: 18px;
      border-radius: 20px;
      padding-bottom: 45px; }
    .cr-review-asset-modal .cr-textinput__input-wrapper ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #829fb1; }
    .cr-review-asset-modal .cr-textinput__input-wrapper ::-moz-placeholder {
      /* Firefox 19+ */
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #829fb1; }
    .cr-review-asset-modal .cr-textinput__input-wrapper :-ms-input-placeholder {
      /* IE 10+ */
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #829fb1; }
    .cr-review-asset-modal .cr-textinput__input-wrapper :-moz-placeholder {
      /* Firefox 18- */
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #829fb1; }
  .cr-review-asset-modal__add-pin {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .cr-review-asset-modal__add-pin span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #795afa;
      margin-left: 10px; }
  .cr-review-asset-modal__action-form {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .cr-review-asset-modal__send-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: -12px;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0; }
    .cr-review-asset-modal__send-button:focus {
      outline: none; }
  .cr-review-asset-modal__comment-form {
    position: relative; }
  .cr-review-asset-modal__action-form {
    position: absolute;
    bottom: 19px;
    padding-left: 26px;
    padding-right: 16px;
    width: 100%; }
  .cr-review-asset-modal__content {
    text-align: center; }
  .cr-review-asset-modal__messages {
    margin-bottom: 8px; }
  .cr-review-asset-modal__message-wrapper-left {
    display: flex;
    margin-bottom: 24px; }
  .cr-review-asset-modal__message-wrapper-right {
    display: flex;
    margin-bottom: 24px;
    flex-direction: row-reverse; }
    .cr-review-asset-modal__message-wrapper-right .cr-review-asset-modal__message {
      margin-left: 40px;
      margin-right: 8px; }
    .cr-review-asset-modal__message-wrapper-right .cr-review-asset-modal__reply {
      display: none; }
  .cr-review-asset-modal__message-number {
    width: 25px;
    height: 25px;
    background-color: #0f55b9;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #ffffff;
    display: inline-block;
    border-radius: 50px;
    margin-right: 24px;
    text-align: center; }
  .cr-review-asset-modal__message {
    background: #ffffff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 0px 30px 30px 30px;
    padding: 18px 28px 20px 37px;
    margin-right: 40px;
    margin-left: 9px;
    flex: 1 1;
    text-align: left;
    align-items: center;
    display: flex;
    max-width: 500px;
    position: relative;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #334856; }
  .cr-review-asset-modal__reply {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #795afa;
    display: flex;
    cursor: pointer;
    align-items: center; }
  .cr-review-asset-modal__avatar {
    width: 46px !important;
    height: 46px !important;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #795afa; }
  .cr-review-asset-modal__current-parent {
    border-color: #795afa; }
    .cr-review-asset-modal__current-parent .cr-review-asset-modal__close {
      opacity: 1; }
  .cr-review-asset-modal__close {
    position: absolute;
    right: 20px;
    top: 10px;
    opacity: 0;
    cursor: pointer; }
  .cr-review-asset-modal__messages-wrapper-left {
    margin-right: 110px; }
    .cr-review-asset-modal__messages-wrapper-left .cr-review-asset-modal__message {
      max-width: 400px; }

.cr-design .cr-design__channel-view {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  margin-bottom: 30px; }

.cr-design .cr-design__channel-form {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 20px; }

.cr-design .cr-custom-form__form-header {
  border: none;
  border-bottom: 1px solid #edf1f6;
  height: 79px;
  padding-top: inherit;
  padding-bottom: inherit;
  display: flex;
  align-items: center; }

.cr-design__buttons {
  padding-left: 50px; }
  .cr-design__buttons .btn {
    margin-top: 0; }
    .cr-design__buttons .btn span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      text-transform: uppercase; }
  .cr-design__buttons .button-submit {
    height: 44px;
    width: 168px !important;
    min-width: auto !important; }
  .cr-design__buttons .disabled {
    background: #bccedb !important;
    color: #fff;
    border-color: #bccedb !important;
    opacity: 1; }

.cr-design .cr-handle-assets__file-buttons .btn {
  margin: 0;
  padding: 8.5px !important; }
  .cr-design .cr-handle-assets__file-buttons .btn:last-child {
    padding-right: 0 !important; }

.cr-design .cr-file-view__file-buttons .btn {
  margin: 0;
  padding: 8.5px !important; }
  .cr-design .cr-file-view__file-buttons .btn:last-child {
    padding-right: 0 !important; }

.cr-design .cr-design__channel-form:last-child .cr-custom-form__container-view {
  margin-bottom: 0;
  padding-bottom: 17px; }

.cr-design .cr-custom-form__right-column .form-group {
  margin-bottom: 34px; }

.cr-design .cr-custom-form__container-view .cr-form-builder-fields {
  margin-bottom: 18px; }

.cr-design .cr-custom-form__container-view .form-group {
  margin-bottom: 46px; }

.cr-design .cr-custom-form__container-view .cr-custom-form__fields-design-view .cr-form-builder-fields__form-field-label {
  margin-bottom: 18px !important; }

.cr-design .cr-custom-form__container-view .cr-custom-form__fields-design-view .cr-form-builder-fields {
  margin-bottom: 0; }

.cr-design .cr-custom-form__container-view .cr-custom-form__view-optional-assets-label {
  margin-bottom: 18px; }

.cr-design .cr-custom-form__design-view-fields .cr-form-builder-fields {
  margin-bottom: 18px; }

.cr-design .cr-form-builder-fields__form-group {
  margin-bottom: 0; }

.cr-design .cr-custom-form__form-content {
  background-color: #fff; }

.cr-design .cr-form-builder-fields__assets {
  margin-bottom: 10px; }

.cr-design .cr-custom-form__form {
  margin-bottom: 30px;
  padding-bottom: 15px; }

.design-container > div {
  padding: 0; }
  .design-container > div > .row > .col {
    margin-bottom: 0; }

.design-container-bottom-buttons, .review-media-container-bottom-buttons {
  display: none !important; }

.cr-media__channel-form {
  margin-bottom: 30px; }

.cr-media .cr-media__channel-form:last-child {
  margin-bottom: 0; }

.cr-preview-media-modal {
  min-width: 788px; }
  .cr-preview-media-modal__field-name {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #334856;
    margin-bottom: 30px; }
  .cr-preview-media-modal__download-bottom {
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-preview-media-modal__download {
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    justify-content: center; }
    .cr-preview-media-modal__download span {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 25px;
      color: #ffffff;
      margin-left: 17px; }
  .cr-preview-media-modal .modal-content {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px;
    border: none; }
  .cr-preview-media-modal__asset-item-container {
    text-align: center; }
  .cr-preview-media-modal__asset-item {
    max-width: 80%;
    margin: 0 auto; }
    .cr-preview-media-modal__asset-item img {
      max-width: 100%;
      margin: 0 auto; }
  .cr-preview-media-modal .slick-dots {
    position: static;
    margin-top: 58px; }
    .cr-preview-media-modal .slick-dots li {
      margin: 0 12.5px;
      width: 15px;
      height: 15px; }
    .cr-preview-media-modal .slick-dots button {
      width: 15px;
      height: 15px;
      background-color: #c2d4e0;
      border-radius: 24px;
      padding: 0;
      margin: 0 auto; }
      .cr-preview-media-modal .slick-dots button::before {
        display: none; }
    .cr-preview-media-modal .slick-dots .slick-active button {
      background-color: #334856; }
  .cr-preview-media-modal .slick-arrow {
    z-index: 99; }
    .cr-preview-media-modal .slick-arrow::before {
      display: none; }
  .cr-preview-media-modal .slick-prev {
    left: 0;
    top: 10px; }
  .cr-preview-media-modal .slick-next {
    right: 0;
    top: 10px; }
  .cr-preview-media-modal .modal-body {
    padding-left: 36.32px;
    padding-right: 44.58px;
    padding-top: 74px;
    padding-bottom: 87px;
    position: relative;
    height: 983px; }
  .cr-preview-media-modal__close-icon {
    position: absolute;
    top: 18px;
    right: 16px;
    cursor: pointer; }

.cr-media-channel {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px; }
  .cr-media-channel__form-header {
    padding: 0 54px 0 51.27px;
    height: 80px;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    cursor: pointer; }
  .cr-media-channel__title {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856;
    margin: 0; }
  .cr-media-channel__channel-icon {
    width: 30px;
    display: flex;
    align-items: center;
    margin-right: 30px; }
    .cr-media-channel__channel-icon img {
      max-width: 100%; }
  .cr-media-channel__head-right {
    display: flex;
    position: absolute;
    right: 54px;
    align-items: center; }
  .cr-media-channel__head-status {
    margin-right: 45px; }
  .cr-media-channel__head-status-label {
    color: #829FB1;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px; }
  .cr-media-channel__head-status-value {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #829FB1;
    margin-left: 10px; }
  .cr-media-channel__button-down-icon {
    margin-top: 5px; }
  .cr-media-channel__button-up-icon {
    margin-top: 5px; }
  .cr-media-channel__show {
    display: block; }
  .cr-media-channel__hide {
    display: none; }
  .cr-media-channel__instructions-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #657894;
    display: block;
    text-transform: uppercase;
    margin-bottom: 20px; }
  .cr-media-channel__instructions-description {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #111b2b;
    display: block;
    flex: 1 1; }
  .cr-media-channel__media-view {
    border-top: 1px solid #edf1f6; }
  .cr-media-channel__field-name {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    height: 33px;
    margin-bottom: 0;
    color: #829fb1; }
  .cr-media-channel__masonry-item {
    margin-bottom: 20px; }
    .cr-media-channel__masonry-item img {
      max-width: 100%; }
  .cr-media-channel__asset {
    position: relative;
    cursor: pointer; }
    .cr-media-channel__asset::after {
      content: "";
      display: none;
      background-color: rgba(0, 0, 0, 0.65);
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      z-index: 1; }
    .cr-media-channel__asset:hover:after {
      display: block; }
    .cr-media-channel__asset:hover .cr-media-channel__search-icon {
      display: block; }
  .cr-media-channel__search-icon {
    position: absolute;
    top: calc((100% - 42px) / 2);
    left: calc((100% - 35px) / 2);
    z-index: 2;
    display: none; }
  .cr-media-channel__head-review {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #6c08ce;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 1px solid rgba(194, 212, 224, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    width: 133px;
    height: 33px;
    text-transform: uppercase; }
  .cr-media-channel__download-all {
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #795afa; }
  .cr-media-channel__instructions {
    padding: 34px 54px 44px 55px;
    border-bottom: 1px solid #edf1f6; }
  .cr-media-channel__instructions-actions {
    display: flex;
    align-items: center;
    justify-content: center; }
    .cr-media-channel__instructions-actions .btn-outline-blue {
      width: 150px !important;
      height: 48px;
      margin-left: 20px; }
  .cr-media-channel__trafficked-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 20px; }
  .cr-media-channel__trafficked-at {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #657894;
    margin-bottom: 5px; }
  .cr-media-channel__btn-trafficked {
    width: 162px !important;
    height: 48px;
    background-color: #73a703;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .cr-media-channel__btn-trafficked span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      border-radius: 18px; }
  .cr-media-channel__undo-link {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #795afa;
    text-transform: uppercase;
    cursor: pointer; }
  .cr-media-channel__custom-form-view .cr-form-builder-fields__form-field-group-saved label {
    margin-bottom: 18px; }
  .cr-media-channel__manager-actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 65px;
    margin-left: 12px; }
    .cr-media-channel__manager-actions .btn-outline-blue {
      height: 48px; }
  .cr-media-channel__trafficked-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 20px; }
  .cr-media-channel__trafficked-at {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #657894;
    margin-bottom: 5px; }
  .cr-media-channel__btn-trafficked {
    width: 162px !important;
    height: 48px;
    background-color: #73a703;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .cr-media-channel__btn-trafficked span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      border-radius: 18px; }
  .cr-media-channel__undo-link {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #795afa;
    text-transform: uppercase;
    cursor: pointer; }

.form-edit-mode .cr-media-channel__instructions-text {
  color: #43535C; }

.cr-review-comment {
  background: #ffffff;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  width: 412px;
  height: 151px;
  padding: 29px 27px 28px 24px;
  position: absolute;
  z-index: 999;
  right: calc(100% - 225px); }
  .cr-review-comment .cr-textinput__input-wrapper {
    border: none; }
  .cr-review-comment .cr-textinput > div {
    margin-bottom: 0 !important; }
  .cr-review-comment textarea {
    background: #ffffff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 20px;
    height: 94px;
    padding: 18.6px 23.16px;
    min-height: 94px; }
  .cr-review-comment .form-control {
    resize: none; }
    .cr-review-comment .form-control::-webkit-input-placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #829fb1; }
    .cr-review-comment .form-control::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #829fb1; }
  .cr-review-comment__close {
    position: absolute;
    top: 13px;
    right: 10px;
    cursor: pointer; }
  .cr-review-comment__submit {
    display: inline-block;
    position: absolute;
    bottom: 21px;
    right: 28px;
    cursor: pointer;
    min-width: auto !important;
    padding: 0 !important;
    height: auto !important;
    background-color: transparent; }

.cr-roles-manager__role-item-wrapper {
  margin-bottom: 16px; }
  .cr-roles-manager__role-item-wrapper form {
    display: flex;
    justify-content: space-between; }

.cr-roles-manager .cr-toggle-switch {
  margin-top: 30px; }

.cr-roles-manager__role-item-inactive .cr-roles-manager__role-title,
.cr-roles-manager__role-item-inactive .cr-roles-manager__product-list,
.cr-roles-manager__role-item-inactive .cr-roles-manager__total-users {
  color: #829fb1; }

.cr-roles-manager__content-wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  flex: 1 1;
  margin-right: 30px; }

.cr-roles-manager__role-head {
  padding-left: 20px;
  padding-right: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 88px; }

.cr-roles-manager__role-title {
  font-weight: 800;
  font-size: 23px;
  color: #111b2b;
  margin-bottom: 0;
  margin-left: 20px;
  display: flex;
  align-items: center; }

.cr-roles-manager__section-main-info {
  display: flex;
  align-items: center; }

.cr-roles-manager .menu-selection-icon {
  cursor: move; }

.cr-roles-manager__btn-clone {
  display: flex;
  align-items: center;
  cursor: pointer; }
  .cr-roles-manager__btn-clone span {
    color: #795afa;
    font-size: 14px;
    margin-left: 10px; }

.cr-roles-manager__section-main-action {
  display: flex;
  align-items: center;
  justify-content: center; }

.cr-roles-manager__button-down {
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  margin-left: 39px;
  box-shadow: none; }
  .cr-roles-manager__button-down:active, .cr-roles-manager__button-down:focus, .cr-roles-manager__button-down:focus-visible {
    outline: none; }

.cr-roles-manager__role-content {
  border-top: 1px solid #edf1f6;
  padding: 33px 60px; }

.cr-roles-manager__section-container {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  margin-bottom: 16px; }

.cr-roles-manager__hide {
  display: none; }

.cr-roles-manager__section-title {
  color: #111b2b;
  font-weight: 800;
  font-size: 23px;
  height: 88px;
  padding-left: 40px;
  padding-right: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.cr-roles-manager__total {
  font-size: 23px;
  color: #657894;
  margin-left: 15px;
  font-weight: 400; }

.cr-roles-manager__section-icon {
  cursor: pointer; }

.cr-roles-manager .cursor {
  cursor: pointer; }

.cr-roles-manager__content-view {
  padding: 40px; }
  .cr-roles-manager__content-view .cr-checkbox__text {
    color: #537a91;
    margin-left: 20px; }

.cr-roles-manager__sub-group-title {
  color: #334856;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 25px; }

.cr-roles-manager__p-left-0 {
  padding-left: 0; }

.cr-roles-manager__form-buttons {
  margin-top: 48px; }
  .cr-roles-manager__form-buttons .btn-blue {
    margin-right: 14px;
    width: 151px; }
  .cr-roles-manager__form-buttons .btn-outline-blue {
    width: 137px; }

.cr-roles-manager__button-right {
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.cr-roles-manager__active-text {
  font-weight: 700;
  text-align: right;
  color: #334856;
  padding-right: 14px; }

.cr-roles-manager__section-head {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  padding: 36px 40px 38px 40px; }
  .cr-roles-manager__section-head label {
    color: #43535c;
    text-transform: uppercase; }

.cr-roles-manager__page-title {
  font-weight: 800;
  font-size: 23px;
  color: #111b2b;
  margin-bottom: 20px; }

.cr-roles-manager .cr-search-box {
  max-width: 482px;
  margin-left: auto;
  margin-top: 30px; }

.cr-roles-manager .cr-select-tags {
  max-width: 553px; }
  .cr-roles-manager .cr-select-tags .react-select__menu {
    width: 100%; }
  .cr-roles-manager .cr-select-tags .react-select__menu-list,
  .cr-roles-manager .cr-select-tags .react-select__option {
    max-width: 100%; }
  .cr-roles-manager .cr-select-tags .react-select__option {
    border: none; }

.cr-roles-manager__default-text {
  color: #829fb1;
  font-size: 14px;
  font-style: italic;
  margin-right: 50px;
  text-transform: lowercase; }

.cr-roles-manager__total-users {
  color: #795afa;
  font-size: 23px;
  font-weight: 400;
  margin-left: 10px;
  cursor: pointer; }

.cr-roles-manager__product-list {
  color: #657894;
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  margin-left: 10px; }

.cr-roles-manager__section-users {
  position: relative;
  display: inline-block; }

.cr-roles-manager__users {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 337px;
  position: absolute;
  right: calc((100% - 337px) / 2);
  bottom: calc(100% - -15px);
  padding-bottom: 20px;
  z-index: 11; }
  .cr-roles-manager__users::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 27px solid white;
    position: absolute;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    bottom: -17px;
    right: calc((100% - 40px) / 2); }

.cr-roles-manager__users-title {
  font-weight: 800;
  font-size: 20px;
  color: #111b2b; }

.cr-roles-manager__user-link {
  visibility: hidden;
  margin-left: 10px;
  margin-top: 5px; }

.cr-roles-manager__users-title {
  font-weight: 800;
  font-size: 20px;
  color: #111b2b;
  padding: 20px 30px; }

.cr-roles-manager__user-item {
  padding: 5px 30px;
  cursor: pointer;
  display: block;
  color: #334856;
  font-weight: 400;
  font-size: 16px; }
  .cr-roles-manager__user-item:hover .cr-roles-manager__user-link {
    visibility: visible; }

.cr-roles-manager .form-group label {
  text-transform: uppercase;
  color: #43535c; }

.cr-roles-manager__clone-text {
  color: #657894;
  font-style: italic;
  margin-top: 35px;
  margin-left: auto;
  text-align: right; }

.cr-roles-manager__role-name {
  margin-bottom: 32px; }

.cr-roles-manager__nav-link.nav-link {
  font-style: normal;
  line-height: 20px;
  color: #657894 !important;
  padding: 12px 6px !important;
  border: none !important;
  border-radius: 6px 6px 0px 0px;
  width: 167px;
  justify-content: center;
  font-size: 16px !important; }
  .cr-roles-manager__nav-link.nav-link.active {
    font-weight: 700 !important; }

.cr-roles-manager__nav-item.nav-item {
  margin-bottom: -4px !important; }
  .cr-roles-manager__nav-item.nav-item.active {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px 6px 0px 0px; }

.cr-roles-manager__tab-pane-separator {
  height: 17px;
  position: absolute;
  width: 86%;
  z-index: 10;
  background: #fff; }

.cr-roles-manager__no-roles-found {
  display: block;
  background: #fff;
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  text-align: center; }

.cr-roles-manager__tab-container {
  margin-top: 35px; }

.cr-roles-manager__column {
  margin: 0px; }

.cr-roles-manager .cr-checkbox .cr-checkbox__text {
  text-transform: capitalize; }

.cr-flight-schedule-modal .close {
  display: none; }

.cr-flight-schedule-modal__header {
  padding: 18px 1rem;
  padding-left: 48px; }
  .cr-flight-schedule-modal__header .modal-title {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856; }

.cr-flight-schedule-modal__body {
  padding: 37px 48px 51px 48px; }

.cr-flight-schedule-modal__text-description {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: #537a91; }

.cr-flight-schedule-modal__label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #43535c;
  margin-bottom: 10px;
  text-transform: uppercase; }

.cr-flight-schedule-modal__label-fake {
  height: 41px;
  width: 1px; }

.cr-flight-schedule-modal .btn {
  text-transform: uppercase; }

.cr-flight-schedule-modal .text-danger {
  display: block; }

.cr-flight-schedule-modal__form-group {
  margin-bottom: 32px; }

.cr-flight-schedule-modal__buttons .btn {
  height: 44px; }

.cr-flight-schedule-modal__buttons .btn-submit {
  width: 169px;
  margin-right: 12px; }

.cr-flight-schedule-modal__buttons .btn-cancel {
  width: 137px; }

.cr-flight-schedule-modal input,
.cr-flight-schedule-modal .react-select__single-value {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334856; }

.cr-flight-schedule-modal__label-week {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 38px;
  color: #537a91;
  text-transform: lowercase; }

.cr-flight-schedule-modal ::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-flight-schedule-modal ::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-flight-schedule-modal ::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-flight-schedule-modal ::-ms-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-flight-schedule-modal .react-select__placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1 !important; }

.cr-flight-schedule-modal .react-datepicker__day--disabled {
  background-color: #C2D4E080;
  color: #C2D4E0 !important; }

.cr-flight-schedule-modal__tooltip {
  opacity: 1; }
  .cr-flight-schedule-modal__tooltip .rc-tooltip-inner {
    background-color: #fff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 10px 20px;
    max-width: 265px; }
  .cr-flight-schedule-modal__tooltip .rc-tooltip-arrow {
    border-color: transparent; }
    .cr-flight-schedule-modal__tooltip .rc-tooltip-arrow:after {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background: #fff;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      top: -13px;
      left: -5px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); }
    .cr-flight-schedule-modal__tooltip .rc-tooltip-arrow:before {
      content: "";
      display: block;
      width: 30px;
      height: 12px;
      background: #fff;
      position: absolute;
      top: -18px;
      z-index: 9;
      left: -15px; }

.cr-flight-schedule-modal__tooltip-content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #334856; }

.cr-flight-schedule-modal__tooltip-icon {
  cursor: pointer;
  margin-left: 5px; }

.cr-digital-order-details__section {
  margin-bottom: 22px !important; }

.cr-digital-order-details__switch-order {
  margin-bottom: 17px; }

.cr-digital-order-details__section-title {
  font-style: normal;
  font-weight: bold !important;
  font-size: 23px !important;
  line-height: 29px;
  color: #334856 !important;
  padding-bottom: 17.45px !important;
  padding-top: 24px !important;
  border-bottom: 1px solid #edf1f6 !important;
  margin-bottom: 0 !important;
  height: 79px; }

.cr-digital-order-details__section-fields {
  padding: 33px 52px 21px 48px !important; }
  .cr-digital-order-details__section-fields.p-top-0 {
    padding-top: 0px !important; }

.cr-digital-order-details .order-view-value {
  margin-bottom: 30px;
  display: inline-block; }

.cr-digital-order-details__col-view-rotation-field label {
  margin-bottom: 18px; }

.cr-digital-order-details__col-field {
  margin-bottom: 22px !important; }
  .cr-digital-order-details__col-field label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #43535c;
    margin-bottom: 8px;
    text-transform: uppercase; }

.cr-digital-order-details__view-field-label {
  color: #829fb1 !important;
  margin-bottom: 18px !important; }

.cr-digital-order-details__buttons {
  justify-content: flex-start !important; }
  .cr-digital-order-details__buttons .btn {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    height: 44px;
    margin-right: 14px !important;
    border-radius: 24px !important; }
  .cr-digital-order-details__buttons .button-submit {
    width: 195px; }
  .cr-digital-order-details__buttons .button-edit {
    width: 195px; }
  .cr-digital-order-details__buttons .button-save-draft {
    width: 189.56px; }
  .cr-digital-order-details__buttons .button-cancel {
    width: 137px; }
  .cr-digital-order-details__buttons .disabled {
    background: #BCCEDB !important;
    color: #fff;
    border-color: #BCCEDB !important;
    opacity: 1; }
  .cr-digital-order-details__buttons span {
    display: block; }

.cr-digital-order-details .cr-handle-assets__file-buttons .btn {
  margin: 0;
  padding: 10.5px !important; }

.cr-digital-order-details__auto-complete {
  margin-bottom: 5px; }
  .cr-digital-order-details__auto-complete input {
    height: 100%; }

.cr-digital-order-details input {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334856; }

.cr-digital-order-details ::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-digital-order-details ::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-digital-order-details ::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-digital-order-details ::-ms-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1; }

.cr-digital-order-details .react-select__placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1 !important; }

.cr-digital-order-details .cr-form-builder-fields .cr-textinput__input-field input {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334856;
  text-transform: inherit; }

.cr-digital-order-details .react-select__single-value {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334856; }

.cr-digital-order-details .cr-form-builder-fields .cr-textinput__input-field ::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1;
  text-transform: inherit; }

.cr-digital-order-details .cr-form-builder-fields .cr-textinput__input-field ::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1;
  text-transform: inherit; }

.cr-digital-order-details .cr-form-builder-fields .cr-textinput__input-field ::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1;
  text-transform: inherit; }

.cr-digital-order-details .cr-form-builder-fields .cr-textinput__input-field ::-ms-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #829fb1;
  text-transform: inherit; }

.cr-digital-order-details__align-right {
  text-align: right; }

.cr-digital-order-details__view-station td {
  padding: 5px 0; }

.cr-digital-order-details__view-station-name {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #334856;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.cr-digital-order-details__view-station-number {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334856;
  padding-left: 20px !important; }

.cr-digital-order-details__order-view .cr-traffic-details__main-title {
  height: 78px;
  padding-top: 27.91px !important;
  padding-bottom: 18.45px !important; }

.cr-digital-order-details .cr-custom-form__head-right {
  right: 34px; }

.cr-digital-order-details__rotation-table {
  margin-bottom: 14px;
  border: 1px solid #C2D4E0;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  position: relative; }

.cr-digital-order-details__rotation-name {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #334856;
  text-transform: uppercase;
  flex: 1 1;
  padding-left: 18px; }

.cr-digital-order-details__rotation-value {
  flex: 0 0 50%;
  max-width: 50%;
  height: 100%; }
  .cr-digital-order-details__rotation-value input {
    border: none;
    height: 100%;
    width: calc(100% - 55px);
    margin-left: 15px;
    border-left: 1px solid #C2D4E0;
    border-radius: 0; }
    .cr-digital-order-details__rotation-value input:focus, .cr-digital-order-details__rotation-value input:active {
      outline: none;
      box-shadow: none;
      border-color: #C2D4E0; }

.cr-digital-order-details__rotation-number-view {
  display: block;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #334856;
  padding-left: 24px;
  border-left: 1px solid #C2D4E0;
  margin-left: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 48px; }

.cr-digital-order-details__rotations {
  margin-bottom: 16px; }
  .cr-digital-order-details__rotations .cr-digital-order-details__rotation-table:last-child {
    margin-bottom: 0; }

.cr-digital-order-details__delete-order-number {
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 19px; }

.cr-digital-order-details .rotation-value input:focus, .cr-digital-order-details .rotation-value input:active {
  outline: none;
  box-shadow: none; }

.cr-digital-order-details__rotation-container-view {
  border: 1px solid #C2D4E0;
  box-sizing: border-box;
  border-radius: 6px; }
  .cr-digital-order-details__rotation-container-view .cr-digital-order-details__rotation-view-table:last-child {
    border-bottom: none; }

.cr-digital-order-details__rotation-container-view-empty {
  border: none; }

.cr-digital-order-details__rotation-view-table {
  margin-bottom: 0;
  border: none;
  border-bottom: 1px solid #C2D4E0;
  border-radius: 0;
  height: 46px; }

.cr-digital-order-details .cr-select-audience__selected-value {
  color: #000; }

.cr-digital-approval {
  margin-bottom: 20px; }
  .cr-digital-approval__wrapper {
    background: #ffffff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    padding: 44px 66px 13px 66px; }
  .cr-digital-approval__title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #111b2b;
    margin-bottom: 15px; }
  .cr-digital-approval__button-approve {
    margin-right: 10px; }
    .cr-digital-approval__button-approve:hover {
      background-color: #73a703 !important;
      border-color: #73a703 !important; }
      .cr-digital-approval__button-approve:hover span {
        color: #fff !important; }
      .cr-digital-approval__button-approve:hover .cr-digital-approval__button-icon {
        display: block; }
    .cr-digital-approval__button-approve:focus {
      box-shadow: none; }
  .cr-digital-approval__button-approved {
    background-color: #73a703 !important;
    border-color: #73a703 !important; }
    .cr-digital-approval__button-approved span {
      color: #fff !important; }
    .cr-digital-approval__button-approved .cr-digital-approval__button-icon {
      display: block; }
  .cr-digital-approval__button-reject:hover {
    background-color: #e40061 !important;
    border-color: #e40061 !important; }
    .cr-digital-approval__button-reject:hover span {
      color: #fff !important; }
    .cr-digital-approval__button-reject:hover .cr-digital-approval__button-icon {
      display: block; }
  .cr-digital-approval__button-reject:focus {
    box-shadow: none; }
  .cr-digital-approval__button-rejected {
    background-color: #e40061 !important;
    border-color: #e40061 !important; }
    .cr-digital-approval__button-rejected span {
      color: #fff !important; }
    .cr-digital-approval__button-rejected .cr-digital-approval__button-icon {
      display: block; }
  .cr-digital-approval__button-icon {
    display: none;
    margin-right: 10px; }
  .cr-digital-approval__buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center; }
  .cr-digital-approval .btn {
    background: #ffffff;
    border: 1px solid #829fb1;
    border-radius: 25px;
    height: 48px;
    min-width: 130px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    opacity: 1;
    max-width: 230px; }
    .cr-digital-approval .btn span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #334856;
      border-radius: 18px; }
  .cr-digital-approval__completed-title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: #334856;
    margin-bottom: 21px; }
  .cr-digital-approval__list-approvals {
    list-style: none;
    padding: 0;
    margin-bottom: 0; }
    .cr-digital-approval__list-approvals li {
      display: inline-flex;
      align-items: center;
      justify-content: left;
      margin-bottom: 15px; }
    .cr-digital-approval__list-approvals li:nth-child(2n + 1) {
      margin-right: 44px; }
  .cr-digital-approval__checked-icon {
    display: flex;
    align-items: center;
    margin-right: 16px; }
  .cr-digital-approval__uncheck-icon {
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    margin-right: 16px;
    background: #d6e5ef;
    border-radius: 50px; }
  .cr-digital-approval__comment-wrapper .cr-textinput__input-wrapper {
    border-color: #c2d4e0;
    overflow: hidden;
    border-radius: 20px; }
    .cr-digital-approval__comment-wrapper .cr-textinput__input-wrapper textarea {
      resize: none;
      background: #ffffff; }
      .cr-digital-approval__comment-wrapper .cr-textinput__input-wrapper textarea ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #829fb1; }
      .cr-digital-approval__comment-wrapper .cr-textinput__input-wrapper textarea ::-moz-placeholder {
        /* Firefox 19+ */
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #829fb1; }
      .cr-digital-approval__comment-wrapper .cr-textinput__input-wrapper textarea :-ms-input-placeholder {
        /* IE 10+ */
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #829fb1; }
      .cr-digital-approval__comment-wrapper .cr-textinput__input-wrapper textarea :-moz-placeholder {
        /* Firefox 18- */
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #829fb1; }
  .cr-digital-approval__form-comment {
    position: relative; }
  .cr-digital-approval__send-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 53px;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0; }
    .cr-digital-approval__send-button:focus {
      outline: none; }
  .cr-digital-approval__show {
    display: block; }
  .cr-digital-approval__hide {
    display: none; }

.cr-review-media__buttons {
  margin-top: 30px; }

.cr-review-media .cr-channel-media:last-child {
  margin-bottom: 0; }

.cr-review-media .cr-channel-media__form-header {
  height: 79px;
  padding-top: inherit;
  padding-bottom: inherit;
  display: flex;
  align-items: center; }

.review-media-container > div {
  padding: 0; }

.cr-channel-media {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px; }
  .cr-channel-media__form-header {
    display: flex;
    flex-direction: row;
    position: relative;
    padding-bottom: 28px;
    padding-top: 29px;
    padding-right: 43px;
    padding-left: 31.63px;
    height: 89px; }
  .cr-channel-media__form-content {
    border-top: 1px solid #edf1f6; }
  .cr-channel-media__title {
    font-style: normal;
    font-weight: 800;
    font-size: 23px;
    line-height: 29px;
    margin-bottom: 0;
    color: #111b2b; }
  .cr-channel-media__channel-icon {
    width: 30px;
    display: flex;
    align-items: center;
    margin-right: 14px; }
    .cr-channel-media__channel-icon img {
      max-width: 100%; }
  .cr-channel-media__head-right {
    display: flex;
    position: absolute;
    right: 43px;
    align-items: center; }
  .cr-channel-media__button-down-icon {
    margin-top: 5px; }
  .cr-channel-media__button-up-icon {
    margin-top: 4px; }
  .cr-channel-media__show {
    display: block; }
  .cr-channel-media__hide {
    display: none; }
  .cr-channel-media__assets-approvals {
    margin-right: 31px; }
  .cr-channel-media__assets-approvals-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #829fb1; }
  .cr-channel-media__assets-approvals-value {
    color: #657891;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    margin-left: 10px; }
  .cr-channel-media__content {
    padding-top: 58px;
    padding-right: 45.32px;
    padding-left: 36px;
    padding-bottom: 205.34px; }
  .cr-channel-media__media-name {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #334856;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: initial;
    word-break: break-all; }
  .cr-channel-media__button-approve {
    margin-right: 7px; }
    .cr-channel-media__button-approve:hover {
      background-color: #73a703 !important;
      border-color: #73a703 !important; }
      .cr-channel-media__button-approve:hover span {
        color: #fff !important; }
      .cr-channel-media__button-approve:hover .cr-channel-media__button-icon {
        display: block; }
    .cr-channel-media__button-approve:focus {
      box-shadow: none; }
  .cr-channel-media__button-approved {
    background-color: #73a703 !important;
    border-color: #73a703 !important; }
    .cr-channel-media__button-approved span {
      color: #fff !important; }
    .cr-channel-media__button-approved .cr-channel-media__button-icon {
      display: block; }
  .cr-channel-media__button-reject {
    margin-right: 0 !important;
    margin-left: 7px; }
    .cr-channel-media__button-reject:hover {
      background-color: #e40061 !important;
      border-color: #e40061 !important; }
      .cr-channel-media__button-reject:hover span {
        color: #fff !important; }
      .cr-channel-media__button-reject:hover .cr-channel-media__button-icon {
        display: block; }
    .cr-channel-media__button-reject:focus {
      box-shadow: none; }
  .cr-channel-media__button-rejected {
    background-color: #e40061 !important;
    border-color: #e40061 !important; }
    .cr-channel-media__button-rejected span {
      color: #fff !important; }
    .cr-channel-media__button-rejected .cr-channel-media__button-icon {
      display: block; }
  .cr-channel-media__button-icon {
    display: none;
    margin-right: 10px; }
  .cr-channel-media__buttons {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 60px;
    cursor: pointer;
    position: relative; }
  .cr-channel-media__button-review {
    background: #ffffff;
    border: 1px solid #829fb1;
    border-radius: 25px;
    height: 48px;
    min-width: 130px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    opacity: 1;
    width: 230px !important; }
    .cr-channel-media__button-review span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #334856;
      border-radius: 18px; }
  .cr-channel-media__content .slick-prev,
  .cr-channel-media__content .slick-next {
    top: 10px;
    z-index: 9;
    margin-top: 7px;
    width: 30px;
    height: 33px;
    cursor: pointer; }
    .cr-channel-media__content .slick-prev svg,
    .cr-channel-media__content .slick-next svg {
      cursor: pointer; }
    .cr-channel-media__content .slick-prev::before,
    .cr-channel-media__content .slick-next::before {
      display: none; }
  .cr-channel-media__content .slick-prev {
    left: 0;
    text-align: left; }
  .cr-channel-media__content .slick-next {
    right: 0;
    text-align: right; }
  .cr-channel-media__slide-media-items {
    margin-top: 40px; }
  .cr-channel-media__pdf-container {
    position: relative;
    margin: 0 auto; }
    .cr-channel-media__pdf-container .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px; }
    .cr-channel-media__pdf-container .page-controls {
      background: white;
      display: inline-block;
      margin: 0 auto;
      margin-top: 15px;
      opacity: 1;
      transition: opacity ease-in-out 0.2s;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px; }
    .cr-channel-media__pdf-container .react-pdf__Page {
      max-width: 516px; }
    .cr-channel-media__pdf-container button {
      width: 44px;
      height: 44px;
      background: white;
      border: 0;
      font-size: 0.8em;
      border-radius: 4px;
      cursor: pointer; }
      .cr-channel-media__pdf-container button:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .cr-channel-media__pdf-container button:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      .cr-channel-media__pdf-container button:focus, .cr-channel-media__pdf-container button:active {
        outline: none;
        border: none; }
  .cr-channel-media__slide-media {
    text-align: center; }
  .cr-channel-media .slick-dots {
    bottom: -65px;
    cursor: default; }
    .cr-channel-media .slick-dots li {
      width: 15px;
      height: 15px;
      margin: 0 11px; }
    .cr-channel-media .slick-dots button {
      width: 15px;
      height: 15px;
      background: #c2d4e0;
      border-radius: 50px;
      margin: 0 auto; }
      .cr-channel-media .slick-dots button::before {
        display: none; }
    .cr-channel-media .slick-dots .slick-active button {
      background: #111b2b; }
  .cr-channel-media__media-image {
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 300px;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    margin: 0 auto; }
  .cr-channel-media__pin-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    cursor: pointer; }
  .cr-channel-media__tooltip-confirmation {
    padding: 0;
    opacity: 1 !important;
    z-index: 14 !important; }
    .cr-channel-media__tooltip-confirmation .rc-tooltip-arrow {
      display: none; }
    .cr-channel-media__tooltip-confirmation .rc-tooltip-inner {
      background-color: #fff;
      border: none;
      padding: 0; }
  .cr-channel-media__confirmation-modal .modal-content {
    max-width: 454px;
    max-height: 174px;
    background: #ffffff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    overflow: hidden; }
  .cr-channel-media__confirmation-modal .modal-footer {
    margin: 0;
    padding-bottom: 29px;
    padding-top: 21px; }
  .cr-channel-media__confirmation-modal .cr-confirmation-modal__header {
    margin-top: 33px; }
  .cr-channel-media__confirmation-modal .cr-confirmation-modal__confirm-button {
    width: 114px;
    min-width: auto;
    max-width: auto;
    margin-right: 2px; }
  .cr-channel-media__confirmation-modal .cr-confirmation-modal__cancel-button {
    width: 110px;
    min-width: auto;
    max-width: auto;
    margin-left: 2px; }
  .cr-channel-media__confirmation-content {
    display: flex;
    align-items: center;
    justify-items: center; }
    .cr-channel-media__confirmation-content span {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 23px;
      color: #334856;
      text-align: left; }
  .cr-channel-media__modal-pin-icon {
    margin-right: 17px;
    margin-left: 49px; }
  .cr-channel-media__pin-asset {
    display: inline-block;
    opacity: 0;
    cursor: pointer;
    pointer-events: none;
    position: absolute; }
  .cr-channel-media__pin-show {
    opacity: 1 !important;
    pointer-events: initial !important; }
  .cr-channel-media .asset-number {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px; }
  .cr-channel-media__placed-icon {
    position: absolute; }
    .cr-channel-media__placed-icon img {
      width: 48px;
      max-width: inherit; }
  .cr-channel-media__placed-wrapper {
    position: relative;
    cursor: pointer; }
  .cr-channel-media__placed-number {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    position: absolute;
    top: 4px;
    right: 12px;
    width: 28px;
    height: 28px;
    display: inline-block;
    text-align: center; }
  .cr-channel-media__media-wrapper {
    display: flex !important;
    align-items: center;
    justify-items: center;
    cursor: initial; }
  .cr-channel-media__review-complete {
    text-align: center;
    padding-bottom: 70px; }
  .cr-channel-media__submit-all-feedback {
    width: 257px !important;
    height: 44px; }
    .cr-channel-media__submit-all-feedback:focus {
      box-shadow: none; }
    .cr-channel-media__submit-all-feedback.disabled {
      background: #bccedb !important;
      border-color: #bccedb !important; }
      .cr-channel-media__submit-all-feedback.disabled span {
        font-weight: 800; }
    .cr-channel-media__submit-all-feedback span {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase; }
  .cr-channel-media__review-complete-image {
    width: 207px;
    margin: 0 auto;
    margin-top: 116px;
    margin-bottom: 16px; }
    .cr-channel-media__review-complete-image img {
      max-width: 100%; }
  .cr-channel-media__review-complete-title {
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #000000;
    margin-bottom: 0; }
  .cr-channel-media .cr-channel-media__review-complete-disabled .cr-channel-media__review-complete-image {
    margin-bottom: 24px; }
  .cr-channel-media .cr-channel-media__review-complete-disabled .cr-channel-media__review-complete-image {
    margin-top: 59px; }
  .cr-channel-media__content-review {
    padding-top: 55px;
    padding-bottom: 56px; }
    .cr-channel-media__content-review .slick-dots {
      bottom: -25px !important;
      z-index: 99; }
  .cr-channel-media__tooltip-edit-comment {
    padding: 0;
    opacity: 1; }
    .cr-channel-media__tooltip-edit-comment .rc-tooltip-content {
      position: relative;
      padding: 0;
      background: #ffffff;
      border: 1px solid #c2d4e0;
      box-sizing: border-box;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      margin-top: -10px; }
    .cr-channel-media__tooltip-edit-comment .rc-tooltip-inner {
      background-color: transparent;
      padding: 24px 27px 31px 24px;
      width: 412px; }
    .cr-channel-media__tooltip-edit-comment .rc-tooltip-arrow {
      display: none; }
    .cr-channel-media__tooltip-edit-comment .cr-textinput__input-wrapper {
      background: #ffffff;
      border: 1px solid #c2d4e0;
      box-sizing: border-box;
      border-radius: 30px;
      padding: 20.58px 20px;
      padding-bottom: 40px; }
    .cr-channel-media__tooltip-edit-comment .form-control {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #334856; }
      .cr-channel-media__tooltip-edit-comment .form-control:focus, .cr-channel-media__tooltip-edit-comment .form-control:hover, .cr-channel-media__tooltip-edit-comment .form-control:active {
        border-color: transparent;
        box-shadow: none; }
  .cr-channel-media__view-comment {
    display: flex;
    align-items: center; }
    .cr-channel-media__view-comment .cr-avatar {
      float: none; }
  .cr-channel-media__message-avatar-text {
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #ffffff;
    background: #0f55b9; }
  .cr-channel-media__tooltip-comment {
    padding: 0;
    opacity: 1;
    z-index: 1049; }
    .cr-channel-media__tooltip-comment .rc-tooltip-content {
      position: relative;
      padding: 0;
      background: #ffffff;
      border: 1px solid #c2d4e0;
      box-sizing: border-box;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      margin-top: -10px; }
    .cr-channel-media__tooltip-comment .rc-tooltip-inner {
      background-color: transparent;
      padding: 20px 48px 18px 25px;
      width: 417px;
      cursor: default; }
    .cr-channel-media__tooltip-comment .rc-tooltip-arrow {
      display: none; }
  .cr-channel-media__view-comment-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #334856;
    margin-left: 13px;
    word-break: break-word; }
  .cr-channel-media__view-comment-close {
    position: absolute;
    top: 13px;
    right: 10px;
    cursor: pointer; }
  .cr-channel-media__edit-comment-close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer; }
  .cr-channel-media__tooltip-comment-actions {
    opacity: 1;
    background-color: transparent; }
    .cr-channel-media__tooltip-comment-actions .rc-tooltip-inner {
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      padding: 14.5px 0 12px 0;
      width: 161px; }
    .cr-channel-media__tooltip-comment-actions .rc-tooltip-arrow {
      border-color: transparent;
      top: -1px; }
      .cr-channel-media__tooltip-comment-actions .rc-tooltip-arrow:after {
        content: "";
        position: absolute;
        width: 19px;
        height: 19px;
        background: #fff;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 1px;
        left: -10px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); }
      .cr-channel-media__tooltip-comment-actions .rc-tooltip-arrow:before {
        content: "";
        display: block;
        width: 30px;
        height: 14px;
        background: #fff;
        position: absolute;
        top: 11px;
        z-index: 9;
        left: -15px; }
  .cr-channel-media__comment-button {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: #334856;
    cursor: pointer;
    padding-left: 24px;
    padding-right: 31px; }
    .cr-channel-media__comment-button:hover {
      background: #c2d4e0; }
  .cr-channel-media__comment-more-icon {
    position: absolute;
    right: 13px;
    bottom: 10.5px;
    cursor: pointer;
    font-size: 0; }
  .cr-channel-media__edit-comment-text {
    position: relative; }
  .cr-channel-media__edit-submit {
    position: absolute;
    bottom: 10px;
    right: 12px;
    padding: 0;
    border: none;
    height: 30px;
    width: 30px;
    background-color: #795afa;
    min-width: auto !important;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-channel-media__react-cursor {
    width: 100%;
    height: 100%;
    position: relative; }
  .cr-channel-media .slick-list {
    overflow: inherit;
    z-index: 2; }
  .cr-channel-media__enlarge-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 25px;
    cursor: pointer; }
  .cr-channel-media .slick-slide {
    opacity: 0;
    visibility: hidden; }
  .cr-channel-media__slick-moving {
    overflow: hidden !important; }
  .cr-channel-media .slick-active.slick-current {
    opacity: 1;
    visibility: visible; }
  .cr-channel-media__reject-container {
    position: relative; }
  .cr-channel-media__pin-icon-top {
    position: relative; }
  .cr-channel-media__review-complete-description {
    color: #657894;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 0; }
  .cr-channel-media__button-select {
    min-width: 190px !important;
    margin: 0 10px !important; }
  .cr-channel-media__button-selections h2 {
    color: #111b2b;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px; }
  .cr-channel-media__selections {
    display: flex;
    align-items: center;
    justify-content: center; }
    .cr-channel-media__selections .cr-send-mail-dialog {
      margin: 0 10px; }
    .cr-channel-media__selections .cr-send-mail-dialog__btn-send-mail {
      margin-right: 0 !important;
      min-width: 190px !important;
      text-transform: uppercase; }
  .cr-channel-media__content-who-review {
    padding-top: 26px !important; }
  .cr-channel-media__title-preview {
    font-size: 16px;
    color: #111b2b;
    width: 100%;
    text-align: center; }

.cr-review-asset-modal {
  max-width: 766px; }
  .cr-review-asset-modal__header {
    border: 1px solid #edf1f6;
    height: 66px;
    padding-left: 48px;
    position: relative; }
  .cr-review-asset-modal .modal-title {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856; }
  .cr-review-asset-modal .modal-body {
    padding-top: 37px;
    padding-left: 48px;
    padding-right: 53px;
    padding-bottom: 0; }
  .cr-review-asset-modal .close {
    display: none; }
  .cr-review-asset-modal__close-button {
    position: absolute;
    right: 25px;
    cursor: pointer; }
  .cr-review-asset-modal__footer {
    border-top: 0;
    justify-content: flex-start;
    padding-top: 44px;
    padding-left: 49.41px;
    padding-bottom: 81px; }
  .cr-review-asset-modal__field-title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #657894;
    text-align: center;
    margin-bottom: 17px; }
  .cr-review-asset-modal .btn {
    width: 160px;
    height: 44px; }
    .cr-review-asset-modal .btn span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      text-transform: uppercase; }
  .cr-review-asset-modal__asset-view {
    margin-bottom: 58px;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 300px;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block; }
  .cr-review-asset-modal__pin-asset {
    display: inline-block;
    opacity: 0;
    cursor: move;
    z-index: 999; }
  .cr-review-asset-modal__pin-show {
    opacity: 1 !important; }
  .cr-review-asset-modal .asset-number {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px; }
  .cr-review-asset-modal__placed-icon {
    position: absolute; }
    .cr-review-asset-modal__placed-icon img {
      width: 48px;
      max-width: inherit; }
  .cr-review-asset-modal__placed-wrapper {
    position: relative;
    cursor: pointer; }
  .cr-review-asset-modal__placed-number {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    position: absolute;
    top: 4px;
    right: 12px;
    width: 28px;
    height: 28px;
    display: inline-block;
    text-align: center; }
  .cr-review-asset-modal .cr-textinput__input-wrapper {
    background: #ffffff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 20px; }
    .cr-review-asset-modal .cr-textinput__input-wrapper textarea {
      resize: none;
      height: 134px;
      padding-left: 27px;
      padding-top: 18px;
      border-radius: 20px;
      padding-bottom: 45px; }
    .cr-review-asset-modal .cr-textinput__input-wrapper ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #829fb1; }
    .cr-review-asset-modal .cr-textinput__input-wrapper ::-moz-placeholder {
      /* Firefox 19+ */
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #829fb1; }
    .cr-review-asset-modal .cr-textinput__input-wrapper :-ms-input-placeholder {
      /* IE 10+ */
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #829fb1; }
    .cr-review-asset-modal .cr-textinput__input-wrapper :-moz-placeholder {
      /* Firefox 18- */
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #829fb1; }
  .cr-review-asset-modal__add-pin {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .cr-review-asset-modal__add-pin span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #795afa;
      margin-left: 10px; }
  .cr-review-asset-modal__action-form {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .cr-review-asset-modal__send-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: -12px;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0; }
    .cr-review-asset-modal__send-button:focus {
      outline: none; }
  .cr-review-asset-modal__comment-form {
    position: relative; }
  .cr-review-asset-modal__action-form {
    position: absolute;
    bottom: 19px;
    padding-left: 26px;
    padding-right: 16px;
    width: 100%; }
  .cr-review-asset-modal__content {
    text-align: center; }
  .cr-review-asset-modal__messages {
    margin-bottom: 8px; }
  .cr-review-asset-modal__message-wrapper-left {
    display: flex;
    margin-bottom: 24px; }
  .cr-review-asset-modal__message-wrapper-right {
    display: flex;
    margin-bottom: 24px;
    flex-direction: row-reverse; }
    .cr-review-asset-modal__message-wrapper-right .cr-review-asset-modal__message {
      margin-left: 40px;
      margin-right: 8px; }
    .cr-review-asset-modal__message-wrapper-right .cr-review-asset-modal__reply {
      display: none; }
  .cr-review-asset-modal__message-number {
    width: 25px;
    height: 25px;
    background-color: #0f55b9;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #ffffff;
    display: inline-block;
    border-radius: 50px;
    margin-right: 24px;
    text-align: center; }
  .cr-review-asset-modal__message {
    background: #ffffff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 0px 30px 30px 30px;
    padding: 18px 28px 20px 37px;
    margin-right: 40px;
    margin-left: 9px;
    flex: 1 1;
    text-align: left;
    align-items: center;
    display: flex;
    max-width: 500px;
    position: relative;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #334856; }
  .cr-review-asset-modal__reply {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #795afa;
    display: flex;
    cursor: pointer;
    align-items: center; }
  .cr-review-asset-modal__avatar {
    width: 46px !important;
    height: 46px !important;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #795afa; }
  .cr-review-asset-modal__current-parent {
    border-color: #795afa; }
    .cr-review-asset-modal__current-parent .cr-review-asset-modal__close {
      opacity: 1; }
  .cr-review-asset-modal__close {
    position: absolute;
    right: 20px;
    top: 10px;
    opacity: 0;
    cursor: pointer; }
  .cr-review-asset-modal__messages-wrapper-left {
    margin-right: 110px; }
    .cr-review-asset-modal__messages-wrapper-left .cr-review-asset-modal__message {
      max-width: 400px; }

.cr-design .cr-design__channel-view {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  margin-bottom: 30px; }

.cr-design .cr-design__channel-form {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 20px; }

.cr-design .cr-custom-form__form-header {
  border: none;
  border-bottom: 1px solid #edf1f6;
  height: 79px;
  padding-top: inherit;
  padding-bottom: inherit;
  display: flex;
  align-items: center; }

.cr-design__buttons {
  padding-left: 50px; }
  .cr-design__buttons .btn {
    margin-top: 0; }
    .cr-design__buttons .btn span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      text-transform: uppercase; }
  .cr-design__buttons .button-submit {
    height: 44px;
    width: 168px !important;
    min-width: auto !important; }
  .cr-design__buttons .disabled {
    background: #bccedb !important;
    color: #fff;
    border-color: #bccedb !important;
    opacity: 1; }

.cr-design .cr-handle-assets__file-buttons .btn {
  margin: 0;
  padding: 8.5px !important; }
  .cr-design .cr-handle-assets__file-buttons .btn:last-child {
    padding-right: 0 !important; }

.cr-design .cr-file-view__file-buttons .btn {
  margin: 0;
  padding: 8.5px !important; }
  .cr-design .cr-file-view__file-buttons .btn:last-child {
    padding-right: 0 !important; }

.cr-design .cr-design__channel-form:last-child .cr-custom-form__container-view {
  margin-bottom: 0;
  padding-bottom: 17px; }

.cr-design .cr-custom-form__right-column .form-group {
  margin-bottom: 34px; }

.cr-design .cr-custom-form__container-view .cr-form-builder-fields {
  margin-bottom: 18px; }

.cr-design .cr-custom-form__container-view .form-group {
  margin-bottom: 46px; }

.cr-design .cr-custom-form__container-view .cr-custom-form__fields-design-view .cr-form-builder-fields__form-field-label {
  margin-bottom: 18px !important; }

.cr-design .cr-custom-form__container-view .cr-custom-form__fields-design-view .cr-form-builder-fields {
  margin-bottom: 0; }

.cr-design .cr-custom-form__container-view .cr-custom-form__view-optional-assets-label {
  margin-bottom: 18px; }

.cr-design .cr-custom-form__design-view-fields .cr-form-builder-fields {
  margin-bottom: 18px; }

.cr-design .cr-form-builder-fields__form-group {
  margin-bottom: 0; }

.cr-design .cr-custom-form__form-content {
  background-color: #fff; }

.cr-design .cr-form-builder-fields__assets {
  margin-bottom: 10px; }

.cr-design .cr-custom-form__form {
  margin-bottom: 30px;
  padding-bottom: 15px; }

.design-container > div {
  padding: 0; }
  .design-container > div > .row > .col {
    margin-bottom: 0; }

.design-container-bottom-buttons, .review-media-container-bottom-buttons {
  display: none !important; }

.cr-media__channel-form {
  margin-bottom: 30px; }

.cr-media .cr-media__channel-form:last-child {
  margin-bottom: 0; }

.cr-preview-media-modal {
  min-width: 788px; }
  .cr-preview-media-modal__field-name {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #334856;
    margin-bottom: 30px; }
  .cr-preview-media-modal__download-bottom {
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-preview-media-modal__download {
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    justify-content: center; }
    .cr-preview-media-modal__download span {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 25px;
      color: #ffffff;
      margin-left: 17px; }
  .cr-preview-media-modal .modal-content {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px;
    border: none; }
  .cr-preview-media-modal__asset-item-container {
    text-align: center; }
  .cr-preview-media-modal__asset-item {
    max-width: 80%;
    margin: 0 auto; }
    .cr-preview-media-modal__asset-item img {
      max-width: 100%;
      margin: 0 auto; }
  .cr-preview-media-modal .slick-dots {
    position: static;
    margin-top: 58px; }
    .cr-preview-media-modal .slick-dots li {
      margin: 0 12.5px;
      width: 15px;
      height: 15px; }
    .cr-preview-media-modal .slick-dots button {
      width: 15px;
      height: 15px;
      background-color: #c2d4e0;
      border-radius: 24px;
      padding: 0;
      margin: 0 auto; }
      .cr-preview-media-modal .slick-dots button::before {
        display: none; }
    .cr-preview-media-modal .slick-dots .slick-active button {
      background-color: #334856; }
  .cr-preview-media-modal .slick-arrow {
    z-index: 99; }
    .cr-preview-media-modal .slick-arrow::before {
      display: none; }
  .cr-preview-media-modal .slick-prev {
    left: 0;
    top: 10px; }
  .cr-preview-media-modal .slick-next {
    right: 0;
    top: 10px; }
  .cr-preview-media-modal .modal-body {
    padding-left: 36.32px;
    padding-right: 44.58px;
    padding-top: 74px;
    padding-bottom: 87px;
    position: relative;
    height: 983px; }
  .cr-preview-media-modal__close-icon {
    position: absolute;
    top: 18px;
    right: 16px;
    cursor: pointer; }

.cr-media-channel {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px; }
  .cr-media-channel__form-header {
    padding: 0 54px 0 51.27px;
    height: 80px;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    cursor: pointer; }
  .cr-media-channel__title {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856;
    margin: 0; }
  .cr-media-channel__channel-icon {
    width: 30px;
    display: flex;
    align-items: center;
    margin-right: 30px; }
    .cr-media-channel__channel-icon img {
      max-width: 100%; }
  .cr-media-channel__head-right {
    display: flex;
    position: absolute;
    right: 54px;
    align-items: center; }
  .cr-media-channel__head-status {
    margin-right: 45px; }
  .cr-media-channel__head-status-label {
    color: #829FB1;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px; }
  .cr-media-channel__head-status-value {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #829FB1;
    margin-left: 10px; }
  .cr-media-channel__button-down-icon {
    margin-top: 5px; }
  .cr-media-channel__button-up-icon {
    margin-top: 5px; }
  .cr-media-channel__show {
    display: block; }
  .cr-media-channel__hide {
    display: none; }
  .cr-media-channel__instructions-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #657894;
    display: block;
    text-transform: uppercase;
    margin-bottom: 20px; }
  .cr-media-channel__instructions-description {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #111b2b;
    display: block;
    flex: 1 1; }
  .cr-media-channel__media-view {
    border-top: 1px solid #edf1f6; }
  .cr-media-channel__field-name {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    height: 33px;
    margin-bottom: 0;
    color: #829fb1; }
  .cr-media-channel__masonry-item {
    margin-bottom: 20px; }
    .cr-media-channel__masonry-item img {
      max-width: 100%; }
  .cr-media-channel__asset {
    position: relative;
    cursor: pointer; }
    .cr-media-channel__asset::after {
      content: "";
      display: none;
      background-color: rgba(0, 0, 0, 0.65);
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      z-index: 1; }
    .cr-media-channel__asset:hover:after {
      display: block; }
    .cr-media-channel__asset:hover .cr-media-channel__search-icon {
      display: block; }
  .cr-media-channel__search-icon {
    position: absolute;
    top: calc((100% - 42px) / 2);
    left: calc((100% - 35px) / 2);
    z-index: 2;
    display: none; }
  .cr-media-channel__head-review {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #6c08ce;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 1px solid rgba(194, 212, 224, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    width: 133px;
    height: 33px;
    text-transform: uppercase; }
  .cr-media-channel__download-all {
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #795afa; }
  .cr-media-channel__instructions {
    padding: 34px 54px 44px 55px;
    border-bottom: 1px solid #edf1f6; }
  .cr-media-channel__instructions-actions {
    display: flex;
    align-items: center;
    justify-content: center; }
    .cr-media-channel__instructions-actions .btn-outline-blue {
      width: 150px !important;
      height: 48px;
      margin-left: 20px; }
  .cr-media-channel__trafficked-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 20px; }
  .cr-media-channel__trafficked-at {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #657894;
    margin-bottom: 5px; }
  .cr-media-channel__btn-trafficked {
    width: 162px !important;
    height: 48px;
    background-color: #73a703;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .cr-media-channel__btn-trafficked span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      border-radius: 18px; }
  .cr-media-channel__undo-link {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #795afa;
    text-transform: uppercase;
    cursor: pointer; }
  .cr-media-channel__custom-form-view .cr-form-builder-fields__form-field-group-saved label {
    margin-bottom: 18px; }
  .cr-media-channel__manager-actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 65px;
    margin-left: 12px; }
    .cr-media-channel__manager-actions .btn-outline-blue {
      height: 48px; }
  .cr-media-channel__trafficked-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 20px; }
  .cr-media-channel__trafficked-at {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #657894;
    margin-bottom: 5px; }
  .cr-media-channel__btn-trafficked {
    width: 162px !important;
    height: 48px;
    background-color: #73a703;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .cr-media-channel__btn-trafficked span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      border-radius: 18px; }
  .cr-media-channel__undo-link {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #795afa;
    text-transform: uppercase;
    cursor: pointer; }

.form-edit-mode .cr-media-channel__instructions-text {
  color: #43535C; }

.cr-review-comment {
  background: #ffffff;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  width: 412px;
  height: 151px;
  padding: 29px 27px 28px 24px;
  position: absolute;
  z-index: 999;
  right: calc(100% - 225px); }
  .cr-review-comment .cr-textinput__input-wrapper {
    border: none; }
  .cr-review-comment .cr-textinput > div {
    margin-bottom: 0 !important; }
  .cr-review-comment textarea {
    background: #ffffff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 20px;
    height: 94px;
    padding: 18.6px 23.16px;
    min-height: 94px; }
  .cr-review-comment .form-control {
    resize: none; }
    .cr-review-comment .form-control::-webkit-input-placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #829fb1; }
    .cr-review-comment .form-control::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #829fb1; }
  .cr-review-comment__close {
    position: absolute;
    top: 13px;
    right: 10px;
    cursor: pointer; }
  .cr-review-comment__submit {
    display: inline-block;
    position: absolute;
    bottom: 21px;
    right: 28px;
    cursor: pointer;
    min-width: auto !important;
    padding: 0 !important;
    height: auto !important;
    background-color: transparent; }

.cr-voice-tracks__vo-track-empty {
  height: 50px;
  background-color: #657894;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 370px;
  margin-bottom: 14px; }
  .cr-voice-tracks__vo-track-empty span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    text-transform: uppercase; }

.cr-voice-tracks__vo-name {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #657894;
  margin-bottom: 11px; }

.cr-voice-tracks__checkbox-producer {
  padding: 15px 0;
  display: flex;
  align-items: center;
  margin-left: 22px; }
  .cr-voice-tracks__checkbox-producer .cr-checkbox__text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000000; }

.cr-voice-tracks .cr-handle-assets__file-buttons .btn {
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin: 0; }

.cr-voice-tracks__other-viewing-assets .cr-file-view__file-name {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #334856; }

.cr-voice-tracks__other-viewing-assets .files-wrapper {
  width: 100%; }

.cr-voice-tracks__other-viewing-assets .player-wrapper,
.cr-voice-tracks__other-viewing-assets .player-container {
  margin-bottom: 0; }

.cr-voice-tracks__full-width .drop-area {
  width: 534px;
  margin-bottom: 7px; }

.cr-voice-tracks__min-width .drop-area {
  margin-bottom: 7px;
  width: 370px; }

.cr-voice-tracks__voice-track-item {
  margin-bottom: 23px; }
  .cr-voice-tracks__voice-track-item:last-child {
    margin-bottom: 0; }

.cr-voice-tracks__col-reset {
  margin-bottom: 0; }

.cr-voice-tracks__viewing-assets .upload-file .col-12 {
  margin-bottom: 0; }

.cr-voice-tracks__viewing-assets .file-value {
  word-break: break-all;
  margin-top: 0; }

.cr-voice-tracks__upload-record .upload-view-file .file-value {
  margin-top: 0; }

.cr-voice-tracks__upload-record .upload-view-file .player-container {
  margin-bottom: 4px; }

.cr-voice-tracks__upload-record .upload-view-file .player-wrapper {
  margin-bottom: 0; }

.cr-voice-tracks__upload-record-rejected {
  margin-top: 10px; }

.cr-voice-tracks .upload-file .delete-icon {
  top: calc(50% - 5px); }

.cr-voice-tracks__over-instructions {
  color: #000000;
  font-size: 16px; }

.cr-voice-tracks__assign-vo-name {
  display: block;
  color: #000000;
  line-height: 150%; }

.cr-voice-tracks__col-field {
  margin-bottom: 30px !important; }

@media screen and (min-width: 1200px) and (max-width: 1436px) {
  .cr-voice-tracks__voice-track-item-content .col-7 {
    flex: 0 0 100%;
    max-width: 100%; }
  .cr-voice-tracks__voice-track-item-content .col-5 {
    flex: 0 0 100%;
    max-width: 100%; }
  .cr-voice-tracks__voice-track-item-content .cr-voice-tracks__checkbox-producer {
    margin-left: 0; } }

@media screen and (max-width: 1065px) {
  .cr-voice-tracks__voice-track-item-content .col-7 {
    flex: 0 0 100%;
    max-width: 100%; }
  .cr-voice-tracks__voice-track-item-content .col-5 {
    flex: 0 0 100%;
    max-width: 100%; }
  .cr-voice-tracks__voice-track-item-content .cr-voice-tracks__checkbox-producer {
    margin-left: 0; } }

.cr-voice-tracks__empty-producer-to-voice {
  font-size: 16px;
  line-height: 36px;
  color: #000000; }

.cr-voice-tracks__review-comments {
  margin-top: 16px;
  position: relative; }
  .cr-voice-tracks__review-comments .cr-chatbox {
    max-width: 100%; }

.cr-voice-tracks__review-description {
  font-size: 14px;
  color: #334856; }

.cr-voice-tracks__change-requested {
  font-size: 12px;
  color: #e40061;
  position: absolute;
  top: -33px;
  right: 0;
  text-transform: capitalize; }

.cr-preview-asset-modal {
  max-width: 766px; }
  .cr-preview-asset-modal__header.modal-header {
    padding: 0 48px;
    border-bottom-color: #edf1f6;
    height: 73px; }
  .cr-preview-asset-modal__header .close {
    display: none; }
  .cr-preview-asset-modal .modal-title {
    display: flex;
    align-items: center;
    height: 100%; }
  .cr-preview-asset-modal__close {
    position: absolute;
    cursor: pointer;
    right: 15px;
    top: 16px;
    z-index: 9; }
    .cr-preview-asset-modal__close:focus {
      outline: none; }
    .cr-preview-asset-modal__close:active {
      outline: none; }
  .cr-preview-asset-modal .modal-body {
    padding-top: 37px;
    padding-left: 48px;
    padding-right: 53px;
    padding-bottom: 0; }
  .cr-preview-asset-modal .close {
    display: none; }
  .cr-preview-asset-modal__file-title {
    font-weight: 700;
    font-size: 23px;
    color: #334856; }
  .cr-preview-asset-modal__field-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #334856;
    margin-bottom: 46px; }
  .cr-preview-asset-modal__asset-view {
    margin-bottom: 58px;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 300px;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block; }
  .cr-preview-asset-modal__pin-asset {
    display: inline-block;
    opacity: 0;
    cursor: move;
    z-index: 999; }
  .cr-preview-asset-modal__pin-show {
    opacity: 1 !important; }
  .cr-preview-asset-modal .asset-number {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px; }
  .cr-preview-asset-modal__placed-icon {
    position: absolute; }
    .cr-preview-asset-modal__placed-icon img {
      width: 48px;
      max-width: inherit; }
  .cr-preview-asset-modal__placed-wrapper {
    position: relative;
    cursor: pointer; }
  .cr-preview-asset-modal__placed-number {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    position: absolute;
    top: 4px;
    right: 12px;
    width: 28px;
    height: 28px;
    display: inline-block;
    text-align: center; }
  .cr-preview-asset-modal__content {
    text-align: center; }
  .cr-preview-asset-modal__tooltip {
    display: none;
    padding: 0;
    opacity: 1;
    background: #ffffff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    width: 412px;
    min-height: 87px;
    margin-top: -5px;
    position: absolute;
    right: calc((100% - 412px) / 2);
    z-index: 9; }
  .cr-preview-asset-modal__visible {
    display: block; }
  .cr-preview-asset-modal__tooltip-message {
    padding-top: 33px;
    padding-left: 38px;
    padding-bottom: 21px;
    margin-right: 38px;
    min-height: 100px; }
    .cr-preview-asset-modal__tooltip-message .avatar-sort-name-text {
      font-size: 15px; }
  .cr-preview-asset-modal__tooltip-message-content {
    margin-left: 55px;
    margin-top: 10px; }
  .cr-preview-asset-modal__close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer; }
  .cr-preview-asset-modal__tooltip-content {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #334856;
    text-align: left;
    height: 100%; }
  .cr-preview-asset-modal__pdf-container {
    position: relative;
    margin: 0 auto;
    text-align: center; }
    .cr-preview-asset-modal__pdf-container .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px; }
    .cr-preview-asset-modal__pdf-container .page-controls {
      background: white;
      display: inline-block;
      margin: 0 auto;
      margin-top: 15px;
      opacity: 1;
      transition: opacity ease-in-out 0.2s;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px; }
    .cr-preview-asset-modal__pdf-container .react-pdf__Page {
      max-width: 516px;
      margin: 0 auto; }
    .cr-preview-asset-modal__pdf-container button {
      width: 44px;
      height: 44px;
      background: white;
      border: 0;
      font-size: 0.8em;
      border-radius: 4px;
      cursor: pointer; }
      .cr-preview-asset-modal__pdf-container button:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .cr-preview-asset-modal__pdf-container button:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      .cr-preview-asset-modal__pdf-container button:focus, .cr-preview-asset-modal__pdf-container button:active {
        outline: none;
        border: none; }
  .cr-preview-asset-modal__content-pdf .cr-preview-asset-modal__field-title {
    margin-bottom: 10px; }
  .cr-preview-asset-modal__content-pdf .cr-preview-asset-modal__asset-pdf-view {
    padding-bottom: 37px;
    overflow-y: auto; }

.cr-confirmation-media__position {
  position: absolute;
  top: 51px;
  z-index: 9;
  right: -50px; }

.cr-confirmation-media__tooltip-position {
  top: 51px;
  right: calc(50% - 214px); }

.cr-confirmation-media__container {
  width: 454px;
  min-height: 163px;
  background: #ffffff;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  overflow: hidden;
  padding-top: 33px;
  padding-left: 49px;
  padding-right: 15px;
  padding-bottom: 18px; }

.cr-confirmation-media__title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0; }
  .cr-confirmation-media__title span {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: #334856; }

.cr-confirmation-media__modal-pin-icon {
  margin-right: 17px; }

.cr-confirmation-media__buttons {
  margin: 0;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center; }

.cr-confirmation-media .cr-confirmation-modal__header {
  margin-top: 33px; }

.cr-confirmation-media__confirm-button {
  width: 114px !important;
  min-width: auto !important;
  margin-right: 2px;
  border-radius: 24px;
  text-transform: uppercase !important; }

.cr-confirmation-media__cancel-button {
  width: 110px !important;
  min-width: auto !important;
  margin-left: 2px;
  margin-top: 0 !important;
  border-radius: 24px;
  text-transform: uppercase !important; }

.cr-confirmation-media__tooltip-container {
  box-shadow: none;
  width: 430px; }
  .cr-confirmation-media__tooltip-container::after {
    content: "";
    height: 20px;
    width: 20px;
    position: absolute;
    background-color: #fff;
    top: -10px;
    left: calc(50% - -1px);
    border-top: #c2d4e0 solid 1px;
    border-left: #c2d4e0 solid 1px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }

.cr-custom-form-traffic__section-form-fields {
  padding: 47px 54.27px 42px 54.27px; }

.cr-custom-form-traffic__view-label {
  margin-bottom: 18px; }

.cr-custom-form-traffic .order-view-value {
  margin-bottom: 30px !important; }

.cr-custom-form-traffic__view-assets-link {
  display: block;
  margin-bottom: 10px; }

.cr-custom-form-traffic__section-media-approve {
  padding: 56px 54.27px 44px 54.27px; }
  .cr-custom-form-traffic__section-media-approve .cr-form-builder-fields label {
    margin-bottom: 18px; }
  .cr-custom-form-traffic__section-media-approve .cr-handle-assets {
    margin-bottom: 0; }
  .cr-custom-form-traffic__section-media-approve .order-view-value {
    margin-bottom: 0 !important; }

.cr-custom-form-traffic__creative-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 9px;
  text-transform: capitalize; }

.cr-custom-form-traffic__fields-design-view .cr-handle-assets__file-item {
  padding-right: 23px;
  padding-left: 17px; }

.cr-custom-form-traffic__creative-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 9px; }
  .cr-custom-form-traffic__creative-head .btn {
    width: 226px !important;
    align-items: center;
    justify-content: center;
    display: flex; }
    .cr-custom-form-traffic__creative-head .btn span {
      font-weight: bold;
      text-transform: initial; }

.cr-custom-form-traffic__download-icon {
  margin-right: 10px; }

.cr-custom-form-traffic__creative-head {
  display: flex;
  align-items: center; }

.cr-custom-form-traffic .cr-handle-assets__file-buttons .btn {
  padding: 9px !important; }

.cr-custom-form-traffic__section-placement-confirmation {
  padding: 0 29px 33px 27px; }
  .cr-custom-form-traffic__section-placement-confirmation .alert-danger {
    display: inline-block; }

.cr-custom-form-traffic__section-placement-content {
  background-color: #f7fbfd;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 6px;
  margin: 0; }

.cr-custom-form-traffic__section-placement-content-edit {
  padding: 34px 33px 50px 33px; }
  .cr-custom-form-traffic__section-placement-content-edit .col-8 {
    padding: 0;
    margin-bottom: 0 !important; }

.cr-custom-form-traffic__section-placement-content-view {
  padding: 34px 33px 49px 33px; }
  .cr-custom-form-traffic__section-placement-content-view .col-8 {
    padding: 0;
    margin-bottom: 0 !important; }
  .cr-custom-form-traffic__section-placement-content-view .cr-handle-assets,
  .cr-custom-form-traffic__section-placement-content-view .cr-handle-assets__file-item {
    margin-bottom: 0; }
  .cr-custom-form-traffic__section-placement-content-view .order-view-value {
    display: block; }

.cr-custom-form-traffic__placement-url {
  margin-bottom: 29px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .cr-custom-form-traffic__placement-url input {
    background-color: #fff !important;
    color: #795afa !important;
    font-weight: bold; }
  .cr-custom-form-traffic__placement-url .btn {
    margin-top: 0; }

.cr-custom-form-traffic__placement-title {
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  margin-bottom: 18px;
  text-transform: capitalize; }

.cr-custom-form-traffic .placement-link {
  display: block; }

.cr-custom-form-traffic__form-handle-assets {
  margin-bottom: 30px;
  max-width: 520px; }
  .cr-custom-form-traffic__form-handle-assets .cr-handle-assets__file-name {
    color: #334856; }

.cr-custom-form-traffic__assets {
  margin-bottom: 5px;
  height: 63px !important;
  max-width: 520px; }

.cr-custom-form-traffic__placement-input {
  flex: 1 1; }

.cr-custom-form-traffic__btn-save {
  width: 100px !important;
  min-width: 100px !important;
  display: block;
  margin-top: 18px; }

.cr-custom-form-traffic__reset {
  margin-bottom: 0; }

.cr-custom-form-traffic__copy-link-truncate {
  white-space: nowrap;
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden; }

.cr-custom-form-traffic__copy-link {
  color: #795afa;
  font-weight: bold;
  display: flex;
  align-items: center;
  width: 100%; }

.cr-custom-form-traffic__copy-icon {
  cursor: pointer;
  margin-left: 10px; }

.cr-order-details__order-switch-text {
  font-size: 17px;
  line-height: 21px;
  color: #374c59;
  font-weight: bold; }

.cr-order-details__order-switch-type {
  margin-bottom: 17px; }

.cr-order-details__order-detail-form input::-webkit-input-placeholder,
.cr-order-details__order-detail-form textarea::-webkit-input-placeholder {
  /* Edge */
  font-size: 16px;
  line-height: 20px;
  color: #829fb1 !important; }

.cr-order-details__order-detail-form input::-webkit-input-placeholder, .cr-order-details__order-detail-form textarea::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 20px;
  color: #829fb1 !important; }

.cr-order-details__order-detail-form input::placeholder,
.cr-order-details__order-detail-form textarea::placeholder {
  font-size: 16px;
  line-height: 20px;
  color: #829fb1 !important; }

.cr-order-details__order-detail-form .react-select__placeholder {
  font-size: 16px;
  line-height: 20px;
  color: #829fb1 !important; }

.cr-order-details__order-detail-form .react-select__single-value,
.cr-order-details__order-detail-form .form-control {
  color: #334856; }

.cr-order-details__order-view-value {
  font-size: 16px;
  line-height: 20px;
  color: #000000; }
  .cr-order-details__order-view-value .cr-order-details__entity-row:last-child {
    margin-bottom: 0; }

.cr-order-details__col-field {
  margin-bottom: 27px !important; }

.cr-order-details__col-reset {
  margin-bottom: 0 !important; }

.cr-order-details__col-approval-field .cr-checkbox__text {
  font-size: 16px;
  line-height: 20px;
  color: #537a91;
  margin-left: 10px; }

.cr-order-details__col-production-type-field:not(.con-dub) {
  margin-bottom: 63px !important; }

.cr-order-details__section-title {
  padding-left: 47.76px;
  padding-right: 43px; }

.cr-order-details__section-production-details-fields {
  margin-top: 30px;
  padding-bottom: 31px; }

.cr-order-details__section-view-production-details-fields {
  padding-bottom: 0;
  margin-top: 21px; }

.cr-order-details__section-client-fields {
  margin-top: 23px;
  padding-bottom: 33px; }

.cr-order-details__rotation-table {
  margin-bottom: 14px;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  position: relative; }

.cr-order-details__rotation-name {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #334856;
  text-transform: uppercase;
  flex: 1 1;
  padding-left: 18px; }

.cr-order-details__rotation-value {
  flex: 0 0 50%;
  max-width: 50%;
  height: 100%; }
  .cr-order-details__rotation-value input {
    border: none;
    height: 100%;
    width: calc(100% - 55px);
    margin-left: 15px;
    border-left: 1px solid #c2d4e0;
    border-radius: 0; }
    .cr-order-details__rotation-value input:focus, .cr-order-details__rotation-value input:active {
      outline: none;
      box-shadow: none;
      border-color: #c2d4e0; }

.cr-order-details__rotation-number-view {
  display: block;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #334856;
  padding-left: 24px;
  border-left: 1px solid #c2d4e0;
  margin-left: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 48px; }

.cr-order-details__rotations {
  margin-bottom: 16px; }
  .cr-order-details__rotations .cr-order-details__rotation-table:last-child {
    margin-bottom: 0; }

.cr-order-details__col-stations-field {
  margin-bottom: 46px !important; }
  .cr-order-details__col-stations-field .cr-checkbox__text {
    color: #000; }

.cr-order-details__entity-row {
  display: block;
  margin-bottom: 16px; }

.cr-order-details__production-type-label {
  margin-bottom: 18px; }

.cr-order-details__box-production-details .cr-order-details__order-view-value {
  color: #000; }

.cr-order-details__box-production-details .cr-toggle-switch__left-component,
.cr-order-details__box-production-details .cr-toggle-switch__right-component {
  color: #829fb1;
  font-size: 17px;
  font-weight: 400;
  text-transform: uppercase; }
  .cr-order-details__box-production-details .cr-toggle-switch__left-component.active,
  .cr-order-details__box-production-details .cr-toggle-switch__right-component.active {
    color: #111b2b;
    font-weight: bold; }

.cr-order-details__coop-order-switch {
  margin-top: 33px; }

.cr-order-details__spot-title {
  font-weight: 700;
  color: #334856;
  line-height: 20px;
  margin-bottom: 20px; }

.cr-order-details__section-creative-details {
  margin-top: 16px; }
  .cr-order-details__section-creative-details .cr-order-details__col-view-field .cr-textarea-evaluator__wrapper-top-length {
    display: none; }

.cr-order-details__approvals {
  display: flex;
  align-items: center; }
  .cr-order-details__approvals .cr-checkbox {
    margin-bottom: 10px; }
    .cr-order-details__approvals .cr-checkbox:last-child {
      margin-bottom: 0; }

.cr-order-details__checkbox-approval {
  margin-right: 34.54px; }
  .cr-order-details__checkbox-approval .cr-checkbox__text {
    font-size: 16px;
    line-height: 20px;
    color: #537a91; }

.cr-order-details .cr-textinput__input-field textarea {
  min-height: 99px; }

.cr-order-details__creative-upload-files {
  margin-bottom: 31px; }
  .cr-order-details__creative-upload-files .cr-upload-files__drop-area {
    height: 70px; }
    .cr-order-details__creative-upload-files .cr-upload-files__drop-area .cr-icon-spin-container {
      width: 24px;
      height: 24px;
      margin: 0 auto; }

.cr-order-details__upload-files {
  height: 100%;
  border-color: #7394a8; }

.cr-order-details__add-rotation {
  margin-bottom: 26px; }

.cr-order-details__add-button {
  width: 183px;
  max-width: initial !important;
  border-color: #795afa; }
  .cr-order-details__add-button span {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #795afa;
    text-transform: initial; }

.cr-order-details__add-icon {
  margin-right: 8px; }

.cr-order-details .cr-textarea-evaluator__main-title-wrapper {
  display: none; }

.cr-order-details__station-view-title {
  white-space: nowrap;
  padding-right: 10px;
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden; }

.cr-order-details__station-view-table {
  display: block;
  border: none;
  border-radius: 0.25rem;
  height: 30px; }

.cr-order-details__order-view-label {
  margin-bottom: 18px; }

.cr-order-details__col-view-field {
  margin-bottom: 46px !important; }

.cr-order-details__col-view-rotation-field {
  margin-bottom: 16px; }

.cr-order-details__buttons {
  margin-top: 0 !important;
  padding-left: 47.76px;
  padding-right: 47.76px;
  margin-left: 0 !important;
  display: flex;
  justify-content: space-between; }
  .cr-order-details__buttons .btn-group {
    margin-left: 0; }
  .cr-order-details__buttons .btn {
    margin-right: 14px !important; }
    .cr-order-details__buttons .btn:last-child {
      margin-right: 0 !important; }

.cr-order-details__delete-order-number {
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 19px; }

.cr-order-details .rotation-value input:focus, .cr-order-details .rotation-value input:active {
  outline: none;
  box-shadow: none; }

.cr-order-details__creative-upload-file .drop-area, .cr-order-details__col-upload-field-tags .drop-area, .cr-order-details__col-upload-field .drop-area {
  border: 1px dashed #7394a8;
  height: 70px; }

.cr-order-details__creative-upload-file .player-container, .cr-order-details__col-upload-field-tags .player-container, .cr-order-details__col-upload-field .player-container {
  margin-bottom: 0; }

.cr-order-details__creative-upload-file .player-wrapper, .cr-order-details__col-upload-field-tags .player-wrapper, .cr-order-details__col-upload-field .player-wrapper {
  margin-bottom: 0; }

.cr-order-details__creative-upload-file .upload-view-file .file-value, .cr-order-details__col-upload-field-tags .upload-view-file .file-value, .cr-order-details__col-upload-field .upload-view-file .file-value {
  word-break: break-all;
  margin: 0; }

.cr-order-details__creative-upload-file .delete-icon, .cr-order-details__col-upload-field-tags .delete-icon, .cr-order-details__col-upload-field .delete-icon {
  top: calc(50% - 4px); }

.cr-order-details__col-upload-field {
  margin-bottom: 30px !important; }

.cr-order-details__add-rotation-provided {
  display: inline-block;
  margin-right: 14px; }

.cr-order-details__spot-buttons {
  margin-bottom: 34px; }

.cr-order-details__spot-details-provided .cr-order-details__col-field {
  margin-bottom: 33px; }

.cr-order-details .rotation-station-label {
  width: 120px; }

.cr-order-details__production-details-continuity .cr-order-details__col-last-view-field {
  margin-bottom: 112.06px !important; }

.cr-order-details__checkbox-use-audio {
  margin-top: 10px; }
  .cr-order-details__checkbox-use-audio .cr-checkbox__text {
    font-size: 14px;
    line-height: 18px;
    color: #657894;
    margin-left: 10px; }

.cr-order-details__col-production-needed-tags-upload-field {
  margin-bottom: 35px !important; }

.cr-order-details__col-field-production-needed-tags {
  margin-bottom: 43px !important; }

.cr-order-details__col-last-view-field-needed-tags {
  margin-bottom: 33px; }

.cr-order-details__delete-spot-button {
  margin-left: 10px;
  padding: 5px 10px;
  display: inline-block;
  cursor: pointer; }
  .cr-order-details__delete-spot-button span {
    color: #795afa;
    line-height: 18px;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 400; }

.cr-order-details__spot-production-type-text {
  color: #334856;
  font-weight: 400;
  font-size: 23px; }

.cr-order-details__confirmation-modal-delete-spot {
  max-width: 557px; }
  .cr-order-details__confirmation-modal-delete-spot .modal-content {
    height: 376px; }
  .cr-order-details__confirmation-modal-delete-spot .modal-title {
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    width: 355px;
    color: #000000; }
  .cr-order-details__confirmation-modal-delete-spot .cr-confirmation-modal__confirm-button {
    width: 151.48px;
    height: 43.5px;
    margin: 0 10px;
    max-width: inherit; }
  .cr-order-details__confirmation-modal-delete-spot .cr-confirmation-modal__cancel-button {
    width: 136.54px;
    height: 42.51px;
    margin: 0 10px;
    max-width: inherit; }

.cr-order-details__col-contract-status-field .react-select__single-value {
  text-transform: capitalize; }

.cr-order-details__select-audience-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.cr-order-details__section-audience {
  padding-top: 15px !important;
  padding-bottom: 25px !important; }
  .cr-order-details__section-audience .audience {
    display: inline-block; }
    .cr-order-details__section-audience .audience .audience-name {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 39px;
      color: #000000;
      margin-right: 30px; }
    .cr-order-details__section-audience .audience .audience-stations {
      font-size: 16px;
      color: #334856; }
  .cr-order-details__section-audience .no-audience-selected {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 40px;
    color: #537a91; }

.cr-order-details__select-audience {
  float: right; }
  .cr-order-details__select-audience .button-text span {
    font-weight: 800;
    font-size: 19px;
    line-height: 24px;
    text-align: center;
    color: #334856; }

.cr-order-details__rotation-container-view {
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 6px; }
  .cr-order-details__rotation-container-view .cr-order-details__rotation-view-table:last-child {
    border-bottom: none; }

.cr-order-details__rotation-container-view-empty {
  border: none; }

.cr-order-details__rotation-view-table {
  margin-bottom: 0;
  border: none;
  border-bottom: 1px solid #c2d4e0;
  border-radius: 0;
  height: 46px; }

.cr-order-details__script-fields-container {
  background: rgba(194, 212, 224, 0.1);
  border: 1px solid rgba(194, 212, 224, 0.7);
  padding: 20px 33px;
  border-radius: 6px;
  margin-bottom: 40px; }

.cr-order-details__col-contract-status-field {
  margin-bottom: 32px; }

.cr-order-details__asset-audit {
  font-size: 11px;
  line-height: 150%;
  color: #657894;
  margin-bottom: 6px; }

.cr-order-details__provided-coop-script .cr-textarea-evaluator__inner-title.edit {
  text-transform: initial; }

.cr-textarea-evaluator__textarea-container {
  position: relative; }

.cr-textarea-evaluator__textarea-disabled {
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(255, 255, 255, 0.5); }

.cr-textarea-evaluator__title {
  padding-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
  color: #324856; }

.cr-textarea-evaluator__title-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; }

.cr-textarea-evaluator__wrapper-top {
  display: flex;
  max-width: 400px;
  margin-left: auto;
  flex: 1 1;
  flex-direction: row;
  align-items: center; }
  .cr-textarea-evaluator__wrapper-top.with-inner-title {
    margin-bottom: 0px; }

.cr-textarea-evaluator__tags-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }

.cr-textarea-evaluator__info {
  padding-top: 10px;
  font-size: 16px;
  color: #334856; }

.cr-textarea-evaluator__slide-label {
  min-width: 40px;
  text-transform: uppercase;
  margin: 0 5px;
  font-size: 14px; }

.cr-textarea-evaluator__fast-slide-label {
  margin-right: 0; }

.cr-textarea-evaluator__wrapper-top-length {
  justify-content: flex-end;
  margin-bottom: 20px; }
  .cr-textarea-evaluator__wrapper-top-length.title {
    float: left;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #657894;
    text-transform: uppercase;
    margin-bottom: 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start; }
  .cr-textarea-evaluator__wrapper-top-length .text-danger {
    font-size: 16px;
    text-transform: initial; }

.cr-textarea-evaluator__inner-title {
  margin-top: 8px;
  width: 100%; }
  .cr-textarea-evaluator__inner-title.edit {
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856;
    text-transform: capitalize; }

.cr-textarea-evaluator__length {
  margin-right: 20px; }

.cr-textarea-evaluator__timer {
  text-align: right;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 15px; }
  .cr-textarea-evaluator__timer.with-inner-title {
    margin-bottom: 8px;
    display: flex;
    align-items: center; }
    .cr-textarea-evaluator__timer.with-inner-title .cr-textinput__input-wrapper {
      width: 47px;
      height: 41px; }
      .cr-textarea-evaluator__timer.with-inner-title .cr-textinput__input-wrapper input {
        background: #fff; }
  .cr-textarea-evaluator__timer span {
    font-size: 16px;
    color: #829fb1;
    margin-right: 10px;
    text-transform: capitalize; }

.cr-textarea-evaluator__text-value {
  font-weight: bold;
  color: #000 !important; }

.cr-textarea-evaluator__delete-text {
  font-weight: bold;
  font-size: 14px;
  color: #795afa;
  margin-left: 20px;
  cursor: pointer; }

.cr-textarea-evaluator__tag {
  background-color: #f7fbfd;
  margin-right: 10px;
  padding: 2px 15px;
  border-radius: 5px;
  cursor: pointer; }

.cr-textarea-evaluator__second-spot {
  text-align: right;
  width: 100%;
  font-size: 16px;
  text-transform: uppercase;
  color: #007bff;
  font-weight: 600; }
  .cr-textarea-evaluator__second-spot > strong {
    margin-right: 5px;
    font-weight: 600; }

.cr-textarea-evaluator__container {
  width: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 10px 0; }

.cr-production-continuity-traffic-details--list {
  margin-top: 20px; }

.cr-production-continuity-traffic-details__section-title {
  padding-left: 47.76px;
  height: 83.6px; }

.cr-production-continuity-traffic-details__box-traffic-container {
  margin-bottom: 0 !important; }

.cr-production-continuity-traffic-details__box-traffic-container-view .cr-production-continuity-traffic-details__section-production-complete-fields {
  margin-top: 27px; }

.cr-production-continuity-traffic-details__box-traffic-container-view .cr-production-continuity-traffic-details__market-spot .cr-rotation-chart__table-view-mode {
  margin-bottom: 0; }

.cr-production-continuity-traffic-details__spot-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
  color: #334856; }

.cr-production-continuity-traffic-details__col-title {
  margin-bottom: 28.43px !important; }

.cr-production-continuity-traffic-details__label {
  margin-bottom: 10px; }

.cr-production-continuity-traffic-details__field-value {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334856; }

.cr-production-continuity-traffic-details__col-field {
  margin-bottom: 35px !important; }

.cr-production-continuity-traffic-details__spot-row {
  padding: 0 49px; }

.cr-production-continuity-traffic-details__spot-production-needed-row .upload-file .row {
  margin-left: 0;
  margin-right: 0; }

.cr-production-continuity-traffic-details__no-padding {
  padding: 0; }

.cr-production-continuity-traffic-details__table-spot {
  flex: 1 1; }
  .cr-production-continuity-traffic-details__table-spot table {
    width: 100%; }
  .cr-production-continuity-traffic-details__table-spot tbody {
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 0 0 1px #c2d4e0; }
  .cr-production-continuity-traffic-details__table-spot td {
    width: 50%; }
  .cr-production-continuity-traffic-details__table-spot .cr-textinput > div {
    margin-bottom: 0 !important; }
  .cr-production-continuity-traffic-details__table-spot .cr-textinput .cr-textinput__input-wrapper {
    border: none;
    height: 48px; }
  .cr-production-continuity-traffic-details__table-spot .cr-textinput .cr-textinput__input-field input:hover {
    box-shadow: none;
    border-color: transparent; }
  .cr-production-continuity-traffic-details__table-spot .cr-textinput input {
    padding-left: 25px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #334856; }

.cr-production-continuity-traffic-details__table-spot-tags {
  padding-left: 15px; }

.cr-production-continuity-traffic-details__table-label {
  margin-bottom: 8px !important; }

.cr-production-continuity-traffic-details__table-value {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #334856; }

.cr-production-continuity-traffic-details__border {
  border-right: 1px solid #c2d4e0; }

.cr-production-continuity-traffic-details__spot-production {
  background: #657894;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  text-transform: uppercase; }

.cr-production-continuity-traffic-details__spot-info {
  display: flex;
  justify-content: space-between; }

.cr-production-continuity-traffic-details__col-table-field {
  margin-bottom: 28.43px !important;
  padding-left: 0;
  padding-right: 0; }

.cr-production-continuity-traffic-details__cart-info {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.cr-production-continuity-traffic-details__recording-info-b {
  margin-top: 20px !important; }

.cr-production-continuity-traffic-details__recording-info {
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-bottom: 15px !important; }
  .cr-production-continuity-traffic-details__recording-info .drop-area {
    height: 70px; }
  .cr-production-continuity-traffic-details__recording-info .delete-icon {
    top: calc(50% - 5px); }
  .cr-production-continuity-traffic-details__recording-info .file-value {
    word-break: break-all;
    margin-top: 0; }

.cr-production-continuity-traffic-details__table-cart-number, .cr-production-continuity-traffic-details__spot-value {
  height: 48px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #334856;
  display: block;
  align-items: center;
  width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 48px; }
  .cr-production-continuity-traffic-details__table-cart-number.cart-number, .cr-production-continuity-traffic-details__spot-value.cart-number {
    float: left;
    width: 50%;
    margin-right: 14px; }

.cr-production-continuity-traffic-details__cart-edit-pencil {
  cursor: pointer;
  align-items: center;
  justify-content: left;
  display: flex;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #795afa;
  float: left;
  border: 1px solid #829FB1;
  border-radius: 25px;
  padding: 15px 50px; }

.cr-production-continuity-traffic-details__spot-pending {
  height: 48px;
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #795afa;
  float: left;
  width: 50%; }
  .cr-production-continuity-traffic-details__spot-pending span {
    margin-left: 7px; }
  .cr-production-continuity-traffic-details__spot-pending.sync-cart {
    cursor: pointer; }

.cr-production-continuity-traffic-details__cart-edit-text {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-left: 10px; }

.cr-production-continuity-traffic-details .upload-file .player-wrapper,
.cr-production-continuity-traffic-details .upload-file .player-container {
  margin-bottom: 0; }

.cr-production-continuity-traffic-details .upload-file .files-wrapper {
  width: 100%; }

.cr-production-continuity-traffic-details .upload-file .player-ui-wrapper {
  max-width: 353px;
  position: relative; }

.cr-production-continuity-traffic-details .upload-file .player-container-ui {
  height: 50px !important; }
  .cr-production-continuity-traffic-details .upload-file .player-container-ui .player-timer {
    width: 135px; }

.cr-production-continuity-traffic-details .upload-file .col-12 {
  margin-bottom: 0; }

.cr-production-continuity-traffic-details .alert-danger {
  margin-top: 5px; }

.cr-production-continuity-traffic-details__section-fields {
  margin-top: 14.4px;
  padding-left: 0 !important;
  padding-right: 0 !important; }
  .cr-production-continuity-traffic-details__section-fields .cr-production-continuity-traffic-details__spot-section:last-child {
    border-bottom: none; }

.cr-production-continuity-traffic-details__section-production-complete-fields {
  margin-top: 24px; }

.cr-production-continuity-traffic-details__title-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #657894 !important; }

.cr-production-continuity-traffic-details__spot-row-view .cr-production-continuity-traffic-details__table-spot tbody {
  box-shadow: none; }

.cr-production-continuity-traffic-details__spot-row-view .cr-production-continuity-traffic-details__table-spot-tags .cr-production-continuity-traffic-details__border {
  border-right: 1px solid #c2d4e0;
  padding-left: 25px; }

.cr-production-continuity-traffic-details__spot-row-view .cr-production-continuity-traffic-details__table-spot-tags .cr-production-continuity-traffic-details__table-value,
.cr-production-continuity-traffic-details__spot-row-view .cr-production-continuity-traffic-details__table-spot-tags .cr-production-continuity-traffic-details__table-cart-number {
  padding-left: 25px; }

.cr-production-continuity-traffic-details__spot-row-view .cr-production-continuity-traffic-details__border {
  border-right: none; }

.cr-production-continuity-traffic-details__spot-row-view .cr-production-continuity-traffic-details__table-value {
  padding-left: 0 !important; }

.cr-production-continuity-traffic-details__spot-row-view .cr-production-continuity-traffic-details__table-cart-number {
  padding-left: 0;
  width: 100%; }

.cr-production-continuity-traffic-details__spot-row-view .cr-production-continuity-traffic-details__col-field {
  margin-bottom: 36px !important; }
  .cr-production-continuity-traffic-details__spot-row-view .cr-production-continuity-traffic-details__col-field.selected-line-numbers {
    margin-bottom: 25px; }

.cr-production-continuity-traffic-details__spot-production {
  background: #657894;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  text-transform: uppercase; }

.cr-production-continuity-traffic-details__spot-production-complete-edit {
  position: relative;
  margin-bottom: 22px;
  border-bottom: 1px solid #edf1f6;
  padding-bottom: 15px;
  padding-left: 31px;
  padding-right: 39px; }
  .cr-production-continuity-traffic-details__spot-production-complete-edit:last-child {
    border-bottom: none;
    margin-bottom: 6px;
    padding-bottom: 0; }
    .cr-production-continuity-traffic-details__spot-production-complete-edit:last-child.cr-production-continuity-traffic-details__spot-row-view {
      margin-bottom: 42px; }

.cr-production-continuity-traffic-details__spot-section {
  border-bottom: 1px solid #edf1f6;
  padding: 25px 49px; }

.cr-production-continuity-traffic-details__rotation-chart {
  padding: 0 49px;
  padding-bottom: 40px; }

.cr-production-continuity-traffic-details__spot-row-hover {
  padding-top: 13px;
  padding-bottom: 16px;
  padding-left: 18px;
  padding-right: 10px; }

.cr-production-continuity-traffic-details__spot-hover:hover {
  background: #e1f4fe;
  border-radius: 6px;
  border-bottom-color: transparent; }
  .cr-production-continuity-traffic-details__spot-hover:hover .cr-textinput__input-wrapper {
    background-color: #fff; }
  .cr-production-continuity-traffic-details__spot-hover:hover .cr-production-continuity-traffic-details__table-cart-number-td {
    background-color: #fff;
    overflow: hidden;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px; }
  .cr-production-continuity-traffic-details__spot-hover:hover .cr-production-continuity-traffic-details__border {
    background-color: #fff;
    overflow: hidden;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px; }

.cr-production-continuity-traffic-details__close-button {
  pointer-events: auto;
  position: absolute;
  top: -3px;
  right: 10px;
  cursor: pointer;
  border: 1px solid #bccedb;
  box-sizing: border-box;
  border-radius: 24px;
  display: inline-flex;
  width: 133px;
  height: 44px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff; }
  .cr-production-continuity-traffic-details__close-button span {
    font-size: 14px;
    line-height: 18px;
    color: #795afa;
    text-transform: uppercase; }

.cr-production-continuity-traffic-details__spot-button {
  padding-left: 49px;
  padding-bottom: 43px; }

.cr-production-continuity-traffic-details__rotation-spot-button {
  padding-left: 49px;
  padding-bottom: 26px;
  border-bottom: 1px solid #edf1f6;
  margin-bottom: 37px; }

.cr-production-continuity-traffic-details__add-button {
  width: 183px !important;
  text-transform: inherit !important; }
  .cr-production-continuity-traffic-details__add-button span {
    font-weight: bold; }

.cr-production-continuity-traffic-details__add-icon {
  margin-right: 15px; }

.cr-production-continuity-traffic-details__col-tag-field {
  margin-top: 30px; }

.cr-production-continuity-traffic-details__label-tag {
  margin-bottom: 15px; }

.cr-production-continuity-traffic-details__spot-pending {
  align-items: center;
  justify-content: left;
  display: flex;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #795afa; }
  .cr-production-continuity-traffic-details__spot-pending span {
    margin-left: 7px; }

.cr-production-continuity-traffic-details__spot-row-rotation .cr-rotation-chart__table-view-mode {
  margin-bottom: 0; }

.cr-production-continuity-traffic-details__spot-row-rotation .cr-rotation-chart {
  margin-bottom: 52px; }

.cr-production-continuity-traffic-details__market-traffic-container {
  margin-bottom: 20px; }
  .cr-production-continuity-traffic-details__market-traffic-container:last-child {
    margin-bottom: 0; }

.cr-production-continuity-traffic-details__market-section-title {
  margin-bottom: 0; }

.cr-production-continuity-traffic-details__traffic-title {
  margin-right: 10px; }

.cr-production-continuity-traffic-details__market-title {
  font-weight: normal; }

.cr-production-continuity-traffic-details__market-step {
  color: #129aa3;
  font-size: 14px;
  font-weight: 800;
  background: #ffffff;
  border: 1px solid rgba(194, 212, 224, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  height: 33px;
  padding: 0 15px;
  text-transform: uppercase;
  margin-right: 40px; }

.cr-production-continuity-traffic-details__traffic-action {
  display: flex;
  align-items: center; }

.cr-production-continuity-traffic-details__market-padding {
  padding-left: 50px;
  padding-right: 48px; }

.cr-production-continuity-traffic-details__market-stations {
  border-bottom: 1px solid #edf1f6; }

.cr-production-continuity-traffic-details__stations span {
  font-size: 16px;
  color: #657894;
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 4px;
  height: 42px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin-right: 14px;
  text-transform: uppercase; }

.cr-production-continuity-traffic-details__market-spot {
  padding-top: 35px;
  border-bottom: 1px solid #edf1f6;
  padding-bottom: 33px; }
  .cr-production-continuity-traffic-details__market-spot .cr-rotation-chart {
    margin-top: 27px; }

.cr-production-continuity-traffic-details__market-traffic-container .cr-production-continuity-traffic-details__col-field {
  margin-bottom: 35px !important; }

.cr-production-continuity-traffic-details__market-traffic-container .cr-production-continuity-traffic-details__market-spot:last-child {
  border-bottom: none; }

.cr-production-continuity-traffic-details__spot-buttons {
  display: flex;
  align-items: center;
  margin-top: 37px; }

.cr-production-continuity-traffic-details__add-rotation-provided {
  display: inline-block;
  margin-right: 14px; }

.cr-production-continuity-traffic-details__delete-spot-button {
  height: 44px;
  width: 132px;
  border: 1px solid #bccedb;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: #fff; }
  .cr-production-continuity-traffic-details__delete-spot-button span {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #829fb1;
    text-transform: initial; }

.cr-production-continuity-traffic-details__checkbox-use-audio {
  margin-top: 15px; }
  .cr-production-continuity-traffic-details__checkbox-use-audio .cr-checkbox__text {
    color: #657894;
    font-size: 14px; }

.cr-production-continuity-traffic-details__col-field-production-needed-tags {
  margin-top: 35px; }

.cr-production-continuity-traffic-details__field-tag-value {
  color: #000000;
  font-size: 24px; }

.cr-production-continuity-traffic-details__market-needed-chart {
  margin-bottom: 39px; }

.cr-production-continuity-traffic-details__completed-time {
  color: #829fb1;
  font-size: 14px;
  margin-right: 40px; }
  .cr-production-continuity-traffic-details__completed-time strong {
    font-weight: bold;
    color: #000;
    margin-left: 5px; }

.cr-production-continuity-traffic-details__horizontal-line {
  border-bottom: 1px solid #edf1f6;
  margin-bottom: 24px;
  margin-top: 24px; }

.cr-production-continuity-traffic-details .file-upload-title-container .spot-length {
  right: 0px; }

.cr-production-continuity-traffic-details__tag-instructions {
  font-size: 24px;
  line-height: 150%;
  color: #000000; }

.cr-production-continuity-traffic-details__produced-spot {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .cr-production-continuity-traffic-details__produced-spot .upload-file {
    flex: 1 1; }
  .cr-production-continuity-traffic-details__produced-spot .drop-area {
    height: 70px; }

.cr-production-continuity-traffic-details__produced-spot-upload {
  flex: 1 1; }
  .cr-production-continuity-traffic-details__produced-spot-upload .upload-file {
    flex: 1 1; }

.cr-production-continuity-traffic-details__btn-coop-script {
  margin-left: 46px; }

.cr-production-dubbing-traffic-details {
  /* &__col-field {
		margin-bottom: 35px;
	} */ }
  .cr-production-dubbing-traffic-details--list {
    margin-top: 20px; }
  .cr-production-dubbing-traffic-details__section-title {
    padding-left: 47.76px;
    height: 83.6px; }
  .cr-production-dubbing-traffic-details__box-traffic-container {
    margin-bottom: 0 !important; }
  .cr-production-dubbing-traffic-details__spot-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
    color: #334856; }
  .cr-production-dubbing-traffic-details__col-title {
    margin-bottom: 19.43px !important; }
  .cr-production-dubbing-traffic-details__label {
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #657894 !important;
    margin-bottom: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .cr-production-dubbing-traffic-details__field-value {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #334856; }
  .cr-production-dubbing-traffic-details__spot-row {
    /* margin-bottom: 26px;
		padding-right: 49px;
		padding-left: 49px;
		border-bottom: 1px solid #edf1f6; */
    margin-bottom: 16px;
    padding-right: 26px;
    padding-left: 26px; }
    .cr-production-dubbing-traffic-details__spot-row:last-child {
      border-bottom: none; }
  .cr-production-dubbing-traffic-details__spot-value {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    min-height: 18px; }
  .cr-production-dubbing-traffic-details__spot-production {
    background: #657894;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    height: 50px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    text-transform: uppercase; }
  .cr-production-dubbing-traffic-details__table-cart-number {
    height: 48px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #334856;
    display: block;
    align-items: center;
    width: 130px;
    padding-left: 25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 48px; }
  .cr-production-dubbing-traffic-details .upload-file {
    width: 100%; }
    .cr-production-dubbing-traffic-details .upload-file .col-12,
    .cr-production-dubbing-traffic-details .upload-file .player-wrapper,
    .cr-production-dubbing-traffic-details .upload-file .player-container {
      margin-bottom: 0; }
    .cr-production-dubbing-traffic-details .upload-file .files-wrapper {
      width: 100%; }
    .cr-production-dubbing-traffic-details .upload-file .btn-link {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      padding: 0 !important;
      height: auto !important;
      text-align: left;
      text-transform: capitalize; }
    .cr-production-dubbing-traffic-details .upload-file .player-container-ui {
      height: 48px !important; }
      .cr-production-dubbing-traffic-details .upload-file .player-container-ui .player-timer {
        width: 135px; }
  .cr-production-dubbing-traffic-details .alert-danger {
    margin-top: 5px; }
  .cr-production-dubbing-traffic-details__section-fields {
    margin-top: 27.4px;
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .cr-production-dubbing-traffic-details__spot-center {
    height: 100%;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .cr-production-dubbing-traffic-details__spot-right {
    height: 100%;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .cr-production-dubbing-traffic-details__button-dubbed-icon {
    margin-right: 10px; }
  .cr-production-dubbing-traffic-details__button-dubbed {
    background: #73a703;
    border-radius: 25px;
    border-color: #73a703;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    min-width: 150px; }
    .cr-production-dubbing-traffic-details__button-dubbed span {
      color: #fff; }
  .cr-production-dubbing-traffic-details__button-mark-as-dubbed {
    border: 1px solid #829fb1;
    height: 48px;
    border-radius: 25px;
    padding-left: 25px;
    padding-right: 25px;
    background: #ffffff;
    border: 1px solid #829fb1;
    color: #795afa; }
    @media (max-width: 1436px) {
      .cr-production-dubbing-traffic-details__button-mark-as-dubbed {
        font-size: 11px;
        min-width: auto !important; } }
    .cr-production-dubbing-traffic-details__button-mark-as-dubbed:disabled {
      background-color: #c2d4e0;
      border-color: #c2d4e0;
      opacity: 1;
      height: 44px; }
      .cr-production-dubbing-traffic-details__button-mark-as-dubbed:disabled span {
        color: #fff; }
    .cr-production-dubbing-traffic-details__button-mark-as-dubbed.automated {
      background-color: #795afa;
      border-color: #795afa;
      outline: none;
      min-width: 134px !important; }
      .cr-production-dubbing-traffic-details__button-mark-as-dubbed.automated span {
        color: #fff; }
      .cr-production-dubbing-traffic-details__button-mark-as-dubbed.automated:focus {
        box-shadow: none; }
      .cr-production-dubbing-traffic-details__button-mark-as-dubbed.automated:active {
        box-shadow: none; }
  .cr-production-dubbing-traffic-details__spot-pending {
    align-items: center;
    display: flex;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #795afa; }
    .cr-production-dubbing-traffic-details__spot-pending span {
      margin-left: 7px; }
  .cr-production-dubbing-traffic-details__dubbed-time {
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #657894;
    margin-bottom: 5px; }
  .cr-production-dubbing-traffic-details__dubbed {
    text-align: center; }
  .cr-production-dubbing-traffic-details__not-dubbed {
    margin-top: 30px; }
    .cr-production-dubbing-traffic-details__not-dubbed.automated {
      margin-top: 0px; }
  .cr-production-dubbing-traffic-details__dubbed-undo {
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #657894;
    text-transform: uppercase;
    cursor: pointer;
    padding: 0 !important;
    height: auto; }
  .cr-production-dubbing-traffic-details__dubbed-undo-disabled {
    cursor: not-allowed; }
  .cr-production-dubbing-traffic-details__confirmation-modal.modal-dialog {
    width: 557px;
    max-width: inherit; }
  .cr-production-dubbing-traffic-details__confirmation-modal .modal-content {
    height: 427px; }
  .cr-production-dubbing-traffic-details__confirmation-modal .modal-title {
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #000000; }
  .cr-production-dubbing-traffic-details__confirmation-modal .btn-blue {
    width: 151.48px;
    max-width: inherit; }
  .cr-production-dubbing-traffic-details__confirmation-modal .btn-outline-blue {
    width: 137.53px;
    max-width: inherit; }
  .cr-production-dubbing-traffic-details__recording-info-b {
    margin-top: 20px !important; }
  .cr-production-dubbing-traffic-details__recording-info .drop-area {
    height: 70px; }
  .cr-production-dubbing-traffic-details__recording-info .delete-icon {
    top: calc(50% - 5px); }
  .cr-production-dubbing-traffic-details__recording-info .file-value {
    word-break: break-all;
    margin-top: 0; }
  .cr-production-dubbing-traffic-details__market-traffic-container {
    margin-bottom: 20px; }
  .cr-production-dubbing-traffic-details__traffic-title {
    margin-right: 10px; }
  .cr-production-dubbing-traffic-details__market-title {
    font-weight: normal; }
  .cr-production-dubbing-traffic-details__market-step {
    color: #2b85ff;
    font-size: 14px;
    font-weight: 800;
    background: #ffffff;
    border: 1px solid rgba(194, 212, 224, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    display: inline-block;
    height: 33px;
    padding: 0 15px;
    text-transform: uppercase;
    margin-right: 40px; }
  .cr-production-dubbing-traffic-details__traffic-action {
    display: flex;
    align-items: center; }
  .cr-production-dubbing-traffic-details__market-padding {
    padding-left: 50px;
    padding-right: 48px; }
  .cr-production-dubbing-traffic-details__market-stations {
    border-bottom: 1px solid #edf1f6; }
  .cr-production-dubbing-traffic-details__stations span {
    font-size: 16px;
    color: #657894;
    background: #f7fbfd;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 4px;
    height: 42px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    margin-right: 14px;
    text-transform: uppercase; }
  .cr-production-dubbing-traffic-details__market-spot {
    padding-top: 35px;
    border-bottom: 1px solid #edf1f6;
    padding-bottom: 33px; }
    .cr-production-dubbing-traffic-details__market-spot .cr-rotation-chart {
      margin-top: 27px; }
  .cr-production-dubbing-traffic-details__market-traffic-container .cr-production-dubbing-traffic-details__col-field {
    margin-bottom: 35px !important; }
  .cr-production-dubbing-traffic-details__market-traffic-container .cr-production-dubbing-traffic-details__market-spot:last-child {
    border-bottom: none; }
  .cr-production-dubbing-traffic-details__market-traffic-container .cr-production-dubbing-traffic-details__field-value {
    color: #000000;
    font-size: 16px; }
  .cr-production-dubbing-traffic-details__market-traffic-container .cr-production-dubbing-traffic-details__not-dubbed {
    margin-top: 0; }
  .cr-production-dubbing-traffic-details__spot-buttons {
    display: flex;
    align-items: center;
    margin-top: 37px; }
  .cr-production-dubbing-traffic-details__add-rotation-provided {
    display: inline-block;
    margin-right: 14px; }
  .cr-production-dubbing-traffic-details__delete-spot-button {
    height: 44px;
    width: 132px;
    border: 1px solid #bccedb;
    box-sizing: border-box;
    border-radius: 24px;
    background-color: #fff; }
    .cr-production-dubbing-traffic-details__delete-spot-button span {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #829fb1;
      text-transform: initial; }
  .cr-production-dubbing-traffic-details__checkbox-use-audio {
    margin-top: 15px; }
    .cr-production-dubbing-traffic-details__checkbox-use-audio .cr-checkbox__text {
      color: #657894;
      font-size: 14px; }
  .cr-production-dubbing-traffic-details__col-field-production-needed-tags {
    margin-top: 35px; }
  .cr-production-dubbing-traffic-details__field-tag-value {
    color: #000000;
    font-size: 24px; }
  .cr-production-dubbing-traffic-details__market-needed-chart {
    margin-bottom: 39px; }
  .cr-production-dubbing-traffic-details__completed-time {
    color: #829fb1;
    font-size: 14px;
    margin-right: 40px; }
    .cr-production-dubbing-traffic-details__completed-time strong {
      font-weight: bold;
      color: #000;
      margin-left: 5px; }
  .cr-production-dubbing-traffic-details__horizontal-line {
    border-bottom: 1px solid #edf1f6;
    margin-bottom: 24px;
    margin-top: 24px;
    margin-left: -26px;
    margin-right: -26px; }
  .cr-production-dubbing-traffic-details__dubbing-progress {
    height: 44px;
    position: absolute;
    background: #73a703;
    border-radius: 25px 0px 0px 25px;
    z-index: 1; }
    .cr-production-dubbing-traffic-details__dubbing-progress.complete {
      border-radius: 25px;
      z-index: -1; }
  .cr-production-dubbing-traffic-details__dub-order {
    min-width: 150px !important;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0px !important;
    grid-gap: 10px;
    gap: 10px; }
    .cr-production-dubbing-traffic-details__dub-order:focus {
      box-shadow: none; }
    .cr-production-dubbing-traffic-details__dub-order:active {
      box-shadow: none; }
    .cr-production-dubbing-traffic-details__dub-order.complete {
      z-index: 1;
      background: #73a703;
      border-color: #73a703; }

.cr-order-approvals__approvals {
  margin-bottom: 30px; }
  .cr-order-approvals__approvals .order-approval-checkbox {
    margin-right: 15px; }
  .cr-order-approvals__approvals .cr-checkbox__text {
    color: #000; }
  .cr-order-approvals__approvals .rc-checkbox-inner {
    width: 23.84px;
    height: 23.12px; }

.cr-order-approvals__checkboxes {
  display: flex;
  align-items: center; }

.cr-order-approvals .form-label {
  margin-bottom: 10px; }

.cr-order-approvals__form-div {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000; }

.cr-order-approvals__form-div-copy-points {
  margin-bottom: 30px; }
  .cr-order-approvals__form-div-copy-points .cr-textarea-evaluator__wrapper-top-length {
    margin-bottom: 0; }

.cr-order-approvals__form-div-draft-script {
  margin-bottom: 30px; }
  .cr-order-approvals__form-div-draft-script .cr-textarea-evaluator__wrapper-top-length {
    display: none; }

.cr-order-approvals .cr-textarea-evaluator__container {
  padding: 0 !important; }

.cr-order-approvals .view-files-component {
  margin-top: 21px;
  max-width: 537px; }

.cr-order-approvals__upload-files {
  height: 48px;
  background: #eff3f6;
  border: 1px dashed #829fb1;
  box-sizing: border-box;
  border-radius: 6px; }

.cr-order-approvals .cr-handle-assets, .cr-order-approvals__creative-upload-files {
  width: 534px; }

.none-placeholder {
  margin: 14px 0px;
  display: block; }

.cr-order-script__approvals {
  margin-bottom: 31px; }
  .cr-order-script__approvals .order-approval-checkbox {
    margin-right: 15px; }
  .cr-order-script__approvals .cr-checkbox__text {
    color: #000; }
  .cr-order-script__approvals .rc-checkbox-inner {
    width: 23.84px;
    height: 23.12px; }

.cr-order-script__checkboxes {
  display: flex;
  align-items: center; }

.cr-order-script .form-label {
  margin-bottom: 10px; }

.cr-order-script__form-div {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000; }
  .cr-order-script__form-div .cr-textarea-evaluator__inner-title.edit {
    font-size: 20px;
    text-transform: capitalize; }

.cr-order-script__form-div-copy-points {
  margin-bottom: 20px; }

.cr-order-script .cr-textarea-evaluator__container {
  padding: 0 !important; }

.cr-order-script .view-files-component {
  margin-top: 21px; }

.cr-order-script__upload-files {
  height: 48px;
  background: #eff3f6;
  border: 1px dashed #829fb1;
  box-sizing: border-box;
  border-radius: 6px; }

.cr-order-script .cr-handle-assets, .cr-order-script__creative-upload-files {
  width: 534px; }

.cr-order-script__col-field {
  margin-bottom: 30px !important; }

.cr-order-script__col-field-bookend .cr-order-script__script-upload-item-top {
  justify-content: space-between !important; }

.cr-order-script__script-upload-item-top {
  max-width: 542px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

.cr-order-script__spot-length {
  margin-bottom: 10px; }
  .cr-order-script__spot-length span {
    color: #829fb1; }
  .cr-order-script__spot-length strong {
    color: #000000; }

.cr-order-script .cr-textarea-evaluator__inner-title edit {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #334856; }

.cr-order-script__horizontal-line {
  border-bottom: 1px solid #edf1f6;
  margin-left: -50px;
  margin-right: -50px;
  margin-bottom: 24px; }

.cr-order-script__history-block label.script-history.form-label {
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #334856;
  text-transform: capitalize; }

.cr-order-script__history-block .audit-info {
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  letter-spacing: 0.05em;
  color: #657894; }

.cr-order-script .cr-textarea-evaluator__main-title-wrapper {
  display: none; }

.cr-order-script__switch-final-script {
  margin-bottom: 10px; }

.cr-order-script .cr-file-view {
  max-width: 542px; }

.cr-order-script__script-upload {
  margin-top: 23px;
  margin-bottom: 45px; }
  .cr-order-script__script-upload .drop-area {
    max-width: 542px;
    height: 70px; }

.cr-order-script__script-upload-item {
  margin-bottom: 15px; }
  .cr-order-script__script-upload-item .alert-danger {
    max-width: 542px; }

.cr-order-script__part-upload-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #334856;
  margin-bottom: 2px; }

.cr-order-script__script-upload-feedback {
  max-width: 542px; }
  .cr-order-script__script-upload-feedback .cr-order-script__part-upload-title {
    font-size: 23px;
    line-height: 29px;
    margin-bottom: 10px;
    text-transform: capitalize; }
  .cr-order-script__script-upload-feedback .drop-area {
    height: 70px;
    text-transform: capitalize; }
  .cr-order-script__script-upload-feedback .file-upload-title-container {
    display: none; }

.cr-order-script .audit-info-file {
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  letter-spacing: 0.05em;
  color: #657894;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 7px; }

.cr-order-script .history-col-feedback .cr-file-view__file-name {
  color: #795afa; }

.cr-order-script__script-file-empty {
  height: 50px;
  background-color: #657894;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 370px;
  margin-bottom: 14px; }
  .cr-order-script__script-file-empty span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    text-transform: uppercase; }

.cr-order-script .alert-danger {
  display: inline-block; }

.segment-script-history .section-fields {
  padding-top: 34px; }

.script-review-segment .RichEditor-root {
  clear: both; }

.script-review-segment .cr-textarea-evaluator__wrapper-top.with-inner-title {
  margin-bottom: 15px; }

.script-review-segment .cr-textarea-evaluator__inner-title {
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
  color: #334856; }

.script-review-segment .script-feedback .cr-textarea-evaluator__inner-title {
  font-size: 23px;
  margin-bottom: 15px;
  color: #334856; }

.separate-segment {
  height: 20px;
  width: 100%; }

.voice-script.segment-container .cr-textarea-evaluator__wrapper-top-length.title {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #334856; }

.segment-approve-recording-script .section-fields {
  padding-top: 35px;
  padding-bottom: 30px; }

.segment-approve-recording-script .cr-order-script__col-field {
  margin-bottom: 0 !important; }

.segment-approve-recording-script .cr-order-script__horizontal-line {
  display: none; }

.cr-production-assignments {
  /* margin-top: 30px; */ }
  .cr-production-assignments__box-assignments {
    margin-bottom: 0 !important; }
  .cr-production-assignments__col-field {
    margin-bottom: 34px;
    padding-left: 35px; }
  .cr-production-assignments__col-view-field {
    margin-bottom: 48px !important; }
  .cr-production-assignments__section-title {
    margin-bottom: 37px; }
  .cr-production-assignments__order-view-label {
    margin-bottom: 24px;
    color: #43535c !important; }
  .cr-production-assignments__order-view-value {
    font-size: 16px;
    line-height: 20px;
    color: #334856; }

.cr-wideorbit__section-title, .cr-wideorbit__table-header, .cr-wideorbit__section-fields {
  padding-left: 47px;
  padding-right: 47px; }

.cr-wideorbit__section-fields:not(.api-config) {
  /* max-height: calc(100vh - 400px);
      overflow-y: auto; */ }

.cr-wideorbit__table-header {
  display: flex;
  align-items: center;
  background-color: #657894;
  height: 55px;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff; }

.cr-wideorbit__table-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  line-height: 38px; }
  .cr-wideorbit__table-row .market-title {
    color: #334856;
    font-size: 24px; }
  .cr-wideorbit__table-row .muted-info {
    color: #657894;
    font-size: 16px; }
  .cr-wideorbit__table-row .action-item {
    display: flex;
    align-items: center;
    margin-right: 2%; }
  .cr-wideorbit__table-row .bw {
    word-break: break-word; }

.cr-wideorbit__input {
  border-color: #C2D4E0;
  height: 44px; }
  .cr-wideorbit__input:not(.left-rounded) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .cr-wideorbit__input:not(.right-rounded) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right-width: 0; }

.cr-wideorbit__label {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #43535C; }

.cr-wideorbit__mb-10 {
  margin-bottom: 10px; }

.cr-wideorbit__mb-20 {
  margin-bottom: 20px; }

.cr-wideorbit__mt-10 {
  margin-top: 10px; }

.cr-wideorbit__padl-0 {
  padding-left: 0; }
  .cr-wideorbit__padl-0 .cr-textinput__input-wrapper {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }

.cr-wideorbit__padr-0 {
  padding-right: 0; }
  .cr-wideorbit__padr-0 .cr-textinput__input-wrapper {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none; }

.cr-wideorbit__last-form-group {
  margin-bottom: 0px; }
  .cr-wideorbit__last-form-group .cr-textinput__input-field input:hover {
    border-color: initial;
    box-shadow: none;
    outline: none; }
  .cr-wideorbit__last-form-group .cr-textinput__input-field input:focus {
    border-color: initial;
    box-shadow: none;
    outline: none; }
  .cr-wideorbit__last-form-group .cr-textinput__input-field input:disabled {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; }

.cr-wideorbit__add-button-container {
  display: flex;
  justify-content: flex-end; }

.cr-wideorbit__add-button {
  text-transform: none;
  color: #4324c7;
  border: 1px solid #4324c7;
  background: #fff !important; }
  .cr-wideorbit__add-button:active {
    color: #4324c7 !important;
    border: 1px solid #4324c7;
    background: #fff !important; }

.cr-wideorbit__add-icon {
  margin-right: 8px; }

.cr-wideorbit__no-material-group {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 20px;
  padding: 20px;
  padding-top: 10px; }

.cr-wideorbit__save-button.btn {
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 140px !important; }

.cr-wideorbit__save-button:focus {
  box-shadow: none; }

.cr-wideorbit__save-button:active {
  box-shadow: none; }

.cr-wideorbit__save-button.complete {
  z-index: 1;
  background: #73A703;
  border-color: #73A703; }

.cr-wideorbit__save-button.error {
  z-index: 1;
  background: #E40061;
  border-color: #E40061; }

.cr-wideorbit__save-button .success-text {
  margin-left: 10px; }

.cr-wideorbit__ftp-connection-progress {
  height: 44px;
  position: absolute;
  background: #73A703;
  border-radius: 25px 0px 0px 25px;
  z-index: 1; }
  .cr-wideorbit__ftp-connection-progress.complete {
    border-radius: 25px;
    z-index: -1; }

.cr-wideorbit__header {
  font-weight: bold;
  font-size: 23px;
  line-height: 29px;
  color: #000000;
  margin-bottom: 20px; }

.cr-wideorbit__info-req-container {
  padding: 0px; }

.cr-wideorbit__delete-button {
  position: absolute;
  right: 40px;
  bottom: 15px;
  cursor: pointer; }
  .cr-wideorbit__delete-button.error-row {
    bottom: 44px; }

.cr-wideorbit__reset-button {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #795AFA;
  margin-left: 12px;
  text-transform: none;
  position: absolute;
  right: 40px;
  bottom: 20px;
  cursor: pointer; }
  .cr-wideorbit__reset-button.error-row {
    bottom: 50px; }

@media (max-width: 1780px) {
  .cr-wideorbit__save-button.btn {
    min-width: 110px !important; } }

.cr-market-list__head-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px; }
  .cr-market-list__head-wrapper input[name="q"]::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #829fb1;
    opacity: 1;
    /* Firefox */ }
  .cr-market-list__head-wrapper input[name="q"]::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #829fb1;
    opacity: 1;
    /* Firefox */ }
  .cr-market-list__head-wrapper input[name="q"]::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #829fb1; }
  .cr-market-list__head-wrapper input[name="q"]::-ms-input-placeholder {
    /* Microsoft Edge  */
    color: #829fb1; }

.cr-market-list__button-create {
  display: flex;
  align-items: center;
  height: 49px; }
  .cr-market-list__button-create span {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 23px;
    color: #374c59;
    text-transform: initial;
    margin-left: 10px; }

.cr-market-list .btn {
  background-color: transparent; }

.cr-market-list .cr-textinput__input-field input:focus {
  box-shadow: none; }

.cr-market-list .ReactTable {
  border: none; }
  .cr-market-list .ReactTable .rt-table {
    overflow: hidden; }
    .cr-market-list .ReactTable .rt-table .rt-thead .rt-th {
      border: none;
      text-align: left;
      padding: 5px 30px; }
      .cr-market-list .ReactTable .rt-table .rt-thead .rt-th span {
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 21px;
        color: #ffffff; }

.cr-market-list .Table__rppContainer {
  min-width: 78px; }
  .cr-market-list .Table__rppContainer .react-select__control {
    min-height: 50px !important;
    border-color: #c2d4e0 !important;
    margin-top: -10px; }
  .cr-market-list .Table__rppContainer .react-select__single-value {
    color: #000000; }
  .cr-market-list .Table__rppContainer .react-select__indicator-separator {
    width: 0px; }
  .cr-market-list .Table__rppContainer .react-select__dropdown-indicator svg {
    fill: #373a3c; }

.cr-market-list .card-body {
  padding-left: 0;
  padding-right: 0; }

.cr-market-list .rt-thead {
  background: #224b8b;
  height: 55px; }
  .cr-market-list .rt-thead .rt-th:last-child {
    flex: 14 0 auto !important; }

.cr-market-list .rt-resizable-header {
  border-right-color: transparent !important; }

.cr-market-list .rt-resizable-header-content {
  padding-left: 25px; }

.cr-market-list .rt-resizable-header {
  padding-left: 30px; }

.cr-market-list .rt-tbody .rt-td {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 20px;
  border-right: none; }

.cr-market-list .rt-tbody .rt-tr .rt-td:first-child {
  padding-left: 30px; }

.cr-market-list .rt-tbody .rt-tr .rt-td:last-child {
  flex: 17 0 auto !important; }

.cr-market-list__table-head-title {
  line-height: 46px; }

.cr-market-list__table-head-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px; }

.cr-market-list__table-head-title-last {
  text-align: center;
  padding-right: 1rem; }

.cr-market-list .Table__pagination {
  border-top: 1px solid #829fb1;
  padding-top: 25px;
  padding-bottom: 0; }
  .cr-market-list .Table__pagination .Table__pageButton--active {
    background: none;
    color: #795afa; }

.cr-market-list__hub-name {
  display: block; }

.cr-market-list__hub-type {
  display: block;
  font-size: 14px;
  font-weight: 400; }

.cr-market-list__head-wrapper .cr-textinput__input-wrapper {
  background: #ffffff;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 50px;
  height: 49px;
  width: 395px;
  overflow: hidden; }
  .cr-market-list__head-wrapper .cr-textinput__input-wrapper:hover {
    border-color: #795afa; }
  .cr-market-list__head-wrapper .cr-textinput__input-wrapper input {
    height: 100%; }
    .cr-market-list__head-wrapper .cr-textinput__input-wrapper input:hover {
      border-color: transparent !important;
      box-shadow: none !important; }

.cr-market-list__head-wrapper .cr-textinput {
  flex: 1 1;
  display: inline-flex;
  justify-content: flex-end; }

.ReactTable .rt-thead {
  box-shadow: none !important; }
  .ReactTable .rt-thead .rt-th {
    box-shadow: none !important; }

.ReactTable .rt-thead div.sortable span:before {
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 18px;
  left: 136px;
  height: 10px;
  width: 14px;
  color: transparent;
  content: ".";
  background-size: 16px 18px;
  background-repeat: no-repeat;
  background-image: url(https://promosuiteplus.net/static/media/sort-up.7fcaf106.svg); }

.ReactTable .rt-thead div.sortable span:after {
  position: absolute;
  right: 0px;
  top: 23px;
  left: 139px;
  height: 15px;
  width: 9px;
  z-index: 120;
  color: transparent;
  content: ".";
  background-size: 11px 19px;
  background-image: url(https://promosuiteplus.net/static/media/sort-down.830a0dce.svg); }

.ReactTable .rt-th.-sort-asc div.sortable span:after {
  display: none; }

.ReactTable .rt-th.-sort-desc div.sortable span:before {
  display: none; }

@media (min-width: 576px) {
  .modal-dialog.cr-stations-view-modal {
    max-width: 508px;
    margin: 5.75rem auto;
    max-height: 478px; } }

.cr-stations-view-modal .modal-header {
  padding: 6px 20px;
  justify-content: center;
  align-items: center;
  border-bottom: none;
  border-bottom: 1px solid #dfe8ee; }
  .cr-stations-view-modal .modal-header .modal-title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #829fb1;
    text-transform: uppercase; }
  .cr-stations-view-modal .modal-header .close {
    font-size: 30px;
    font-weight: 900;
    color: #c2d4e0;
    outline: none; }
    .cr-stations-view-modal .modal-header .close:hover {
      color: #c2d4e0; }

.cr-stations-view-modal .modal-body.stations-list {
  padding: 0px;
  overflow-y: auto;
  max-height: 436px; }
  .cr-stations-view-modal .modal-body.stations-list .ReactTable {
    border: none; }
    .cr-stations-view-modal .modal-body.stations-list .ReactTable .rt-tbody .rt-tr-group {
      border-bottom: 1px solid #dfe8ee; }
      .cr-stations-view-modal .modal-body.stations-list .ReactTable .rt-tbody .rt-tr-group .rt-td {
        border: none;
        padding: 2px 20px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 39px;
        color: #111b2b;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block !important; }
    .cr-stations-view-modal .modal-body.stations-list .ReactTable .no-stations-selected {
      padding: 6px 20px;
      text-align: center; }
  .cr-stations-view-modal .modal-body.stations-list .list-group-item {
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #e40061; }
    .cr-stations-view-modal .modal-body.stations-list .list-group-item .warning-container {
      display: flex; }
      .cr-stations-view-modal .modal-body.stations-list .list-group-item .warning-container .warning-ico {
        margin-top: 4px;
        margin-right: 10px; }

.cr-create-team-view .close {
  display: none; }

.cr-create-team-view__form-container {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  overflow: hidden; }

.cr-create-team-view__title {
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 29px;
  color: #111b2b;
  padding: 43px 39px 24px 39px;
  border-bottom: 1px solid #edf1f6;
  margin-bottom: 0px; }

.cr-create-team-view__content-view {
  padding: 38px 39px; }
  .cr-create-team-view__content-view .row {
    margin-bottom: 20px; }
  .cr-create-team-view__content-view .info {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #111b2b;
    padding: 0px 7px; }
  .cr-create-team-view__content-view .form-group label {
    text-transform: uppercase !important;
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #43535c; }
  .cr-create-team-view__content-view .form-group input {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000000; }
  .cr-create-team-view__content-view .add-stations-btn {
    position: absolute;
    left: -24px;
    height: 611px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .cr-create-team-view__content-view .tab-pane-separator {
    height: 17px;
    position: absolute;
    width: 86%;
    z-index: 10;
    background: #fff; }

.cr-create-team-view__col1 {
  padding-right: 40px; }

.cr-create-team-view__col2 {
  padding-left: 40px; }

.cr-create-team-view__nav-item {
  margin-bottom: -5px !important; }

.cr-create-team-view__nav-link {
  font-size: 12px !important;
  text-align: center;
  display: inline-block !important;
  padding: 12px 26px !important;
  color: #795afa !important;
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
  border: none !important;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  line-height: 15px;
  font-weight: normal; }
  .cr-create-team-view__nav-link:hover {
    color: #43535c !important; }
  .cr-create-team-view__nav-link.active {
    color: #43535c !important;
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.102);
    font-weight: normal !important; }

.cr-create-team-view__tab-content {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  overflow: hidden;
  padding: 36px 30px;
  height: 573px; }

.cr-create-team-view__tags-container {
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 6px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 501px; }
  .cr-create-team-view__tags-container .list-group-item {
    background: none;
    border: none;
    margin: 0px;
    padding: 0; }
    .cr-create-team-view__tags-container .list-group-item .tag-line-item {
      background: #f7fbfd;
      border: none;
      border-bottom: 1px solid #dfe8ee;
      margin: 0px;
      padding: 12px 16px;
      display: flex;
      align-items: center; }
      .cr-create-team-view__tags-container .list-group-item .tag-line-item .cr-checkbox__text {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.05em;
        color: #829fb1;
        text-transform: uppercase;
        margin-left: 15px; }
      .cr-create-team-view__tags-container .list-group-item .tag-line-item .btn.expand-btn {
        padding: 0 !important;
        min-width: unset;
        display: flex;
        align-items: flex-end;
        justify-content: right;
        margin: 0;
        float: right;
        height: 20px;
        line-height: 20px; }
    .cr-create-team-view__tags-container .list-group-item:last-child .tag-line-item:not(.open) {
      border-bottom: none; }
    .cr-create-team-view__tags-container .list-group-item:last-child .stations-selection-list .list-group-item:last-child .station-line-item {
      border-bottom: none; }
    .cr-create-team-view__tags-container .list-group-item .stations-selection-list .list-group-item .station-line-item {
      background: #ffffff;
      border: none;
      border-bottom: 1px solid #dfe8ee;
      margin: 0px;
      padding: 2px 16px;
      display: flex;
      align-items: center; }
      .cr-create-team-view__tags-container .list-group-item .stations-selection-list .list-group-item .station-line-item .cr-checkbox__text {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #334856;
        text-transform: uppercase;
        margin-left: 15px; }
      .cr-create-team-view__tags-container .list-group-item .stations-selection-list .list-group-item .station-line-item .station-name {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 36px;
        color: #334856;
        width: 100%; }
      .cr-create-team-view__tags-container .list-group-item .stations-selection-list .list-group-item .station-line-item .col-6 {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 39px;
        color: #334856; }
    .cr-create-team-view__tags-container .list-group-item .cr-checkbox {
      width: 100%; }
      .cr-create-team-view__tags-container .list-group-item .cr-checkbox .rc-checkbox-inner {
        width: 21px;
        height: 21px; }
        .cr-create-team-view__tags-container .list-group-item .cr-checkbox .rc-checkbox-inner:after {
          left: 6px;
          top: 2px; }
      .cr-create-team-view__tags-container .list-group-item .cr-checkbox .rc-checkbox-disabled .rc-checkbox-inner {
        background: #c2d4e0;
        border-color: #c2d4e0; }
        .cr-create-team-view__tags-container .list-group-item .cr-checkbox .rc-checkbox-disabled .rc-checkbox-inner:after {
          width: 7px;
          height: 13px;
          border: 3px solid #ffffff;
          border-top: 0;
          border-left: 0; }
      .cr-create-team-view__tags-container .list-group-item .cr-checkbox .cr-checkbox__checked-icon {
        left: 4px;
        top: 2px; }
    .cr-create-team-view__tags-container .list-group-item .cr-checkbox__checked-icon {
      left: 4px;
      top: 2px; }

.cr-create-team-view__stations-container {
  height: 611px;
  border: 1px solid #c2d4e0;
  border-radius: 6px; }
  .cr-create-team-view__stations-container .table-container {
    height: 524px; }
    .cr-create-team-view__stations-container .table-container .ReactTable {
      border: none;
      height: 100%; }
      .cr-create-team-view__stations-container .table-container .ReactTable .rt-thead.-header {
        background: #f7fbfd;
        border-radius: 6px 6px;
        box-shadow: none;
        flex: unset; }
        .cr-create-team-view__stations-container .table-container .ReactTable .rt-thead.-header .rt-th {
          border: none;
          border-bottom: 1px solid #c2d4e0;
          justify-content: left;
          display: flex;
          padding: 5px 20px; }
          .cr-create-team-view__stations-container .table-container .ReactTable .rt-thead.-header .rt-th .header {
            font-style: normal;
            line-height: 18px;
            letter-spacing: 0.05em;
            padding: 6px 0px; }
          .cr-create-team-view__stations-container .table-container .ReactTable .rt-thead.-header .rt-th .header:not(.last) {
            font-weight: normal;
            color: #829fb1;
            font-size: 12px;
            text-transform: uppercase; }
          .cr-create-team-view__stations-container .table-container .ReactTable .rt-thead.-header .rt-th .header.last {
            font-weight: bold;
            font-size: 14px;
            text-align: right;
            color: #111b2b;
            text-transform: lowercase; }
          .cr-create-team-view__stations-container .table-container .ReactTable .rt-thead.-header .rt-th:last-child {
            justify-content: center !important; }
      .cr-create-team-view__stations-container .table-container .ReactTable .rt-tbody {
        max-height: 483px;
        flex: unset; }
        .cr-create-team-view__stations-container .table-container .ReactTable .rt-tbody .rt-tr-group {
          border: none; }
          .cr-create-team-view__stations-container .table-container .ReactTable .rt-tbody .rt-tr-group .rt-tr {
            border-bottom: 1px solid #dfe8ee; }
            .cr-create-team-view__stations-container .table-container .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td {
              border-right: none;
              padding: 8px 22px;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 26px;
              display: inline-block !important;
              color: #111b2b; }
              .cr-create-team-view__stations-container .table-container .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td .trash-icon {
                padding: 0 !important;
                height: inherit;
                line-height: inherit;
                min-width: 100%;
                display: flex;
                justify-content: center; }
  .cr-create-team-view__stations-container .buttons-container {
    padding: 20px 27px;
    background: #f7fbfd !important;
    border-top: 1px solid #c2d4e0;
    border-radius: 0px 0px 6px 6px; }
    .cr-create-team-view__stations-container .buttons-container button[type="submit"] {
      margin-right: 15px;
      width: 192.37px;
      height: 44px; }
    .cr-create-team-view__stations-container .buttons-container .btn-blue:disabled,
    .cr-create-team-view__stations-container .buttons-container .btn-blue:disabled:hover {
      background: #c2d4e0 !important;
      border-color: #c2d4e0;
      opacity: 1; }
    .cr-create-team-view__stations-container .buttons-container button.cr-create-team-view__button-clear,
    .cr-create-team-view__stations-container .buttons-container button.cr-create-team-view__button-cancel {
      width: 136px;
      height: 44px; }
    .cr-create-team-view__stations-container .buttons-container button.cr-create-team-view__button-clear {
      margin-right: 15px; }
  .cr-create-team-view__stations-container .no-stations-selected {
    display: block;
    position: absolute;
    background: none;
    transition: all 0.3s ease;
    z-index: 1;
    pointer-events: none;
    padding: 20px;
    min-height: 565px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-style: normal;
    font-weight: 800;
    font-size: 23px;
    line-height: 29px;
    text-align: center;
    color: #111b2b;
    white-space: break-spaces;
    word-break: break-word; }
    .cr-create-team-view__stations-container .no-stations-selected span {
      max-width: 340px; }

.cr-create-team-view__search-container .search-header h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #334856;
  margin-bottom: 10px; }

.cr-create-team-view__search-container .search-header .cr-textinput__input-wrapper {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 50px; }
  .cr-create-team-view__search-container .search-header .cr-textinput__input-wrapper .cr-textinput__input-field input {
    padding: 0.375rem 1.75rem;
    border-radius: 50px;
    color: #000000; }
    .cr-create-team-view__search-container .search-header .cr-textinput__input-wrapper .cr-textinput__input-field input:hover, .cr-create-team-view__search-container .search-header .cr-textinput__input-wrapper .cr-textinput__input-field input:focus {
      border: none;
      box-shadow: none; }
  .cr-create-team-view__search-container .search-header .cr-textinput__input-wrapper .cr-textinput__input-field input::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 14px;
    color: #829fb1;
    opacity: 1;
    /* Firefox */ }
  .cr-create-team-view__search-container .search-header .cr-textinput__input-wrapper .cr-textinput__input-field input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 14px;
    color: #829fb1;
    opacity: 1;
    /* Firefox */ }
  .cr-create-team-view__search-container .search-header .cr-textinput__input-wrapper .cr-textinput__input-field input::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 14px;
    color: #829fb1; }
  .cr-create-team-view__search-container .search-header .cr-textinput__input-wrapper .cr-textinput__input-field input::-ms-input-placeholder {
    /* Microsoft Edge  */
    font-size: 14px;
    color: #829fb1; }
  .cr-create-team-view__search-container .search-header .cr-textinput__input-wrapper .cr-textinput__right-component .btn-search {
    text-align: right;
    min-width: unset; }

.cr-create-team-view__search-container .search-results {
  margin-top: 30px; }
  .cr-create-team-view__search-container .search-results h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #334856; }
  .cr-create-team-view__search-container .search-results .table-container {
    border-radius: 6px;
    border: 1px solid #c2d4e0;
    min-height: 68px;
    max-height: 350px;
    overflow-y: auto; }
    .cr-create-team-view__search-container .search-results .table-container .ReactTable {
      border: none;
      height: 350px; }
      .cr-create-team-view__search-container .search-results .table-container .ReactTable .rt-table {
        height: 100%; }
      .cr-create-team-view__search-container .search-results .table-container .ReactTable .rt-thead {
        background: #f7fbfd;
        border-radius: 6px 6px 0px 0px;
        box-shadow: none; }
        .cr-create-team-view__search-container .search-results .table-container .ReactTable .rt-thead .rt-th {
          border: none;
          border-bottom: 1px solid #c2d4e0;
          justify-content: left;
          display: flex;
          padding: 14px 6px; }
          .cr-create-team-view__search-container .search-results .table-container .ReactTable .rt-thead .rt-th .header {
            font-weight: normal;
            color: #829fb1;
            font-size: 12px;
            text-transform: uppercase; }
          .cr-create-team-view__search-container .search-results .table-container .ReactTable .rt-thead .rt-th:first-child {
            max-width: 40px; }
      .cr-create-team-view__search-container .search-results .table-container .ReactTable .rt-tr .rt-td {
        border-right: none;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block !important;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 30px;
        color: #334856; }
        .cr-create-team-view__search-container .search-results .table-container .ReactTable .rt-tr .rt-td:first-child {
          padding-left: 12px; }
        .cr-create-team-view__search-container .search-results .table-container .ReactTable .rt-tr .rt-td .cr-checkbox__checkbox {
          display: block; }
        .cr-create-team-view__search-container .search-results .table-container .ReactTable .rt-tr .rt-td .rc-checkbox-inner {
          width: 21px;
          height: 21px; }
          .cr-create-team-view__search-container .search-results .table-container .ReactTable .rt-tr .rt-td .rc-checkbox-inner:after {
            left: 6px;
            top: 1px; }
        .cr-create-team-view__search-container .search-results .table-container .ReactTable .rt-tr .rt-td .rc-checkbox-disabled .rc-checkbox-inner {
          background: #c2d4e0;
          border-color: #c2d4e0; }
          .cr-create-team-view__search-container .search-results .table-container .ReactTable .rt-tr .rt-td .rc-checkbox-disabled .rc-checkbox-inner:after {
            width: 7px;
            height: 13px;
            border: 3px solid #ffffff;
            border-top: 0;
            border-left: 0; }
        .cr-create-team-view__search-container .search-results .table-container .ReactTable .rt-tr .rt-td .cr-checkbox__checked-icon {
          left: 4px;
          top: 2px; }
      .cr-create-team-view__search-container .search-results .table-container .ReactTable .no-results {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #829fb1;
        display: flex;
        align-items: center;
        padding: 35px; }

.cr-create-team-view .btn-blue.btn-primary {
  background: #795afa !important;
  border-color: #5129f9; }
  .cr-create-team-view .btn-blue.btn-primary:hover {
    background: #795afa !important;
    border-color: #5129f9; }

.cr-create-team-view__form-buttons {
  display: flex; }

@media screen and (min-width: 1200px) and (max-width: 1625px) {
  .cr-create-team-view__nav-link {
    letter-spacing: 0em;
    padding: 12px 12px !important; }
  .cr-create-team-view__stations-container .buttons-container {
    padding: 20px 28px; }
    .cr-create-team-view__stations-container .buttons-container button {
      min-width: 108px !important;
      width: 30% !important; }
    .cr-create-team-view__stations-container .buttons-container button[type="submit"] {
      width: 31% !important; } }

.modal-dialog.cr-create-team-view {
  min-width: 1307px;
  min-height: 884px; }
  .modal-dialog.cr-create-team-view .modal-header {
    padding: 24px 42px; }
    .modal-dialog.cr-create-team-view .modal-header .modal-title {
      font-style: normal;
      font-weight: 800;
      font-size: 23px;
      line-height: 29px;
      color: #111b2b; }
  .modal-dialog.cr-create-team-view .entities-picker.modal-body .cr-create-team-view__form-container {
    box-shadow: none; }
    .modal-dialog.cr-create-team-view .entities-picker.modal-body .cr-create-team-view__form-container .cr-create-team-view__content-view {
      padding: 4px 24px; }
      .modal-dialog.cr-create-team-view .entities-picker.modal-body .cr-create-team-view__form-container .cr-create-team-view__content-view .info {
        padding: 10px 0px; }
  .modal-dialog.cr-create-team-view .audience-picker.modal-body .cr-create-team-view__form-container {
    box-shadow: none; }
    .modal-dialog.cr-create-team-view .audience-picker.modal-body .cr-create-team-view__form-container .cr-create-team-view__content-view {
      padding: 4px 24px; }
      .modal-dialog.cr-create-team-view .audience-picker.modal-body .cr-create-team-view__form-container .cr-create-team-view__content-view .info {
        padding: 0px; }
        .modal-dialog.cr-create-team-view .audience-picker.modal-body .cr-create-team-view__form-container .cr-create-team-view__content-view .info .create-group-checkbox-container {
          padding-top: 20px; }
          .modal-dialog.cr-create-team-view .audience-picker.modal-body .cr-create-team-view__form-container .cr-create-team-view__content-view .info .create-group-checkbox-container .cr-checkbox__checked-icon {
            top: 4px; }
          .modal-dialog.cr-create-team-view .audience-picker.modal-body .cr-create-team-view__form-container .cr-create-team-view__content-view .info .create-group-checkbox-container .cr-checkbox__text {
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            color: #000000; }

@media screen and (min-width: 1360px) and (max-width: 1625px) {
  .modal-dialog.cr-create-team-view .cr-create-team-view__nav-link {
    letter-spacing: 0em;
    padding: 12px 26px !important; }
  .modal-dialog.cr-create-team-view .cr-create-team-view__stations-container .buttons-container {
    padding: 20px 33px; }
    .modal-dialog.cr-create-team-view .cr-create-team-view__stations-container .buttons-container button {
      min-width: 136px !important;
      width: 136px !important; }
    .modal-dialog.cr-create-team-view .cr-create-team-view__stations-container .buttons-container button[type="submit"] {
      width: 180px !important; } }

.production-provided-recording {
  box-shadow: none !important;
  background-color: transparent !important;
  border-radius: 0 !important; }
  .production-provided-recording > div {
    padding: 0; }
  .production-provided-recording .col {
    margin-bottom: 0; }
  .production-provided-recording .tag-instructions {
    font-size: 26px;
    line-height: 33px; }

.production-provided-recording-bottom-buttons {
  margin-left: 49px; }

.cr-production-recording__spot-info {
  margin-bottom: 24px; }

.cr-production-recording__section-fields {
  padding-top: 36px; }

.cr-production-recording__spot-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #334856;
  margin-bottom: 0; }

.cr-production-recording__spot-title-field {
  margin-bottom: 31.43px !important; }

.cr-production-recording__label {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #657894;
  margin-bottom: 10px; }

.cr-production-recording__field-value {
  font-size: 16px;
  line-height: 20px;
  color: #334856; }

.cr-production-recording__title-field {
  margin-bottom: 38px !important; }

.cr-production-recording__spot-length-field {
  margin-bottom: 34px !important; }

.cr-production-recording__tag-field {
  margin-bottom: 60px !important; }

.cr-production-recording .upload-file .col-12 {
  margin-bottom: 0; }

.cr-production-recording__recoding-field {
  margin-bottom: 32px !important; }

.cr-production-recording__recoding-field .player-ui-wrapper,
.cr-production-recording__recoding-field .files-wrapper,
.cr-production-recording__recoding-field .drop-area {
  position: relative; }

.cr-production-recording__recoding-field .player-container-ui {
  height: 50px !important; }
  .cr-production-recording__recoding-field .player-container-ui .player-timer {
    width: 135px; }

.cr-production-recording__final-recording-fields-empty {
  padding-top: 20px !important;
  padding-bottom: 38px !important; }

.cr-production-recording__final-recording-fields {
  padding-top: 29px;
  padding-bottom: 50px; }

.cr-production-recording__spot-final-recording {
  margin-bottom: 28px;
  /* .files-wrapper {
      max-width: 419px;
    }
    .upload-view-file {
      > div {
        max-width: 419px;
      }
    } */ }
  .cr-production-recording__spot-final-recording .drop-area {
    background: #eff3f6;
    border: 1px dashed #829fb1;
    box-sizing: border-box;
    border-radius: 6px;
    height: 91px; }

.cr-production-recording .player-container {
  margin-bottom: 0; }
  .cr-production-recording .player-container .player-wrapper {
    margin-bottom: 0; }

.cr-production-recording__recording-helper-text {
  text-transform: capitalize; }

.cr-production-recording__spot-final-recording-last {
  margin-bottom: 0 !important; }

.cr-production-recording__spot-final-recording-field .alert-danger {
  margin-top: 8px; }

.cr-production-recording__spot-final-recording-field .delete-icon {
  top: calc(50% - 5px); }

.cr-production-recording__spot-title {
  font-weight: 400;
  font-size: 23px; }

.cr-production-recording__spot-production {
  background: #657894;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  text-transform: uppercase; }

.cr-rotation-chart {
  display: inline-block; }
  .cr-rotation-chart__head-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #829fb1;
    text-transform: uppercase;
    min-width: 62px; }
  .cr-rotation-chart__table {
    box-sizing: border-box;
    border-radius: 6px;
    border-collapse: collapse;
    border-style: hidden;
    box-shadow: 0 0 0 1px #c2d4e0;
    overflow: hidden; }
  .cr-rotation-chart__thead {
    background-color: #f7fbfd; }
  .cr-rotation-chart__td {
    flex: 1 1;
    padding-top: 5px;
    padding-bottom: 5px;
    min-height: 43px; }
  .cr-rotation-chart__th {
    height: 43px;
    line-height: 42px;
    text-align: center;
    flex: 1 1; }
  .cr-rotation-chart__tr {
    display: flex; }
  .cr-rotation-chart__first-head {
    width: 130px;
    border-right: 1px solid #c2d4e0;
    padding-left: 15px;
    padding-right: 15px;
    text-align: left;
    flex: unset;
    box-sizing: border-box; }
  .cr-rotation-chart__station-name {
    width: 130px;
    font-weight: bold;
    font-size: 14px;
    color: #111b2b;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    flex: unset;
    border-right: 1px solid #c2d4e0; }
  .cr-rotation-chart__rotation-input {
    max-width: 59px;
    border: 1px solid #829fb1;
    height: 100%;
    font-size: 14px;
    text-align: center;
    color: #829fb1;
    height: 31px;
    border-radius: 4px;
    outline: none; }
  .cr-rotation-chart__rotation-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: #829fb1; }
  .cr-rotation-chart__rotation-continuity-text {
    color: #111b2b; }
  .cr-rotation-chart__rotation-error .cr-rotation-chart__rotation-input {
    color: #ff006c; }
  .cr-rotation-chart__rotation-value {
    padding: 0 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-rotation-chart__field-label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #334856 !important;
    text-transform: inherit !important;
    margin-bottom: 0; }
  .cr-rotation-chart__table-view-mode {
    margin-bottom: 32px; }
  .cr-rotation-chart .alert-danger {
    margin-top: 5px;
    display: inline-block; }
  .cr-rotation-chart__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; }
  .cr-rotation-chart__adjust-rotations {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #795afa;
    cursor: pointer; }
  @media (max-width: 1200px) {
    .cr-rotation-chart .cr-rotation-chart__td,
    .cr-rotation-chart .cr-rotation-chart__first-head {
      padding-left: 5px !important;
      padding-right: 5px !important; } }

.cr-alert-not-allowed {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center; }
  .cr-alert-not-allowed__title {
    font-size: 30px;
    font-weight: bold;
    color: #657894;
    text-align: center;
    margin-bottom: 0; }

.cr-view-team-view__name-container {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  overflow: hidden;
  height: 120px;
  display: flex;
  align-items: center;
  padding: 60px 30px; }
  .cr-view-team-view__name-container .team-name {
    font-style: normal;
    font-weight: 800;
    font-size: 23px;
    line-height: 29px;
    color: #111b2b; }

.cr-view-team-view__tabs-container {
  margin-top: 35px; }
  .cr-view-team-view__tabs-container .tab-pane-separator {
    height: 17px;
    position: absolute;
    min-width: 70%;
    z-index: 10;
    background: #fff; }

.cr-view-team-view__nav-item {
  margin-bottom: -5px !important; }

.cr-view-team-view__nav-link {
  text-align: center;
  display: inline-block !important;
  padding: 12px 26px !important;
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
  border: none !important;
  letter-spacing: 0.05em;
  font-style: normal;
  font-weight: 800 !important;
  font-size: 18px !important;
  line-height: 23px !important;
  color: #657894 !important; }
  .cr-view-team-view__nav-link:hover {
    color: #334856 !important; }
  .cr-view-team-view__nav-link.active {
    color: #334856 !important;
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.102); }

.cr-view-team-view__teaminfo-container .team-info-container {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  padding: 28px 0;
  overflow: hidden; }
  .cr-view-team-view__teaminfo-container .team-info-container .team-detail-row {
    padding: 26px 45px 30px 45px;
    border-bottom: 1px solid #EDF1F6; }
    .cr-view-team-view__teaminfo-container .team-info-container .team-detail-row:last-child {
      border-bottom: none; }
    .cr-view-team-view__teaminfo-container .team-info-container .team-detail-row .label {
      font-style: normal;
      font-weight: 800;
      font-size: 23px;
      line-height: 29px;
      color: #111b2b;
      width: 100%; }
    .cr-view-team-view__teaminfo-container .team-info-container .team-detail-row .data-row {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #334856;
      padding: 6px 0px; }
      .cr-view-team-view__teaminfo-container .team-info-container .team-detail-row .data-row.entities-detail-row {
        font-size: 14px; }
      .cr-view-team-view__teaminfo-container .team-info-container .team-detail-row .data-row .ReactTable {
        border: none; }
        .cr-view-team-view__teaminfo-container .team-info-container .team-detail-row .data-row .ReactTable .rt-tbody .rt-tr-group {
          border-bottom: none; }
          .cr-view-team-view__teaminfo-container .team-info-container .team-detail-row .data-row .ReactTable .rt-tbody .rt-tr-group .rt-td {
            border-right: none; }
            .cr-view-team-view__teaminfo-container .team-info-container .team-detail-row .data-row .ReactTable .rt-tbody .rt-tr-group .rt-td:nth-child(1) {
              min-width: 150px; }
            .cr-view-team-view__teaminfo-container .team-info-container .team-detail-row .data-row .ReactTable .rt-tbody .rt-tr-group .rt-td:nth-child(2) {
              min-width: 250px; }
            .cr-view-team-view__teaminfo-container .team-info-container .team-detail-row .data-row .ReactTable .rt-tbody .rt-tr-group .rt-td:nth-child(3) {
              min-width: 250px; }

.cr-view-team-view__teaminfo-container .view-buttons-container {
  padding: 30px 40px; }
  .cr-view-team-view__teaminfo-container .view-buttons-container button.btn-edit {
    margin-right: 14px; }

.cr-view-team-view__members .tab-pane-separator {
  display: none; }

.cr-order-details-duplicate__title {
  font-weight: 800;
  font-size: 40px;
  text-align: center;
  color: #000000;
  padding-top: 47px;
  padding-bottom: 38px;
  max-width: 400px;
  margin: 0 auto; }

.cr-order-details-duplicate__fields-list {
  width: 350px;
  margin: 0 auto; }

.cr-order-details-duplicate .cr-checkbox {
  margin-bottom: 14.88px; }

.cr-order-details-duplicate__buttons {
  margin-top: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px; }

.cr-order-details-duplicate__button-submit {
  width: 172px !important;
  margin-right: 7.5px;
  border-radius: 25px;
  display: inline-block; }
  .cr-order-details-duplicate__button-submit span {
    text-transform: uppercase; }

.cr-order-details-duplicate__button-cancel {
  width: 157px !important;
  margin-left: 7.5px;
  border-radius: 25px;
  display: inline-block; }
  .cr-order-details-duplicate__button-cancel span {
    text-transform: uppercase; }

.modal-dialog.production-duplicate-modal {
  width: 573px;
  max-width: inherit; }

.cr-list-users .card-body {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0; }

.cr-list-users .ReactTable {
  border: none; }

.cr-list-users__head-admin-list-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 17px;
  margin-bottom: 19px;
  padding-left: 16px;
  padding-right: 16px; }
  .cr-list-users__head-admin-list-wrapper .cr-textinput {
    flex: 1 1;
    display: inline-flex;
    justify-content: flex-end; }

.cr-list-users__btn-invite-user {
  width: 203px;
  height: 49px;
  border-radius: 50px; }
  .cr-list-users__btn-invite-user span {
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff; }

.cr-list-users__btn-new-user {
  background: #ffffff;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 50px;
  width: 227px;
  height: 49px;
  margin-right: 19px; }
  .cr-list-users__btn-new-user span {
    font-weight: 800;
    font-size: 18px;
    line-height: 23px;
    color: #374c59;
    margin-left: 17px; }

.cr-list-users .rt-td {
  padding: 16px 0;
  border-right: none !important;
  cursor: pointer; }

.cr-list-users .rt-tr-group {
  border-bottom-color: #edf1f6; }

.cr-list-users .cr-textinput__input-wrapper {
  background: #ffffff;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 50px;
  height: 49px;
  width: 395px;
  overflow: hidden; }

.cr-list-users__table .rt-thead {
  background: #224b8b; }

.cr-list-users__table .rt-th {
  padding: 17px 0 !important;
  border-right: 0 !important; }
  .cr-list-users__table .rt-th span {
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    color: #ffffff; }

.cr-list-users__table.empty-list .pagination-bottom {
  display: none; }

.cr-list-users__table.empty-list .table-no-data {
  position: relative;
  text-align: center; }

.cr-list-users__th-name {
  padding-left: 43px; }

.cr-list-users__user-name-column {
  padding-left: 43px; }

.cr-list-users .user-name-column {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding-left: 43px; }

.cr-list-users__td-value {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize; }

.cr-list-users__action-button {
  background: #fff;
  border-color: #fff;
  padding-left: 14px !important;
  padding-right: 15px !important; }
  .cr-list-users__action-button:hover, .cr-list-users__action-button:focus, .cr-list-users__action-button:active {
    background: #fff !important;
    border-color: #fff; }

.cr-list-users__th-sort {
  display: flex; }

.cr-list-users__sortable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  text-align: center;
  margin-left: 8px; }

.cr-list-users__sortable-icon {
  position: absolute;
  font-size: 16px;
  color: #fff; }

.cr-list-users__sortable-active {
  color: #c12674; }

.cr-list-users__head-active {
  align-items: center;
  justify-content: center; }

.cr-list-users__checkbox {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 70px; }

.cr-list-users__new-label {
  font-weight: bold;
  font-size: 12px;
  margin-left: 12px;
  text-transform: uppercase;
  color: #73a703; }

.cr-list-users__text-inactive {
  color: #829fb1 !important; }

.cr-list-users__locked_icon {
  margin-left: 10px;
  cursor: pointer; }

.cr-list-users__unlcok_confirm_text {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 16px; }

.cr-list-users__tooltip {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  opacity: 1;
  padding: 0; }
  .cr-list-users__tooltip .rc-tooltip-content {
    background: #ffffff;
    padding: 24px;
    border-radius: 4px; }
  .cr-list-users__tooltip .rc-tooltip-arrow {
    border-top-color: #ffffff;
    border-width: 10px 10px 0;
    bottom: -8px; }
  .cr-list-users__tooltip .rc-tooltip-inner {
    padding: 0;
    background-color: #ffffff;
    box-shadow: none;
    min-height: auto; }

.cr-list-users__out-of-office-table .rt-td {
  cursor: initial;
  display: flex;
  align-items: center;
  padding-right: 16px; }
  .cr-list-users__out-of-office-table .rt-td .market-string {
    cursor: default; }

.cr-user-detail {
  max-width: 1270px; }
  .cr-user-detail__info-profile {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px;
    padding: 25px 36px 35px 36px;
    margin-bottom: 30px;
    max-width: 1270px; }
  .cr-user-detail__user-avatar {
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
    padding: 5.33px;
    display: inline-block;
    border-radius: 100px;
    position: relative; }
    .cr-user-detail__user-avatar .avatar-sort-name-text {
      font-size: 40px; }
  .cr-user-detail__user-profile-left {
    display: flex;
    align-items: center; }
  .cr-user-detail__user-name {
    margin-left: 31px;
    display: inline-block; }
  .cr-user-detail__text-head {
    font-weight: 800;
    font-size: 23px;
    line-height: 29px;
    color: #111b2b;
    text-transform: capitalize; }
  .cr-user-detail__tab-link {
    font-weight: normal;
    font-size: 16px !important;
    line-height: 20px;
    color: #657894 !important;
    min-width: 140px;
    border-top-right-radius: 6px !important;
    border-top-left-radius: 6px !important;
    border: none !important;
    padding: 12px 6px !important;
    justify-content: center;
    text-transform: capitalize; }
  .cr-user-detail__active.nav-link {
    font-weight: bold;
    color: #334856 !important;
    box-shadow: -2px -3px 6px -2px rgba(0, 0, 0, 0.102);
    background-color: #fff;
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .cr-user-detail__nav .nav-item:last-child {
    margin-right: 0; }
  .cr-user-detail .nav-item {
    margin-right: 30px; }
  .cr-user-detail .card {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px; }
  .cr-user-detail__button-cancel {
    border-radius: 24px;
    text-transform: uppercase; }
  .cr-user-detail__edit-icon {
    position: absolute;
    bottom: 0;
    display: inline-block;
    right: 0;
    cursor: pointer; }
  .cr-user-detail__reset-position {
    position: inherit; }

.cr-user-assignments__view-content {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  margin-bottom: 31px; }

.cr-user-assignments__assignment-title {
  font-weight: 800;
  font-size: 23px;
  height: 32px;
  color: #111b2b;
  margin-bottom: 24px;
  text-transform: capitalize; }

.cr-user-assignments__individual-title {
  margin-bottom: 45px; }

.cr-user-assignments__team-assignments {
  padding: 58px 44px 32px 44px; }

.cr-user-assignments__none-text {
  font-size: 16px;
  line-height: 40px;
  color: #334856;
  margin-bottom: 0;
  text-transform: capitalize; }

.cr-user-assignments__item-text {
  font-size: 16px;
  line-height: 40px;
  color: #334856; }

.cr-user-assignments__line {
  width: 100%;
  height: 1px;
  background-color: #edf1f6; }

.cr-user-assignments__col-reset {
  margin-bottom: 0; }

.cr-user-assignments__buttons {
  margin-left: 44px; }
  .cr-user-assignments__buttons .btn-blue {
    margin-right: 14px; }

.cr-user-assignments__small-title {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #43535c;
  margin-bottom: 17px;
  text-transform: uppercase; }

.cr-user-assignments__none-large-text {
  font-size: 20px;
  height: 43px;
  color: #334856;
  margin-bottom: 0; }

.cr-user-assignments__head-form {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.cr-user-assignments__btn-assign {
  width: 220px;
  min-width: auto;
  height: 52px;
  border-radius: 24px; }

.cr-user-assignments__btn-assign {
  display: flex;
  align-items: center;
  justify-content: center; }
  .cr-user-assignments__btn-assign span {
    font-weight: 800;
    font-size: 19px;
    line-height: 24px;
    text-transform: capitalize;
    color: #334856;
    margin-left: 23px; }

.cr-user-assignments__confirmation-modal {
  width: 557px;
  min-width: auto;
  max-width: inherit; }
  .cr-user-assignments__confirmation-modal .modal-header {
    margin-top: 0; }
  .cr-user-assignments__confirmation-modal .modal-title {
    max-width: 480px; }
  .cr-user-assignments__confirmation-modal .modal-footer {
    padding: 0;
    margin-top: 50px; }
  .cr-user-assignments__confirmation-modal .btn-blue {
    margin-right: 10px; }
  .cr-user-assignments__confirmation-modal .btn-outline-blue {
    margin-left: 10px; }

.cr-user-assignments__item-selected {
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 4px;
  height: 42px;
  padding: 0 15px;
  margin: 8px 8px 8px 0;
  display: inline-flex;
  align-items: center;
  justify-content: center; }

.cr-user-assignments__item-selected-label {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #657894;
  margin: 0 18px; }

.cr-user-assignments__pointer {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

.cr-user-assignments__role-name {
  font-weight: 600;
  font-size: 20px;
  line-height: 55px;
  color: #334856; }

.cr-user-assignments__station-name {
  text-transform: uppercase; }

.cr-user-assignments__switch-label {
  font-size: 17px;
  line-height: 21px;
  color: #829fb1;
  text-transform: uppercase;
  font-weight: normal; }

.cr-user-assignments__switch-label-active {
  color: #374c59;
  font-weight: bold; }

.cr-user-assignments__switch-apply {
  margin-top: 45px;
  margin-bottom: 49px; }

.cr-user-assignments__role-station-name {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #111b2b;
  margin-bottom: 4px;
  text-transform: uppercase; }

.cr-user-assignments__select-roles {
  margin-bottom: 40px; }

.cr-user-assignments__manage-privileges {
  font-size: 16px;
  line-height: 20px;
  color: #795afa;
  margin-top: 15px;
  cursor: pointer; }

.cr-user-assignments__entity-label-view {
  font-size: 14px;
  line-height: 39px;
  color: #111b2b;
  text-transform: capitalize; }

.cr-user-assignments__no-margin {
  margin: 0 !important; }

.cr-user-assignments__shows-assignments {
  padding: 58px 44px 32px 44px; }

.cr-user-assignments__show-stations {
  padding-bottom: 35px; }

.cr-user-assignments__show-station-name {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #111b2b;
  margin-bottom: 6px;
  text-transform: uppercase;
  display: block; }

.cr-user-assignments__show-station-selected {
  margin-bottom: 46px; }
  .cr-user-assignments__show-station-selected:last-child {
    margin-bottom: 0; }

.cr-user-assignments__conflict-stations-modal {
  max-width: 600px; }

.cr-user-assignments__hub-assignments {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 40px; }
  .cr-user-assignments__hub-assignments label {
    color: #43535c;
    text-transform: uppercase; }

.cr-user-assignments__hub-view-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -8px; }
  .cr-user-assignments__hub-view-list .cr-user-assignments__none-text {
    margin-left: 8px; }

.cr-user-assignments__hub-item {
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  border-radius: 4px;
  padding: 8px 15px;
  margin: 0 8px;
  margin-bottom: 8px; }
  .cr-user-assignments__hub-item span {
    color: #657894;
    font-size: 16px; }

.cr-radio-buttons__radio-input {
  margin-right: 0; }

.cr-radio-buttons .form-check {
  margin-right: 20px; }
  .cr-radio-buttons .form-check:last-child {
    margin-right: 0; }

.cr-radio-buttons .form-check-label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  cursor: pointer; }

.cr-radio-buttons__label {
  font-size: 16px;
  line-height: 20px;
  color: #111b2b;
  margin-left: 15px; }

.cr-radio-buttons__radio-view {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  border: 1px solid #c2d4e0;
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center; }

.cr-radio-buttons__radio-view-checked {
  position: relative; }
  .cr-radio-buttons__radio-view-checked::before {
    content: " ";
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: #224b8b;
    border-radius: 100px;
    position: absolute; }

.cr-select-tags__tag-view {
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #657894;
  margin-right: 13.32px;
  display: inline-block;
  height: 42px;
  padding: 10px 20px;
  margin-bottom: 15px; }

.cr-select-tags__selected-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #334856;
  margin-bottom: 5px;
  text-transform: capitalize; }

.cr-select-tags__react-input-select-container {
  margin-bottom: 39px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .cr-select-tags__react-input-select-container .react-select-container {
    flex: 1 1; }

.cr-select-tags__remove-tag {
  margin-right: 14px;
  cursor: pointer; }

.cr-select-tags__button-add {
  width: 88px !important;
  margin-left: 18px;
  min-width: auto !important; }

.cr-select-tags__view-most {
  margin-top: 13px;
  font-size: 14px;
  line-height: 18px;
  color: #795afa; }

.cr-select-tags__menu-new {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 43px;
  margin-bottom: 7px;
  cursor: pointer; }

.cr-select-tags__new-label {
  font-weight: bold;
  font-size: 14px;
  color: #795afa;
  margin-left: 10px; }

.cr-user-invite-form__title {
  font-weight: bold;
  font-size: 23px;
  line-height: 29px;
  color: #334856;
  padding: 20px 20px 20px 40px;
  margin-bottom: 0;
  border-bottom: 1px solid #edf1f6; }

.cr-user-invite-form__content-form {
  padding: 30px 42px; }

.cr-user-invite-form__form-buttons {
  padding: 0 40px; }
  .cr-user-invite-form__form-buttons .btn-blue {
    margin-right: 10px; }

.cr-user-invite-form .form-group label {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #43535c;
  text-transform: uppercase; }

.cr-manage-privileges-modal {
  min-width: 1000px; }
  .cr-manage-privileges-modal .modal-header {
    padding: 0; }
  .cr-manage-privileges-modal__close {
    cursor: pointer; }
  .cr-manage-privileges-modal .modal-title {
    padding: 35px 37px 33px 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
  .cr-manage-privileges-modal__title {
    font-weight: bold;
    font-size: 30px;
    line-height: 38px;
    color: #111b2b;
    text-transform: capitalize; }
  .cr-manage-privileges-modal .modal-footer {
    border: none;
    padding-top: 46px;
    padding-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-manage-privileges-modal__section-title {
    font-weight: 800;
    font-size: 23px;
    line-height: 29px;
    color: #111b2b;
    position: relative;
    cursor: pointer; }
  .cr-manage-privileges-modal__body {
    padding: 0; }
  .cr-manage-privileges-modal__section-container {
    padding: 28px 68px 32px 61px;
    border-bottom: 1px solid #edf1f6; }
  .cr-manage-privileges-modal__section-icon {
    position: absolute;
    right: 0; }
  .cr-manage-privileges-modal__total {
    margin-left: 10px;
    color: #537a91;
    text-transform: capitalize;
    font-weight: normal; }
  .cr-manage-privileges-modal__sub-group-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #334856;
    margin-bottom: 25px; }
  .cr-manage-privileges-modal .cr-checkbox {
    margin-bottom: 16px; }
  .cr-manage-privileges-modal .cr-checkbox__text {
    font-size: 16px;
    color: #537a91;
    margin-left: 21px; }
  .cr-manage-privileges-modal__hide {
    display: none; }
  .cr-manage-privileges-modal__show {
    display: block;
    padding-top: 26px; }
  .cr-manage-privileges-modal__p-left-0 {
    padding-left: 0; }

.cr-button-add.create-clock {
  border-radius: 50px;
  width: 243px !important;
  border: 1px solid #acc4d4;
  text-transform: capitalize; }
  .cr-button-add.create-clock:hover, .cr-button-add.create-clock:focus, .cr-button-add.create-clock:active {
    border-color: #acc4d4 !important;
    box-shadow: none !important; }
  .cr-button-add.create-clock span {
    margin-left: 0px;
    font-size: 18px;
    color: #374C59; }

.cr-clock-manager .row > [class^="col-"] {
  margin-bottom: 0px; }

.cr-clock-manager .card {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  border: none;
  padding-bottom: 20px; }
  .cr-clock-manager .card.no-data {
    padding-bottom: 70px; }
  .cr-clock-manager .card .card-body {
    padding: 0px; }
    .cr-clock-manager .card .card-body .rc-checkbox {
      opacity: 1;
      transition: none; }
      .cr-clock-manager .card .card-body .rc-checkbox .rc-checkbox-inner {
        width: 20px;
        height: 19.39px;
        border-color: #224b8b; }
    .cr-clock-manager .card .card-body .cr-checkbox__checked-icon {
      left: 4px;
      top: 1px; }

.cr-clock-manager__head-wrapper {
  padding: 44px 17px 34px 17px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.cr-clock-manager__clock-name {
  font-weight: bold; }
  .cr-clock-manager__clock-name.inactive {
    color: #829fb1; }

.cr-clock-manager__clock-creation-date.inactive {
  color: #829fb1; }

.cr-clock-manager__clock-station.inactive {
  color: #829fb1; }

.cr-clock-manager__is-active.inactive .rc-checkbox-inner {
  border: 1px solid #c2d4e0 !important; }

.cr-clock-manager__is-active .cr-checkbox__checkbox {
  justify-content: center; }

.cr-clock-manager__helper-box {
  display: flex;
  padding: 30px 100px;
  justify-content: center;
  align-items: center; }

.cr-clock-manager__helper-text {
  font-weight: 800;
  font-size: 23px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  width: 553px;
  margin-top: 40px; }

.cr-clock-manager .clocks-table .rt-tr .rt-td {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.cr-clock-manager .clocks-table .rt-tr .rt-th .th-pad-left {
  padding-left: 20px !important; }

.cr-clock-manager__tooltip-station-text {
  color: #000000; }

.cr-clock-manager__tooltip {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  opacity: 1;
  padding: 0; }
  .cr-clock-manager__tooltip .rc-tooltip-content {
    background: #ffffff;
    padding: 24px;
    border-radius: 4px; }
  .cr-clock-manager__tooltip .rc-tooltip-arrow {
    border-top-color: #ffffff;
    border-width: 10px 10px 0;
    bottom: -8px; }
  .cr-clock-manager__tooltip .rc-tooltip-inner {
    padding: 0;
    background-color: #ffffff;
    box-shadow: none;
    min-height: auto; }

.ReactTable.admin-table {
  border: none; }
  .ReactTable.admin-table .rt-table .rt-thead {
    background: #224b8b; }
    .ReactTable.admin-table .rt-table .rt-thead .rt-th {
      padding: 17px 0 !important;
      border-right: 0 !important; }
      .ReactTable.admin-table .rt-table .rt-thead .rt-th span {
        font-weight: bold;
        font-size: 17px;
        line-height: 21px;
        color: #ffffff; }
      .ReactTable.admin-table .rt-table .rt-thead .rt-th .th-sort {
        display: flex; }
        .ReactTable.admin-table .rt-table .rt-thead .rt-th .th-sort .sortable {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 25px;
          height: 25px;
          text-align: center; }
          .ReactTable.admin-table .rt-table .rt-thead .rt-th .th-sort .sortable .sortable-icon {
            position: absolute;
            font-size: 10px;
            color: #fff; }
            .ReactTable.admin-table .rt-table .rt-thead .rt-th .th-sort .sortable .sortable-icon:first-child {
              margin-bottom: 5px; }
        .ReactTable.admin-table .rt-table .rt-thead .rt-th .th-sort .sortable-active {
          color: #c12674 !important; }
      .ReactTable.admin-table .rt-table .rt-thead .rt-th .th-pad-left {
        padding-left: 45px; }
      .ReactTable.admin-table .rt-table .rt-thead .rt-th .th-center {
        align-items: center;
        justify-content: center;
        padding-left: 0; }
  .ReactTable.admin-table .rt-table .rt-tbody .rt-tr-group {
    border-bottom: 1px solid #f0f3f4; }
    .ReactTable.admin-table .rt-table .rt-tbody .rt-tr-group:last-child {
      border-bottom: none !important; }
    .ReactTable.admin-table .rt-table .rt-tbody .rt-tr-group .rt-td {
      border: none;
      padding: 15px 45px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      cursor: default; }
      .ReactTable.admin-table .rt-table .rt-tbody .rt-tr-group .rt-td > span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }

.cr-search-box {
  position: relative;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 50px;
  min-width: 395px;
  height: 49px;
  background-color: #fff;
  overflow: hidden; }
  .cr-search-box__icon-search {
    position: absolute;
    right: 10px;
    color: #121d2f;
    font-size: 30px;
    top: calc(50% - 25px);
    background: none;
    border: none;
    padding: 0;
    cursor: pointer; }
    .cr-search-box__icon-search:focus {
      outline: none; }
  .cr-search-box__icon-close {
    position: absolute;
    right: 45px;
    top: calc(50% - 8px);
    cursor: pointer; }
  .cr-search-box__input {
    /* stylelint-disable-next-line */
    padding: 15px 60px 15px 20px;
    font-size: 16px;
    line-height: 20px;
    color: #829fb1;
    width: 100% !important;
    border: none; }
    .cr-search-box__input:focus {
      color: #829fb1; }
    .cr-search-box__input::-webkit-input-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: #829fb1; }
    .cr-search-box__input:-moz-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: #829fb1; }
    .cr-search-box__input::-moz-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: #829fb1; }
    .cr-search-box__input:-ms-input-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: #829fb1; }

.cr-clock-type {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 12px;
  background: #edf3f7;
  border-radius: 50%;
  width: 51px;
  height: 51px; }
  .cr-clock-type__type-prefix {
    font-weight: 800;
    font-size: 23px;
    line-height: 20px;
    text-align: center; }
  .cr-clock-type__type {
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    text-transform: uppercase; }

.cr-clock-creator--edit {
  padding-bottom: 140px !important; }

.cr-clock-creator__form-panel {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 6px; }
  .cr-clock-creator__form-panel .col {
    padding-left: 0px;
    margin-bottom: 0px !important; }
    .cr-clock-creator__form-panel .col:last-child {
      padding-right: 0px; }
    .cr-clock-creator__form-panel .col.title.edit-daypart:not(.show) {
      padding-left: 43px !important;
      padding-right: 8%; }
    .cr-clock-creator__form-panel .col.clock-type.edit.show:not(.edit-daypart) {
      flex: 0 0 22%;
      max-width: 22%; }
  .cr-clock-creator__form-panel .buttons-container-show {
    align-self: flex-start;
    margin-top: 20px;
    margin-bottom: 1rem !important; }
  .cr-clock-creator__form-panel .buttons-container {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    flex: 0 0 28%;
    max-width: 28%; }
  .cr-clock-creator__form-panel.sub-panel {
    margin-top: 23px; }

.cr-clock-creator__header-wrapper {
  padding: 26px 48px;
  border-bottom: 1px solid #edf1f6; }

.cr-clock-creator__header {
  font-weight: bold;
  font-size: 23px;
  line-height: 29px;
  color: #000000;
  margin: 0; }
  .cr-clock-creator__header .sub-text {
    font-size: 18px;
    font-weight: normal;
    font-style: italic; }

.cr-clock-creator__form-container {
  padding: 40px 26px;
  display: flex;
  align-items: flex-start; }
  .cr-clock-creator__form-container .cr-select-audience__selected-audience {
    line-height: 48px; }
  .cr-clock-creator__form-container label {
    margin-bottom: 0px;
    display: block; }
    .cr-clock-creator__form-container label span:first-child {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #43535c;
      text-transform: uppercase; }

.cr-clock-creator__form-daypart-container {
  padding: 40px 48px; }

.cr-clock-creator__readonly-label {
  vertical-align: top; }

.cr-clock-creator__buttons-container {
  padding: 26px 48px; }
  .cr-clock-creator__buttons-container .row > [class^="col-"] {
    padding: 0px;
    margin: 0px; }

.cr-clock-creator__form-buttons {
  display: flex; }
  .cr-clock-creator__form-buttons button {
    margin-right: 9px;
    box-shadow: none; }
    .cr-clock-creator__form-buttons button:last-child {
      margin-right: 0px !important; }
  .cr-clock-creator__form-buttons .btn-blue:disabled {
    background: #c2d4e0;
    border-color: #c2d4e0; }
    .cr-clock-creator__form-buttons .btn-blue:disabled:hover {
      background: #c2d4e0 !important; }
  .cr-clock-creator__form-buttons .btn-blue:focus, .cr-clock-creator__form-buttons .btn-blue:active, .cr-clock-creator__form-buttons .btn-blue:hover {
    box-shadow: none !important;
    border-color: #795afa !important;
    color: #fff !important;
    background: #795afa !important; }
  .cr-clock-creator__form-buttons .btn-outline-blue {
    line-height: 32px; }
    .cr-clock-creator__form-buttons .btn-outline-blue:focus, .cr-clock-creator__form-buttons .btn-outline-blue:active, .cr-clock-creator__form-buttons .btn-outline-blue:hover {
      box-shadow: none !important;
      border-color: #acc4d4 !important;
      color: #795afa !important; }
  .cr-clock-creator__form-buttons.bottom {
    margin-top: 60px; }

.cr-clock-creator__form-daypart-buttons {
  justify-content: space-between; }

.cr-clock-creator__helper-box {
  display: flex; }

.cr-clock-creator__helper-text {
  font-weight: 800;
  font-size: 23px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  max-width: 513px; }

.cr-clock-creator__clock-info {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 27px 38px; }
  .cr-clock-creator__clock-info .row {
    display: flex;
    align-items: center;
    margin: 0px; }
    .cr-clock-creator__clock-info .row .col {
      margin-bottom: 0px !important;
      padding: 0; }
      .cr-clock-creator__clock-info .row .col:last-child {
        display: flex;
        justify-content: flex-end; }
      .cr-clock-creator__clock-info .row .col button {
        text-align: right;
        padding: 0px !important; }
  .cr-clock-creator__clock-info span.clock-name {
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #000000; }

.cr-clock-creator__calendar-container {
  margin-top: 50px; }

.cr-clock-creator__calendar-header h2 {
  font-weight: bold;
  font-size: 23px;
  line-height: 29px;
  color: #000000;
  margin: 0px; }

.cr-clock-creator__calendar-header .sub-text {
  margin-top: 4px;
  font-size: 16px;
  line-height: 20px;
  color: #537a91; }

.cr-clock-creator__field-value {
  font-weight: normal;
  font-size: 16px;
  line-height: 50px;
  color: #334856; }

.cr-clock-creator__daypart-helper {
  padding: 17px 48px; }
  .cr-clock-creator__daypart-helper span {
    font-size: 16px;
    line-height: 20px;
    color: #537a91; }

.cr-clock-creator__error-clock-validation {
  display: block; }

.cr-clock-creator__daypart-schedules-container {
  padding: 12px 48px 0px 48px;
  display: flex;
  align-items: center; }
  .cr-clock-creator__daypart-schedules-container label {
    margin-bottom: 6px; }
    .cr-clock-creator__daypart-schedules-container label span:first-child {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #43535c;
      text-transform: uppercase; }

.cr-clock-creator__add-row-button-container .col-12 {
  display: flex;
  padding: 0px 48px 20px 48px;
  align-items: center; }
  .cr-clock-creator__add-row-button-container .col-12 button {
    padding: 0px !important; }
    .cr-clock-creator__add-row-button-container .col-12 button span {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #795afa;
      margin-left: 12px;
      text-transform: none; }
    .cr-clock-creator__add-row-button-container .col-12 button:active, .cr-clock-creator__add-row-button-container .col-12 button:focus {
      box-shadow: none !important; }

.cr-clock-creator__helper-text-content {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #537a91; }

.cr-clock-creator__progclock-slots-container {
  padding: 0px 48px; }
  .cr-clock-creator__progclock-slots-container label {
    text-transform: uppercase; }

.cr-clock-creator__button-panel-toggle-icon {
  float: right;
  cursor: pointer; }

.cr-clock-creator__national-slots {
  padding: 0px 48px 40px; }
  .cr-clock-creator__national-slots .ReactTable .rt-th,
  .cr-clock-creator__national-slots .ReactTable .rt-td {
    overflow: visible; }
  .cr-clock-creator__national-slots .table-container {
    border-radius: 6px;
    border: 1px solid #c2d4e0;
    min-height: 68px;
    max-height: 350px;
    overflow-y: auto; }
    .cr-clock-creator__national-slots .table-container .ReactTable {
      border: none; }
      .cr-clock-creator__national-slots .table-container .ReactTable .rt-thead {
        background: #f7fbfd;
        border-radius: 6px 6px 0px 0px;
        box-shadow: none; }
        .cr-clock-creator__national-slots .table-container .ReactTable .rt-thead .rt-th {
          border: none;
          border-bottom: 1px solid #c2d4e0;
          justify-content: left;
          display: flex;
          padding: 10px 21px; }
          .cr-clock-creator__national-slots .table-container .ReactTable .rt-thead .rt-th .header {
            font-weight: normal;
            color: #829fb1;
            font-size: 12px;
            text-transform: uppercase; }
            .cr-clock-creator__national-slots .table-container .ReactTable .rt-thead .rt-th .header .asterisk {
              font-size: 21px;
              position: absolute;
              top: 4px; }
      .cr-clock-creator__national-slots .table-container .ReactTable .rt-tr .rt-td {
        border-right: none;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block !important;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 30px;
        color: #334856;
        padding: 16px 21px 13px; }
        .cr-clock-creator__national-slots .table-container .ReactTable .rt-tr .rt-td .cr-checkbox__text {
          font-size: 16px;
          line-height: 42px;
          color: #000000;
          margin-left: 16px; }
        .cr-clock-creator__national-slots .table-container .ReactTable .rt-tr .rt-td .rc-checkbox-inner {
          width: 21px;
          height: 21px; }
          .cr-clock-creator__national-slots .table-container .ReactTable .rt-tr .rt-td .rc-checkbox-inner:after {
            left: 6px;
            top: 1px; }
        .cr-clock-creator__national-slots .table-container .ReactTable .rt-tr .rt-td .rc-checkbox-disabled .rc-checkbox-inner {
          background: #c2d4e0;
          border-color: #c2d4e0; }
          .cr-clock-creator__national-slots .table-container .ReactTable .rt-tr .rt-td .rc-checkbox-disabled .rc-checkbox-inner:after {
            width: 7px;
            height: 13px;
            border: 3px solid #ffffff;
            border-top: 0;
            border-left: 0; }
        .cr-clock-creator__national-slots .table-container .ReactTable .rt-tr .rt-td .cr-checkbox__checked-icon {
          left: 4px;
          top: 2px; }
        .cr-clock-creator__national-slots .table-container .ReactTable .rt-tr .rt-td .react-select-container {
          width: 80%; }
        .cr-clock-creator__national-slots .table-container .ReactTable .rt-tr .rt-td .react-select__control {
          min-height: 38px !important;
          height: 38px !important;
          line-height: 20px; }
        .cr-clock-creator__national-slots .table-container .ReactTable .rt-tr .rt-td .react-select__indicators {
          height: 37px !important; }
        .cr-clock-creator__national-slots .table-container .ReactTable .rt-tr .rt-td .cr-textinput__input-wrapper {
          height: 38px;
          width: 61px; }
        .cr-clock-creator__national-slots .table-container .ReactTable .rt-tr .rt-td .cr-textinput {
          width: 26%;
          float: left; }
          .cr-clock-creator__national-slots .table-container .ReactTable .rt-tr .rt-td .cr-textinput input {
            text-align: center; }
        .cr-clock-creator__national-slots .table-container .ReactTable .rt-tr .rt-td .days-before-text {
          float: left;
          font-weight: normal;
          font-size: 14px;
          line-height: 37px;
          color: #334856;
          margin-left: 10px; }
      .cr-clock-creator__national-slots .table-container .ReactTable .no-results {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #829fb1;
        display: flex;
        align-items: center;
        padding: 35px; }

.cr-clock-creator__empty-dash {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 42px;
  color: #829fb1; }

.cr-clock-creator__reassign-dropdown-container {
  width: 279px;
  margin-top: 20px;
  text-align: left; }
  .cr-clock-creator__reassign-dropdown-container .react-select__value-container {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #829fb1 !important; }

.cr-clock-creator__rules-helper-text {
  border-bottom: 1px solid #edf1f6; }

.cr-clock-creator__rules-item {
  border-bottom: 1px solid #edf1f6; }

.cr-clock-creator__rule-title {
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  margin-bottom: 12px; }

.cr-clock-creator__rule-description {
  color: #537a91;
  font-size: 16px;
  margin-bottom: 0; }

.cr-clock-creator__rule-content-checkboxes {
  margin-top: 30px;
  max-width: 600px; }
  .cr-clock-creator__rule-content-checkboxes .cr-checkbox {
    width: 300px;
    display: inline-block;
    margin-bottom: 11px; }

.cr-clock-creator__rule-wrapper {
  display: flex; }
  .cr-clock-creator__rule-wrapper .cr-checkbox {
    margin-top: 5px; }

.cr-clock-creator__rule-content {
  margin-left: 15px; }

.cr-clock-creator__time-table {
  margin-top: 18px;
  max-width: 574px; }
  .cr-clock-creator__time-table .table {
    border-collapse: collapse;
    background: #f7fbfd;
    box-sizing: border-box;
    border-radius: 6px;
    border-style: hidden;
    box-shadow: 0 0 0 1px #c2d4e0;
    overflow: hidden; }
    .cr-clock-creator__time-table .table th {
      border-bottom: 1px solid #c2d4e0; }
      .cr-clock-creator__time-table .table th span {
        font-size: 12px;
        color: #829fb1;
        font-weight: normal;
        text-transform: uppercase; }
    .cr-clock-creator__time-table .table tbody {
      background-color: #fff; }
    .cr-clock-creator__time-table .table td {
      vertical-align: center; }
  .cr-clock-creator__time-table .cr-textinput {
    max-width: 63px;
    display: inline-block; }
    .cr-clock-creator__time-table .cr-textinput input {
      font-weight: bold;
      font-size: 16px;
      color: #334856;
      text-align: center; }
      .cr-clock-creator__time-table .cr-textinput input::-webkit-input-placeholder {
        color: #334856; }
      .cr-clock-creator__time-table .cr-textinput input::placeholder {
        color: #334856; }

.cr-clock-creator__time-table-title {
  font-weight: bold;
  font-size: 16px;
  color: #334856;
  margin-bottom: 8px; }

.cr-clock-creator__time-label {
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  line-height: 50px;
  margin-left: 10px; }

.cr-clock-creator__time-width {
  width: 100px; }

.cr-clock-creator__time-hour {
  position: relative; }

.cr-clock-creator__time-divider {
  color: #000000;
  font-size: 21px;
  position: absolute;
  right: -7px;
  top: 7px; }

.cr-clock-creator__priority-title {
  font-weight: bold;
  font-size: 16px;
  color: #334856;
  margin-bottom: 7px; }

.cr-clock-creator .menu-selection-icon {
  margin-right: 12px;
  cursor: move; }

.cr-clock-creator__sort-number {
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  margin-right: 10px; }

.cr-clock-creator__priority-label {
  background: #ffffff;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 6px;
  width: 507px;
  height: 46px;
  display: inline-flex;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  align-items: center;
  padding: 0 20px; }

.cr-clock-creator__selection-item {
  margin: 8px 0; }

.cr-clock-creator .button-reset {
  display: flex;
  align-items: center;
  justify-content: center; }
  .cr-clock-creator .button-reset span {
    margin-left: 10px; }

.cr-clock-creator__separation-rules-details {
  display: none; }

.cr-clock-creator__separation-rules-details-opened {
  display: block; }

.cr-clock-creator .bottom-actions-sticky .btn-primary {
  margin-right: 14px; }

.cr-clock-creator .bottom-actions-sticky a.button-cancel {
  display: inline-flex;
  align-items: center;
  justify-content: center; }

.cr-clock-creator .bottom-actions-sticky .buttons {
  display: flex;
  justify-content: flex-start; }

.cr-clock-creator .bottom-actions-sticky .daypart-buttons {
  justify-content: space-between; }

.cr-clock-creator__form-daypart {
  margin-bottom: 50px; }

@media (min-width: 1599px) {
  .cr-clock-creator .bottom-actions-sticky .buttons {
    padding-left: 70px;
    padding-right: 70px; } }

@media screen and (min-width: 1200px) and (max-width: 1436px) {
  .cr-clock-creator__daypart-schedules-container {
    padding: 12px 30px 0px 30px; }
  .cr-clock-creator__form-daypart-container {
    padding: 40px 30px; }
  .cr-clock-creator__daypart-helper {
    padding: 17px 30px; }
  .cr-clock-creator__header-wrapper {
    padding: 26px 30px; } }

@media (max-width: 1698px) {
  .cr-clock-creator__form-panel .col:not(.edit-daypart) {
    flex: 0 0 33.33% !important;
    max-width: 33.33% !important; }
    .cr-clock-creator__form-panel .col:not(.edit-daypart):nth-child(3) {
      padding-right: 0px; }
  .cr-clock-creator__form-panel .buttons-container {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin-top: 20px;
    justify-content: flex-start; }
  .cr-clock-creator__form-panel .col.title.edit-daypart {
    padding-left: 28px !important; }
  .cr-clock-creator__form-panel .col.station.edit-daypart {
    padding-left: 4px !important; } }

.cr-programming-clock-calendar__calendar {
  margin-top: 48px;
  min-width: 1316px; }
  .cr-programming-clock-calendar__calendar .week-day {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #334856;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 164px;
    margin-bottom: 8px !important;
    margin-right: 10px !important; }
    .cr-programming-clock-calendar__calendar .week-day:first-child {
      max-width: 92px !important;
      margin-right: 0px !important;
      min-width: 74px;
      justify-content: flex-end;
      padding-right: 8px !important; }

.cr-programming-clock-calendar__slot {
  min-width: 164px;
  min-height: 39px;
  background: rgba(194, 212, 224, 0.6);
  opacity: 0.5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px !important;
  cursor: pointer; }
  .cr-programming-clock-calendar__slot svg {
    pointer-events: none; }
  .cr-programming-clock-calendar__slot.active {
    background: #795AFA;
    opacity: 1; }

.cr-programming-clock-calendar__hour-block {
  min-width: 164px;
  background: none;
  border-radius: 4px;
  margin-right: 10px !important;
  cursor: pointer; }

.cr-programming-clock-calendar__timeslot {
  height: 39px;
  background: #829FB1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 6px;
  padding: 8px 28px; }
  .cr-programming-clock-calendar__timeslot:last-child {
    margin-bottom: 0px; }
  .cr-programming-clock-calendar__timeslot span.time {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    z-index: 1; }

.cr-programming-clock-calendar__slot-color-indicator {
  width: 13px;
  height: 39px;
  position: absolute;
  border-left: 1px solid #F7FBFD; }

.cr-programming-clock-calendar__slot-edit-icon {
  position: absolute;
  right: 10px; }

.cr-programming-clock-calendar__hour {
  margin-bottom: 18px !important; }

.cr-programming-clock-calendar__time {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #537A91;
  padding-right: 24px !important;
  max-width: 92px !important;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: 8px !important; }
  .cr-programming-clock-calendar__time.filled {
    padding-right: 8px !important;
    cursor: pointer; }

.cr-programming-clock-calendar__time-chevron-icon {
  margin-top: -3px;
  margin-left: 5px; }

.cr-programming-clock-calendar__collaps-all {
  cursor: pointer; }
  .cr-programming-clock-calendar__collaps-all span {
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    color: #795AFA;
    padding-right: 3px; }

.cr-programming-clock-calendar__filter-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -35px !important; }
  .cr-programming-clock-calendar__filter-container .label-container {
    flex: 0 0 11.33333%;
    max-width: 11.33333%; }
    .cr-programming-clock-calendar__filter-container .label-container label {
      margin-bottom: 0px; }
      .cr-programming-clock-calendar__filter-container .label-container label span {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.05em;
        color: #43535C;
        text-transform: uppercase; }
  .cr-programming-clock-calendar__filter-container .dropdown-container .react-select-menu-open .react-select__menu {
    border-top: 1px solid #E3EDF4 !important; }

@media (max-width: 1698px) {
  .cr-programming-clock-calendar__calendar {
    min-width: 1090px; }
    .cr-programming-clock-calendar__calendar .week-day {
      min-width: 128px; }
  .cr-programming-clock-calendar__slot {
    min-width: 128px; }
  .cr-programming-clock-calendar__hour-block {
    min-width: 128px; }
  .cr-programming-clock-calendar__filter-container {
    padding-right: 50px; }
    .cr-programming-clock-calendar__filter-container .dropdown-container {
      flex: 0 0 28%;
      max-width: 28%; } }

.cr-show-clock-calendar__calendar {
  margin-top: 48px;
  min-width: 1275px; }
  .cr-show-clock-calendar__calendar .week-day {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #334856;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 171px;
    padding-bottom: 24px !important; }
    .cr-show-clock-calendar__calendar .week-day:first-child {
      max-width: 75px !important;
      margin-right: 0px !important;
      min-width: 75px;
      border: none; }

.cr-show-clock-calendar__hour.hour-0 .cr-show-clock-calendar__slot {
  border-top: 1px solid rgba(194, 212, 224, 0.6); }

.cr-show-clock-calendar__slot {
  min-width: 171px;
  height: 57px;
  background: none;
  opacity: 0.7;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-bottom: 1px solid rgba(194, 212, 224, 0.6);
  border-right: 1px solid rgba(194, 212, 224, 0.6); }
  .cr-show-clock-calendar__slot.active {
    background: #795afa;
    opacity: 1; }
  .cr-show-clock-calendar__slot.slot-01 {
    border-radius: 4px 0 0 0; }
  .cr-show-clock-calendar__slot.slot-07 {
    border-radius: 0 4px 0 0; }
  .cr-show-clock-calendar__slot.slot-231 {
    border-radius: 0 0 0 4px; }
  .cr-show-clock-calendar__slot.slot-237 {
    border-radius: 0 0 4px 0; }
  .cr-show-clock-calendar__slot.column-1 {
    border-left: 1px solid rgba(194, 212, 224, 0.6); }

.cr-show-clock-calendar__time {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #537a91;
  padding-right: 24px !important;
  max-width: 75px !important;
  display: flex;
  align-items: start;
  justify-content: flex-end; }

.cr-show-clock-calendar__show {
  position: absolute;
  border-radius: 4px;
  z-index: 10;
  margin: 1px;
  cursor: pointer; }
  .cr-show-clock-calendar__show div.description {
    color: #ffffff;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: 9px 10px 6px; }
    .cr-show-clock-calendar__show div.description div.name {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      -webkit-box-orient: vertical;
      font-size: 12px;
      padding-top: 1px; }
      .cr-show-clock-calendar__show div.description div.name.truncate-one-line {
        -webkit-line-clamp: 1; }
    .cr-show-clock-calendar__show div.description div.time {
      font-weight: normal;
      line-height: 14px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      -webkit-box-orient: vertical; }

.cr-show-clock-calendar__show-tooltip-popper {
  opacity: 1 !important; }

.cr-show-clock-calendar__show-tooltip.tooltip-inner {
  max-width: 400px;
  width: 100%;
  padding: 8px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18); }
  .cr-show-clock-calendar__show-tooltip.tooltip-inner span {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000; }

.cr-show-clock-calendar__show-type-label {
  background: #ffffff;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  color: #334856;
  text-transform: uppercase;
  font-weight: 700; }

.cr-show-clock-calendar__show-top-left-small {
  height: 11px;
  font-size: 7px;
  min-width: 45px;
  right: 1px;
  top: 1px;
  position: absolute; }

.cr-show-clock-calendar__show-type-normal {
  height: 23px;
  min-width: 73px;
  margin: 0 10px; }

@media (max-width: 1698px) {
  .cr-show-clock-calendar__calendar {
    min-width: 1090px; }
    .cr-show-clock-calendar__calendar .week-day {
      min-width: 140px; }
  .cr-show-clock-calendar__slot {
    min-width: 140px; } }

.cr-clock-calendar-modal {
  min-width: 674px; }
  .cr-clock-calendar-modal .modal-header {
    padding: 24px 43px;
    border: none; }
    .cr-clock-calendar-modal .modal-header button.close {
      color: #657894;
      font-size: 40px;
      opacity: 1;
      font-weight: bold !important;
      padding: 0px; }
      .cr-clock-calendar-modal .modal-header button.close:focus, .cr-clock-calendar-modal .modal-header button.close:active {
        outline: none; }
  .cr-clock-calendar-modal .title {
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856; }
  .cr-clock-calendar-modal .sub-title {
    font-size: 16px;
    line-height: 20px;
    color: #537a91;
    padding-left: 1px; }
  .cr-clock-calendar-modal .modal-body {
    padding: 0px; }
    .cr-clock-calendar-modal .modal-body .field-container {
      padding: 0px 43px; }
      .cr-clock-calendar-modal .modal-body .field-container.hours-container {
        margin-top: 24px;
        display: flex;
        align-items: center; }
        .cr-clock-calendar-modal .modal-body .field-container.hours-container .dropdown-container {
          flex: 0 0 69.33333%;
          max-width: 83.33333%; }
          .cr-clock-calendar-modal .modal-body .field-container.hours-container .dropdown-container .react-select__menu {
            border-top: 1px solid #c2d4e0 !important;
            border-radius: 6px !important;
            border-bottom: none !important; }
          .cr-clock-calendar-modal .modal-body .field-container.hours-container .dropdown-container .react-select-menu-open .react-select__menu {
            border-top-color: #795afa !important; }
          .cr-clock-calendar-modal .modal-body .field-container.hours-container .dropdown-container .react-select-menu-open .react-select__control {
            border-bottom: 1px solid #795afa;
            border-bottom-left-radius: 6px !important;
            border-bottom-right-radius: 6px !important; }
        .cr-clock-calendar-modal .modal-body .field-container.hours-container .checkbox-container {
          flex: 0 0 30.66667%;
          max-width: 30.66667%;
          padding-left: 30px;
          margin-top: 30px; }
          .cr-clock-calendar-modal .modal-body .field-container.hours-container .checkbox-container .cr-checkbox__text {
            margin-left: 12px;
            font-size: 16px;
            line-height: 20px;
            color: #334856; }
        .cr-clock-calendar-modal .modal-body .field-container.hours-container .react-select__value-container {
          padding-left: 8px; }
      .cr-clock-calendar-modal .modal-body .field-container.randomize {
        margin-top: 22px; }
        .cr-clock-calendar-modal .modal-body .field-container.randomize .cr-checkbox__text {
          margin-left: 14px; }
    .cr-clock-calendar-modal .modal-body .start-end-time-separator {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: 28px;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #43535c;
      padding-top: 18px; }
    .cr-clock-calendar-modal .modal-body label > span:first-child {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #43535c;
      text-transform: uppercase; }
    .cr-clock-calendar-modal .modal-body .cr-days-of-week {
      margin-top: 37px !important; }
      .cr-clock-calendar-modal .modal-body .cr-days-of-week .cr-days-of-week__day-text {
        font-size: 18px;
        line-height: 23px;
        color: #000000; }
  .cr-clock-calendar-modal__name-container {
    flex: 0 0 89.5%;
    max-width: 89.5%; }
  .cr-clock-calendar-modal__time-combo-container {
    flex: 0 0 48.5%;
    max-width: 48.5%; }
    .cr-clock-calendar-modal__time-combo-container.start-time {
      padding-right: 15px !important; }
    .cr-clock-calendar-modal__time-combo-container.end-time {
      padding-left: 15px !important; }
  .cr-clock-calendar-modal__color-picker-container {
    padding-left: 12px !important; }
    .cr-clock-calendar-modal__color-picker-container label {
      display: flex; }
  .cr-clock-calendar-modal__separator {
    border-top: 1px solid #edf1f6;
    margin: 25px 0px; }
    .cr-clock-calendar-modal__separator.m-b20 {
      margin-bottom: 20px; }
  .cr-clock-calendar-modal__form-buttons {
    padding: 49px 0px;
    display: flex; }
    .cr-clock-calendar-modal__form-buttons button {
      margin-right: 9px;
      box-shadow: none;
      height: 47px; }
      .cr-clock-calendar-modal__form-buttons button:last-child {
        margin-right: 0px !important; }
    .cr-clock-calendar-modal__form-buttons .btn-blue:disabled {
      background: #c2d4e0;
      border-color: #c2d4e0; }
      .cr-clock-calendar-modal__form-buttons .btn-blue:disabled:hover {
        background: #c2d4e0 !important; }
    .cr-clock-calendar-modal__form-buttons .btn-blue:focus, .cr-clock-calendar-modal__form-buttons .btn-blue:active, .cr-clock-calendar-modal__form-buttons .btn-blue:hover {
      box-shadow: none !important;
      border-color: #acc4d4 !important;
      color: #fff !important;
      background: #795afa !important; }
    .cr-clock-calendar-modal__form-buttons .btn-outline-blue {
      line-height: 32px;
      height: 47px; }
      .cr-clock-calendar-modal__form-buttons .btn-outline-blue:focus, .cr-clock-calendar-modal__form-buttons .btn-outline-blue:active, .cr-clock-calendar-modal__form-buttons .btn-outline-blue:hover {
        box-shadow: none !important;
        border-color: #acc4d4 !important;
        color: #795afa !important; }
  .cr-clock-calendar-modal__add-timeslot-button-container .cr-button-add.btn-secondary:focus {
    box-shadow: none !important; }
  .cr-clock-calendar-modal__add-timeslot-button-container .cr-button-add.btn-secondary:active {
    box-shadow: none !important; }
  .cr-clock-calendar-modal__add-timeslot-button-container .cr-button-add.btn-secondary span {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #795afa;
    margin-left: 12px;
    text-transform: none; }
  .cr-clock-calendar-modal__copy-overlay {
    border: 2px dashed #c2d4e0;
    border-radius: 6px;
    background: #e2ecf2;
    min-height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
    .cr-clock-calendar-modal__copy-overlay .box {
      font-weight: 800;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #000000;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 392px; }
      .cr-clock-calendar-modal__copy-overlay .box span {
        margin-bottom: 20px; }
  .cr-clock-calendar-modal .assigned-users {
    margin-top: 37px; }
    .cr-clock-calendar-modal .assigned-users .react-select__placeholder {
      font-size: 16px;
      color: #829fb1 !important; }
  .cr-clock-calendar-modal__select-users {
    max-width: 380px; }
  .cr-clock-calendar-modal__assigned-users-to-show {
    margin-top: 25px; }
  .cr-clock-calendar-modal__assign-to-show-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #334856;
    margin-bottom: 20px;
    display: block; }
  .cr-clock-calendar-modal__user-name {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #657894; }
  .cr-clock-calendar-modal__user-item {
    background: #f7fbfd;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 4px;
    height: 46px;
    margin-right: 12px;
    margin-bottom: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px; }
  .cr-clock-calendar-modal__remove-user {
    margin-right: 12px;
    display: flex;
    cursor: pointer; }
  .cr-clock-calendar-modal__show-type {
    margin-bottom: 30px;
    margin-top: 14px; }
    .cr-clock-calendar-modal__show-type .cr-toggle-switch__left-component,
    .cr-clock-calendar-modal__show-type .cr-toggle-switch__right-component {
      text-transform: uppercase;
      font-size: 17px;
      font-weight: 400;
      color: #829fb1; }
    .cr-clock-calendar-modal__show-type .active {
      color: #374c59;
      font-weight: 700; }

.cr-input-time {
  display: flex;
  align-items: center; }
  .cr-input-time__input {
    outline: none; }
    .cr-input-time__input:focus {
      border-width: 0px;
      border-color: #795afa;
      box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.25); }
    .cr-input-time__input:hover {
      border-width: 0px;
      border-color: #4324c7;
      box-shadow: 0 0 0 2px #4324c7; }
  .cr-input-time__hour {
    background: #ffffff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 6px;
    height: 48px;
    width: 25%;
    text-align: center;
    margin-right: 8px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #000000; }
    .cr-input-time__hour::-webkit-input-placeholder {
      color: #b7c9d3; }
    .cr-input-time__hour::placeholder {
      color: #b7c9d3; }
    .cr-input-time__hour::-ms-input-placeholder {
      color: #b7c9d3; }
    .cr-input-time__hour::-ms-input-placeholder {
      color: #b7c9d3; }
  .cr-input-time__separator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -7px !important;
    font-weight: normal;
    font-size: 21px;
    line-height: 26px;
    color: #000000; }
  .cr-input-time__minute {
    background: #ffffff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 6px;
    height: 48px;
    width: 25%;
    text-align: center;
    margin-left: 8px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #000000; }
    .cr-input-time__minute::-webkit-input-placeholder {
      color: #b7c9d3; }
    .cr-input-time__minute::placeholder {
      color: #b7c9d3; }
    .cr-input-time__minute::-ms-input-placeholder {
      color: #b7c9d3; }
    .cr-input-time__minute::-ms-input-placeholder {
      color: #b7c9d3; }
  .cr-input-time__ampm {
    width: 42%;
    margin-left: 8px; }
    .cr-input-time__ampm .react-select__control {
      max-height: 48px;
      min-height: 48px !important; }
    .cr-input-time__ampm .react-select__indicator-separator {
      display: none; }
    .cr-input-time__ampm .react-select__single-value {
      color: #000000 !important; }
  .cr-input-time__time-text {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    display: flex;
    height: 48px;
    align-items: center; }

.cr-color-picker__selected-color {
  width: 50px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer; }

.cr-color-picker__color-list {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: absolute;
  min-width: 172px;
  padding: 13px !important;
  z-index: 1;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .cr-color-picker__color-list:before {
    content: "";
    position: absolute;
    top: 1px;
    left: 6%;
    width: 16px;
    height: 16px;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    -webkit-transform: translate(50%, -50%) rotate(-45deg);
            transform: translate(50%, -50%) rotate(-45deg);
    -webkit-clip-path: polygon(-20px -20px, calc(100% + 20px) -20px, calc(100% + 20px) calc(100% + 20px));
    clip-path: polygon(-20px -20px, calc(100% + 20px) -20px, calc(100% + 20px) calc(100% + 20px)); }

.cr-color-picker__arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #ffffff; }

.cr-color-picker__color {
  width: 23.84px;
  height: 23.72px;
  border-radius: 3px;
  margin: 6px !important;
  cursor: pointer; }

.cr-daypart-scheduler {
  margin-bottom: 21px !important; }
  .cr-daypart-scheduler.error {
    margin-bottom: 45px !important; }
    .cr-daypart-scheduler.error .text-danger {
      position: absolute; }
  .cr-daypart-scheduler__schedule-row .cr-input-time__hour, .cr-daypart-scheduler__schedule-row .cr-input-time__separator, .cr-daypart-scheduler__schedule-row .cr-input-time__ampm {
    max-width: 95px; }
  .cr-daypart-scheduler__schedule-start-time {
    padding-right: 12px !important; }
  .cr-daypart-scheduler__schedule-end-time {
    padding-left: 12px !important; }
  .cr-daypart-scheduler__start-end-time-separator {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #43535C;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    height: 46px; }
    .cr-daypart-scheduler__start-end-time-separator.row-1 {
      margin-top: 28px; }
  .cr-daypart-scheduler__schedule-days {
    flex: 0 0 24%;
    max-width: 24%; }
    .cr-daypart-scheduler__schedule-days .cr-days-of-week {
      justify-content: center; }
      .cr-daypart-scheduler__schedule-days .cr-days-of-week .col-6 {
        max-width: unset !important; }
    .cr-daypart-scheduler__schedule-days .cr-days-of-week__day-text {
      margin-bottom: 2px;
      color: #000000;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.25em; }
    .cr-daypart-scheduler__schedule-days .rc-checkbox-inner {
      width: 16px;
      height: 16px; }
    .cr-daypart-scheduler__schedule-days .cr-checkbox__checked-icon {
      top: -1px; }
    .cr-daypart-scheduler__schedule-days .text-danger {
      padding-left: 17%; }
  .cr-daypart-scheduler__schedule-label {
    flex: 0 0 24%;
    max-width: 24%; }
    .cr-daypart-scheduler__schedule-label .cr-textinput__input-wrapper {
      height: 46px; }
    .cr-daypart-scheduler__schedule-label .cr-textinput {
      margin-right: 26px; }
    .cr-daypart-scheduler__schedule-label .trash-icon {
      cursor: pointer;
      position: absolute;
      bottom: 12px;
      right: -6px; }
  @media screen and (min-width: 1200px) and (max-width: 1436px) {
    .cr-daypart-scheduler__schedule-label {
      max-width: 22%; } }

@media (min-width: 1699px) {
  .cr-daypart-scheduler__schedule-start-time {
    padding-right: 30px !important; }
  .cr-daypart-scheduler__schedule-end-time {
    padding-left: 30px !important; } }

.cr-progclock-slots__slot-row {
  display: flex;
  align-items: center;
  margin-bottom: 21px !important; }
  .cr-progclock-slots__slot-row.error {
    margin-bottom: 40px !important; }
    .cr-progclock-slots__slot-row.error .text-danger {
      position: absolute; }

.cr-progclock-slots__national {
  display: flex;
  align-items: center;
  flex: 0 0 10.66667%;
  max-width: 10.66667%; }
  .cr-progclock-slots__national .cr-checkbox__text {
    font-size: 16px;
    line-height: 20px;
    color: #334856; }
  .cr-progclock-slots__national.row-1 {
    margin-top: 26px !important; }

.cr-progclock-slots__category {
  padding-right: 17px !important;
  flex: 0 0 29%;
  max-width: 29%; }

.cr-progclock-slots__type {
  flex: 0 0 29%;
  max-width: 29%; }

.cr-progclock-slots__color {
  flex: 0 0 7.33333%;
  max-width: 7.33333%;
  text-align: center;
  padding-right: 12px;
  padding-left: 12px; }
  .cr-progclock-slots__color .cr-color-picker {
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-progclock-slots__color.row-1 .cr-color-picker__color-list {
    top: 80px;
    left: 20px; }
  .cr-progclock-slots__color .cr-color-picker__color-list {
    top: 48px;
    left: 16px; }

.cr-progclock-slots__slot-label {
  flex: 0 0 24%;
  max-width: 24%; }
  .cr-progclock-slots__slot-label .cr-textinput {
    margin-right: 26px; }
  .cr-progclock-slots__slot-label .input-container {
    margin-bottom: 0px !important; }
  .cr-progclock-slots__slot-label .trash-icon {
    cursor: pointer;
    position: absolute;
    bottom: 12px;
    right: -6px; }
  .cr-progclock-slots__slot-label .reset-icon {
    cursor: pointer;
    position: absolute;
    bottom: 12px;
    right: -18px; }
    .cr-progclock-slots__slot-label .reset-icon span {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #795AFA;
      margin-left: 12px;
      text-transform: none; }

.cr-progclock-slots__create-new-option {
  display: flex;
  align-items: center; }
  .cr-progclock-slots__create-new-option .text {
    font-size: 16px;
    line-height: 40px;
    color: #829FB1;
    width: 95%; }
  .cr-progclock-slots__create-new-option .plus-btn {
    display: flex;
    justify-content: flex-end; }

@media screen and (min-width: 1200px) and (max-width: 1436px) {
  .cr-progclock-slots__national {
    flex: 0 0 13.66667%;
    max-width: 13.66667%; }
  .cr-progclock-slots__category {
    flex: 0 0 27%;
    max-width: 27%; }
  .cr-progclock-slots__type {
    flex: 0 0 27%;
    max-width: 27%; } }

@media (min-width: 1699px) {
  .cr-daypart-scheduler__schedule-start-time {
    padding-right: 30px !important; }
  .cr-daypart-scheduler__schedule-end-time {
    padding-left: 30px !important; } }

.cr-timeslot-line-items {
  margin-bottom: 9px !important; }
  .cr-timeslot-line-items.error {
    margin-bottom: 35px !important; }
    .cr-timeslot-line-items.error .text-danger {
      position: absolute; }
  .cr-timeslot-line-items .cr-input-time div.hour-container {
    display: flex;
    flex-wrap: wrap;
    min-width: 26px;
    justify-content: center;
    margin-right: 10px; }
    .cr-timeslot-line-items .cr-input-time div.hour-container label {
      margin-top: 5px; }
  .cr-timeslot-line-items .cr-input-time .cr-input-time__separator {
    padding-right: 10px;
    margin-top: 26px !important; }
  .cr-timeslot-line-items .cr-input-time div.minute-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 80%; }
    .cr-timeslot-line-items .cr-input-time div.minute-container label {
      margin-top: 5px; }
    .cr-timeslot-line-items .cr-input-time div.minute-container .minute-input-wrapper {
      width: 86%; }
      .cr-timeslot-line-items .cr-input-time div.minute-container .minute-input-wrapper .cr-input-time__minute {
        width: 100%;
        margin: 0px; }
  .cr-timeslot-line-items .cr-input-time .cr-input-time__ampm {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    margin-top: 28px; }
  .cr-timeslot-line-items .react-select__control {
    min-height: 47px !important;
    padding-left: 17px; }
  .cr-timeslot-line-items__dd-icon-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative; }
    .cr-timeslot-line-items__dd-icon-wrapper .color-indicator {
      position: absolute;
      width: 17px;
      height: 100%;
      z-index: 1;
      border-radius: 4px 0px 0px 4px; }
  .cr-timeslot-line-items__dd {
    display: flex;
    flex: 1 1;
    margin-right: 20px; }
    .cr-timeslot-line-items__dd > div {
      flex-grow: 1; }
    .cr-timeslot-line-items__dd .react-select__multi-value__label {
      display: block !important; }
    .cr-timeslot-line-items__dd .react-select__multi-value {
      max-width: 170px; }
  .cr-timeslot-line-items__icon {
    cursor: pointer; }
  .cr-timeslot-line-items__slot-priority {
    padding-right: 35px !important;
    padding-left: 18px !important;
    flex: 0 0 19.66667%;
    max-width: 19.66667%; }
    .cr-timeslot-line-items__slot-priority .cr-textinput__input-wrapper {
      height: 47px !important;
      margin-bottom: 0px; }
  .cr-timeslot-line-items__trash-icon {
    position: absolute;
    top: 44px;
    right: 0px;
    cursor: pointer; }

.cr-timeslot-line-items.special .cr-input-time__separator {
  margin-top: -5px !important; }

.cr-timeslot-line-items.special .cr-input-time__ampm {
  margin-top: 0; }

.cr-timeslot-line-items.special .cr-timeslot-line-items__lock-icon {
  top: 10px; }

.cr-timeslot-line-items.special .cr-timeslot-line-items__trash-icon {
  top: 14px; }

.cr-prog-slots-filter-dropdown__option-container .react-select__option {
  padding-left: 24px !important; }

.cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-6A9C00 .rc-checkbox-inner {
  border-color: #6A9C00 !important;
  background-color: #6A9C00 !important; }
  .cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-6A9C00 .rc-checkbox-inner:after {
    border-color: #6A9C00; }

.cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-009C77 .rc-checkbox-inner {
  border-color: #009C77 !important;
  background-color: #009C77 !important; }
  .cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-009C77 .rc-checkbox-inner:after {
    border-color: #009C77; }

.cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-077FC2 .rc-checkbox-inner {
  border-color: #077FC2 !important;
  background-color: #077FC2 !important; }
  .cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-077FC2 .rc-checkbox-inner:after {
    border-color: #077FC2; }

.cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-4654D3 .rc-checkbox-inner {
  border-color: #4654D3 !important;
  background-color: #4654D3 !important; }
  .cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-4654D3 .rc-checkbox-inner:after {
    border-color: #4654D3; }

.cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-A646D3 .rc-checkbox-inner {
  border-color: #A646D3 !important;
  background-color: #A646D3 !important; }
  .cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-A646D3 .rc-checkbox-inner:after {
    border-color: #A646D3; }

.cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-D34646 .rc-checkbox-inner {
  border-color: #D34646 !important;
  background-color: #D34646 !important; }
  .cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-D34646 .rc-checkbox-inner:after {
    border-color: #D34646; }

.cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-D7811C .rc-checkbox-inner {
  border-color: #D7811C !important;
  background-color: #D7811C !important; }
  .cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-D7811C .rc-checkbox-inner:after {
    border-color: #D7811C; }

.cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-BCAA07 .rc-checkbox-inner {
  border-color: #BCAA07 !important;
  background-color: #BCAA07 !important; }
  .cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-BCAA07 .rc-checkbox-inner:after {
    border-color: #BCAA07; }

.cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-A97C07 .rc-checkbox-inner {
  border-color: #A97C07 !important;
  background-color: #A97C07 !important; }
  .cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-A97C07 .rc-checkbox-inner:after {
    border-color: #A97C07; }

.cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-93886D .rc-checkbox-inner {
  border-color: #93886D !important;
  background-color: #93886D !important; }
  .cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-93886D .rc-checkbox-inner:after {
    border-color: #93886D; }

.cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-74896D .rc-checkbox-inner {
  border-color: #74896D !important;
  background-color: #74896D !important; }
  .cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-74896D .rc-checkbox-inner:after {
    border-color: #74896D; }

.cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-6D7189 .rc-checkbox-inner {
  border-color: #6D7189 !important;
  background-color: #6D7189 !important; }
  .cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__checkbox-6D7189 .rc-checkbox-inner:after {
    border-color: #6D7189; }

.cr-prog-slots-filter-dropdown .cr-checkbox .cr-checkbox__text {
  margin-left: 15px;
  color: #111B2B; }

.cr-prog-slots-filter-dropdown__filter-button-container {
  display: flex;
  align-items: center;
  margin: 30px 34px; }

.cr-prog-slots-filter-dropdown__value-container {
  padding-left: 15px;
  cursor: pointer; }
  .cr-prog-slots-filter-dropdown__value-container span {
    color: #829FB1; }
  .cr-prog-slots-filter-dropdown__value-container .cr-checkbox__text {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 180px;
    overflow: hidden; }

.cr-custom-multiselect-dropdown__option-container .react-select__option {
  padding-left: 24px !important; }

.cr-custom-multiselect-dropdown__option-select-group .react-select__option {
  padding-left: 30px !important; }

.cr-custom-multiselect-dropdown .cr-checkbox .cr-checkbox__text {
  margin-left: 15px;
  color: #111b2b; }

.cr-custom-multiselect-dropdown__value-container {
  padding-left: 7px;
  cursor: pointer;
  font-weight: normal;
  line-height: 20px;
  color: #334856; }

.cr-custom-multiselect-dropdown .exclude-dates-btn {
  color: #795afa;
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  cursor: pointer;
  text-transform: capitalize; }

.cr-custom-multiselect-dropdown__group-heading-checkbox .cr-checkbox__text {
  font-size: 16px; }

.cr-insert-button-modal {
  max-width: 600px;
  width: 600px; }
  .cr-insert-button-modal .modal-title {
    color: #334856;
    font-weight: 700;
    font-size: 23px;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    flex: 1 1; }
  .cr-insert-button-modal .modal-header {
    height: 70px;
    padding: 0 48px;
    border-bottom: 1px solid #edf1f6; }
  .cr-insert-button-modal__close {
    background: transparent;
    border: none;
    cursor: pointer; }
  .cr-insert-button-modal .modal-body {
    padding: 35px 48px; }
  .cr-insert-button-modal label {
    color: #334856;
    text-transform: uppercase; }
  .cr-insert-button-modal .form-group {
    margin-bottom: 25px; }
  .cr-insert-button-modal__form-buttons {
    text-align: center; }
    .cr-insert-button-modal__form-buttons .btn {
      margin: 0 7.5px; }

.cr-admin-card__background-swirl {
  height: 84px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.cr-admin-card__server-image {
  display: flex;
  align-items: center;
  justify-content: center; }
  .cr-admin-card__server-image img {
    width: 100%;
    padding: 20px; }

.cr-admin-card__server-text {
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  text-transform: capitalize;
  text-align: center; }

.cr-upload-files__drop-area {
  background-color: #eff3f6;
  padding: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  outline: none;
  flex-wrap: wrap;
  cursor: pointer;
  height: 87px;
  border-width: 1px;
  border-radius: 6px;
  text-transform: capitalize; }

.cr-upload-files__drop-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center; }

.cr-upload-files__drop-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #8165f3;
  margin-left: 17px; }

.cr-upload-files__files-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around; }

.cr-upload-files__files-name-wrapper {
  color: #795afa;
  font-weight: 700;
  margin-right: 10px;
  text-transform: initial; }

.cr-calendar-modal {
  min-width: 600px; }
  .cr-calendar-modal .modal-header {
    padding: 20px 20px 20px 40px;
    margin-bottom: 0;
    border-bottom: 1px solid #edf1f6;
    position: relative; }
  .cr-calendar-modal .modal-title {
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856; }
  .cr-calendar-modal .modal-body {
    padding: 30px 42px; }
  .cr-calendar-modal .close {
    display: none; }
  .cr-calendar-modal__close-icon {
    position: absolute;
    right: 20px; }
  .cr-calendar-modal .btn-blue {
    margin-right: 7px; }
  .cr-calendar-modal .btn-outline-blue {
    margin-left: 7px; }
  .cr-calendar-modal label {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #43535c;
    text-transform: uppercase; }
  .cr-calendar-modal__buttons {
    margin-top: 30px; }

.cr-comments-sidebar.custom-collapse {
  transition: max-width 0.1s ease-out;
  overflow: hidden;
  max-width: 405px; }

.cr-comments-sidebar.custom-collapse.expanded {
  transition: max-width 0.1s ease-out;
  max-width: 81px; }
  .cr-comments-sidebar.custom-collapse.expanded .cr-chatbox {
    min-width: initial; }

.cr-comments-sidebar.custom-collapse.unexpanded {
  transition: max-width 0.1s ease-out;
  padding-bottom: 300px; }

.cr-comments-sidebar.unexpanded .targetCollapse {
  display: block; }

.cr-comments-sidebar__collapse-with-switch {
  margin-top: 43px; }

.cr-comments-sidebar__comments {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.102);
  border-radius: 100px; }
  .cr-comments-sidebar__comments.comments-panel-icon-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
    z-index: 99; }

.cr-comments-sidebar__comment-red {
  position: absolute;
  top: 8px;
  left: 20px;
  border: 1px solid #fff;
  height: 17px;
  min-width: 17px;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  display: block;
  background-color: #e40061;
  padding: 0 4px;
  box-sizing: border-box;
  border-radius: 10px; }

.cr-comments-sidebar__comment-white {
  position: absolute;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  top: 15px;
  left: 0;
  width: 100%; }

.cr-comments-sidebar__tagged-users {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 4px; }
  .cr-comments-sidebar__tagged-users .avatar-sort-name-text {
    font-size: 15px;
    line-height: initial; }
  .cr-comments-sidebar__tagged-users .cr-avatar {
    margin: 4px 0;
    cursor: pointer; }

@media screen and (min-width: 1200px) and (max-width: 1436px) {
  .cr-comments-sidebar.custom-collapse {
    max-width: 400px;
    margin-left: 10px; }
    .cr-comments-sidebar.custom-collapse .cr-chatbox {
      width: 100%; } }

.cr-page-processes-add-basic_production .cr-comments-sidebar {
  margin-top: 43px; }

.cr-admin-card__background-swirl {
  height: 84px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.cr-admin-card__server-image {
  display: flex;
  align-items: center;
  justify-content: center; }
  .cr-admin-card__server-image img {
    width: 100%;
    padding: 20px; }

.cr-admin-card__server-text {
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  text-transform: capitalize;
  text-align: center; }

.cr-upload-files__drop-area {
  background-color: #eff3f6;
  padding: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  outline: none;
  flex-wrap: wrap;
  cursor: pointer;
  height: 87px;
  border-width: 1px;
  border-radius: 6px;
  text-transform: capitalize; }

.cr-upload-files__drop-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center; }

.cr-upload-files__drop-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #8165f3;
  margin-left: 17px; }

.cr-upload-files__files-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around; }

.cr-upload-files__files-name-wrapper {
  color: #795afa;
  font-weight: 700;
  margin-right: 10px;
  text-transform: initial; }

.cr-calendar-modal {
  min-width: 600px; }
  .cr-calendar-modal .modal-header {
    padding: 20px 20px 20px 40px;
    margin-bottom: 0;
    border-bottom: 1px solid #edf1f6;
    position: relative; }
  .cr-calendar-modal .modal-title {
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856; }
  .cr-calendar-modal .modal-body {
    padding: 30px 42px; }
  .cr-calendar-modal .close {
    display: none; }
  .cr-calendar-modal__close-icon {
    position: absolute;
    right: 20px; }
  .cr-calendar-modal .btn-blue {
    margin-right: 7px; }
  .cr-calendar-modal .btn-outline-blue {
    margin-left: 7px; }
  .cr-calendar-modal label {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #43535c;
    text-transform: uppercase; }
  .cr-calendar-modal__buttons {
    margin-top: 30px; }

.cr-user-assignments-modal {
  min-width: 1307px; }
  .cr-user-assignments-modal .cr-user-assignments__view-content {
    box-shadow: none; }
  .cr-user-assignments-modal .modal-body {
    padding: 0;
    padding-bottom: 40px; }
  .cr-user-assignments-modal .modal-header {
    padding: 20px 20px 20px 48px;
    border-bottom: 1px solid #edf1f6; }
  .cr-user-assignments-modal .modal-title {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-transform: capitalize; }
  .cr-user-assignments-modal .close {
    display: none; }
  .cr-user-assignments-modal__close {
    background: transparent;
    border: none; }
  .cr-user-assignments-modal .cr-user-assignments__switch-apply .react-switch-handle:focus {
    pointer-events: none; }

.cr-order-details-schedule__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
  padding-top: 34px; }

.cr-order-details-schedule .modal-body {
  padding: 0; }

.cr-order-details-schedule__btn-cancel {
  margin-left: 7.5px; }

.cr-order-details-schedule__btn-submit {
  margin-right: 7.5px; }

.cr-order-details-schedule label {
  color: #43535c;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  display: block;
  font-style: normal;
  font-weight: normal; }

.cr-order-details-schedule__title {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 29px;
  color: #334856;
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #e9ecef; }

.cr-order-details-schedule__form-group-view {
  margin-bottom: 36px; }
  .cr-order-details-schedule__form-group-view label {
    color: #657894; }

.cr-order-details-schedule__order-view-value {
  font-size: 16px;
  line-height: 20px;
  color: #000000; }

.cr-prize-form {
  padding-bottom: 84px; }
  .cr-prize-form__content-form label {
    color: #43535c;
    text-transform: uppercase;
    margin-bottom: 8px; }
  .cr-prize-form__content-form .form-group {
    margin-bottom: 27px; }
  .cr-prize-form__content-form .cr-select-tags .react-select__dropdown-indicator {
    padding: 0 19px !important; }
  .cr-prize-form .cr-days-of-week__label-text {
    display: block; }
  .cr-prize-form__content-view {
    margin-top: 58.62px; }
    .cr-prize-form__content-view label {
      letter-spacing: 0.05em;
      color: #657894;
      text-transform: uppercase; }
    .cr-prize-form__content-view .form-group {
      margin-bottom: 27px; }
    .cr-prize-form__content-view .cr-prize-form__table-right .cr-prize-form__table-text {
      padding-left: 15px; }
    .cr-prize-form__content-view .cr-prize-form__section-title.smaller span {
      font-size: 20px !important; }
  .cr-prize-form__prize-value-view {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 100%; }
  .cr-prize-form__section-form {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px;
    margin-bottom: 20px; }
  .cr-prize-form__col-reset {
    margin-bottom: 0 !important; }
  .cr-prize-form__form {
    margin-top: 58.62px; }
  .cr-prize-form__section-title {
    border-bottom: 1px solid #edf1f6;
    height: 66px;
    padding: 0 35px;
    display: flex;
    align-items: center; }
    .cr-prize-form__section-title span {
      font-weight: bold;
      font-size: 23px;
      color: #000000;
      text-transform: capitalize; }
  .cr-prize-form .station-itemized-section.section-close .section-body,
  .cr-prize-form .station-itemized-section.section-close .cr-prize-form__allocation-fields {
    display: none; }
  .cr-prize-form .station-itemized-section.section-open .chevron svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .cr-prize-form .station-itemized-section .chevron {
    height: 20px;
    margin-left: 30px; }
  .cr-prize-form__itemized-allocation {
    padding-bottom: 52px;
    padding-top: 14px; }
  .cr-prize-form__section-itemize-price {
    border-bottom: none;
    border-top: 1px solid #edf1f6;
    justify-content: space-between; }
    .cr-prize-form__section-itemize-price.smaller {
      font-size: 18px;
      font-weight: 700; }
      .cr-prize-form__section-itemize-price.smaller span {
        font-size: 18px;
        font-weight: 700; }
    .cr-prize-form__section-itemize-price .itemized-counts {
      display: flex;
      justify-content: center;
      align-items: center; }
      .cr-prize-form__section-itemize-price .itemized-counts span {
        font-size: 16px;
        color: #537a91; }
        .cr-prize-form__section-itemize-price .itemized-counts span.count-title {
          margin-left: 16px; }
        .cr-prize-form__section-itemize-price .itemized-counts span.count {
          color: #43535c;
          margin-left: 2px; }
  .cr-prize-form__fields {
    padding-left: 35px;
    padding-right: 35px; }
  .cr-prize-form__item-fields {
    padding-top: 42px;
    padding-bottom: 35px; }
  .cr-prize-form__allocation-fields {
    padding-top: 45px;
    padding-bottom: 38px; }
    .cr-prize-form__allocation-fields.small-padding {
      padding-top: 0px; }
  .cr-prize-form__fulfillment-fields {
    padding-top: 40px;
    padding-bottom: 58px;
    padding-right: 33px; }
  .cr-prize-form .cr-radio-buttons__radios {
    display: flex;
    height: 50px; }
  .cr-prize-form__station-head label {
    width: 50%;
    margin-bottom: 12px; }
    .cr-prize-form__station-head label:last-child {
      padding-left: 15px; }
  .cr-prize-form__itemization-detail {
    padding: 10px 15px;
    border-left: 1px solid #c2d4e0;
    border-top: 1px solid #c2d4e0;
    flex: 1 1;
    font-size: 16px; }
    .cr-prize-form__itemization-detail.bold {
      font-weight: 700; }
    .cr-prize-form__itemization-detail:first-child {
      border-left: none; }
  .cr-prize-form__itemized-prize-assigned .item-row:first-child .cr-prize-form__itemization-detail {
    border-top: none; }
  .cr-prize-form__itemized-prize-assigned.bordered {
    border-radius: 6px;
    border: 1px solid #c2d4e0;
    overflow: hidden; }
  .cr-prize-form__itemized-prize-assigned.smaller span {
    font-size: 20px; }
  .cr-prize-form__itemized-prize-assigned .cr-textinput {
    margin-top: 14px;
    width: 30%;
    display: flex;
    flex-direction: column; }
    .cr-prize-form__itemized-prize-assigned .cr-textinput .input-container {
      margin-bottom: 0 !important; }
  .cr-prize-form__itemized-prize-assigned .add-itemized-price {
    margin-top: 32px;
    border: 1px solid #829fb1; }
  .cr-prize-form__itemized-price-trash {
    height: 48px;
    margin-top: 18px;
    display: flex;
    align-items: center;
    cursor: pointer; }
    .cr-prize-form__itemized-price-trash.first-trash {
      margin-top: 42px;
      visibility: hidden; }
  .cr-prize-form__toggle-switch-input {
    height: 48px;
    margin-top: 32px;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
    display: flex; }
  .cr-prize-form__group-radios {
    display: flex;
    flex-direction: column; }
    .cr-prize-form__group-radios label {
      text-transform: none;
      color: #43535c;
      font-size: 14px; }
      .cr-prize-form__group-radios label .cr-radio-buttons__label {
        margin-left: 8px; }
  .cr-prize-form__station-item {
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 6px;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 50px; }
    .cr-prize-form__station-item.error-block {
      margin-top: -14px;
      height: -webkit-max-content;
      height: max-content;
      border: none; }
    .cr-prize-form__station-item .cr-textinput__input-wrapper {
      border: none;
      height: 100%;
      border-left: 1px solid #c2d4e0;
      border-radius: 0; }
    .cr-prize-form__station-item .cr-textinput {
      height: 100%;
      width: 100%; }
  .cr-prize-form__station-name {
    font-weight: bold;
    font-size: 16px;
    color: #334856;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 25px; }
  .cr-prize-form__station-qty {
    flex: 0 0 50%;
    display: flex;
    align-items: center;
    max-width: 50%;
    position: relative;
    height: 100%;
    padding-left: 15px; }
    .cr-prize-form__station-qty .cr-textinput__input-field input {
      border-radius: 0; }
      .cr-prize-form__station-qty .cr-textinput__input-field input:hover, .cr-prize-form__station-qty .cr-textinput__input-field input:focus {
        border-color: transparent;
        box-shadow: none;
        border-radius: 0; }
  .cr-prize-form__station-trash {
    position: absolute;
    right: 22px;
    top: 14px;
    cursor: pointer; }
  .cr-prize-form__allocation-table {
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 5px; }
  .cr-prize-form__table-head, .cr-prize-form__table-row {
    display: flex;
    flex-direction: row; }
  .cr-prize-form__table-row {
    height: 52px;
    align-items: center; }
  .cr-prize-form__table-text {
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    text-transform: uppercase; }
  .cr-prize-form__table-left {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 22px; }
  .cr-prize-form__table-right {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 15px; }
    .cr-prize-form__table-right .cr-prize-form__table-text {
      padding-left: 12px; }
  .cr-prize-form__table-head {
    background-color: #f7fbfd;
    height: 38px;
    border-bottom: 1px solid #c2d4e0; }
    .cr-prize-form__table-head span {
      font-weight: 600;
      font-size: 12px;
      color: #829fb1;
      text-transform: uppercase;
      line-height: 38px; }
  .cr-prize-form__form-group-stations {
    margin-bottom: 38px !important; }
  .cr-prize-form__select-stations .react-select__multi-value {
    display: none; }
  .cr-prize-form__select-stations .react-select__clear-indicator {
    visibility: hidden; }
  .cr-prize-form__station-shared {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 15px; }
    .cr-prize-form__station-shared .cr-prize-form__station-item {
      position: relative;
      justify-content: flex-start; }
  .cr-prize-form__checkboxes {
    display: flex; }
    .cr-prize-form__checkboxes .cr-checkbox {
      margin-right: 37px; }
    .cr-prize-form__checkboxes .cr-checkbox__text {
      color: #334856; }
  .cr-prize-form__btn-submit {
    margin-right: 14px; }
  .cr-prize-form__btn-disabled {
    background-color: #c2d4e0 !important;
    border: 1px solid #c2d4e0 !important; }
  .cr-prize-form__no-audience {
    margin-left: 32px;
    margin-top: 16px; }
    .cr-prize-form__no-audience label {
      -webkit-transform: translateY(25%);
              transform: translateY(25%);
      margin-left: 32px;
      max-width: 220px;
      margin-bottom: 0; }
      .cr-prize-form__no-audience label span {
        font-weight: 800;
        font-size: 23px;
        line-height: 29px;
        color: #0f5589; }
  .cr-prize-form__fulfill-digital, .cr-prize-form__fulfill-mail-out, .cr-prize-form__fulfill-location {
    margin-top: 27px; }
  .cr-prize-form__to-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin: 0; }
  .cr-prize-form__station-view-qty {
    display: flex;
    height: 100%;
    align-items: center;
    border-left: 1px solid #c2d4e0;
    padding-left: 15px; }
  .cr-prize-form .react-select__placeholder {
    color: #829fb1 !important; }
  .cr-prize-form .cr-toggle-switch__left-component {
    font-size: 17px;
    color: #374c59;
    text-transform: uppercase;
    line-height: 48px; }
  .cr-prize-form__nav-wrapper {
    margin: 0 42px; }
    .cr-prize-form__nav-wrapper .nav-tabs {
      margin-bottom: -2px; }
      .cr-prize-form__nav-wrapper .nav-tabs .nav-item:not(.is-clickable) {
        pointer-events: none; }
      .cr-prize-form__nav-wrapper .nav-tabs .nav-item .nav-link {
        width: 180px;
        display: flex;
        justify-content: center;
        padding-top: 14px;
        padding-bottom: 28px;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.05em;
        color: #795afa;
        text-transform: uppercase; }
        .cr-prize-form__nav-wrapper .nav-tabs .nav-item .nav-link:hover {
          border: none !important; }
        .cr-prize-form__nav-wrapper .nav-tabs .nav-item .nav-link.active {
          border: none;
          color: #43535c;
          font-weight: normal;
          box-shadow: 0px -3px 6px -2px rgba(0, 0, 0, 0.102); }
    .cr-prize-form__nav-wrapper .tab-pane .cr-prize-form__fields .form-group label {
      font-size: 12px;
      line-height: 15px;
      color: #43535c;
      text-transform: uppercase; }
    .cr-prize-form__nav-wrapper .tab-pane .cr-prize-form__fields .form-group .cr-radio-buttons .cr-radio-buttons__label span {
      text-transform: initial; }
    .cr-prize-form__nav-wrapper .tab-content {
      padding: 60px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
      border-radius: 6px;
      margin-bottom: 30px; }
      .cr-prize-form__nav-wrapper .tab-content .cr-prize-form__station-shared {
        margin-top: 30px; }
      .cr-prize-form__nav-wrapper .tab-content .cr-prize-form__section-form .form-group label {
        font-size: 12px;
        line-height: 15px;
        color: #43535c;
        text-transform: uppercase; }
      .cr-prize-form__nav-wrapper .tab-content .cr-prize-form__section-form .form-group .cr-radio-buttons .cr-radio-buttons__label span {
        text-transform: initial; }
    .cr-prize-form__nav-wrapper .split-form-buttons {
      margin-left: 50px;
      margin-bottom: 43px; }
  .cr-prize-form__form-buttons .buttons {
    display: flex;
    justify-content: space-between; }
  @media (min-width: 1599px) {
    .cr-prize-form .bottom-actions-sticky .buttons {
      padding-left: 70px;
      padding-right: 70px; } }

.cr-dropdown-location .react-select__menu-list {
  padding-bottom: 0 !important; }

.cr-dropdown-location__menu-create-location {
  border-top: 1px solid #c2d4e0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 23px; }

.cr-dropdown-location .cr-textinput {
  width: calc(100% - 50px); }

.cr-dropdown-location .cr-textinput__input-wrapper {
  border: none;
  box-shadow: none; }
  .cr-dropdown-location .cr-textinput__input-wrapper input:focus, .cr-dropdown-location .cr-textinput__input-wrapper input:hover, .cr-dropdown-location .cr-textinput__input-wrapper input:active {
    box-shadow: none;
    border: none; }

.cr-location-modal {
  width: 962px;
  max-width: 962px; }
  .cr-location-modal__header {
    height: 63px;
    border-bottom: 1px solid #edf1f6;
    padding: 0 35px; }
    .cr-location-modal__header .close {
      display: none; }
  .cr-location-modal .modal-title {
    font-weight: bold;
    font-size: 23px;
    color: #000000;
    display: flex;
    align-items: center;
    height: 100%;
    text-transform: capitalize; }
  .cr-location-modal__body {
    padding: 40px 35px 53px 35px; }

.cr-location-form__content-form label {
  color: #43535c;
  margin-bottom: 6px;
  text-transform: uppercase; }

.cr-location-form__content-form .form-group {
  margin-bottom: 28px; }

.cr-location-form__buttons {
  margin-top: 32px; }
  .cr-location-form__buttons .btn-blue {
    margin-right: 14px; }

.cr-location-form__to-title {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center; }

.cr-enlarge-asset-modal.modal-dialog {
  max-width: 1108px; }

.cr-enlarge-asset-modal .close {
  display: none; }

.cr-enlarge-asset-modal .modal-title {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%; }

.cr-enlarge-asset-modal__header {
  border-bottom: none; }

.cr-enlarge-asset-modal__close {
  border: none;
  cursor: pointer;
  background: transparent; }
  .cr-enlarge-asset-modal__close:focus, .cr-enlarge-asset-modal__close:active {
    box-shadow: none;
    outline: none; }

.cr-enlarge-asset-modal__image {
  max-width: 100%; }

.cr-enlarge-asset-modal__body {
  padding-left: 103px;
  padding-right: 100px;
  padding-bottom: 100px;
  padding-top: 32px;
  text-align: center; }

.cr-icon-selector {
  width: 93px;
  border-radius: 4px;
  padding: 0;
  background-color: #fff;
  position: absolute;
  z-index: 9;
  right: -22px;
  top: 33px;
  -webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  -moz-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25)); }
  .cr-icon-selector__icon {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    text-align: center;
    cursor: pointer; }
    .cr-icon-selector__icon:hover {
      background: rgba(194, 212, 224, 0.3); }
  .cr-icon-selector__active {
    background: rgba(194, 212, 224, 0.3); }
  .cr-icon-selector::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 14px solid white;
    position: absolute;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    top: -14px;
    right: 39px; }

.cr-digital-report {
  margin-bottom: 80px; }
  .cr-digital-report__channel {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px;
    margin-bottom: 20px; }
  .cr-digital-report__channel-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 34px 0 49px;
    height: 88px; }
  .cr-digital-report__channel-content {
    padding: 0 34px 0 49px; }
  .cr-digital-report__channel-content-form {
    padding-top: 49px;
    padding-bottom: 74px;
    max-width: 651px; }
  .cr-digital-report__channel-content-view {
    padding-top: 55px;
    padding-bottom: 42px;
    max-width: 651px; }
    .cr-digital-report__channel-content-view .cr-handle-assets__file-item:last-child {
      margin-bottom: 0; }
  .cr-digital-report__channel-open .cr-digital-report__channel-head {
    height: 92px;
    border-bottom: 1px solid #edf1f6; }
  .cr-digital-report__button-down {
    border: none;
    outline: none;
    padding: 0;
    background: transparent;
    cursor: pointer; }
    .cr-digital-report__button-down:focus, .cr-digital-report__button-down:active {
      outline: none; }
  .cr-digital-report__section-main-info {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: flex-start; }
  .cr-digital-report__channel-title {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856;
    margin-left: 23px;
    margin-bottom: 0;
    text-transform: capitalize; }
  .cr-digital-report__channel-title-inactive {
    font-style: normal;
    font-weight: 800;
    font-size: 23px;
    line-height: 29px;
    color: #829fb1; }
  .cr-digital-report__status {
    margin-right: 39px;
    font-size: 14px;
    color: #829fb1;
    text-transform: capitalize; }
    .cr-digital-report__status strong {
      color: #334856;
      margin-left: 5px; }
  .cr-digital-report__channel-head-right {
    display: flex;
    align-items: center; }
  .cr-digital-report__view-field-label {
    color: #43535c;
    margin-bottom: 18px; }
  .cr-digital-report__order-view-value {
    color: #334856; }
  .cr-digital-report__col-view-field {
    margin-bottom: 46px; }
  .cr-digital-report__col-reset {
    margin-bottom: 0; }
  .cr-digital-report__col-form-field {
    margin-bottom: 42px; }
  .cr-digital-report__form-handle-assets {
    margin-bottom: 22px; }
    .cr-digital-report__form-handle-assets .cr-handle-assets__file-name {
      color: #334856; }

.report-container-bottom-buttons {
  margin-left: 49px;
  margin-top: 0;
  /* & .button-edit.disabled{
    display: none;
  } */ }

.cr-display-settings-form__title {
  color: #000000;
  font-size: 23px;
  font-weight: 700;
  text-transform: none; }

.cr-display-settings-form__subtitle {
  font-size: 16px;
  color: #537A91;
  margin-bottom: 16px; }

.cr-display-settings-form__font-size-box {
  background: #FFFFFF;
  border: 1px solid #C2D4E0;
  box-sizing: border-box;
  border-radius: 6px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.cr-display-settings-form__hr {
  border-bottom: 1px solid #EDF1F6;
  margin: 16px -64px; }
  .cr-display-settings-form__hr.padded {
    margin-bottom: 38px;
    margin-top: 38px; }

.cr-display-settings-form__jock-tags {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-right: 24px; }

.cr-display-settings-form__unused-widgets-wrapper {
  display: flex;
  background: #EFF3F6;
  border: 1px dashed #829FB1;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 20px 24px;
  color: #829FB1;
  font-size: 16px;
  line-height: 20px; }
  .cr-display-settings-form__unused-widgets-wrapper.has-data {
    padding: 6px; }
  .cr-display-settings-form__unused-widgets-wrapper.empty-widgets {
    padding: 18px 24px;
    color: #334856;
    font-size: 20px;
    line-height: 25px; }
  .cr-display-settings-form__unused-widgets-wrapper .cr-jock-console__jock-tag {
    margin-bottom: 0; }

.cr-display-settings-form .form-group label {
  font-size: 12px;
  line-height: 15px;
  color: #43535C;
  text-transform: uppercase; }

.cr-display-settings-form .setupTextStyle {
  display: flex;
  align-items: center; }
  .cr-display-settings-form .setupTextStyle .form-group {
    width: 100px;
    margin-bottom: 0; }
  .cr-display-settings-form .setupTextStyle .cr-setting-font-size {
    width: inherit; }

.cr-display-settings-modal {
  max-width: 80%; }
  .cr-display-settings-modal .modal-content {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    overflow: hidden; }
  .cr-display-settings-modal .modal-header {
    background-color: #34a7e1;
    color: white;
    border-bottom: none;
    padding-left: 48px; }
    .cr-display-settings-modal .modal-header .modal-title {
      font-weight: 800; }
    .cr-display-settings-modal .modal-header .close {
      color: white; }
  .cr-display-settings-modal .modal-body {
    padding: 0; }
  .cr-display-settings-modal .card-body {
    overflow: hidden;
    padding: 24px 48px; }
  .cr-display-settings-modal__button-cancel {
    background: #ffffff;
    border: 1px solid #acc4d4;
    border-radius: 24px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #795afa; }

.cr-setting-font-size {
  display: flex;
  align-items: center; }
  .cr-setting-font-size .range {
    width: calc(100% - 100px); }
  .cr-setting-font-size .btControl {
    background: #00a6e0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    color: white;
    font-weight: bold;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    font-size: 22px;
    cursor: pointer;
    line-height: 22px; }
    .cr-setting-font-size .btControl.left {
      margin-right: 20px; }
    .cr-setting-font-size .btControl.right {
      margin-left: 20px; }

.cr-promotion-liner-details--edit .form-group {
  margin-bottom: 27px; }

.cr-promotion-liner-details--view .form-group {
  margin-bottom: 30px; }

.cr-promotion-liner-details__reset-col, .cr-promotion-liner-details__reset-form-group {
  margin-bottom: 0 !important; }

.cr-promotion-liner-details__order-view-label {
  margin-bottom: 10px; }

.cr-promotion-liner-details__order-view-value {
  font-size: 16px;
  line-height: 20px;
  color: #000000; }

.cr-promotion-liner-details__col-reset {
  margin-bottom: 0 !important; }

.cr-promotion-liner-details__section-audience {
  padding-top: 28px !important;
  padding-bottom: 33px !important; }
  .cr-promotion-liner-details__section-audience .select-stations-wrapper {
    max-width: 370px; }

.cr-promotion-liner-details__section-audience-view {
  padding-top: 37px !important;
  padding-bottom: 54px !important; }

.cr-promotion-liner-details__quick-availability-container .disable-availability .btn-expand {
  color: #829fb1 !important; }

.cr-promotion-liner-details__quick-availability-container .disable-availability svg path {
  fill: #829fb1; }

.cr-promotion-liner-details__quick-availability-container .button-row {
  display: flex;
  align-items: center; }
  .cr-promotion-liner-details__quick-availability-container .button-row div {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase; }
  .cr-promotion-liner-details__quick-availability-container .button-row div:not(.btn-expand) {
    color: #829fb1; }
  .cr-promotion-liner-details__quick-availability-container .button-row .button-quick-view {
    display: flex;
    cursor: pointer;
    align-items: center; }
  .cr-promotion-liner-details__quick-availability-container .button-row .btn-expand {
    color: #795afa;
    margin-left: 12px; }
  .cr-promotion-liner-details__quick-availability-container .button-row div.icon {
    padding-top: 2px;
    margin-left: 12px; }

.cr-promotion-liner-details__quick-view-section-title {
  margin-top: 25px;
  border: none !important; }

.cr-promotion-liner-details__switch-toolbar.cr-toggle-switch .switch-text {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  color: #829fb1;
  text-transform: uppercase; }
  .cr-promotion-liner-details__switch-toolbar.cr-toggle-switch .switch-text.active {
    color: #334856; }

.cr-promotion-liner-details__switch-toolbar.cr-toggle-switch .react-switch-bg {
  background: #795afa !important; }

.cr-promotion-liner-details__quick-view-section-toolbar {
  display: flex;
  align-items: center; }
  .cr-promotion-liner-details__quick-view-section-toolbar .react-select-container {
    margin-left: 30px; }
    .cr-promotion-liner-details__quick-view-section-toolbar .react-select-container .react-select__control {
      min-width: 300px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #334856; }
  .cr-promotion-liner-details__quick-view-section-toolbar .react-select__single-value .value-container {
    display: flex; }
    .cr-promotion-liner-details__quick-view-section-toolbar .react-select__single-value .value-container .type {
      min-width: 110px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 10px; }

.cr-promotion-liner-details__quick-view-section-fields {
  padding-top: 0px !important;
  padding-bottom: 35px !important; }

.cr-promotion-liner-details__availability-table .scroll-bar-style::-webkit-scrollbar {
  display: block;
  width: auto;
  height: 8px; }

.cr-promotion-liner-details__availability-table .vertical-scroll {
  overflow-y: auto;
  max-height: 710px; }

.cr-promotion-liner-details__availability-table .rules {
  height: 55px;
  border-top: 1px solid #829fb1;
  display: flex;
  align-items: center; }
  .cr-promotion-liner-details__availability-table .rules .header {
    padding-left: 25px; }
  .cr-promotion-liner-details__availability-table .rules .left-column {
    margin-right: 10px; }
  .cr-promotion-liner-details__availability-table .rules .cr-checkbox__text {
    font-size: 14px;
    color: #334856;
    text-align: left;
    white-space: nowrap; }
  .cr-promotion-liner-details__availability-table .rules .re-calculate {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 29px;
    white-space: nowrap; }
    .cr-promotion-liner-details__availability-table .rules .re-calculate span {
      color: #795afa;
      font-size: 14px;
      margin-left: 5px; }
  .cr-promotion-liner-details__availability-table .rules .weeks {
    height: 55px;
    display: flex;
    align-items: center; }
  .cr-promotion-liner-details__availability-table .rules .week {
    padding: 0 !important;
    border-bottom: 0 !important;
    min-width: auto !important;
    margin-right: 19px; }
  .cr-promotion-liner-details__availability-table .rules .cr-checkbox__checkbox {
    justify-content: center; }

.cr-promotion-liner-details__availability-table .quick-view-box {
  background: #f7fbfd;
  box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px; }
  .cr-promotion-liner-details__availability-table .quick-view-box .fixed-column {
    background: rgba(194, 212, 224, 0.3);
    border-radius: 6px 0px 0px 5px;
    padding-bottom: 14px !important; }
    .cr-promotion-liner-details__availability-table .quick-view-box .fixed-column .left-column {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      color: #0f5589;
      padding: 25px 22px;
      border-bottom: 1px solid rgba(194, 212, 224, 0.6);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
      .cr-promotion-liner-details__availability-table .quick-view-box .fixed-column .left-column.header {
        font-weight: normal;
        font-size: 16px;
        color: #6f96d1;
        height: 71px;
        white-space: initial;
        padding: 0 25px;
        display: flex;
        align-items: center; }
      .cr-promotion-liner-details__availability-table .quick-view-box .fixed-column .left-column.header-checkbox {
        height: 97px; }
      .cr-promotion-liner-details__availability-table .quick-view-box .fixed-column .left-column:last-child {
        border-bottom: none; }

.cr-promotion-liner-details__availability-table .weeks .week-checkbox {
  display: flex;
  align-items: center;
  justify-content: center; }

.cr-promotion-liner-details__availability-table .weeks .week {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #6f96d1;
  padding: 15px 22px;
  border-bottom: 1px solid rgba(194, 212, 224, 0.6);
  width: 120px; }
  .cr-promotion-liner-details__availability-table .weeks .week.bordered {
    border-right: 1px solid rgba(194, 212, 224, 0.6); }
    .cr-promotion-liner-details__availability-table .weeks .week.bordered:last-child {
      border-right: none; }
  .cr-promotion-liner-details__availability-table .weeks .week.item-count {
    font-weight: bold;
    font-size: 18px;
    color: #0f5589;
    padding: 25px 22px; }
  .cr-promotion-liner-details__availability-table .weeks .week .all-items-count {
    color: #6f96d1;
    font-weight: 400; }

.cr-promotion-liner-details__availability-table .weeks .week-all {
  max-width: 100px; }

.cr-promotion-liner-details__availability-table-total .quick-view-box .fixed-column {
  background-color: #f7fbfd;
  padding-bottom: 0 !important; }

.cr-promotion-liner-details__availability-table-total .quick-view-box .weeks .week-row {
  border-bottom: 1px solid rgba(194, 212, 224, 0.6); }
  .cr-promotion-liner-details__availability-table-total .quick-view-box .weeks .week-row .item-count,
  .cr-promotion-liner-details__availability-table-total .quick-view-box .weeks .week-row .week {
    border-bottom: none; }
  .cr-promotion-liner-details__availability-table-total .quick-view-box .weeks .week-row:last-child {
    border-bottom: none; }

.cr-promotion-liner-details__availability-table-total .quick-view-box .fixed-column {
  padding-bottom: 0 !important; }

.cr-promotion-liner-details__item-details-section-title {
  margin-top: 25px; }

.cr-promotion-liner-details__item-details-section-fields-view {
  padding-top: 30px !important;
  padding-bottom: 31px !important; }

.cr-promotion-liner-details__item-details-section-fields {
  padding-bottom: 66px !important;
  padding-top: 30px !important; }
  .cr-promotion-liner-details__item-details-section-fields .cr-days-of-week .col-6 {
    max-width: 100%; }
    .cr-promotion-liner-details__item-details-section-fields .cr-days-of-week .col-6 .cr-days-of-week__day-text {
      margin-bottom: 2px; }
  .cr-promotion-liner-details__item-details-section-fields .cr-checkbox .rc-checkbox-inner {
    width: 21px;
    height: 22px; }
  .cr-promotion-liner-details__item-details-section-fields .cr-checkbox__checked-icon {
    left: 6px;
    top: 1px; }
  .cr-promotion-liner-details__item-details-section-fields .exclude-dates-btn {
    float: right;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #795afa;
    cursor: pointer;
    margin-bottom: 5px;
    text-transform: capitalize; }
  .cr-promotion-liner-details__item-details-section-fields .exclude-date-picker {
    clear: both; }
    .cr-promotion-liner-details__item-details-section-fields .exclude-date-picker .react-datepicker__day-names {
      background-color: #f7fbfd;
      border: 1px solid #c2d4e0 !important; }
      .cr-promotion-liner-details__item-details-section-fields .exclude-date-picker .react-datepicker__day-names .react-datepicker__day-name {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #829fb1;
        padding: 12px 20px; }
    .cr-promotion-liner-details__item-details-section-fields .exclude-date-picker .react-datepicker__week .react-datepicker__day {
      border-bottom: 1px solid #c2d4e0;
      border-left: 1px solid #c2d4e0;
      font-size: 16px;
      line-height: 20px;
      color: #829fb1;
      padding: 10px 20px; }
    .cr-promotion-liner-details__item-details-section-fields .exclude-date-picker .react-datepicker__week .react-datepicker__day--disabled {
      color: #829fb1 !important;
      background: rgba(194, 212, 224, 0.4) !important; }
      .cr-promotion-liner-details__item-details-section-fields .exclude-date-picker .react-datepicker__week .react-datepicker__day--disabled:hover {
        color: #829fb1 !important;
        background: rgba(194, 212, 224, 0.4); }
    .cr-promotion-liner-details__item-details-section-fields .exclude-date-picker .react-datepicker__week .react-datepicker__day--highlighted {
      background-color: #795afa !important;
      color: #fff !important;
      font-weight: normal; }
    .cr-promotion-liner-details__item-details-section-fields .exclude-date-picker .react-datepicker__week .react-datepicker__day--today {
      font-weight: normal; }
    .cr-promotion-liner-details__item-details-section-fields .exclude-date-picker .react-datepicker__week .react-datepicker__day--keyboard-selected,
    .cr-promotion-liner-details__item-details-section-fields .exclude-date-picker .react-datepicker__week .react-datepicker__day--selected {
      background-color: #fff; }
    .cr-promotion-liner-details__item-details-section-fields .exclude-date-picker .datePickerTxt {
      font-weight: 800;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      color: #334856; }
    .cr-promotion-liner-details__item-details-section-fields .exclude-date-picker .datePickerButtons {
      color: #795afa; }

.cr-promotion-liner-details__item-shcedule-helper-text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #657894; }

.cr-promotion-liner-details__item-scheduling-section-fields-view {
  padding-top: 30px;
  padding-bottom: 5px; }

.cr-promotion-liner-details__order-view-statement {
  color: #334856;
  font-size: 18px; }

.cr-promotion-liner-details__item-scheduling-section-fields {
  padding-top: 26px;
  padding-bottom: 28px; }
  .cr-promotion-liner-details__item-scheduling-section-fields .cr-radio-buttons__label {
    text-transform: capitalize;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #43535c; }

.cr-promotion-liner-details .cr-days-of-week__day {
  margin-right: 15px; }

.cr-promotion-liner-details__badges-container {
  display: inline-flex;
  flex-wrap: wrap; }

.cr-promotion-liner-details__badges label span {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #334856;
  margin-bottom: 10px;
  display: block;
  text-transform: capitalize; }

.cr-promotion-liner-details__badges-hours .cr-text-badge__text {
  text-transform: lowercase; }

.cr-promotion-liner-details__badges-excluded-hours .cr-text-badge__text {
  text-transform: uppercase; }

.cr-promotion-liner-details__scheduling-statement-header {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #43535c;
  text-transform: uppercase;
  margin-bottom: 8px; }

.cr-promotion-liner-details__scheduling-statement-text {
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: #537a91; }

.cr-promotion-liner-details__desired-items {
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 49px 50px 49px 40px;
  margin-bottom: 41px; }

.cr-promotion-liner-details__desired-items-title {
  font-size: 23px;
  color: #334856;
  margin-bottom: 28px;
  display: flex;
  align-items: center; }
  .cr-promotion-liner-details__desired-items-title strong {
    margin: 0 10px;
    color: #334856; }

.cr-promotion-liner-details__desired-title {
  font-size: 23px;
  color: #334856; }

.cr-promotion-liner-details__desired-slider {
  display: flex;
  align-items: center;
  justify-content: center; }
  .cr-promotion-liner-details__desired-slider .input-container {
    margin-bottom: 0 !important; }
  .cr-promotion-liner-details__desired-slider .cr-textinput {
    background-color: #fff;
    width: 88px;
    display: inline-block; }
    .cr-promotion-liner-details__desired-slider .cr-textinput input {
      font-size: 23px;
      text-align: center;
      background: transparent; }
  .cr-promotion-liner-details__desired-slider .cr-textinput__input-wrapper {
    height: 54px; }

.cr-promotion-liner-details__slider {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  margin: 0 20px; }

.cr-promotion-liner-details__slider-value {
  color: #43535c;
  font-size: 16px;
  width: 50px;
  text-align: center; }

.cr-promotion-liner-details__help-tooltip-overlay {
  opacity: 1; }
  .cr-promotion-liner-details__help-tooltip-overlay .rc-tooltip-inner {
    background-color: #fff;
    max-width: 400px;
    padding: 10px 20px; }
  .cr-promotion-liner-details__help-tooltip-overlay .rc-tooltip-arrow {
    border-color: transparent;
    top: -1px; }
    .cr-promotion-liner-details__help-tooltip-overlay .rc-tooltip-arrow:after {
      content: "";
      position: absolute;
      width: 19px;
      height: 19px;
      background: #fff;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 40px;
      left: -3px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); }
    .cr-promotion-liner-details__help-tooltip-overlay .rc-tooltip-arrow:before {
      content: "";
      display: block;
      width: 30px;
      height: 14px;
      background: #fff;
      position: absolute;
      top: 42px;
      z-index: 9;
      left: -1px;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg); }

.cr-promotion-liner-details__help-icon {
  cursor: pointer;
  display: inherit; }

.cr-promotion-liner-details__help-tooltip {
  font-size: 18px;
  color: #657894; }

.cr-promotion-liner-details__render-weeks {
  display: flex; }

.cr-promotion-liner-details__scheduling-preview-container {
  margin-top: 26px;
  padding-bottom: 0; }

.cr-promotion-liner-details__preview-title {
  font-weight: bold;
  font-size: 23px;
  text-transform: capitalize;
  color: #334856;
  margin-bottom: 21px; }

.cr-promotion-liner-details__preview-content {
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden; }

.cr-promotion-liner-details__preview-head {
  padding: 43px 65px 43px 55px; }
  .cr-promotion-liner-details__preview-head .input-container {
    margin-bottom: 0;
    margin: 0 10px; }
  .cr-promotion-liner-details__preview-head .cr-textinput__input-wrapper {
    width: 88px;
    background-color: #fff; }

.cr-promotion-liner-details__preview-total-text {
  font-size: 23px;
  color: #334856;
  display: flex;
  align-items: center;
  margin-bottom: 17.23px; }
  .cr-promotion-liner-details__preview-total-text.no-flex {
    display: block; }
    .cr-promotion-liner-details__preview-total-text.no-flex div {
      display: flex;
      align-items: center; }
  .cr-promotion-liner-details__preview-total-text:last-child {
    margin-bottom: 0; }
  .cr-promotion-liner-details__preview-total-text strong {
    color: #000;
    margin: 0 10px; }

.cr-promotion-liner-details__preview-buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  .cr-promotion-liner-details__preview-buttons .btn {
    width: 254px;
    margin-right: 0 !important; }
    .cr-promotion-liner-details__preview-buttons .btn:first-child {
      margin-bottom: 15px; }

.cr-promotion-liner-details__section-toolbar {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }

.cr-promotion-liner-details__preview-distribution {
  font-size: 14px;
  color: #334856;
  margin-bottom: 9px; }

.cr-promotion-liner-details__desired-items-description {
  color: #537a91;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 4px; }

.cr-promotion-liner-details__desired-items-alert {
  color: #e40061;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 4px; }

.cr-promotion-liner-details__text-cap {
  text-transform: capitalize; }

.cr-promotion-liner-details__scheduling-table-preview .quick-view-box {
  border-top: 1px solid #829fb1;
  background-color: #fff !important;
  box-shadow: none !important;
  border-radius: 0 !important; }

.cr-promotion-liner-details__scheduling-table-preview .cr-promotion-liner-details__availability-table .left-column {
  background-color: #f7fbfd !important; }

.cr-promotion-liner-details__scheduling-table-preview .cr-promotion-liner-details__availability-table .item-count {
  background-color: #fff; }

.cr-promotion-liner-details__item-dropdown .react-select__option {
  text-transform: capitalize; }

.cr-promotion-liner-details__order-view-value-excluded-dates {
  text-transform: uppercase; }

.cr-promotion-liner-details .react-select__menu-list {
  overflow-x: hidden; }

.cr-promotion-liner-details .exclude-dates-btn {
  color: #795afa;
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  cursor: pointer;
  text-transform: capitalize; }

@media (max-width: 1739px) {
  .cr-promotion-liner-details__quick-view-section-toolbar .react-select-container .react-select__control {
    min-width: 190px; }
  .cr-promotion-liner-details__quick-view-section-toolbar .react-select__single-value .value-container .type {
    min-width: 120px !important; }
  .cr-promotion-liner-details__item-details-section-fields .cr-days-of-week__day {
    margin-right: 6px; }
  .react-select__option .value-container .type {
    min-width: 70px; } }

.cr-promotion-liner-scripts__section-add-group {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  padding: 34px 50px 36px 50px; }

.cr-promotion-liner-scripts__add-button {
  border-color: #795afa;
  width: 221px; }
  .cr-promotion-liner-scripts__add-button span {
    text-transform: initial;
    font-weight: bold; }

.cr-promotion-liner-scripts__add-icon {
  margin-right: 8px; }

.cr-promotion-liner-scripts .cr-days-of-week .col-6 {
  max-width: 100%; }
  .cr-promotion-liner-scripts .cr-days-of-week .col-6 .cr-days-of-week__day-text {
    margin-bottom: 2px; }

.cr-promotion-liner-scripts .cr-checkbox .rc-checkbox-inner {
  width: 21px;
  height: 22px; }

.cr-promotion-liner-scripts .cr-checkbox__checked-icon {
  left: 6px;
  top: 1px; }

.cr-promotion-liner-buttons__buttons-left {
  display: flex; }

.cr-promotion-liner-buttons__buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%; }
  .cr-promotion-liner-buttons__buttons.multi-row {
    margin-bottom: 26px; }
    .cr-promotion-liner-buttons__buttons.multi-row .btn-add {
      display: flex;
      align-items: center; }
      .cr-promotion-liner-buttons__buttons.multi-row .btn-add span {
        margin-left: 16px; }

.cr-promotion-liner-buttons__btn-draft {
  background: red;
  margin-right: 14px !important; }

.cr-promotion-liner-buttons__btn-publish {
  float: right;
  background-color: #224b8b;
  border-color: #224b8b;
  width: 188px; }
  .cr-promotion-liner-buttons__btn-publish.disabled {
    background-color: #c2d4e0;
    border-color: #c2d4e0;
    width: 170px; }

.cr-promotion-liner-buttons__btn-delete {
  margin-right: 0; }

.cr-promotion-liner-buttons__btn-draft {
  margin-top: 0; }

.cr-promotion-liner-buttons__btn-submit {
  margin-right: 14px !important; }

.cr-promotion-liner-buttons__confirmation-publish-modal .modal-title span {
  margin-right: 8px; }

.cr-promotion-liner-buttons__confirmation-publish-modal .modal-body {
  font-size: 18px;
  color: #334856;
  margin-top: 15px; }

@media (max-width: 1739px) {
  .cr-promotion-liner-buttons__buttons .btn {
    margin-right: 10px !important; } }

.cr-promotion-schedule .cr-schedule-calendar__calendar-content {
  padding-bottom: 0; }

@media (min-width: 1599px) {
  .cr-promotion-schedule .bottom-actions-sticky .buttons {
    padding-left: 50px;
    padding-right: 50px; } }

.cr-schedule-calendar-item {
  display: inline-flex;
  min-width: auto; }
  .cr-schedule-calendar-item__item-container {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    padding: 0 0 0 16px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    min-height: 39px; }
  .cr-schedule-calendar-item__item-disabled {
    cursor: not-allowed; }
  .cr-schedule-calendar-item__item-filled .cr-schedule-calendar-item__flag {
    border-top-color: #f7fbfd; }
  .cr-schedule-calendar-item__filled-text {
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    color: #334856;
    position: absolute;
    top: -27px;
    right: 1px; }
  .cr-schedule-calendar-item__number {
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    margin-right: 15px;
    line-height: 22px; }
  .cr-schedule-calendar-item__title {
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    padding-right: 25px; }
  .cr-schedule-calendar-item__flag {
    width: 0;
    height: 0;
    border-top: 24px solid #364857;
    border-left: 24px solid transparent;
    position: absolute;
    top: 0;
    right: 0; }
    .cr-schedule-calendar-item__flag::after {
      content: "";
      display: block;
      width: 36px;
      background: #f7fbfd;
      height: 1px;
      position: absolute;
      right: -6px;
      top: -12px;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
  .cr-schedule-calendar-item__flag-ineligible {
    opacity: 0.5; }
  .cr-schedule-calendar-item__multiple-types {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    flex: 1 1; }
  .cr-schedule-calendar-item__type-item {
    width: 20px;
    height: 39px;
    border-left: 1px solid #f7fbfd; }
  .cr-schedule-calendar-item__item-highlight {
    opacity: 1; }
  .cr-schedule-calendar-item__item-highlight-empty {
    background-color: #131e30 !important;
    opacity: 1; }
  .cr-schedule-calendar-item__item-highlight-selected {
    background-color: #131e30 !important; }

.calendar-tooltip {
  opacity: 1; }
  .calendar-tooltip--on_hold.rc-tooltip-placement-bottom .rc-tooltip-arrow::before, .calendar-tooltip--fill.rc-tooltip-placement-bottom .rc-tooltip-arrow::before {
    background-color: #334856;
    height: 10px; }
  .calendar-tooltip .rc-tooltip-inner {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 0; }
  .calendar-tooltip .rc-tooltip-arrow {
    border-color: transparent; }
    .calendar-tooltip .rc-tooltip-arrow:after {
      content: "";
      position: absolute;
      width: 19px;
      height: 19px;
      background: #fff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); }
    .calendar-tooltip .rc-tooltip-arrow:before {
      content: "";
      display: block;
      width: 50px;
      height: 20px;
      background: #fff;
      position: absolute;
      z-index: 9; }
  .calendar-tooltip.rc-tooltip-placement-bottom .rc-tooltip-arrow:after {
    width: 14px;
    height: 14px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -2px;
    left: 0; }
  .calendar-tooltip.rc-tooltip-placement-bottom .rc-tooltip-arrow::before {
    left: -15px;
    top: 5px; }
  .calendar-tooltip.rc-tooltip-placement-top .rc-tooltip-arrow {
    bottom: 19px;
    top: auto; }
    .calendar-tooltip.rc-tooltip-placement-top .rc-tooltip-arrow:after {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 0;
      left: 0; }
    .calendar-tooltip.rc-tooltip-placement-top .rc-tooltip-arrow::before {
      left: -15px;
      top: -10px; }
  .calendar-tooltip.calendar-tooltip-none .rc-tooltip-arrow {
    display: none; }

.cr-schedule-swap {
  max-width: 330px;
  width: 276px; }
  .cr-schedule-swap__text-time {
    font-size: 16px;
    color: #657894; }
  .cr-schedule-swap__title {
    font-size: 16px;
    color: #334856;
    display: block;
    font-weight: bold; }
  .cr-schedule-swap__slot-item {
    display: flex;
    align-items: center;
    margin-top: 10px; }
  .cr-schedule-swap__slot-color {
    width: 16px;
    height: 15px;
    background: #40a3ff;
    border-radius: 3px;
    margin-right: 9px; }
  .cr-schedule-swap__slot-text {
    color: #657894;
    font-size: 16px;
    max-width: calc(100% - 25px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .cr-schedule-swap__slot-date-time {
    font-size: 14px;
    color: #334856;
    margin-top: 6px; }
  .cr-schedule-swap__slot-table {
    margin-top: 17px;
    display: flex;
    flex-direction: column; }
  .cr-schedule-swap__slot-number {
    font-weight: bold;
    font-size: 14px;
    color: #334856;
    margin-right: 10px;
    min-width: 30px;
    display: inline-block; }
  .cr-schedule-swap__swap-content {
    padding: 30px 24px;
    position: relative; }
  .cr-schedule-swap__close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer; }
  .cr-schedule-swap__hold-status {
    background: #334856;
    border-radius: 4px 4px 0px 0px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #a1b1bc; }
  .cr-schedule-swap__error-override-hover {
    display: flex;
    align-items: center;
    margin-top: 19px; }
    .cr-schedule-swap__error-override-hover svg {
      width: 15px; }
    .cr-schedule-swap__error-override-hover span {
      font-size: 14px;
      color: #e40061;
      margin-left: 9px; }
  .cr-schedule-swap__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px; }
  .cr-schedule-swap__action-right-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .cr-schedule-swap__action-swap {
    margin-right: 15px; }
  .cr-schedule-swap__action-button {
    text-transform: uppercase;
    font-size: 10px;
    color: #795afa;
    display: flex;
    align-items: center;
    cursor: pointer; }
    .cr-schedule-swap__action-button svg {
      width: 16px; }
    .cr-schedule-swap__action-button span {
      margin-left: 5px; }
  .cr-schedule-swap__action-swap .rc-checkbox-inner {
    width: 16px;
    height: 16px; }
  .cr-schedule-swap__action-swap .cr-checkbox {
    margin-bottom: 15px; }
  .cr-schedule-swap__action-swap .cr-checkbox__text {
    font-size: 14px;
    color: #e40061; }
    .cr-schedule-swap__action-swap .cr-checkbox__text strong {
      text-transform: lowercase;
      margin: 0 2px; }
  .cr-schedule-swap__action-swap .cr-checkbox__checked-icon {
    left: 4px;
    top: -1px; }
  .cr-schedule-swap__btn-swap.disabled {
    background: #bccedb;
    border-color: transparent; }

.cr-search-autocomplete-input__search-input-wrapper {
  position: relative; }

.cr-search-autocomplete-input__search-icon {
  position: absolute;
  top: 14px;
  right: 18px;
  cursor: pointer; }

.cr-search-autocomplete-input__clear-icon {
  top: 14px;
  position: absolute;
  right: 54px;
  cursor: pointer; }

.cr-search-autocomplete-input .cr-auto-complete-input .auto-complete .auto-complete-wrapper > div:first-child {
  border: none;
  max-width: 389px; }

.cr-search-autocomplete-input .cr-auto-complete-input .auto-complete input {
  border-radius: 50px;
  max-width: 100%;
  padding-right: 70px !important; }
  .cr-search-autocomplete-input .cr-auto-complete-input .auto-complete input::-webkit-input-placeholder {
    /* Edge */
    color: #829fb1; }
  .cr-search-autocomplete-input .cr-auto-complete-input .auto-complete input::placeholder {
    color: #829fb1; }

.cr-search-autocomplete-input .item {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center; }

.cr-search-autocomplete-input .menu {
  max-width: 400px;
  padding: 26px 0 !important;
  margin-top: 1px;
  z-index: 102; }
  .cr-search-autocomplete-input .menu .cr-search-autocomplete-input__saved-searches {
    display: block; }

.cr-search-autocomplete-input__saved-searches {
  display: none; }

.cr-search-autocomplete-input__saved-search-title {
  color: #334856;
  font-weight: 700;
  text-transform: capitalize;
  padding-left: 24px;
  padding-right: 24px; }

.cr-search-autocomplete-input__saved-search-list {
  max-height: 200px;
  overflow: auto; }
  .cr-search-autocomplete-input__saved-search-list span {
    display: block;
    color: #334856;
    line-height: 40px;
    margin: 5px 0;
    cursor: pointer;
    padding-left: 24px;
    padding-right: 24px; }
    .cr-search-autocomplete-input__saved-search-list span:hover {
      background-color: rgba(188, 213, 229, 0.3); }

.cr-search-autocomplete-input__item-title {
  color: #334856;
  font-weight: bold;
  font-size: 14px;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  text-transform: capitalize; }

.cr-search-autocomplete-input__item-subtitle {
  color: #334856;
  font-size: 14px;
  display: inline-block;
  padding-left: 20px;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 180px); }

.search-input-tooltip {
  opacity: 1; }
  .search-input-tooltip .rc-tooltip-inner {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 10px 20px; }
  .search-input-tooltip .rc-tooltip-arrow {
    border-color: transparent; }
    .search-input-tooltip .rc-tooltip-arrow:after {
      content: "";
      position: absolute;
      width: 14px;
      height: 14px;
      background: #fff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); }
    .search-input-tooltip .rc-tooltip-arrow:before {
      content: "";
      display: block;
      width: 50px;
      height: 12px;
      background: #fff;
      position: absolute;
      z-index: 9; }
  .search-input-tooltip.rc-tooltip-placement-top .rc-tooltip-arrow {
    bottom: 19px;
    top: auto; }
    .search-input-tooltip.rc-tooltip-placement-top .rc-tooltip-arrow:after {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 3px;
      left: 0; }
    .search-input-tooltip.rc-tooltip-placement-top .rc-tooltip-arrow::before {
      left: -18px;
      top: -2px; }
  .search-input-tooltip .tooltip-title {
    font-weight: bold;
    font-size: 16px;
    color: #334856; }

.cr-schedule-action-statement {
  background: #ffffff;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 15px 18px 14px 30px;
  display: inline-block; }
  .cr-schedule-action-statement__text {
    font-size: 16px;
    color: #537a91;
    margin-right: 15px; }
  .cr-schedule-action-statement__undo {
    text-transform: uppercase;
    font-size: 15px;
    color: #795afa;
    cursor: pointer; }

.cr-calendar-week-sticky {
  padding-left: 99px; }
  .cr-calendar-week-sticky__weekday-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-transform: capitalize;
    flex: 1 1; }
  .cr-calendar-week-sticky__weekday {
    font-size: 16px;
    color: #657894; }
  .cr-calendar-week-sticky__header-date {
    font-weight: 800;
    font-size: 20px;
    color: #334856; }
  .cr-calendar-week-sticky__header-add-fill {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #795afa;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .cr-calendar-week-sticky__header-add-fill span {
      margin-left: 5px; }
  .cr-calendar-week-sticky .rbc-calendar {
    position: relative; }
  .cr-calendar-week-sticky .rbc-time-content {
    display: none; }
  .cr-calendar-week-sticky .rbc-time-header-gutter {
    display: none; }
  .cr-calendar-week-sticky .rbc-allday-cell {
    display: none; }
  .cr-calendar-week-sticky .rbc-time-header-content,
  .cr-calendar-week-sticky .rbc-time-view {
    border: none; }
  .cr-calendar-week-sticky .rbc-time-header-cell .rbc-header {
    border: none; }
    .cr-calendar-week-sticky .rbc-time-header-cell .rbc-header.rbc-today {
      background-color: transparent; }
  .cr-calendar-week-sticky__label-date,
  .cr-calendar-week-sticky .rbc-toolbar-label {
    font-size: 23px !important;
    color: #829fb1 !important;
    text-align: center;
    margin-bottom: 14px !important;
    line-height: initial !important;
    width: 100%; }
  .cr-calendar-week-sticky__back-next-buttons button {
    position: absolute;
    bottom: 5px; }
  .cr-calendar-week-sticky__btn-back {
    border: none;
    background-color: transparent;
    cursor: pointer;
    left: -10px;
    top: 55px; }
    .cr-calendar-week-sticky__btn-back svg {
      width: 15px; }
      .cr-calendar-week-sticky__btn-back svg + svg {
        margin-left: -4px; }
    .cr-calendar-week-sticky__btn-back:focus {
      outline: none; }
  .cr-calendar-week-sticky__btn-back-2 {
    border: none;
    background-color: transparent;
    cursor: pointer;
    left: 20px;
    top: 55px; }
    .cr-calendar-week-sticky__btn-back-2 svg {
      width: 15px; }
    .cr-calendar-week-sticky__btn-back-2:focus {
      outline: none; }
  .cr-calendar-week-sticky__btn-next {
    border: none;
    background-color: transparent;
    cursor: pointer;
    right: 20px;
    top: 55px; }
    .cr-calendar-week-sticky__btn-next svg {
      width: 15px; }
    .cr-calendar-week-sticky__btn-next:focus {
      outline: none; }
  .cr-calendar-week-sticky__btn-next-2 {
    border: none;
    background-color: transparent;
    cursor: pointer;
    right: -10px;
    top: 55px; }
    .cr-calendar-week-sticky__btn-next-2 svg {
      width: 15px; }
      .cr-calendar-week-sticky__btn-next-2 svg + svg {
        margin-left: -4px; }
    .cr-calendar-week-sticky__btn-next-2:focus {
      outline: none; }

.cr-copy-group {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  margin-bottom: 20px; }
  .cr-copy-group__header {
    height: 92px;
    padding: 0 44.47px 0 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid transparent; }
  .cr-copy-group__header-open {
    border-bottom-color: #edf1f6;
    height: 79px; }
  .cr-copy-group__header-left {
    font-size: 23px;
    color: #334856;
    text-transform: capitalize; }
    .cr-copy-group__header-left strong {
      margin-right: 5px; }
  .cr-copy-group__status-text {
    margin-right: 24px;
    font-weight: 800;
    font-size: 16px;
    text-transform: uppercase; }
  .cr-copy-group__baseline-text {
    color: #4683b8; }
  .cr-copy-group__superseding-text {
    color: #73a703; }
  .cr-copy-group__expired-text {
    color: #c2d4e0; }
  .cr-copy-group__delete-button {
    margin-right: 40px;
    cursor: pointer; }
  .cr-copy-group__content-form .form-group {
    margin-bottom: 27px; }
  .cr-copy-group__content-view .form-group {
    margin-bottom: 33px; }
  .cr-copy-group .cr-days-of-week .col-6 {
    max-width: 100%; }
  .cr-copy-group__time-line {
    font-size: 16px;
    color: #334856;
    width: 47.55px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px; }
  .cr-copy-group__time-col {
    width: 50%; }
  .cr-copy-group__time-inputs {
    display: flex;
    align-items: center;
    justify-content: center; }
    .cr-copy-group__time-inputs .cr-input-time__input {
      color: #829fb1; }
    .cr-copy-group__time-inputs .react-select__single-value {
      color: #334856 !important; }
  .cr-copy-group__switch-label {
    font-size: 17px;
    color: #374c59;
    text-transform: uppercase;
    font-weight: 400; }
  .cr-copy-group__switch-label-active {
    font-weight: bold;
    color: #000; }
  .cr-copy-group__switch-group {
    display: flex;
    align-items: flex-end;
    height: 100%;
    margin-bottom: 0;
    padding-bottom: 12px; }
  .cr-copy-group__col-reset {
    margin-bottom: 0; }
  .cr-copy-group__form-padding {
    padding: 41px 40px 42px 48px; }
  .cr-copy-group__view-padding {
    padding: 40px 53px 31px 53px; }
  .cr-copy-group__form-section-fields {
    border-bottom: 1px solid #edf1f6; }
  .cr-copy-group__form-section-jock {
    padding: 25px 40px 42px 48px;
    border-bottom: 1px solid #edf1f6; }
  .cr-copy-group__form-section-script {
    padding: 36px 40px 43px 48px;
    border-bottom: 1px solid #edf1f6; }
    .cr-copy-group__form-section-script .cr-textarea-evaluator__textarea-container {
      margin-bottom: 25px; }
      .cr-copy-group__form-section-script .cr-textarea-evaluator__textarea-container:last-child {
        margin-bottom: 0; }
  .cr-copy-group__view-section {
    padding: 48px 45px 42px 47px;
    border-bottom: 1px solid #edf1f6; }
  .cr-copy-group .cr-textarea-evaluator__title {
    font-size: 23px;
    font-weight: bold;
    color: #334856;
    text-transform: capitalize; }
  .cr-copy-group .public-DraftEditorPlaceholder-inner {
    font-size: 16px;
    color: #829fb1; }
  .cr-copy-group__add-rotation {
    padding-top: 43px; }
  .cr-copy-group__add-button {
    border-color: #795afa;
    width: 177px; }
    .cr-copy-group__add-button span {
      text-transform: initial;
      font-weight: bold; }
  .cr-copy-group__add-icon {
    margin-right: 8px; }
  .cr-copy-group__section-title {
    font-weight: bold;
    font-size: 23px;
    color: #334856;
    margin-bottom: 21px; }
  .cr-copy-group__toggle-button {
    cursor: pointer; }
  .cr-copy-group__script-history {
    font-weight: bold;
    font-size: 14px;
    color: #795afa;
    margin-left: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    cursor: pointer; }
    .cr-copy-group__script-history span {
      margin-left: 10px; }
  .cr-copy-group .cr-textarea-evaluator__title-wrapper {
    margin-bottom: 8px; }
    .cr-copy-group .cr-textarea-evaluator__title-wrapper .cr-textarea-evaluator__wrapper-top {
      display: none; }
  .cr-copy-group .cr-textarea-evaluator__wrapper-top-length {
    position: absolute;
    right: 0;
    margin-bottom: 0;
    display: flex;
    top: 18px; }
    .cr-copy-group .cr-textarea-evaluator__wrapper-top-length .cr-textarea-evaluator__timer {
      margin-bottom: 0; }
  .cr-copy-group__view-scripts .cr-copy-group__order-view-value {
    margin-bottom: 19px;
    color: #333333; }
    .cr-copy-group__view-scripts .cr-copy-group__order-view-value:last-child {
      margin-bottom: 0; }
  .cr-copy-group__view-scripts .cr-textarea-evaluator__wrapper-top-length {
    top: -46px; }
  .cr-copy-group__view-jock .cr-copy-group__order-view-value {
    color: #333333; }
  .cr-copy-group .cr-copy-group__switch-label {
    white-space: nowrap; }
  .cr-copy-group__order-view-value {
    color: #000; }

.cr-script-history-modal {
  width: 868px;
  max-width: inherit; }
  .cr-script-history-modal .modal-header {
    height: 87px;
    padding-left: 48px;
    padding-right: 36px;
    border-color: #edf1f6;
    display: flex;
    align-items: center; }
  .cr-script-history-modal .modal-title {
    font-weight: bold;
    font-size: 23px;
    color: #334856;
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    text-transform: capitalize; }
  .cr-script-history-modal .modal-body {
    padding: 0;
    max-height: 740px;
    overflow-y: scroll; }
  .cr-script-history-modal__close-icon {
    cursor: pointer; }
  .cr-script-history-modal .close {
    display: none; }
  .cr-script-history-modal__script-item {
    border-bottom: 1px solid #edf1f6;
    padding: 35px 69px 37px 47px; }
    .cr-script-history-modal__script-item:last-child {
      border-bottom: none; }
  .cr-script-history-modal__script-header {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .cr-script-history-modal__script-header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end; }
  .cr-script-history-modal__section-title {
    font-weight: bold;
    font-size: 23px;
    color: #334856;
    text-transform: capitalize; }
  .cr-script-history-modal__flight-text {
    font-weight: bold;
    font-size: 16px;
    color: #4683b8;
    text-transform: uppercase; }
  .cr-script-history-modal__auth-text {
    font-size: 12px;
    color: #657894; }

.cr-text-badge {
  display: flex;
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 11px;
  height: 46px;
  align-items: center;
  margin-right: 11px;
  justify-content: center;
  margin-bottom: 10px; }
  .cr-text-badge__close-icon {
    cursor: pointer;
    display: flex;
    align-items: center; }
  .cr-text-badge__text {
    font-size: 16px;
    color: #657894;
    margin-left: 8px; }

.cr-promotion-liner-client__order-view-value {
  font-size: 16px;
  color: #000; }

.cr-promotion-liner-client__section-client-details {
  padding-top: 22px;
  padding-bottom: 24px; }
  .cr-promotion-liner-client__section-client-details .form-group {
    margin-bottom: 27px; }

.cr-promotion-liner-client__section-client-details-view {
  padding-top: 30px;
  padding-bottom: 30px; }
  .cr-promotion-liner-client__section-client-details-view .form-group {
    margin-bottom: 33px; }

.cr-promotion-liner-client__reset {
  margin-bottom: 0; }

.cr-promotion-liner-client__section-documents-details {
  padding-top: 21px;
  padding-bottom: 48px; }
  .cr-promotion-liner-client__section-documents-details .form-group {
    margin-bottom: 0; }

.cr-promotion-liner-client__section-documents-details-view {
  padding-top: 21px !important;
  padding-bottom: 29px !important; }
  .cr-promotion-liner-client__section-documents-details-view .cr-handle-assets__file-name-view {
    color: #795afa; }
  .cr-promotion-liner-client__section-documents-details-view .form-group {
    margin-bottom: 0; }

.cr-promotion-liner-client__section-notes-details {
  padding-top: 23px;
  padding-bottom: 51px; }
  .cr-promotion-liner-client__section-notes-details .form-group {
    margin-bottom: 0; }

.cr-promotion-liner-client__section-notes-details-view {
  padding-top: 30px !important;
  padding-bottom: 32px !important; }
  .cr-promotion-liner-client__section-notes-details-view .cr-promotion-liner-client__order-view-value {
    color: #334856; }

.cr-feedback-success-modal {
  width: 639px;
  max-width: 639px; }
  .cr-feedback-success-modal__body {
    height: 435px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .cr-feedback-success-modal__title {
    font-weight: 800;
    font-size: 32px;
    text-align: center;
    color: #000000; }
  .cr-feedback-success-modal__description {
    font-size: 18px;
    text-align: center;
    color: #657894; }
  .cr-feedback-success-modal__user {
    margin-top: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
  .cr-feedback-success-modal__user-name {
    font-weight: 800;
    font-size: 18px;
    text-align: center;
    color: #224b8b; }
  .cr-feedback-success-modal__user-role {
    font-size: 14px;
    text-align: center;
    color: #336abb; }
  .cr-feedback-success-modal__feedback-avatar {
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
    width: 150px;
    height: 150px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    overflow: hidden;
    margin: 0 auto;
    padding: 10px;
    margin-bottom: 10px; }
    .cr-feedback-success-modal__feedback-avatar img {
      width: 100%;
      height: 100%; }
  .cr-feedback-success-modal__avatar {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 100px; }
  @media (max-width: 480px) {
    .cr-feedback-success-modal {
      width: auto !important;
      height: auto !important;
      align-items: center;
      min-height: calc(100% - (0.5rem * 2));
      display: flex; }
      .cr-feedback-success-modal__title {
        font-size: 32px;
        line-height: 38px; }
      .cr-feedback-success-modal__user {
        margin-top: 10px; } }

.cr-client-reassign-modal {
  min-width: 562px; }
  .cr-client-reassign-modal__body {
    padding-left: 133px;
    padding-right: 150px;
    margin-bottom: 37px; }
  .cr-client-reassign-modal .close {
    display: none; }
  .cr-client-reassign-modal__header {
    border-bottom: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: 81px;
    padding-bottom: 14px; }
  .cr-client-reassign-modal__footer {
    border-top: 0;
    justify-content: center;
    padding-bottom: 83px; }
  .cr-client-reassign-modal .modal-title {
    color: #000000;
    font-weight: 800;
    font-size: 40px; }

.cr-client-manager-form__content-form {
  padding-bottom: 100px; }
  .cr-client-manager-form__content-form label {
    color: #43535c;
    text-transform: uppercase;
    display: flex; }
  .cr-client-manager-form__content-form .form-group {
    margin-bottom: 27px; }

.cr-client-manager-form__content-view {
  padding-bottom: 100px; }
  .cr-client-manager-form__content-view label {
    color: #657894;
    text-transform: uppercase;
    display: block; }

.cr-client-manager-form__order-view-value {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  display: block; }

.cr-client-manager-form__col-view-field {
  margin-bottom: 46px !important; }

.cr-client-manager-form__reset {
  margin-bottom: 0; }

.cr-client-manager-form__section-title {
  text-transform: capitalize; }

.cr-client-manager-form__section-client-fields {
  padding-top: 23px; }

.cr-client-manager-form__form-buttons {
  padding-left: 49px; }
  .cr-client-manager-form__form-buttons .btn-outline-blue {
    margin-left: 14px; }

.cr-client-manager-form__checkboxes {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .cr-client-manager-form__checkboxes .cr-checkbox {
    margin-right: 28px; }

.cr-client-manager-form__add-icon {
  margin-right: 18px; }

.cr-client-manager-form__section-contact-fields {
  padding: 0 !important; }
  .cr-client-manager-form__section-contact-fields .cr-client-manager-form__contact-container:first-child {
    border-top: none; }

.cr-client-manager-form__add-button {
  width: 184px !important; }
  .cr-client-manager-form__add-button span {
    text-transform: initial;
    font-weight: bold; }

.cr-client-manager-form__contact-title {
  color: #334856;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 14px;
  position: relative;
  text-transform: capitalize; }

.cr-client-manager-form__contact-button {
  padding-left: 49px;
  padding-right: 49px;
  padding-bottom: 48px;
  display: inline-flex;
  flex-direction: column; }
  .cr-client-manager-form__contact-button .alert-danger {
    margin-top: 5px; }

.cr-client-manager-form__contact-container {
  padding-top: 23px;
  padding-left: 49px;
  padding-right: 49px;
  border-top: 1px solid #edf1f6; }

.cr-client-manager-form__contact-primary-text {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-left: 20px;
  color: #795afa;
  cursor: pointer; }

.cr-client-manager-form__success-modal .modal-title {
  max-width: 300px; }

.cr-client-manager-form__contact-remove {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  background-color: transparent;
  border: none; }
  .cr-client-manager-form__contact-remove:disabled {
    cursor: not-allowed; }

.cr-client-manager-form .bottom-actions-sticky .buttons .btn-blue {
  margin-right: 14px; }

@media (min-width: 1599px) {
  .cr-client-manager-form .bottom-actions-sticky .buttons {
    padding-left: 70px;
    padding-right: 70px; } }

.cr-sidebar-new {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }
  .cr-sidebar-new__btn-new {
    display: flex;
    align-items: center;
    justify-content: center; }
    .cr-sidebar-new__btn-new.btn-radius {
      width: 90px;
      min-width: 90px;
      height: 30px !important; }
    .cr-sidebar-new__btn-new span {
      font-weight: bold;
      font-size: 14px;
      margin-left: 10px;
      text-transform: capitalize; }
  .cr-sidebar-new__btn-circle {
    border-radius: 100px;
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    flex-direction: column; }
    .cr-sidebar-new__btn-circle.btn {
      padding-left: 0 !important;
      padding-right: 0 !important; }
  .cr-sidebar-new--opened .cr-sidebar-new__tooltip-new {
    top: 0 !important;
    left: 265px !important; }
  .cr-sidebar-new--closed .cr-sidebar-new__tooltip-new {
    top: 0 !important;
    left: 78px !important; }
  .cr-sidebar-new__tooltip-new-opened.cr-sidebar-new__tooltip-new-normal .rc-tooltip-content {
    margin-top: 116px; }
  .cr-sidebar-new__tooltip-new-opened.cr-sidebar-new__tooltip-new-normal .rc-tooltip-arrow {
    top: 152px;
    left: -1px; }
  .cr-sidebar-new__tooltip-new-opened.cr-sidebar-new__tooltip-new-admin .rc-tooltip-content {
    margin-top: 125px; }
  .cr-sidebar-new__tooltip-new-opened.cr-sidebar-new__tooltip-new-admin .rc-tooltip-arrow {
    top: 160px;
    left: -1px; }
  .cr-sidebar-new__tooltip-new-closed.cr-sidebar-new__tooltip-new-normal .rc-tooltip-content {
    margin-top: 109px;
    margin-left: 21px; }
  .cr-sidebar-new__tooltip-new-closed.cr-sidebar-new__tooltip-new-normal .rc-tooltip-arrow {
    top: 145px;
    left: 20px; }
  .cr-sidebar-new__tooltip-new-closed.cr-sidebar-new__tooltip-new-admin .rc-tooltip-content {
    margin-top: 125px;
    margin-left: 21px; }
  .cr-sidebar-new__tooltip-new-closed.cr-sidebar-new__tooltip-new-admin .rc-tooltip-arrow {
    top: 160px;
    left: 20px; }
  .cr-sidebar-new__tooltip-new {
    opacity: 1;
    padding-top: 14px; }
    .cr-sidebar-new__tooltip-new .rc-tooltip-inner {
      background-color: #fff;
      padding: 0; }
    .cr-sidebar-new__tooltip-new .rc-tooltip-arrow {
      border-right-color: #224b8b;
      border-width: 10px 10px 10px 0; }
  .cr-sidebar-new__header {
    background: #224b8b;
    border-radius: 4px 4px 0 0;
    height: 55px;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
    padding-left: 40px;
    display: flex;
    align-items: center; }
  .cr-sidebar-new__section-admin {
    background: rgba(237, 244, 248, 0.7); }
  .cr-sidebar-new__sections {
    display: flex; }
    .cr-sidebar-new__sections .cr-sidebar-new__section:last-child {
      padding-right: 40px; }
  .cr-sidebar-new__section {
    min-width: 192px;
    padding-top: 25px;
    padding-left: 25px;
    padding-bottom: 35px; }
  .cr-sidebar-new__section-title {
    color: #6ea7da;
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    padding-left: 15px; }
  .cr-sidebar-new__links a,
  .cr-sidebar-new__links .btn-link {
    color: #334856;
    font-size: 14px;
    white-space: nowrap;
    display: block;
    padding: 5px 15px !important;
    height: inherit;
    text-align: left;
    text-transform: capitalize !important; }
    .cr-sidebar-new__links a:hover, .cr-sidebar-new__links a:focus,
    .cr-sidebar-new__links .btn-link:hover,
    .cr-sidebar-new__links .btn-link:focus {
      background: rgba(194, 212, 224, 0.25);
      text-decoration: none;
      font-weight: 600; }
  @media screen and (min-width: 1200px) and (max-width: 1436px) {
    .cr-sidebar-new--opened .cr-sidebar-new__tooltip-new {
      top: -24px !important;
      left: 244px !important; } }
  .cr-sidebar-new__wo-button-container {
    margin-left: 20px;
    margin-right: 20px; }
  .cr-sidebar-new__wo-button {
    background: #73a703;
    border-radius: 24px;
    color: #fff;
    width: 182px;
    height: 30px;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right; }
    .cr-sidebar-new__wo-button:hover, .cr-sidebar-new__wo-button:focus, .cr-sidebar-new__wo-button:active {
      text-decoration: none;
      background: #73a703;
      color: #fff; }
    .cr-sidebar-new__wo-button span {
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 12px;
      text-align: center;
      color: #ffffff;
      vertical-align: middle;
      text-decoration: none;
      margin-left: 6px;
      text-transform: uppercase; }
      .cr-sidebar-new__wo-button span:hover {
        text-decoration: none; }

.cr-advanced-search-modal {
  width: 610px;
  max-width: 610px; }
  .cr-advanced-search-modal--results {
    width: 785px;
    max-width: 785px; }
  .cr-advanced-search-modal .close {
    display: none; }
  .cr-advanced-search-modal .modal-header {
    padding: 0 50px;
    height: 70px;
    border-bottom-color: #edf1f6;
    display: flex;
    align-items: center;
    position: relative; }
  .cr-advanced-search-modal .modal-title {
    font-weight: bold;
    font-size: 23px;
    color: #334856;
    text-transform: capitalize; }
  .cr-advanced-search-modal__close {
    position: absolute;
    top: 20px;
    right: 23px;
    cursor: pointer; }
  .cr-advanced-search-modal .modal-body {
    padding: 39px 50px 50px; }
    .cr-advanced-search-modal .modal-body label {
      color: #334856;
      text-transform: uppercase;
      display: block; }
    .cr-advanced-search-modal .modal-body .form-group {
      margin-bottom: 27px; }
  .cr-advanced-search-modal__btn-search {
    margin-right: 14px; }
  .cr-advanced-search-modal__group-checkboxes {
    display: flex;
    align-items: center; }
    .cr-advanced-search-modal__group-checkboxes .cr-checkbox {
      margin-right: 30px; }
  .cr-advanced-search-modal__search-results-body {
    padding: 0 !important; }
    .cr-advanced-search-modal__search-results-body .cr-advanced-search-modal__buttons {
      border-top: 1px solid #edf1f6;
      padding: 27px 50px; }
  .cr-advanced-search-modal .table {
    margin-bottom: 0; }
    .cr-advanced-search-modal .table .cr-no-items-found {
      padding: 0; }
    .cr-advanced-search-modal .table thead {
      background-color: #224b8b;
      display: table;
      table-layout: fixed;
      width: 100%; }
      .cr-advanced-search-modal .table thead span {
        font-weight: bold;
        font-size: 16px;
        color: #f7fbfd; }
      .cr-advanced-search-modal .table thead th {
        border: none;
        padding: 9px 0.75rem;
        height: 44px; }
      .cr-advanced-search-modal .table thead tr .text-select {
        width: 70px; }
    .cr-advanced-search-modal .table tbody {
      max-height: 394px;
      overflow: auto;
      display: block;
      margin-right: 5px;
      padding-right: 5px; }
      .cr-advanced-search-modal .table tbody tr {
        display: table;
        table-layout: fixed;
        width: 100%; }
        .cr-advanced-search-modal .table tbody tr:hover {
          box-shadow: 0px 0px 15px rgba(194, 212, 224, 0.8); }
      .cr-advanced-search-modal .table tbody td {
        border-bottom-color: #edf1f6;
        height: 76px; }
  .cr-advanced-search-modal__item-sortable {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .cr-advanced-search-modal__item-sortable-center {
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-advanced-search-modal__sortable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    text-align: center; }
    .cr-advanced-search-modal__sortable:hover {
      border-radius: 100px;
      background-image: linear-gradient(to bottom, #6f96d1, #5f85c0, #4f75b0, #3f659f, #2e558f); }
  .cr-advanced-search-modal__sortable-icon {
    position: absolute;
    font-size: 16px;
    color: #fff; }
  .cr-advanced-search-modal__sortable-active {
    color: #c12674; }
  .cr-advanced-search-modal__title-text {
    font-weight: bold;
    font-size: 16px;
    color: #334856; }
  .cr-advanced-search-modal__type-text {
    font-size: 16px;
    color: #334856;
    padding-left: 20px !important; }
  .cr-advanced-search-modal__created-text {
    font-size: 16px;
    color: #537a91;
    border-right: 1px solid #edf1f6;
    padding-left: 22px !important; }
  .cr-advanced-search-modal__checkbox-td {
    width: 70px; }
    .cr-advanced-search-modal__checkbox-td .cr-checkbox {
      display: flex;
      justify-content: flex-end;
      height: 100%; }
  .cr-advanced-search-modal .title-th,
  .cr-advanced-search-modal .cr-advanced-search-modal__title-text {
    width: 30%; }
  .cr-advanced-search-modal input::-webkit-input-placeholder {
    color: #657894; }
  .cr-advanced-search-modal input::placeholder {
    color: #657894; }
  .cr-advanced-search-modal .react-select__placeholder {
    color: #657894 !important; }
  .cr-advanced-search-modal .react-datepicker__input-container input::-webkit-input-placeholder {
    color: #829fb1; }
  .cr-advanced-search-modal .react-datepicker__input-container input::placeholder {
    color: #829fb1; }
  .cr-advanced-search-modal__prize-status {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .cr-advanced-search-modal__prize-status .cr-checkbox__text {
      color: #43535c;
      text-transform: lowercase; }
  .cr-advanced-search-modal__buttons {
    margin-top: 20px; }
  .cr-advanced-search-modal .cr-toggle-switch .cr-toggle-switch__left-component,
  .cr-advanced-search-modal .cr-toggle-switch .cr-toggle-switch__right-component {
    text-transform: uppercase;
    font-weight: 400;
    color: #829fb1; }
  .cr-advanced-search-modal .cr-toggle-switch .active {
    font-weight: 600;
    color: #374c59; }

.cr-feedback-success-modal {
  width: 639px;
  max-width: 639px; }
  .cr-feedback-success-modal__body {
    height: 435px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .cr-feedback-success-modal__title {
    font-weight: 800;
    font-size: 32px;
    text-align: center;
    color: #000000; }
  .cr-feedback-success-modal__description {
    font-size: 18px;
    text-align: center;
    color: #657894; }
  .cr-feedback-success-modal__user {
    margin-top: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
  .cr-feedback-success-modal__user-name {
    font-weight: 800;
    font-size: 18px;
    text-align: center;
    color: #224b8b; }
  .cr-feedback-success-modal__user-role {
    font-size: 14px;
    text-align: center;
    color: #336abb; }
  .cr-feedback-success-modal__feedback-avatar {
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
    width: 150px;
    height: 150px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    overflow: hidden;
    margin: 0 auto;
    padding: 10px;
    margin-bottom: 10px; }
    .cr-feedback-success-modal__feedback-avatar img {
      width: 100%;
      height: 100%; }
  .cr-feedback-success-modal__avatar {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 100px; }
  @media (max-width: 480px) {
    .cr-feedback-success-modal {
      width: auto !important;
      height: auto !important;
      align-items: center;
      min-height: calc(100% - (0.5rem * 2));
      display: flex; }
      .cr-feedback-success-modal__title {
        font-size: 32px;
        line-height: 38px; }
      .cr-feedback-success-modal__user {
        margin-top: 10px; } }

.cr-client-reassign-modal {
  min-width: 562px; }
  .cr-client-reassign-modal__body {
    padding-left: 133px;
    padding-right: 150px;
    margin-bottom: 37px; }
  .cr-client-reassign-modal .close {
    display: none; }
  .cr-client-reassign-modal__header {
    border-bottom: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: 81px;
    padding-bottom: 14px; }
  .cr-client-reassign-modal__footer {
    border-top: 0;
    justify-content: center;
    padding-bottom: 83px; }
  .cr-client-reassign-modal .modal-title {
    color: #000000;
    font-weight: 800;
    font-size: 40px; }

.cr-client-manager-form__content-form {
  padding-bottom: 100px; }
  .cr-client-manager-form__content-form label {
    color: #43535c;
    text-transform: uppercase;
    display: flex; }
  .cr-client-manager-form__content-form .form-group {
    margin-bottom: 27px; }

.cr-client-manager-form__content-view {
  padding-bottom: 100px; }
  .cr-client-manager-form__content-view label {
    color: #657894;
    text-transform: uppercase;
    display: block; }

.cr-client-manager-form__order-view-value {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  display: block; }

.cr-client-manager-form__col-view-field {
  margin-bottom: 46px !important; }

.cr-client-manager-form__reset {
  margin-bottom: 0; }

.cr-client-manager-form__section-title {
  text-transform: capitalize; }

.cr-client-manager-form__section-client-fields {
  padding-top: 23px; }

.cr-client-manager-form__form-buttons {
  padding-left: 49px; }
  .cr-client-manager-form__form-buttons .btn-outline-blue {
    margin-left: 14px; }

.cr-client-manager-form__checkboxes {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .cr-client-manager-form__checkboxes .cr-checkbox {
    margin-right: 28px; }

.cr-client-manager-form__add-icon {
  margin-right: 18px; }

.cr-client-manager-form__section-contact-fields {
  padding: 0 !important; }
  .cr-client-manager-form__section-contact-fields .cr-client-manager-form__contact-container:first-child {
    border-top: none; }

.cr-client-manager-form__add-button {
  width: 184px !important; }
  .cr-client-manager-form__add-button span {
    text-transform: initial;
    font-weight: bold; }

.cr-client-manager-form__contact-title {
  color: #334856;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 14px;
  position: relative;
  text-transform: capitalize; }

.cr-client-manager-form__contact-button {
  padding-left: 49px;
  padding-right: 49px;
  padding-bottom: 48px;
  display: inline-flex;
  flex-direction: column; }
  .cr-client-manager-form__contact-button .alert-danger {
    margin-top: 5px; }

.cr-client-manager-form__contact-container {
  padding-top: 23px;
  padding-left: 49px;
  padding-right: 49px;
  border-top: 1px solid #edf1f6; }

.cr-client-manager-form__contact-primary-text {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-left: 20px;
  color: #795afa;
  cursor: pointer; }

.cr-client-manager-form__success-modal .modal-title {
  max-width: 300px; }

.cr-client-manager-form__contact-remove {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  background-color: transparent;
  border: none; }
  .cr-client-manager-form__contact-remove:disabled {
    cursor: not-allowed; }

.cr-client-manager-form .bottom-actions-sticky .buttons .btn-blue {
  margin-right: 14px; }

@media (min-width: 1599px) {
  .cr-client-manager-form .bottom-actions-sticky .buttons {
    padding-left: 70px;
    padding-right: 70px; } }

.cr-exclude-hours-modal {
  min-width: 677px;
  width: 677px; }
  .cr-exclude-hours-modal .modal-header {
    position: relative;
    padding-top: 26px;
    padding-left: 46px;
    padding-right: 58px;
    border-bottom: none;
    padding-bottom: 24px; }
  .cr-exclude-hours-modal .modal-body {
    padding-left: 46px;
    padding-right: 58px;
    padding-top: 0;
    padding-bottom: 0; }
  .cr-exclude-hours-modal .modal-footer {
    justify-content: flex-start;
    padding-left: 46px;
    padding-bottom: 36px;
    padding-right: 58px;
    padding-top: 36px;
    border-top: 0; }
    .cr-exclude-hours-modal .modal-footer .btn-blue {
      margin-right: 10px; }
  .cr-exclude-hours-modal__title {
    color: #334856;
    font-weight: bold;
    font-size: 23px;
    margin-bottom: 0;
    text-transform: capitalize; }
  .cr-exclude-hours-modal__description {
    color: #537a91;
    font-size: 16px;
    margin-bottom: 0; }
  .cr-exclude-hours-modal__close {
    position: absolute;
    top: 21px;
    right: 25px;
    cursor: pointer; }
  .cr-exclude-hours-modal .nav-tabs {
    border-bottom: none; }
    .cr-exclude-hours-modal .nav-tabs .nav-link {
      font-size: 12px;
      color: #795afa;
      text-transform: uppercase;
      text-align: center;
      width: 69px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%; }
      .cr-exclude-hours-modal .nav-tabs .nav-link.active {
        width: 88px;
        color: #334856;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
        border: none;
        position: relative; }
        .cr-exclude-hours-modal .nav-tabs .nav-link.active:after {
          background-color: #fff;
          height: 20px;
          width: 88px;
          content: "";
          position: absolute;
          bottom: -15px; }
      .cr-exclude-hours-modal .nav-tabs .nav-link.disabled {
        color: #829fb1;
        cursor: not-allowed; }
  .cr-exclude-hours-modal .tab-content {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 0 0 6px 6px;
    padding: 50px 0; }
  .cr-exclude-hours-modal__hours {
    max-width: 450px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 400px;
    margin: 0 auto; }
    .cr-exclude-hours-modal__hours .cr-checkbox {
      width: 33%;
      margin: 7px 0;
      flex: 1 1 31px;
      margin: 7px 0; }
    .cr-exclude-hours-modal__hours .cr-checkbox__text {
      color: #111b2b;
      font-size: 16px;
      margin-left: 17px; }

.cr-supplement-items-modal {
  min-width: 609px;
  width: 609px; }
  .cr-supplement-items-modal .modal-header {
    position: relative;
    padding-left: 31px;
    padding-right: 34px;
    border-bottom: 1px solid #edf1f6;
    height: 78px;
    align-items: center; }
  .cr-supplement-items-modal .modal-body {
    padding-left: 33px;
    padding-right: 34px;
    padding-top: 14px;
    padding-bottom: 0; }
  .cr-supplement-items-modal .modal-footer {
    justify-content: flex-start;
    padding-left: 33px;
    padding-bottom: 31px;
    padding-right: 34px;
    padding-top: 0;
    border-top: 0; }
    .cr-supplement-items-modal .modal-footer .btn-blue {
      margin-right: 10px; }
  .cr-supplement-items-modal__title {
    color: #334856;
    font-weight: bold;
    font-size: 23px;
    margin-bottom: 0; }
  .cr-supplement-items-modal__description {
    color: #657894;
    font-size: 16px;
    margin-bottom: 20px; }
  .cr-supplement-items-modal__close {
    position: absolute;
    top: 21px;
    right: 25px;
    cursor: pointer; }
  .cr-supplement-items-modal__head-text {
    color: #334856;
    font-size: 20px;
    margin-bottom: 16px;
    display: flex;
    align-items: center; }
    .cr-supplement-items-modal__head-text strong {
      margin: 0 10px; }
  .cr-supplement-items-modal__type-text {
    color: #334856;
    font-size: 14px; }
  .cr-supplement-items-modal__available-text {
    font-size: 20px;
    color: #334856; }
  .cr-supplement-items-modal .table {
    margin-bottom: 28px;
    border-radius: 6px;
    overflow: hidden;
    border-style: hidden;
    /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px #c2d4e0;
    /* this draws the table border  */ }
    .cr-supplement-items-modal .table thead {
      background: #f7fbfd;
      box-sizing: border-box; }
      .cr-supplement-items-modal .table thead th {
        border-bottom: 1px solid #c2d4e0; }
        .cr-supplement-items-modal .table thead th:first-child {
          padding-left: 37px; }
      .cr-supplement-items-modal .table thead span {
        color: #829fb1;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase; }
    .cr-supplement-items-modal .table .cr-textinput {
      width: 67px;
      margin: 0 auto; }
      .cr-supplement-items-modal .table .cr-textinput .cr-textinput__input-wrapper {
        height: 32px; }
    .cr-supplement-items-modal .table tbody td:first-child {
      width: 50%;
      padding-left: 37px; }

.cr-team-members-list .card {
  border: none;
  border-radius: 0; }

.cr-team-members-list .ReactTable {
  min-height: 200px;
  background-color: #fff !important;
  margin-top: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  overflow: hidden;
  border: none !important;
  padding-bottom: 0 !important; }
  .cr-team-members-list .ReactTable .rt-table {
    overflow: hidden; }
    .cr-team-members-list .ReactTable .rt-table .rt-thead .rt-th {
      border: none;
      text-align: left;
      padding: 5px 0; }
      .cr-team-members-list .ReactTable .rt-table .rt-thead .rt-th span {
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 21px;
        color: #ffffff; }

.cr-team-members-list .Table__rppContainer {
  min-width: 78px; }
  .cr-team-members-list .Table__rppContainer .react-select__control {
    min-height: 50px !important;
    border-color: #c2d4e0 !important;
    margin-top: -10px; }
  .cr-team-members-list .Table__rppContainer .react-select__single-value {
    color: #000000; }
  .cr-team-members-list .Table__rppContainer .react-select__indicator-separator {
    width: 0px; }
  .cr-team-members-list .Table__rppContainer .react-select__dropdown-indicator svg {
    fill: #373a3c; }

.cr-team-members-list .card-body {
  padding: 0;
  padding-top: 1px;
  background: #fff; }

.cr-team-members-list .rt-thead {
  background: #657894;
  height: 55px; }
  .cr-team-members-list .rt-thead .rt-th:last-child {
    flex: 14 0 auto !important; }

.cr-team-members-list .rt-resizable-header {
  border-right-color: transparent !important; }

.cr-team-members-list .rt-resizable-header-content {
  padding-left: 25px; }

.cr-team-members-list .rt-resizable-header {
  padding-left: 30px; }

.cr-team-members-list .rt-tbody .rt-td {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 20px;
  border-right: none; }

.cr-team-members-list .rt-tbody .rt-tr .rt-td:first-child {
  padding-left: 30px; }

.cr-team-members-list .rt-tbody .rt-tr .rt-td:last-child {
  flex: 17 0 auto !important; }

.cr-team-members-list__table-head-title-lead {
  padding-left: 30px; }

.cr-team-members-list__text-truncate {
  white-space: nowrap;
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden; }

.cr-team-members-list__table-head-title {
  line-height: 46px; }

.cr-team-members-list__table-head-title-last {
  text-align: center;
  padding-right: 1rem; }

.cr-team-members-list .sortable span {
  position: relative; }
  .cr-team-members-list .sortable span::before {
    top: 0px !important;
    right: -25px !important;
    left: auto !important; }
  .cr-team-members-list .sortable span::after {
    top: 7px !important;
    right: -23px !important;
    left: auto !important; }

.cr-unrated-market-modal .modal-header {
  border-bottom: 1px solid #edf1f6;
  padding: 0 26px;
  height: 66px;
  display: flex;
  align-items: center; }

.cr-unrated-market-modal .modal-title {
  font-weight: bold;
  font-size: 23px;
  color: #334856;
  text-transform: capitalize; }

.cr-unrated-market-modal__close {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 20px; }
  .cr-unrated-market-modal__close:focus {
    box-shadow: none; }

.cr-unrated-market-modal .close {
  display: none; }

.cr-unrated-market-modal .modal-content {
  width: 695px; }

.cr-unrated-market-modal .modal-body {
  padding: 0; }

.cr-unrated-market-modal__content-form {
  padding-top: 29px; }
  .cr-unrated-market-modal__content-form label {
    color: #43535c;
    text-transform: uppercase; }
  .cr-unrated-market-modal__content-form .form-group {
    margin-bottom: 27px; }

.cr-unrated-market-modal__col-reset {
  margin-bottom: 0; }

.cr-unrated-market-modal__form-padding {
  padding-left: 38px;
  padding-right: 52px; }

.cr-unrated-market-modal__form-buttons {
  padding-top: 15px;
  padding-bottom: 47px; }
  .cr-unrated-market-modal__form-buttons .btn-blue {
    margin-right: 14px; }

.cr-jock-console-content__jock-content {
  margin-top: 16px; }

.cr-jock-console-content__jock-type {
  color: #657894;
  font-size: 24px;
  font-weight: 400; }

.cr-jock-console-content__deadline-text {
  color: #657894;
  font-size: 14px; }

.cr-jock-console-content__jock-title {
  font-weight: bold;
  font-size: 30px;
  color: #000000;
  margin: 0; }

.cr-jock-console-content__restrictions {
  text-align: right; }
  .cr-jock-console-content__restrictions span {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #657894;
    opacity: 0.8;
    text-transform: uppercase;
    margin-right: 10px; }
  .cr-jock-console-content__restrictions strong {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #000000; }

.cr-jock-console-content__deadline-time {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center; }

.cr-jock-console-content__deadline-text {
  color: #657894;
  font-size: 14px;
  opacity: 0.8;
  text-transform: uppercase; }

.cr-jock-console-content__jock-header {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.cr-jock-console-content__deadline-date {
  font-size: 16px;
  color: #334856;
  text-transform: capitalize;
  text-align: right; }
  .cr-jock-console-content__deadline-date strong {
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    margin-left: 9px;
    text-transform: lowercase; }

.cr-jock-console-content__jock-card {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  position: relative; }

.cr-jock-console-content__jock-card-contest .cr-jock-console-content__card-header {
  background-color: #3897d7; }

.cr-jock-console-content__card-header {
  background: #795afa;
  border-radius: 20px 20px 0px 0px;
  height: 46px;
  padding-left: 40px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.cr-jock-console-content__card-title {
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase; }

.cr-jock-console-content__card-content {
  padding: 10px 18px 52px 20px;
  font-size: 16px;
  color: #333333; }

.cr-jock-console-content__card-content-zoom {
  font-size: 30px;
  line-height: 38px; }

.cr-jock-console-content__search-icon {
  position: absolute;
  bottom: 7px;
  right: 16px;
  cursor: pointer; }

.cr-jock-console-content__tweet-icon {
  bottom: 16px;
  left: 27px;
  position: absolute; }

.cr-jock-console-content__copy-icon {
  position: absolute;
  bottom: 11px;
  left: 27px;
  cursor: pointer; }
  .cr-jock-console-content__copy-icon svg {
    width: 19px; }
  .cr-jock-console-content__copy-icon span {
    color: #73a703; }

.cr-jock-console-content__copied {
  bottom: 11px; }

.cr-jock-console-content .cr-jock-console-content__jock-card {
  margin-bottom: 16px; }

.cr-jock-console-content__buttons {
  margin-top: 30px;
  display: flex;
  align-items: center; }

.cr-jock-console-content__btn-done {
  height: 63px;
  background: #73a703;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1; }
  .cr-jock-console-content__btn-done span {
    font-weight: 800;
    font-size: 18px;
    color: #fff;
    margin-left: 10px; }

.cr-jock-console-content__btn-no-winner {
  background: #ffffff;
  height: 63px;
  width: 202px;
  margin-left: 11px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .cr-jock-console-content__btn-no-winner span {
    font-weight: 800;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #e40061; }

.cr-jock-console-content__btn-completed {
  height: 63px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  background: rgba(101, 120, 148, 0.3);
  border-radius: 20px;
  font-weight: 800;
  font-size: 18px;
  color: #fff; }

.cr-jock-console-content__completed-button-text {
  font-weight: 800;
  font-size: 18px;
  color: #fff;
  margin-left: 10px; }

.cr-jock-console-content__completed-time-text {
  text-transform: lowercase;
  color: #fff;
  font-size: 18px;
  margin-left: 10px; }

.cr-jock-console-content__view-note {
  font-size: 16px;
  line-height: 20px;
  text-decoration: underline;
  text-transform: uppercase;
  color: #795afa;
  margin-left: 20px; }

.cr-jock-console-content__with-no-winner {
  color: #e40061;
  margin-left: 5px; }

.cr-jock-console-content .replace-tag-red {
  color: #e40061;
  text-transform: uppercase;
  font-weight: 800; }

.cr-jock-console-content__undo {
  font-size: 16px;
  line-height: 20px;
  text-decoration: underline;
  text-transform: uppercase;
  color: #795afa;
  margin-left: 20px; }

.cr-jock-console-content__no-arrow {
  -moz-appearance: textfield; }
  .cr-jock-console-content__no-arrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

.cr-jock-console-content__overdue-text {
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.05em;
  color: #e40061;
  margin-bottom: 2px;
  text-transform: uppercase; }

.cr-jock-console-content__overdue-date {
  font-size: 14px;
  line-height: 18px;
  color: #e40061;
  text-align: right; }

.cr-jock-console-content__jock-card-table {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  margin-top: 26px;
  overflow: hidden; }

.cr-jock-console-content__jock-card-table-title {
  background: #0f5589;
  height: 75px;
  padding: 0 28px 0 37px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .cr-jock-console-content__jock-card-table-title h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.05em;
    color: #ffffff;
    opacity: 0.8;
    text-transform: uppercase;
    margin-bottom: 0; }

.cr-jock-console-content__jock-card-table-title-info {
  text-transform: uppercase; }
  .cr-jock-console-content__jock-card-table-title-info span {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #ffffff;
    font-weight: 600;
    opacity: 0.8;
    margin-right: 10px; }
  .cr-jock-console-content__jock-card-table-title-info strong {
    font-weight: 800;
    font-size: 26px;
    color: #ffffff; }

.cr-jock-console-content__jock-card-table-middle {
  background: #f4fafe;
  padding: 0 33px; }

.cr-jock-console-content__contest-description {
  padding-top: 29px;
  padding-bottom: 19px; }
  .cr-jock-console-content__contest-description h3 {
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    color: #000000;
    text-transform: capitalize;
    margin-bottom: 6px; }

.cr-jock-console-content__jock-card-table-bottom {
  padding: 0 33px;
  margin-bottom: 40px; }

.cr-jock-console-content__prize-table {
  width: 100%; }
  .cr-jock-console-content__prize-table thead tr {
    border-bottom: 0.75px solid #f2f2f2; }
  .cr-jock-console-content__prize-table thead th {
    padding: 6px 0; }
    .cr-jock-console-content__prize-table thead th span {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.05em;
      font-weight: 500;
      text-transform: uppercase;
      color: #829fb1; }
  .cr-jock-console-content__prize-table tbody td {
    padding: 6px 0; }
  .cr-jock-console-content__prize-table strong {
    font-size: 18px;
    color: #000000; }

.cr-jock-console-content__prize-amount {
  font-weight: bold;
  font-size: 16px;
  color: #000000; }

.cr-jock-console-content__prize-text {
  font-size: 16px;
  color: #333333; }

.cr-jock-console-content__prize-column {
  width: 30%;
  padding-right: 6px; }

.cr-jock-console-content__prize-deadline-text {
  color: #795afa;
  font-size: 16px; }

.cr-jock-console-content__checked-icon {
  margin-right: 4px; }
  .cr-jock-console-content__checked-icon svg path {
    fill: #ffffff; }

.cr-jock-console-content__winner-table {
  width: 100%; }
  .cr-jock-console-content__winner-table thead tr {
    border-bottom: 0.75px solid #daecf8; }
  .cr-jock-console-content__winner-table thead th {
    padding: 9px 0; }
  .cr-jock-console-content__winner-table thead span {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #537a91;
    font-weight: 400; }
    .cr-jock-console-content__winner-table thead span.asterisk {
      color: red; }
  .cr-jock-console-content__winner-table tbody td {
    padding: 22px 0; }
  .cr-jock-console-content__winner-table tbody strong {
    font-weight: bold;
    font-size: 18px;
    color: #795afa; }
  .cr-jock-console-content__winner-table tbody span {
    font-size: 16px;
    color: #000000; }
  .cr-jock-console-content__winner-table .action-button {
    background-color: #795afa;
    border-radius: 24px;
    padding: 8px 24px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    margin-left: 8px;
    cursor: pointer; }
    .cr-jock-console-content__winner-table .action-button span {
      color: #ffffff;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase; }
    .cr-jock-console-content__winner-table .action-button:hover {
      opacity: 0.8; }
    .cr-jock-console-content__winner-table .action-button.has-won {
      background-color: #73a703;
      pointer-events: none; }
    .cr-jock-console-content__winner-table .action-button.has-lost {
      pointer-events: none;
      background-color: #ff006c; }
    .cr-jock-console-content__winner-table .action-button.disabled {
      background-color: #c2d4e0 !important;
      pointer-events: none; }
      .cr-jock-console-content__winner-table .action-button.disabled.completed {
        display: none; }
  .cr-jock-console-content__winner-table .prize-input .input-container {
    border-radius: 6px;
    background: #ffffff;
    width: 80px;
    color: #000000; }

.cr-jock-console-content .btn-radius svg + span {
  margin-left: 10px; }

.cr-jock-console-content .cr-preview-script-modal__slider-wrapper {
  justify-content: flex-end; }

@media screen and (min-width: 1200px) and (max-width: 1436px) {
  .cr-jock-console-content__deadline-date {
    font-size: 14px;
    display: flex;
    flex-direction: column; } }

.cr-jock-console-content .content-editable {
  padding-left: 18px;
  padding-top: 10px;
  padding-right: 18px;
  border: 1px solid #fff;
  outline: 1px solid #fff; }
  .cr-jock-console-content .content-editable.disabled:hover {
    border: 1px solid #fff;
    cursor: initial; }
  .cr-jock-console-content .content-editable:hover {
    border: 1px solid #c2d4e0;
    cursor: pointer; }
  .cr-jock-console-content .content-editable p {
    margin-bottom: 0; }
  .cr-jock-console-content .content-editable br {
    content: "";
    display: block;
    padding: 10px 0; }

.cr-jock-console-left-sidebar {
  position: relative; }
  .cr-jock-console-left-sidebar .cr-jock-console__calendar-card {
    margin-bottom: 8px; }
  .cr-jock-console-left-sidebar__calendar-hour-tasks {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1;
    height: calc(100% - 100px); }
    .cr-jock-console-left-sidebar__calendar-hour-tasks .cr-jock-console__calendar-hour-tasks {
      margin-bottom: 19px; }
  .cr-jock-console-left-sidebar__calendar-tasks {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%; }
    .cr-jock-console-left-sidebar__calendar-tasks.has-floating-bottom {
      margin-bottom: 79px;
      padding-bottom: 18px;
      z-index: 8; }
  .cr-jock-console-left-sidebar__calendar-picker {
    text-align: center;
    margin-bottom: 20px; }
  .cr-jock-console-left-sidebar__no-tasks {
    text-align: center;
    color: #333333;
    font-size: 16px; }
  .cr-jock-console-left-sidebar__calendar-filter {
    margin-bottom: 20px; }
    .cr-jock-console-left-sidebar__calendar-filter .cr-toggle-switch {
      justify-content: center; }
    .cr-jock-console-left-sidebar__calendar-filter .cr-toggle-switch__left-component,
    .cr-jock-console-left-sidebar__calendar-filter .cr-toggle-switch__right-component {
      text-transform: uppercase;
      color: #537a91; }
      .cr-jock-console-left-sidebar__calendar-filter .cr-toggle-switch__left-component.active,
      .cr-jock-console-left-sidebar__calendar-filter .cr-toggle-switch__right-component.active {
        color: #374c59; }

.cr-jock-console-right-sidebar.hide {
  width: 80px !important;
  cursor: pointer; }
  .cr-jock-console-right-sidebar.hide .cr-jock-console-right-sidebar__jock-head,
  .cr-jock-console-right-sidebar.hide .cr-jock-console-right-sidebar__jock-tags,
  .cr-jock-console-right-sidebar.hide .cr-jock-console-right-sidebar__multiple-content,
  .cr-jock-console-right-sidebar.hide .cr-jock-console__social-links,
  .cr-jock-console-right-sidebar.hide .cr-jock-console__widget-detail-padding,
  .cr-jock-console-right-sidebar.hide .cr-jock-console__no-widgets {
    display: none; }
  .cr-jock-console-right-sidebar.hide .collapseIcon svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

.cr-jock-console-right-sidebar .collapseIcon {
  position: absolute;
  left: 18px;
  top: 15px;
  cursor: pointer; }

.cr-jock-console-right-sidebar .cr-jock-console__station-logo {
  margin-right: 37px; }

.cr-jock-console-right-sidebar__jock-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;
  padding-left: 24px;
  padding-right: 23px; }

.cr-jock-console-right-sidebar__jock-info:first-child {
  margin-bottom: 15px; }

.cr-jock-console-right-sidebar__jock-info strong {
  display: block;
  font-weight: bold;
  color: #000000;
  font-size: 21px; }

.cr-jock-console-right-sidebar__jock-info span {
  display: block;
  font-size: 21px;
  color: #7b8693; }

.cr-jock-console-right-sidebar__text-small {
  font-size: 14px;
  color: #7b8693;
  max-width: 140px; }

.cr-jock-console-right-sidebar__jock-tags {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-left: 24px;
  padding-right: 23px; }

.cr-jock-console-right-sidebar__multiple-view {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .cr-jock-console-right-sidebar__multiple-view span {
    font-size: 12px;
    line-height: 15px;
    color: #795afa; }

.cr-jock-console-right-sidebar__star-icon {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #795afa;
  border-radius: 100px;
  margin-right: 6px; }
  .cr-jock-console-right-sidebar__star-icon svg {
    margin-bottom: 1px; }

.cr-jock-console-right-sidebar__multiple-content {
  display: flex;
  justify-content: flex-end;
  padding-right: 23px;
  padding-left: 24px;
  margin-bottom: 24px; }

.cr-jock-console {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background: #dfecf5;
  height: 100%; }
  .cr-jock-console .cr-jock-console-left-sidebar {
    width: 400px;
    min-width: 400px;
    padding-top: 35px;
    background-color: rgba(255, 255, 255, 0.5); }
  .cr-jock-console .cr-jock-console-right-sidebar {
    width: 430px;
    background-color: #fff;
    padding-top: 47px;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: width 0.2s; }
  .cr-jock-console .cr-jock-console-content {
    width: 1100px;
    padding: 16px 20px 30px 20px; }
  .cr-jock-console__content-wrapper {
    display: flex;
    justify-content: center;
    overflow-y: auto;
    width: 98%; }
    .cr-jock-console__content-wrapper {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */ }
    .cr-jock-console__content-wrapper::-webkit-scrollbar {
      display: none; }
  .cr-jock-console__scroll-content {
    position: absolute;
    top: 37px;
    height: calc(100% - 80px);
    overflow: scroll;
    left: 0;
    right: 0;
    bottom: 0; }
  .cr-jock-console__scroll-container {
    flex: 1 1;
    position: relative; }
  .cr-jock-console__items-list {
    padding-right: 10px; }
  .cr-jock-console__item-row {
    margin-bottom: 20px; }
    .cr-jock-console__item-row:last-child {
      margin-bottom: 0; }
  .cr-jock-console__widget-detail-padding {
    margin-left: 24px;
    margin-right: 23px; }
  .cr-jock-console__jock-tag {
    z-index: 999999 !important;
    background: #dfecf5;
    border-radius: 6px;
    width: 92px;
    height: 51px;
    padding: 5px;
    position: relative;
    margin: 0 5px 5px 0;
    display: inline-block;
    cursor: pointer;
    opacity: 0.3; }
    .cr-jock-console__jock-tag:hover, .cr-jock-console__jock-tag.cr-jock-console__jock-tag-active {
      opacity: 1; }
    .cr-jock-console__jock-tag.is-empty {
      cursor: default;
      pointer-events: none;
      background: #eff3f6;
      border: 1px dashed #829fb1; }
      .cr-jock-console__jock-tag.is-empty .cr-jock-console__star-icon {
        display: none; }
  .cr-jock-console__jock-tag-title {
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 14px; }
  .cr-jock-console__jock-tag-preview {
    border-radius: 6px;
    width: 168px;
    position: relative;
    display: inline-block;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 8px;
    z-index: 999999 !important; }
    .cr-jock-console__jock-tag-preview:last-child {
      margin-right: 0; }
    .cr-jock-console__jock-tag-preview.is-empty {
      background: #eff3f6;
      border: 1px dashed #829fb1; }
      .cr-jock-console__jock-tag-preview.is-empty .cr-jock-console__jock-tag-preview-overlay {
        top: 0;
        background: #eff3f6; }
  .cr-jock-console__jock-tag-preview-title {
    font-weight: bold;
    padding: 8px 10px;
    font-size: 12px;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 14px;
    height: 34px;
    display: flex;
    align-items: center; }
  .cr-jock-console__jock-tag-preview-overlay {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top, #ffffff, rgba(0, 0, 0, 0)); }
  .cr-jock-console__jock-tag-preview-content {
    padding: 0 1px;
    background: linear-gradient(to bottom, #c2d4e0, #ffffff); }
  .cr-jock-console__jock-tag-preview-content-group {
    padding: 0 14px 8px 14px;
    background: #ffffff; }
    .cr-jock-console__jock-tag-preview-content-group:first-child {
      padding-top: 11px; }
  .cr-jock-console__jock-tag-preview-content-bar {
    background: #e2eef7;
    border-radius: 3px;
    height: 7px;
    margin-bottom: 3px; }
  .cr-jock-console__star-icon {
    width: 12px;
    height: 12px;
    display: inline-flex;
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
    right: 4px;
    position: absolute;
    bottom: 4px; }
    .cr-jock-console__star-icon svg {
      margin-bottom: 1px; }
  .cr-jock-console__jock-tags {
    display: flex; }
  .cr-jock-console__station-board {
    padding-bottom: 40px;
    position: relative; }
    .cr-jock-console__station-board .scroll-bar-style {
      overflow: auto; }
  .cr-jock-console__station-board-title {
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    margin-bottom: 20px;
    padding-left: 6px; }
  .cr-jock-console__station-board-content {
    font-size: 13px;
    line-height: 16px;
    color: #334856;
    max-height: 528px;
    padding-left: 6px;
    padding-right: 18px; }
  .cr-jock-console__new-window-icon {
    position: absolute;
    position: absolute;
    right: 20px;
    bottom: 10px;
    cursor: pointer; }
  .cr-jock-console__head-title-multi-view {
    height: 47px;
    border-radius: 6px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    color: #fff !important;
    position: -webkit-sticky;
    position: sticky;
    left: 0; }
  .cr-jock-console__news-title {
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    margin-bottom: 5px; }
  .cr-jock-console__news-subtitle {
    font-size: 11px;
    line-height: 14px;
    color: #ad29c0; }
  .cr-jock-console__news-image {
    background-repeat: no-repeat;
    border-radius: 10px;
    margin-right: 10px;
    width: 123px;
    height: 67px;
    background-size: cover;
    background-repeat: no-repeat;
    flex: none; }
  .cr-jock-console__news-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px; }
  .cr-jock-console__news-head-title {
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    margin-bottom: 10px; }
  .cr-jock-console__trending-hashtags-head-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    text-transform: capitalize; }
  .cr-jock-console__tag-item {
    padding: 13px 10px 10px;
    border-bottom: 1px solid rgba(194, 212, 224, 0.6); }
  .cr-jock-console__tag-category {
    font-size: 11px;
    line-height: 14px;
    color: #657894; }
  .cr-jock-console__tag-title {
    font-weight: 800;
    font-size: 16px;
    color: #000000;
    line-height: 20px; }
  .cr-jock-console__tag-subtitle {
    font-size: 14px;
    line-height: 18px;
    color: #657894; }
  .cr-jock-console__hashtags {
    margin-top: 23px;
    margin-bottom: 20px;
    border-bottom: 1px solid #829fb1;
    padding-bottom: 10px; }
  .cr-jock-console__hashtag-item {
    color: #657894;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin: 0 10px;
    cursor: pointer; }
  .cr-jock-console__hashtag-item-active {
    color: #795afa; }
  .cr-jock-console__social-list {
    padding: 0 10px; }
  .cr-jock-console__social-head-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 15px;
    text-transform: capitalize; }
  .cr-jock-console__social-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 17px;
    cursor: pointer; }
  .cr-jock-console__social-icon {
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-jock-console__social-link {
    font-size: 13px;
    line-height: 16px;
    color: #795afa;
    word-break: break-word; }
  .cr-jock-console__phone-numbers-head-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 20px;
    text-transform: capitalize; }
  .cr-jock-console__phone-name {
    font-size: 14px;
    line-height: 19px;
    color: #43535c;
    font-weight: bold; }
  .cr-jock-console__phone-number {
    font-size: 14px;
    line-height: 19px;
    color: #43535c;
    margin-left: 5px; }
  .cr-jock-console__phone-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 10px; }
  .cr-jock-console__calendars-head-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 20px;
    text-transform: capitalize; }
  .cr-jock-console__event-item {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .cr-jock-console__event-title {
    font-size: 14px;
    line-height: 18px;
    color: #000000; }
  .cr-jock-console__event-subtitle {
    font-size: 11px;
    line-height: 14px;
    color: #ad29c0; }
  .cr-jock-console__event-image {
    width: 100px;
    height: 65px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 10px;
    margin-right: 10px;
    flex: none; }
  .cr-jock-console__event-left {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .cr-jock-console__event-date {
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 4px 24px rgba(51, 72, 86, 0.12);
    width: 50px;
    height: 55px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center; }
  .cr-jock-console__event-date-text {
    color: #000000;
    letter-spacing: -0.1em;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px; }
  .cr-jock-console__event-month-text {
    font-size: 12px;
    line-height: 14px;
    color: #ad29c0;
    font-weight: bold; }
  .cr-jock-console__calendar-card {
    max-width: 305px;
    background-color: #fff;
    height: 64px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative; }
  .cr-jock-console__calendar-card-content-left {
    display: flex; }
  .cr-jock-console__winner-icon {
    margin-right: 10px; }
  .cr-jock-console__calendar-card-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 17px 0 18px;
    height: 100%;
    position: absolute;
    right: 0;
    width: calc(100% - 10px);
    background-color: #fff;
    transition: left ease-out 0.2s;
    border-radius: 6px;
    cursor: pointer; }
  .cr-jock-console__calendar-card-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 215px; }
  .cr-jock-console__calendar-card-subtitle {
    color: #657894;
    font-size: 14px;
    line-height: 18px; }
  .cr-jock-console__calendar-card-text {
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #795afa;
    text-transform: uppercase;
    display: block; }
  .cr-jock-console__calendar-card-time {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #537a91;
    display: block; }
  .cr-jock-console__calendar-card-action {
    background: #795afa;
    border-radius: 4px 0px 0px 4px;
    height: 100%;
    width: 10px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-jock-console__calendar-card-open .cr-jock-console__calendar-card-action {
    width: 47px; }
  .cr-jock-console__calendar-card-open .cr-jock-console__calendar-card-content {
    width: 303px;
    right: -46px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .cr-jock-console__calendar-card-past .cr-jock-console__calendar-card-action {
    background-color: #bccedb; }
  .cr-jock-console__calendar-card-past .cr-jock-console__calendar-card-text,
  .cr-jock-console__calendar-card-past .cr-jock-console__calendar-card-time {
    color: #537a91; }
  .cr-jock-console__calendar-card-action-contest .cr-jock-console__calendar-card-action {
    background-color: #73a703; }
  .cr-jock-console__calendar-card-action-contest .cr-jock-console__calendar-card-text {
    color: #73a703; }
  .cr-jock-console__calendar-hour {
    width: 305px;
    margin: 0 auto; }
  .cr-jock-console__calendar-hour-text {
    font-size: 16px;
    line-height: 20px;
    color: #537a91; }
  .cr-jock-console__calendar-hour-floating-text {
    text-transform: uppercase; }
  .cr-jock-console__calendar-hour-total-tasks {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #0f5589;
    cursor: pointer; }
  .cr-jock-console__triangle {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    position: absolute;
    right: 20px;
    top: 5px;
    cursor: pointer; }
  .cr-jock-console__triangle-up {
    border-bottom: 13px solid #95a7ae; }
  .cr-jock-console__triangle-down {
    border-top: 13px solid #95a7ae; }
  .cr-jock-console__calendar-hour-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding-right: 40px;
    padding-left: 20px;
    margin-bottom: 11px;
    height: 23px; }
  .cr-jock-console__calendar-hour-done {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #334856;
    text-transform: uppercase; }
  .cr-jock-console__calendar-hour-incomplete-text {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #e40061;
    text-transform: uppercase; }
  .cr-jock-console__calendar-hour-incomplete .cr-jock-console__calendar-hour-total-tasks {
    color: #e40061; }
  .cr-jock-console__calendar-inline {
    width: 305px;
    text-align: center;
    margin: 0 auto; }
  .cr-jock-console__calendar-inline-time {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #000000; }
  .cr-jock-console__calendar-slide {
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-jock-console__calendar-slide-content {
    margin: 0 33px;
    position: relative;
    width: 215px; }
  .cr-jock-console__calendar-date {
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #657894;
    margin-left: 15px; }
  .cr-jock-console__calendar-arrow-left {
    margin-top: 2px;
    cursor: pointer; }
  .cr-jock-console__calendar-arrow-right {
    margin-top: 2px;
    cursor: pointer; }
  .cr-jock-console__datepicker {
    position: absolute;
    z-index: 9;
    left: -70px; }
  .cr-jock-console__calendar-slide-trigger {
    cursor: pointer; }
  .cr-jock-console__storm-closings-head-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 20px;
    text-transform: capitalize; }
  .cr-jock-console__no-widgets {
    padding-left: 24px;
    padding-right: 23px;
    margin-top: 25px; }
  .cr-jock-console__no-widgets-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #336abb;
    display: block;
    margin-bottom: 15px; }
  .cr-jock-console__no-widgets-description {
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #334856;
    display: block; }
  .cr-jock-console__load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
    height: 43px; }
  .cr-jock-console__load-more-button {
    cursor: pointer; }
    .cr-jock-console__load-more-button span {
      font-size: 12px;
      line-height: 17px;
      text-transform: uppercase;
      color: #829fb1;
      margin-bottom: 5px;
      display: block;
      text-align: center;
      margin-bottom: 9px; }
      .cr-jock-console__load-more-button span:last-child {
        margin-bottom: 0; }
  .cr-jock-console__other-links-head-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 20px;
    text-transform: capitalize; }
  .cr-jock-console__link-name {
    font-size: 14px;
    line-height: 19px;
    color: #43535c;
    font-weight: bold;
    white-space: nowrap; }
  .cr-jock-console__link-url {
    font-size: 13px;
    line-height: 16px;
    color: #795afa;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .cr-jock-console__link-item {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 10px; }
  .cr-jock-console__text-editor {
    padding-bottom: 40px;
    position: relative; }
    .cr-jock-console__text-editor .scroll-bar-style {
      overflow: auto; }
  .cr-jock-console__text-editor-title {
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    margin-bottom: 20px; }
  .cr-jock-console__text-editor-content {
    font-size: 13px;
    line-height: 16px;
    color: #334856;
    max-height: 528px;
    padding-right: 18px; }
    .cr-jock-console__text-editor-content .cr-textarea-evaluator__wrapper-top-length {
      display: none; }
    .cr-jock-console__text-editor-content .cr-textarea-evaluator__container {
      padding-top: 0; }
  .cr-jock-console__rss-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #4324c7; }
  .cr-jock-console__rss-date {
    font-size: 14px;
    font-style: italic;
    color: #657894;
    max-width: 5px 0;
    word-break: break-word; }
  .cr-jock-console__rss-subtitle {
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: #657894;
    word-break: break-word; }
  .cr-jock-console__station-logo-img {
    margin-right: 37px;
    max-width: 192px;
    min-width: 192px;
    width: 192px; }
  .cr-jock-console__station-logo {
    background-color: #eff3f6;
    width: 192px;
    min-width: 192px;
    max-width: 192px;
    height: 162px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 15px;
    text-align: center;
    justify-content: center; }
  .cr-jock-console__station-name {
    color: #000000;
    font-weight: bold;
    font-size: 30px;
    line-height: 38px;
    overflow-wrap: break-word;
    word-break: break-all; }
  .cr-jock-console__left-line {
    height: 0.75px;
    width: 305px;
    background-color: #c2d4e0;
    margin: 0 auto; }
  .cr-jock-console__calendar-floating-tasks {
    margin-top: 13px;
    width: 100%; }
    .cr-jock-console__calendar-floating-tasks.floating-bottom {
      position: absolute;
      bottom: 10px;
      background: #eff6fa;
      z-index: 9;
      margin-top: 0; }
      .cr-jock-console__calendar-floating-tasks.floating-bottom .cr-jock-console__left-line {
        display: none; }
      .cr-jock-console__calendar-floating-tasks.floating-bottom::before {
        content: "";
        height: 34px;
        display: block;
        background: linear-gradient(180deg, rgba(15, 85, 137, 0) 0%, rgba(15, 85, 137, 0.15) 100%);
        position: absolute;
        width: 100%;
        top: -34px; }
      .cr-jock-console__calendar-floating-tasks.floating-bottom .cr-jock-console__calendar-hour-head {
        padding-top: 15px;
        height: inherit; }
        .cr-jock-console__calendar-floating-tasks.floating-bottom .cr-jock-console__calendar-hour-head .cr-jock-console__triangle {
          top: 20px; }
      .cr-jock-console__calendar-floating-tasks.floating-bottom.open {
        position: absolute;
        bottom: 0;
        z-index: 9; }
        .cr-jock-console__calendar-floating-tasks.floating-bottom.open .cr-jock-console__left-line {
          display: none; }
    .cr-jock-console__calendar-floating-tasks.open {
      background: #eff6fa; }
      .cr-jock-console__calendar-floating-tasks.open::before {
        content: "";
        height: 34px;
        display: block;
        background: linear-gradient(180deg, rgba(15, 85, 137, 0) 0%, rgba(15, 85, 137, 0.15) 100%);
        position: absolute;
        top: -34px;
        width: 100%; }
    .cr-jock-console__calendar-floating-tasks .cr-jock-console__calendar-hour-head {
      width: 305px;
      margin: 0 auto;
      padding-top: 19px;
      height: inherit;
      padding-bottom: 11px; }
      .cr-jock-console__calendar-floating-tasks .cr-jock-console__calendar-hour-head .cr-jock-console__triangle {
        top: 20px; }
    .cr-jock-console__calendar-floating-tasks .cr-jock-console__calendar-hour-open {
      height: 370px;
      overflow-y: auto;
      overflow-x: hidden; }
  .cr-jock-console__calendar-card-content-center {
    margin-right: 5px; }
  @media screen and (min-width: 1200px) and (max-width: 1436px) {
    .cr-jock-console .cr-jock-console-left-sidebar {
      width: 350px;
      min-width: 350px; }
    .cr-jock-console .cr-jock-console-right-sidebar {
      width: 350px; }
    .cr-jock-console .cr-jock-console__jock-tag {
      max-width: 74px; }
    .cr-jock-console .cr-jock-console-content {
      width: 100%; }
    .cr-jock-console__calendar-card {
      max-width: 100%; }
    .cr-jock-console__calendar-hour {
      max-width: 90%; }
    .cr-jock-console__calendar-card-open .cr-jock-console__calendar-card-action {
      width: 30px; }
    .cr-jock-console__calendar-card-open .cr-jock-console__calendar-card-content {
      width: 297px;
      right: -22px; }
    .cr-jock-console__no-widgets {
      padding-left: 30px;
      padding-right: 31px; }
    .cr-jock-console__calendar-card-title {
      max-width: 190px; } }

.cr-jock-console-winner-modal {
  max-width: 1131px; }
  .cr-jock-console-winner-modal .modal-content {
    border-radius: 20px;
    overflow: hidden; }
  .cr-jock-console-winner-modal .modal-header {
    background: #73a703;
    height: 88px;
    display: flex;
    align-items: center;
    padding-left: 56px; }
  .cr-jock-console-winner-modal .modal-body {
    padding: 0; }
  .cr-jock-console-winner-modal__close {
    position: absolute;
    right: 18px;
    top: 13px;
    cursor: pointer; }
  .cr-jock-console-winner-modal__title {
    font-weight: 800;
    font-size: 26px;
    line-height: 40px;
    color: #ffffff;
    margin-bottom: 0; }
  .cr-jock-console-winner-modal__search-header {
    background: #e9f1f8;
    padding: 46px 55px 38px 55px; }
  .cr-jock-console-winner-modal .add-winners-list {
    border: none;
    max-height: 300px;
    border-bottom: 1px solid rgba(194, 212, 224, 0.5);
    margin-bottom: 25px; }
    .cr-jock-console-winner-modal .add-winners-list .selected-winner {
      box-shadow: 0px 0px 20px rgba(51, 72, 86, 0.15), 0px 0px 20px rgba(51, 72, 86, 0.15); }
    .cr-jock-console-winner-modal .add-winners-list .rt-tr-group {
      border: none !important;
      height: 50px; }
    .cr-jock-console-winner-modal .add-winners-list .col-content {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%; }
    .cr-jock-console-winner-modal .add-winners-list .rt-thead {
      padding: 20px 0; }
    .cr-jock-console-winner-modal .add-winners-list .rt-th {
      border: none;
      text-align: left;
      color: #537a91;
      text-transform: uppercase;
      font-size: 13px; }
    .cr-jock-console-winner-modal .add-winners-list .rt-td {
      border: none !important; }
    .cr-jock-console-winner-modal .add-winners-list .rt-tr {
      padding: 0 55px;
      font-size: 16px;
      color: #000000; }
    .cr-jock-console-winner-modal .add-winners-list .highlighted {
      color: #795afa !important;
      font-weight: 800;
      cursor: pointer; }
  .cr-jock-console-winner-modal__search-input {
    position: relative; }
    .cr-jock-console-winner-modal__search-input .cr-textinput__input-wrapper {
      background-color: #fff;
      border-radius: 25px; }
      .cr-jock-console-winner-modal__search-input .cr-textinput__input-wrapper input {
        border-radius: 25px;
        padding: 0 30px;
        padding-right: 55px; }
        .cr-jock-console-winner-modal__search-input .cr-textinput__input-wrapper input::-webkit-input-placeholder {
          font-weight: bold;
          font-size: 16px;
          color: #000000; }
        .cr-jock-console-winner-modal__search-input .cr-textinput__input-wrapper input::placeholder {
          font-weight: bold;
          font-size: 16px;
          color: #000000; }
  .cr-jock-console-winner-modal__search-icon {
    position: absolute;
    top: 12px;
    right: 28px;
    cursor: pointer; }
  .cr-jock-console-winner-modal__search-help {
    height: 230px;
    padding-top: 61px;
    padding-left: 81px;
    padding-bottom: 81px;
    background-color: #fff; }
  .cr-jock-console-winner-modal__search-help-text {
    font-size: 26px;
    line-height: 40px;
    font-weight: 800;
    color: #537a91;
    max-width: 395px;
    position: relative; }
  .cr-jock-console-winner-modal__search-help-icon {
    position: absolute;
    top: -15px;
    right: -60px; }
  .cr-jock-console-winner-modal__search-none-text {
    font-size: 26px;
    line-height: 40px;
    font-weight: 800;
    color: #537a91;
    margin-bottom: 30px; }
  .cr-jock-console-winner-modal__search-none {
    padding-top: 40px;
    padding-bottom: 97px;
    text-align: center; }
    .cr-jock-console-winner-modal__search-none.winner-has-data {
      padding-top: 0;
      padding-bottom: 32px; }
    .cr-jock-console-winner-modal__search-none .btn {
      margin: 0 7.5px; }
  .cr-jock-console-winner-modal__add-new-text {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #000000; }
  .cr-jock-console-winner-modal .eligibility-box {
    padding: 8px;
    border: 1px solid rgba(194, 212, 224, 0.57);
    border-radius: 4px;
    align-items: center;
    justify-content: center; }
    .cr-jock-console-winner-modal .eligibility-box span {
      font-weight: 800;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      text-transform: uppercase;
      color: #c2d4e0;
      opacity: 0.6; }
    .cr-jock-console-winner-modal .eligibility-box.highlighted {
      color: #795afa;
      opacity: 1; }
      .cr-jock-console-winner-modal .eligibility-box.highlighted span {
        color: #795afa;
        opacity: 1; }
  .cr-jock-console-winner-modal .eligibility-status {
    text-transform: uppercase;
    color: #e40061; }
  .cr-jock-console-winner-modal .eligibility-notice {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 16px;
    font-weight: 700;
    font-size: 18px; }
    .cr-jock-console-winner-modal .eligibility-notice span {
      margin-left: 4px; }
    .cr-jock-console-winner-modal .eligibility-notice.eligible .eligibility-status {
      color: #73a703; }
  .cr-jock-console-winner-modal__winner-form {
    padding-top: 40px;
    padding-bottom: 73px;
    padding-left: 55px;
    padding-right: 65px; }
    .cr-jock-console-winner-modal__winner-form label {
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #537a91;
      display: block;
      text-transform: uppercase;
      white-space: nowrap; }
    .cr-jock-console-winner-modal__winner-form .btn {
      margin: 0 7.5px; }
  .cr-jock-console-winner-modal__content-form {
    margin-bottom: 47px; }
    .cr-jock-console-winner-modal__content-form .checkbox {
      justify-content: flex-end;
      display: flex;
      margin-top: 35px; }
  .cr-jock-console-winner-modal__form-buttons .form-group {
    text-align: center; }
  .cr-jock-console-winner-modal .cr-form-builder-fields .cr-textinput__input-field input {
    text-transform: inherit; }

.cr-note-no-winner-modal {
  max-width: 60%; }
  .cr-note-no-winner-modal .modal-content {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    overflow: hidden; }
  .cr-note-no-winner-modal .modal-header {
    background-color: #224b8b;
    color: white;
    border-bottom: none;
    padding-left: 48px; }
    .cr-note-no-winner-modal .modal-header .modal-title {
      font-weight: 800; }
    .cr-note-no-winner-modal .modal-header .close {
      color: white; }
  .cr-note-no-winner-modal .modal-body {
    padding: 10px 55px; }
    .cr-note-no-winner-modal .modal-body textarea {
      width: 100%;
      min-height: 160px;
      border: 1px solid #c2d4e0;
      border-radius: 4px;
      padding: 10px;
      outline: none;
      box-shadow: none; }
  .cr-note-no-winner-modal__buttons .form-group {
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center; }
    .cr-note-no-winner-modal__buttons .form-group button + button {
      margin-left: 15px; }
  .cr-note-no-winner-modal .card-body {
    overflow: hidden;
    padding: 24px 48px; }
  .cr-note-no-winner-modal__button-cancel {
    background: #ffffff;
    border: 1px solid #acc4d4;
    border-radius: 24px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #795afa; }
  .cr-note-no-winner-modal__description-text {
    color: #000;
    font-weight: 600;
    margin-bottom: 10px;
    display: block; }

.cr-jock-console-manager {
  padding-top: 30px;
  padding-bottom: 50px; }
  .cr-jock-console-manager__jock-manager-head {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 34px; }
  .cr-jock-console-manager__right-option {
    display: flex;
    align-items: center; }
    .cr-jock-console-manager__right-option label {
      display: none; }
    .cr-jock-console-manager__right-option .react-select-container {
      width: 308px;
      margin-right: 19px; }
  .cr-jock-console-manager__jock-manager-description {
    font-size: 18px;
    line-height: 23px;
    color: #334856;
    max-width: 700px; }
  .cr-jock-console-manager__activated-text {
    text-align: right;
    margin-bottom: 13px; }
    .cr-jock-console-manager__activated-text span {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #334856;
      text-transform: capitalize; }
  .cr-jock-console-manager__button-create {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 31px;
    width: 298px !important;
    height: 52px; }
    .cr-jock-console-manager__button-create span {
      font-weight: 800;
      font-size: 19px;
      text-align: center;
      color: #334856;
      margin-left: 20px;
      text-transform: initial; }

.cr-jock-console-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px; }
  .cr-jock-console-item--none-activated .cr-jock-console-item__jock-console-title-text {
    color: #829fb1; }
  .cr-jock-console-item--activated .cr-jock-console-item__jock-console-title-text {
    color: #334856; }
  .cr-jock-console-item__jock-console-title {
    display: flex;
    align-items: center; }
  .cr-jock-console-item__jock-console-title-text {
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #829fb1;
    text-transform: capitalize; }
  .cr-jock-console-item__jock-console-color {
    background: #336abb;
    border-radius: 3px;
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: 11px; }
  .cr-jock-console-item__button-down {
    border: none;
    background-color: transparent; }
    .cr-jock-console-item__button-down:active, .cr-jock-console-item__button-down:focus {
      outline: none; }
  .cr-jock-console-item__jock-console-main {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px;
    flex: 1 1; }
  .cr-jock-console-item__jock-console-head-left {
    display: flex;
    align-items: center; }
  .cr-jock-console-item__jock-console-head-right {
    margin-right: 55px; }
  .cr-jock-console-item__jock-console-head {
    display: flex;
    align-items: center;
    height: 89px;
    width: 100%;
    padding-left: 22px;
    position: relative;
    padding-right: 59px;
    justify-content: space-between; }
  .cr-jock-console-item .menu-selection-icon {
    margin-right: 20px;
    cursor: move; }
  .cr-jock-console-item__button-down {
    position: absolute;
    right: 59px;
    cursor: pointer; }
  .cr-jock-console-item__switch-action {
    width: 90px;
    justify-content: flex-end;
    display: flex;
    margin-top: 25px; }
  .cr-jock-console-item__form-buttons {
    border-top: 1px solid #edf1f6;
    padding-left: 46px;
    padding-top: 37px;
    padding-bottom: 50px;
    padding-right: 49px; }
    .cr-jock-console-item__form-buttons .btn-blue {
      width: 195px;
      margin-right: 14px; }
  .cr-jock-console-item__form-padding {
    padding-left: 49px;
    padding-right: 49px; }
  .cr-jock-console-item__content-form label {
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #43535c;
    display: block; }
  .cr-jock-console-item .react-select__placeholder {
    color: #829fb1 !important; }
  .cr-jock-console-item__form-top {
    padding-top: 44px;
    padding-bottom: 47px;
    border-bottom: 1px solid #edf1f6; }
  .cr-jock-console-item__remove-icon {
    position: absolute;
    right: -40px;
    top: 11px;
    cursor: pointer; }
  .cr-jock-console-item__remove-icon-first {
    top: 39px; }
  .cr-jock-console-item__col-type-color {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
  .cr-jock-console-item__group-type {
    margin-right: 21px;
    flex: 1 1; }
    .cr-jock-console-item__group-type .cr-jock-console-item__jock-console-view-field-value {
      text-transform: capitalize; }
  .cr-jock-console-item__view-rows {
    padding-top: 53px;
    padding-right: 50px; }
  .cr-jock-console-item__add-row-button {
    margin-bottom: 45px;
    margin-top: 10px; }
    .cr-jock-console-item__add-row-button .btn-add-row span {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      margin-left: 14px;
      text-transform: initial; }
  .cr-jock-console-item__col-row-delete {
    position: relative; }
  .cr-jock-console-item__social-row {
    position: relative; }
    .cr-jock-console-item__social-row .form-group {
      padding-left: 50px;
      position: relative; }
  .cr-jock-console-item__social-icon {
    position: absolute;
    left: 0;
    top: 15px; }
  .cr-jock-console-item__remove-link-icon {
    position: absolute;
    top: 14px;
    right: 22px;
    cursor: pointer; }
  .cr-jock-console-item__social-row-first .cr-jock-console-item__social-icon {
    top: 37px; }
  .cr-jock-console-item__social-row-first .cr-jock-console-item__remove-link-icon {
    top: 40px; }
  .cr-jock-console-item__social-media {
    padding-top: 54px; }
    .cr-jock-console-item__social-media .text-danger {
      margin-bottom: 20px; }
  .cr-jock-console-item .btn-delete {
    float: right; }
  .cr-jock-console-item__rss-feed-url {
    padding-top: 42px;
    padding-bottom: 46px; }
  .cr-jock-console-item__other-links {
    padding-top: 53px; }
  .cr-jock-console-item__form-type-text_editor .cr-jock-console-item__form-top {
    padding-bottom: 0;
    border-bottom: 0; }
  .cr-jock-console-item__text-editor {
    padding-top: 5px; }
    .cr-jock-console-item__text-editor .cr-textarea-evaluator__container {
      padding-top: 0;
      padding-bottom: 0; }
    .cr-jock-console-item__text-editor .cr-textarea-evaluator__wrapper-top {
      display: none; }
    .cr-jock-console-item__text-editor .form-group {
      margin-bottom: 54px; }
    .cr-jock-console-item__text-editor .public-DraftEditor-content {
      min-height: 178px; }
  .cr-jock-console-item__form-type-bulletin_board_replacement .cr-jock-console-item__form-center {
    padding: 0; }
  .cr-jock-console-item__form-type-bulletin_board .cr-jock-console-item__form-top {
    padding-bottom: 6px;
    border-bottom: none; }
  .cr-jock-console-item__jock-console-details {
    border-top: 1px solid #edf1f6;
    display: none; }
  .cr-jock-console-item__jock-console-details-opened {
    display: block; }
  .cr-jock-console-item__bulletin-board-dates {
    padding-top: 53px;
    padding-left: 49px;
    padding-right: 47px;
    padding-bottom: 20px;
    border-bottom: 1px solid #edf1f6; }
  .cr-jock-console-item__bulletin-board-replacement-content {
    padding-top: 49px;
    padding-bottom: 11px; }
    .cr-jock-console-item__bulletin-board-replacement-content .public-DraftEditor-content {
      min-height: 178px; }
  .cr-jock-console-item__replacement {
    font-size: 14px;
    color: #795afa;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-jock-console-item__replacement-icon {
    margin-right: 18px; }
  .cr-jock-console-item__replacement-dates {
    margin-left: 5px; }
  .cr-jock-console-item__remove-icon-rss {
    position: absolute;
    right: 20px;
    top: 40px;
    cursor: pointer; }
  .cr-jock-console-item__rss-feed-input {
    position: relative; }
  .cr-jock-console-item__phone-numbers {
    padding-top: 53px; }
  .cr-jock-console-item__content-view label {
    color: #657894;
    text-transform: uppercase;
    display: block;
    margin-bottom: 18px; }
  .cr-jock-console-item__content-view .cr-jock-console-item__social-media {
    margin-bottom: 30px; }
  .cr-jock-console-item__content-view .cr-jock-console-item__social-row-first .cr-jock-console-item__social-icon {
    top: 48px; }
  .cr-jock-console-item__content-view .public-DraftEditor-content {
    min-height: auto; }
  .cr-jock-console-item__jock-console-view-field-value {
    font-size: 16px;
    line-height: 20px;
    color: #000000; }
  .cr-jock-console-item__social-media .cr-jock-console-item__jock-console-view-field-value {
    min-height: 50px;
    display: flex;
    align-items: center; }

.cr-title-tooltip__title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.cr-title-tooltip__tooltip {
  opacity: 1; }
  .cr-title-tooltip__tooltip .rc-tooltip-inner {
    background-color: #fff;
    padding-bottom: 15px; }
  .cr-title-tooltip__tooltip .rc-tooltip-arrow {
    border-color: transparent; }
    .cr-title-tooltip__tooltip .rc-tooltip-arrow:after {
      content: "";
      position: absolute;
      width: 11px;
      height: 11px;
      background: #fff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      top: -10px;
      left: -5px; }
    .cr-title-tooltip__tooltip .rc-tooltip-arrow:before {
      content: "";
      display: block;
      width: 30px;
      height: 11px;
      background: #fff;
      position: absolute;
      z-index: 9;
      top: -16px;
      left: -15px; }

.cr-title-tooltip__tooltip-content {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  font-weight: bold; }

.cr-dropdown-length {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  .cr-dropdown-length__dropdown {
    flex: 1 1;
    margin-right: 30px; }
    .cr-dropdown-length__dropdown .cr-textinput {
      max-width: 100% !important; }
  .cr-dropdown-length__checkbox {
    margin-top: 37px; }
  .cr-dropdown-length .cr-textinput {
    max-width: 70px; }
  .cr-dropdown-length__divider {
    font-size: 24px;
    line-height: 30px;
    color: #334856;
    margin: 0 10px; }
  .cr-dropdown-length__inputs {
    display: flex;
    align-items: center; }

.modal.show .cr-jock-console-multi-view-modal {
  -webkit-transform: translate(0, 0) !important;
          transform: translate(0, 0) !important; }

.cr-jock-console-multi-view-modal {
  max-width: 1575px;
  min-height: 90%;
  display: flex;
  flex-direction: column;
  -webkit-transform: translate(100%, 0) !important;
          transform: translate(100%, 0) !important;
  position: absolute;
  right: 0;
  width: 90vw; }
  .cr-jock-console-multi-view-modal .modal-content {
    flex: 1 1; }
  .cr-jock-console-multi-view-modal .modal-body {
    padding-top: 40px;
    padding-bottom: 56px;
    padding-left: 24px;
    padding-right: 30px;
    display: flex;
    flex-direction: column; }
  .cr-jock-console-multi-view-modal__station-info strong {
    color: #000000;
    font-size: 21px;
    display: block; }
  .cr-jock-console-multi-view-modal__station-info span {
    font-size: 14px;
    line-height: 18px;
    color: #7b8693;
    display: block; }
  .cr-jock-console-multi-view-modal__text-small {
    font-size: 14px;
    color: #7b8693;
    max-width: 140px; }
  .cr-jock-console-multi-view-modal__station-center {
    display: inline-flex;
    align-items: center;
    justify-content: center; }
  .cr-jock-console-multi-view-modal .cr-jock-console__station-logo,
  .cr-jock-console-multi-view-modal .cr-jock-console__station-logo-img {
    margin: 0 32px; }
  .cr-jock-console-multi-view-modal__station-center {
    display: inline-flex;
    align-items: center;
    justify-content: center; }
  .cr-jock-console-multi-view-modal__multiple-head {
    text-align: center; }
  .cr-jock-console-multi-view-modal__btn-close {
    display: block;
    position: absolute;
    cursor: pointer;
    top: 23px; }
    .cr-jock-console-multi-view-modal__btn-close svg {
      width: 14px; }
  .cr-jock-console-multi-view-modal__widgets {
    display: flex;
    flex-direction: row;
    margin-top: 38px;
    flex: 1 1; }
    .cr-jock-console-multi-view-modal__widgets .cr-jock-console__widget-detail-padding {
      margin-left: 0;
      margin-right: 0;
      overflow: auto; }
  .cr-jock-console-multi-view-modal__widget {
    width: 25%;
    padding: 0 4px;
    max-width: 380px;
    display: flex;
    flex-direction: column; }
    .cr-jock-console-multi-view-modal__widget .cr-jock-console__scroll-content {
      top: 57px;
      height: calc(100% - 100px); }

.cr-winner-details {
  margin-top: 35px;
  margin-bottom: 55px;
  max-width: 1010px; }
  .cr-winner-details .nav-link {
    font-size: 16px !important;
    line-height: 20px;
    color: #657894;
    width: 149px;
    height: 50px;
    justify-content: center;
    border: none;
    text-transform: capitalize; }
    .cr-winner-details .nav-link.active {
      color: #657894 !important;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
      border-radius: 6px 6px 0 0; }
  .cr-winner-details .tab-content {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    padding-left: 35px;
    padding-right: 38px; }
    .cr-winner-details .tab-content::before {
      content: "";
      height: 7px;
      width: 100%;
      background-color: #fff;
      display: block;
      position: absolute;
      top: 1px;
      z-index: 9;
      left: 0;
      right: 0; }
  .cr-winner-details__tab-content-view {
    padding-top: 51px; }
  .cr-winner-details__tab-content-edit {
    padding-top: 47px; }
  .cr-winner-details__active-first-tab-content {
    overflow: initial !important; }
  .cr-winner-details__col-reset {
    margin-bottom: 0 !important; }
  .cr-winner-details__participant-view {
    margin-bottom: 17px; }
  .cr-winner-details__participant-head {
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 28px; }
  .cr-winner-details__participant-name {
    line-height: 27px;
    color: #0f5589; }
  .cr-winner-details__participant-sex {
    color: #795afa;
    margin: 0 10px; }
  .cr-winner-details__participant-edit {
    cursor: pointer;
    margin-left: 10px; }
  .cr-winner-details__info-item {
    margin-bottom: 0; }
    .cr-winner-details__info-item label {
      font-weight: bold;
      line-height: 28px;
      color: #333333;
      font-size: 16px;
      text-transform: capitalize;
      margin-right: 10px;
      margin-bottom: 0; }
  .cr-winner-details__info-item-value {
    color: #829fb1; }
  .cr-winner-details__total-winnings {
    text-transform: capitalize;
    display: flex;
    align-items: flex-end;
    flex-direction: column; }
  .cr-winner-details__winnings-label {
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #333333;
    font-weight: bold; }
  .cr-winner-details__winnings-value {
    font-size: 16px;
    line-height: 24px;
    color: #537a91;
    margin-left: 5px; }
  .cr-winner-details__winnings-total {
    font-weight: bold;
    font-size: 23px;
    color: #333333; }
  .cr-winner-details__participant-right-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
  .cr-winner-details__participant-right-info-contact > div {
    align-items: flex-start; }
  .cr-winner-details__participant-right-item {
    margin-bottom: 7px; }
  .cr-winner-details__participant-approval {
    margin-top: 20px;
    display: flex; }
    .cr-winner-details__participant-approval span {
      color: #537a91;
      margin-left: 14px; }
  .cr-winner-details__content-form label {
    color: #43535c;
    text-transform: uppercase;
    display: block; }
  .cr-winner-details__content-form .form-group {
    margin-bottom: 29px; }
  .cr-winner-details__form-buttons {
    margin-bottom: 35px; }
    .cr-winner-details__form-buttons .btn-blue {
      margin-right: 14px; }
  .cr-winner-details__contests {
    margin-top: 29px; }
  .cr-winner-details__contest-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #0f5589;
    margin-bottom: 10px;
    margin-left: 36px;
    text-transform: capitalize; }
  .cr-winner-details__contest-container {
    margin-bottom: 27px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px; }
  .cr-winner-details__contest-past-container .cr-winner-details__contest-type span {
    color: rgba(194, 212, 224, 0.6) !important; }
  .cr-winner-details__contest-item-title {
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856;
    margin-bottom: 0;
    margin-left: 28px; }
  .cr-winner-details__contest-station {
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856; }
  .cr-winner-details__contest-type {
    width: 54px;
    height: 51px;
    background: #edf3f7;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .cr-winner-details__contest-type > div {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      align-items: center;
      justify-content: center; }
  .cr-winner-details__contest-head {
    display: flex;
    align-items: center;
    height: 88px;
    padding-left: 26px;
    padding-right: 175px;
    position: relative; }
  .cr-winner-details__contest-hour {
    color: #537a91;
    font-size: 12px;
    text-transform: uppercase; }
  .cr-winner-details__contest-date {
    color: #795afa;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase; }
  .cr-winner-details__contest-time {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 30px; }
  .cr-winner-details__contest-head-right {
    position: absolute;
    right: 38px;
    display: flex;
    align-items: center; }
  .cr-winner-details__action {
    cursor: pointer;
    display: inline-block; }
  .cr-winner-details__contest-head-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1; }
  .cr-winner-details__contest-type-name {
    font-weight: 800;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    color: #795afa; }
  .cr-winner-details__contest-type-text {
    font-size: 7px;
    line-height: 9px;
    text-align: center;
    color: #795afa; }
  .cr-winner-details__contest-expired {
    font-weight: 800;
    font-size: 16px;
    line-height: 40px;
    text-align: right;
    color: rgba(194, 212, 224, 0.6);
    text-transform: uppercase;
    margin-right: 30px; }
  .cr-winner-details__contest-section-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 14px;
    height: 32px;
    text-transform: capitalize; }
  .cr-winner-details__contest-content {
    padding-left: 36px;
    padding-right: 38px;
    padding-top: 30px;
    padding-bottom: 38px;
    border-top: 1px solid #edf1f6; }
  .cr-winner-details__contest-section-box {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px;
    padding: 33px 46px 40px 46px;
    margin-bottom: 15px; }
    .cr-winner-details__contest-section-box:last-child {
      margin-bottom: 0; }
  .cr-winner-details__contest-group {
    margin-bottom: 32px; }
    .cr-winner-details__contest-group label {
      letter-spacing: 0.05em;
      color: #829fb1;
      margin-bottom: 12px;
      text-transform: uppercase; }
  .cr-winner-details__contest-group-last {
    margin-bottom: 0; }
  .cr-winner-details__contest-group-value {
    font-size: 16px;
    line-height: 20px;
    color: #111b2b; }
  .cr-winner-details__contest-details-text {
    font-size: 18px;
    line-height: 22px;
    color: #333333; }
  .cr-winner-details__contest-table-text {
    font-size: 16px;
    line-height: 20px;
    color: #000000; }
  .cr-winner-details__contest-table-status {
    display: flex; }
  .cr-winner-details__contest-table.table {
    border-collapse: collapse;
    background: #f7fbfd;
    box-sizing: border-box;
    border-radius: 6px;
    border-style: hidden;
    box-shadow: 0 0 0 1px #c2d4e0;
    overflow: hidden;
    margin-bottom: 0; }
    .cr-winner-details__contest-table.table th {
      border-bottom: 1px solid #c2d4e0; }
      .cr-winner-details__contest-table.table th span {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.05em;
        color: #829fb1;
        text-transform: uppercase; }
    .cr-winner-details__contest-table.table thead tr th:first-child span {
      padding-left: 10px; }
    .cr-winner-details__contest-table.table tbody {
      background-color: #fff; }
      .cr-winner-details__contest-table.table tbody tr td {
        padding-top: 18px;
        padding-bottom: 18px; }
        .cr-winner-details__contest-table.table tbody tr td:first-child .cr-winner-details__contest-table-text {
          padding-left: 10px; }
  .cr-winner-details__contest-table-status .cr-winner-details__contest-table-text {
    margin-left: 4px; }
  .cr-winner-details__contest-table-actions span {
    margin: 0 10px;
    cursor: pointer; }
  .cr-winner-details__text-center {
    text-align: center; }
  .cr-winner-details__contest-section-details {
    margin-bottom: 39px; }
  .cr-winner-details__prize-value {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #000000; }
  .cr-winner-details__contest-prize-text {
    font-size: 16px;
    line-height: 22px;
    color: #111b2b; }
  .cr-winner-details__time-text-at {
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #657894;
    margin-bottom: 5px; }
  .cr-winner-details__btn-undo {
    font-size: 12px;
    line-height: 15px;
    color: #795afa;
    margin-top: 5px;
    display: inline-block;
    cursor: pointer;
    text-transform: uppercase; }
  .cr-winner-details .btn-fulfilled {
    background: #73a703;
    border: transparent; }
    .cr-winner-details .btn-fulfilled:focus {
      box-shadow: none; }
  .cr-winner-details .btn-unfulfilled-danger span {
    color: #537a91; }
  .cr-winner-details__contest-button {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column; }
    .cr-winner-details__contest-button .btn {
      display: flex;
      align-items: center;
      justify-content: center; }
      .cr-winner-details__contest-button .btn span {
        margin-left: 7px;
        font-size: 14px;
        text-transform: uppercase; }
  .cr-winner-details__contest-button-action {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
  .cr-winner-details__prize-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #000000; }
  .cr-winner-details__prize-title-value {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #829fb1; }
  .cr-winner-details__fullfilment-table {
    margin-top: 24px; }
    .cr-winner-details__fullfilment-table.table {
      border-collapse: collapse;
      background: #f7fbfd;
      box-sizing: border-box;
      border-radius: 6px;
      border-style: hidden;
      box-shadow: 0 0 0 1px #c2d4e0;
      overflow: hidden;
      margin-bottom: 0; }
      .cr-winner-details__fullfilment-table.table th {
        padding-top: 17px;
        padding-bottom: 17px;
        border-bottom: 1px solid #c2d4e0; }
        .cr-winner-details__fullfilment-table.table th span {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.05em;
          color: #829fb1;
          text-transform: uppercase; }
      .cr-winner-details__fullfilment-table.table th,
      .cr-winner-details__fullfilment-table.table td {
        padding: 0.75rem 2rem; }
      .cr-winner-details__fullfilment-table.table tbody {
        background-color: #fff; }
  .cr-winner-details__fullfilment-group {
    margin-bottom: 13px; }
    .cr-winner-details__fullfilment-group label {
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #829fb1;
      display: block;
      text-transform: uppercase; }
  .cr-winner-details__fullfilment-value {
    font-size: 16px;
    line-height: 22px;
    color: #111b2b; }
  .cr-winner-details__text-location {
    text-transform: capitalize; }
  .cr-winner-details__contact-activity-box {
    margin-top: 28px; }
  .cr-winner-details .cr-winner-details__section-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    padding-left: 35px;
    color: #000000;
    height: inherit;
    padding-top: 33px;
    padding-bottom: 0;
    border-bottom: none; }
  .cr-winner-details__contact-activity-items {
    border: 1px solid rgba(0, 0, 0, 0.1); }
  .cr-winner-details__contact-activity-fields {
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 52px;
    margin-top: 0; }
  .cr-winner-details__activity-year {
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    padding: 0 27px;
    line-height: 40px;
    display: flex;
    align-items: center; }
  .cr-winner-details__activity-item {
    padding: 0 27px;
    height: 42px;
    display: flex;
    align-items: center; }
    .cr-winner-details__activity-item:nth-child(2n + 1) {
      background-color: #f7fbfd; }
  .cr-winner-details__activity-item-date {
    font-size: 16px; }
  .cr-winner-details__activity-item-time {
    font-size: 14px; }
  .cr-winner-details__activity-item-date, .cr-winner-details__activity-item-time {
    line-height: 40px;
    color: #537a91;
    width: 89px;
    margin-right: 20px; }
  .cr-winner-details__activity-item-text {
    font-size: 14px;
    color: #000000; }
    .cr-winner-details__activity-item-text .highlight-text {
      color: #795afa; }
  .cr-winner-details__participation-activity-box {
    margin-top: 20px; }
    .cr-winner-details__participation-activity-box .cr-winner-details__section-title {
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      color: #000000;
      padding-top: 34px;
      padding-bottom: 0;
      height: auto; }
  .cr-winner-details__participation-activity-fields {
    margin-top: 0;
    padding-top: 8px;
    padding-left: 35px;
    padding-right: 32px;
    padding-bottom: 51px; }
    .cr-winner-details__participation-activity-fields .table-first-head {
      width: 60% !important;
      padding-left: 25px !important; }
    .cr-winner-details__participation-activity-fields .cr-list-table .table {
      box-shadow: none;
      border-collapse: collapse;
      overflow: inherit; }
      .cr-winner-details__participation-activity-fields .cr-list-table .table thead .table-first-head {
        border-top-left-radius: 20px; }
      .cr-winner-details__participation-activity-fields .cr-list-table .table thead .table-last-head {
        border-top-right-radius: 20px; }
      .cr-winner-details__participation-activity-fields .cr-list-table .table thead th {
        border-top: 0; }
      .cr-winner-details__participation-activity-fields .cr-list-table .table tbody {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .cr-winner-details .participation-item {
    font-size: 16px; }
    .cr-winner-details .participation-item:nth-child(2n + 1) {
      background-color: #f7fbfd; }
    .cr-winner-details .participation-item td {
      height: initial;
      padding: 8px 0; }
    .cr-winner-details .participation-item .text-date {
      color: #537a91;
      padding: 0 8px;
      border-right: 1px solid rgba(0, 0, 0, 0.1); }
    .cr-winner-details .participation-item .title {
      padding-left: 25px;
      padding-right: 8px;
      width: 60% !important;
      font-weight: bold;
      color: #333;
      border-left: 1px solid rgba(0, 0, 0, 0.1); }
    .cr-winner-details .participation-item .text-type {
      color: #795afa;
      padding: 0 8px; }
  .cr-winner-details .cr-jock-console-winner-modal__winner-form {
    padding: 0; }
  .cr-winner-details .cr-jock-console-winner-modal__form-buttons .form-group {
    text-align: left; }
  .cr-winner-details .cr-jock-console-winner-modal__content-form {
    margin-bottom: 0; }
  .cr-winner-details .cr-form-builder-fields .cr-textinput__input-field input {
    text-transform: inherit; }

.cr-promotion-contest-details .react-select__menu {
  z-index: 100; }

.cr-promotion-contest-details--view .cr-promotion-contest-details__contest-schedule-fields {
  padding-bottom: 5px; }

.cr-promotion-contest-details__text-cap {
  text-transform: capitalize; }

.cr-promotion-contest-details__section-audience {
  padding-bottom: 43px !important; }
  .cr-promotion-contest-details__section-audience .select-stations-wrapper {
    max-width: 370px; }

.cr-promotion-contest-details .cr-days-of-week .cr-checkbox .rc-checkbox-inner {
  width: 21px;
  height: 22px; }

.cr-promotion-contest-details .cr-days-of-week .cr-checkbox__checked-icon {
  left: 6px;
  top: 1px; }

.cr-promotion-contest-details .cr-days-of-week .cr-days-of-week__day-text {
  margin-bottom: 0; }

.cr-promotion-contest-details .cr-days-of-week .cr-days-of-week__day {
  margin-right: 12px; }

.cr-promotion-contest-details__box-action {
  cursor: pointer; }

.cr-promotion-contest-details__order-view-value {
  font-size: 16px;
  line-height: 20px;
  color: #334856; }

.cr-promotion-contest-details__contest-details-section-title, .cr-promotion-contest-details__contest-script-section-title {
  text-transform: capitalize;
  cursor: pointer; }

.cr-promotion-contest-details__contest-client-section-title {
  cursor: pointer; }

.cr-promotion-contest-details__contest-details-section-fields {
  padding-bottom: 23px; }

.cr-promotion-contest-details__contest-script-section-fields .cr-textarea-evaluator__inner-title, .cr-promotion-contest-details__contest-details-section-fields .cr-textarea-evaluator__inner-title {
  font-size: 21px;
  text-transform: initial; }

.cr-promotion-contest-details .exclude-dates-btn {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: #795afa;
  cursor: pointer;
  text-transform: capitalize; }

.cr-promotion-contest-details__box-script-details .cr-textarea-evaluator__title-wrapper {
  display: none; }

.cr-promotion-contest-details__box-script-details .cr-textarea-evaluator__wrapper-top.with-inner-title {
  margin-bottom: 12px; }

.cr-promotion-contest-details__box-script-details .cr-textarea-evaluator__container {
  padding-top: 0;
  padding-bottom: 0; }

.cr-promotion-contest-details__box-script-details .form-group {
  margin-bottom: 27px; }

.cr-promotion-contest-details__script-subtitle {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #43535c;
  text-transform: uppercase; }

.cr-promotion-contest-details__line {
  width: 100%;
  height: 1px;
  background-color: #edf1f6; }

.cr-promotion-contest-details__form-section-jock .cr-textarea-evaluator__wrapper-top-length {
  float: none;
  clear: both;
  display: block;
  width: 100%;
  margin: 0;
  margin-bottom: 15px; }
  .cr-promotion-contest-details__form-section-jock .cr-textarea-evaluator__wrapper-top-length .cr-textarea-evaluator__inner-title {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #43535c;
    font-weight: normal;
    text-transform: uppercase; }

.cr-promotion-contest-details__form-section-jock .with-inner-title {
  display: none; }

.cr-promotion-contest-details__form-section-jock .RichEditor-set-as-default {
  top: -30px;
  right: 0; }

.cr-promotion-contest-details__section-fields {
  padding-top: 28px; }
  .cr-promotion-contest-details__section-fields .form-group {
    margin-bottom: 27px; }

.cr-promotion-contest-details__contest-script-section-jock-fields {
  padding-top: 23px; }

.cr-promotion-contest-details__contests-quick-view-container {
  position: relative; }
  .cr-promotion-contest-details__contests-quick-view-container .button-row {
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 13px;
    cursor: pointer; }
    .cr-promotion-contest-details__contests-quick-view-container .button-row span {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      color: #795afa; }
    .cr-promotion-contest-details__contests-quick-view-container .button-row .icon {
      margin-left: 10px; }

.cr-promotion-contest-details__contests-quick-view-content {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 26px 34px 42px 34px;
  width: 100%;
  z-index: 1;
  margin-bottom: 20px; }
  .cr-promotion-contest-details__contests-quick-view-content .cr-prog-slots-filter-dropdown {
    min-width: 220px; }

.cr-promotion-contest-details__content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row; }
  .cr-promotion-contest-details__content-header .cr-toggle-switch div {
    font-size: 16px;
    color: #829fb1;
    text-transform: uppercase; }
    .cr-promotion-contest-details__content-header .cr-toggle-switch div.active {
      font-weight: 900;
      color: #000000; }
  .cr-promotion-contest-details__content-header .year-title {
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    text-align: center; }

.cr-promotion-contest-details__content-container {
  margin-top: 16px;
  padding: 16px;
  background: #f7fbfd;
  box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px; }

.cr-promotion-contest-details__quick-view-disabled span {
  color: #829fb1 !important; }

.cr-promotion-contest-details__badges {
  margin-top: 7px; }
  .cr-promotion-contest-details__badges .form-group {
    margin-bottom: 0; }
  .cr-promotion-contest-details__badges .cr-text-badge {
    max-width: 124px; }

.cr-promotion-contest-details__badge-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #334856;
  margin-bottom: 15px;
  display: block;
  text-transform: capitalize; }

.cr-promotion-contest-details__badges-container {
  display: flex;
  flex-direction: row; }
  .cr-promotion-contest-details__badges-container .cr-text-badge__text {
    text-transform: uppercase; }

.cr-promotion-contest-details__winner-on-air .cr-promotion-contest-details__order-view-value {
  height: 50px;
  display: flex;
  align-items: center; }

.cr-promotion-contest-details--view .cr-textarea-evaluator__inner-title {
  font-weight: bold;
  font-size: 23px;
  line-height: 29px;
  color: #334856; }

.cr-promotion-contest-details--view .form-group {
  margin-bottom: 30px; }

.cr-promotion-contest-details--view .cr-promotion-contest-details__contest-rules {
  margin-top: 0; }

.cr-promotion-contest-details__box-head-title {
  position: relative; }
  .cr-promotion-contest-details__box-head-title .menu-selection-icon {
    position: absolute;
    cursor: move;
    left: -31px; }

.cr-promotion-contest-details__contest-schedule-fields {
  padding-bottom: 28px; }
  .cr-promotion-contest-details__contest-schedule-fields .cr-radio-buttons__label {
    font-size: 14px;
    line-height: 18px;
    color: #43535c;
    text-transform: capitalize; }

.cr-promotion-contest-details__item-schedule-helper-text {
  font-size: 18px;
  line-height: 23px;
  color: #657894;
  margin-bottom: 25px; }

.cr-promotion-contest-details__contest-rules {
  font-size: 16px;
  line-height: 20px;
  color: #795afa;
  margin-top: 21px;
  cursor: pointer; }

.cr-promotion-contest-details__or-text {
  text-align: center;
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  color: #000000;
  display: inline-block;
  width: 49px;
  text-transform: uppercase;
  margin-top: 14px; }

.cr-promotion-contest-details__rule-days-group {
  display: flex; }
  .cr-promotion-contest-details__rule-days-group .form-group {
    flex: 1 1; }

.cr-promotion-contest-details__rule-switch .cr-toggle-switch {
  margin-top: 20px; }

.cr-promotion-contest-details__rule-switch .cr-toggle-switch__left-component,
.cr-promotion-contest-details__rule-switch .cr-toggle-switch__right-component {
  text-transform: uppercase;
  font-weight: normal;
  color: #829fb1; }

.cr-promotion-contest-details__rule-switch .active {
  font-weight: 600;
  color: #000; }

.cr-promotion-contest-details__toggle-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 35px; }

.cr-promotion-contest-details__qualifying-details .cr-promotion-contest-details__contest-script-section-title {
  border-top: 1px solid #edf1f6;
  border-bottom: none; }

.cr-promotion-contest-details__contest-qualifying-section-fields .cr-toggle-switch__left-component,
.cr-promotion-contest-details__contest-qualifying-section-fields .cr-toggle-switch__right-component, .cr-promotion-contest-details__contest-qualifying-section-fields-view .cr-toggle-switch__left-component,
.cr-promotion-contest-details__contest-qualifying-section-fields-view .cr-toggle-switch__right-component {
  font-size: 17px;
  line-height: 21px;
  color: #829fb1;
  text-transform: uppercase;
  font-weight: 400; }
  .cr-promotion-contest-details__contest-qualifying-section-fields .cr-toggle-switch__left-component.active,
  .cr-promotion-contest-details__contest-qualifying-section-fields .cr-toggle-switch__right-component.active, .cr-promotion-contest-details__contest-qualifying-section-fields-view .cr-toggle-switch__left-component.active,
  .cr-promotion-contest-details__contest-qualifying-section-fields-view .cr-toggle-switch__right-component.active {
    font-weight: bold; }

.cr-promotion-contest-details__contest-qualifying-section-fields-view .cr-promotion-contest-details__toggle-switch {
  margin-top: 30px;
  justify-content: flex-start; }

.cr-promotion-contest-details__group-jock-instructions .cr-textarea-evaluator__inner-title {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #43535c;
  font-weight: normal;
  text-transform: uppercase; }

.cr-promotion-contest-details__script-view-value .RichEditor-none {
  margin-top: 20px; }

.cr-promotion-contest-details__time-col {
  max-width: 370px; }
  .cr-promotion-contest-details__time-col .cr-input-time__ampm {
    max-width: 83px; }

.cr-promotion-contest-details__introduction-field-empty .RichEditor-set-as-default {
  cursor: not-allowed;
  opacity: 0.9; }

.cr-promotion-contest-details__section-fulfillment {
  border-top: 1px solid #edf1f6;
  padding: 30px 49px; }

.cr-promotion-contest-details__fulfillment-automation-title {
  font-weight: 700;
  text-transform: capitalize;
  color: #334856;
  margin-bottom: 23px;
  font-size: 23px; }

.cr-promotion-contest-details__contest-image-help-text {
  color: #829fb1;
  display: flex;
  flex-direction: column;
  margin-top: 20px; }

.cr-promotion-contest-details__contest-image .cr-upload-files__drop-area {
  height: 48px; }

.cr-promotion-contest-details__contest-image .cr-file-view__file-name {
  color: #795afa; }

.cr-schedule-preview--edit .form-group {
  margin-bottom: 27px; }

.cr-schedule-preview--view .form-group {
  margin-bottom: 30px; }

.cr-schedule-preview__reset-col, .cr-schedule-preview__reset-form-group {
  margin-bottom: 0 !important; }

.cr-schedule-preview__order-view-label {
  margin-bottom: 10px; }

.cr-schedule-preview__order-view-value {
  font-size: 16px;
  line-height: 20px;
  color: #000000; }

.cr-schedule-preview__col-reset {
  margin-bottom: 0 !important; }

.cr-schedule-preview__section-audience {
  padding-top: 28px !important;
  padding-bottom: 33px !important; }

.cr-schedule-preview__section-audience-view {
  padding-top: 37px !important;
  padding-bottom: 54px !important; }

.cr-schedule-preview__quick-availability-container .button-row {
  display: flex;
  align-items: center;
  cursor: pointer; }
  .cr-schedule-preview__quick-availability-container .button-row div {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase; }
  .cr-schedule-preview__quick-availability-container .button-row div:not(.btn-expand) {
    color: #829fb1; }
  .cr-schedule-preview__quick-availability-container .button-row .btn-expand {
    color: #795afa;
    margin-left: 12px; }
  .cr-schedule-preview__quick-availability-container .button-row div.icon {
    padding-top: 2px;
    margin-left: 12px; }

.cr-schedule-preview__quick-view-section-title {
  margin-top: 25px;
  border: none !important; }

.cr-schedule-preview__switch-toolbar.cr-toggle-switch .switch-text {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  color: #829fb1;
  text-transform: uppercase; }
  .cr-schedule-preview__switch-toolbar.cr-toggle-switch .switch-text.active {
    color: #334856; }

.cr-schedule-preview__switch-toolbar.cr-toggle-switch .react-switch-bg {
  background: #795afa !important; }

.cr-schedule-preview__quick-view-section-toolbar {
  display: flex;
  align-items: center; }
  .cr-schedule-preview__quick-view-section-toolbar .react-select-container {
    margin-left: 30px; }
    .cr-schedule-preview__quick-view-section-toolbar .react-select-container .react-select__control {
      min-width: 300px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #334856; }
  .cr-schedule-preview__quick-view-section-toolbar .react-select__single-value .value-container {
    display: flex; }
    .cr-schedule-preview__quick-view-section-toolbar .react-select__single-value .value-container .type {
      min-width: 110px !important; }

.cr-schedule-preview__quick-view-section-fields {
  padding-top: 0px !important;
  padding-bottom: 35px !important; }

.cr-schedule-preview__availability-table .scroll-bar-style::-webkit-scrollbar {
  display: block;
  width: 8px;
  height: 8px; }

.cr-schedule-preview__availability-table .vertical-scroll {
  overflow-y: auto;
  max-height: 710px; }

.cr-schedule-preview__availability-table .rules {
  height: 55px;
  border-top: 1px solid #829fb1;
  display: flex;
  align-items: center; }
  .cr-schedule-preview__availability-table .rules .header {
    padding-left: 25px; }
  .cr-schedule-preview__availability-table .rules .left-column {
    margin-right: 10px; }
  .cr-schedule-preview__availability-table .rules .cr-checkbox__text {
    font-size: 14px;
    color: #334856;
    text-align: left;
    white-space: nowrap; }
  .cr-schedule-preview__availability-table .rules .re-calculate {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 15px;
    white-space: nowrap; }
    .cr-schedule-preview__availability-table .rules .re-calculate span {
      color: #795afa;
      font-size: 14px;
      margin-left: 5px; }
  .cr-schedule-preview__availability-table .rules .weeks {
    height: 55px;
    display: flex;
    align-items: center; }
  .cr-schedule-preview__availability-table .rules .week {
    padding: 0 !important;
    border-bottom: 0 !important;
    min-width: auto !important;
    margin-right: 19px; }
  .cr-schedule-preview__availability-table .rules .cr-checkbox__checkbox {
    justify-content: center; }

.cr-schedule-preview__availability-table .quick-view-box {
  background: #f7fbfd;
  box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px; }
  .cr-schedule-preview__availability-table .quick-view-box .fixed-column {
    background: rgba(194, 212, 224, 0.3);
    border-radius: 6px 0px 0px 5px;
    padding-bottom: 14px !important; }
    .cr-schedule-preview__availability-table .quick-view-box .fixed-column .left-column {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      color: #0f5589;
      padding: 25px 22px;
      border-bottom: 1px solid rgba(194, 212, 224, 0.6);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
      .cr-schedule-preview__availability-table .quick-view-box .fixed-column .left-column.header {
        font-weight: normal;
        font-size: 16px;
        color: #6f96d1;
        height: 71px;
        white-space: initial;
        padding: 0 25px;
        display: flex;
        align-items: center; }
      .cr-schedule-preview__availability-table .quick-view-box .fixed-column .left-column.header-checkbox {
        height: 97px; }
      .cr-schedule-preview__availability-table .quick-view-box .fixed-column .left-column:last-child {
        border-bottom: none; }

.cr-schedule-preview__availability-table .weeks {
  overflow-x: auto; }
  .cr-schedule-preview__availability-table .weeks .week-checkbox {
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-schedule-preview__availability-table .weeks .week {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #6f96d1;
    padding: 15px 22px;
    border-bottom: 1px solid rgba(194, 212, 224, 0.6);
    min-width: 120px; }
    .cr-schedule-preview__availability-table .weeks .week.bordered {
      border-right: 1px solid rgba(194, 212, 224, 0.6); }
      .cr-schedule-preview__availability-table .weeks .week.bordered:last-child {
        border-right: none; }
    .cr-schedule-preview__availability-table .weeks .week.item-count {
      font-weight: bold;
      font-size: 18px;
      color: #0f5589;
      padding: 25px 22px; }
    .cr-schedule-preview__availability-table .weeks .week .all-items-count {
      color: #6f96d1;
      font-weight: 400; }
  .cr-schedule-preview__availability-table .weeks .week-checkbox {
    width: auto !important; }
  .cr-schedule-preview__availability-table .weeks .week-all {
    max-width: 100px; }

.cr-schedule-preview__availability-table-total .quick-view-box .fixed-column {
  background-color: #f7fbfd;
  padding-bottom: 0 !important; }

.cr-schedule-preview__availability-table-total .quick-view-box .weeks .week-row {
  border-bottom: 1px solid rgba(194, 212, 224, 0.6); }
  .cr-schedule-preview__availability-table-total .quick-view-box .weeks .week-row .item-count,
  .cr-schedule-preview__availability-table-total .quick-view-box .weeks .week-row .week {
    border-bottom: none;
    width: 120px; }
  .cr-schedule-preview__availability-table-total .quick-view-box .weeks .week-row:last-child {
    border-bottom: none; }

.cr-schedule-preview__availability-table-total .quick-view-box .weeks.weeks-multiple .week-row {
  border-bottom: none !important; }
  .cr-schedule-preview__availability-table-total .quick-view-box .weeks.weeks-multiple .week-row .item-count,
  .cr-schedule-preview__availability-table-total .quick-view-box .weeks.weeks-multiple .week-row .week {
    border-bottom: 1px solid rgba(194, 212, 224, 0.6) !important; }

.cr-schedule-preview__availability-table-total .quick-view-box .fixed-column {
  padding-bottom: 0 !important; }

.cr-schedule-preview__item-details-section-title {
  margin-top: 25px; }

.cr-schedule-preview__item-details-section-fields-view {
  padding-top: 30px !important;
  padding-bottom: 31px !important; }

.cr-schedule-preview__item-details-section-fields {
  padding-bottom: 66px !important;
  padding-top: 30px !important; }
  .cr-schedule-preview__item-details-section-fields .cr-days-of-week .col-6 {
    max-width: 100%; }
    .cr-schedule-preview__item-details-section-fields .cr-days-of-week .col-6 .cr-days-of-week__day-text {
      margin-bottom: 2px; }
  .cr-schedule-preview__item-details-section-fields .cr-checkbox .rc-checkbox-inner {
    width: 21px;
    height: 22px; }
  .cr-schedule-preview__item-details-section-fields .cr-checkbox__checked-icon {
    left: 6px;
    top: 1px; }
  .cr-schedule-preview__item-details-section-fields .exclude-dates-btn {
    float: right;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #795afa;
    cursor: pointer;
    margin-bottom: 5px;
    text-transform: capitalize; }
  .cr-schedule-preview__item-details-section-fields .exclude-date-picker {
    clear: both; }
    .cr-schedule-preview__item-details-section-fields .exclude-date-picker .react-datepicker__day-names {
      background-color: #f7fbfd;
      border: 1px solid #c2d4e0 !important; }
      .cr-schedule-preview__item-details-section-fields .exclude-date-picker .react-datepicker__day-names .react-datepicker__day-name {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #829fb1;
        padding: 12px 20px; }
    .cr-schedule-preview__item-details-section-fields .exclude-date-picker .react-datepicker__week .react-datepicker__day {
      border-bottom: 1px solid #c2d4e0;
      border-left: 1px solid #c2d4e0;
      font-size: 16px;
      line-height: 20px;
      color: #829fb1;
      padding: 10px 20px; }
    .cr-schedule-preview__item-details-section-fields .exclude-date-picker .react-datepicker__week .react-datepicker__day--disabled {
      color: #829fb1 !important;
      background: rgba(194, 212, 224, 0.4) !important; }
      .cr-schedule-preview__item-details-section-fields .exclude-date-picker .react-datepicker__week .react-datepicker__day--disabled:hover {
        color: #829fb1 !important;
        background: rgba(194, 212, 224, 0.4); }
    .cr-schedule-preview__item-details-section-fields .exclude-date-picker .react-datepicker__week .react-datepicker__day--highlighted {
      background-color: #795afa !important;
      color: #fff !important;
      font-weight: normal; }
    .cr-schedule-preview__item-details-section-fields .exclude-date-picker .react-datepicker__week .react-datepicker__day--today {
      font-weight: normal; }
    .cr-schedule-preview__item-details-section-fields .exclude-date-picker .react-datepicker__week .react-datepicker__day--keyboard-selected,
    .cr-schedule-preview__item-details-section-fields .exclude-date-picker .react-datepicker__week .react-datepicker__day--selected {
      background-color: #fff; }
    .cr-schedule-preview__item-details-section-fields .exclude-date-picker .datePickerTxt {
      font-weight: 800;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      color: #334856; }
    .cr-schedule-preview__item-details-section-fields .exclude-date-picker .datePickerButtons {
      color: #795afa; }

.cr-schedule-preview__item-shcedule-helper-text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #657894; }

.cr-schedule-preview__item-scheduling-section-fields-view {
  padding-top: 30px;
  padding-bottom: 5px; }

.cr-schedule-preview__order-view-statement {
  color: #334856;
  font-size: 18px; }

.cr-schedule-preview__item-scheduling-section-fields {
  padding-top: 26px;
  padding-bottom: 27px; }
  .cr-schedule-preview__item-scheduling-section-fields .cr-radio-buttons__label {
    text-transform: capitalize;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #43535c; }

.cr-schedule-preview .cr-days-of-week__day {
  margin-right: 15px; }

.cr-schedule-preview__badges-container {
  display: inline-flex;
  flex-wrap: wrap; }

.cr-schedule-preview__badges label span {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #334856;
  margin-bottom: 10px;
  display: block;
  text-transform: capitalize; }

.cr-schedule-preview__badges-hours .cr-text-badge__text {
  text-transform: lowercase; }

.cr-schedule-preview__scheduling-statement-header {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #43535c;
  text-transform: uppercase;
  margin-bottom: 8px; }

.cr-schedule-preview__scheduling-statement-text {
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: #537a91; }

.cr-schedule-preview__desired-items {
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 49px 50px 49px 40px;
  margin-bottom: 41px; }

.cr-schedule-preview__desired-items-title {
  font-size: 23px;
  color: #334856;
  margin-bottom: 28px;
  display: flex;
  align-items: center; }
  .cr-schedule-preview__desired-items-title strong {
    margin: 0 10px;
    color: #334856; }

.cr-schedule-preview__desired-title {
  font-size: 23px;
  color: #334856; }

.cr-schedule-preview__desired-slider {
  display: flex;
  align-items: center;
  justify-content: center; }
  .cr-schedule-preview__desired-slider .input-container {
    margin-bottom: 0 !important; }
  .cr-schedule-preview__desired-slider .cr-textinput {
    background-color: #fff;
    width: 88px;
    display: inline-block; }
    .cr-schedule-preview__desired-slider .cr-textinput input {
      font-size: 23px;
      text-align: center;
      background: transparent; }
  .cr-schedule-preview__desired-slider .cr-textinput__input-wrapper {
    height: 54px; }

.cr-schedule-preview__slider {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  margin: 0 20px; }

.cr-schedule-preview__slider-value {
  color: #43535c;
  font-size: 16px;
  width: 50px;
  text-align: center; }

.cr-schedule-preview__help-tooltip-overlay {
  opacity: 1; }
  .cr-schedule-preview__help-tooltip-overlay .rc-tooltip-inner {
    background-color: #fff;
    max-width: 400px;
    padding: 10px 20px; }
  .cr-schedule-preview__help-tooltip-overlay .rc-tooltip-arrow {
    border-color: transparent;
    top: -1px; }
    .cr-schedule-preview__help-tooltip-overlay .rc-tooltip-arrow:after {
      content: "";
      position: absolute;
      width: 19px;
      height: 19px;
      background: #fff;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 40px;
      left: -3px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); }
    .cr-schedule-preview__help-tooltip-overlay .rc-tooltip-arrow:before {
      content: "";
      display: block;
      width: 30px;
      height: 14px;
      background: #fff;
      position: absolute;
      top: 42px;
      z-index: 9;
      left: -1px;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg); }

.cr-schedule-preview__help-icon {
  cursor: pointer;
  display: inherit; }

.cr-schedule-preview__help-tooltip {
  font-size: 18px;
  color: #657894; }

.cr-schedule-preview__render-weeks {
  display: flex; }

.cr-schedule-preview__scheduling-preview-container {
  margin-top: 26px;
  padding-bottom: 0; }

.cr-schedule-preview__preview-title {
  font-weight: bold;
  font-size: 23px;
  text-transform: capitalize;
  color: #334856;
  margin-bottom: 21px; }

.cr-schedule-preview__preview-content {
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden; }

.cr-schedule-preview__preview-head {
  padding: 43px 65px 43px 55px; }
  .cr-schedule-preview__preview-head .input-container {
    margin-bottom: 0;
    margin: 0 10px; }
  .cr-schedule-preview__preview-head .cr-textinput__input-wrapper {
    width: 88px;
    background-color: #fff; }

.cr-schedule-preview__preview-total-text {
  font-size: 23px;
  color: #334856;
  display: flex;
  align-items: center;
  margin-bottom: 17.23px; }
  .cr-schedule-preview__preview-total-text.no-flex {
    display: block; }
    .cr-schedule-preview__preview-total-text.no-flex div {
      display: flex;
      align-items: center; }
  .cr-schedule-preview__preview-total-text:last-child {
    margin-bottom: 0; }
  .cr-schedule-preview__preview-total-text strong {
    color: #000;
    margin: 0 10px; }
  .cr-schedule-preview__preview-total-text .cr-textinput {
    margin-left: 10px; }

.cr-schedule-preview__preview-buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  .cr-schedule-preview__preview-buttons .btn {
    width: 254px;
    margin-right: 0 !important; }
    .cr-schedule-preview__preview-buttons .btn:first-child {
      margin-bottom: 15px; }

.cr-schedule-preview__section-toolbar {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }

.cr-schedule-preview__preview-distribution {
  font-size: 14px;
  color: #334856;
  margin-bottom: 9px; }

.cr-schedule-preview__desired-items-description {
  color: #537a91;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 4px; }

.cr-schedule-preview__desired-items-alert {
  color: #e40061;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 4px; }

.cr-schedule-preview__text-cap {
  text-transform: capitalize; }

.cr-schedule-preview__scheduling-table-preview .quick-view-box {
  border-top: 1px solid #829fb1;
  background-color: #fff !important;
  box-shadow: none !important;
  border-radius: 0 !important; }

.cr-schedule-preview__scheduling-table-preview .cr-promotion-liner-details__availability-table .left-column {
  background-color: #f7fbfd !important; }

.cr-schedule-preview__scheduling-table-preview .cr-promotion-liner-details__availability-table .item-count {
  background-color: #fff; }

.cr-schedule-preview__item-dropdown .react-select__option {
  text-transform: capitalize; }

.cr-schedule-preview__order-view-value-excluded-dates {
  text-transform: uppercase; }

.cr-schedule-preview__preview-help-text {
  font-size: 18px;
  line-height: 23px;
  color: #657894;
  display: block;
  font-weight: normal;
  text-transform: initial;
  margin-top: 7px; }

.cr-schedule-preview__fill-item-rotation-section-title {
  border-bottom: none;
  border-top: 1px solid #edf1f6;
  text-transform: capitalize;
  padding-top: 37px; }

.cr-schedule-preview__fill-item-rotation-helper {
  font-size: 18px;
  line-height: 23px;
  color: #657894;
  margin-bottom: 20px;
  display: block; }

.cr-schedule-preview__fill-item-rotation-fields {
  margin-top: 0;
  padding-bottom: 40px; }
  .cr-schedule-preview__fill-item-rotation-fields .alert-danger {
    max-width: 849px; }

.cr-schedule-preview__head-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #829fb1;
  text-transform: uppercase;
  min-width: 62px; }

.cr-schedule-preview__rotation-table {
  box-sizing: border-box;
  border-radius: 6px;
  border-collapse: collapse;
  border-style: hidden;
  box-shadow: 0 0 0 1px #c2d4e0;
  overflow: hidden;
  max-width: 849px;
  width: 100%; }
  .cr-schedule-preview__rotation-table td {
    border: 1px solid #c2d4e0;
    height: 55px; }
  .cr-schedule-preview__rotation-table thead {
    background-color: #f7fbfd; }
  .cr-schedule-preview__rotation-table th {
    height: 57px;
    line-height: 42px;
    text-align: center; }

.cr-schedule-preview__first-head {
  border-right: 1px solid #c2d4e0;
  padding-left: 37px;
  text-align: left !important; }

.cr-schedule-preview__last-head {
  width: 166px; }

.cr-schedule-preview__station-name {
  font-weight: bold;
  font-size: 14px;
  line-height: 39px;
  color: #111b2b;
  padding-left: 37px; }

.cr-schedule-preview__rotation-input {
  max-width: 59px;
  border: 1px solid #829fb1;
  height: 100%;
  font-size: 14px;
  text-align: center;
  color: #111b2b;
  height: 31px;
  border-radius: 4px;
  outline: none;
  font-weight: bold; }

.cr-schedule-preview__rotation-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #829fb1; }

.cr-schedule-preview__rotation-continuity-text {
  color: #111b2b; }

.cr-schedule-preview__rotation-error .cr-schedule-preview__rotation-input {
  color: #ff006c;
  border-color: #ff006c; }

.cr-schedule-preview__rotation-value {
  padding: 0 10px;
  border-right: none !important;
  border-left: none !important;
  text-align: center; }

.cr-schedule-preview__rotation-value-first, .cr-schedule-preview__head-label-first {
  padding-left: 23px; }

.cr-schedule-preview__rotation-value-last, .cr-schedule-preview__head-label-last {
  padding-right: 26px; }

.cr-schedule-preview__field-label {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #334856 !important;
  text-transform: inherit !important;
  margin-bottom: 0; }

.cr-schedule-preview__table-view-mode {
  margin-bottom: 32px; }

@media (max-width: 1739px) {
  .cr-promotion-liner-details__quick-view-section-toolbar .react-select-container .react-select__control {
    min-width: 190px; }
  .cr-promotion-liner-details__quick-view-section-toolbar .react-select__single-value .value-container .type {
    min-width: 120px !important; }
  .cr-promotion-liner-details__item-details-section-fields .cr-days-of-week__day {
    margin-right: 6px; }
  .react-select__option .value-container .type {
    min-width: 70px; } }

.cr-contest-rules-modal .modal-content {
  width: 644px;
  max-width: auto; }

.cr-contest-rules-modal .modal-header {
  position: relative;
  border-bottom-color: #edf1f6;
  padding: 10px 24px 10px 36px;
  height: 80px;
  align-items: center; }

.cr-contest-rules-modal .modal-title {
  font-weight: bold;
  font-size: 23px;
  line-height: 29px;
  color: #334856;
  text-transform: capitalize; }

.cr-contest-rules-modal__close-icon {
  position: absolute;
  top: 25px;
  right: 24px;
  cursor: pointer; }

.cr-contest-rules-modal .modal-footer {
  border-top: 0;
  text-align: left;
  padding-left: 36px;
  padding-bottom: 34px;
  justify-content: flex-start; }

.cr-contest-rules-modal .modal-body {
  padding-top: 33px;
  padding-left: 38px;
  padding-right: 56px;
  padding-bottom: 20px; }

.cr-contest-rules-modal__contest-rules-view {
  font-size: 16px;
  line-height: 20px;
  color: #334856; }

.cr-contest-rules-modal label {
  color: #43535c;
  text-transform: uppercase; }

.cr-list-table .table {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px 20px 5px 5px;
  overflow: hidden;
  margin-bottom: 0;
  background: #fff; }

.cr-list-table thead {
  background-color: #224b8b;
  color: #fff;
  font-weight: 700;
  font-size: 16px; }
  .cr-list-table thead th {
    border-bottom: none;
    padding: 8px;
    text-align: left; }
    .cr-list-table thead th span {
      font-weight: 400;
      text-transform: capitalize; }

.cr-list-table tbody td {
  text-align: left;
  border-top-color: #ecf1f7;
  padding: 14px 0;
  height: 84px; }

.cr-list-table tbody tr:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
  cursor: pointer; }

.cr-list-table .table-scroll .table-first-head,
.cr-list-table .table-scroll .first-col {
  width: 153px; }

.cr-list-table .table-scroll thead {
  display: table;
  table-layout: fixed;
  width: 100%; }

.cr-list-table .table-scroll tbody {
  max-height: 400px;
  overflow: auto;
  display: block;
  margin-right: 5px;
  padding-right: 5px; }
  .cr-list-table .table-scroll tbody tr {
    display: table;
    table-layout: fixed;
    width: 100%; }
    .cr-list-table .table-scroll tbody tr td {
      padding-left: 8px; }
      .cr-list-table .table-scroll tbody tr td:last-child {
        border-right: 1px solid #ecf1f7; }

.cr-list-table .item-sortable {
  align-items: center;
  justify-content: flex-start;
  display: inline-flex;
  white-space: nowrap; }

.cr-list-table .sortable-active {
  color: #c12674; }

.cr-list-table .sortable {
  flex-direction: column;
  width: 25px;
  height: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }

.cr-list-table .sortable-icon {
  position: absolute;
  font-size: 16px; }

.cr-list-table .first-col {
  padding-left: 12px !important; }

.cr-list-table .table-first-head {
  padding-left: 20px; }

.cr-list-table .td-value {
  color: #334856;
  font-size: 16px; }

.cr-list-table .title .td-value {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #111b2b; }

.cr-list-table .sub-value {
  color: #829fb1;
  font-size: 12px;
  display: block;
  text-transform: uppercase; }

.list-liner-item.list-liner-prize-item {
  background-color: #EDF1F6; }
  .list-liner-item.list-liner-prize-item td {
    height: 50px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border-bottom: 1px solid #FFFFFF; }
    .list-liner-item.list-liner-prize-item td.title .td-value {
      color: #334856;
      font-weight: normal;
      font-size: 16px; }
  .list-liner-item.list-liner-prize-item:hover {
    background-color: #D7E6EF; }

.list-liner-item .type {
  width: 51px;
  height: 51px;
  border-radius: 100px;
  background: #edf3f7;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .list-liner-item .type strong {
    font-weight: 800;
    font-size: 19px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase; }
  .list-liner-item .type span {
    font-size: 7px;
    line-height: 9px;
    text-align: center;
    text-transform: uppercase; }

.list-liner-item .sub-value {
  color: #829fb1;
  font-size: 12px;
  display: block;
  text-transform: uppercase; }

.list-liner-item .flight-dates {
  color: #537a91; }

.list-liner-item .liner-fill {
  color: #795afa; }

.list-liner-item .liner-simple {
  color: #469cff; }

.list-liner-item .liner-targeted {
  color: #32bad5; }

.list-liner-item .list-none-text {
  padding-left: 5px; }

.cr-associate-items {
  margin-top: 66px; }
  .cr-associate-items .cr-no-items-found {
    padding: 0;
    min-height: 300px; }
  .cr-associate-items .table {
    margin-top: 38px; }
  .cr-associate-items__title {
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 23px; }
  .cr-associate-items .btn-add {
    width: 185px;
    margin-top: 23px; }
    .cr-associate-items .btn-add span {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #795afa;
      margin-left: 11px;
      text-transform: initial; }
  .cr-associate-items__buttons {
    text-align: right; }
  .cr-associate-items .react-select-container {
    max-width: 390px; }
  .cr-associate-items .cr-search-box {
    width: 390px !important;
    min-width: auto;
    float: right;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12); }
  .cr-associate-items .table-scroll tbody {
    max-height: 339px; }

.cr-preview-item-modal {
  max-width: 877px; }
  .cr-preview-item-modal .modal-body {
    padding: 33px 50px 54px 34px; }
  .cr-preview-item-modal .modal-content {
    max-width: auto; }
  .cr-preview-item-modal__item-head {
    margin-bottom: 34px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .cr-preview-item-modal__item-title {
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    color: #111b2b; }
  .cr-preview-item-modal__item-subtitle {
    font-size: 18px;
    line-height: 23px;
    color: #829fb1; }
  .cr-preview-item-modal__item-length {
    font-size: 16px;
    line-height: 20px;
    color: #657894;
    margin-right: 13px; }
    .cr-preview-item-modal__item-length strong {
      color: #111b2b;
      margin-left: 5px; }
  .cr-preview-item-modal__close-icon {
    position: absolute;
    top: 26px;
    right: 25px;
    cursor: pointer; }
  .cr-preview-item-modal__item-group h4 {
    font-size: 23px;
    color: #334856;
    text-transform: capitalize;
    margin-bottom: 0; }

.cr-add-item-modal {
  max-width: 949px; }
  .cr-add-item-modal .modal-title {
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856; }
  .cr-add-item-modal .modal-header {
    border-bottom-color: #edf1f6;
    height: 74px;
    padding-left: 48px;
    display: flex;
    align-items: center; }
  .cr-add-item-modal .modal-body {
    padding-left: 48px;
    padding-right: 51px;
    padding-top: 31px;
    padding-bottom: 63px; }
  .cr-add-item-modal__col-reset {
    margin-bottom: 0; }
  .cr-add-item-modal__form-content label {
    letter-spacing: 0.05em;
    color: #43535c;
    text-transform: uppercase; }
  .cr-add-item-modal__form-content .form-group {
    margin-bottom: 27px; }
  .cr-add-item-modal .cr-days-of-week .col-6 {
    max-width: 100%; }
    .cr-add-item-modal .cr-days-of-week .col-6 .cr-days-of-week__day-text {
      margin-bottom: 0; }
  .cr-add-item-modal .cr-checkbox .rc-checkbox-inner {
    width: 21px;
    height: 22px; }
  .cr-add-item-modal .cr-checkbox__checked-icon {
    left: 6px;
    top: 1px; }
  .cr-add-item-modal__buttons {
    padding-top: 24px; }
    .cr-add-item-modal__buttons .btn-blue {
      margin-right: 14px; }
  .cr-add-item-modal__excluded-days-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #334856;
    text-transform: capitalize;
    margin-bottom: 10px; }
  .cr-add-item-modal__badges-container {
    display: flex;
    align-items: center; }
  .cr-add-item-modal .exclude-dates-btn {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #795afa;
    cursor: pointer;
    text-transform: capitalize; }
  .cr-add-item-modal .cr-text-badge {
    max-width: 130px; }
  .cr-add-item-modal .cr-text-badge__text {
    text-transform: uppercase; }

.cr-promotion-contest-prizes--view .cr-promotion-contest-prizes__total-value strong {
  height: 30px; }

.cr-promotion-contest-prizes--view .cr-promotion-contest-prizes__contest-prize-fields {
  padding-bottom: 33px; }

.cr-promotion-contest-prizes--edit .cr-promotion-contest-prizes__selected-prize-package {
  min-width: 395px !important; }

.cr-promotion-contest-prizes__contest-prize-section-title {
  text-transform: capitalize;
  border-bottom: 0;
  cursor: pointer; }

.cr-promotion-contest-prizes__price-value {
  margin-left: 32px;
  margin-right: 32px;
  color: #000000; }

.cr-promotion-contest-prizes__price-total {
  font-size: 16px;
  font-weight: 400; }

.cr-promotion-contest-prizes__prize-empty-text {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 420px;
  height: 323px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .cr-promotion-contest-prizes__prize-empty-text span {
    font-weight: 800;
    font-size: 23px;
    line-height: 29px;
    text-align: center;
    color: #795afa;
    max-width: 250px; }

.cr-promotion-contest-prizes__title {
  font-weight: bold;
  font-size: 23px;
  line-height: 29px;
  color: #000000;
  text-transform: capitalize; }

.cr-promotion-contest-prizes__prize-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #edf1f6;
  padding: 0 49px;
  padding-bottom: 15px;
  margin-bottom: 20px; }

.cr-promotion-contest-prizes__buttons span {
  text-transform: initial;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #795afa;
  margin-left: 10px; }

.cr-promotion-contest-prizes__box-action {
  cursor: pointer; }

.cr-promotion-contest-prizes__prize-table {
  margin-top: 60px; }

.cr-promotion-contest-prizes .table {
  margin-top: 22px;
  margin-bottom: 33px; }
  .cr-promotion-contest-prizes .table .title {
    width: 40%; }

.cr-promotion-contest-prizes .cr-no-items-found {
  padding: 0;
  min-height: 300px; }

.cr-promotion-contest-prizes__prize-type {
  width: 51px;
  height: 51px;
  background-color: #edf3f7;
  border-radius: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  margin: 0 8px; }

.cr-promotion-contest-prizes__prize-cash {
  color: #3db972; }

.cr-promotion-contest-prizes__prize-variable {
  color: #3db972; }

.cr-promotion-contest-prizes__prize-tickets {
  color: #32bad5; }

.cr-promotion-contest-prizes__prize-prize {
  color: #795afa; }

.cr-promotion-contest-prizes__prize-merch {
  color: #469cff; }

.cr-promotion-contest-prizes__prize-sort-name {
  font-weight: 800;
  font-size: 23px;
  text-align: center;
  line-height: 24px; }

.cr-promotion-contest-prizes__prize-name {
  font-size: 8px;
  line-height: 10px;
  text-align: center; }

.cr-promotion-contest-prizes__contest-prize-fields .cr-search-box {
  min-width: initial;
  max-width: 389px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  float: right; }

.cr-promotion-contest-prizes__contest-prize-fields .react-select-container {
  max-width: 237px; }

.cr-promotion-contest-prizes__selected-prize-package {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  width: calc(100% / 3 - 10px);
  max-width: 420px;
  min-width: 350px;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .cr-promotion-contest-prizes__selected-prize-package .react-select__indicator-separator {
    display: none; }
  .cr-promotion-contest-prizes__selected-prize-package .react-select__dropdown-indicator {
    padding: 14px !important; }
  .cr-promotion-contest-prizes__selected-prize-package .alert-danger {
    display: block;
    margin-bottom: 2px; }

.cr-promotion-contest-prizes__only-number {
  max-width: 32px; }
  .cr-promotion-contest-prizes__only-number .cr-promotion-contest-prizes__itemed_prize_item {
    padding: 0; }
  .cr-promotion-contest-prizes__only-number .cr-promotion-contest-prizes__itemed_prize {
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0; }

.cr-promotion-contest-prizes__itemed_prize {
  flex: 1 1;
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  color: #334856;
  font-size: 18px;
  line-height: 23px;
  border-radius: 6px;
  padding-left: 24px;
  padding-right: 20px; }
  .cr-promotion-contest-prizes__itemed_prize .menu-selection-icon {
    cursor: move; }

.cr-promotion-contest-prizes__itemed_prize_item {
  margin-left: 8px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center; }

.cr-promotion-contest-prizes__itemed_prize_number {
  padding: 4px;
  color: #795afa;
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 29px; }

.cr-promotion-contest-prizes__itemed_prize_item_delete {
  cursor: pointer; }

.cr-promotion-contest-prizes__selected-prize-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  padding-top: 26px;
  max-width: calc(100% - 20px); }

.cr-promotion-contest-prizes__selected-prize-subtitle {
  font-size: 16px;
  line-height: 20px;
  color: #829fb1;
  display: block;
  margin-bottom: 13px; }

.cr-promotion-contest-prizes__selected-prize-value {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #334856;
  margin-bottom: 8px;
  text-transform: uppercase; }
  .cr-promotion-contest-prizes__selected-prize-value span {
    margin-right: 5px; }

.cr-promotion-contest-prizes__selected-prize-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  border-top: 1px solid #edf1f6;
  padding-left: 31px;
  padding-right: 27px; }
  .cr-promotion-contest-prizes__selected-prize-footer strong {
    font-size: 18px;
    line-height: 23px;
    color: #795afa;
    margin-left: 10px; }

.cr-promotion-contest-prizes__number-input:not(input) {
  margin-left: 4px; }

.cr-promotion-contest-prizes__number-input input {
  padding: 5px;
  max-width: 100px; }

.cr-promotion-contest-prizes__number-input .input-container {
  margin-bottom: 0 !important; }
  .cr-promotion-contest-prizes__number-input .input-container .cr-textinput__input-wrapper {
    height: 35px; }

.cr-promotion-contest-prizes__selected-prize-description {
  font-size: 16px;
  line-height: 20px;
  color: #334856;
  margin-bottom: 20px; }

.cr-promotion-contest-prizes__selected-prize-padding {
  padding-left: 31px;
  padding-right: 27px; }

.cr-promotion-contest-prizes__selected-prize-row {
  padding-bottom: 24px;
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between; }

.cr-promotion-contest-prizes__trash {
  position: absolute;
  right: 27px;
  top: 26px;
  cursor: pointer; }

.cr-promotion-contest-prizes__prize-items {
  display: flex;
  flex-wrap: wrap; }

.cr-promotion-contest-prizes__total-value {
  text-align: center;
  white-space: nowrap; }
  .cr-promotion-contest-prizes__total-value strong {
    color: #000000;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center; }

.cr-promotion-contest-prizes__per-play-dropdown {
  min-width: 76px; }

.cr-promotion-contest-prizes__fulfillment-dropdown {
  width: 164px; }

.cr-promotion-contest-prizes__selected-prize-item {
  margin-bottom: 10px;
  min-width: 65px; }
  .cr-promotion-contest-prizes__selected-prize-item .order-view-value {
    min-height: 30px;
    display: flex;
    align-items: center;
    margin-bottom: 0 !important; }

.cr-promotion-contest-prizes__tooltip {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  opacity: 1;
  padding: 0; }
  .cr-promotion-contest-prizes__tooltip p {
    margin-bottom: 14px; }
  .cr-promotion-contest-prizes__tooltip .rc-tooltip-content {
    background-color: #ffffff;
    padding: 32px 20px;
    border-radius: 4px;
    font-size: 14px; }
  .cr-promotion-contest-prizes__tooltip .rc-tooltip-arrow {
    border-top-color: #ffffff;
    border-width: 10px 10px 0;
    bottom: -8px; }
  .cr-promotion-contest-prizes__tooltip .rc-tooltip-inner {
    padding: 0;
    color: #334856;
    background-color: #ffffff;
    box-shadow: none; }

.cr-promotion-contest-prizes__selected-prize-type {
  margin-bottom: 10px; }

.cr-promotion-contest-prizes__type-dropdown {
  max-width: initial !important; }

@media screen and (min-width: 1200px) and (max-width: 1599px) {
  .cr-promotion-contest-prizes__selected-prize-package {
    width: calc(50% - 10px); } }

.cr-promotion-contest-winners {
  margin-bottom: 84px; }
  .cr-promotion-contest-winners__title {
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 0; }
  .cr-promotion-contest-winners__winners-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    height: 91px; }
  .cr-promotion-contest-winners__information {
    display: flex;
    align-items: center; }
    .cr-promotion-contest-winners__information div {
      margin-left: 20px; }
    .cr-promotion-contest-winners__information span {
      font-size: 16px;
      color: #537a91; }
    .cr-promotion-contest-winners__information strong {
      font-weight: 800;
      font-size: 17px;
      color: #795afa;
      margin-left: 5px; }
  .cr-promotion-contest-winners .table tbody .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 40px;
    color: #000000; }
  .cr-promotion-contest-winners__small-text {
    display: block;
    font-size: 12px;
    color: #829fb1; }
  .cr-promotion-contest-winners__status {
    background: #ffffff;
    border: 1px solid rgba(194, 212, 224, 0.57);
    box-sizing: border-box;
    border-radius: 4px;
    height: 33px;
    padding: 0 15px;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #0f5589;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase; }
  .cr-promotion-contest-winners__prize-name {
    font-weight: bold;
    font-size: 14px;
    color: #334856; }
  .cr-promotion-contest-winners__date-time {
    font-size: 14px;
    color: #334856; }
  .cr-promotion-contest-winners__button-add-winner {
    margin-right: 0 !important; }
  .cr-promotion-contest-winners__winner-status {
    background: #ffffff;
    border: 1px solid rgba(194, 212, 224, 0.57);
    box-sizing: border-box;
    border-radius: 4px;
    width: 132px;
    height: 33px;
    font-weight: 800;
    text-transform: uppercase;
    display: inline-block;
    line-height: 33px; }
  .cr-promotion-contest-winners__winner-status-signed {
    color: #795afa; }
  .cr-promotion-contest-winners__winner-status-expired {
    color: #e40061; }
  .cr-promotion-contest-winners__winner-status-fulfilled {
    color: #73a703; }
  .cr-promotion-contest-winners__winner-status-selected {
    color: #a82ac0; }
  .cr-promotion-contest-winners__winner-status-unsigned {
    color: #829fb1; }
  .cr-promotion-contest-winners__winner-status-contacted {
    color: #0f5589; }
  .cr-promotion-contest-winners__winner-status-pending {
    color: #2b85ff; }
  .cr-promotion-contest-winners__winner-type {
    width: 54px;
    height: 51px;
    background-color: #edf3f7;
    border-radius: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
    margin: 0 8px; }
    .cr-promotion-contest-winners__winner-type.call-in {
      color: #795afa; }
  .cr-promotion-contest-winners__winner-sort-name {
    font-weight: 800;
    font-size: 19px;
    text-align: center;
    line-height: 24px; }
  .cr-promotion-contest-winners__winner-name {
    font-size: 7px;
    line-height: 9px;
    text-align: center; }
  .cr-promotion-contest-winners__winner-txt {
    color: #3db972; }
  .cr-promotion-contest-winners__winner-on {
    color: #32bad5; }
  .cr-promotion-contest-winners__winner-call {
    color: #795afa; }
  .cr-promotion-contest-winners__grand-winners-table {
    margin-bottom: 26px; }
  .cr-promotion-contest-winners__empty-winner-help {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #0f5589;
    padding-left: 20px; }
  .cr-promotion-contest-winners__no-winner-text {
    font-size: 16px;
    line-height: 20px;
    color: #ffffff; }
  .cr-promotion-contest-winners__grand-winners-empty-table .btn-radius span {
    margin-left: 20px;
    text-transform: initial; }
  .cr-promotion-contest-winners__grand-winners-empty-table .cr-promotion-contest-winners__no-winner-text span {
    text-transform: initial !important; }
  .cr-promotion-contest-winners .tab-content {
    z-index: initial !important;
    position: relative; }
  .cr-promotion-contest-winners .nav-link {
    font-size: 16px !important;
    min-width: 226px;
    text-align: center;
    display: inline-block !important;
    padding: 12px 6px !important;
    color: #657894 !important;
    border-top-right-radius: 6px !important;
    border-top-left-radius: 6px !important;
    border: none !important;
    text-transform: capitalize; }
    .cr-promotion-contest-winners .nav-link.active {
      font-weight: 700 !important;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      color: #657894 !important;
      position: relative; }
      .cr-promotion-contest-winners .nav-link.active::after {
        left: 0;
        content: "";
        display: block;
        background-color: #fff;
        width: 100%;
        height: 20px;
        position: absolute; }
  .cr-promotion-contest-winners__winners-table {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px; }
    .cr-promotion-contest-winners__winners-table .table {
      box-shadow: none;
      border-radius: 0 0 6px 6px; }

.cr-quick-view-calender .daypart-small-title {
  text-align: center;
  color: #795AFA;
  font-size: 10px; }

.cr-quick-view-calender .daypart-title {
  text-align: center;
  color: #0F5589;
  font-size: 16px;
  font-weight: bold; }

.cr-quick-view-calender__wrapper {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden; }

.cr-quick-view-calender .quick-view-item {
  position: absolute;
  background: #40A3FF;
  border-radius: 3px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;
  padding: 4px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  min-width: 30px; }
  .cr-quick-view-calender .quick-view-item .title {
    max-width: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .cr-quick-view-calender .quick-view-item .date {
    font-size: 11px;
    color: rgba(255, 255, 255, 0.6); }
  .cr-quick-view-calender .quick-view-item .overflow {
    display: flex; }
    .cr-quick-view-calender .quick-view-item .overflow .dot {
      margin-left: 2px;
      height: 4px;
      width: 4px;
      border-radius: 2px;
      background: rgba(255, 255, 255, 0.6); }
  .cr-quick-view-calender .quick-view-item.small-item {
    justify-content: center; }

.cr-quick-view-calender__tooltip {
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  opacity: 1;
  padding: 0; }
  .cr-quick-view-calender__tooltip p {
    margin-bottom: 14px; }
  .cr-quick-view-calender__tooltip .rc-tooltip-content {
    background: #FFFFFF;
    border-radius: 4px; }
  .cr-quick-view-calender__tooltip .rc-tooltip-arrow {
    border-top-color: #FFFFFF;
    border-width: 10px 10px 0;
    bottom: -8px; }
  .cr-quick-view-calender__tooltip .rc-tooltip-inner {
    padding: 0;
    background-color: #FFFFFF;
    box-shadow: none; }
  .cr-quick-view-calender__tooltip .tooltip-title {
    font-size: 16px;
    color: #334856;
    font-weight: bold; }
  .cr-quick-view-calender__tooltip .tooltip-type {
    font-size: 14px;
    color: #657894;
    display: flex;
    align-items: center; }
  .cr-quick-view-calender__tooltip .tooltip-type-icon {
    background-color: #40A3FF;
    border-radius: 3px;
    width: 14px;
    height: 14px;
    margin-right: 8px; }
  .cr-quick-view-calender__tooltip .tooltip-text {
    font-size: 14px;
    color: #334856;
    margin-bottom: 4px; }
  .cr-quick-view-calender__tooltip-content {
    position: relative;
    margin: 32px 20px 0 20px;
    padding-bottom: 50px; }
    .cr-quick-view-calender__tooltip-content .view-contest {
      display: none !important; }
    .cr-quick-view-calender__tooltip-content.show-view-contest .view-contest {
      position: absolute;
      right: 0;
      bottom: 20px;
      display: flex !important; }
  .cr-quick-view-calender__tooltip .view-contest {
    color: #795AFA;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer; }
    .cr-quick-view-calender__tooltip .view-contest svg {
      height: 12px;
      width: 18px;
      margin-right: 4px; }

.cr-quick-view-calender__month-wrapper {
  align-items: center;
  text-align: center;
  color: #334856;
  position: relative; }
  .cr-quick-view-calender__month-wrapper .month {
    font-weight: 800;
    font-size: 16px; }
  .cr-quick-view-calender__month-wrapper .year {
    font-size: 12px; }
  .cr-quick-view-calender__month-wrapper .month-arrow-right,
  .cr-quick-view-calender__month-wrapper .month-arrow-left {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
    .cr-quick-view-calender__month-wrapper .month-arrow-right svg,
    .cr-quick-view-calender__month-wrapper .month-arrow-left svg {
      width: 10px !important;
      height: 16px !important; }
  .cr-quick-view-calender__month-wrapper .month-arrow-left {
    left: 0; }
  .cr-quick-view-calender__month-wrapper .month-arrow-right {
    right: 0; }

.cr-quick-view-calender__btn-back, .cr-quick-view-calender__btn-next {
  border-color: transparent;
  background-color: transparent;
  cursor: pointer; }

.cr-quick-view-calender .day-part-row {
  position: relative; }

.cr-quick-view-calender .day-part-col {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  display: flex;
  justify-content: space-between;
  margin: 0 -2px;
  position: unset; }

.cr-quick-view-calender .day-part-divider {
  position: absolute;
  bottom: 0;
  right: -15px;
  height: 1px;
  background: rgba(194, 212, 224, 0.6);
  width: calc(100% - 8.33333%); }

.cr-quick-view-calender .month-week {
  background-color: #E7EFF4;
  flex: 1 1;
  margin: 0 2px; }

.cr-stations-list .create-station {
  border: 1px solid #c2d4e0; }

.cr-stations-list__list-padding {
  padding-left: 42px;
  padding-right: 32px; }

.cr-stations-list__list-left-padding {
  padding-left: 42px; }

.cr-stations-list .ReactTable.admin-table .rt-table .rt-tbody .rt-tr-group .rt-td {
  padding-left: 0;
  padding-right: 0;
  color: #000000;
  font-weight: bold;
  cursor: pointer; }
  .cr-stations-list .ReactTable.admin-table .rt-table .rt-tbody .rt-tr-group .rt-td:first-child {
    padding-left: 42px; }

.cr-stations-list__tooltip {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  opacity: 1;
  padding: 0; }
  .cr-stations-list__tooltip .rc-tooltip-content {
    background: #ffffff;
    padding: 24px;
    border-radius: 4px; }
  .cr-stations-list__tooltip .rc-tooltip-arrow {
    border-top-color: #ffffff;
    border-width: 10px 10px 0;
    bottom: -8px; }
  .cr-stations-list__tooltip .rc-tooltip-inner {
    padding: 0;
    background-color: #ffffff;
    box-shadow: none;
    min-height: auto; }

.cr-stations-list .cr-search-box {
  max-width: 395px;
  margin-left: auto; }

.product-string {
  color: #334856;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-transform: capitalize; }

.market-string {
  color: #000000;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-transform: capitalize; }

.text-caps {
  text-transform: uppercase; }

.rc-tooltip-content .product-string,
.rc-tooltip-content .market-string {
  white-space: initial !important;
  overflow: initial !important;
  word-break: break-all; }

.cr-order-text-timer {
  margin-top: 23px;
  margin-bottom: 35px; }
  .cr-order-text-timer--upload-file .btn-edit,
  .cr-order-text-timer--upload-file .btn-submit,
  .cr-order-text-timer--upload-file .btn-cancel {
    margin-top: 20px; }
  .cr-order-text-timer--upload-file .drop-area {
    max-width: 542px;
    height: 70px; }
  .cr-order-text-timer--bookend .cr-order-text-timer__script-upload-item-top {
    justify-content: space-between !important; }
  .cr-order-text-timer__script-upload-item {
    margin-bottom: 15px; }
  .cr-order-text-timer__part-upload-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #334856;
    margin-bottom: 2px; }
  .cr-order-text-timer .cr-file-view {
    max-width: 542px; }
  .cr-order-text-timer__script-upload-item-top {
    max-width: 542px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
  .cr-order-text-timer__spot-length {
    margin-bottom: 10px; }
    .cr-order-text-timer__spot-length span {
      color: #829fb1; }
    .cr-order-text-timer__spot-length strong {
      color: #000000; }

.cr-group-section-dropdown .react-select__group-heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #334856;
  margin-left: 69px;
  text-transform: initial;
  cursor: pointer; }

.cr-group-section-dropdown__group-heading {
  position: relative;
  height: 40px;
  display: flex;
  align-items: center; }

.cr-group-section-dropdown__group-heading-icon {
  position: absolute;
  left: 27px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  transition: rotate ease-out 0.2ms;
  cursor: pointer; }

.cr-group-section-dropdown .react-select__menu-list {
  padding: 16px 0; }

.cr-group-section-dropdown__group-heading-icon-active {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.cr-group-section-dropdown .cr-radio__text {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #829fb1;
  text-transform: uppercase;
  margin-left: 18px; }

.cr-group-section-dropdown__option-container {
  margin: 10px 0; }
  .cr-group-section-dropdown__option-container .react-select__option {
    padding-left: 69px; }

.cr-preview-script-modal {
  width: 1050px;
  max-width: 1050px; }
  .cr-preview-script-modal .modal-content {
    border-radius: 20px;
    overflow: hidden;
    border: none; }
  .cr-preview-script-modal .modal-header {
    height: 46px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #795afa;
    padding: 0 30px 0 40px;
    border-bottom: none; }
  .cr-preview-script-modal__contest {
    background-color: #3897d7 !important; }
  .cr-preview-script-modal .modal-title {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%; }
  .cr-preview-script-modal .modal-body {
    padding: 0; }
  .cr-preview-script-modal__search-icon {
    text-align: right;
    cursor: pointer; }
  .cr-preview-script-modal__content-script {
    padding: 45px 36px 26px 40px;
    position: relative; }
  .cr-preview-script-modal__section-top {
    background-color: #f4fafe;
    height: 86px; }
  .cr-preview-script-modal__section-top {
    display: flex;
    align-items: center;
    background-color: #f4fafe;
    padding: 0 36px;
    justify-content: flex-end; }
  .cr-preview-script-modal__slider-wrapper {
    display: flex;
    align-items: center; }
  .cr-preview-script-modal__label {
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: #43535c;
    margin-right: 10px; }
  .cr-preview-script-modal__slider .rc-slider {
    width: 224px;
    margin: 15px; }
  .cr-preview-script-modal__slider .rc-slider-track {
    background-color: #00a6e0;
    height: 8px; }
  .cr-preview-script-modal__slider .rc-slider-handle {
    border-color: #00a6e0;
    width: 25px;
    height: 25px;
    border-width: 4px;
    margin-right: 0 !important;
    margin-left: -12.5px;
    top: 1px; }
  .cr-preview-script-modal__slider .rc-slider-rail {
    background-color: #cedfe8;
    height: 8px; }
  .cr-preview-script-modal__minus, .cr-preview-script-modal__plus {
    width: 28px;
    height: 28px;
    border-radius: 50px;
    background-color: #00a6e0;
    color: #fff;
    display: flex;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    line-height: 24px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none; }
  .cr-preview-script-modal__slider {
    display: flex;
    align-items: center; }
  .cr-preview-script-modal__script-item {
    margin-bottom: 15px; }
  .cr-preview-script-modal__script-title {
    font-weight: bold;
    line-height: 20px;
    color: #334856; }
  .cr-preview-script-modal__script-head {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .cr-preview-script-modal__production-type {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    padding: 10px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background: #edf3f7;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 15px; }
  .cr-preview-script-modal .ad-types-title-spec {
    color: #6803cc; }
  .cr-preview-script-modal .ad-types-title-sold {
    color: #0f99a2; }
  .cr-preview-script-modal__type-icon {
    font-size: 19px;
    font-weight: bold;
    line-height: 15px; }
  .cr-preview-script-modal__type-text {
    font-size: 10px;
    text-transform: uppercase; }
  .cr-preview-script-modal--production .cr-preview-script-modal__section-top {
    justify-content: space-between; }
  .cr-preview-script-modal__length {
    text-transform: capitalize; }

.cr-associated-items__section-fields {
  padding-top: 28px; }
  .cr-associated-items__section-fields .form-group {
    margin-bottom: 27px; }

.cr-associated-items__contest-associated-fields {
  padding-bottom: 44px; }
  .cr-associated-items__contest-associated-fields .type {
    margin-left: 14px; }
  .cr-associated-items__contest-associated-fields .alert-danger {
    margin-top: 10px; }

.cr-associated-items__selected-associated-item {
  margin-bottom: 10px; }
  .cr-associated-items__selected-associated-item:last-child {
    margin-bottom: 0; }

.cr-associated-items__box-head-title {
  position: relative; }
  .cr-associated-items__box-head-title .menu-selection-icon {
    position: absolute;
    cursor: move;
    left: -31px; }

.cr-associated-items__selected-associated-item {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  height: 85px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 0; }

.cr-associated-items__associated-empty-text {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #795afa;
  padding: 0 43px; }

.cr-associated-items__trash {
  position: absolute;
  right: 26px;
  top: 4px;
  cursor: pointer; }

.cr-associated-items__text-cap {
  text-transform: capitalize; }

.cr-associated-items__static-input .cr-textinput__input-wrapper {
  pointer-events: none;
  border: none; }
  .cr-associated-items__static-input .cr-textinput__input-wrapper input {
    padding-left: 0; }

.cr-associated-items__contest-script-section-title {
  cursor: pointer; }

.cr-order-client-details {
  position: relative; }
  .cr-order-client-details__col-view-field {
    margin-bottom: 46px !important; }
  .cr-order-client-details__order-view-value {
    font-size: 16px;
    line-height: 20px;
    color: #000000; }
  .cr-order-client-details__col-field {
    margin-bottom: 27px !important; }
  .cr-order-client-details__col-reset {
    margin-bottom: 0 !important; }
  .cr-order-client-details__section-client-fields {
    margin-top: 23px;
    padding-bottom: 18px;
    margin-bottom: 35px;
    border-bottom: 1px solid #edf1f6; }
  .cr-order-client-details__edit-client-link {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #795afa;
    text-transform: capitalize;
    margin-bottom: 17px;
    cursor: pointer;
    display: inline-block; }

.cr-edit-client-modal {
  max-width: 1030px; }
  .cr-edit-client-modal .modal-body {
    padding: 0;
    position: relative; }
  .cr-edit-client-modal__close {
    border: none;
    cursor: pointer;
    position: absolute;
    background: transparent;
    right: 22px;
    top: 20px; }
    .cr-edit-client-modal__close:focus, .cr-edit-client-modal__close:active {
      box-shadow: none;
      outline: none; }
  .cr-edit-client-modal .cr-client-manager-form__box-client {
    box-shadow: none;
    border-radius: 0; }
  .cr-edit-client-modal .cr-client-manager-form__form-group-buttons {
    padding-bottom: 38px;
    margin-bottom: 0;
    padding-top: 35px;
    border-top: 1px solid #edf1f6; }
  .cr-edit-client-modal .cr-client-manager-form__box-client-contacts {
    margin-bottom: 0; }
  .cr-edit-client-modal .cr-client-manager-form__contact-button {
    padding-bottom: 37px; }
  .cr-edit-client-modal .cr-client-manager-form__section-contacts-title {
    border-bottom: none;
    border-top: 1px solid #edf1f6; }

.cr-schedule-settings-modal {
  max-width: 677px; }
  .cr-schedule-settings-modal .modal-title {
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856;
    text-transform: capitalize; }
  .cr-schedule-settings-modal .modal-header {
    border-bottom-color: #edf1f6;
    height: 82px;
    align-items: center;
    position: relative;
    padding-left: 46px; }
  .cr-schedule-settings-modal__close-icon {
    position: absolute;
    right: 25px;
    top: 26px; }
  .cr-schedule-settings-modal .modal-body {
    padding: 0;
    padding-top: 33px;
    padding-bottom: 30px; }
  .cr-schedule-settings-modal__padding {
    padding: 0 46px; }
  .cr-schedule-settings-modal .modal-footer {
    justify-content: center;
    padding: 30px;
    border-top-color: #edf1f6; }
  .cr-schedule-settings-modal__buttons {
    padding-top: 30px;
    border-top: 1px solid #edf1f6;
    margin-top: 51px;
    text-align: center; }
    .cr-schedule-settings-modal__buttons .btn {
      margin: 0 7.5px; }
  .cr-schedule-settings-modal__manage-title {
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856;
    text-transform: capitalize; }
  .cr-schedule-settings-modal__manage-help {
    font-size: 16px;
    line-height: 20px;
    color: #537a91;
    margin-bottom: 48px; }
  .cr-schedule-settings-modal__add-fill-items-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #334856;
    margin-bottom: 38px; }

.cr-copy-select-stations-modal {
  max-width: 562px; }
  .cr-copy-select-stations-modal__title {
    font-weight: 800;
    font-size: 40px;
    text-align: center;
    color: #000000;
    margin-bottom: 14px; }
  .cr-copy-select-stations-modal .cr-custom-multiselect-dropdown {
    max-width: 300px;
    margin: 0 auto; }
  .cr-copy-select-stations-modal .modal-footer {
    border-top: none;
    justify-content: center; }
  .cr-copy-select-stations-modal .modal-body {
    padding-top: 81px; }
  .cr-copy-select-stations-modal .modal-footer {
    padding-bottom: 83px; }

.cr-production-reschedule {
  background: #ffffff;
  padding: 18px 31px 19px 32px;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 18px; }
  .cr-production-reschedule__title {
    font-weight: bold;
    color: #e40061;
    text-transform: uppercase;
    font-size: 16px; }
  .cr-production-reschedule__description {
    margin-bottom: 0;
    color: #657894; }
  .cr-production-reschedule__info {
    display: flex;
    align-items: center;
    flex-direction: row; }
    .cr-production-reschedule__info strong {
      color: #334856;
      text-transform: uppercase;
      margin-right: 10px; }
  .cr-production-reschedule__original-date {
    margin-right: 20px; }
  .cr-production-reschedule .col,
  .cr-production-reschedule [class^="col-"] {
    margin-bottom: 0 !important; }
  .cr-production-reschedule .btn {
    min-width: 172px !important; }
  .cr-production-reschedule__buttons {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end; }
  .cr-production-reschedule__button-completed {
    color: #73A703  !important;
    font-weight: bold; }
    .cr-production-reschedule__button-completed:hover, .cr-production-reschedule__button-completed:focus {
      color: #73A703 !important;
      outline: none;
      box-shadow: none;
      border-color: #acc4d4; }

.cr-system-forms__forms-container {
  padding-left: 60px; }

.cr-system-forms__forms-head {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.cr-system-forms__views-text {
  font-size: 16px;
  line-height: 20px;
  color: #334856;
  text-transform: uppercase; }

.cr-system-forms__published-text {
  color: #829fb1; }

.cr-system-forms__fields-status {
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  margin-right: 50px;
  display: flex;
  align-items: center; }

.cr-system-forms__status-text {
  color: #73a703;
  margin-left: 5px; }
  .cr-system-forms__status-text span {
    margin-right: 5px; }

.cr-system-forms__status-text-missing {
  color: #f2994a; }

.cr-system-forms__active-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #334856; }

.cr-system-forms__forms-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 29px; }

.cr-system-forms__status-right {
  display: flex;
  justify-content: center; }

.cr-system-forms__missing-fields {
  padding-left: 25px;
  color: #f2994a;
  font-size: 13px;
  text-transform: capitalize; }
  .cr-system-forms__missing-fields li {
    list-style-type: disc; }

.cr-system-forms__selection-condition svg {
  cursor: pointer; }

.cr-system-forms__tooltip {
  opacity: 1; }
  .cr-system-forms__tooltip .rc-tooltip-inner {
    background-color: #fff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 10px 20px;
    max-width: 265px; }

.cr-system-forms__tooltip-text {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #334856;
  line-height: 18px;
  margin-bottom: 0; }

.cr-system-forms__selection-condition {
  display: flex; }

.cr-master-listener {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  overflow: hidden; }
  .cr-master-listener__head-container {
    height: 88px;
    padding-left: 24px;
    padding-right: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .cr-master-listener__total-fields {
    font-size: 23px;
    color: #657894; }
  .cr-master-listener__header-right {
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-master-listener__head-title {
    font-weight: 800;
    font-size: 23px;
    line-height: 29px;
    color: #111b2b;
    margin-left: 19px;
    text-transform: capitalize;
    margin-right: 10px; }
  .cr-master-listener__btn-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 54px;
    cursor: pointer; }
    .cr-master-listener__btn-preview span {
      margin-left: 10px;
      font-size: 14px;
      line-height: 40px;
      color: #795afa; }
  .cr-master-listener__button-down {
    border: none;
    outline: none;
    width: 19px;
    padding: 0;
    background: transparent;
    cursor: pointer; }
    .cr-master-listener__button-down img {
      max-width: 100%; }
    .cr-master-listener__button-down:focus, .cr-master-listener__button-down:active {
      outline: none; }
  .cr-master-listener__button-icon {
    color: #795afa;
    margin-right: 10px; }
  .cr-master-listener__button-down-icon {
    color: #795afa;
    font-size: 29px; }
  .cr-master-listener__button-up-icon {
    color: #c2d4e0;
    font-size: 29px; }
  .cr-master-listener__head-right {
    display: flex;
    align-items: center; }
  .cr-master-listener__form {
    border-top: 1px solid #edf1f6;
    background-color: #fff; }
  .cr-master-listener__form-name {
    padding-top: 32px;
    padding-bottom: 48px;
    padding-left: 70px;
    padding-right: 70px;
    margin-bottom: 29px;
    border-bottom: 1px solid #edf1f6; }
    .cr-master-listener__form-name label {
      line-height: 15px;
      color: #43535c;
      text-transform: uppercase;
      margin-bottom: 22px; }
  .cr-master-listener__form-name-title {
    line-height: 20px;
    text-transform: capitalize;
    color: #111b2b; }
  .cr-master-listener__first-last-name {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px;
    height: 78px;
    padding: 0 56px;
    display: flex;
    align-items: center;
    margin-top: 29px;
    margin-bottom: 25px; }
    .cr-master-listener__first-last-name span {
      color: #000000;
      text-transform: uppercase; }
  .cr-master-listener__form-fields {
    padding-left: 50px;
    padding-right: 45px; }
  .cr-master-listener__button-create {
    background: #ffffff;
    border: 1px solid #795afa;
    box-sizing: border-box;
    width: 161px;
    width: auto;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 44px;
    margin-top: 34px;
    margin-right: 14px; }
    .cr-master-listener__button-create span {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #795afa;
      text-transform: initial !important; }
  .cr-master-listener__form-buttons {
    padding-top: 37px;
    padding-bottom: 47px;
    padding-left: 46px;
    border-top: 1px solid #edf1f6; }
  .cr-master-listener .btn-submit {
    margin-right: 14px; }

.cr-system-form {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  margin: 20px 0;
  position: relative;
  margin-right: 70px; }
  .cr-system-form--open .cr-system-form__form::after {
    content: "";
    width: 3.5px;
    background-color: #dae8f1;
    display: block;
    height: calc(100% - 80px);
    position: absolute;
    left: -40px;
    top: 80px; }
  .cr-system-form--last .cr-system-form__form::after {
    display: none !important; }
  .cr-system-form__head-container {
    height: 88px;
    padding-left: 24px;
    padding-right: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .cr-system-form__total-fields {
    font-size: 23px;
    color: #657894; }
  .cr-system-form__header-right {
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-system-form__head-title {
    font-weight: 800;
    font-size: 23px;
    line-height: 29px;
    color: #111b2b;
    margin-left: 19px;
    text-transform: capitalize;
    margin-right: 10px; }
  .cr-system-form__btn-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
    cursor: pointer; }
  .cr-system-form__btn-clone {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
    cursor: pointer; }
  .cr-system-form__button-down {
    border: none;
    outline: none;
    width: 19px;
    padding: 0;
    background: transparent;
    cursor: pointer; }
    .cr-system-form__button-down img {
      max-width: 100%; }
    .cr-system-form__button-down:focus, .cr-system-form__button-down:active {
      outline: none; }
  .cr-system-form__button-icon {
    color: #795afa;
    margin-right: 10px; }
  .cr-system-form__button-down-icon {
    color: #795afa;
    font-size: 29px; }
  .cr-system-form__button-up-icon {
    color: #c2d4e0;
    font-size: 29px; }
  .cr-system-form__head-right {
    display: flex;
    align-items: center; }
    .cr-system-form__head-right .cr-checkbox {
      margin-right: 33px; }
  .cr-system-form__form {
    border-top: 1px solid #edf1f6;
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px; }
  .cr-system-form__form-name {
    padding-top: 32px;
    padding-bottom: 48px;
    padding-left: 50px;
    padding-right: 45px;
    border-bottom: 1px solid #edf1f6;
    margin-bottom: 29px; }
    .cr-system-form__form-name label {
      line-height: 15px;
      color: #43535c;
      text-transform: uppercase;
      margin-bottom: 22px; }
  .cr-system-form__form-name-title {
    line-height: 20px;
    text-transform: capitalize;
    color: #111b2b; }
  .cr-system-form__first-last-name {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px;
    height: 78px;
    padding: 0 56px;
    padding-right: 40px;
    align-items: center;
    margin-top: 29px;
    margin-bottom: 25px;
    color: #000000;
    line-height: 78px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between; }
  .cr-system-form__col-reset {
    margin-bottom: 0; }
  .cr-system-form__form-fields {
    padding-left: 50px;
    padding-right: 45px; }
  .cr-system-form__button-create {
    background: #ffffff;
    border: 1px solid #795afa;
    box-sizing: border-box;
    width: 161px;
    width: auto;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 44px;
    margin-top: 34px;
    margin-right: 14px; }
    .cr-system-form__button-create span {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #795afa;
      text-transform: initial !important; }
  .cr-system-form__form-buttons {
    padding-top: 37px;
    padding-bottom: 47px;
    padding-left: 46px;
    border-top: 1px solid #edf1f6; }
  .cr-system-form .btn-submit {
    margin-right: 14px; }
  .cr-system-form__arrow-icon {
    position: absolute;
    left: -40px;
    top: -30px; }
  .cr-system-form__head-form-type {
    text-transform: capitalize; }
  .cr-system-form__system-disabled .cr-system-form__head-title {
    color: #829fb1; }
  .cr-system-form__system-disabled .cr-system-form__head-form-type {
    color: #829fb1; }
  .cr-system-form__system-form-switch {
    position: absolute;
    top: 30px;
    right: -75px; }
  .cr-system-form__required-text {
    font-size: 17px;
    line-height: 21px;
    color: #537a91 !important;
    margin-left: 156px; }

.cr-system-form-preview-modal--desktop {
  max-width: 1140px;
  width: 1140px; }

.cr-system-form-preview-modal--mobile {
  max-width: 376px;
  width: 376px; }

.cr-system-form-preview-modal .modal-content {
  border: none; }

.cr-system-form-preview-modal .modal-header {
  background-color: #0f5589;
  height: 67px;
  position: relative;
  border-bottom: none;
  align-items: center;
  justify-content: center; }

.cr-system-form-preview-modal .modal-body {
  padding: 0; }

.cr-system-form-preview-modal .modal-title {
  font-weight: bold;
  font-size: 23px;
  line-height: 29px;
  text-align: center;
  color: #ffffff; }

.cr-system-form-preview-modal__actions {
  position: absolute;
  right: 12.5px;
  top: 22px;
  display: flex;
  align-items: center; }

.cr-system-form-preview-modal__btn-action {
  margin: 0 12.5px;
  cursor: pointer; }

.cr-system-form-preview-modal__body-desktop .cr-preview-system-form__form-fulfillment,
.cr-system-form-preview-modal__body-desktop .cr-preview-system-form__station-description,
.cr-system-form-preview-modal__body-desktop .cr-preview-system-form__station-title {
  background-color: #f7fbfd;
  padding-left: 72px;
  padding-right: 72px; }

.cr-system-form-preview-modal__body-desktop .cr-preview-system-form__station-title {
  padding-top: 28px;
  padding-bottom: 15px; }

.cr-system-form-preview-modal__body-desktop .cr-preview-system-form__station-section {
  padding-bottom: 45px; }

.cr-system-form-preview-modal__body-desktop .cr-preview-system-form__fulfillment-buttons {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 59px; }

.cr-system-form-preview-modal__body-desktop .cr-preview-system-form__fulfillment-fields {
  padding: 0 40px;
  padding-top: 45px;
  position: relative; }
  .cr-system-form-preview-modal__body-desktop .cr-preview-system-form__fulfillment-fields .cr-form-builder-fields__form-field-separator-saved::before {
    width: calc(100% + 80px);
    left: -40px;
    right: -40px; }

.cr-system-form-preview-modal__body-mobile .cr-preview-system-form__station-title {
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 20px;
  padding-bottom: 10px; }

.cr-system-form-preview-modal__body-mobile .cr-preview-system-form__station-description {
  padding-left: 35px;
  padding-right: 35px; }

.cr-system-form-preview-modal__body-mobile .cr-preview-system-form__station-section {
  padding-bottom: 25px; }

.cr-system-form-preview-modal__body-mobile .cr-preview-system-form__form-fulfillment {
  padding-left: 14px;
  padding-right: 14px; }

.cr-system-form-preview-modal__body-mobile .cr-preview-system-form__fulfillment-fields {
  padding-left: 23px;
  padding-right: 23px;
  padding-top: 20px;
  position: relative; }
  .cr-system-form-preview-modal__body-mobile .cr-preview-system-form__fulfillment-fields .cr-form-builder-fields__form-field-separator-saved::before {
    width: calc(100% + 46px);
    left: -23px;
    right: -23px; }

.cr-system-form-preview-modal__body-mobile .cr-preview-system-form__fulfillment-buttons {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 40px; }

.cr-preview-system-form__title-green {
  height: 88px;
  padding: 0 55px;
  background: #73a703;
  display: flex;
  align-items: center; }
  .cr-preview-system-form__title-green span {
    font-weight: 800;
    font-size: 26px;
    color: #ffffff; }

.cr-preview-system-form__head-description {
  height: 100px;
  padding: 0 56px;
  background: #e9f1f8;
  display: flex;
  align-items: center; }
  .cr-preview-system-form__head-description span {
    font-weight: bold;
    font-size: 16px;
    color: #000000; }

.cr-preview-system-form__custom-console-winner-fields {
  margin-top: 45px;
  padding: 0 55px; }

.cr-preview-system-form__custom-fields {
  margin-top: 45px;
  padding: 0 55px;
  position: relative; }

.cr-preview-system-form__form .cr-textinput__input-field input::-webkit-input-placeholder, .cr-preview-system-form__form .cr-textinput__input-field textarea::-webkit-input-placeholder, .cr-preview-system-form__form .react-datepicker__input-container input::-webkit-input-placeholder, .cr-preview-system-form__form .react-datepicker__input-container textarea::-webkit-input-placeholder {
  text-transform: capitalize !important; }

.cr-preview-system-form__form .cr-textinput__input-field input::placeholder,
.cr-preview-system-form__form .cr-textinput__input-field textarea::placeholder,
.cr-preview-system-form__form .react-datepicker__input-container input::placeholder,
.cr-preview-system-form__form .react-datepicker__input-container textarea::placeholder {
  text-transform: capitalize !important; }

.cr-preview-system-form__form .react-select__placeholder,
.cr-preview-system-form__form .cr-upload-files__drop-text {
  text-transform: initial !important; }

.cr-preview-system-form__console-winner-buttons {
  display: flex;
  align-items: center;
  padding-top: 47px;
  padding-bottom: 47px;
  justify-content: center; }
  .cr-preview-system-form__console-winner-buttons .btn {
    margin: 0 7.5px; }

.cr-preview-system-form__common-buttons {
  background: rgba(194, 212, 224, 0.5); }

.cr-preview-system-form .btn-disabled {
  background-color: #bccedb;
  border-color: #bccedb; }

.cr-preview-system-form .cr-form-builder-fields label:not(.form-check-label) {
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #537a91;
  text-transform: uppercase;
  display: block; }

.cr-preview-system-form .cr-form-builder-fields .cr-textinput__input-field input {
  text-transform: inherit !important; }

.cr-preview-system-form .cr-form-builder-fields .cr-upload-files__drop-area {
  height: 63px !important; }

.cr-preview-system-form__common-buttons {
  padding-top: 27px;
  padding-bottom: 28px;
  padding-left: 55px; }

.cr-preview-system-form__form-name {
  font-weight: 800;
  font-size: 23px;
  line-height: 29px;
  color: #334856;
  border-bottom: 1px solid #edf1f6;
  padding-top: 37px;
  padding-bottom: 24px;
  padding-left: 55px;
  padding-right: 55px; }

.cr-preview-system-form__station-title {
  font-weight: 800;
  font-size: 21px;
  line-height: 26px;
  text-align: center;
  color: #334856; }

.cr-preview-system-form__station-description {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #334856; }

.cr-preview-system-form__script-description * {
  font-family: inherit !important; }

.cr-preview-system-form__script-description .tag {
  background-color: transparent !important;
  color: #334856 !important;
  border: none !important;
  padding: 0 !important;
  font-size: 16px !important; }
  .cr-preview-system-form__script-description .tag::before {
    content: "[";
    display: inline-block; }
  .cr-preview-system-form__script-description .tag::after {
    content: "]";
    display: inline-block; }

.cr-preview-system-form__fulfillment-fields {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px; }

.cr-preview-system-form__station-image {
  padding: 20px 0;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  text-align: center;
  min-height: 132px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .cr-preview-system-form__station-image img {
    margin: 0 auto; }

.cr-preview-system-form__station-section {
  background-color: #f7fbfd; }
  .cr-preview-system-form__station-section .cr-jock-console__station-logo {
    margin: 0 auto; }

.cr-station-settings-form .form-group {
  margin-bottom: 34px; }

.cr-station-settings-form__form-title {
  font-weight: 800;
  font-size: 23px;
  color: #111b2b;
  height: 70px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #edf1f6; }

.cr-station-settings-form__form-inactive .cr-station-settings-form__form-title {
  color: #829fb1; }

.cr-station-settings-form__station-wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px; }

.cr-station-settings-form__title {
  font-weight: 800;
  font-size: 23px;
  line-height: 29px;
  color: #111b2b;
  text-transform: capitalize; }

.cr-station-settings-form__form-padding {
  padding: 0 40px; }

.cr-station-settings-form__station-wrapper {
  padding-top: 40px; }

.cr-station-settings-form__listener-geography .cr-station-settings-form__title {
  margin-bottom: 25px; }

.cr-station-settings-form__listener-geography textarea {
  min-height: 118px; }

.cr-station-settings-form__col-reset {
  margin-bottom: 0 !important; }

.cr-station-settings-form label {
  letter-spacing: 0.05em;
  color: #43535c;
  text-transform: uppercase; }

.cr-station-settings-form__selected-input-checkbox {
  background: #ffffff;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 6px;
  height: 50px;
  display: flex;
  align-items: center; }

.cr-station-settings-form__selected-checkbox {
  background: #224b8b;
  width: 23.84px;
  height: 23.12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 17px;
  margin-right: 14px; }

.cr-station-settings-form__selected-value {
  color: #657894; }

.cr-station-settings-form .cr-toggle-switch span {
  text-transform: uppercase;
  font-size: 17px;
  color: #829fb1;
  font-weight: 400; }

.cr-station-settings-form .cr-toggle-switch .active span {
  font-weight: 700 !important;
  color: #374c59 !important; }

.cr-station-settings-form__limit-text {
  color: #000000;
  margin-bottom: 16px; }

.cr-station-settings-form__listener-statement {
  padding-bottom: 50px; }

.cr-station-settings-form__listener-statement-text {
  font-weight: 700;
  font-size: 18px;
  color: #537a91; }

.cr-station-settings-form__range-inputs {
  display: flex;
  align-items: center; }
  .cr-station-settings-form__range-inputs .cr-textinput {
    width: 124px; }

.cr-station-settings-form__range-divider {
  margin: 0 10px; }

.cr-station-settings-form .btn-range-submit {
  margin-top: 21px; }

.cr-station-settings-form__contest-eligibility-rules {
  border-top: 1px solid #edf1f6;
  padding-top: 24px;
  padding-bottom: 37px; }

.cr-station-settings-form__contest-helper-text {
  font-size: 18px;
  line-height: 23px;
  color: #657894; }

.cr-station-settings-form__contest-head {
  margin-bottom: 34px; }

.cr-station-settings-form__field-helper-text {
  line-height: 20px;
  margin-bottom: 11px;
  color: #000000;
  display: block; }

.cr-station-settings-form .cr-radio-buttons__radios {
  margin-top: 15px; }

.cr-station-settings-form__copy-stations {
  border-top: 1px solid #edf1f6;
  padding-top: 35px;
  padding-bottom: 47px; }
  .cr-station-settings-form__copy-stations .cr-custom-multiselect-dropdown__value-container {
    color: #829fb1; }
  .cr-station-settings-form__copy-stations .react-select__value-container--has-value .cr-custom-multiselect-dropdown__value-container {
    color: #334856; }

.cr-station-settings-form__copy-stations-title {
  line-height: 20px;
  color: #000000;
  margin-bottom: 21px;
  font-weight: 700; }

.cr-station-settings-form__selected-stations {
  display: flex;
  align-items: center;
  margin-top: 27px;
  flex-wrap: wrap; }

.cr-station-settings-form__station {
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 4px;
  height: 42px;
  margin-right: 10px;
  color: #657894;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 0 14px;
  margin-bottom: 10px; }

.cr-station-settings-form__remove-tag {
  margin-right: 14px;
  cursor: pointer; }

.cr-station-settings-form__form-buttons {
  margin-top: 33px;
  padding: 0 40px; }
  .cr-station-settings-form__form-buttons .btn-outline-blue {
    margin-left: 14px; }

.cr-station-settings-form .cr-radio-buttons__label {
  text-transform: capitalize; }

.cr-station-settings-form .cr-radio-buttons__radios {
  display: flex; }

.cr-station-settings-form__button-audience {
  width: 255px !important;
  justify-content: space-around;
  display: flex;
  align-items: center; }
  .cr-station-settings-form__button-audience span {
    text-transform: capitalize;
    font-weight: 800;
    font-size: 19px;
    line-height: 24px;
    text-align: center;
    color: #334856; }

.cr-station-settings-form__station-w9-form .cr-station-settings-form__station-wrapper {
  padding-top: 0; }

.cr-station-settings-form__session-title {
  margin-top: 31px;
  margin-bottom: 16px;
  color: #000000;
  font-weight: 700; }

.cr-station-settings-form__winning-help-text {
  color: #657894;
  margin-top: 30px; }

.cr-station-settings-form__checkboxes .cr-checkbox:first-child {
  margin-bottom: 23px; }

.cr-station-settings-form__checkbox-year {
  margin-bottom: 20px; }

.cr-station-settings-form__fulfillment-help-text {
  color: #334856;
  font-size: 18px;
  padding-left: 40px;
  padding-bottom: 36px; }

.cr-station-settings-form__fulfillment-automation {
  padding-right: 70px; }

.cr-station-settings-form__active-text {
  height: 27px;
  color: #334856;
  font-weight: 700;
  text-align: right;
  width: calc(100% + 70px); }

.cr-station-settings-form__station-fulfillment-form .cr-station-settings-form__station-wrapper {
  padding-top: 0;
  margin-bottom: 20px; }

.cr-station-settings-form__station-fulfillment-form .cr-station-settings-form__form-buttons {
  margin-top: 0;
  padding-bottom: 40px; }

.cr-station-settings-form__select-days {
  padding-right: 40px; }
  .cr-station-settings-form__select-days label {
    text-transform: uppercase;
    color: #43535c; }
  .cr-station-settings-form__select-days .react-select-container {
    max-width: 365px; }

.cr-station-settings-form__form-head {
  position: relative; }
  .cr-station-settings-form__form-head .cr-station-settings-form__form-title {
    border-bottom: 0;
    height: 88px; }
  .cr-station-settings-form__form-head .cr-toggle-switch {
    position: absolute;
    right: -80px;
    top: 32px; }

.cr-station-settings-form__form-actions {
  position: absolute;
  display: flex;
  top: 33px;
  right: 40px; }

.cr-station-settings-form__btn-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
  cursor: pointer; }

.cr-station-settings-form__button-down {
  border: none;
  outline: none;
  width: 24px;
  padding: 0;
  background: transparent;
  cursor: pointer; }
  .cr-station-settings-form__button-down img {
    max-width: 100%; }
  .cr-station-settings-form__button-down:focus, .cr-station-settings-form__button-down:active {
    outline: none; }

.cr-station-settings-form__button-icon {
  color: #795afa;
  margin-right: 10px; }

.cr-station-settings-form__button-down-icon {
  color: #795afa;
  font-size: 29px; }

.cr-station-settings-form__button-up-icon {
  color: #c2d4e0;
  font-size: 29px; }

.cr-station-settings-form__form-opened .cr-station-settings-form__form-head {
  border-bottom: 1px solid #edf1f6; }

.cr-station-settings-form__selection-box {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 20px; }

.cr-station-settings-form__selection-box-text {
  background-color: #f7fbfd;
  margin-left: 20px; }

.cr-station-settings-form .cr-selection-dropdown {
  margin: 0 5px; }

.cr-station-settings-form__step {
  padding-left: 40px;
  position: relative; }

.cr-station-settings-form__arrow {
  position: absolute;
  top: -35px;
  left: -10px; }

.cr-station-settings-form__step-middle::after {
  content: "";
  display: block;
  width: 3.5px;
  background-color: #dae8f1;
  border: 0.5px solid #dae8f1;
  height: 100%;
  position: absolute;
  top: 0;
  left: -10px; }

.cr-station-settings-form__step-start::after {
  content: "";
  display: block;
  width: 3.5px;
  background-color: #dae8f1;
  border: 0.5px solid #dae8f1;
  height: 100%;
  position: absolute;
  top: 50px;
  left: -10px; }

.cr-station-settings-form__start-text {
  color: #657894;
  font-size: 12px;
  text-transform: uppercase;
  position: absolute;
  left: -27px;
  top: 25px; }

.cr-station-settings-form__steps-padding {
  padding: 40px 50px 50px 50px; }

.cr-station-settings-form__step-status {
  font-weight: 700;
  font-size: 18px;
  color: #e40061;
  padding-left: 20px;
  margin-top: 50px;
  position: relative; }
  .cr-station-settings-form__step-status::after {
    content: "";
    display: block;
    width: 3.5px;
    background-color: #dae8f1;
    border: 0.5px solid #dae8f1;
    height: 49px;
    position: absolute;
    top: -60px;
    left: -10px; }

.cr-station-settings-form__icon-status {
  position: absolute;
  left: -20px; }

.cr-station-settings-form__step-completed {
  color: #73a703; }

.cr-station-settings-form__step-help-text {
  color: #111b2b;
  font-size: 18px;
  padding: 0 40px;
  margin: 23px 0; }

.cr-station-settings-form__btn-disabled {
  cursor: not-allowed;
  opacity: 0.6; }

.cr-station-settings-form__copy-stations-modal {
  max-width: 750px !important; }

@media (max-width: 1436px) {
  .cr-station-settings-form__high-radios .cr-radio-buttons__radios {
    display: block;
    margin-top: 0; } }

.cr-station-settings-form .bottom-actions-sticky .btn-blue {
  margin-right: 14px; }

@media (min-width: 1599px) {
  .cr-station-settings-form .bottom-actions-sticky .buttons {
    padding-left: 70px;
    padding-right: 70px; } }

.cr-winner-fulfillment .form-group {
  margin-bottom: 27px; }

.cr-winner-fulfillment__title {
  text-transform: capitalize; }

.cr-winner-fulfillment__fields {
  padding-top: 25px;
  padding-bottom: 18px; }

.cr-winner-fulfillment__buttons {
  padding-left: 49px;
  padding-right: 49px;
  padding-bottom: 41px;
  padding-top: 29px;
  border-top: 1px solid #edf1f6; }

.cr-winner-fulfillment__btn-preview {
  display: flex;
  align-items: center;
  cursor: pointer; }
  .cr-winner-fulfillment__btn-preview span {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #795afa;
    text-transform: uppercase;
    margin-left: 10px; }

.cr-winner-fulfillment__upload-description {
  font-size: 14px;
  color: #657894;
  max-width: 200px;
  display: block; }

.cr-winner-fulfillment__introduction-field .cr-textarea-evaluator__title-wrapper,
.cr-winner-fulfillment__introduction-field .cr-textarea-evaluator__wrapper-top-length {
  display: none; }

.cr-winner-fulfillment__introduction-field .public-DraftEditorPlaceholder-inner {
  color: #c2d4e0; }

.cr-winner-fulfillment__introduction-field-empty .RichEditor-set-as-default {
  cursor: not-allowed;
  opacity: 0.9; }

.cr-winner-fulfillment__option-custom {
  display: flex;
  align-items: center;
  margin-bottom: 17px; }

.cr-winner-fulfillment__introduction-label {
  margin-bottom: 0; }

.cr-winner-fulfillment__button-create {
  text-transform: inherit !important;
  margin-right: 27px; }

.cr-winner-fulfillment__button-icon {
  margin-right: 14px; }

.cr-winner-fulfillment__optional-text {
  font-size: 14px;
  line-height: 18px;
  color: #657894;
  max-width: 242px;
  display: inline-block; }

.cr-winner-fulfillment .cr-form-builder-fields__form-field-group-helper-text {
  background-color: #fff;
  padding-bottom: 5px; }
  .cr-winner-fulfillment .cr-form-builder-fields__form-field-group-helper-text .cr-textinput__input-wrapper {
    height: 39px; }

.cr-winner-fulfillment .cr-form-builder-fields__input-label {
  max-width: 100%; }

.cr-winner-fulfillment .cr-preview-system-form__station-description .tag {
  background: transparent !important; }

.cr-winner-notification__section-title {
  border-bottom: none;
  padding: 0 35px; }

.cr-winner-notification__section-fields {
  padding: 0; }

.cr-winner-notification__total-table {
  margin-bottom: 20px; }
  .cr-winner-notification__total-table thead {
    background: #f7fbfd; }
    .cr-winner-notification__total-table thead th {
      border-bottom: 1px solid #edf1f6;
      border-top: 1px solid #edf1f6;
      padding: 4px; }
      .cr-winner-notification__total-table thead th span {
        font-weight: 600;
        font-size: 12px;
        color: #829fb1;
        text-transform: uppercase; }

.cr-winner-notification__total-section {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding-left: 30px; }
  .cr-winner-notification__total-section strong {
    font-weight: 700;
    color: #334856; }

.cr-winner-notification__total-number {
  font-size: 32px;
  color: #795afa; }

.cr-winner-notification__btn-new-notification span {
  margin-left: 10px;
  text-transform: capitalize !important;
  font-weight: 700; }

.cr-winner-notification__notification-progress {
  margin-bottom: 9px;
  display: flex;
  align-items: center; }

.cr-winner-notification__progress-title {
  margin-right: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 185px; }
  .cr-winner-notification__progress-title h3 {
    font-weight: 600;
    font-size: 14px;
    color: #334856;
    margin-bottom: 0; }
  .cr-winner-notification__progress-title span {
    margin-left: 15px;
    color: #795afa;
    font-weight: 700; }

.cr-winner-notification__progress-bar {
  width: 338px;
  height: 19px;
  position: relative;
  background-color: #c2d4e0; }

.cr-winner-notification__progress-value {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 19px; }

.cr-winner-notification__list-progress {
  display: inline-block;
  padding-top: 20px; }

.cr-winner-notification__column-winner {
  width: 20%;
  padding-left: 40px !important; }

.cr-winner-notification__column-completed {
  width: 50%; }

.cr-winner-notification__column-actions {
  width: 30%; }

.cr-winner-notification__column-notification, .cr-winner-notification__td-notification {
  width: 35%;
  padding-left: 40px !important; }

.cr-winner-notification__notification-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%; }

.cr-winner-notification__column-creator, .cr-winner-notification__td-creator {
  width: 20%; }

.cr-winner-notification__column-status, .cr-winner-notification__td-status {
  width: 20%; }
  .cr-winner-notification__column-status .cr-winner-notification__item-sortable, .cr-winner-notification__td-status .cr-winner-notification__item-sortable {
    text-align: center;
    justify-content: center; }

.cr-winner-notification__column-success, .cr-winner-notification__column-notification-actions, .cr-winner-notification__td-success {
  width: 12.5%; }

.cr-winner-notification__column-notification-actions {
  text-align: center; }

.cr-winner-notification__notification-table {
  background: #224b8b; }
  .cr-winner-notification__notification-table thead {
    display: table;
    table-layout: fixed;
    width: calc(100% - 8px); }
    .cr-winner-notification__notification-table thead th {
      border: none;
      padding-top: 8px;
      padding-bottom: 8px; }
      .cr-winner-notification__notification-table thead th span {
        color: #fff; }
  .cr-winner-notification__notification-table td {
    border-bottom-color: #edf1f6; }
  .cr-winner-notification__notification-table tbody.scroll-bar-style {
    display: block;
    max-height: 300px;
    overflow-y: auto;
    background-color: #fff; }
    .cr-winner-notification__notification-table tbody.scroll-bar-style tr {
      display: table;
      table-layout: fixed;
      width: 100%; }

.cr-winner-notification__empty-notifications {
  font-size: 18px;
  line-height: 23px;
  color: #537a91;
  padding: 30px 0 30px 28px; }

.cr-winner-notification__item-sortable {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer; }

.cr-winner-notification__sortable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  text-align: center; }
  .cr-winner-notification__sortable:hover {
    border-radius: 100px;
    background-image: linear-gradient(to bottom, #6f96d1, #5f85c0, #4f75b0, #3f659f, #2e558f); }

.cr-winner-notification__sortable-icon {
  position: absolute;
  font-size: 16px;
  color: #fff; }

.cr-winner-notification__sortable-active {
  color: #c12674; }

.cr-winner-notification__notification-title {
  color: #000000;
  font-weight: 700;
  display: block; }

.cr-winner-notification__template-text {
  color: #829fb1;
  font-size: 12px; }

.cr-winner-notification__creator-text {
  color: #334856;
  font-size: 14px; }

.cr-winner-notification__notification-status {
  background: #ffffff;
  border: 1px solid rgba(194, 212, 224, 0.57);
  border-radius: 4px;
  width: 128px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 14px; }

.cr-winner-notification__timer-text {
  color: #657894;
  font-size: 11px; }

.cr-winner-notification__table-buttons {
  display: flex;
  align-items: center;
  justify-content: center; }

.cr-winner-notification__edit-action, .cr-winner-notification__view-action {
  cursor: pointer;
  width: 40px;
  display: inline-block; }

.cr-winner-notification__success-sent strong {
  color: #334856;
  font-weight: 700;
  font-size: 14px;
  margin-right: 15px; }

.cr-winner-notification__success-sent span {
  font-size: 14px;
  color: #657894; }

.cr-winner-notification__success-opens strong {
  color: #73a703;
  font-weight: 700;
  font-size: 14px;
  margin-right: 15px; }

.cr-winner-notification__success-opens span {
  font-size: 14px;
  color: #657894; }

.cr-winner-notification__notification-draft {
  color: #bccedb; }

.cr-winner-notification__notification-scheduled {
  color: #795afa; }

.cr-winner-notification__notification-sent .cr-winner-notification__notification-status {
  color: #fff;
  background: #73a703;
  border-color: #73a703;
  text-transform: uppercase; }

.cr-winner-notification__notification-automatic {
  color: #2b85ff; }
  .cr-winner-notification__notification-automatic .cr-winner-notification__creator-text {
    color: #829fb1;
    text-transform: uppercase; }

.cr-winner-notification__sent-icon {
  display: inherit;
  margin-right: 10px; }

.cr-export-modal {
  min-width: 573px !important; }
  .cr-export-modal .modal-header {
    justify-content: center;
    border-bottom: 0;
    padding-top: 40px;
    padding-bottom: 20px; }
  .cr-export-modal .modal-title {
    font-weight: 800;
    font-size: 40px;
    text-align: center;
    color: #000000; }
  .cr-export-modal .close {
    display: none; }
  .cr-export-modal .cr-checkbox__text {
    line-height: 20px;
    color: #000000; }
  .cr-export-modal__fields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 380px;
    margin: 0 auto; }
    .cr-export-modal__fields .cr-checkbox {
      width: 50%;
      margin: 5px 0; }
  .cr-export-modal__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0; }
    .cr-export-modal__buttons .btn {
      margin: 0 7px; }
  .cr-export-modal__error {
    text-align: center; }
  .cr-export-modal .cr-toggle-switch {
    justify-content: center;
    margin-bottom: 30px; }
    .cr-export-modal .cr-toggle-switch .cr-toggle-switch__left-component,
    .cr-export-modal .cr-toggle-switch .cr-toggle-switch__right-component {
      text-transform: uppercase;
      color: #829fb1;
      font-weight: 400; }
      .cr-export-modal .cr-toggle-switch .cr-toggle-switch__left-component.active,
      .cr-export-modal .cr-toggle-switch .cr-toggle-switch__right-component.active {
        color: #374c59;
        font-weight: 700; }

.cr-save-search-modal {
  min-width: 573px !important; }
  .cr-save-search-modal .modal-header {
    padding-left: 47px;
    padding-right: 47px;
    border-bottom: 1px solid #edf1f6;
    position: relative; }
  .cr-save-search-modal .modal-body {
    padding: 0; }
  .cr-save-search-modal .modal-title {
    font-weight: 700;
    font-size: 23px;
    color: #334856;
    text-transform: capitalize; }
  .cr-save-search-modal .close {
    display: none; }
  .cr-save-search-modal__buttons {
    display: flex;
    align-items: center;
    padding: 4px 0 0 0; }
    .cr-save-search-modal__buttons .btn-primary {
      margin-right: 14px; }
  .cr-save-search-modal__close-button {
    position: absolute;
    right: 24px;
    cursor: pointer; }
  .cr-save-search-modal form {
    padding-left: 47px;
    padding-right: 50px;
    padding-top: 30px;
    padding-bottom: 47px; }
  .cr-save-search-modal label {
    color: #334856;
    text-transform: uppercase; }
  .cr-save-search-modal__saved-searches {
    border-top: 1px solid #edf1f6;
    padding-top: 29px;
    padding-bottom: 40px; }
  .cr-save-search-modal__saved-title {
    color: #000000;
    font-weight: 700;
    text-transform: capitalize;
    padding-left: 47px;
    padding-right: 42px; }
  .cr-save-search-modal__saved-searches-list {
    margin-top: 10px;
    max-height: 92px;
    overflow: auto; }
  .cr-save-search-modal__search-item {
    color: #000;
    display: block;
    margin: 10px 0;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 36px;
    padding-left: 47px;
    padding-right: 42px; }
    .cr-save-search-modal__search-item:hover {
      background: rgba(194, 212, 224, 0.25); }
  .cr-save-search-modal__search-item-title {
    cursor: pointer;
    flex: 1 1; }
  .cr-save-search-modal__delete-search {
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    align-items: center; }

.cr-review-history {
  margin-top: 20px; }
  .cr-review-history__field-name {
    text-transform: uppercase !important;
    color: #43535c !important;
    margin-bottom: 13px; }
  .cr-review-history__review-history-title {
    border-bottom: none !important; }
  .cr-review-history__section-fields {
    padding: 0 !important;
    margin-top: 0  !important;
    display: flex;
    flex-direction: column-reverse; }
  .cr-review-history__box-action, .cr-review-history__box-head-title {
    cursor: pointer; }
  .cr-review-history__fields-status-content {
    display: flex;
    align-items: center; }
    .cr-review-history__fields-status-content .cr-file-view {
      flex: 1 1;
      margin-left: 10px;
      margin-bottom: 0; }
    .cr-review-history__fields-status-content .cr-file-view__file-item {
      margin-bottom: 0; }
  .cr-review-history__review-history-field {
    margin-bottom: 23px; }
  .cr-review-history__timer {
    display: flex;
    color: #657894;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    height: 100%;
    justify-content: center;
    flex-direction: column; }
    .cr-review-history__timer span {
      margin-right: 4px; }
  .cr-review-history__fields-status-item {
    margin-bottom: 10px; }
  .cr-review-history__review-version {
    padding: 27px 47.76px;
    border-top: 1px solid #edf1f6; }
  .cr-review-history__version-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #334856;
    text-transform: capitalize;
    margin-bottom: 16px; }

.cr-system-template-form .cr-textinput__input-label {
  display: block;
  position: relative; }

.cr-system-template-form label {
  color: #43535c;
  line-height: 15px;
  text-transform: uppercase; }

.cr-system-template-form .public-DraftEditorPlaceholder-root {
  color: #829fb1; }

.cr-system-template-form .cr-textarea-evaluator__title-wrapper,
.cr-system-template-form .cr-textarea-evaluator__wrapper-top-length {
  display: none; }

.cr-system-template-form .btn-submit {
  margin-right: 14px; }

.cr-system-template-form__preview {
  color: #795afa;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer; }
  .cr-system-template-form__preview span {
    margin-left: 10px; }

.cr-system-template-form__head-preview {
  display: flex;
  justify-content: flex-end; }

.cr-system-template-form__btn-merge {
  position: absolute;
  right: 0;
  top: 6px;
  text-transform: capitalize;
  cursor: pointer; }
  .cr-system-template-form__btn-merge span {
    margin-left: 5px;
    color: #795afa;
    font-weight: 700;
    font-size: 14px; }

.cr-system-template-form__list-merge-tags {
  position: absolute;
  margin-top: 8px;
  right: 0;
  background: #ffffff;
  border: 1px solid #795afa;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 181px;
  z-index: 9;
  padding: 10px 0; }

.cr-system-template-form__tag {
  line-height: 20px;
  font-size: 16px;
  color: #334856;
  height: 38px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  cursor: pointer; }
  .cr-system-template-form__tag:hover {
    background: rgba(194, 212, 224, 0.3); }

.cr-system-template-form__selected-stations {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.cr-system-template-form__station {
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 4px;
  height: 42px;
  margin-right: 10px;
  color: #657894;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 0 14px;
  margin-bottom: 10px; }

.cr-system-template-form__remove-tag {
  margin-right: 14px;
  cursor: pointer; }

.cr-system-template-form__left-buttons {
  margin-right: 14px; }

.cr-selection-dropdown {
  display: inline-flex;
  position: relative; }
  .cr-selection-dropdown__placeholder {
    color: #795afa; }
  .cr-selection-dropdown__selected-placeholder {
    color: #336abb; }
  .cr-selection-dropdown__select-box {
    height: 36px;
    border-radius: 4px;
    padding: 0 10px;
    border: 1px solid #c2d4e0;
    background: #f7fbfd;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    text-transform: initial; }
  .cr-selection-dropdown__select-error {
    color: #e40061;
    background-color: rgba(246, 44, 0, 0.14);
    border-color: rgba(246, 44, 0, 0.14); }
  .cr-selection-dropdown__select-has-value {
    color: #336abb;
    background-color: #f7fbfd;
    border-color: #c2d4e0; }
  .cr-selection-dropdown__text-cap {
    text-transform: capitalize; }
  .cr-selection-dropdown__menu {
    background: #ffffff;
    border: 1px solid #795afa;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    min-width: 280px;
    max-width: 340px;
    position: absolute;
    top: 100%;
    z-index: 9; }
  .cr-selection-dropdown__items {
    padding: 20px 0;
    max-height: 300px;
    overflow-y: scroll; }
  .cr-selection-dropdown__btn-new {
    border-top: 1px solid #c2d4e0;
    height: 55px;
    color: #795afa;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .cr-selection-dropdown__btn-new svg {
      margin-right: 10px; }
  .cr-selection-dropdown__menu-item {
    min-height: 38px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    cursor: pointer; }
    .cr-selection-dropdown__menu-item:hover {
      background: rgba(194, 212, 224, 0.3); }
  .cr-selection-dropdown__menu-item-checkbox {
    line-height: 38px;
    padding: 0 20px;
    cursor: pointer;
    text-transform: capitalize; }

.cr-preview-fulfillment-automation-modal {
  max-width: 1218px; }
  .cr-preview-fulfillment-automation-modal .modal-content {
    border: none;
    border-radius: 6px 6px 0 0; }
  .cr-preview-fulfillment-automation-modal .modal-header {
    height: 67px;
    background-color: #0f5589; }
  .cr-preview-fulfillment-automation-modal .modal-title {
    color: #fff;
    font-size: 23px;
    font-weight: 700;
    text-align: center;
    width: 100%; }
  .cr-preview-fulfillment-automation-modal .close {
    display: none; }
  .cr-preview-fulfillment-automation-modal .modal-body {
    padding: 30px 50px; }
  .cr-preview-fulfillment-automation-modal__close {
    position: absolute;
    top: 15px;
    right: 64px;
    cursor: pointer; }
  .cr-preview-fulfillment-automation-modal__preview-content {
    padding: 10px; }
    .cr-preview-fulfillment-automation-modal__preview-content .cr-jock-console__station-logo {
      margin: 0 auto; }
    .cr-preview-fulfillment-automation-modal__preview-content .replace-image {
      max-width: 100%; }
  .cr-preview-fulfillment-automation-modal__preview-bottom {
    text-align: center; }
  .cr-preview-fulfillment-automation-modal__system-template-preview {
    padding: 40px; }
  .cr-preview-fulfillment-automation-modal__click-here {
    background: #2a7ebd;
    border-radius: 24px;
    width: 195px;
    height: 40px;
    border: none;
    color: white;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-transform: uppercase; }
  .cr-preview-fulfillment-automation-modal .slick-current .cr-preview-fulfillment-automation-modal__content {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    min-height: 400px; }
  .cr-preview-fulfillment-automation-modal__preview-completes {
    color: #334856;
    font-size: 21px;
    font-weight: 800;
    max-width: 600px;
    margin: 0 auto;
    overflow: hidden; }
  .cr-preview-fulfillment-automation-modal__preview-head {
    text-align: center;
    margin-bottom: 16px; }
  .cr-preview-fulfillment-automation-modal__preview-info-head {
    margin-bottom: 16px; }
  .cr-preview-fulfillment-automation-modal__form-name {
    color: #795afa;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px; }
  .cr-preview-fulfillment-automation-modal__notification-text {
    color: #829fb1;
    font-size: 14px;
    margin-top: 10px; }
    .cr-preview-fulfillment-automation-modal__notification-text strong {
      font-weight: 700;
      margin-left: 5px;
      color: #657894; }
  .cr-preview-fulfillment-automation-modal .slick-arrow {
    top: 0;
    z-index: 9; }
  .cr-preview-fulfillment-automation-modal .slick-prev {
    left: 20%; }
  .cr-preview-fulfillment-automation-modal .slick-next {
    right: 20%; }
  .cr-preview-fulfillment-automation-modal__system-form-preview .cr-preview-system-form__fulfillment-fields {
    box-shadow: none;
    border-radius: 0;
    background-color: inherit;
    padding: 30px 40px;
    overflow: hidden; }
    .cr-preview-fulfillment-automation-modal__system-form-preview .cr-preview-system-form__fulfillment-fields label {
      color: #537a91;
      text-transform: uppercase; }
  .cr-preview-fulfillment-automation-modal__system-form-preview .cr-preview-system-form__fulfillment-buttons {
    display: none; }

.cr-email-template-modal {
  max-width: 1051px; }
  .cr-email-template-modal .cr-textinput__input-label {
    display: block;
    position: relative; }
  .cr-email-template-modal label {
    color: #43535c;
    line-height: 15px;
    text-transform: uppercase; }
  .cr-email-template-modal .public-DraftEditorPlaceholder-root {
    color: #829fb1; }
  .cr-email-template-modal .cr-textarea-evaluator__title-wrapper,
  .cr-email-template-modal .cr-textarea-evaluator__wrapper-top-length {
    display: none; }
  .cr-email-template-modal .btn-submit {
    margin-right: 14px; }
  .cr-email-template-modal .modal-body {
    padding: 0; }
  .cr-email-template-modal__preview {
    color: #795afa;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer; }
    .cr-email-template-modal__preview span {
      margin-left: 10px; }
  .cr-email-template-modal__btn-merge {
    position: absolute;
    right: 0;
    top: 6px;
    text-transform: capitalize;
    cursor: pointer; }
    .cr-email-template-modal__btn-merge span {
      margin-left: 5px;
      color: #795afa;
      font-weight: 700;
      font-size: 14px; }
  .cr-email-template-modal__list-merge-tags {
    position: absolute;
    margin-top: 8px;
    right: 0;
    background: #ffffff;
    border: 1px solid #795afa;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    width: 181px;
    z-index: 9;
    padding: 10px 0; }
  .cr-email-template-modal__tag {
    line-height: 20px;
    font-size: 16px;
    color: #334856;
    height: 38px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    cursor: pointer; }
    .cr-email-template-modal__tag:hover {
      background: rgba(194, 212, 224, 0.3); }
  .cr-email-template-modal__selected-stations {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  .cr-email-template-modal__station {
    background: #f7fbfd;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 4px;
    height: 42px;
    margin-right: 10px;
    color: #657894;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 0 14px;
    margin-bottom: 10px; }
  .cr-email-template-modal__remove-tag {
    margin-right: 14px;
    cursor: pointer; }
  .cr-email-template-modal__buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 45px 30px 45px; }
  .cr-email-template-modal .cr-textarea-evaluator__container-edit {
    padding: 0; }
  .cr-email-template-modal__head {
    height: 75px;
    border-bottom: 1px solid #edf1f6;
    padding: 0 45px;
    display: flex;
    align-items: center;
    flex-direction: row;
    align-self: stretch;
    justify-content: space-between; }
    .cr-email-template-modal__head h2 {
      font-weight: 700;
      font-size: 23px;
      color: #000000; }
  .cr-email-template-modal__template-box {
    padding: 20px 45px; }
  .cr-email-template-modal__contest-image-help-text {
    color: #829fb1;
    display: flex;
    flex-direction: column;
    margin-top: 20px; }
  .cr-email-template-modal__contest-image .cr-upload-files__drop-area {
    height: 48px; }
  .cr-email-template-modal__contest-image .cr-file-view__file-name {
    color: #795afa; }
  .cr-email-template-modal__schedule {
    padding-top: 23px;
    border-top: 1px solid #edf1f6;
    padding-left: 45px;
    padding-right: 45px;
    padding-bottom: 40px; }
    .cr-email-template-modal__schedule h2 {
      font-weight: 700;
      font-size: 23px;
      color: #000000; }
  .cr-email-template-modal__schedule-text {
    color: #657894;
    margin-bottom: 15px; }
  .cr-email-template-modal .btn-draft {
    margin-right: 14px; }
  .cr-email-template-modal .btn-cancel {
    margin-right: 14px; }
  .cr-email-template-modal__schedule-status {
    background: #ffffff;
    border: 1px solid rgba(194, 212, 224, 0.57);
    border-radius: 4px;
    height: 33px;
    width: 128px;
    color: #2b85ff;
    font-weight: 800;
    font-size: 14px;
    display: flex;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    margin-top: 13px; }

.cr-preview-email-template-modal {
  min-width: 600px; }
  .cr-preview-email-template-modal .modal-content {
    border-radius: 6px;
    overflow: hidden;
    border: none; }
  .cr-preview-email-template-modal .modal-header {
    background-color: #0f5589;
    height: 67px;
    position: relative; }
  .cr-preview-email-template-modal .modal-title {
    font-weight: 700;
    font-size: 23px;
    text-align: center;
    color: #ffffff;
    flex: 1 1; }
  .cr-preview-email-template-modal__close-icon {
    position: absolute;
    right: 25px;
    top: 17px;
    cursor: pointer; }
  .cr-preview-email-template-modal .modal-body {
    background: #ecf2f5;
    padding: 48px 34px; }
  .cr-preview-email-template-modal__info-text strong {
    color: #334856;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 10px; }
  .cr-preview-email-template-modal__info-text span {
    color: #334856; }
  .cr-preview-email-template-modal__section-info {
    margin-bottom: 36px; }
  .cr-preview-email-template-modal__section-bottom {
    text-align: center; }
  .cr-preview-email-template-modal__official-text {
    color: #334856;
    font-size: 13px;
    cursor: pointer; }
  .cr-preview-email-template-modal__line {
    height: 1px;
    width: 100%;
    background-color: #c2d4e0;
    margin: 20px 0; }
  .cr-preview-email-template-modal__copyright {
    display: block;
    color: #829fb1;
    font-size: 12px; }
    .cr-preview-email-template-modal__copyright span {
      display: block; }
  .cr-preview-email-template-modal__section-content {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    padding: 20px 0px;
    margin-bottom: 20px; }
  .cr-preview-email-template-modal__station-image {
    text-align: center;
    margin-bottom: 20px; }
    .cr-preview-email-template-modal__station-image .cr-jock-console__station-logo {
      margin: 0 auto; }
  .cr-preview-email-template-modal .cr-jock-console__station-logo-img {
    margin: 0 auto;
    padding-bottom: 20px; }
  .cr-preview-email-template-modal__image {
    text-align: center;
    margin-bottom: 20px; }
    .cr-preview-email-template-modal__image img {
      max-width: 100%; }
  .cr-preview-email-template-modal .cr-preview-email-template-modal__preview-content {
    padding: 0 30px; }

.cr-production-settings-form form label {
  color: #43535c;
  text-transform: uppercase; }

.cr-production-settings-form__content-wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px; }

.cr-production-settings-form__section-title {
  font-weight: 700;
  font-size: 23px;
  color: #111b2b;
  padding-top: 36px;
  padding-bottom: 20px;
  padding-left: 40px;
  text-transform: capitalize;
  border-bottom: 1px solid #edf1f6; }

.cr-production-settings-form__form-padding {
  padding-left: 40px;
  padding-right: 40px; }

.cr-production-settings-form__form-buttons {
  padding-left: 40px;
  margin-top: 30px;
  padding-right: 40px; }
  .cr-production-settings-form__form-buttons .btn-blue {
    margin-right: 14px; }

.cr-production-settings-form__helper-text {
  color: #000000;
  padding-bottom: 40px; }

.cr-production-settings-form__col-reset {
  margin-bottom: 0; }

.cr-production-settings-form__group-checkboxes {
  display: flex;
  flex-direction: column; }
  .cr-production-settings-form__group-checkboxes .cr-checkbox {
    margin-bottom: 10px; }
  .cr-production-settings-form__group-checkboxes .cr-checkbox__text {
    color: #334856; }

.cr-production-settings-form__input-number {
  max-width: 127px; }

.cr-production-settings-form .cr-dropdown-length {
  max-width: 400px; }

.cr-production-settings-form .cr-dropdown-length__checkbox {
  margin-top: 42px; }

.cr-production-settings-form__content-order-settings-wrapper .cr-production-settings-form__section-title {
  margin-bottom: 24px; }

.cr-production-settings-form__content-workflow-settings-wrapper .cr-production-settings-form__form-padding {
  padding-top: 30px;
  padding-bottom: 30px; }

.cr-production-settings-form__content-workflow-settings-wrapper .form-group {
  margin-bottom: 32px; }

.cr-production-settings-form__content-workflow-settings-wrapper .cr-toggle-switch__left-component,
.cr-production-settings-form__content-workflow-settings-wrapper .cr-toggle-switch__right-component {
  color: #829fb1;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 400; }
  .cr-production-settings-form__content-workflow-settings-wrapper .cr-toggle-switch__left-component.active,
  .cr-production-settings-form__content-workflow-settings-wrapper .cr-toggle-switch__right-component.active {
    color: #111b2b;
    font-weight: bold; }

.cr-production-settings-form .bottom-actions-sticky .btn-blue {
  margin-right: 14px; }

.cr-production-settings-form__description-text {
  color: #000000;
  margin-bottom: 18px; }

.cr-production-settings-form__deadline-text {
  color: #334856;
  margin-bottom: 15px; }

.cr-production-settings-form__deadline-group {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-direction: row;
  align-items: flex-end; }

.cr-production-settings-form__dropdown-deadline {
  min-width: 180px; }

@media (min-width: 1599px) {
  .cr-production-settings-form .bottom-actions-sticky .buttons {
    padding-left: 70px;
    padding-right: 70px; } }

.cr-final-script-modal {
  min-width: 877px; }
  .cr-final-script-modal .modal-header {
    border-bottom: 0;
    padding-top: 36px;
    padding-left: 50px;
    padding-right: 25px;
    padding-bottom: 38px; }
  .cr-final-script-modal .modal-title {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    position: relative; }
  .cr-final-script-modal__client-name {
    color: #829fb1;
    font-size: 18px; }
  .cr-final-script-modal__close-icon {
    position: absolute;
    right: 0;
    cursor: pointer;
    top: -8px; }
  .cr-final-script-modal__head-section {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .cr-final-script-modal__spot-text {
    font-size: 32px; }
  .cr-final-script-modal__title {
    color: #111b2b;
    font-weight: 800;
    font-size: 32px;
    margin-left: 10px; }
  .cr-final-script-modal__spot-length {
    color: #657894;
    font-size: 16px;
    margin-right: 25px; }
    .cr-final-script-modal__spot-length strong {
      color: #111b2b;
      margin-left: 10px; }
  .cr-final-script-modal .modal-body {
    padding-top: 0;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 60px; }
  .cr-final-script-modal .btn-blue {
    display: flex;
    align-items: center; }
    .cr-final-script-modal .btn-blue span {
      margin-left: 10px; }
  .cr-final-script-modal__script {
    font-size: 18px;
    color: #334856;
    text-transform: uppercase;
    line-break: anywhere; }
  .cr-final-script-modal__content-script {
    margin-bottom: 21px; }

.cr-override-stations-modal {
  max-width: 743px; }
  .cr-override-stations-modal .modal-header {
    border-bottom: 0;
    padding: 49px 44px 30px 44px; }
  .cr-override-stations-modal .modal-footer {
    border-top: 0;
    justify-content: center;
    padding-bottom: 50px; }
  .cr-override-stations-modal .modal-body {
    padding-top: 0; }
  .cr-override-stations-modal__override-title {
    color: #000000;
    font-weight: 800;
    font-size: 36px;
    text-align: center;
    line-height: 45px; }
  .cr-override-stations-modal__override-desc {
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #657894;
    margin-top: 20px; }
  .cr-override-stations-modal__list-stations {
    display: flex;
    max-width: 400px;
    margin: 0 auto;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end; }
    .cr-override-stations-modal__list-stations .cr-checkbox {
      margin-bottom: 16px;
      flex: 0 1 50%;
      min-width: 50%;
      padding-left: 10%; }
    .cr-override-stations-modal__list-stations .cr-checkbox__text {
      color: #000000; }
  .cr-override-stations-modal__list-stations-center {
    justify-content: center; }
  .cr-override-stations-modal .modal-footer .btn {
    margin: 0 7px; }

.cr-enterprise-settings-form form label {
  color: #43535c;
  text-transform: uppercase; }

.cr-enterprise-settings-form__content-wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px; }

.cr-enterprise-settings-form__section-title {
  font-weight: 700;
  font-size: 23px;
  color: #111b2b;
  padding-top: 36px;
  padding-bottom: 20px;
  padding-left: 40px;
  text-transform: capitalize; }

.cr-enterprise-settings-form__team-member-wrapper {
  border-bottom: 1px solid #edf1f6;
  padding-bottom: 29px; }
  .cr-enterprise-settings-form__team-member-wrapper .cr-enterprise-settings-form__helper-text {
    padding-bottom: 32px; }

.cr-enterprise-settings-form__form-padding {
  padding-left: 40px;
  padding-right: 40px; }

.cr-enterprise-settings-form__form-buttons {
  padding-left: 40px;
  margin-top: 30px;
  padding-right: 40px; }
  .cr-enterprise-settings-form__form-buttons .btn-blue {
    margin-right: 14px; }

.cr-enterprise-settings-form__helper-text {
  color: #000000; }

.cr-enterprise-settings-form__col-reset {
  margin-bottom: 0; }

.cr-enterprise-settings-form__group-checkboxes {
  display: flex;
  flex-direction: column; }
  .cr-enterprise-settings-form__group-checkboxes .cr-checkbox {
    margin-bottom: 10px; }
  .cr-enterprise-settings-form__group-checkboxes .cr-checkbox__text {
    color: #334856; }

.cr-enterprise-settings-form .cr-toggle-switch {
  margin-bottom: 40px; }

.cr-enterprise-settings-form .cr-toggle-switch__right-component {
  text-transform: uppercase; }

.cr-enterprise-settings-form .cr-toggle-switch__left-component,
.cr-enterprise-settings-form .cr-toggle-switch__right-component {
  color: #829fb1;
  font-size: 17px;
  font-weight: 400; }
  .cr-enterprise-settings-form .cr-toggle-switch__left-component.active,
  .cr-enterprise-settings-form .cr-toggle-switch__right-component.active {
    color: #334856;
    font-weight: bold; }

.cr-enterprise-settings-form__label-bold {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #334856 !important;
  margin-bottom: 0;
  text-transform: capitalize !important;
  margin-top: 25px; }

.cr-enterprise-settings-form__form-group-capacity {
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .cr-enterprise-settings-form__form-group-capacity .react-select-container {
    width: 167px; }

.cr-enterprise-settings-form__team-recommendations-wrapper {
  padding-bottom: 40px; }
  .cr-enterprise-settings-form__team-recommendations-wrapper .cr-enterprise-settings-form__helper-text {
    margin-bottom: 22px; }

.cr-enterprise-settings-form__example-text {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #43535c;
  margin-bottom: 12px;
  text-transform: uppercase; }

.cr-enterprise-settings-form__example-boxes {
  display: flex;
  align-items: center;
  flex-direction: row; }

.cr-enterprise-settings-form__example-box {
  width: 185px; }
  .cr-enterprise-settings-form__example-box span {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #657894;
    display: block;
    margin-top: 16px; }

.cr-enterprise-settings-form__example-active-box {
  margin-right: 38px; }

.cr-production-manager-queue__table-wrapper {
  border-radius: 30px;
  margin-top: -35px;
  background-color: #f7fbfd;
  overflow: hidden;
  padding: 10px; }

.cr-production-manager-queue__table-wrapper-mode-in-tab {
  margin-top: 0;
  padding: 0;
  border: 1px solid #c2d4e0; }
  .cr-production-manager-queue__table-wrapper-mode-in-tab table th {
    border-top: none; }

.cr-production-manager-queue__table {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px 20px 5px 5px;
  overflow: hidden;
  margin-bottom: 0;
  background: #fff; }
  .cr-production-manager-queue__table .col-table-checkbox {
    min-width: 70px;
    text-align: center; }
    .cr-production-manager-queue__table .col-table-checkbox .cr-checkbox__checkbox {
      justify-content: center; }
  .cr-production-manager-queue__table .cr-count-down {
    justify-content: center !important; }
  .cr-production-manager-queue__table thead {
    background-color: #224b8b;
    color: #fff;
    font-weight: 700;
    font-size: 14px; }
    .cr-production-manager-queue__table thead th {
      border-bottom: none;
      padding: 8px;
      text-align: left;
      text-transform: capitalize; }
  .cr-production-manager-queue__table tbody tr {
    text-align: left; }
    .cr-production-manager-queue__table tbody tr:hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
      cursor: pointer; }
    .cr-production-manager-queue__table tbody tr:nth-child(2n + 1) {
      background-color: #f7fbfd; }
  .cr-production-manager-queue__table tbody td {
    text-align: left;
    border-top-color: #ecf1f7;
    padding: 14px 8px; }

.cr-production-manager-queue__item-sortable {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.cr-production-manager-queue__item-sortable-center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize; }

.cr-production-manager-queue__sortable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  text-align: center; }
  .cr-production-manager-queue__sortable:hover {
    border-radius: 100px;
    background-image: linear-gradient(to bottom, #6f96d1, #5f85c0, #4f75b0, #3f659f, #2e558f); }

.cr-production-manager-queue__sortable-icon {
  position: absolute;
  font-size: 16px; }

.cr-production-manager-queue__sortable-active {
  color: #c12674; }

.cr-production-manager-queue__order-status {
  border: 1px solid rgba(194, 212, 224, 0.5);
  border-radius: 4px;
  min-width: 80%;
  display: flex;
  min-width: 133px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  color: #9aa4ca;
  min-height: 33px;
  align-items: center;
  padding: 5px 10px;
  justify-content: center;
  text-align: center; }

.cr-production-manager-queue__client-name {
  color: #000000;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 8px;
  display: flex; }
  .cr-production-manager-queue__client-name:hover .cr-production-manager-queue__btn-link {
    opacity: 1; }

.cr-production-manager-queue__btn-link {
  opacity: 0;
  cursor: pointer;
  padding: 0 5px; }

.cr-production-manager-queue__order-title {
  color: #795afa;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
  display: block; }

.cr-production-manager-queue__order-info-item {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #829fb1; }
  .cr-production-manager-queue__order-info-item label {
    margin-bottom: 0; }
  .cr-production-manager-queue__order-info-item span {
    color: #334856;
    margin-left: 5px; }

.cr-production-manager-queue__stations span {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #334856;
  display: block;
  margin-bottom: 4px; }

.cr-production-manager-queue__order span {
  display: block;
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #334856; }

.cr-production-manager-queue__cart span {
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: #334856;
  margin-bottom: 4px;
  display: block; }

.cr-production-manager-queue__cart-pending {
  text-transform: uppercase;
  color: #bccedb !important;
  font-weight: 700 !important; }

.cr-production-manager-queue__flight-dates {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center; }
  .cr-production-manager-queue__flight-dates label {
    display: block;
    color: #829fb1;
    margin-bottom: 0; }
  .cr-production-manager-queue__flight-dates span {
    display: block;
    color: #334856;
    margin-bottom: 10px; }

.cr-production-manager-queue__box {
  background: #ffffff;
  border: 1px solid rgba(194, 212, 224, 0.5);
  border-radius: 4px;
  padding: 9px 8px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box; }
  .cr-production-manager-queue__box:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12); }

.cr-production-manager-queue__box-content {
  flex: 1 1; }

.cr-production-manager-queue__box-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px; }
  .cr-production-manager-queue__box-actions .btn-blue {
    min-width: 120px;
    border-radius: 18px;
    height: 20px;
    font-size: 12px;
    font-weight: 600;
    padding: 0; }

.cr-production-manager-queue__btn-edit {
  cursor: pointer;
  margin-left: 5px; }

.cr-production-manager-queue__voice-text {
  font-size: 11px; }
  .cr-production-manager-queue__voice-text span {
    font-weight: 500;
    color: #829fb1;
    line-height: 16px; }
  .cr-production-manager-queue__voice-text strong {
    font-weight: 700;
    color: #000000;
    margin-left: 5px; }

.cr-production-manager-queue__box-content .cr-production-manager-queue__assign-role:last-child {
  margin-bottom: 0; }

.cr-production-manager-queue__assign-role {
  display: flex;
  margin-bottom: 5px; }

.cr-production-manager-queue__role-name {
  width: 15.45px;
  height: 15.45px;
  background-color: #d6e5ef;
  border-radius: 100px;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  color: #657894;
  display: block;
  text-align: center; }

.cr-production-manager-queue__assign-users {
  display: flex;
  margin-left: 8px;
  flex-direction: column; }
  .cr-production-manager-queue__assign-users span {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #657894;
    display: flex; }

.cr-production-manager-queue__assign-select {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #c2d4e0;
  margin-left: 8px; }

.cr-production-manager-queue__user-status {
  width: 8px;
  height: 8px;
  display: block;
  margin-right: 4px;
  border-radius: 100px;
  margin-top: 4px;
  background-color: #c2d4e0; }

.cr-production-manager-queue__status-overload {
  background-color: #e40061; }

.cr-production-manager-queue__status-inactive {
  background-color: #e40061; }

.cr-production-manager-queue__status-available {
  background-color: #73a703; }

.cr-production-manager-queue__instructions-item {
  line-height: 15px; }
  .cr-production-manager-queue__instructions-item span {
    margin-left: 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #334856;
    text-transform: capitalize; }

.cr-production-manager-queue__instructions-spec-text {
  padding: 0 15px;
  margin-bottom: 2px; }
  .cr-production-manager-queue__instructions-spec-text span {
    color: #334856;
    font-weight: 800;
    font-size: 12px;
    line-height: 15.06px;
    text-transform: uppercase; }

.cr-production-manager-queue__fake-check {
  display: inline-block;
  width: 2px; }

.cr-production-manager-queue__comments {
  cursor: pointer;
  margin-top: 8px;
  position: relative;
  display: inline-block;
  text-align: center; }

.cr-production-manager-queue__comment-red {
  position: absolute;
  top: -9px;
  left: 8px;
  border: 1px solid #fff;
  height: 17px;
  min-width: 17px;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  display: block;
  background-color: #e40061;
  padding: 0 4px;
  box-sizing: border-box;
  border-radius: 10px; }

.cr-production-manager-queue__comment-white {
  position: absolute;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  top: 0;
  left: 0;
  width: 100%; }

.cr-production-manager-queue__table-first-head .rc-checkbox-checked .rc-checkbox-inner {
  background-color: #fff; }

.cr-production-manager-queue__table-first-head .cr-checkbox__checked-icon svg path {
  stroke: #224b8b; }

.cr-production-manager-queue__order-template-text {
  color: #334856;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 10px;
  display: block;
  margin-left: 10px;
  text-transform: capitalize; }

.cr-production-manager-queue__box-assign-team-view {
  border: none;
  background-color: transparent;
  padding: 0;
  margin-left: 10px; }
  .cr-production-manager-queue__box-assign-team-view:hover {
    box-shadow: none; }
  .cr-production-manager-queue__box-assign-team-view .cr-production-manager-queue__assign-unassigned {
    color: #b7c0cd; }
  .cr-production-manager-queue__box-assign-team-view .cr-production-manager-queue__box-content {
    max-height: inherit; }

.cr-right-sidebar__wrapper {
  position: fixed;
  top: 65px;
  right: 0;
  max-width: 600px;
  opacity: 0;
  width: 100%;
  height: calc(100% - 65px);
  overflow: hidden;
  z-index: 999;
  transform: translateX(100%);
  -webkit-transform: translateX(100%); }

.cr-right-sidebar__instructions {
  max-width: 600px;
  background: #f7fbfd;
  box-shadow: -5px 20px 20px rgba(0, 0, 0, 0.15);
  opacity: 1; }

.cr-right-sidebar__assign {
  background: #f7fbfd;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.102);
  max-width: 903px;
  opacity: 1; }

.cr-right-sidebar__assign-vo {
  background: #f7fbfd;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.102);
  max-width: 968px;
  opacity: 1; }

.cr-right-sidebar__open {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards; }

.cr-right-sidebar__close {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards; }

@keyframes slide-in {
  0% {
    -webkit-transform: translateX(100%); }
  100% {
    -webkit-transform: translateX(0%); } }

@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%); }
  100% {
    -webkit-transform: translateX(100%); } }

.cr-right-sidebar__background {
  position: fixed;
  width: 100%;
  height: 100%;
  background: transparent;
  top: 0;
  left: 0;
  overflow: hidden; }

.cr-right-sidebar__header {
  height: 95px;
  padding-left: 38px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c2d4e0; }

.cr-right-sidebar__btn-close {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.102);
  width: 50px;
  height: 50px;
  border-radius: 100px;
  margin-right: 20px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.cr-right-sidebar__title h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #000000; }

.cr-right-sidebar__title span {
  display: block;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #795afa;
  text-transform: capitalize; }

.cr-right-sidebar__hub-stations {
  background: #f7fbfd;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.102);
  max-width: 1306px;
  width: 80%;
  opacity: 1;
  display: flex;
  flex-direction: column; }
  .cr-right-sidebar__hub-stations .cr-right-sidebar__header .cr-right-sidebar__title h3 {
    margin-bottom: 0; }

.cr-right-sidebar__hub-members {
  background: #f7fbfd;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.102);
  max-width: 968px;
  width: 80%;
  opacity: 1; }
  .cr-right-sidebar__hub-members .cr-right-sidebar__header .cr-right-sidebar__title h3 {
    margin-bottom: 0; }

.cr-right-sidebar__filter-view, .cr-right-sidebar__filter-view-produced-spots, .cr-right-sidebar__filter-view-production-archive, .cr-right-sidebar__filter-view-production-queue {
  background: #f7fbfd;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.102);
  max-width: 493px;
  width: 100%;
  opacity: 1; }

.cr-file-instructions__instruction-header {
  height: 42px;
  padding: 0 32px;
  display: flex;
  background: #f7fbfd;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(194, 212, 224, 0.5);
  cursor: pointer; }

.cr-file-instructions__section-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #334856;
  text-transform: uppercase;
  cursor: pointer; }
  .cr-file-instructions__section-title svg {
    margin-right: 6px; }

.cr-file-instructions__fake-icon {
  width: 13px;
  display: inline-block; }

.cr-file-instructions__section-action {
  cursor: pointer; }

.cr-file-instructions__section-content {
  padding: 32px;
  background: #fff;
  overflow-y: scroll; }

.cr-file-instructions__final-script-content {
  padding: 0 !important;
  overflow: initial; }

.cr-file-instructions__content-buttons {
  margin-top: 20px; }
  .cr-file-instructions__content-buttons .btn-cancel {
    margin-left: 14px; }

.cr-file-instructions__content-details h4 {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #829fb1;
  margin-bottom: 12px;
  text-transform: uppercase; }

.cr-file-instructions__content-details label {
  color: #829fb1;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 16px; }

.cr-file-instructions__content-item {
  margin-bottom: 16px; }
  .cr-file-instructions__content-item .cr-textarea-evaluator__wrapper-top {
    color: #829fb1; }
  .cr-file-instructions__content-item .cr-textarea-evaluator__timer span {
    font-size: 14px; }

.cr-file-instructions__content-text {
  font-size: 16px;
  color: #000000;
  margin-bottom: 0; }

.cr-file-instructions__bold-text {
  color: #000000;
  font-weight: 700;
  margin-left: 5px; }

.cr-file-instructions__asset-audit {
  margin-bottom: 5px;
  display: block;
  font-size: 11px;
  line-height: 150%;
  color: #657894; }

.cr-file-instructions__approved-label {
  margin-bottom: 10px !important; }

.cr-file-instructions__spot-title {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #657894;
  text-transform: uppercase;
  margin-bottom: 11px; }

.cr-file-instructions__spot-recording-item {
  margin-bottom: 27px; }
  .cr-file-instructions__spot-recording-item .spot-length {
    display: none; }
  .cr-file-instructions__spot-recording-item .player-container,
  .cr-file-instructions__spot-recording-item .player-wrapper {
    margin-bottom: 0; }
  .cr-file-instructions__spot-recording-item .upload-file-buttons .btn {
    padding: 0 !important;
    height: initial; }

.cr-file-instructions__spot-production {
  background: #657894;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  text-transform: uppercase; }

.cr-file-instructions__empty-content-details label {
  color: #829fb1;
  font-weight: 600;
  margin-bottom: 12px;
  display: block;
  text-transform: uppercase; }

.cr-file-instructions__empty-text {
  color: #000000;
  font-size: 12px;
  line-height: 16px; }

.cr-assign-team-sidebar__assigned-header {
  height: 84px;
  display: flex;
  padding-left: 30px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c2d4e0;
  background: #fff; }

.cr-assign-team-sidebar__assignments-text {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #000000; }

.cr-assign-team-sidebar__members {
  margin-bottom: 0; }

.cr-assign-team-sidebar .cr-textarea-evaluator__wrapper-top-length {
  display: none; }

.cr-assign-team-sidebar__container-users {
  width: 100%; }

.cr-assign-team-sidebar__col-user {
  position: relative; }

.cr-assign-team-sidebar__user-row {
  background-color: #ffffff;
  border-bottom: 1px solid #edf1f6;
  padding: 20px 21px 20px 25px; }
  .cr-assign-team-sidebar__user-row [class^="col-"] {
    margin-bottom: 0 !important; }
  .cr-assign-team-sidebar__user-row:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102); }

.cr-assign-team-sidebar__user-location {
  font-size: 14px;
  display: block; }
  .cr-assign-team-sidebar__user-location span {
    color: #657894;
    font-weight: 600; }
  .cr-assign-team-sidebar__user-location strong {
    margin-left: 3px;
    font-weight: 800;
    line-height: 18px;
    color: #4324c7; }

.cr-assign-team-sidebar__user-selected .cr-assign-team-sidebar__user-info::after {
  display: none; }

.cr-assign-team-sidebar__user-ooo .cr-select-team__user-name {
  color: #829fb1;
  display: flex;
  width: calc(100% - 30px);
  justify-content: space-between; }

.cr-assign-team-sidebar__user-ooo .cr-select-team__user-task-label,
.cr-assign-team-sidebar__user-ooo .cr-select-team__user-task-value {
  color: #829fb1; }

.cr-assign-team-sidebar__user-ooo .cr-select-team__user-avatar {
  opacity: 0.6; }

.cr-assign-team-sidebar__user-ooo .cr-select-team__user-role-button {
  cursor: not-allowed; }

.cr-assign-team-sidebar__out-of-office {
  color: #e40061;
  font-size: 11px;
  margin-top: 7px; }

.cr-assign-team-sidebar__user-info {
  display: flex;
  align-items: center; }
  .cr-assign-team-sidebar__user-info:after {
    content: "";
    width: 1px;
    height: 100%;
    display: block;
    position: absolute;
    background: #c2d4e0;
    right: 0;
    top: 0;
    bottom: 0; }

.cr-assign-team-sidebar__user-avatar {
  width: 46px !important;
  height: 46px !important;
  max-width: 100%;
  overflow: hidden;
  margin-right: 10px !important;
  float: none !important; }
  .cr-assign-team-sidebar__user-avatar .avatar-sort-name-text {
    font-size: 14px; }

.cr-assign-team-sidebar__vacation-icon {
  margin-right: 10px; }

.cr-assign-team-sidebar__user-name {
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  line-height: 26px;
  color: #111b2b; }

.cr-assign-team-sidebar__user-roles {
  flex-direction: row;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%; }

.cr-assign-team-sidebar__user-role-button {
  background: #f7fbfd;
  border: 1px solid #829fb1;
  box-sizing: border-box;
  border-radius: 50px;
  width: 117px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 5px 0;
  margin-right: 5px;
  color: #4324c7;
  padding: 0 15px; }

.cr-assign-team-sidebar__user-role-assigned {
  background: #795afa;
  color: #fff; }

.cr-assign-team-sidebar__user-role-disabled {
  color: #c2d4e0;
  border-color: #c2d4e0; }

.cr-assign-team-sidebar__user-tasks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  overflow: hidden; }

.cr-assign-team-sidebar__user-task-label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #829fb1; }
  @media (max-width: 768px) {
    .cr-assign-team-sidebar__user-task-label {
      font-size: 2vw; } }

.cr-assign-team-sidebar__user-info-right {
  width: calc(100% - 60px);
  position: relative; }

.cr-assign-team-sidebar__user-task {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  white-space: nowrap; }

.cr-assign-team-sidebar__user-task-current {
  margin-right: 5px; }

.cr-assign-team-sidebar__user-task-value {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #4628bf;
  margin-left: 5px; }

.cr-assign-team-sidebar__user-action {
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: 0px; }

.cr-assign-team-sidebar__user-role-title {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.cr-assign-team-sidebar__roles {
  display: flex;
  align-items: center;
  flex: 1 1; }

.cr-assign-team-sidebar__role-item {
  margin-left: 20px;
  flex-direction: row;
  display: flex;
  align-items: center; }

.cr-assign-team-sidebar__role {
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 15px;
  display: inline-block;
  max-width: 190px;
  overflow: hidden; }
  @media (max-width: 768px) {
    .cr-assign-team-sidebar__role {
      margin-bottom: 20px; } }

.cr-assign-team-sidebar__role-left {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column; }

.cr-assign-team-sidebar__assigned-user-avatar {
  width: 55px !important;
  height: 55px !important;
  margin-right: 12px; }

.cr-assign-team-sidebar__assigned-user-name {
  color: #224b8b;
  font-size: 16px;
  font-weight: 800;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  top: 21px;
  left: 10px;
  display: block; }

.cr-assign-team-sidebar__role-title {
  font-size: 16px;
  line-height: 20px;
  color: #334856; }

.cr-assign-team-sidebar__assigned {
  color: #224b8b; }

.cr-assign-team-sidebar__role-image {
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  background-color: #d8e4eb;
  margin-right: 12px; }

.cr-assign-team-sidebar__assigned-user-info {
  position: relative; }

.cr-assign-team-sidebar__checked {
  background: #fff;
  border-radius: 100px;
  width: 23px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px; }

.cr-assign-team-sidebar__filter-row {
  justify-content: space-between;
  display: flex;
  padding: 8px 0;
  padding-left: 30px;
  background-color: #f7fbfd;
  min-height: 61px; }
  .cr-assign-team-sidebar__filter-row .react-select-container {
    min-width: 170px; }
    .cr-assign-team-sidebar__filter-row .react-select-container .react-select__control {
      min-height: 40px !important; }
    .cr-assign-team-sidebar__filter-row .react-select-container .react-select__single-value {
      color: #657894 !important; }

.cr-assign-team-sidebar__filter-row-left {
  display: flex; }

.cr-assign-team-sidebar__team-member-dropdown {
  display: flex;
  align-items: center; }

.cr-assign-team-sidebar__filter-dropdown-label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  margin-right: 10px;
  font-weight: 600;
  color: #829fb1;
  text-transform: uppercase; }

.cr-assign-team-sidebar__filters {
  display: flex;
  margin-bottom: 27px; }

.cr-assign-team-sidebar__filter-role {
  color: #8165f3;
  font-size: 16px;
  font-weight: 700;
  margin-right: 20px;
  text-transform: uppercase;
  cursor: pointer; }

.cr-assign-team-sidebar__filter-selected {
  color: #224b8b; }

.cr-assign-team-sidebar__team-members {
  margin-bottom: 0 !important;
  overflow: hidden; }

.cr-assign-team-sidebar__settings {
  color: #9abcd1;
  cursor: pointer; }
  .cr-assign-team-sidebar__settings > span {
    font-size: 15px;
    font-weight: 400;
    margin-right: 15px; }

.cr-assign-team-sidebar__team-member-title {
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 29px;
  color: #334856;
  margin-left: 30px;
  display: flex;
  align-items: center; }

.cr-assign-team-sidebar__select-teams-member {
  margin-bottom: 0; }

.cr-assign-team-sidebar__team-members-title {
  padding-right: 25px !important; }

.cr-assign-team-sidebar__user-roles-reel {
  display: flex;
  align-items: center; }
  .cr-assign-team-sidebar__user-roles-reel .player-container {
    margin-right: 10px;
    flex: 1 1; }
  .cr-assign-team-sidebar__user-roles-reel .player-container,
  .cr-assign-team-sidebar__user-roles-reel .player-wrapper {
    margin-bottom: 0; }

.cr-assign-team-sidebar__team-members-title {
  text-transform: capitalize; }

.cr-assign-team-sidebar__members-items {
  display: flex;
  flex-direction: row;
  margin: 0 -12px;
  flex-wrap: wrap; }

.cr-assign-team-sidebar__member-box {
  height: 42px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px; }

.cr-assign-team-sidebar__member-item {
  margin: 0 12px;
  margin-bottom: 12px; }

.cr-assign-team-sidebar__member-inactive .cr-select-team__member-box {
  background: #c2d4e0;
  border: 1px solid #c2d4e0;
  width: 195px;
  cursor: pointer; }

.cr-assign-team-sidebar__member-selected .cr-select-team__member-box {
  width: 195px;
  background: #795afa;
  border: 1px solid #795afa; }

.cr-assign-team-sidebar__member-assigned .cr-select-team__member-box {
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  padding: 5px 30px;
  position: relative;
  cursor: pointer; }
  .cr-assign-team-sidebar__member-assigned .cr-select-team__member-box:hover {
    border-color: #795afa; }
    .cr-assign-team-sidebar__member-assigned .cr-select-team__member-box:hover .cr-select-team__delete-icon {
      opacity: 1;
      visibility: visible; }

.cr-assign-team-sidebar__delete-icon {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 10px;
  opacity: 0;
  visibility: hidden; }

.cr-assign-team-sidebar__vo-text {
  color: #795afa;
  margin-bottom: 8px;
  display: block;
  text-transform: uppercase;
  text-align: center; }

.cr-assign-team-sidebar__member-name {
  color: #334856;
  text-transform: capitalize; }

.cr-assign-team-sidebar__members-search {
  margin-left: 15px; }
  .cr-assign-team-sidebar__members-search .cr-search-form {
    box-shadow: none;
    border: 1px solid #c2d4e0; }
  .cr-assign-team-sidebar__members-search .cr-search-form__input {
    padding: 10px;
    color: #657894; }

.cr-assign-team-sidebar__btn-assign-team {
  width: 204px !important;
  margin-left: 20px; }

.cr-assign-team-sidebar__btn-assign-team {
  height: 49px; }
  .cr-assign-team-sidebar__btn-assign-team:disabled {
    background: #bccedb;
    border-color: #bccedb; }

.cr-assign-team-sidebar__assign-vo .cr-select-team__user-roles-reel {
  justify-content: flex-end; }

.cr-assign-team-sidebar__scroll-container {
  overflow-y: scroll;
  background: #fff; }

.cr-assign-team-sidebar__assigned-footer {
  height: 84px;
  display: flex;
  align-items: center;
  padding: 0 25px;
  border-top: 1px solid #c2d4e0; }
  .cr-assign-team-sidebar__assigned-footer .btn-blue {
    margin-right: 14px; }

.cr-assign-team-sidebar__selected-title {
  height: 32px;
  padding: 0 30px;
  background-color: #f7fbfd;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #edf1f6; }
  .cr-assign-team-sidebar__selected-title span {
    color: #829fb1;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase; }

.cr-assign-team-sidebar__filter-audition {
  display: flex; }

.cr-assign-team-sidebar__user-status {
  width: 13px;
  height: 13px;
  background-color: #c2d4e0;
  display: inline-block;
  border-radius: 100px;
  margin-right: 6px; }

.cr-assign-team-sidebar__status-overload {
  background-color: #e40061; }

.cr-assign-team-sidebar__status-available {
  background-color: #73a703; }

.cr-assign-team-sidebar__status-inactive {
  background-color: grey; }

.cr-assign-team-sidebar__role-assigned-number {
  color: #795afa;
  margin-left: 10px; }

.cr-assign-team-sidebar__hub-assign-members-scroll {
  overflow-y: scroll;
  padding-top: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  background-color: #fff; }
  .cr-assign-team-sidebar__hub-assign-members-scroll .cr-assign-team-sidebar__filter-row {
    background-color: #fff; }

.cr-assign-team-sidebar__nav-tabs {
  margin-left: 8px;
  margin-top: 8px; }

.cr-assign-team-sidebar__nav-link {
  font-size: 16px !important;
  min-width: 202px;
  text-align: center;
  display: inline-block !important;
  padding: 12px 6px !important;
  color: #657894;
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
  border: none !important;
  text-transform: capitalize; }
  .cr-assign-team-sidebar__nav-link.active {
    font-weight: bold !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    color: #657894 !important;
    position: relative; }
    .cr-assign-team-sidebar__nav-link.active::after {
      left: 0;
      content: "";
      display: block;
      background-color: #fff;
      width: 100%;
      height: 20px;
      position: absolute; }

.cr-assign-team-sidebar__instruction-header {
  height: 42px;
  padding: 0 32px;
  display: flex;
  background: #f7fbfd;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c2d4e0;
  cursor: pointer; }

.cr-assign-team-sidebar__section-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #334856;
  text-transform: uppercase;
  cursor: pointer; }
  .cr-assign-team-sidebar__section-title svg {
    margin-right: 6px; }

.cr-assign-team-sidebar__section-content-item {
  padding: 32px;
  background: #fff;
  border-bottom: 1px solid #c2d4e0; }

.cr-assign-team-sidebar__section-action {
  cursor: pointer; }

.cr-assign-team-sidebar__content-details h4 {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #829fb1;
  margin-bottom: 12px;
  text-transform: uppercase; }

.cr-assign-team-sidebar__content-details label {
  color: #829fb1;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 16px; }

.cr-assign-team-sidebar__content-text {
  font-size: 16px;
  color: #000000;
  margin-bottom: 0; }

.cr-assign-team-sidebar__deadline-group {
  padding: 0 20px;
  margin-top: 24px;
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  align-items: flex-end; }
  .cr-assign-team-sidebar__deadline-group .input-container {
    margin-bottom: 0 !important; }
  .cr-assign-team-sidebar__deadline-group .react-select-container {
    min-width: 140px; }
  .cr-assign-team-sidebar__deadline-group label {
    color: #43535c;
    display: block;
    text-transform: uppercase;
    margin-bottom: 8px;
    line-height: 15px; }

.cr-assign-team-sidebar__production-deadlines-open {
  border-bottom: 1px solid #c2d4e0; }

@media screen and (min-width: 1200px) and (max-width: 1436px) {
  .cr-assign-team-sidebar__user-role-button {
    width: 100px;
    height: 35px;
    padding: 0 10px; }
  .cr-assign-team-sidebar__user-roles-reel-column {
    flex-direction: column;
    align-items: flex-start; }
    .cr-assign-team-sidebar__user-roles-reel-column .player-container {
      width: 100%;
      margin-right: 0; } }

.cr-comments-panel__wrapper {
  position: fixed;
  top: 65px;
  right: 0;
  max-width: 600px;
  opacity: 0;
  width: 100%;
  height: calc(100% - 65px);
  overflow: hidden;
  z-index: 999;
  transform: translateX(100%);
  -webkit-transform: translateX(100%); }

.cr-comments-panel__section-content {
  height: calc(100% - 95px);
  background-color: #fff;
  padding-left: 2px; }

.cr-comments-panel__wrapper-comments {
  background: #f7fbfd;
  box-shadow: -5px 20px 20px rgba(0, 0, 0, 0.15);
  opacity: 1;
  max-width: 602px; }

.cr-comments-panel__open {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards; }

.cr-comments-panel__close {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards; }

@keyframes slide-in {
  0% {
    -webkit-transform: translateX(100%); }
  100% {
    -webkit-transform: translateX(0%); } }

@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%); }
  100% {
    -webkit-transform: translateX(100%); } }

.cr-comments-panel__background {
  position: fixed;
  width: 100%;
  height: 100%;
  background: transparent;
  top: 0;
  left: 0;
  overflow-y: hidden;
  z-index: 9; }

.cr-comments-panel__header {
  height: 95px;
  padding-left: 38px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c2d4e0; }

.cr-comments-panel__btn-close {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.102);
  width: 50px;
  height: 50px;
  border-radius: 100px;
  margin-right: 20px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.cr-comments-panel__title h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #000000; }

.cr-comments-panel__title span {
  display: block;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #795afa;
  text-transform: capitalize; }

.cr-comments-panel__chat-input {
  padding-top: 24px;
  padding-bottom: 15px;
  padding-left: 40px;
  padding-right: 30px;
  background: #f7fbfd;
  direction: ltr; }

.cr-comments-panel .cr-chatbox-input__input {
  margin-top: 0; }

.cr-comments-panel__message-item {
  background: #fff;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 40px;
  padding-right: 30px;
  display: flex; }

.cr-comments-panel__message-highlight {
  background: #e5f1fa; }

.cr-comments-panel__message-author {
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  color: #334856; }

.cr-comments-panel__message-time {
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #829fb1;
  margin-left: 10px; }

.cr-comments-panel__message-content {
  padding-left: 14px;
  flex: 1 1; }

.cr-comments-panel__message-text {
  font-size: 14px;
  color: #334856;
  line-break: anywhere; }
  .cr-comments-panel__message-text .user-tag {
    color: #795afa;
    padding: 0;
    text-decoration: none; }

.cr-comments-panel__section-messages {
  overflow-y: auto;
  direction: rtl; }

.cr-comments-panel__messages-list {
  direction: ltr; }

.cr-comments-panel__members {
  display: flex;
  align-items: center; }

.cr-comments-panel__member-item {
  margin: 0 8px; }

.cr-comments-panel__message-avatar {
  margin-top: 8px; }

.cr-comments-panel__message-avatar-text {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #ffffff;
  background: #0f55b9; }

.cr-comments-panel__box-members-container {
  position: relative; }

.cr-comments-panel__button-plus {
  border: 1px solid #c2d4e0;
  width: 36px;
  height: 36px;
  background: #ffffff;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.cr-comments-panel__box-conversation {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 278px;
  position: absolute;
  z-index: 99;
  top: -8px;
  left: 40px; }

.cr-comments-panel__box-head {
  background: #193478;
  border-radius: 4px 4px 0px 0px;
  height: 55px;
  display: flex;
  padding: 0 30px;
  align-items: center;
  position: relative; }
  .cr-comments-panel__box-head span {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #ffffff; }

.cr-comments-panel__box-title {
  padding: 25px 30px 6px 30px; }
  .cr-comments-panel__box-title span {
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #6ea7da; }

.cr-comments-panel__box-member-item {
  padding: 8px 30px;
  font-size: 14px;
  color: #334856;
  cursor: pointer; }
  .cr-comments-panel__box-member-item:hover {
    background: #ebf2f7; }
  .cr-comments-panel__box-member-item strong {
    margin-right: 5px; }

.cr-comments-panel__box-members {
  padding-bottom: 18px; }

.cr-comments-panel__box-arrow {
  position: absolute;
  left: -13px; }

.cr-comments-panel__message-attachments .cr-chat-attachment {
  border: 1px solid #c2d4e0;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px; }

.cr-quill-editor .ql-toolbar {
  background: #f7fbfd;
  border-color: #d7e3ea;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px; }
  .cr-quill-editor .ql-toolbar .ql-active {
    color: #e40061 !important; }
    .cr-quill-editor .ql-toolbar .ql-active .ql-stroke {
      stroke: #e40061 !important; }

.cr-quill-editor .ql-container {
  border-color: #d7e3ea;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 16px;
  font-family: "Mulish", sans-serif; }

.cr-quill-editor .ql-editor {
  min-height: 100px; }

.cr-quill-editor .ql-blank::before {
  font-style: inherit;
  color: #9197a3; }

.cr-order-details-view__col-view-field {
  margin-bottom: 46px !important; }

.cr-order-details-view__order-view-value {
  font-size: 16px;
  line-height: 20px;
  color: #000000; }

.cr-order-details-view__section-fields {
  margin-top: 30px; }

.cr-order-details-view__section-title {
  text-transform: capitalize; }

.cr-copy-order-modal {
  min-width: 573px; }
  .cr-copy-order-modal .modal-body {
    padding: 0; }
  .cr-copy-order-modal__content {
    min-height: 581px;
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
  .cr-copy-order-modal__title {
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #000000;
    padding: 0 90px;
    margin-bottom: 30px; }
  .cr-copy-order-modal__slide-spots {
    padding-top: 40px;
    text-align: center; }
  .cr-copy-order-modal__summary-spots {
    padding-top: 40px;
    text-align: center; }
  .cr-copy-order-modal__slide-spot {
    padding-bottom: 30px; }
    .cr-copy-order-modal__slide-spot .col-6 {
      text-align: center; }
    .cr-copy-order-modal__slide-spot .cr-checkbox {
      margin-bottom: 15px; }
    .cr-copy-order-modal__slide-spot .cr-checkbox__text {
      color: #000000;
      text-transform: capitalize; }
  .cr-copy-order-modal__buttons {
    padding-bottom: 40px;
    text-align: center; }
    .cr-copy-order-modal__buttons .btn {
      margin: 0 7.5px; }
  .cr-copy-order-modal__spot-number {
    font-size: 12px;
    line-height: 15px;
    color: #334856;
    text-transform: uppercase; }
  .cr-copy-order-modal__checkbox-spots .cr-checkbox__text {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #334856; }
  .cr-copy-order-modal__checkbox-spot {
    text-align: left;
    max-width: 60%;
    margin: 0 auto;
    margin-bottom: 15px; }
    .cr-copy-order-modal__checkbox-spot .cr-copy-order-modal__spot-number {
      margin-left: 33px; }
  .cr-copy-order-modal__select-spot-header {
    padding: 20px 40px;
    border-bottom: 1px solid #c2d4e0; }
  .cr-copy-order-modal__spot-title {
    margin-bottom: 0;
    font-weight: 800;
    font-size: 30px;
    line-height: 38px;
    color: #334856; }
  .cr-copy-order-modal__spot-switch-template {
    text-align: center;
    margin-top: 30px; }
    .cr-copy-order-modal__spot-switch-template .cr-toggle-switch {
      align-items: center;
      justify-content: center; }
    .cr-copy-order-modal__spot-switch-template .cr-toggle-switch__left-component,
    .cr-copy-order-modal__spot-switch-template .cr-toggle-switch__right-component {
      text-transform: uppercase;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      font-weight: 500;
      color: #829fb1;
      max-width: 190px; }
      .cr-copy-order-modal__spot-switch-template .cr-toggle-switch__left-component.active,
      .cr-copy-order-modal__spot-switch-template .cr-toggle-switch__right-component.active {
        font-weight: 700;
        color: #334856; }
  .cr-copy-order-modal__copy-to-spot-text {
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    display: block;
    text-align: center;
    margin: 20px 0; }
  .cr-copy-order-modal__field-checkboxes {
    display: inline-flex;
    flex-direction: column; }
  .cr-copy-order-modal__spot-template {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #829fb1;
    text-transform: uppercase;
    margin-left: 33px; }

.cr-hub-modal {
  max-width: 562px; }
  .cr-hub-modal .modal-header {
    padding: 0;
    border-bottom: none;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 21px; }
    .cr-hub-modal .modal-header .close {
      display: none; }
  .cr-hub-modal .modal-title {
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #000000; }
  .cr-hub-modal .modal-body form {
    max-width: 362px;
    margin: 0 auto; }
    .cr-hub-modal .modal-body form::-webkit-input-placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #829fb1; }
    .cr-hub-modal .modal-body form::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #829fb1; }
    .cr-hub-modal .modal-body form .form-group {
      margin-bottom: 28px; }
  .cr-hub-modal__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 34px;
    margin-bottom: 34px; }
    .cr-hub-modal__buttons .btn {
      margin: 0 7.5px; }

.delete-hub-modal .modal-body {
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: #000000; }

.cr-hub-manager-details .ReactTable {
  border: none; }

.cr-hub-manager-details .rt-td {
  padding: 16px 0;
  border-right: none !important;
  cursor: pointer; }

.cr-hub-manager-details .rt-tr-group {
  border-bottom-color: #edf1f6; }

.cr-hub-manager-details__table .rt-thead {
  background: #224b8b; }

.cr-hub-manager-details__table .rt-th {
  padding: 17px 0 !important;
  border-right: 0 !important; }
  .cr-hub-manager-details__table .rt-th span {
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    color: #ffffff; }

.cr-hub-manager-details__table.empty-list .pagination-bottom {
  display: none; }

.cr-hub-manager-details__table.empty-list .table-no-data {
  position: relative;
  text-align: center; }

.cr-hub-manager-details__th-name {
  padding-left: 43px; }

.cr-hub-manager-details__td-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  font-size: 18px;
  color: #334856; }

.cr-hub-manager-details .other-market-hub-value {
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize; }

.cr-hub-manager-details__role-name {
  width: 27px;
  height: 27px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #d6e5ef;
  border-radius: 100px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #657894;
  margin-right: 5px; }

.cr-hub-manager-details__th-sort {
  display: flex; }

.cr-hub-manager-details__sortable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  text-align: center;
  margin-left: 8px; }

.cr-hub-manager-details__sortable-icon {
  position: absolute;
  font-size: 16px;
  color: #fff; }

.cr-hub-manager-details__sortable-active {
  color: #c12674; }

.cr-hub-manager-details__tooltip {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  opacity: 1;
  padding: 0; }
  .cr-hub-manager-details__tooltip .rc-tooltip-content {
    background: #ffffff;
    padding: 24px;
    border-radius: 4px; }
    .cr-hub-manager-details__tooltip .rc-tooltip-content .other-market-hub-value {
      color: #000000;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px; }
  .cr-hub-manager-details__tooltip .rc-tooltip-arrow {
    border-top-color: #ffffff;
    border-width: 10px 10px 0;
    bottom: -8px; }
  .cr-hub-manager-details__tooltip .rc-tooltip-inner {
    padding: 0;
    background-color: #ffffff;
    box-shadow: none;
    min-height: auto; }

.cr-hub-manager-details__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  padding-left: 35px;
  padding-right: 61px; }

.cr-hub-manager-details__hub-name {
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  color: #111b2b; }

.cr-hub-manager-details__btn-stations {
  width: 206px; }
  .cr-hub-manager-details__btn-stations span {
    font-weight: 800;
    font-size: 18px;
    line-height: 23px;
    color: #374c59;
    margin-left: 17px;
    text-transform: capitalize; }

.cr-hub-manager-details__name-column {
  font-weight: 600;
  padding-left: 43px;
  font-size: 18px;
  line-height: 52px; }

.cr-hub-manager-details__assignments-summary {
  margin-bottom: 28px;
  padding-left: 35px;
  padding-right: 61px;
  display: flex;
  align-items: center; }

.cr-hub-manager-details__assignments-summary-text {
  color: #000000;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px; }

.cr-hub-manager-details__role-summary {
  display: flex;
  flex-direction: row; }

.cr-hub-manager-details__role-summary-item {
  font-size: 16px;
  line-height: 20px;
  margin: 0 25px; }
  .cr-hub-manager-details__role-summary-item span {
    color: #334856;
    margin-right: 12px; }
  .cr-hub-manager-details__role-summary-item strong {
    color: #795afa; }

.cr-hub-manager-details__content-assign-stations {
  padding: 35px;
  height: calc(100% - 70px); }

.cr-hub-manager-details__hub-assign-description {
  font-size: 16px;
  line-height: 20px;
  color: #111b2b; }

.cr-hub-manager-details__th-center {
  text-align: center; }

.cr-hub-assign-stations {
  padding: 35px;
  flex: 1 1;
  overflow-y: scroll;
  background: #fff; }
  .cr-hub-assign-stations__hub-assign-description {
    margin-bottom: 30px; }
  .cr-hub-assign-stations .nav-tabs {
    margin-left: 0 !important; }
  .cr-hub-assign-stations__content-view .info {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #111b2b;
    padding: 0px 7px; }
  .cr-hub-assign-stations__content-view .form-group label {
    text-transform: uppercase !important;
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #43535c; }
  .cr-hub-assign-stations__content-view .form-group input {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000000; }
  .cr-hub-assign-stations__content-view .add-stations-btn {
    position: absolute;
    left: -24px;
    height: 611px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .cr-hub-assign-stations__content-view .tab-pane-separator {
    height: 17px;
    position: absolute;
    width: 86%;
    z-index: 10;
    background: #fff; }
  .cr-hub-assign-stations__col1 {
    padding-right: 40px; }
  .cr-hub-assign-stations__col2 {
    padding-left: 40px; }
  .cr-hub-assign-stations__nav-item {
    margin-bottom: -5px !important; }
  .cr-hub-assign-stations__nav-link {
    font-size: 12px !important;
    text-align: center;
    display: inline-block !important;
    padding: 12px 26px !important;
    color: #795afa !important;
    border-top-right-radius: 6px !important;
    border-top-left-radius: 6px !important;
    border: none !important;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    line-height: 15px;
    font-weight: normal; }
    .cr-hub-assign-stations__nav-link:hover {
      color: #43535c !important; }
    .cr-hub-assign-stations__nav-link.active {
      color: #43535c !important;
      box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.102);
      font-weight: normal !important; }
  .cr-hub-assign-stations__tab-content {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px;
    overflow: hidden;
    padding: 36px 30px;
    height: 573px; }
  .cr-hub-assign-stations__tags-container {
    background: #f7fbfd;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 6px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 501px; }
    .cr-hub-assign-stations__tags-container .list-group-item {
      background: none;
      border: none;
      margin: 0px;
      padding: 0; }
      .cr-hub-assign-stations__tags-container .list-group-item .tag-line-item {
        background: #f7fbfd;
        border: none;
        border-bottom: 1px solid #dfe8ee;
        margin: 0px;
        padding: 12px 16px;
        display: flex;
        align-items: center; }
        .cr-hub-assign-stations__tags-container .list-group-item .tag-line-item .cr-checkbox__text {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.05em;
          color: #829fb1;
          text-transform: uppercase;
          margin-left: 15px; }
        .cr-hub-assign-stations__tags-container .list-group-item .tag-line-item .btn.expand-btn {
          padding: 0 !important;
          min-width: unset;
          display: flex;
          align-items: flex-end;
          justify-content: right;
          margin: 0;
          float: right;
          height: 20px;
          line-height: 20px; }
      .cr-hub-assign-stations__tags-container .list-group-item:last-child .tag-line-item:not(.open) {
        border-bottom: none; }
      .cr-hub-assign-stations__tags-container .list-group-item:last-child .stations-selection-list .list-group-item:last-child .station-line-item {
        border-bottom: none; }
      .cr-hub-assign-stations__tags-container .list-group-item .stations-selection-list .list-group-item .station-line-item {
        background: #ffffff;
        border: none;
        border-bottom: 1px solid #dfe8ee;
        margin: 0px;
        padding: 2px 16px;
        display: flex;
        align-items: center; }
        .cr-hub-assign-stations__tags-container .list-group-item .stations-selection-list .list-group-item .station-line-item .cr-checkbox__text {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 39px;
          color: #334856;
          text-transform: uppercase;
          margin-left: 15px; }
        .cr-hub-assign-stations__tags-container .list-group-item .stations-selection-list .list-group-item .station-line-item .station-name {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 36px;
          color: #334856;
          width: 100%; }
        .cr-hub-assign-stations__tags-container .list-group-item .stations-selection-list .list-group-item .station-line-item .col-6 {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 39px;
          color: #334856; }
      .cr-hub-assign-stations__tags-container .list-group-item .cr-checkbox {
        width: 100%; }
        .cr-hub-assign-stations__tags-container .list-group-item .cr-checkbox .rc-checkbox-inner {
          width: 21px;
          height: 21px; }
          .cr-hub-assign-stations__tags-container .list-group-item .cr-checkbox .rc-checkbox-inner:after {
            left: 6px;
            top: 2px; }
        .cr-hub-assign-stations__tags-container .list-group-item .cr-checkbox .rc-checkbox-disabled .rc-checkbox-inner {
          background: #c2d4e0;
          border-color: #c2d4e0; }
          .cr-hub-assign-stations__tags-container .list-group-item .cr-checkbox .rc-checkbox-disabled .rc-checkbox-inner:after {
            width: 7px;
            height: 13px;
            border: 3px solid #ffffff;
            border-top: 0;
            border-left: 0; }
        .cr-hub-assign-stations__tags-container .list-group-item .cr-checkbox .cr-checkbox__checked-icon {
          left: 4px;
          top: 2px; }
      .cr-hub-assign-stations__tags-container .list-group-item .cr-checkbox__checked-icon {
        left: 4px;
        top: 2px; }
  .cr-hub-assign-stations__stations-container {
    height: 611px;
    border: 1px solid #c2d4e0;
    border-radius: 6px; }
    .cr-hub-assign-stations__stations-container .table-container {
      height: 524px; }
      .cr-hub-assign-stations__stations-container .table-container .ReactTable {
        border: none;
        height: 100%; }
        .cr-hub-assign-stations__stations-container .table-container .ReactTable .rt-thead.-header {
          background: #f7fbfd;
          border-radius: 6px 6px;
          box-shadow: none;
          flex: unset; }
          .cr-hub-assign-stations__stations-container .table-container .ReactTable .rt-thead.-header .rt-th {
            border: none;
            border-bottom: 1px solid #c2d4e0;
            justify-content: left;
            display: flex;
            padding: 5px 20px; }
            .cr-hub-assign-stations__stations-container .table-container .ReactTable .rt-thead.-header .rt-th .header {
              font-style: normal;
              line-height: 18px;
              letter-spacing: 0.05em;
              padding: 6px 0px; }
            .cr-hub-assign-stations__stations-container .table-container .ReactTable .rt-thead.-header .rt-th .header:not(.last) {
              font-weight: normal;
              color: #829fb1;
              font-size: 12px;
              text-transform: uppercase; }
            .cr-hub-assign-stations__stations-container .table-container .ReactTable .rt-thead.-header .rt-th .header.last {
              font-weight: bold;
              font-size: 14px;
              text-align: right;
              color: #111b2b;
              text-transform: lowercase; }
            .cr-hub-assign-stations__stations-container .table-container .ReactTable .rt-thead.-header .rt-th:last-child {
              justify-content: center !important; }
        .cr-hub-assign-stations__stations-container .table-container .ReactTable .rt-tbody {
          max-height: 483px;
          flex: unset; }
          .cr-hub-assign-stations__stations-container .table-container .ReactTable .rt-tbody .rt-tr-group {
            border: none; }
            .cr-hub-assign-stations__stations-container .table-container .ReactTable .rt-tbody .rt-tr-group .rt-tr {
              border-bottom: 1px solid #dfe8ee; }
              .cr-hub-assign-stations__stations-container .table-container .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td {
                border-right: none;
                padding: 8px 22px;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 26px;
                display: inline-block !important;
                color: #111b2b; }
                .cr-hub-assign-stations__stations-container .table-container .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td .trash-icon {
                  padding: 0 !important;
                  height: inherit;
                  line-height: inherit;
                  min-width: 100%;
                  display: flex;
                  justify-content: center; }
    .cr-hub-assign-stations__stations-container .buttons-container {
      padding: 20px 27px;
      background: #f7fbfd !important;
      border-top: 1px solid #c2d4e0;
      border-radius: 0px 0px 6px 6px; }
      .cr-hub-assign-stations__stations-container .buttons-container button[type="submit"] {
        margin-right: 15px;
        width: 192.37px;
        height: 44px; }
      .cr-hub-assign-stations__stations-container .buttons-container .btn-blue:disabled,
      .cr-hub-assign-stations__stations-container .buttons-container .btn-blue:disabled:hover {
        background: #c2d4e0 !important;
        border-color: #c2d4e0;
        opacity: 1; }
      .cr-hub-assign-stations__stations-container .buttons-container button.cr-hub-assign-stations__button-clear,
      .cr-hub-assign-stations__stations-container .buttons-container button.cr-hub-assign-stations__button-cancel {
        width: 136px;
        height: 44px; }
      .cr-hub-assign-stations__stations-container .buttons-container button.cr-hub-assign-stations__button-clear {
        margin-right: 15px; }
    .cr-hub-assign-stations__stations-container .no-stations-selected {
      display: block;
      position: absolute;
      background: none;
      transition: all 0.3s ease;
      z-index: 1;
      pointer-events: none;
      padding: 20px;
      min-height: 565px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-style: normal;
      font-weight: 800;
      font-size: 23px;
      line-height: 29px;
      text-align: center;
      color: #111b2b;
      white-space: break-spaces;
      word-break: break-word; }
      .cr-hub-assign-stations__stations-container .no-stations-selected span {
        max-width: 340px; }
  .cr-hub-assign-stations__search-container .search-header h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #334856;
    margin-bottom: 10px; }
  .cr-hub-assign-stations__search-container .search-header .cr-textinput__input-wrapper {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
    border-radius: 50px; }
    .cr-hub-assign-stations__search-container .search-header .cr-textinput__input-wrapper .cr-textinput__input-field input {
      padding: 0.375rem 1.75rem;
      border-radius: 50px;
      color: #000000; }
      .cr-hub-assign-stations__search-container .search-header .cr-textinput__input-wrapper .cr-textinput__input-field input:hover, .cr-hub-assign-stations__search-container .search-header .cr-textinput__input-wrapper .cr-textinput__input-field input:focus {
        border: none;
        box-shadow: none; }
    .cr-hub-assign-stations__search-container .search-header .cr-textinput__input-wrapper .cr-textinput__input-field input::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-size: 14px;
      color: #829fb1;
      opacity: 1;
      /* Firefox */ }
    .cr-hub-assign-stations__search-container .search-header .cr-textinput__input-wrapper .cr-textinput__input-field input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-size: 14px;
      color: #829fb1;
      opacity: 1;
      /* Firefox */ }
    .cr-hub-assign-stations__search-container .search-header .cr-textinput__input-wrapper .cr-textinput__input-field input::-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: 14px;
      color: #829fb1; }
    .cr-hub-assign-stations__search-container .search-header .cr-textinput__input-wrapper .cr-textinput__input-field input::-ms-input-placeholder {
      /* Microsoft Edge  */
      font-size: 14px;
      color: #829fb1; }
    .cr-hub-assign-stations__search-container .search-header .cr-textinput__input-wrapper .cr-textinput__right-component .btn-search {
      text-align: right;
      min-width: unset; }
  .cr-hub-assign-stations__search-container .search-results {
    margin-top: 30px; }
    .cr-hub-assign-stations__search-container .search-results h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: #334856; }
    .cr-hub-assign-stations__search-container .search-results .table-container {
      border-radius: 6px;
      border: 1px solid #c2d4e0;
      min-height: 68px;
      max-height: 350px;
      overflow-y: auto; }
      .cr-hub-assign-stations__search-container .search-results .table-container .ReactTable {
        border: none;
        height: 350px; }
        .cr-hub-assign-stations__search-container .search-results .table-container .ReactTable .rt-table {
          height: 100%; }
        .cr-hub-assign-stations__search-container .search-results .table-container .ReactTable .rt-thead {
          background: #f7fbfd;
          border-radius: 6px 6px 0px 0px;
          box-shadow: none; }
          .cr-hub-assign-stations__search-container .search-results .table-container .ReactTable .rt-thead .rt-th {
            border: none;
            border-bottom: 1px solid #c2d4e0;
            justify-content: left;
            display: flex;
            padding: 14px 6px; }
            .cr-hub-assign-stations__search-container .search-results .table-container .ReactTable .rt-thead .rt-th .header {
              font-weight: normal;
              color: #829fb1;
              font-size: 12px;
              text-transform: uppercase; }
            .cr-hub-assign-stations__search-container .search-results .table-container .ReactTable .rt-thead .rt-th:first-child {
              max-width: 40px; }
        .cr-hub-assign-stations__search-container .search-results .table-container .ReactTable .rt-tr .rt-td {
          border-right: none;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: block !important;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 30px;
          color: #334856; }
          .cr-hub-assign-stations__search-container .search-results .table-container .ReactTable .rt-tr .rt-td:first-child {
            padding-left: 12px; }
          .cr-hub-assign-stations__search-container .search-results .table-container .ReactTable .rt-tr .rt-td .cr-checkbox__checkbox {
            display: block; }
          .cr-hub-assign-stations__search-container .search-results .table-container .ReactTable .rt-tr .rt-td .rc-checkbox-inner {
            width: 21px;
            height: 21px; }
            .cr-hub-assign-stations__search-container .search-results .table-container .ReactTable .rt-tr .rt-td .rc-checkbox-inner:after {
              left: 6px;
              top: 1px; }
          .cr-hub-assign-stations__search-container .search-results .table-container .ReactTable .rt-tr .rt-td .rc-checkbox-disabled .rc-checkbox-inner {
            background: #c2d4e0;
            border-color: #c2d4e0; }
            .cr-hub-assign-stations__search-container .search-results .table-container .ReactTable .rt-tr .rt-td .rc-checkbox-disabled .rc-checkbox-inner:after {
              width: 7px;
              height: 13px;
              border: 3px solid #ffffff;
              border-top: 0;
              border-left: 0; }
          .cr-hub-assign-stations__search-container .search-results .table-container .ReactTable .rt-tr .rt-td .cr-checkbox__checked-icon {
            left: 4px;
            top: 2px; }
        .cr-hub-assign-stations__search-container .search-results .table-container .ReactTable .no-results {
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          color: #829fb1;
          display: flex;
          align-items: center;
          padding: 35px; }
  .cr-hub-assign-stations .btn-blue.btn-primary {
    background: #795afa !important;
    border-color: #5129f9; }
    .cr-hub-assign-stations .btn-blue.btn-primary:hover {
      background: #795afa !important;
      border-color: #5129f9; }
  .cr-hub-assign-stations__form-buttons {
    display: flex; }

@media screen and (min-width: 1200px) and (max-width: 1625px) {
  .cr-hub-assign-stations__nav-link {
    letter-spacing: 0em;
    padding: 12px 12px !important; }
  .cr-hub-assign-stations__stations-container .buttons-container {
    padding: 20px 28px; }
    .cr-hub-assign-stations__stations-container .buttons-container button {
      min-width: 108px !important;
      width: 30% !important; }
    .cr-hub-assign-stations__stations-container .buttons-container button[type="submit"] {
      width: 31% !important; } }

.modal-dialog.cr-create-team-view {
  min-width: 1307px;
  min-height: 884px; }
  .modal-dialog.cr-create-team-view .modal-header {
    padding: 24px 42px; }
    .modal-dialog.cr-create-team-view .modal-header .modal-title {
      font-style: normal;
      font-weight: 800;
      font-size: 23px;
      line-height: 29px;
      color: #111b2b; }

.cr-hub-details__nav-link {
  font-size: 16px !important;
  min-width: 226px;
  text-align: center;
  display: inline-block !important;
  padding: 12px 6px !important;
  color: #657894;
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
  border: none !important;
  text-transform: capitalize; }
  .cr-hub-details__nav-link.active {
    font-weight: bold !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    color: #657894 !important;
    position: relative; }
    .cr-hub-details__nav-link.active::after {
      left: 0;
      content: "";
      display: block;
      background-color: #fff;
      width: 100%;
      height: 20px;
      position: absolute; }

.cr-hub-details__tab-content {
  position: relative;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 48px 0 0 0; }

.cr-hub-details__back-to-list {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #657894;
  cursor: pointer;
  margin-bottom: 30px;
  display: block; }
  .cr-hub-details__back-to-list:hover {
    text-decoration: none;
    color: #657894; }

.cr-team-management-filter-view label {
  color: #43535c;
  text-transform: uppercase; }

.cr-team-management-filter-view__dropdown {
  margin-bottom: 35px; }

.cr-team-management-filter-view__filter-view-content {
  padding: 35px 50px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between; }

.cr-team-management-filter-view__buttons .btn-blue {
  margin-right: 14px; }
  .cr-team-management-filter-view__buttons .btn-blue.disabled {
    border-color: #c2d4e0;
    background-color: #c2d4e0;
    color: #fff; }

.cr-send-back-order-status-modal {
  max-width: 573px; }
  .cr-send-back-order-status-modal .modal-body {
    padding: 40px; }
  .cr-send-back-order-status-modal__title {
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #000000;
    max-width: 450px;
    margin: 0 auto; }
  .cr-send-back-order-status-modal__description {
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #657894;
    margin-top: 23px;
    margin-bottom: 40px; }
  .cr-send-back-order-status-modal__buttons {
    margin-top: 40px;
    text-align: center; }
    .cr-send-back-order-status-modal__buttons .btn {
      margin: 0 7.5px; }
  .cr-send-back-order-status-modal form {
    max-width: 367px;
    margin: 0 auto; }
    .cr-send-back-order-status-modal form label {
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #334856;
      text-transform: uppercase; }
  .cr-send-back-order-status-modal__select-status {
    margin-bottom: 35px; }
    .cr-send-back-order-status-modal__select-status .react-select__single-value {
      text-transform: capitalize; }
    .cr-send-back-order-status-modal__select-status .react-select__option {
      text-transform: capitalize; }

.cr-assign-vo-team-sidebar__instruction-header {
  height: 42px;
  padding: 0 32px;
  display: flex;
  background: #f7fbfd;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(194, 212, 224, 0.5);
  cursor: pointer; }

.cr-assign-vo-team-sidebar__section-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #334856;
  text-transform: uppercase;
  cursor: pointer; }
  .cr-assign-vo-team-sidebar__section-title svg {
    margin-right: 6px; }

.cr-assign-vo-team-sidebar__fake-icon {
  width: 13px;
  display: inline-block; }

.cr-assign-vo-team-sidebar__section-action {
  cursor: pointer; }

.cr-assign-vo-team-sidebar__section-content {
  padding: 32px;
  background: #fff; }

.cr-assign-vo-team-sidebar__content-details h4 {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #829fb1;
  margin-bottom: 12px;
  text-transform: uppercase; }

.cr-assign-vo-team-sidebar__content-details label {
  color: #829fb1;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 16px; }

.cr-assign-vo-team-sidebar__content-item {
  margin-bottom: 16px; }
  .cr-assign-vo-team-sidebar__content-item .cr-textarea-evaluator__wrapper-top {
    color: #829fb1; }
  .cr-assign-vo-team-sidebar__content-item .cr-textarea-evaluator__timer span {
    font-size: 14px; }

.cr-assign-vo-team-sidebar__content-text {
  font-size: 16px;
  color: #000000;
  margin-bottom: 0; }

.cr-assign-vo-team-sidebar__bold-text {
  color: #000000;
  font-weight: 700;
  margin-left: 5px; }

.cr-assign-vo-team-sidebar__approved-label {
  margin-bottom: 10px !important; }

.cr-assign-vo-team-sidebar__scroll-content {
  overflow-y: scroll;
  background: #fff;
  margin-right: 2px; }

.cr-assign-vo-team-sidebar__assigned-footer {
  height: 84px;
  display: flex;
  align-items: center;
  padding: 0 25px;
  border-top: 1px solid #c2d4e0; }
  .cr-assign-vo-team-sidebar__assigned-footer .btn-blue {
    margin-right: 14px; }

.cr-assign-vo-team-sidebar .cr-textarea-evaluator__container {
  padding: 0;
  border-radius: 0; }

.cr-assign-vo-team-sidebar__content-buttons {
  margin-top: 20px; }
  .cr-assign-vo-team-sidebar__content-buttons .btn-cancel {
    margin-left: 14px; }

.cr-assign-vo-team-sidebar__script-file-label {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.cr-assign-vo-team-sidebar__spot-length {
  margin-bottom: 10px; }

.cr-team-list-orders__table {
  width: 100%; }
  .cr-team-list-orders__table thead {
    background: #f7fbfd;
    height: 38px; }
    .cr-team-list-orders__table thead th:first-child {
      padding-left: 70px; }
    .cr-team-list-orders__table thead th span {
      color: #829fb1;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase; }
  .cr-team-list-orders__table tr {
    border-bottom: 1px solid #edf1f6;
    cursor: pointer; }
    .cr-team-list-orders__table tr td {
      padding-top: 5px;
      padding-bottom: 5px; }

.cr-team-list-orders__order-disabled {
  cursor: not-allowed !important; }

.cr-team-list-orders__empty-orders {
  text-align: center;
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.cr-team-list-orders__order-status {
  border: 1px solid rgba(194, 212, 224, 0.5);
  border-radius: 4px;
  display: inline-flex;
  min-width: 122px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  color: #9aa4ca;
  height: 25px;
  align-items: center;
  padding: 0 10px;
  justify-content: center;
  text-align: center; }

.cr-team-list-orders .cr-count-down {
  display: inline-flex; }

.cr-team-list-orders__order-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #111b2b; }

.cr-team-list-orders__client-name {
  font-size: 14px;
  line-height: 18px;
  padding-left: 70px;
  color: #334856; }

.cr-team-list-orders__role-name {
  width: 27px;
  height: 27px;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #d6e5ef;
  margin-right: 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #657894; }

.cr-team-list-orders__flight-dates {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #829fb1; }

.cr-processes-filter-view {
  margin-right: 5px; }
  .cr-processes-filter-view .scroll-bar-style {
    overflow-y: scroll;
    padding: 35px 50px;
    box-sizing: border-box;
    overflow-x: hidden; }
  .cr-processes-filter-view__filter-view-content-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; }
  .cr-processes-filter-view label {
    color: #43535c;
    text-transform: uppercase; }
  .cr-processes-filter-view__dropdown {
    margin-bottom: 35px; }
  .cr-processes-filter-view__filter-view-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 70px); }
  .cr-processes-filter-view__buttons {
    padding-top: 30px;
    padding-bottom: 30px; }
    .cr-processes-filter-view__buttons .btn-blue {
      margin-right: 14px; }
      .cr-processes-filter-view__buttons .btn-blue.disabled {
        border-color: #c2d4e0;
        background-color: #c2d4e0;
        color: #fff; }
  .cr-processes-filter-view .input-container {
    background-color: #fff; }
  .cr-processes-filter-view__produced-buttons {
    margin-top: 24px;
    display: flex; }
  .cr-processes-filter-view__production-buttons {
    padding-top: 24px;
    margin-top: 0;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
    height: 92px; }
  .cr-processes-filter-view__sticky-bottom-buttons {
    height: 95px;
    background: #f7fbfd;
    border-top: 1px solid #c2d4e0;
    padding: 24px 50px; }
  .cr-processes-filter-view .cr-checkbox__text {
    text-transform: capitalize; }
  .cr-processes-filter-view__spot-types, .cr-processes-filter-view__statuses {
    margin-bottom: 14px; }

.cr-right-sidebar__filter-view-produced-spots .cr-processes-filter-view__col-reset {
  margin-bottom: 0 !important; }

.cr-textarea label {
  color: #43535c;
  text-transform: uppercase;
  line-height: 15px;
  letter-spacing: 0.05em; }

.cr-textarea textarea {
  background: #f7fbfd;
  border: 1px solid #c2d4e0;
  border-radius: 6px;
  padding: 15px;
  min-height: 62px;
  width: 100%;
  box-sizing: border-box; }
  .cr-textarea textarea:focus {
    background-color: #fff;
    border-color: #795afa;
    box-shadow: 0 0 0 0.2rem rgba(121, 90, 250, 0.25);
    outline: 0; }
  .cr-textarea textarea:hover {
    border-color: #4324c7;
    box-shadow: 0 0 0 1px #4324c7; }

.cr-header-mobile {
  background-color: #121d2f;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999; }
  .cr-header-mobile img {
    max-width: 159px; }

.cr-footer {
  text-align: center;
  margin-bottom: 50px; }
  .cr-footer a {
    color: #795afa;
    margin: 0 4px;
    text-transform: uppercase; }
  .cr-footer p {
    margin-bottom: 5px;
    color: #657894; }

.cr-zetta .card-body {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0; }

.cr-zetta__card-title {
  color: #000000;
  font-weight: 700;
  font-size: 23px; }

.cr-zetta__section-header {
  padding-left: 47px;
  padding-right: 47px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.cr-zetta__section-actions {
  display: flex;
  display: flex;
  flex-direction: row;
  grid-gap: 35px;
  gap: 35px; }
  .cr-zetta__section-actions .cr-toggle-switch .cr-toggle-switch__left-component,
  .cr-zetta__section-actions .cr-toggle-switch .cr-toggle-switch__right-component {
    text-transform: uppercase;
    color: #829fb1;
    font-weight: 400; }
    .cr-zetta__section-actions .cr-toggle-switch .cr-toggle-switch__left-component.active,
    .cr-zetta__section-actions .cr-toggle-switch .cr-toggle-switch__right-component.active {
      color: #000000;
      font-weight: 700; }

.cr-zetta__btn-icon {
  cursor: pointer; }

.cr-zetta__head {
  background-color: #657894;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 47px;
  align-items: center; }
  .cr-zetta__head .cr-zetta__head-label:first-child {
    max-width: 225px;
    width: 225px;
    display: inline-flex;
    text-align: left; }

.cr-zetta__tr {
  margin-bottom: 20px; }

.cr-zetta__head-label {
  color: #ffffff;
  font-size: 17px;
  font-weight: 700;
  flex: 1 1;
  text-align: center; }

.cr-zetta__market-name {
  color: #334856;
  font-size: 24px;
  margin-bottom: 10px; }

.cr-zetta__body {
  padding: 20px 47px; }

.cr-zetta__station-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px; }

.cr-zetta__station-name {
  color: #334856;
  font-weight: 600;
  font-size: 16px;
  width: 225px; }

.cr-zetta__missed-info span {
  color: #657894;
  margin-right: 20px;
  display: inline-block; }

.cr-zetta__missed-info svg {
  cursor: pointer; }

.cr-zetta__port {
  width: 101px;
  max-width: 101px; }

.cr-zetta__ftp-path, .cr-zetta__subfolder, .cr-zetta__username, .cr-zetta__password {
  width: 164px;
  max-width: 164px; }

.cr-zetta__edit-settings {
  display: flex;
  flex-direction: row; }
  .cr-zetta__edit-settings .form-control {
    border: none;
    border-radius: none; }
  .cr-zetta__edit-settings .text-danger {
    font-size: 14px;
    padding-left: 2px;
    padding-right: 2px; }
  .cr-zetta__edit-settings input:focus, .cr-zetta__edit-settings input:hover {
    box-shadow: none;
    outline: none; }

.cr-zetta__view-settings {
  display: flex;
  flex-direction: row; }

.cr-zetta__ftp-path .cr-textinput__input-wrapper {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none; }

.cr-zetta__port .cr-textinput__input-wrapper, .cr-zetta__subfolder .cr-textinput__input-wrapper, .cr-zetta__username .cr-textinput__input-wrapper {
  border-right: none;
  border-radius: 0; }

.cr-zetta__password .cr-textinput__input-wrapper {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.cr-zetta .btn-save {
  margin-left: 20px;
  margin-top: 3.5px;
  width: 134px;
  display: flex;
  align-items: center;
  justify-content: center; }

.cr-zetta .btn-cancel {
  margin-top: 2.5px;
  margin-left: 14px; }

.cr-zetta__btn-delete {
  margin-left: 15px;
  cursor: pointer; }

.cr-zetta__btn-progress-success {
  background-color: #73a703 !important;
  border-color: #73a703; }
  .cr-zetta__btn-progress-success:hover {
    border-color: #73a703;
    outline: none; }
  .cr-zetta__btn-progress-success:focus {
    box-shadow: none; }
  .cr-zetta__btn-progress-success span {
    margin-left: 10px; }

.cr-zetta__btn-progress-error {
  background-color: #e40061 !important;
  border-color: #e40061; }
  .cr-zetta__btn-progress-error:hover {
    border-color: #e40061;
    outline: none; }
  .cr-zetta__btn-progress-error:focus {
    box-shadow: none; }
  .cr-zetta__btn-progress-error span {
    margin-left: 10px; }

.cr-zetta__btn-progress {
  border-color: transparent;
  background: linear-gradient(to right, #73a703 90%, #795afa 10%); }
  .cr-zetta__btn-progress:hover {
    border-color: transparent;
    background: linear-gradient(to right, #73a703 90%, #795afa 10%);
    outline: none; }
  .cr-zetta__btn-progress:focus {
    box-shadow: none; }

.cr-zetta__text {
  color: #334856;
  text-align: center; }

.cr-zetta__btn-edit {
  margin-left: 15px;
  cursor: pointer; }

.cr-video-player {
  text-align: center; }

.cr-production-deadlines__deadline-group {
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  align-items: flex-end; }
  .cr-production-deadlines__deadline-group .input-container {
    margin-bottom: 0 !important; }
  .cr-production-deadlines__deadline-group .react-select-container {
    min-width: 140px; }

.cr-production-deadlines__section-title {
  text-transform: capitalize; }

.cr-production-deadlines__col-view-field {
  margin-bottom: 46px !important; }

.cr-production-deadlines__order-view-value {
  font-size: 16px;
  line-height: 20px;
  color: #000000; }

.cr-page__page-top,
.cr-page .page-container {
  padding-left: 50px;
  padding-right: 50px; }

.cr-page__header {
  padding-bottom: 20px; }

.cr-page .page-container {
  padding-bottom: 50px; }

.cr-page__page-padding {
  padding-left: 50px;
  padding-right: 50px; }

.cr-page__page-header {
  background-color: #336abb;
  height: 193px; }

.cr-page__title {
  font-weight: 800;
  font-size: 32px;
  margin-bottom: 0;
  color: #ffffff; }

.cr-page__breadcrumb .active {
  font-weight: 600;
  color: #657894; }

@media (min-width: 1599px) {
  .cr-page__page-top {
    padding-left: 70px;
    padding-right: 70px; }
  .cr-page .page-container {
    padding-left: 70px;
    padding-right: 70px;
    padding-bottom: 50px; } }

.cr-page-form-manager .cr-page__header {
  margin-left: 10px; }

.cr-page-form-manager .cr-page__header .cr-page__breadcrumb {
  padding-top: 0;
  padding-bottom: 0; }

.cr-page-form-manager .breadcrumb {
  margin-bottom: 0; }

.cr-page-form-manager .breadcrumb-item {
  color: #657894;
  font-weight: normal; }
  .cr-page-form-manager .breadcrumb-item a {
    color: #657894; }
    .cr-page-form-manager .breadcrumb-item a:hover {
      text-decoration: none; }
  .cr-page-form-manager .breadcrumb-item.active {
    font-weight: bold; }

.cr-add-update-process {
  background-color: #f7fbfd; }
  .cr-add-update-process--production_provided .form-tab-dub_script, .cr-add-update-process--basic_production .form-tab-dub_script {
    padding-bottom: 0 !important; }
  .cr-add-update-process--basic_production .form-edit-mode.form-tab-assign_vo .assign-vo-bottom-buttons {
    display: none; }
  .cr-add-update-process--basic_production .form-edit-mode.form-tab-write_script, .cr-add-update-process--basic_production .form-edit-mode.form-tab-continuity,
  .cr-add-update-process--basic_production .form-view-mode.form-tab-write_script,
  .cr-add-update-process--basic_production .form-view-mode.form-tab-continuity {
    padding-bottom: 100px; }
  .cr-add-update-process--liner .write-script-bottom-buttons {
    display: none !important; }
  .cr-add-update-process--contest .form-tab-winners {
    padding-bottom: 0 !important; }
  .cr-add-update-process--digital .form-tab-traffic,
  .cr-add-update-process--digital .form-tab-report,
  .cr-add-update-process--digital .form-tab-review_media {
    padding-bottom: 0 !important; }
  .cr-add-update-process__header {
    background-color: #336abb;
    color: white;
    padding: 10px 0px;
    min-width: 745px; }
  .cr-add-update-process__header-breadcrumb {
    font-size: 14px;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: capitalize; }
  .cr-add-update-process__header-client {
    margin-top: 47px;
    margin-bottom: 60px; }
    .cr-add-update-process__header-client .title-container {
      margin-bottom: 8px; }
  .cr-add-update-process__container-padding {
    padding: 0px 50px; }
  .cr-add-update-process__container-padding-tabs {
    padding: 0 40px;
    padding-top: 12px; }
  .cr-add-update-process__container-tabs-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 65px;
    transition: all 0.5ms;
    transition: padding-top 0.05s ease-out;
    background: linear-gradient(180deg, #f7fbfd 54.63%, rgba(247, 251, 253, 0) 100%); }
    .cr-add-update-process__container-tabs-sticky.tabs-sticky {
      z-index: 99;
      height: 108px; }
      .cr-add-update-process__container-tabs-sticky.tabs-sticky .cr-add-update-process__tabs {
        top: 0;
        background-color: transparent; }
  @media (max-width: 768px) {
    .cr-add-update-process__container-padding {
      padding: 0px 20px; } }
  .cr-add-update-process__client-name-wrapper {
    display: flex;
    align-items: center; }
  .cr-add-update-process__client-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .cr-add-update-process__client-name-icon {
    padding: 10px;
    height: 51px;
    width: 51px;
    border-radius: 50%;
    background: #edf3f7;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 15px;
    color: #8165f3; }
  .cr-add-update-process__header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8px; }
  .cr-add-update-process__header-content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
  .cr-add-update-process__order-head-info {
    display: inline-flex;
    align-items: center;
    margin-left: 140px;
    padding-right: 10px; }
  .cr-add-update-process__order-head-info-item {
    text-transform: uppercase; }
    .cr-add-update-process__order-head-info-item strong {
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      margin-right: 5px; }
      .cr-add-update-process__order-head-info-item strong span {
        color: #ffffff; }
    .cr-add-update-process__order-head-info-item span {
      font-size: 16px;
      line-height: 20px;
      color: #c2d4e0; }
  .cr-add-update-process__order-head-info-cart {
    margin-left: 30px; }
  @media (max-width: 480px) {
    .cr-add-update-process__header-right {
      justify-content: flex-start; } }
  .cr-add-update-process__header-timer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-right: 25px;
    color: #c1daff;
    text-transform: uppercase; }
  .cr-add-update-process__overdue {
    font-size: 16px;
    margin-right: 5px; }
  .cr-add-update-process__header-countdown {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: right;
    color: #a9d1f4; }
  .cr-add-update-process__header-timer-days {
    font-size: 24px;
    font-weight: 800;
    line-height: 1; }
  .cr-add-update-process__header-time {
    font-size: 16px; }
  .cr-add-update-process__icon-char {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.1em;
    text-align: center; }
  .cr-add-update-process__icon-text {
    font-size: 10px;
    text-transform: uppercase; }
  .cr-add-update-process__ad-types-title-sold {
    color: #0f99a2; }
  .cr-add-update-process__ad-types-title-done {
    color: gray; }
  .cr-add-update-process__ad-types-title-edit {
    color: #24b8d2; }
  .cr-add-update-process__ad-types-title-spec {
    color: #6803cc; }
  .cr-add-update-process__ad-types-title-contest {
    color: #8165f3;
    font-weight: bold; }
    .cr-add-update-process__ad-types-title-contest .cr-add-update-process__icon-char {
      font-size: 10px;
      line-height: 13px;
      text-transform: uppercase;
      letter-spacing: normal; }
  .cr-add-update-process__client-text {
    color: #c2d4e0;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left; }
  .cr-add-update-process__client-name {
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left; }
  .cr-add-update-process__process-status {
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: right; }
  .cr-add-update-process__tabs {
    background: #f7fbfd;
    padding: 7px 5px;
    border-radius: 23px;
    margin-bottom: 0;
    display: inline-flex;
    top: -30px;
    position: relative;
    transition: top 0.07s ease; }
  .cr-add-update-process .nav-link:focus, .cr-add-update-process .nav-link:hover {
    border-color: transparent; }
  .cr-add-update-process__tab_item {
    color: #334856;
    padding: 0px 0px;
    border-radius: 50px;
    background-color: #ffffff;
    margin-right: 5px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    white-space: nowrap;
    margin-bottom: 10px !important; }
    .cr-add-update-process__tab_item.active {
      background-color: #224b8b; }
      .cr-add-update-process__tab_item.active .nav-link {
        color: #f7fbfd !important;
        font-weight: normal; }
    .cr-add-update-process__tab_item:hover {
      background-color: #795afa;
      color: #f7fbfd; }
      .cr-add-update-process__tab_item:hover .cr-add-update-process__tab-checked-icon {
        color: #f7fbfd !important; }
    .cr-add-update-process__tab_item .media {
      display: inline-block; }
  .cr-add-update-process .switch-active-text {
    font-style: normal;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: bold !important;
    color: #374c59; }
  .cr-add-update-process .switch-text {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: #829fb1;
    text-transform: uppercase; }
  .cr-add-update-process .tab-content {
    z-index: 9;
    width: calc(100% - 81px);
    max-width: 1320px;
    overflow: hidden; }
  .cr-add-update-process .btn {
    text-transform: uppercase;
    margin-right: 14px; }
    .cr-add-update-process .btn:last-child {
      margin-right: 0; }
  .cr-add-update-process .review-media-container,
  .cr-add-update-process .design-container,
  .cr-add-update-process .media-container,
  .cr-add-update-process .traffic-container {
    box-shadow: none;
    background-color: transparent !important; }
  .cr-add-update-process .design-media-tab-container .process-bottom-buttons,
  .cr-add-update-process .media-tab-container .process-bottom-buttons,
  .cr-add-update-process .traffic-tab-container .process-bottom-buttons {
    padding-left: 50px; }
  .cr-add-update-process .review-media-tab-container .process-bottom-buttons {
    padding-left: 31.63px; }
  .cr-add-update-process .approve_script_copy_points {
    border-radius: 10px;
    box-sizing: border-box;
    padding: 15px;
    min-height: 100px;
    border: 1px solid rgba(0, 0, 0, 0.125); }
  .cr-add-update-process .button-groups.row {
    display: inline-block; }
  .cr-add-update-process .tabs-icon.order_details {
    margin-bottom: 3px; }
  .cr-add-update-process .tabs-icon.promotion_details {
    margin-bottom: 3px; }
  .cr-add-update-process label,
  .cr-add-update-process .form-label {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #657894;
    text-transform: uppercase;
    display: block;
    font-style: normal;
    font-weight: normal; }
  .cr-add-update-process .form-view-mode {
    padding-bottom: 84px; }
    .cr-add-update-process .form-view-mode label {
      margin-bottom: 18px; }
    .cr-add-update-process .form-view-mode .order-view-value {
      margin-bottom: 30px; }
  .cr-add-update-process .form-edit-mode {
    padding-bottom: 100px; }
    .cr-add-update-process .form-edit-mode label,
    .cr-add-update-process .form-edit-mode .form-label {
      color: #43535c !important; }
  .cr-add-update-process .cr-days-of-week__label-text {
    text-transform: uppercase !important;
    display: block;
    color: #657894;
    font-size: 13px !important;
    font-weight: normal; }
  .cr-add-update-process .order-spots-view-buttons {
    margin-bottom: 25px; }
  .cr-add-update-process .button-another-spot {
    border-radius: 30px !important;
    margin-right: 10px;
    margin-left: 14px;
    min-width: 140px;
    padding-right: 25px;
    padding-left: 25px;
    width: auto; }
  .cr-add-update-process .button-delete-spot {
    border-radius: 30px !important;
    min-width: 140px;
    padding-right: 25px;
    padding-left: 25px;
    margin-left: 10px;
    margin-right: 10px;
    width: auto; }
  .cr-add-update-process .button-save-draft {
    border-radius: 30px !important;
    min-width: 140px;
    padding-right: 25px;
    padding-left: 25px;
    margin-right: 10px;
    width: auto; }
  .cr-add-update-process .button-cancel {
    border-radius: 30px !important;
    min-width: 140px;
    padding-right: 25px;
    padding-left: 25px;
    width: auto; }
  .cr-add-update-process .button-submit {
    border-radius: 30px !important;
    min-width: 140px;
    padding-right: 25px;
    padding-left: 25px;
    margin-right: 10px;
    width: auto; }
  .cr-add-update-process .button-edit {
    border-radius: 30px !important;
    min-width: 140px;
    padding-right: 25px;
    padding-left: 25px;
    margin-right: 14px;
    width: auto; }
  .cr-add-update-process .btn-link {
    background-color: transparent;
    color: #795afa;
    margin-right: 0 !important; }
  .cr-add-update-process .order-details-container-bottom-buttons,
  .cr-add-update-process .log-container-bottom-buttons,
  .cr-add-update-process .contest-prizes-bottom-buttons,
  .cr-add-update-process .contest-winners-bottom-buttons,
  .cr-add-update-process .client-bottom-buttons,
  .cr-add-update-process .programming-schedule-bottom-buttons,
  .cr-add-update-process .traffic-container-bottom-buttons,
  .cr-add-update-process .dubbing-container-bottom-buttons {
    display: none !important; }
  .cr-add-update-process .cr-order-details__buttons .disabled,
  .cr-add-update-process .process-bottom-buttons .disabled {
    background: #bccedb !important;
    color: #fff;
    border-color: #bccedb !important;
    opacity: 1; }
  .cr-add-update-process .cr-order-details__buttons span,
  .cr-add-update-process .process-bottom-buttons span {
    display: block; }
  .cr-add-update-process .switch-active-text {
    font-style: normal;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: bold !important;
    color: #374c59; }
  .cr-add-update-process .switch-text {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: #829fb1;
    text-transform: uppercase; }
  .cr-add-update-process .process-link-clear-form {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #795afa;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    text-transform: uppercase;
    background: transparent; }
    .cr-add-update-process .process-link-clear-form:focus {
      outline: none;
      box-shadow: none; }
  .cr-add-update-process .button-edit-link span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; }
  .cr-add-update-process .review-media-container,
  .cr-add-update-process .design-container,
  .cr-add-update-process .media-container,
  .cr-add-update-process .traffic-container {
    box-shadow: none; }
  .cr-add-update-process .design-media-tab-container .process-bottom-buttons,
  .cr-add-update-process .media-tab-container .process-bottom-buttons,
  .cr-add-update-process .traffic-tab-container .process-bottom-buttons {
    padding-left: 50px; }
  .cr-add-update-process .review-media-tab-container .process-bottom-buttons {
    padding-left: 31.63px; }
  .cr-add-update-process .view-files-component .view-only .cr-file-view__file-name {
    color: #334856; }
  .cr-add-update-process .view-files-component .cr-handle-assets__file-buttons .btn {
    margin: 0;
    padding-left: 15px !important;
    padding-right: 15px !important;
    width: 55px; }
  .cr-add-update-process .voice-over .cr-voice-tracks {
    margin-bottom: 30px; }
  .cr-add-update-process .recording .cr-voice-tracks {
    margin-bottom: 27px; }
  .cr-add-update-process .voice-over .form-div,
  .cr-add-update-process .recording .form-div {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #000000; }
  .cr-add-update-process .voice-over .row > .col,
  .cr-add-update-process .recording .row > .col {
    margin-bottom: 0; }
  .cr-add-update-process .voice-over .view-files-component,
  .cr-add-update-process .voice-over .files-upload,
  .cr-add-update-process .recording .view-files-component,
  .cr-add-update-process .recording .files-upload {
    max-width: 534px; }
  .cr-add-update-process .voice-over .files-upload,
  .cr-add-update-process .recording .files-upload {
    padding: 13px 12px; }
  .cr-add-update-process .voice-over .form-label-assets,
  .cr-add-update-process .recording .form-label-assets {
    margin-bottom: 21px; }
  .cr-add-update-process .voice-over .cr-voice-tracks,
  .cr-add-update-process .recording .cr-voice-tracks {
    margin-top: 22px; }
  .cr-add-update-process .voice-over .cr-file-view__file-item,
  .cr-add-update-process .recording .cr-file-view__file-item {
    margin-bottom: 12px;
    padding-right: 16px; }
  .cr-add-update-process .voice-over .cr-file-view__file-buttons .btn,
  .cr-add-update-process .recording .cr-file-view__file-buttons .btn {
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin: 0;
    width: 55px; }
  .cr-add-update-process .voice-over .cr-handle-assets__file-item,
  .cr-add-update-process .recording .cr-handle-assets__file-item {
    padding: 0px 16px 0 20px;
    margin-bottom: 12px; }
  .cr-add-update-process .voice-over-bottom-buttons,
  .cr-add-update-process .recording-bottom-buttons,
  .cr-add-update-process .write-script-bottom-buttons,
  .cr-add-update-process .approve-script-bottom-buttons,
  .cr-add-update-process .air-check-bottom-buttons,
  .cr-add-update-process .approve-recording-bottom-buttons,
  .cr-add-update-process .air-check-provided-bottom-buttons,
  .cr-add-update-process .assign-vo-bottom-buttons {
    margin-top: 31px;
    margin-left: 49px;
    margin-right: 49px; }
  .cr-add-update-process .write-script-bottom-buttons,
  .cr-add-update-process .recording-bottom-buttons {
    display: flex; }
    .cr-add-update-process .write-script-bottom-buttons .form-group,
    .cr-add-update-process .recording-bottom-buttons .form-group {
      display: flex; }
    .cr-add-update-process .write-script-bottom-buttons .cr-send-mail-dialog__btn-send-mail,
    .cr-add-update-process .recording-bottom-buttons .cr-send-mail-dialog__btn-send-mail {
      margin-right: 14px; }
  .cr-add-update-process .script-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px; }
  .cr-add-update-process .segment-script .script-buttons {
    margin-bottom: 29px;
    margin-top: 13px;
    justify-content: flex-start; }
    .cr-add-update-process .segment-script .script-buttons .btn-outline-primary {
      width: 190px; }
  .cr-add-update-process .segment-script .form-div-script {
    padding-left: 0;
    padding-right: 0; }
  .cr-add-update-process .segment-script .segment-right-section-title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #334856;
    align-items: center;
    justify-content: center;
    display: flex; }
    .cr-add-update-process .segment-script .segment-right-section-title .script-length-value {
      font-weight: 600;
      color: #000; }
  .cr-add-update-process .segment-recording .cr-chatbox {
    max-width: 100%; }
  .cr-add-update-process .segment-recording .btn-link {
    padding: 0 !important;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #795afa;
    height: auto;
    text-transform: capitalize;
    margin-top: 7px; }
  .cr-add-update-process .segment-recording .files-wrapper {
    width: 100%; }
  .cr-add-update-process .segment-recording .drop-area {
    height: 91px;
    margin-bottom: 0;
    margin-top: 8px;
    /* max-width: 769px; */
    margin-left: auto;
    margin-right: auto; }
  .cr-add-update-process .segment-recording .promotion-section-title {
    margin-bottom: 13px; }
  .cr-add-update-process .segment-recording .btn-upload-new-version {
    margin-bottom: 18px; }
  .cr-add-update-process .segment-recording .file-upload-buttons {
    margin-bottom: 19px; }
  .cr-add-update-process .segment-recording .final-recording {
    margin-bottom: 22px; }
    .cr-add-update-process .segment-recording .final-recording .upload-file {
      margin-bottom: 5px; }
      .cr-add-update-process .segment-recording .final-recording .upload-file .file-value {
        word-break: break-all; }
        .cr-add-update-process .segment-recording .final-recording .upload-file .file-value span:last-child:not(.file-aduit) {
          display: none; }
      .cr-add-update-process .segment-recording .final-recording .upload-file .delete-icon {
        top: calc(50% - 5px); }
    .cr-add-update-process .segment-recording .final-recording .alert-danger {
      display: inline-block; }
  .cr-add-update-process .segment-recording .version {
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856;
    padding: 20px 0px; }
  .cr-add-update-process .segment-recording .version.spot {
    padding-top: 0px !important; }
  .cr-add-update-process .segment-recording .player-wrapper,
  .cr-add-update-process .segment-recording .player-container {
    margin-bottom: 0; }
  .cr-add-update-process .segment-recording .section-fields {
    padding-bottom: 40px; }
    .cr-add-update-process .segment-recording .section-fields .feedback-note {
      padding: 10px 0px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #334856; }
    .cr-add-update-process .segment-recording .section-fields .recording-feedback .rc-checkbox-inner {
      width: 23px;
      height: 23px; }
    .cr-add-update-process .segment-recording .section-fields .recording-feedback .cr-checkbox__text {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 14px;
      color: #657894; }
    .cr-add-update-process .segment-recording .section-fields .recording-feedback .cr-chatbox__mentionInput__control {
      margin-left: 4px; }
  .cr-add-update-process .segment-recording .cr-chatbox .message.yours .time {
    text-align: left; }
  .cr-add-update-process .segment-recording-history .section-fields {
    padding: 0px !important; }
    .cr-add-update-process .segment-recording-history .section-fields .recording-history-wrapper {
      padding-bottom: 20px; }
      .cr-add-update-process .segment-recording-history .section-fields .recording-history-wrapper .history-block {
        border-bottom: 1px solid #edf1f6;
        padding-top: 25px; }
        .cr-add-update-process .segment-recording-history .section-fields .recording-history-wrapper .history-block:last-child {
          border-bottom: none !important; }
        .cr-add-update-process .segment-recording-history .section-fields .recording-history-wrapper .history-block .history-col {
          margin-bottom: 0px !important;
          padding: 0px 60px; }
          .cr-add-update-process .segment-recording-history .section-fields .recording-history-wrapper .history-block .history-col .cr-order-script__form-div-draft-script {
            margin-bottom: 20px; }
  .cr-add-update-process .recording-bottom-buttons {
    margin-top: 30px; }
  .cr-add-update-process .dubbing-container {
    margin-bottom: 24px;
    background-color: transparent; }
  .cr-add-update-process .continuity-container,
  .cr-add-update-process .dubbing-container {
    box-shadow: none;
    border-radius: 0;
    background-color: transparent; }
    .cr-add-update-process .continuity-container > div,
    .cr-add-update-process .dubbing-container > div {
      padding: 0;
      margin-top: 0 !important; }
    .cr-add-update-process .continuity-container .cr-order-details__box-production-details,
    .cr-add-update-process .dubbing-container .cr-order-details__box-production-details {
      margin-bottom: 0;
      margin-bottom: 20px; }
    .cr-add-update-process .continuity-container .cr-order-details__section-view-production-details-fields,
    .cr-add-update-process .dubbing-container .cr-order-details__section-view-production-details-fields {
      margin-top: 21px; }
    .cr-add-update-process .continuity-container .col:first-child,
    .cr-add-update-process .dubbing-container .col:first-child {
      margin-bottom: 0; }
  .cr-add-update-process .upload-file .btn-link {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    padding: 0 !important;
    height: auto !important;
    text-align: left;
    text-transform: capitalize; }
  .cr-add-update-process .air-check-provided .drop-area,
  .cr-add-update-process .air-check .drop-area {
    height: 70px; }
  .cr-add-update-process__header-digital .cr-add-update-process__process-status {
    padding-top: 11px; }
  .cr-add-update-process .btn-send-back {
    color: #795afa;
    background-color: transparent; }
  @media screen and (min-width: 1200px) and (max-width: 1436px) {
    .cr-add-update-process .tab-content {
      min-width: 700px !important; }
    .cr-add-update-process .segment-script-feedback {
      margin-top: -4px; }
      .cr-add-update-process .segment-script-feedback > div {
        box-shadow: 0px 9px 10px rgba(0, 0, 0, 0.102);
        border-top: 1px solid #edf1f6;
        border-radius: 0; }
        .cr-add-update-process .segment-script-feedback > div > h6 {
          border-bottom: none !important;
          margin-bottom: -10px; }
    .cr-add-update-process .segment-script-history .script-history.form-label {
      font-style: normal;
      font-weight: bold;
      font-size: 23px !important;
      line-height: 29px;
      color: #334856;
      text-transform: unset !important; }
    .cr-add-update-process .segment-script-history .section-fields {
      padding: 0px !important; }
      .cr-add-update-process .segment-script-history .section-fields .cr-order-script {
        padding-bottom: 20px; }
        .cr-add-update-process .segment-script-history .section-fields .cr-order-script__review-history {
          padding: 10px 49px !important; }
        .cr-add-update-process .segment-script-history .section-fields .cr-order-script .history-block {
          border-bottom: 1px solid #edf1f6;
          padding-top: 25px; }
          .cr-add-update-process .segment-script-history .section-fields .cr-order-script .history-block:last-child {
            border-bottom: none !important; }
          .cr-add-update-process .segment-script-history .section-fields .cr-order-script .history-block .history-col {
            margin-bottom: 0px !important;
            padding: 0px 60px; }
            .cr-add-update-process .segment-script-history .section-fields .cr-order-script .history-block .history-col .cr-order-script__form-div-draft-script {
              margin-bottom: 20px; }
    .cr-add-update-process .segment-script-history .audit-info {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      text-align: right;
      letter-spacing: 0.05em;
      color: #657894;
      text-transform: uppercase; }
    .cr-add-update-process .upload-view-file .file-value {
      margin: 0; }
    .cr-add-update-process .delete-icon {
      top: calc(50% - 4px); }
    .cr-add-update-process__tabs-icon-wrapper {
      display: flex;
      align-items: center; } }

@media screen and (min-width: 1200px) and (max-width: 1436px) and (min-width: 1599px) {
  .cr-add-update-process__container-padding {
    padding-left: 70px;
    padding-right: 70px; }
  .cr-add-update-process__container-padding-tabs {
    padding-left: 60px;
    padding-right: 60px; } }
  @media screen and (min-width: 1200px) and (max-width: 1436px) {
    .cr-add-update-process .form-view-mode .voice-over .cr-order-approvals__approvals,
    .cr-add-update-process .form-view-mode .recording .cr-order-approvals__approvals {
      margin-bottom: 46px; }
    .cr-add-update-process .form-view-mode .voice-over .cr-order-approvals__form-div-draft-script,
    .cr-add-update-process .form-view-mode .voice-over .cr-order-approvals__form-div-copy-points,
    .cr-add-update-process .form-view-mode .recording .cr-order-approvals__form-div-draft-script,
    .cr-add-update-process .form-view-mode .recording .cr-order-approvals__form-div-copy-points {
      margin-bottom: 46px; }
    .cr-add-update-process .form-view-mode .dubbing-container .cr-order-details__col-field {
      margin-bottom: 46px !important; }
    .cr-add-update-process .form-view-mode .dubbing-container .cr-production-dubbing-traffic-details__label {
      margin-bottom: 18px; } }
  .cr-add-update-process__tab-content-container {
    position: relative; }

.sidebar-comments-open .tab-content {
  z-index: 9;
  width: calc(100% - 405px); }

.file-upload-title-container .title {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #334856;
  margin-bottom: 2px; }

.file-upload-title-container .spot-length {
  position: absolute;
  right: 15px;
  top: 0px;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #657894; }
  .file-upload-title-container .spot-length .value {
    color: #334856;
    font-weight: bold; }

.cr-order-recording__horizontal-line {
  border-bottom: 1px solid #edf1f6;
  margin-left: -50px;
  margin-right: -50px;
  margin-bottom: 24px;
  margin-top: 24px; }

.line-hashes-badges-container {
  display: inline-flex;
  flex-wrap: wrap; }

.selected-line-hashes label.label {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-transform: capitalize;
  color: #334856; }

.programming-schedule {
  box-shadow: none !important;
  background-color: transparent !important;
  border-radius: 0 !important; }
  .programming-schedule > div {
    padding: 0; }

.segment-production-deadline {
  margin-top: 24px; }
  .segment-production-deadline .cr-production-deadlines {
    margin-bottom: 0; }

.segment-own-container::after {
  content: "";
  display: block;
  clear: both; }

.segment-request-change .btn {
  margin: 33px 0 13px 49px; }

.segment-select-team-vo .cr-select-team {
  margin-top: 20px; }

.process-bottom-buttons,
.bottom-actions-sticky {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  flex-direction: column;
  margin: 0 0 0 78px;
  width: calc(100% - 78px);
  z-index: 99; }
  .process-bottom-buttons::before,
  .bottom-actions-sticky::before {
    content: "";
    background-image: linear-gradient(180deg, rgba(15, 85, 137, 0) 0%, rgba(15, 85, 137, 0.15) 100%);
    height: 34px;
    width: 100%; }
  .process-bottom-buttons .buttons,
  .bottom-actions-sticky .buttons {
    margin-top: 0; }
  .process-bottom-buttons .form-group,
  .process-bottom-buttons .buttons,
  .bottom-actions-sticky .form-group,
  .bottom-actions-sticky .buttons {
    margin-bottom: 0;
    background-color: #f7fbfd;
    height: 80px;
    width: 100%;
    padding: 18px 50px;
    border-top: 1px solid #c2d4e0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between; }
  .process-bottom-buttons .btn,
  .bottom-actions-sticky .btn {
    text-transform: uppercase; }
  .process-bottom-buttons .process-buttons-left,
  .process-bottom-buttons .process-buttons-right,
  .bottom-actions-sticky .process-buttons-left,
  .bottom-actions-sticky .process-buttons-right {
    flex: 1 1;
    display: flex; }
  .process-bottom-buttons .process-buttons-right,
  .bottom-actions-sticky .process-buttons-right {
    justify-content: flex-end; }

.body-sidebar-open .process-bottom-buttons,
.body-sidebar-open .bottom-actions-sticky {
  margin: 0 0 0 265px;
  transition: margin 0.2s ease-in;
  width: calc(100% - 265px); }

@media screen and (min-width: 1200px) and (max-width: 1436px) {
  .body-sidebar-open .process-bottom-buttons,
  .body-sidebar-open .bottom-actions-sticky {
    margin: 0 0 0 244px;
    width: calc(100% - 244px); } }

.body-sidebar-open .process-buttons-left {
  flex: 1 1;
  display: flex; }

.body-sidebar-open .process-buttons-right {
  flex: 1 1;
  display: flex;
  justify-content: flex-end; }

.process-bottom-buttons .form-group {
  display: flex;
  justify-content: space-between;
  flex: 1 1; }

.process-bottom-buttons .process-buttons-left {
  flex: 1 1;
  display: flex; }

.process-bottom-buttons .process-buttons-right {
  flex: 1 1;
  display: flex;
  justify-content: flex-end; }

.cr-process-list {
  background-color: #f7fbfd; }
  .cr-process-list__table-first-head {
    padding-left: 20px !important; }
  .cr-process-list__table-last-head {
    padding-right: 20px !important; }
  .cr-process-list__first-col {
    padding-left: 12px !important; }
  .cr-process-list__header {
    background-color: #336abb;
    color: white;
    padding: 65px 50px 22px 50px; }
  .cr-process-list__title {
    text-transform: capitalize;
    padding: 0.75rem 8px;
    font-size: 14px; }
    .cr-process-list__title a {
      color: white; }
      .cr-process-list__title a:hover {
        text-decoration: none; }
    .cr-process-list__title strong {
      font-weight: 600; }
    .cr-process-list__title span {
      font-size: 14px;
      font-style: normal;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left; }
  .cr-process-list__header-actions {
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: space-between;
    margin: 38px auto;
    margin-bottom: 60px; }
    .cr-process-list__header-actions.align-right {
      justify-content: flex-end !important; }
    .cr-process-list__header-actions .process-title {
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 40px;
      letter-spacing: 0em;
      text-align: left;
      text-transform: capitalize; }
    .cr-process-list__header-actions .cr-search-form {
      min-width: 389px; }
      .cr-process-list__header-actions .cr-search-form .cr-search-form__input {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left; }
  .cr-process-list__header-actions-only-search {
    justify-content: flex-end; }
  .cr-process-list__table-wrapper {
    border-radius: 30px;
    margin-top: -35px;
    background-color: #f7fbfd;
    overflow: hidden;
    padding: 10px; }
  .cr-process-list__table-wrapper-mode-in-tab {
    margin-top: 0;
    padding: 0;
    border: 1px solid #c2d4e0; }
    .cr-process-list__table-wrapper-mode-in-tab table th {
      border-top: none; }
  .cr-process-list__table {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px 20px 5px 5px;
    overflow: hidden;
    margin-bottom: 0;
    background: #fff; }
    .cr-process-list__table thead {
      background-color: #224b8b;
      color: #fff;
      font-weight: 700;
      font-size: 16px; }
      .cr-process-list__table thead th {
        border-bottom: none;
        padding: 8px;
        text-align: left;
        text-transform: capitalize; }
    .cr-process-list__table tbody tr {
      text-align: left; }
      .cr-process-list__table tbody tr:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
        cursor: pointer; }
    .cr-process-list__table tbody td {
      text-align: left;
      border-top-color: #ecf1f7;
      padding: 14px 0;
      height: 84px; }
  .cr-process-list__client-name .cr-process-list__td-value {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #334856; }
  .cr-process-list__order-title .cr-process-list__td-value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #111b2b; }
  .cr-process-list__order-title .small {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #829fb1;
    margin-top: 2px; }
  .cr-process-list__activity {
    color: #999fb2;
    font-weight: 300;
    font-style: italic;
    max-width: 150px;
    overflow: hidden;
    margin: 0 auto; }
  .cr-process-list__mentions {
    display: flex; }
  .cr-process-list__comment-display {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2px; }
  .cr-process-list__comment-avatar {
    display: inline-flex;
    background-color: white;
    border-radius: 100px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 40px;
    height: 40px;
    color: #8165f3;
    box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 10px;
    position: relative; }
    .cr-process-list__comment-avatar img {
      border-radius: 50%;
      max-width: 100%;
      position: absolute;
      width: 100%;
      height: 100%; }
    .cr-process-list__comment-avatar.avatar-sort-name {
      background-color: #0f5589; }
      .cr-process-list__comment-avatar.avatar-sort-name span {
        font-size: 14px;
        font-weight: 600;
        color: #fff; }
  .cr-process-list__comment-content {
    margin-left: 10px; }
  .cr-process-list__order-status {
    border: 1px solid rgba(194, 212, 224, 0.5);
    border-radius: 4px;
    min-width: 80%;
    display: flex;
    min-width: 133px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    color: #9aa4ca;
    min-height: 33px;
    align-items: center;
    padding: 5px 10px;
    justify-content: center;
    text-align: center; }
  .cr-process-list__draft, .cr-process-list__order-status-draft {
    color: #9aa4ca !important; }
  .cr-process-list__order-spec-status {
    color: #0f5589; }
  .cr-process-list__item-sortable {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .cr-process-list__item-sortable-center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize; }
  .cr-process-list__sortable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    text-align: center; }
    .cr-process-list__sortable:hover {
      border-radius: 100px;
      background-image: linear-gradient(to bottom, #6f96d1, #5f85c0, #4f75b0, #3f659f, #2e558f); }
  .cr-process-list__sortable-icon {
    position: absolute;
    font-size: 16px; }
  .cr-process-list__sortable-active {
    color: #c12674; }
  .cr-process-list__type-sold {
    color: #0860d6;
    background-color: #edf3f7;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px; }
  .cr-process-list__type-spec {
    color: #0860d6;
    background-color: #edf3f7;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px; }
  .cr-process-list__type-digital {
    background-color: #f5f3f5;
    font-weight: bold;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.07em;
    color: #a82ac0; }
  .cr-process-list__type-digital-BannerAd {
    font-size: 10px;
    line-height: 13px; }
  .cr-process-list .can-click {
    position: relative; }
  .cr-process-list__flight-dates {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #202124;
    font-weight: 400; }
  .cr-process-list__produced-status-on-air {
    color: #1c6fec; }
  .cr-process-list__produced-status-aired {
    color: #129aa3; }
  .cr-process-list__produced-status-upcoming {
    color: #3118ca; }
  .cr-process-list__produced-status-archived {
    color: #c1c1c1; }
  .cr-process-list__activity-display {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  .cr-process-list__no-padding {
    padding: 0;
    background: #fff; }
  .cr-process-list__header-tab-prize-closet {
    padding: 30px 0;
    background: #fff; }
  .cr-process-list__prize-categories {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    margin-top: 30px; }
    .cr-process-list__prize-categories li {
      font-weight: bold;
      font-size: 15px;
      display: inline-block;
      border: 1px solid #9abcd1;
      border-radius: 20px;
      color: #795afa;
      text-transform: uppercase;
      background-color: #fff;
      cursor: pointer;
      text-align: center;
      padding: 5px 10px;
      margin-right: 6px; }
      .cr-process-list__prize-categories li:hover {
        color: #fff;
        background-color: #224b8b;
        border-color: #224b8b; }
  .cr-process-list__liner-type {
    width: 51px;
    height: 51px;
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #edf3f7; }
    .cr-process-list__liner-type strong {
      font-size: 19px;
      text-transform: uppercase;
      font-weight: 800;
      line-height: 20px; }
    .cr-process-list__liner-type span {
      font-size: 7px; }
  .cr-process-list__liner-type-value {
    color: #829fb1;
    font-size: 12px;
    display: block;
    padding: 0 8px; }
  .cr-process-list__liner-flight-dates {
    color: #537a91; }
  .cr-process-list__liner-sortable {
    justify-content: center; }
  .cr-process-list__liner-liner {
    color: #795afa; }
  .cr-process-list__liner-rec-spons {
    color: #4654d3; }
  .cr-process-list__liner-rec-psa {
    color: #d34646; }
  .cr-process-list__liner-promo {
    color: #a646d3; }
  .cr-process-list__liner-live-spon {
    color: #469cff; }
  .cr-process-list__liner-contest {
    color: #73a703; }
  .cr-process-list__liner-live-psa {
    color: #d7811c; }
  .cr-process-list__liner-call-in {
    color: #32bad5; }
  .cr-process-list__liner-talk-brk {
    color: #6fcf97; }
  .cr-process-list__category-active {
    color: #fff !important;
    background-color: #224b8b !important;
    border-color: #224b8b !important; }
  .cr-process-list .cr-count-down {
    justify-content: flex-start;
    padding: 0; }
  .cr-process-list__td-value {
    padding: 0 8px;
    display: inline-block;
    color: #334856; }
  .cr-process-list__td-value-disabled {
    color: #829fb1 !important; }
  .cr-process-list__order-cart {
    font-weight: 400; }
  .cr-process-list__process-list-container {
    padding: 0px 40px; }
  .cr-process-list__type {
    height: 51px;
    width: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    position: relative; }
    .cr-process-list__type .avatar-small {
      position: absolute;
      top: 0;
      right: -14px; }
      .cr-process-list__type .avatar-small .avatar-sort-name-text {
        font-size: 12px; }
  .cr-process-list__type-block {
    display: flex;
    align-items: center; }
    .cr-process-list__type-block .cr-process-list__type {
      margin-right: 24px; }
  .cr-process-list__search-input {
    display: inline-flex;
    flex: 1 1;
    justify-content: flex-end; }
  .cr-process-list__prize-type {
    width: 51px;
    height: 51px;
    background-color: #edf3f7;
    border-radius: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
    margin: 0 8px; }
  .cr-process-list__prize-sort-name {
    font-weight: 800;
    font-size: 23px;
    text-align: center;
    line-height: 24px; }
  .cr-process-list__prize-name {
    font-size: 8px;
    line-height: 10px;
    text-align: center; }
  .cr-process-list__prize-cash {
    color: #3db972; }
  .cr-process-list__prize-variable {
    color: #3db972; }
  .cr-process-list__prize-tickets {
    color: #32bad5; }
  .cr-process-list__prize-prize {
    color: #795afa; }
  .cr-process-list__prize-merch {
    color: #469cff; }
  .cr-process-list__prize-description {
    padding: 14px 8px !important;
    font-size: 14px;
    color: #334856;
    max-width: 260px; }
  .cr-process-list__tooltip {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    opacity: 1;
    padding: 0; }
    .cr-process-list__tooltip p {
      margin-bottom: 14px; }
    .cr-process-list__tooltip .rc-tooltip-content {
      background-color: #ffffff;
      padding: 32px 20px;
      border-radius: 4px;
      font-size: 14px; }
    .cr-process-list__tooltip .rc-tooltip-arrow {
      border-top-color: #ffffff;
      border-width: 10px 10px 0;
      bottom: -8px; }
    .cr-process-list__tooltip .rc-tooltip-inner {
      padding: 0;
      color: #334856;
      background-color: #ffffff;
      box-shadow: none; }
  .cr-process-list__prize-text {
    padding: 14px 8px !important;
    font-size: 16px;
    color: #334856; }
    .cr-process-list__prize-text strong {
      font-weight: bold;
      color: #000000;
      display: block; }
  .cr-process-list__prize-title {
    min-width: 200px; }
  .cr-process-list__subtitle {
    font-size: 12px;
    color: #537a91; }
  .cr-process-list__text-center {
    text-align: center !important; }
  .cr-process-list__item-center {
    justify-content: center; }
  .cr-process-list__prize-title {
    font-weight: bold; }
  .cr-process-list__table-prizes .cr-process-list__item-sortable {
    font-weight: normal; }
  .cr-process-list .winner-name {
    color: #000000; }
  .cr-process-list__winner-list-name {
    color: #000000;
    width: 15%; }
  .cr-process-list__table-client-manager .cr-checkbox__checkbox {
    justify-content: center; }
  .cr-process-list__table-client-manager .text-select {
    padding-left: 44px; }
  .cr-process-list__table-client-manager .cr-process-list__item-sortable {
    text-transform: capitalize; }
  .cr-process-list__table-client-manager .cr-checkbox {
    display: inline-block;
    padding-left: 44px; }
    .cr-process-list__table-client-manager .cr-checkbox .rc-checkbox-inner {
      width: 20px;
      height: 19.29px; }
    .cr-process-list__table-client-manager .cr-checkbox .cr-checkbox__checked-icon {
      left: 3px;
      top: 1px; }
  .cr-process-list__table-client-manager tbody .cr-process-list__client-name:first-child {
    padding-left: 44px; }
  .cr-process-list__table-client-manager thead .client-name:first-child {
    padding-left: 44px;
    text-align: left !important; }
  .cr-process-list__client-actions {
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-process-list__edit-action, .cr-process-list__reassign-action, .cr-process-list__view-action {
    margin: 0 10px; }
  .cr-process-list__view-action {
    display: flex;
    width: 24px; }
  .cr-process-list__reassign-bulk-td {
    background: #edf4f8 !important; }
  .cr-process-list__reassign-bulk-row {
    display: flex;
    align-items: center;
    padding-left: 34px; }
    .cr-process-list__reassign-bulk-row .react-select-container {
      width: 297px;
      margin: 0 16px; }
    .cr-process-list__reassign-bulk-row .btn-blue {
      width: 190px; }
    .cr-process-list__reassign-bulk-row .react-select__placeholder {
      font-size: 14px;
      color: #829fb1 !important; }
  .cr-process-list__client-name {
    font-weight: bold;
    font-size: 16px;
    color: #111b2b; }
  .cr-process-list__account-name {
    font-size: 16px;
    color: #334856; }
  .cr-process-list__created-text {
    font-size: 16px;
    color: #537a91; }
  .cr-process-list__reassign-bulk-row-label {
    font-size: 14px;
    line-height: 18px;
    color: #537a91; }
  .cr-process-list__tooltip-action {
    opacity: 1; }
    .cr-process-list__tooltip-action .rc-tooltip-inner {
      background-color: #fff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px; }
      .cr-process-list__tooltip-action .rc-tooltip-inner span {
        color: #334856;
        font-size: 14px;
        text-transform: capitalize; }
    .cr-process-list__tooltip-action .rc-tooltip-arrow {
      border-color: transparent; }
      .cr-process-list__tooltip-action .rc-tooltip-arrow:after {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        background: #fff;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        top: -13px;
        left: -5px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); }
      .cr-process-list__tooltip-action .rc-tooltip-arrow:before {
        content: "";
        display: block;
        width: 30px;
        height: 14px;
        background: #fff;
        position: absolute;
        top: -19px;
        z-index: 9;
        left: -15px; }
  .cr-process-list__search-wrapper {
    position: relative; }
  .cr-process-list__advanced-search-text {
    color: #a9d1f4;
    font-size: 16px;
    display: block;
    text-align: right;
    cursor: pointer;
    position: absolute;
    top: -30px;
    width: 100%; }
  .cr-process-list__advanced-search-text-relative {
    color: #a9d1f4;
    font-size: 16px;
    display: block;
    text-align: right;
    cursor: pointer;
    margin-bottom: 5px; }
  .cr-process-list__assignment-name {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    color: #111b2b; }
  .cr-process-list__assignment-action {
    min-width: 160px; }
  .cr-process-list__assignment-action-assigned .btn {
    display: none; }
  .cr-process-list__assignment-action-assigned:hover .cr-process-list__assignment-name {
    display: none; }
  .cr-process-list__assignment-action-assigned:hover .btn {
    display: block; }
  .cr-process-list__dub-order {
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0px !important; }
    .cr-process-list__dub-order:focus {
      box-shadow: none; }
    .cr-process-list__dub-order:active {
      box-shadow: none; }
    .cr-process-list__dub-order.complete {
      z-index: 1;
      background: #73a703;
      border-color: #73a703; }
    .cr-process-list__dub-order .sent-text {
      margin-left: 10px; }
  .cr-process-list__dubbing-progress {
    height: 44px;
    position: absolute;
    background: #73a703;
    border-radius: 25px 0px 0px 25px;
    z-index: 2; }
    .cr-process-list__dubbing-progress.complete {
      border-radius: 25px;
      z-index: -1; }
  .cr-process-list__small-text {
    font-size: 12px;
    line-height: 15px;
    color: #829fb1;
    display: block; }
  .cr-process-list__winner-text {
    padding: 14px 8px !important;
    color: #000000; }
    .cr-process-list__winner-text strong {
      color: #111b2b;
      display: block; }
  .cr-process-list__winner-prize-text {
    font-size: 14px;
    color: #334856; }
  .cr-process-list__winner-phone-text {
    color: #829fb1;
    font-size: 12px;
    display: block;
    text-transform: uppercase; }
  .cr-process-list__winner-email-text {
    font-size: 12px;
    color: #829fb1; }
  .cr-process-list__email-tooltip .rc-tooltip-content {
    padding: 15px !important;
    font-size: 12px;
    color: #829fb1; }
  .cr-process-list__email-tooltip .rc-tooltip-inner {
    min-height: inherit; }
  .cr-process-list__winner-station-text {
    color: #334856;
    text-transform: uppercase;
    display: block; }
    .cr-process-list__winner-station-text .station-name {
      display: block;
      text-transform: capitalize;
      font-size: 12px;
      color: #829fb1; }
  .cr-process-list__date-won {
    color: #657894;
    font-size: 14px; }
  .cr-process-list__winner-status {
    background: #ffffff;
    border: 1px solid rgba(194, 212, 224, 0.57);
    box-sizing: border-box;
    border-radius: 4px;
    width: 132px;
    height: 33px;
    font-weight: 800;
    text-transform: uppercase;
    display: inline-block;
    line-height: 33px; }
  .cr-process-list__winner-status-signed {
    color: #795afa; }
  .cr-process-list__winner-status-expired {
    color: #e40061; }
  .cr-process-list__winner-status-fulfilled {
    color: #73a703; }
  .cr-process-list__winner-type {
    width: 54px;
    height: 51px;
    background-color: #edf3f7;
    border-radius: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
    margin: 0 8px; }
    .cr-process-list__winner-type.call-in {
      color: #795afa; }
  .cr-process-list__winner-sort-name {
    font-weight: 800;
    font-size: 19px;
    text-align: center;
    line-height: 24px; }
  .cr-process-list__winner-name {
    font-size: 7px;
    line-height: 9px;
    text-align: center; }
  .cr-process-list__winner-txt, .cr-process-list__liner-text_to_win {
    color: #3db972; }
  .cr-process-list__winner-on, .cr-process-list__liner-online_registration {
    color: #32bad5; }
  .cr-process-list__winner-call, .cr-process-list__liner-call_in_contest {
    color: #795afa; }
  .cr-process-list__liner-type-contests {
    width: 54px;
    height: 54px; }
    .cr-process-list__liner-type-contests strong {
      font-size: 17px; }
      .cr-process-list__liner-type-contests strong span {
        font-size: 17px; }
    .cr-process-list__liner-type-contests span {
      font-size: 8px;
      text-transform: uppercase; }
  .cr-process-list__winners-list-table .cr-process-list__table-wrapper {
    margin-top: 0; }
  .cr-process-list__winners-list-table th span {
    text-transform: capitalize;
    white-space: nowrap; }
  .cr-process-list__winners-header .cr-process-list__header {
    display: flex;
    padding-top: 65px;
    flex-direction: column; }
  .cr-process-list__winners-header .cr-search-autocomplete-input {
    width: 373px; }
    .cr-process-list__winners-header .cr-search-autocomplete-input input {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12); }
  .cr-process-list__winners-header .cr-process-list__advanced-search-text-relative {
    color: #795afa;
    font-size: 14px;
    text-transform: capitalize; }
  .cr-process-list__winners-header .cr-custom-multiselect-dropdown__option-select-group .react-select__option {
    text-transform: uppercase; }
    .cr-process-list__winners-header .cr-custom-multiselect-dropdown__option-select-group .react-select__option .cr-checkbox__text {
      color: #657894 !important; }
  .cr-process-list__winners-header .cr-custom-multiselect-dropdown__option-select-group.cr-custom-multiselect-dropdown__option-all .react-select__option {
    padding-left: 12px !important;
    padding-right: 12px;
    font-weight: 600;
    text-transform: capitalize; }
    .cr-process-list__winners-header .cr-custom-multiselect-dropdown__option-select-group.cr-custom-multiselect-dropdown__option-all .react-select__option .cr-checkbox__text {
      color: #000000 !important; }
  .cr-process-list__winners-header .react-select__group-heading {
    font-weight: 600;
    color: #000000;
    text-transform: capitalize; }
  .cr-process-list__winners-actions {
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 20px; }
  .cr-process-list__header-top {
    background-color: #336abb; }
  .cr-process-list__export-winners {
    min-width: 266px;
    height: 39px;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: #fff;
    padding: 0 0 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .cr-process-list__total {
    font-weight: 700;
    line-height: 20px;
    color: #334856;
    text-transform: capitalize; }
    .cr-process-list__total strong {
      margin-right: 10px; }
  .cr-process-list__btn-export, .cr-process-list__btn-save-search {
    min-width: 89px !important;
    padding: 0 !important;
    height: 100% !important; }
    .cr-process-list__btn-export:hover, .cr-process-list__btn-save-search:hover {
      text-decoration: none; }
  .cr-process-list__btn-save-search {
    text-transform: inherit; }
  .cr-process-list__winners-stations {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .cr-process-list__winners-stations .cr-custom-multiselect-dropdown {
      min-width: 400px; }
  .cr-process-list__winners-filter {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .cr-process-list__filter-years {
    margin-left: 21px; }
    .cr-process-list__filter-years .react-select-container {
      min-width: 170px; }
  .cr-process-list__viewing-text {
    color: #a9d1f4;
    margin-right: 10px; }
  @media (min-width: 1599px) {
    .cr-process-list__header, .cr-process-list__winners-actions {
      padding-left: 70px;
      padding-right: 70px; }
    .cr-process-list__process-list-container {
      padding: 0 60px; } }
  .cr-process-list__add-icon {
    position: absolute;
    left: 20px; }
  .cr-process-list__button-audience {
    width: 230px !important;
    height: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
    .cr-process-list__button-audience .button-text {
      font-weight: 800;
      font-size: 19px;
      text-align: center;
      color: #334856;
      text-transform: capitalize; }
  .cr-process-list__guide-help {
    display: flex;
    margin-top: -20px;
    align-items: center;
    margin-right: 300px; }
    .cr-process-list__guide-help span {
      font-weight: 800;
      font-size: 23px;
      line-height: 26px;
      text-align: center;
      color: #0f5589;
      max-width: 200px;
      display: inline-block; }
  .cr-process-list__table-report-winners .action-col {
    width: 60px; }
  .cr-process-list__table-report-winners .checkbox-col {
    width: 80px; }
  .cr-process-list__table-report-winners .year-col {
    width: 100px; }
  .cr-process-list__table-report-winners .cash-col,
  .cr-process-list__table-report-winners .non-cash-col,
  .cr-process-list__table-report-winners .total-col {
    width: 150px; }
  .cr-process-list__table-report-winners .align-middle {
    padding: 14px 8px; }
  .cr-process-list__table-report-winners .signed-col {
    width: 200px; }
  .cr-process-list__table-report-winners .cr-process-list__table-first-head {
    padding-left: 14px !important; }
    .cr-process-list__table-report-winners .cr-process-list__table-first-head .rc-checkbox-inner {
      border-color: #fff !important;
      background-color: #224b8b; }
      .cr-process-list__table-report-winners .cr-process-list__table-first-head .rc-checkbox-inner::after {
        border-color: #224b8b; }
    .cr-process-list__table-report-winners .cr-process-list__table-first-head .rc-checkbox-checked {
      color: #fff; }
  .cr-process-list__table-report-winners .cr-checkbox__checkbox {
    justify-content: center; }
  .cr-process-list__signed-icon {
    display: inline-flex;
    align-items: center;
    margin-right: 5px; }
  .cr-process-list__signed-status {
    display: inline-flex;
    align-items: center; }
  .cr-process-list__empty-entities {
    font-size: 18px;
    line-height: 40px;
    color: #657894;
    padding-left: 60px; }
  .cr-process-list__winner-phone-email-text {
    font-size: 11px;
    line-height: 14px;
    color: #657894; }
    .cr-process-list__winner-phone-email-text span {
      display: block; }
  .cr-process-list__tr-expand td {
    border-top: none; }
  .cr-process-list__tr-expand:hover {
    box-shadow: none !important; }
  .cr-process-list__expand-details {
    width: 100%; }
    .cr-process-list__expand-details td {
      height: 42px !important;
      padding: 0 8px !important; }
    .cr-process-list__expand-details tr:hover {
      box-shadow: none !important;
      cursor: initial !important; }
    .cr-process-list__expand-details tr:nth-child(2n + 1) {
      background-color: #f7fbfd; }
  .cr-process-list__button-disabled {
    cursor: not-allowed; }
  .cr-process-list__highlight-deadline-text {
    font-weight: 700;
    color: #e40061;
    text-transform: uppercase; }
  .cr-process-list__spot-end-date-status {
    text-transform: uppercase;
    color: #e40061;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 2px; }
  .cr-process-list__last-col {
    padding-left: 10px !important;
    padding-right: 20px !important; }
  .cr-process-list__row-blocked {
    background-color: rgba(194, 212, 224, 0.25); }
  .cr-process-list__filter-view {
    display: flex;
    align-items: center;
    margin-right: 25px;
    cursor: pointer; }
    .cr-process-list__filter-view span {
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: #ffffff;
      margin-right: 10px; }
  .cr-process-list__process-list-results .cr-process-list__table-wrapper,
  .cr-process-list__process-list-results .cr-production-manager-queue__table-wrapper {
    margin-top: 10px; }
  .cr-process-list__filter-tags {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px; }
  .cr-process-list__filter-tag {
    background: #ffffff;
    border: 1px solid #c2d4e0;
    border-radius: 4px;
    padding: 8px;
    display: flex;
    color: #657894;
    font-size: 14px;
    line-height: 18px;
    margin-right: 5px;
    text-transform: capitalize; }
  .cr-process-list__filter-status {
    font-weight: 800;
    text-transform: uppercase; }
  .cr-process-list__filter-status-assign-vo {
    color: #a82ac0; }
  .cr-process-list__filter-status-assign-team, .cr-process-list__filter-status-approve-recording {
    color: #6803cc; }
  .cr-process-list__filter-status-write-script, .cr-process-list__filter-status-script-change, .cr-process-list__filter-status-spot-change, .cr-process-list__filter-status-change-request {
    color: #e40061; }
  .cr-process-list__filter-status-record-script, .cr-process-list__filter-status-approve-script, .cr-process-list__filter-status-client-review {
    color: #cc1ee6; }
  .cr-process-list__filter-status-voice-over {
    color: #3118ca; }
  .cr-process-list__filter-status-continuity {
    color: #0f99a2; }
  .cr-process-list__filter-status-draft {
    color: #9aa4ca; }
  .cr-process-list__filter-status-dub-script {
    color: #6803cc; }
  .cr-process-list__header-results {
    padding: 0 10px;
    margin-top: 20px;
    display: flex; }
  .cr-process-list__total-results {
    font-size: 14px;
    line-height: 18px;
    color: #657894;
    margin-right: 15px; }
    .cr-process-list__total-results strong {
      margin: 0 5px;
      color: #111b2b; }
  .cr-process-list__remove-tag {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: 10px; }
  @media screen and (max-width: 1436px) {
    .cr-process-list__winner-status {
      margin: 0 20px;
      padding: 0 10px;
      width: 120px; } }

.cr-page-processes-assignments_queue .cr-process-list__table thead th {
  white-space: nowrap; }

.cr-audit-list {
  background-color: #f7fbfd; }
  .cr-audit-list__table-first-head {
    padding-left: 20px !important; }
  .cr-audit-list__first-col {
    padding-left: 12px !important; }
  .cr-audit-list__header {
    background-color: #336abb;
    color: white;
    padding: 65px 50px 22px 50px; }
  .cr-audit-list__title {
    text-transform: capitalize;
    padding: 0.75rem 8px 0 8px; }
    .cr-audit-list__title span {
      font-size: 14px;
      font-style: normal;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left; }
  .cr-audit-list__header-actions {
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: space-between;
    margin: 38px auto; }
    .cr-audit-list__header-actions.align-right {
      justify-content: flex-end !important; }
    .cr-audit-list__header-actions .process-title {
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 40px;
      letter-spacing: 0em;
      text-align: left; }
    .cr-audit-list__header-actions .cr-search-form {
      min-width: 389px; }
      .cr-audit-list__header-actions .cr-search-form .cr-search-form__input {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left; }
  .cr-audit-list__header-actions-only-search {
    justify-content: flex-end; }
  .cr-audit-list__table-wrapper {
    border-radius: 30px;
    margin-top: -35px;
    background-color: #f7fbfd;
    overflow: hidden;
    padding: 10px; }
  .cr-audit-list__table-wrapper-mode-in-tab {
    margin-top: 0;
    padding: 0;
    border: 1px solid #c2d4e0; }
    .cr-audit-list__table-wrapper-mode-in-tab table th {
      border-top: none; }
  .cr-audit-list__table {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px 20px 5px 5px;
    overflow: hidden;
    margin-bottom: 0;
    background: #fff; }
    .cr-audit-list__table thead {
      background-color: #224b8b;
      color: #fff;
      font-weight: 700;
      font-size: 16px; }
      .cr-audit-list__table thead th {
        border-bottom: none;
        padding: 8px;
        text-align: left; }
    .cr-audit-list__table tbody tr {
      text-align: left; }
      .cr-audit-list__table tbody tr:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
        cursor: pointer; }
    .cr-audit-list__table tbody td {
      text-align: left;
      border-top-color: #ecf1f7;
      padding: 14px 5px;
      height: 84px;
      vertical-align: top !important; }
  .cr-audit-list__client-name .cr-process-list__td-value {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #334856; }
  .cr-audit-list__order-title .cr-process-list__td-value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #111b2b; }
  .cr-audit-list__order-title .small {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #829fb1;
    margin-top: 2px; }
  .cr-audit-list__activity {
    color: #999fb2;
    font-weight: 300;
    font-style: italic;
    max-width: 150px;
    overflow: hidden;
    margin: 0 auto; }
  .cr-audit-list__comment-display {
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-audit-list__comment-avatar {
    display: inline-flex;
    background-color: white;
    border-radius: 100px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 40px;
    height: 40px;
    color: #8165f3;
    box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 10px;
    position: relative; }
    .cr-audit-list__comment-avatar img {
      border-radius: 50%;
      max-width: 100%;
      position: absolute;
      width: 100%;
      height: 100%; }
  .cr-audit-list__comment-content {
    margin-left: 10px; }
  .cr-audit-list__order-status {
    border: 1px solid rgba(194, 212, 224, 0.5);
    border-radius: 4px;
    min-width: 80%;
    display: flex;
    min-width: 133px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    color: #9aa4ca;
    height: 33px;
    align-items: center;
    padding: 0 10px;
    justify-content: center; }
  .cr-audit-list__order-status-change-request {
    font-size: 13px; }
  .cr-audit-list__draft {
    color: #c1c1c1; }
  .cr-audit-list__item-sortable {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .cr-audit-list__sortable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    text-align: center; }
    .cr-audit-list__sortable:hover {
      border-radius: 100px;
      background-image: linear-gradient(to bottom, #6f96d1, #5f85c0, #4f75b0, #3f659f, #2e558f); }
  .cr-audit-list__sortable-icon {
    position: absolute;
    font-size: 16px; }
  .cr-audit-list__sortable-active {
    color: #c12674; }
  .cr-audit-list__type-sold {
    color: #0860d6;
    background-color: #edf3f7;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px; }
  .cr-audit-list__type-spec {
    color: #0860d6;
    background-color: #edf3f7;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px; }
  .cr-audit-list__type-digital {
    background-color: #f5f3f5;
    font-weight: bold;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.07em;
    color: #a82ac0; }
  .cr-audit-list__type-digital-BannerAd {
    font-size: 10px;
    line-height: 13px; }
  .cr-audit-list .can-click {
    position: relative; }
  .cr-audit-list__flight-dates {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #202124;
    font-weight: 400; }
  .cr-audit-list__produced-status-on-air {
    color: blue; }
  .cr-audit-list__produced-status-archived {
    color: #c1c1c1; }
  .cr-audit-list__activity-display {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  .cr-audit-list__no-padding {
    padding: 0;
    background: #fff; }
  .cr-audit-list__header-tab-prize-closet {
    padding: 30px 0;
    background: #fff; }
  .cr-audit-list__prize-categories {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    margin-top: 30px; }
    .cr-audit-list__prize-categories li {
      font-weight: bold;
      font-size: 15px;
      display: inline-block;
      border: 1px solid #9abcd1;
      border-radius: 20px;
      color: #795afa;
      text-transform: uppercase;
      background-color: #fff;
      cursor: pointer;
      text-align: center;
      padding: 5px 10px;
      margin-right: 6px; }
      .cr-audit-list__prize-categories li:hover {
        color: #fff;
        background-color: #224b8b;
        border-color: #224b8b; }
  .cr-audit-list__category-active {
    color: #fff !important;
    background-color: #224b8b !important;
    border-color: #224b8b !important; }
  .cr-audit-list .cr-count-down {
    justify-content: flex-start;
    padding: 0; }
  .cr-audit-list__td-value {
    padding: 0 8px;
    display: inline-block;
    color: #334856; }
  .cr-audit-list__order-cart {
    font-weight: 400; }
  .cr-audit-list__audit-list-container {
    padding: 0px 40px; }
  .cr-audit-list__type {
    height: 51px;
    width: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px; }
  .cr-audit-list__search-input {
    display: inline-flex;
    flex: 1 1;
    justify-content: flex-end; }
  @media (min-width: 1599px) {
    .cr-audit-list__header {
      padding-left: 70px;
      padding-right: 70px; }
    .cr-audit-list__process-list-container {
      padding: 0 60px; } }
  .cr-audit-list__prize-type {
    width: 51px;
    height: 51px;
    background-color: #edf3f7;
    border-radius: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-transform: uppercase;
    margin: 0 8px; }
  .cr-audit-list__prize-sort-name {
    font-weight: 800;
    font-size: 23px;
    text-align: center; }
  .cr-audit-list__prize-name {
    font-size: 8px;
    line-height: 10px;
    text-align: center; }
  .cr-audit-list__prize-cash {
    color: #3db972; }
  .cr-audit-list__prize-tickets {
    color: #32bad5; }
  .cr-audit-list__prize-prize {
    color: #795afa; }
  .cr-audit-list__prize-merch {
    color: #469cff; }
  .cr-audit-list__prize-description {
    padding: 14px 8px !important;
    font-size: 14px;
    color: #334856; }
  .cr-audit-list__prize-text {
    padding: 14px 8px !important;
    font-size: 16px;
    color: #334856; }
    .cr-audit-list__prize-text strong {
      font-weight: bold;
      color: #000000;
      display: block; }
  .cr-audit-list__subtitle {
    font-size: 12px;
    color: #537a91; }
  .cr-audit-list__text-center {
    text-align: center !important; }
  .cr-audit-list__item-center {
    justify-content: center; }
  .cr-audit-list__prize-title {
    font-weight: bold; }
  .cr-audit-list__table-prizes .cr-process-list__item-sortable {
    font-weight: normal; }
  .cr-audit-list label {
    min-width: 120px; }
  .cr-audit-list .auditicon {
    color: green;
    margin-left: -40px;
    position: absolute;
    background-color: white;
    border-radius: 50%;
    font-size: 30px;
    height: 38px;
    width: 38px;
    text-align: center;
    border: 1px solid white;
    font-weight: 900; }
  .cr-audit-list table.plain tbody tr:hover {
    box-shadow: none;
    cursor: pointer; }
  .cr-audit-list table.plain tbody td {
    height: auto; }
  .cr-audit-list .stationcode {
    color: #777;
    font-size: 10px;
    padding-top: 7px;
    padding-left: 22px;
    padding-right: 1px;
    display: block;
    white-space: nowrap; }
  .cr-audit-list .zoominlinkstation,
  .cr-audit-list .zoominlink {
    opacity: 0;
    transition: opacity 0.4s;
    position: absolute;
    top: 15px;
    background: #b5b5b596;
    width: 20px;
    height: 20px;
    border-radius: 15px;
    color: #525252;
    padding: 0px 1px 1px 1px;
    border: 1px solid white;
    box-shadow: 0 0 0 2px #b5b5b58c; }
  .cr-audit-list .zoominlinkstation {
    opacity: 0;
    margin-top: -5px;
    top: auto;
    font-size: 17px; }
  .cr-audit-list .cr-audit-list__table tbody td:hover .zoominlink {
    opacity: 1; }
  .cr-audit-list .stationcode:hover .zoominlinkstation {
    opacity: 1; }
  .cr-audit-list .single-audit-dialog {
    top: 150px;
    padding: 40px;
    background: #fefefe;
    left: 50px;
    right: 50px;
    z-index: 99;
    box-shadow: 0 0 10px;
    min-height: 400px;
    position: fixed;
    bottom: 150px;
    border-radius: 4px;
    overflow: auto; }
    .cr-audit-list .single-audit-dialog .close-button {
      position: absolute;
      right: 5px;
      margin-top: -35px;
      cursor: pointer;
      color: white;
      background: #595cfb;
      padding: 4px 10px;
      border-radius: 4px;
      font-weight: bold;
      box-shadow: #191c46 0px -1px 7px -3px inset; }
  .cr-audit-list .avatar-overlay {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    position: absolute;
    margin-left: 37px;
    margin-top: -16px;
    zoom: 0.7;
    border: 1px solid white;
    background-color: whitesmoke; }
  @media (min-width: 1599px) {
    .cr-audit-list__header {
      padding-left: 70px;
      padding-right: 70px; }
    .cr-audit-list__audit-list-container {
      padding: 0 60px; } }

.auth-form {
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.36);
  max-width: 1000px;
  margin: auto;
  border-radius: 28px; }
  .auth-form form {
    max-width: 340px;
    width: 100%;
    align-self: center; }
  .auth-form .reset {
    padding: 0; }
  .auth-form .section-logo .logo {
    border-radius: 0px 28px 28px 0px; }
  .auth-form .form-title {
    font-weight: 800;
    color: #2e3b51;
    font-size: 60px;
    margin-bottom: 2rem; }
  .auth-form .auth-label {
    font-weight: 400;
    font-size: 12px;
    color: #43535c;
    text-transform: uppercase; }
  .auth-form .form-control {
    border-radius: 8px;
    border-color: #c2d4e0;
    padding: 12px; }
    .auth-form .form-control:active {
      border-color: #8165f3;
      box-shadow: 0 5px 11px rgba(0, 123, 255, 0.1); }
    .auth-form .form-control.is-invalid:focus {
      border-color: #8165f3;
      box-shadow: 0 5px 11px rgba(0, 123, 255, 0.1); }
  .auth-form .form-group {
    margin-bottom: 1.5rem; }
  .auth-form .bg-gradient-auth {
    background: #896aff linear-gradient(180deg, #3d378e, #896aff) repeat-y;
    border-color: #896aff;
    padding: 9px; }
    .auth-form .bg-gradient-auth:focus {
      box-shadow: none; }
    .auth-form .bg-gradient-auth:active {
      background: #896aff linear-gradient(180deg, #3d378e, #896aff) repeat-y !important;
      box-shadow: none !important; }
  .auth-form .link {
    color: #8165f3;
    font-weight: 400;
    font-size: 16px;
    text-decoration: underline;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
  .auth-form .logo {
    display: flex;
    justify-content: center;
    align-items: center; }
  .auth-form .main-text-logo {
    color: #fff;
    font-size: 125px; }
  .auth-form .card.card-body {
    border-radius: 28px 0px 0px 28px; }
  .auth-form .password-input {
    position: relative; }
    .auth-form .password-input input {
      padding-right: 30px; }
    .auth-form .password-input .btn-show-password {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      padding: 16px 10px;
      display: flex;
      align-items: center; }
      .auth-form .password-input .btn-show-password.active::after {
        content: "";
        display: block;
        position: absolute;
        background-color: #795afa;
        width: 28px;
        height: 3px;
        -webkit-transform: rotate(124deg);
                transform: rotate(124deg);
        top: 22px;
        right: 8px; }
  .auth-form .auth-links {
    display: flex;
    justify-content: space-between; }

.auth-content-page {
  height: 100vh;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto; }

.cr-server__logo {
  background-color: white;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 10px;
  border-radius: initial !important;
  max-height: 100%;
  width: auto !important;
  margin-bottom: 10px; }

.cr-server__change-logo {
  color: #795afa;
  text-transform: uppercase;
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer; }

.cr-server__view-logo {
  margin-bottom: 20px; }

.cr-server__picker-trigger {
  width: 50px;
  height: 20px;
  display: block;
  box-shadow: 0 0 0 2px #fff inset;
  border: 1px solid #999;
  display: inline-block;
  padding: 2px;
  border-radius: 2px;
  -webkit-user-select: none;
  user-select: none;
  margin-left: 5px; }

.cr-server .list-group-item-flex {
  display: flex;
  align-items: center; }

.cr-admins__active {
  background-color: #2e558f;
  color: #fff !important; }

.cr-admins__tab-link {
  font-size: 16px !important;
  min-width: 140px;
  text-align: center;
  display: inline-block !important;
  padding: 12px 6px !important;
  color: #657894;
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
  border: none !important; }
  .cr-admins__tab-link:hover {
    color: inherit; }
  .cr-admins__tab-link.active {
    font-weight: bold !important;
    box-shadow: 0px -3px 6px -2px rgba(0, 0, 0, 0.102);
    color: #657894 !important; }

.cr-admins .cr-admins__nav > .nav-item {
  min-width: 140px;
  margin-bottom: -4px; }

.cr-admins__title {
  font-weight: 400;
  font-size: 25px; }

.cr-admins__tab-detail .card {
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px; }

.cr-admins .cr-role-list__head-wrapper .cr-textinput__input-wrapper,
.cr-admins .head-admin-list-wrapper .cr-textinput__input-wrapper,
.cr-admins .cr-list-users__head-admin-list-wrapper .cr-textinput__input-wrapper {
  background: #ffffff;
  border: 1px solid #c2d4e0;
  box-sizing: border-box;
  border-radius: 50px;
  height: 49px;
  width: 395px;
  overflow: hidden; }
  .cr-admins .cr-role-list__head-wrapper .cr-textinput__input-wrapper:hover,
  .cr-admins .head-admin-list-wrapper .cr-textinput__input-wrapper:hover,
  .cr-admins .cr-list-users__head-admin-list-wrapper .cr-textinput__input-wrapper:hover {
    border-color: #795afa; }
  .cr-admins .cr-role-list__head-wrapper .cr-textinput__input-wrapper input,
  .cr-admins .head-admin-list-wrapper .cr-textinput__input-wrapper input,
  .cr-admins .cr-list-users__head-admin-list-wrapper .cr-textinput__input-wrapper input {
    height: 100%; }
    .cr-admins .cr-role-list__head-wrapper .cr-textinput__input-wrapper input:hover, .cr-admins .cr-role-list__head-wrapper .cr-textinput__input-wrapper input:focus,
    .cr-admins .head-admin-list-wrapper .cr-textinput__input-wrapper input:hover,
    .cr-admins .head-admin-list-wrapper .cr-textinput__input-wrapper input:focus,
    .cr-admins .cr-list-users__head-admin-list-wrapper .cr-textinput__input-wrapper input:hover,
    .cr-admins .cr-list-users__head-admin-list-wrapper .cr-textinput__input-wrapper input:focus {
      border-color: transparent !important;
      box-shadow: none !important; }

.cr-admins .head-admin-list-wrapper .cr-textinput,
.cr-admins .cr-role-list__head-wrapper .cr-textinput {
  flex: 1 1;
  display: inline-flex;
  justify-content: flex-end; }

.cr-admins--hub_details .cr-admins__nav {
  display: none; }

.cr-page-profile {
  padding-top: 65px;
  background-color: #f7fbfd; }
  .cr-page-profile .cr-page__page-top {
    background-color: #336abb; }
  .cr-page-profile .breadcrumb {
    padding-top: 20px;
    padding-bottom: 0;
    margin-bottom: 38px; }
  .cr-page-profile .breadcrumb-item {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff; }
    .cr-page-profile .breadcrumb-item::before {
      color: #fff; }
    .cr-page-profile .breadcrumb-item.active {
      font-weight: bold; }
  .cr-page-profile .cr-page__header {
    padding-top: 0; }
  .cr-page-profile .cr-page__title {
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    color: #ffffff;
    margin-bottom: 55px; }

.cr-prize-page {
  padding-bottom: 24px; }
  .cr-prize-page__page-padding {
    padding-left: 50px;
    padding-right: 50px; }
  .cr-prize-page__prize-header {
    background-color: #336abb;
    height: 193px; }
  .cr-prize-page__breadcrumb {
    padding-top: 22px;
    padding-bottom: 28px;
    font-size: 14px;
    color: #f7fbfd; }
    .cr-prize-page__breadcrumb strong {
      margin-left: 5px; }
  .cr-prize-page__type {
    width: 51px;
    height: 51px;
    background-color: #edf3f7;
    border-radius: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-transform: uppercase; }
  .cr-prize-page__header-content {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .cr-prize-page__header-title {
    margin-left: 22px; }
  .cr-prize-page__title {
    font-weight: 800;
    font-size: 32px;
    margin-bottom: 0;
    color: #ffffff; }
  .cr-prize-page__subtitle {
    font-size: 18px;
    color: #c2d4e0;
    display: inline-block; }
  .cr-prize-page__sort-name {
    font-weight: 800;
    font-size: 23px;
    text-align: center; }
  .cr-prize-page__name {
    font-size: 8px;
    line-height: 10px;
    text-align: center; }
  .cr-prize-page__cash {
    color: #3db972; }
  .cr-prize-page__tickets {
    color: #32bad5; }
  .cr-prize-page__prize {
    color: #795afa; }
  .cr-prize-page__merch {
    color: #469cff; }
  @media (min-width: 1599px) {
    .cr-prize-page__page-padding {
      padding-left: 70px;
      padding-right: 70px; } }

.cr-client-review {
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover; }
  .cr-client-review__server-image {
    position: fixed;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%; }
  .cr-client-review__server-image img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    min-width: 50%;
    min-height: 50%; }
  .cr-client-review .cr-channel-media {
    box-shadow: none;
    border-radius: 0; }
  .cr-client-review .cr-channel-media__channel-icon,
  .cr-client-review .cr-channel-media__title,
  .cr-client-review .cr-channel-media__button-up-icon {
    display: none; }
  .cr-client-review .cr-channel-media__assets-approvals-title {
    text-transform: uppercase; }
  .cr-client-review .cr-channel-media__head-right {
    position: static; }
  .cr-client-review .cr-channel-media__assets-approvals {
    margin-right: 0; }
  .cr-client-review .cr-channel-media__assets-approvals-title {
    color: #334856; }
  .cr-client-review .cr-channel-media__form-content {
    border-top: none;
    overflow: hidden; }
  .cr-client-review .cr-channel-media__form-header {
    border-bottom: 0;
    padding-top: 55px;
    height: auto;
    align-items: center;
    display: flex;
    justify-content: center; }
  .cr-client-review .cr-channel-media__content {
    padding-top: 9px;
    cursor: initial; }
  .cr-client-review .cr-channel-media__review-complete .cr-channel-media__review-complete-image {
    margin-top: 54px; }
  .cr-client-review .cr-channel-media__review-complete .cr-channel-media .slick-dots {
    bottom: 25px !important; }
  .cr-client-review .cr-channel-media .slick-dots {
    bottom: -86px; }
  .cr-client-review__review-container {
    max-width: 1400px;
    margin: 0 auto;
    overflow: hidden; }
  .cr-client-review__header {
    height: 102px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 30px 30px 0px 0px;
    background: #0f5589;
    padding: 8px 40px; }
    .cr-client-review__header .cr-client-review__logo {
      height: 50px; }
  .cr-client-review__button-req-change {
    border-color: #829fb1;
    background: transparent; }
  .cr-client-review__branding-wrapper {
    display: flex;
    align-items: flex-end;
    height: 50px; }
  .cr-client-review__server-name {
    font-weight: 900;
    color: #fff;
    font-size: 24px;
    margin-bottom: 0;
    line-height: 50px;
    height: 50px; }
  .cr-client-review__server-description {
    font-size: 26px;
    line-height: 34px;
    color: #fff;
    margin-left: 25px;
    align-self: flex-end;
    white-space: nowrap;
    margin-bottom: 0; }
  .cr-client-review__review-content {
    display: flex;
    flex-direction: row;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    background-color: #fff;
    border-radius: 0 0 6px 6px;
    margin: 0 auto; }
  .cr-client-review__review-left {
    width: 896px; }
  .cr-client-review__review-right {
    width: 100%;
    max-width: 512px;
    background: rgba(247, 251, 253, 0.6); }
  .cr-client-review__guide-container {
    background-color: #fff;
    padding-top: 94px;
    padding-left: 83px;
    padding-right: 109px;
    display: flex;
    flex-direction: column;
    padding-bottom: 100px; }
  .cr-client-review__guide-client-name {
    color: #795afa;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
    text-align: center; }
  .cr-client-review__guide-title {
    font-weight: 800;
    font-size: 40px;
    text-align: center;
    margin-top: 0;
    color: #000000; }
  .cr-client-review__guide-description {
    font-size: 16px;
    color: #000000;
    text-align: center; }
  .cr-client-review__guide-image {
    text-align: center;
    margin-bottom: 58px; }
  .cr-client-review__guide-button {
    position: relative;
    min-width: 259px !important;
    width: 259px !important;
    margin: 0 auto;
    height: 52px !important; }
    .cr-client-review__guide-button span {
      color: #334856;
      font-weight: 800;
      font-size: 19px;
      text-transform: initial; }
  .cr-client-review__guide-arrow {
    position: absolute;
    top: -20px;
    right: -130px; }
  .cr-client-review__comment-sidebar {
    padding: 73px 41px 43px; }
  .cr-client-review__comment-client-name {
    color: #829fb1;
    font-size: 18px;
    margin-bottom: 4px; }
  .cr-client-review__process-title {
    color: #334856;
    font-weight: 800;
    font-size: 30px;
    margin-bottom: 20px; }
  .cr-client-review__process-stations, .cr-client-review__process-flight-dates, .cr-client-review__process-campaign {
    color: #657894;
    font-size: 16px;
    margin-bottom: 8px;
    line-height: 22px; }
    .cr-client-review__process-stations strong, .cr-client-review__process-flight-dates strong, .cr-client-review__process-campaign strong {
      text-transform: uppercase; }
  .cr-client-review__stations, .cr-client-review__date, .cr-client-review__campaign {
    margin-left: 5px; }
  .cr-client-review__asset-status-item {
    padding: 13px 0; }
  .cr-client-review__asset-status {
    display: flex;
    align-items: center;
    cursor: pointer; }
  .cr-client-review__asset-text {
    color: #334856;
    font-size: 14px;
    margin-left: 14px; }
  .cr-client-review__view-comment-content {
    background: #ffffff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 0px 15px 15px 15px;
    padding: 17px 14px 11px 16px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    min-height: 75px; }
  .cr-client-review__message-avatar-text {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #ffffff;
    background: #0f55b9; }
  .cr-client-review__view-comment-text {
    font-size: 14px;
    color: #334856;
    margin-left: 14px;
    word-break: break-word; }
  .cr-client-review__comment-number {
    width: 32px;
    height: 32px;
    background-color: #0f55b9;
    font-weight: 800;
    font-size: 18px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    min-width: 32px; }
  .cr-client-review__comment-more-icon {
    position: absolute;
    bottom: 11px;
    right: 14px; }
  .cr-client-review__view-comment-close {
    position: absolute;
    top: 17px;
    cursor: pointer;
    right: 14px; }
  .cr-client-review__asset-comments .cr-client-review__view-comment-content:first-child,
  .cr-client-review__asset-comments .cr-client-review__edit-comment-content:first-child {
    margin-top: 20px; }
  .cr-client-review__asset-comments .cr-client-review__view-comment-content:last-child {
    margin-bottom: 0; }
  .cr-client-review__view-comment-left {
    display: flex;
    align-items: center;
    flex: 1 1;
    margin-right: 25px;
    cursor: pointer; }
  .cr-client-review__tooltip-comment-actions {
    opacity: 1;
    background-color: transparent; }
    .cr-client-review__tooltip-comment-actions .rc-tooltip-inner {
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      padding: 14.5px 0 12px 0;
      width: 161px; }
    .cr-client-review__tooltip-comment-actions .rc-tooltip-arrow {
      border-color: transparent;
      top: -1px; }
      .cr-client-review__tooltip-comment-actions .rc-tooltip-arrow:after {
        content: "";
        position: absolute;
        width: 19px;
        height: 19px;
        background: #fff;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 1px;
        left: -10px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); }
      .cr-client-review__tooltip-comment-actions .rc-tooltip-arrow:before {
        content: "";
        display: block;
        width: 30px;
        height: 14px;
        background: #fff;
        position: absolute;
        top: 11px;
        z-index: 9;
        left: -15px; }
  .cr-client-review__comment-button {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: #334856;
    cursor: pointer;
    padding-left: 24px;
    padding-right: 31px; }
    .cr-client-review__comment-button:hover {
      background: #c2d4e0; }
  .cr-client-review__edit-comment-content {
    background: #ffffff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 0px 15px 15px 15px;
    padding: 15px 39px 19px 16px;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-bottom: 10px; }
  .cr-client-review__edit-comment-close {
    position: absolute;
    top: 17px;
    right: 14px;
    cursor: pointer; }
  .cr-client-review__edit-comment-text {
    position: relative;
    margin-left: 13px;
    flex: 1 1; }
    .cr-client-review__edit-comment-text textarea {
      resize: none;
      border-radius: 15px; }
      .cr-client-review__edit-comment-text textarea:hover, .cr-client-review__edit-comment-text textarea:focus, .cr-client-review__edit-comment-text textarea:active {
        border-color: transparent;
        box-shadow: none; }
    .cr-client-review__edit-comment-text .cr-textinput__input-wrapper {
      border-radius: 15px;
      border: 1px solid #c2d4e0;
      padding-bottom: 40px; }
  .cr-client-review__edit-submit {
    position: absolute;
    bottom: 10px;
    right: 12px;
    padding: 0;
    border: none;
    height: 30px;
    width: 30px;
    background-color: #795afa;
    min-width: auto !important;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-client-review__chat-comments {
    margin-top: 34px;
    margin-bottom: 15px; }
    .cr-client-review__chat-comments .cr-chatbox__title,
    .cr-client-review__chat-comments .cr-chatbox__input__button-mention {
      display: none; }
    .cr-client-review__chat-comments .cr-chatbox__input {
      box-sizing: border-box;
      border-radius: 15px;
      min-height: 96px;
      align-items: flex-start;
      position: relative; }
    .cr-client-review__chat-comments .cr-chatbox__input__button {
      position: absolute;
      bottom: 12px;
      right: 14px; }
  .cr-client-review .col-reset {
    padding-left: 0;
    padding-right: 0; }
  .cr-client-review__view-all {
    cursor: pointer; }
  .cr-client-review .cr-chatbox {
    max-width: 100%; }
  @media (max-width: 1436px) {
    .cr-client-review .cr-client-review__review-container {
      max-width: calc(100% - 160px); }
    .cr-client-review .cr-client-review__review-left {
      width: 60%; }
    .cr-client-review .cr-client-review__review-right {
      width: 40%; }
    .cr-client-review .cr-client-review__p-review-left {
      padding-top: 39px; }
    .cr-client-review .cr-client-review__p-review-right {
      padding-top: 50px; } }
  .cr-client-review__p-review-left {
    padding: 55px 68px; }
  .cr-client-review__p-client-name {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #657894;
    text-transform: uppercase; }
  .cr-client-review__p-order-title {
    font-weight: 800;
    font-size: 30px;
    line-height: 34px;
    color: #334856;
    margin-bottom: 10px; }
  .cr-client-review__script-recording-header {
    font-weight: 700;
    font-size: 23px;
    line-height: 29px;
    color: #334856;
    text-transform: capitalize;
    margin-bottom: 8px; }
  .cr-client-review__script {
    background: #ffffff;
    border: 1px solid #c2d4e0;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 8px 0px 14px 0;
    padding: 5px 15px 15px; }
    .cr-client-review__script .cr-textarea-evaluator__container {
      padding-bottom: 0;
      padding-right: 42px; }
    .cr-client-review__script .cr-textarea-evaluator__wrapper-top-length {
      display: none; }
  .cr-client-review__script-height {
    max-height: 200px;
    overflow-y: scroll; }
  .cr-client-review__buttons-container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-client-review__p-review-right {
    padding-top: 55px;
    padding-left: 37px;
    padding-right: 40px;
    padding-bottom: 66px; }
  .cr-client-review__comments-header {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #334856;
    border-bottom: 1px solid rgba(194, 212, 224, 0.67);
    padding-bottom: 14px;
    margin-bottom: 17px;
    margin-top: 30px; }
  .cr-client-review__comments-help {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #334856; }
  .cr-client-review__comments-container.no-message {
    margin-top: 30px; }
  .cr-client-review__comments-container .cr-chatbox__mentionInput__control {
    margin-left: 6px; }
  .cr-client-review__comments-container .messages-wrapper {
    margin-top: 20px; }
    .cr-client-review__comments-container .messages-wrapper .message-content .time {
      text-align: left !important;
      padding-left: 18px;
      padding-top: 5px;
      font-size: 11px;
      line-height: 14px;
      color: #829fb1; }
  .cr-client-review__timestamp-helper {
    background: #eff4fb;
    border-radius: 10px;
    margin-top: 40px;
    padding: 40px 30px; }
    .cr-client-review__timestamp-helper svg {
      max-width: 100%; }
  .cr-client-review .script-part-header {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #334856;
    margin-top: 10px;
    display: block; }
  .cr-client-review__final-script-recording {
    margin-top: 30px; }
  .cr-client-review__comment-part-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #334856;
    margin-top: 10px;
    display: block;
    margin-bottom: 10px; }
  .cr-client-review__script-file {
    margin-top: 9px;
    max-width: 544px; }
  .cr-client-review__script-change-title {
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #334856;
    text-transform: capitalize;
    margin-bottom: 3px; }
  .cr-client-review__script-change-description {
    color: #000;
    font-size: 16px;
    margin-bottom: 20px; }
    .cr-client-review__script-change-description span {
      display: block; }
  .cr-client-review__script-file-review {
    margin-top: 34px; }
    .cr-client-review__script-file-review .cr-order-script__part-upload-title {
      display: none; }
  .cr-client-review__script-file-review-bookend .cr-order-script__part-upload-title {
    display: block;
    font-size: 16px;
    line-height: 20px;
    color: #334856; }
  .cr-client-review .cr-order-script__col-field:nth-last-child {
    margin-bottom: 0 !important; }
  .cr-client-review__script-feedback-buttons {
    padding-top: 19px; }
    .cr-client-review__script-feedback-buttons .btn-send {
      margin-right: 14px; }
  .cr-client-review__production-change-request .cr-textarea-evaluator__inner-title {
    margin-top: 0; }
  .cr-client-review__production-change-request .cr-client-review__script-recording-header {
    border-bottom: 1px solid rgba(194, 212, 224, 0.67) !important; }
  .cr-client-review__production-review .btn-submit-script-review {
    margin-top: 20px; }
  .cr-client-review__production-review .file-upload-title-container {
    display: none; }
  .cr-client-review__production-review .final-recording .file-upload-title-container {
    display: block; }
  .cr-client-review__production-review .final-recording .upload-file-buttons {
    display: flex;
    margin-top: 4px; }
  .cr-client-review__production-review .final-recording .btn-link {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    padding: 0 !important;
    height: auto !important;
    text-align: left;
    text-transform: capitalize; }
  .cr-client-review__production-review .slick-prev,
  .cr-client-review__production-review .slick-next {
    top: 10px;
    z-index: 9;
    margin-top: 16px;
    width: 30px;
    height: 33px;
    cursor: pointer; }
    .cr-client-review__production-review .slick-prev svg,
    .cr-client-review__production-review .slick-next svg {
      cursor: pointer; }
    .cr-client-review__production-review .slick-prev::before,
    .cr-client-review__production-review .slick-next::before {
      display: none; }
  .cr-client-review__production-review .slick-prev {
    left: 0;
    text-align: left; }
  .cr-client-review__production-review .slick-next {
    right: 0;
    text-align: right; }
  .cr-client-review__slide-script-item .cr-textarea-evaluator__wrapper-top-length {
    display: none; }
  .cr-client-review__slide-script-item .cr-client-review__script-recording-header {
    border-bottom: none; }
  .cr-client-review__slide-script-item .cr-textarea-evaluator__title {
    font-size: 23px;
    font-weight: 700;
    width: 100%; }
  .cr-client-review__slide-script-item .cr-textarea-evaluator__title-wrapper {
    margin-bottom: 8px;
    padding-bottom: 0; }
  .cr-client-review__slide-review {
    text-align: center; }
  .cr-client-review__button-approve {
    margin-right: 7px; }
    .cr-client-review__button-approve:hover {
      border-color: #829fb1; }
    .cr-client-review__button-approve:hover:not([disabled]) {
      background-color: #73a703 !important;
      border-color: #73a703 !important; }
      .cr-client-review__button-approve:hover:not([disabled]) span {
        color: #fff !important; }
      .cr-client-review__button-approve:hover:not([disabled]) .cr-client-review__button-icon {
        display: block; }
    .cr-client-review__button-approve:focus {
      box-shadow: none; }
  .cr-client-review__button-approved {
    background-color: #73a703 !important;
    border-color: #73a703 !important; }
    .cr-client-review__button-approved:hover {
      background-color: #73a703 !important;
      border-color: #73a703 !important; }
    .cr-client-review__button-approved span {
      color: #fff !important; }
    .cr-client-review__button-approved .cr-client-review__button-icon {
      display: block; }
  .cr-client-review__button-reject {
    margin-right: 0 !important;
    margin-left: 7px; }
    .cr-client-review__button-reject:hover {
      border-color: #829fb1; }
    .cr-client-review__button-reject:hover:not([disabled]) {
      background-color: #e40061 !important;
      border-color: #e40061 !important; }
      .cr-client-review__button-reject:hover:not([disabled]) span {
        color: #fff !important; }
      .cr-client-review__button-reject:hover:not([disabled]) .cr-client-review__button-icon {
        display: block; }
    .cr-client-review__button-reject:focus {
      box-shadow: none; }
  .cr-client-review__button-rejected {
    background-color: #e40061 !important;
    border-color: #e40061 !important; }
    .cr-client-review__button-rejected span {
      color: #fff !important; }
    .cr-client-review__button-rejected .cr-client-review__button-icon {
      display: block; }
  .cr-client-review__button-icon {
    display: none;
    margin-right: 10px; }
  .cr-client-review__slider-review-buttons {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 37px;
    cursor: pointer;
    position: relative; }
  .cr-client-review__button-review {
    background: #ffffff;
    border: 1px solid #829fb1;
    border-radius: 25px;
    height: 48px;
    min-width: 130px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    opacity: 1;
    width: 230px !important; }
    .cr-client-review__button-review span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #334856;
      border-radius: 18px; }
  .cr-client-review__assets-approvals {
    margin-bottom: 8px;
    text-align: center; }
  .cr-client-review__assets-approvals-title {
    color: #334856;
    font-size: 14px;
    text-transform: uppercase; }
  .cr-client-review__assets-approvals-value {
    color: #334856;
    font-weight: 700;
    font-size: 14px;
    text-align: right;
    margin-left: 10px; }
  .cr-client-review__slide-script-item {
    text-align: left; }
  .cr-client-review__review-status-item {
    margin-top: 20px;
    display: flex;
    align-items: center; }
    .cr-client-review__review-status-item svg {
      margin-right: 5px; }
  .cr-client-review__part-text {
    margin-left: 5px; }
  .cr-client-review__helper-text {
    color: #336abb;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    margin-top: 15px; }
  .cr-client-review .btn-submit-script-review {
    width: 230px;
    margin: 0 auto;
    margin-top: 15px;
    font-size: 14px;
    height: 48px; }
    .cr-client-review .btn-submit-script-review.disabled {
      background-color: #bccedb !important;
      border-color: #bccedb !important; }
  .cr-client-review__submit-all-feedback {
    text-align: center;
    margin-top: 30px; }
  .cr-client-review__section-title-editor {
    padding-bottom: 17px;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(194, 212, 224, 0.67); }
  .cr-client-review__feedback-description {
    color: #334856;
    font-size: 16px;
    line-height: 20px;
    margin-top: 14px;
    margin-bottom: 20px;
    font-weight: 400;
    display: block; }
  .cr-client-review__script-recording-header {
    padding-bottom: 17px;
    border-bottom: 1px solid rgba(194, 212, 224, 0.67);
    margin-bottom: 0; }
  @media (max-width: 480px) {
    .cr-client-review {
      padding: 0;
      align-items: flex-start;
      background-color: #fff;
      height: 100vh; }
      .cr-client-review__review-container {
        max-width: 100% !important;
        overflow: auto; }
      .cr-client-review__server-image {
        display: none; }
      .cr-client-review__header {
        padding: 20px 28px;
        border-radius: 0;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: auto; }
      .cr-client-review__branding-wrapper {
        height: auto;
        flex-direction: column;
        align-items: center;
        text-transform: capitalize; }
      .cr-client-review__server-name {
        font-weight: 900;
        font-size: 32px;
        line-height: 34px;
        text-align: center;
        color: #ffffff;
        text-transform: capitalize; }
      .cr-client-review__server-description {
        margin: 0 !important; }
      .cr-client-review__production-review {
        border-radius: 0; }
      .cr-client-review__review-left {
        width: 100% !important;
        padding: 20px 35px; }
      .cr-client-review__p-order-title {
        font-size: 24px;
        line-height: 28px; }
      .cr-client-review__final-script-recording {
        margin-top: 20px; }
      .cr-client-review__buttons-container {
        margin-top: 20px; }
      .cr-client-review__form-buttons {
        align-items: center; }
        .cr-client-review__form-buttons .btn {
          margin: 0 7.5px;
          font-size: 14px; }
      .cr-client-review__p-review-right {
        width: 100% !important;
        padding: 20px 28px !important; }
      .cr-client-review__comments-header {
        font-size: 24px;
        line-height: 28px;
        padding-bottom: 10px; }
      .cr-client-review .cr-textarea-evaluator__container {
        padding-right: 0; }
      .cr-client-review__script-height {
        max-height: inherit; }
      .cr-client-review .RichEditor-styleButton {
        padding: 5px 15px; }
      .cr-client-review__view-all {
        text-decoration: underline;
        margin-left: 5px; }
      .cr-client-review__process-stations {
        position: relative; }
      .cr-client-review__tooltip-stations {
        position: absolute;
        width: 100%;
        padding: 15px;
        background: #ffffff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102); }
        .cr-client-review__tooltip-stations span {
          font-size: 16px;
          line-height: 22px;
          color: #657894;
          text-transform: uppercase;
          text-decoration: none; }
        .cr-client-review__tooltip-stations strong {
          margin-right: 10px; }
      .cr-client-review__button-icon {
        display: none !important; }
      .cr-client-review__review-status-content {
        font-size: 13px; }
      .cr-client-review__script-production-review {
        flex-direction: column-reverse; }
        .cr-client-review__script-production-review .cr-client-review__review-right {
          background-color: #f7fbfd; }
        .cr-client-review__script-production-review .cr-client-review__review-left {
          text-align: center;
          background: #ffffff;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102); }
        .cr-client-review__script-production-review .slick-next,
        .cr-client-review__script-production-review .slick-prev {
          top: 50%;
          margin: 0; }
        .cr-client-review__script-production-review .slick-prev {
          left: -25px; }
        .cr-client-review__script-production-review .slick-next {
          right: -25px; }
      .cr-client-review__assets-approvals {
        margin-top: 40px; }
      .cr-client-review__slider-review-buttons {
        display: flex;
        margin-bottom: 40px;
        margin-top: 23px; }
      .cr-client-review__button-review {
        width: calc(50% - 7px) !important;
        height: 38px; }
        .cr-client-review__button-review span {
          font-size: 12px !important; }
      .cr-client-review__spot-production-review {
        flex-direction: column-reverse; }
        .cr-client-review__spot-production-review .cr-client-review__review-left {
          background: #ffffff;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102); }
        .cr-client-review__spot-production-review .cr-client-review__review-right {
          background-color: #f7fbfd; }
        .cr-client-review__spot-production-review .cr-client-review__script-recording-header {
          border-bottom: none;
          text-align: left;
          padding-bottom: 0px; }
        .cr-client-review__spot-production-review .cr-client-review__comments-header {
          text-align: left; }
        .cr-client-review__spot-production-review .cr-client-review__review-left {
          padding: 20px 28px; }
      .cr-client-review__timestamp-helper,
      .cr-client-review .RichEditor-styleDropdownFonts {
        display: none; } }

.cr-add-update-client-manager__container-padding {
  padding: 0px 50px; }

.cr-add-update-client-manager__content {
  padding-top: 42px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between; }
  .cr-add-update-client-manager__content .cr-comments-sidebar.expanded {
    max-width: 100px; }
    .cr-add-update-client-manager__content .cr-comments-sidebar.expanded .cr-chatbox {
      min-width: 70px; }

.cr-add-update-client-manager .cr-client-manager-form {
  flex: 1 1; }

@media (min-width: 1599px) {
  .cr-add-update-client-manager__container-padding {
    padding-left: 70px;
    padding-right: 70px; } }

@media (max-width: 768px) {
  .cr-add-update-client-manager__container-padding {
    padding: 0px 20px; } }

@media (max-width: 480px) {
  .cr-add-update-client-manager__container-padding {
    padding: 0px 10px; } }

.sidebar-comments-open .cr-client-manager-form {
  max-width: 898px;
  min-width: 740px; }

.cr-schedule .breadcrumb {
  margin-bottom: 0; }
  .cr-schedule .breadcrumb .breadcrumb-item::before {
    color: #fff; }
  .cr-schedule .breadcrumb .active {
    color: #fff;
    font-weight: bold; }

.cr-schedule .cr-page__page-top {
  background-color: #336abb;
  color: white;
  margin-top: 65px; }

.cr-schedule__padding-container {
  padding: 0px 50px; }

.cr-schedule__page-title {
  font-weight: 800;
  font-size: 32px;
  color: #ffffff; }

.cr-schedule .cr-page__header {
  padding-top: 0; }

.cr-schedule__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 39px; }

.cr-schedule__search-input {
  min-width: 389px; }

.cr-schedule .cr-schedule-calendar {
  padding-top: 35px; }

.cr-schedule__advanced-search-text {
  font-size: 14px;
  line-height: 40px;
  width: 100%;
  display: block;
  text-transform: capitalize;
  cursor: pointer;
  text-align: right;
  color: #a9d1f4; }

@media (min-width: 1599px) {
  .cr-schedule__padding-container {
    padding: 0 70px; } }

.cr-jock-console-page {
  margin-top: 65px;
  height: calc(100% - 65px); }

.cr-jock-console-manager-page .breadcrumb .breadcrumb-item::before {
  color: #fff; }

.cr-jock-console-manager-page .breadcrumb .active {
  color: #fff;
  font-weight: bold; }

.cr-jock-console-manager-page .cr-page__page-top {
  background-color: #336abb;
  color: white;
  margin-top: 65px; }

.cr-jock-console-manager-page__padding-container {
  padding: 0px 50px; }

.cr-jock-console-manager-page__page-title {
  font-weight: 800;
  font-size: 32px;
  color: #ffffff;
  text-transform: capitalize; }

.cr-jock-console-manager-page__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 39px; }

@media (min-width: 1599px) {
  .cr-jock-console-manager-page__padding-container {
    padding: 0 70px; } }

.cr-jock-console-page {
  margin-top: 65px;
  height: calc(100% - 65px); }

.cr-form-manager .select-stations-wrapper {
  width: 430px;
  margin-left: 10px;
  margin-bottom: 30px; }
  .cr-form-manager .select-stations-wrapper label {
    font-size: 12px;
    line-height: 15px;
    color: #43535c;
    text-transform: uppercase; }

.cr-form-manager .tab-content {
  position: relative; }

.cr-form-manager .nav-tabs {
  margin-left: 10px; }

.cr-form-manager .nav-link {
  font-size: 16px !important;
  min-width: 226px;
  text-align: center;
  display: inline-block !important;
  padding: 12px 6px !important;
  color: #657894;
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
  border: none !important; }
  .cr-form-manager .nav-link.active {
    font-weight: bold !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    color: #657894 !important;
    position: relative; }
    .cr-form-manager .nav-link.active::after {
      left: 0;
      content: "";
      display: block;
      background-color: #fff;
      width: 100%;
      height: 20px;
      position: absolute; }

.cr-form-manager__channels-filter {
  position: absolute;
  top: -35px;
  right: 0; }
  .cr-form-manager__channels-filter span {
    font-weight: bold;
    line-height: 20px;
    text-align: center;
    color: #334856; }

.cr-form-manager .cr-select-audience .cr-select-audience__selected-audience {
  margin-left: 10px;
  margin-bottom: 30px; }
  .cr-form-manager .cr-select-audience .cr-select-audience__selected-audience label {
    font-size: 12px;
    line-height: 15px;
    color: #43535c;
    text-transform: uppercase; }
  .cr-form-manager .cr-select-audience .cr-select-audience__selected-audience .cr-select-audience__selected-value {
    display: block; }

@media (max-width: 1160px) {
  .cr-form-manager .select-stations-wrapper {
    width: 300px; } }

.cr-station-settings {
  padding-bottom: 80px; }
  .cr-station-settings .select-stations-wrapper {
    width: 430px;
    margin-bottom: 30px; }
    .cr-station-settings .select-stations-wrapper label {
      font-size: 12px;
      line-height: 15px;
      color: #43535c;
      text-transform: uppercase; }
  .cr-station-settings .tab-content {
    position: relative; }
  .cr-station-settings .nav-link {
    font-size: 16px !important;
    min-width: 226px;
    text-align: center;
    display: inline-block !important;
    padding: 12px 6px !important;
    color: #657894;
    border-top-right-radius: 6px !important;
    border-top-left-radius: 6px !important;
    border: none !important; }
    .cr-station-settings .nav-link.active {
      font-weight: bold !important;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      color: #657894 !important;
      position: relative; }
      .cr-station-settings .nav-link.active::after {
        left: 0;
        content: "";
        display: block;
        background-color: #fff;
        width: 100%;
        height: 20px;
        position: absolute; }
  .cr-station-settings .cr-select-audience .cr-select-audience__selected-audience {
    margin-left: 10px;
    margin-bottom: 30px; }
    .cr-station-settings .cr-select-audience .cr-select-audience__selected-audience label {
      font-size: 12px;
      line-height: 15px;
      color: #43535c;
      text-transform: uppercase; }
    .cr-station-settings .cr-select-audience .cr-select-audience__selected-audience .cr-select-audience__selected-value {
      display: block; }

.cr-system-templates {
  padding-bottom: 50px; }
  .cr-system-templates .cr-page__page-top {
    background: #336abb;
    padding-bottom: 57px;
    margin-bottom: 50px; }
    .cr-system-templates .cr-page__page-top .page-title {
      color: white; }
    .cr-system-templates .cr-page__page-top .cr-page__breadcrumb .breadcrumb .breadcrumb-item a,
    .cr-system-templates .cr-page__page-top .cr-page__breadcrumb .breadcrumb .breadcrumb-item span,
    .cr-system-templates .cr-page__page-top .breadcrumb-item + .breadcrumb-item::before {
      color: white; }
  .cr-system-templates .select-stations-wrapper {
    width: 430px;
    margin-left: 10px;
    margin-bottom: 30px; }
    .cr-system-templates .select-stations-wrapper label {
      font-size: 12px;
      line-height: 15px;
      color: #43535c;
      text-transform: uppercase; }
  .cr-system-templates .nav-tabs {
    margin-left: 10px; }
  .cr-system-templates .nav-link {
    font-size: 16px !important;
    min-width: 226px;
    text-align: center;
    display: inline-block !important;
    padding: 12px 6px !important;
    color: #657894;
    border-top-right-radius: 6px !important;
    border-top-left-radius: 6px !important;
    border: none !important;
    text-transform: capitalize; }
    .cr-system-templates .nav-link.active {
      font-weight: bold !important;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      color: #657894 !important;
      position: relative; }
      .cr-system-templates .nav-link.active::after {
        left: 0;
        content: "";
        display: block;
        background-color: #fff;
        width: 100%;
        height: 20px;
        position: absolute; }
  .cr-system-templates__button-create {
    background: #ffffff !important;
    border-radius: 24px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    height: 49px;
    border-color: #795afa;
    margin-left: 10px; }
    .cr-system-templates__button-create span {
      color: #2d4364;
      font-weight: 800;
      font-size: 20px;
      max-width: calc(100% - 25px);
      margin-left: 17px;
      text-transform: inherit; }
  .cr-system-templates__icon-add {
    color: #795afa;
    font-size: 25px; }
  .cr-system-templates .guide-text-create {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #537a91; }
  .cr-system-templates .templateItem {
    margin: 10px 10px 20px 10px;
    width: 100%;
    display: flex;
    align-items: start; }
    .cr-system-templates .templateItem .template-wrapper {
      background: #ffffff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
      width: 90%;
      border-radius: 6px; }
    .cr-system-templates .templateItem .template-wrapper-edit {
      position: relative;
      margin-bottom: 97px; }
    .cr-system-templates .templateItem .template-edit-content {
      width: 100%; }
      .cr-system-templates .templateItem .template-edit-content .system-template-box {
        box-shadow: none;
        margin: 0;
        padding-bottom: 0;
        width: 100%; }
      .cr-system-templates .templateItem .template-edit-content .system-templates-buttons {
        position: absolute;
        bottom: -115px; }
      .cr-system-templates .templateItem .template-edit-content .cr-system-template-form__preview {
        display: none; }
      .cr-system-templates .templateItem .template-edit-content .system-templates-buttons {
        padding-bottom: 20px; }
    .cr-system-templates .templateItem .templateItemContainer {
      display: flex;
      align-items: center;
      padding: 30px;
      width: 100%; }
      .cr-system-templates .templateItem .templateItemContainer .template-name {
        font-weight: 800;
        font-size: 23px;
        line-height: 29px;
        color: #111b2b;
        margin-right: auto;
        cursor: pointer; }
      .cr-system-templates .templateItem .templateItemContainer .actions {
        display: flex;
        align-items: center; }
        .cr-system-templates .templateItem .templateItemContainer .actions .cr-checkbox {
          margin-right: 30px; }
  .cr-system-templates__template-inactive .template-name,
  .cr-system-templates__template-inactive .cr-system-templates__sub-title {
    color: #829fb1 !important; }
  .cr-system-templates__btn-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
    cursor: pointer; }
  .cr-system-templates__btn-clone {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
    cursor: pointer; }
  .cr-system-templates__button-down {
    border: none;
    outline: none;
    width: 24px;
    padding: 0;
    background: transparent;
    cursor: pointer; }
    .cr-system-templates__button-down img {
      max-width: 100%; }
    .cr-system-templates__button-down:focus, .cr-system-templates__button-down:active {
      outline: none; }
  .cr-system-templates__button-icon {
    color: #795afa;
    margin-right: 10px; }
  .cr-system-templates__button-down-icon {
    color: #795afa;
    font-size: 29px; }
  .cr-system-templates__button-up-icon {
    color: #c2d4e0;
    font-size: 29px; }
  .cr-system-templates .toogle-button {
    display: flex;
    justify-content: center;
    width: 10%;
    margin-top: 30px; }
  .cr-system-templates .publish-bar {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #334856;
    text-align: right;
    margin: 10px;
    margin-right: 40px; }
  .cr-system-templates .template-form {
    position: relative; }
    .cr-system-templates .template-form .preview {
      position: absolute;
      right: 10px;
      top: -30px;
      font-weight: 400;
      font-size: 14px;
      line-height: 40px;
      color: #795afa;
      display: flex;
      align-items: center;
      cursor: pointer; }
      .cr-system-templates .template-form .preview svg {
        margin-right: 5px; }
    .cr-system-templates .template-form .cr-textarea-evaluator__title-wrapper {
      display: none; }
    .cr-system-templates .template-form .cr-textarea-evaluator__timer.with-inner-title {
      display: none; }
    .cr-system-templates .template-form .guide-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      margin-bottom: 10px; }
    .cr-system-templates .template-form .select-stations-wrapper {
      margin: 0px; }
    .cr-system-templates .template-form .footer-form {
      border-top: 1px solid #edf1f6;
      padding-top: 35px;
      margin-left: -40px;
      margin-right: -40px;
      padding-left: 25px;
      padding-right: 25px; }
      .cr-system-templates .template-form .footer-form button + button {
        margin-left: 16px; }
    .cr-system-templates .template-form .form-group label span {
      text-transform: uppercase;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #43535c; }
  .cr-system-templates .modal-header {
    background: #0f5589;
    text-align: center;
    position: relative; }
    .cr-system-templates .modal-header .close-modal-icon {
      position: absolute;
      top: 15px;
      right: 20px;
      background: none;
      outline: none;
      box-shadow: none;
      border: none;
      cursor: pointer; }
  .cr-system-templates .modal-title {
    font-size: 23px;
    line-height: 29px;
    margin: auto;
    color: #ffffff;
    font-weight: 700; }
  .cr-system-templates .modal-footer {
    background: #ecf2f5;
    justify-content: flex-start;
    padding-left: 40px;
    padding-right: 40px; }
    .cr-system-templates .modal-footer button {
      border-radius: 24px;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: white; }
  .cr-system-templates__click-here {
    background: #2a7ebd;
    border-radius: 24px;
    width: 195px;
    height: 40px;
    border: none;
    color: white;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-left: 40px;
    margin-bottom: 40px;
    text-transform: uppercase; }
  .cr-system-templates .cr-select-audience .cr-select-audience__selected-audience {
    margin-left: 10px;
    margin-bottom: 30px; }
    .cr-system-templates .cr-select-audience .cr-select-audience__selected-audience label {
      font-size: 12px;
      line-height: 15px;
      color: #43535c;
      text-transform: uppercase; }
    .cr-system-templates .cr-select-audience .cr-select-audience__selected-audience .cr-select-audience__selected-value {
      display: block; }
  .cr-system-templates .system-template-box {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px;
    padding: 15px 57px 38px 34px;
    margin-left: 10px;
    width: 90%; }
  .cr-system-templates .system-templates-box-create {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    border-radius: 6px;
    padding: 48px 49px 33px 39px;
    margin: 0 10px;
    width: 90%; }
  .cr-system-templates .system-templates-buttons {
    padding-top: 33px;
    padding-right: 34px;
    padding-left: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .cr-system-templates__sub-title {
    color: #537a91;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 400; }
  .cr-system-templates .modal-content {
    border: none; }
    .cr-system-templates .modal-content .cr-system-templates__click-here {
      min-width: 195px; }
  .cr-system-templates .modal-body {
    padding: 0;
    background-color: #ecf2f5; }
  .cr-system-templates__preview-content {
    margin: 40px;
    padding: 40px 35px 25px 35px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
    text-align: center; }
    .cr-system-templates__preview-content .replace-image {
      max-width: 100%; }
    .cr-system-templates__preview-content .cr-jock-console__station-logo {
      margin: 0 auto;
      width: 122px;
      min-width: 122px;
      height: 113px;
      margin-bottom: 30px; }
    .cr-system-templates__preview-content .cr-jock-console__station-logo-img {
      margin: 0 auto;
      width: 122px;
      min-width: 122px;
      margin-bottom: 30px; }
    .cr-system-templates__preview-content .cr-jock-console__station-name {
      font-size: 20px;
      line-height: 28px; }
  .cr-system-templates__preview-text {
    text-align: left; }
  .cr-system-templates__preview-head {
    padding: 35px 47px 26px 47px;
    background-color: #fff; }
  .cr-system-templates__preview-info-head {
    padding: 35px 47px 26px 47px;
    background-color: #fff; }
  .cr-system-templates__info-item {
    color: #334856; }
    .cr-system-templates__info-item strong {
      font-weight: 700;
      margin-right: 5px; }
  .cr-system-templates__preview-bottom {
    padding-bottom: 27px; }
    .cr-system-templates__preview-bottom p {
      margin-bottom: 0;
      text-align: center;
      color: #829fb1;
      font-size: 12px; }
      .cr-system-templates__preview-bottom p strong {
        color: #334856;
        font-weight: 400; }
  .cr-system-templates__preview_contest_rules, .cr-system-templates__preview_release_form {
    background-color: #ffff;
    padding: 40px; }
    .cr-system-templates__preview_contest_rules .cr-system-templates__preview-content, .cr-system-templates__preview_release_form .cr-system-templates__preview-content {
      margin: 0;
      padding: 0;
      box-shadow: none; }

.cr-tags-modal .modal-header {
  text-align: left;
  position: relative; }
  .cr-tags-modal .modal-header .close-modal-icon {
    position: absolute;
    top: 15px;
    right: 20px;
    background: none;
    outline: none;
    box-shadow: none;
    border: none;
    cursor: pointer; }

.cr-tags-modal .modal-header {
  border-bottom: none;
  padding-bottom: 0px; }

.cr-tags-modal .modal-title {
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  color: #000000; }

.cr-tags-modal .modal-body .group_tags {
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  color: #334856;
  cursor: pointer; }
  .cr-tags-modal .modal-body .group_tags svg {
    margin-right: 20px; }

.cr-tags-modal .modal-body ul {
  list-style-type: none;
  padding-left: 0; }
  .cr-tags-modal .modal-body ul li {
    padding-left: 55px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: -15px;
    margin-right: -15px;
    cursor: pointer; }
    .cr-tags-modal .modal-body ul li button {
      background: none;
      border: none;
      outline: none;
      box-shadow: none;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #657894;
      cursor: pointer; }
    .cr-tags-modal .modal-body ul li:hover {
      background: #ebf2f7; }

.cr-hub-manager-page .cr-page__page-top {
  background: #336abb;
  margin-top: 65px;
  padding-top: 30px;
  padding-bottom: 30px; }
  .cr-hub-manager-page .cr-page__page-top .page-title {
    color: white; }
  .cr-hub-manager-page .cr-page__page-top .breadcrumb {
    margin-bottom: 0; }
  .cr-hub-manager-page .cr-page__page-top .breadcrumb-item,
  .cr-hub-manager-page .cr-page__page-top .cr-page__breadcrumb .breadcrumb .breadcrumb-item span,
  .cr-hub-manager-page .cr-page__page-top .breadcrumb-item + .breadcrumb-item::before {
    color: white; }

.cr-hub-manager-page__nav-tabs {
  margin-left: 10px; }

.cr-hub-manager-page__nav-link {
  font-size: 16px !important;
  min-width: 226px;
  text-align: center;
  display: inline-block !important;
  padding: 12px 6px !important;
  color: #657894;
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
  border: none !important;
  text-transform: capitalize; }
  .cr-hub-manager-page__nav-link.active {
    font-weight: bold !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    color: #657894 !important;
    position: relative; }
    .cr-hub-manager-page__nav-link.active::after {
      left: 0;
      content: "";
      display: block;
      background-color: #fff;
      width: 100%;
      height: 20px;
      position: absolute; }

.cr-hub-manager-page__dropdown {
  margin-top: 29px;
  margin-bottom: 24px;
  margin-left: 10px;
  max-width: 390px; }

.cr-hub-manager-page__tab-content {
  position: relative;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 48px 0 0 0;
  margin: 0 10px; }

.cr-team-management-page__page-top {
  padding-left: 50px;
  padding-right: 50px;
  background-color: #336abb;
  margin-top: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 32px; }

.cr-team-management-page .breadcrumb {
  padding: 0;
  margin: 0;
  background-color: transparent; }

.cr-team-management-page .breadcrumb-item {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff; }
  .cr-team-management-page .breadcrumb-item::before {
    color: #fff; }
  .cr-team-management-page .breadcrumb-item.active {
    font-weight: bold; }

.cr-team-management-page__header-filter {
  display: flex;
  align-items: center; }

.cr-team-management-page__filter-view {
  display: flex;
  align-items: center;
  margin-right: 24px;
  cursor: pointer; }
  .cr-team-management-page__filter-view span {
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    margin-right: 10px; }

.cr-team-management-page__th-sort {
  display: flex;
  align-items: center; }

.cr-team-management-page__sortable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  text-align: center;
  margin-left: 8px; }

.cr-team-management-page__sortable-active {
  color: #c12674; }

.cr-team-management-page .ReactTable {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border: none; }
  .cr-team-management-page .ReactTable .rt-thead {
    background: #224b8b; }
    .cr-team-management-page .ReactTable .rt-thead .rt-th {
      padding: 10px 5px;
      color: #fff;
      font-weight: 700;
      font-size: 16px; }
  .cr-team-management-page .ReactTable .rt-td {
    padding: 22px 5px;
    border-right-color: transparent; }
  .cr-team-management-page .ReactTable .rt-tbody {
    background: #ffffff; }

.cr-team-management-page__th-name, .cr-team-management-page__user-name-column {
  padding-left: 20px; }

.cr-team-management-page__rt-header-center .cr-team-management-page__th-sort {
  justify-content: center; }

.cr-team-management-page__sortable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  text-align: center; }

.cr-team-management-page__sortable-icon {
  position: absolute;
  font-size: 16px; }

.cr-team-management-page__content {
  padding-top: 27px; }

.cr-team-management-page__item-number {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #73a703;
  display: block;
  width: 100%; }

.cr-team-management-page__user-avatar .avatar-sort-name-text {
  font-size: 16px;
  line-height: 20px; }

.cr-team-management-page__user-name-column {
  display: flex;
  align-items: center; }

.cr-team-management-page__user-info {
  margin-left: 20px; }

.cr-team-management-page__user-name {
  color: #111b2b;
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  margin-left: 7px; }

.cr-team-management-page__user-status {
  width: 13px;
  height: 13px;
  display: inline-block;
  border-radius: 100px;
  background-color: #73a703; }

.cr-team-management-page__role-name {
  width: 27px;
  height: 27px;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #d6e5ef;
  margin-right: 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #657894; }

.cr-team-management-page__hub-list {
  font-size: 16px;
  line-height: 20px;
  color: #334856; }

.cr-team-management-page .hubs-string {
  font-size: 16px;
  line-height: 20px;
  color: #334856;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize; }

.cr-team-management-page__action-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }

.cr-team-management-page__tooltip {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  opacity: 1;
  padding: 0; }
  .cr-team-management-page__tooltip .rc-tooltip-content {
    background: #ffffff;
    padding: 24px;
    border-radius: 4px; }
  .cr-team-management-page__tooltip .rc-tooltip-arrow {
    border-top-color: #ffffff;
    border-width: 10px 10px 0;
    bottom: -8px; }
  .cr-team-management-page__tooltip .rc-tooltip-inner {
    padding: 0;
    background-color: #ffffff;
    box-shadow: none;
    min-height: auto; }
  .cr-team-management-page__tooltip .hubs-string {
    font-size: 16px;
    line-height: 20px;
    color: #334856;
    text-transform: capitalize; }

.cr-team-management-page__out-of-office {
  color: #e40061;
  font-size: 11px;
  line-height: 14px;
  margin-top: 3px; }

.cr-team-management-page__vacation-icon {
  margin-left: 5px; }

.cr-team-management-page__row-out-as .cr-team-management-page__user-name,
.cr-team-management-page__row-out-as .cr-team-management-page__item-number {
  color: #829fb1 !important; }

.cr-team-management-page__row-out-as .cr-avatar {
  opacity: 0.6; }

.cr-team-management-page__row-overload .cr-team-management-page__user-status {
  background-color: #e40061; }

.cr-team-management-page__row-overload .cr-team-management-page__item-number {
  color: #e40061; }

.cr-team-management-page__total-text {
  color: #657894;
  font-size: 14px;
  margin-right: 10px; }
  .cr-team-management-page__total-text strong {
    color: #334856;
    margin: 0 5px; }

.cr-team-management-page__top-filtered {
  display: flex;
  align-items: center;
  margin-bottom: 26px; }

.cr-team-management-page__filter-tag {
  background: #ffffff;
  border: 1px solid #c2d4e0;
  border-radius: 4px;
  padding: 7px 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: #657894;
  margin-right: 10px; }
  .cr-team-management-page__filter-tag svg {
    cursor: pointer;
    margin-left: 10px; }

@media (min-width: 1599px) {
  .cr-team-management-page__page-top {
    padding-left: 70px;
    padding-right: 70px; }
  .cr-team-management-page .page-container {
    padding-left: 70px;
    padding-right: 70px;
    padding-bottom: 50px; } }

@media (max-width: 480px) {
  .cr-page-login {
    min-width: 100%;
    background-color: #fff;
    padding-top: 70px; }
    .cr-page-login .cr-content {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
      background-image: url(https://promosuiteplus.net/static/media/profile_background.d504c1ee.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 0px -35px; }
    .cr-page-login .auth-form {
      box-shadow: none;
      border-radius: 0;
      margin-top: 165px;
      margin-bottom: 50px; }
      .cr-page-login .auth-form .card {
        box-shadow: none;
        border-radius: 0;
        padding-left: 20px;
        padding-right: 20px;
        border: none; }
      .cr-page-login .auth-form form {
        width: 100%; }
        .cr-page-login .auth-form form .btn-submit-auth {
          font-size: 14px;
          border-radius: 24px; }
        .cr-page-login .auth-form form .reset {
          padding: inherit; } }

@media (max-width: 480px) {
  .cr-page-home-mobile {
    min-width: auto;
    height: 100%; }
    .cr-page-home-mobile .cr-content {
      background-image: url(https://promosuiteplus.net/static/media/profile_background.d504c1ee.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 0px 0px; }
    .cr-page-home-mobile__page-container {
      padding-top: 70px;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column; }
    .cr-page-home-mobile__user-card {
      padding-top: 36px; }
      .cr-page-home-mobile__user-card .avatar-sort-name-text {
        font-size: 30px;
        line-height: 35px; }
    .cr-page-home-mobile .cr-user-card__children {
      display: none; }
    .cr-page-home-mobile__box-container {
      text-align: center; }
    .cr-page-home-mobile__content {
      padding-left: 34px;
      padding-right: 34px;
      padding-top: 60px;
      padding-bottom: 60px;
      display: flex;
      flex: 1 1;
      flex-direction: column;
      justify-content: space-between; }
    .cr-page-home-mobile__title {
      color: #657894;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 21px; }
    .cr-page-home-mobile__box-actions {
      text-align: center; }
      .cr-page-home-mobile__box-actions .btn {
        margin-bottom: 24px;
        width: 201px; }
        .cr-page-home-mobile__box-actions .btn span {
          margin-left: 10px;
          text-transform: capitalize;
          font-size: 14px;
          font-weight: 700; }
    .cr-page-home-mobile__box-message {
      max-width: 231px;
      margin: 0 auto;
      text-align: center; }
    .cr-page-home-mobile .btn-logout {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto; }
      .cr-page-home-mobile .btn-logout span {
        font-size: 14px;
        margin-left: 10px; }
    .cr-page-home-mobile__btn-desktop {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 21px;
      display: inline-flex;
      align-self: center;
      align-items: center;
      justify-content: center; } }

@media (max-width: 480px) {
  .cr-page-processes-details-mobile {
    min-width: auto; }
    .cr-page-processes-details-mobile #layout-sidebar,
    .cr-page-processes-details-mobile .cr-header,
    .cr-page-processes-details-mobile #tabs-sticky,
    .cr-page-processes-details-mobile .cr-comments-sidebar {
      display: none; }
    .cr-page-processes-details-mobile .cr-content {
      width: 100%;
      margin: auto; }
    .cr-page-processes-details-mobile .cr-add-update-process__header {
      min-width: auto; }
    .cr-page-processes-details-mobile .cr-order-details__order-switch-type {
      margin-bottom: 22px;
      margin-top: 22px; }
    .cr-page-processes-details-mobile .cr-add-update-process__tab-content-container .tab-content {
      width: 100%;
      max-width: 100%; }
    .cr-page-processes-details-mobile .cr-order-details__section-title {
      min-height: 79px;
      height: auto;
      padding-top: 10px;
      padding-bottom: 10px;
      box-sizing: border-box; }
    .cr-page-processes-details-mobile .cr-order-details__section-creative-details,
    .cr-page-processes-details-mobile .cr-order-client-details__section-client-fields,
    .cr-page-processes-details-mobile .cr-order-details__section-title,
    .cr-page-processes-details-mobile .cr-order-details__section-fields,
    .cr-page-processes-details-mobile .cr-order-details__section-production-details-fields,
    .cr-page-processes-details-mobile .cr-order-details__section-view-production-details-fields {
      padding-left: 20px;
      padding-right: 20px; }
    .cr-page-processes-details-mobile .cr-order-client-details__section-client-fields {
      margin-bottom: 20px; }
    .cr-page-processes-details-mobile .cr-order-details__section-production-details-fields {
      padding-bottom: 20px; }
    .cr-page-processes-details-mobile .cr-order-details__spot-buttons {
      margin-bottom: 20px; }
    .cr-page-processes-details-mobile .bottom-actions-sticky {
      margin-left: 0;
      width: 100%; }
      .cr-page-processes-details-mobile .bottom-actions-sticky .buttons {
        padding: 18px 20px; }
        .cr-page-processes-details-mobile .bottom-actions-sticky .buttons .btn {
          font-size: 14px;
          padding-left: 15px !important;
          padding-right: 15px !important;
          min-width: 90px !important; }
        .cr-page-processes-details-mobile .bottom-actions-sticky .buttons .button-another-spot {
          margin-left: 10px;
          margin-right: 10px; }
    .cr-page-processes-details-mobile .cr-order-client-details__col-field,
    .cr-page-processes-details-mobile .cr-order-details__col-field,
    .cr-page-processes-details-mobile .cr-order-client-details__col-view-field,
    .cr-page-processes-details-mobile .cr-order-details__col-view-field,
    .cr-page-processes-details-mobile .cr-order-details__col-stations-field {
      flex: 0 0 100%;
      max-width: 100%; }
    .cr-page-processes-details-mobile .cr-textarea-evaluator__wrapper-top-length.title {
      float: none;
      margin-top: 0;
      margin-bottom: 10px; }
    .cr-page-processes-details-mobile .cr-textarea-evaluator__wrapper-top-length {
      justify-content: flex-start; }
    .cr-page-processes-details-mobile .cr-order-details__coop-order-switch {
      margin-top: 0; }
    .cr-page-processes-details-mobile .cr-order-details__delete-order-number {
      bottom: 17px; }
    .cr-page-processes-details-mobile .cr-order-details__rotation-table {
      height: auto;
      flex-direction: column;
      overflow: hidden; }
      .cr-page-processes-details-mobile .cr-order-details__rotation-table .cr-order-details__rotation-name {
        height: 50px;
        padding-left: 0;
        flex: none;
        width: 100%;
        border-bottom: 1px solid #c2d4e0;
        display: flex;
        align-items: center;
        padding: 0 16px;
        box-sizing: border-box; }
      .cr-page-processes-details-mobile .cr-order-details__rotation-table .cr-order-details__rotation-value {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%; }
        .cr-page-processes-details-mobile .cr-order-details__rotation-table .cr-order-details__rotation-value input {
          width: 100%;
          margin: 0;
          border-left: none;
          height: 50px; }
      .cr-page-processes-details-mobile .cr-order-details__rotation-table .cr-order-details__rotation-number-view {
        margin-left: 0;
        border-left: none; }
    .cr-page-processes-details-mobile .cr-order-details__rotation-view-table {
      margin-bottom: 14px;
      border-radius: 6px;
      border: 1px solid #c2d4e0 !important; }
    .cr-page-processes-details-mobile .cr-order-details__rotation-container-view {
      border: none;
      border-radius: none; }
    .cr-page-processes-details-mobile .selected-station-item {
      margin: 10px 0; }
    .cr-page-processes-details-mobile .cr-order-details__col-stations-field {
      margin-bottom: 27px !important; }
    .cr-page-processes-details-mobile .cr-handle-assets__file-buttons .btn {
      padding: 0;
      margin: 0; }
    .cr-page-processes-details-mobile .cr-file-view__file-buttons .btn {
      margin: 0 !important; }
    .cr-page-processes-details-mobile .cr-file-view__file-item .cr-file-view__file-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1 1; }
    .cr-page-processes-details-mobile .cr-add-update-process__header {
      margin-bottom: 20px; }
    .cr-page-processes-details-mobile .cr-add-update-process__header-client {
      margin-top: 14px;
      margin-bottom: 14px; }
    .cr-page-processes-details-mobile .cr-add-update-process__header-breadcrumb a {
      color: #fff;
      font-size: 14px; }
    .cr-page-processes-details-mobile .btn-send-back,
    .cr-page-processes-details-mobile .cr-order-client-details__edit-client-link {
      display: none; } }

